import React, { useEffect } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { getStore } from '../Store';

import { LessonModal } from '../components/modals';
import { AlertDialog } from '../components/dialogs';
import JitsiModal from '../components/LessonScreen/JitsiModal';

import AuthHelper from '../helpers/AuthHelper';
import { NavigationBarWrapper } from '../components/common';
import { updateCommonStates, openDialog } from '../actions';
import AppHelper from '../helpers/AppHelper';
import { useTranslation } from 'react-i18next';

// const { STRING } = require('../helpers/ConstantHelper');

export const StudentOnlyRoute = ({ component: Component, isStandalone, ...props }) => {
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (!AuthHelper.isLoggedIn()) {
      getStore().dispatch(updateCommonStates({ prop: 'redirectTo', value: props.location }));
    } else { // if logged in, check
      if (AppHelper.isIncompleteProfileAccount()) {
        isIncompleteProfileHandler();
      } else if (AppHelper.isCBETeacher()) { // if complete, then we check if it's a teacher account
        isTeacherHandler();
      }
    }
  }, []);

  const isIncompleteProfileHandler = () => {
    getStore().dispatch(openDialog({
      dialog: AlertDialog,
      props: {
        title: t('profile.completeProfile'),
        contentText: t('profile.toContinueMustCompleteProfileStudent'),
        disableEscapeKeyDown: true,
        disableBackdropClick: true,
        buttons: [
          {
            text: t('alert.later'),
            onClick: () => {
              history.push('/dash');
            }
          },
          {
            text: t('alert.ok'),
            onClick: () => {
              getStore().dispatch(updateCommonStates({ prop: 'redirectTo', value: props.location }));
              history.push('/complete-student-profile');
            },
            primary: true
          }
        ]
      }
    }));
  };

  const isTeacherHandler = () => {
    getStore().dispatch(openDialog({
      dialog: AlertDialog,
      props: {
        title: t('profile.studentExclusiveArea'),
        contentText: t('profile.studentExclusiveTeacherNotAllowed'),
        disableEscapeKeyDown: true,
        disableBackdropClick: true,
        buttons: [
          {
            text: t('alert.ok'),
            onClick: () => history.push('/dash'),
            primary: true
          }
        ]
      }
    }));
  };
  
  const renderComponent = (routeProps) => {
    const loggedIn = AuthHelper.isLoggedIn();

    if (loggedIn) {
      if (isStandalone) {
        return (
          <Component {...routeProps} />
        );
      } else {
        return (
          <NavigationBarWrapper>
            <Component {...routeProps} />

            {/* GLOBAL MODALS after logged in */}
            <LessonModal />
            <JitsiModal />
          </NavigationBarWrapper>
        );
      }
    }

    // return (
    //   <Redirect
    //     to={{
    //       pathname: '/sign-in',
    //       state: {
    //         signUpAs: STRING.ROLE.STUDENT
    //       }
    //     }}
    //   />
    // );

    // shows sign up page right away, as more likely to get visits from unregistered user
    return (
      <Redirect
        to='/sign-up-student'
      />
    );
  };

  const render = () => {
    return (
      <Route
        {...props}
        render={renderComponent}
      />
    );
  };
  
  return render();
};
