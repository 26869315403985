// get global variable

const lsTest = () => {
  var test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch(e) {
    return false;
  }
};

/**
 * Get global variable by name
 * @param {String} name - Key name
 * @param {Boolean} parse - If do not want to parse string
 */
const get = (name, parse = true) => {
  if (!name) {
    console.warn('Need to provide name for global variable');
    return;
  }

  if (lsTest()) {
    if (parse) {
      return JSON.parse(localStorage.getItem(name));
    }

    return localStorage.getItem(name);
  } else {
    return window.APP[name];
  }
};

/**
 * Set global variable by name
 * @param {*} name 
 * @param {*} data 
 */
const set = (name, data) => {
  if (!name) {
    console.warn('Need to provide name for global variable');
    return;
  }

  if (lsTest()) {
    localStorage.setItem(name, JSON.stringify(data));
  } else {
    window.APP = { ...window.APP, [name]: data };
  }
};

/**
 * Remove global variable by name
 * @param {*} name
 */
const remove = (name) => {
  if (!name) {
    console.warn('Need to provide name for global variable');
    return;
  }

  if (lsTest()) {
    localStorage.removeItem(name);
  } else {
    window.APP = { ...window.APP, [name]: null };
  }
};

/**
 * Clear global variables when logout
 */
const logoutClear = () => {
  if (lsTest()) {
    localStorage.setItem('user', null);
    localStorage.setItem('token', null);
    localStorage.setItem('role', null);
  } else {
    window.APP = { user: null, token: null, role: null };
  }
};

/**
 * Clear all global variables
 */
const clear = () => {
  if (lsTest()) {
    localStorage.clear();
  } else {
    window.APP = {};
  }
};

/**
 * Avoid use console.log() etc.. use this to only log in dev environment
 */
const logger = {
  info: (...data) => (process.env.NODE_ENV === 'development') && console.log(...data),

  warn: (...data) => (process.env.NODE_ENV === 'development') && console.warn(...data),

  error: (...data) => (process.env.NODE_ENV === 'development') && console.error(...data)
};

export default { get, set, remove, clear, logoutClear, logger };
