import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SingleRoomParticipantList from './SingleRoomParticipantList';

const GroupedRoomList = (props) => {
  const [now, setNow] = useState(new Date());
  let nowTimer = null;

  const {
    practiceRooms,
    participants
  } = props;

  const nowTick = () => {
    setNow(new Date());
  };

  const nowTimerStart = () =>  {
    clearInterval(nowTimer);
    nowTimer = setInterval(nowTick, 1000);
  };

  useEffect(() => {
    nowTimerStart();

    // clear timer when component is unmounted
    return () => {
      clearInterval(nowTimer);
    };
  }, []);
  

  const render = () => {
    return (
      <div className="grouped-rooms">
        {
          practiceRooms.map((tutorRoom) => (
            <SingleRoomParticipantList
              key={tutorRoom.id}
              practiceRoom={tutorRoom}
              participants={participants[`pr_${tutorRoom.id}`] || []}
              now={now}
            />
          ))
        }
      </div>
    );
  };

  return render();
};

const mapStateToProps = (state) => {
  const {
    practiceRooms,
    participants
  } = state.practiceRoom.controlRoom;

  return {
    practiceRooms,
    participants
  };
};

export default connect(mapStateToProps, null)(GroupedRoomList);
