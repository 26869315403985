import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import {
  Dialog,
  DialogTitle,
  Typography,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';

import {
  Close as CloseIcon,
  Home as HomeIcon,
  AccountCircle as AccountCircleIcon,
  // Security as SecurityIcon,
} from "@material-ui/icons";

import SwipeableViews from "react-swipeable-views";

import AccountTab from "./AccountTab";
// import SecurityTab from "./SecurityTab";
import CandidateProfileTab from "./CandidateProfileTab";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },

  tabs: {
    display: "initial",
  },
});

const tabs = [
  {
    key: "account",
    icon: <HomeIcon />,
    label: "Account",
  },

  {
    key: "profile",
    icon: <AccountCircleIcon />,
    label: "Profile"
  },

  //{
  //  key: "security",
  //  icon: <SecurityIcon />,
  //  label: "Security",
  //},

];

const initialState = {
  selectedTab: 0,
  snackbarOpen: false,
  snackbarSeverity: 'success',
  snackbarMessage: '',
};

class ProfileDialog extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  handleExited = () => {
    this.setState(initialState);
  };

  handleTabChange = (event, value) => {
    this.setState({
      selectedTab: value,
    });
  };

  handleIndexChange = (index) => {
    this.setState({
      selectedTab: index,
    });
  };

  openSnackbar = (severity, message) => {
    this.setState({
      snackbarOpen: true,
      snackbarSeverity: severity,
      snackbarMessage: message,
    });
  };

  closeSnackbar = () => {
    this.setState({
      snackbarOpen: false,
    });
  }

  render() {
    // Styling
    const { classes } = this.props;

    // Dialog Properties
    const { dialogProps } = this.props;

    // Custom Properties
    const { user, userData } = this.props;

    // Custom Functions

    // Custom Functions
    // const { onDeleteAccountClick } = this.props;

    const { selectedTab, snackbarOpen, snackbarSeverity, snackbarMessage } = this.state;

    return (
      <Dialog {...dialogProps} onExited={this.handleExited}>
        <DialogTitle disableTypography>
          <Typography variant="h6"></Typography>

          <Tooltip title="Close">
            <IconButton
              className={classes.closeButton}
              onClick={dialogProps.onClose}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <Tabs
          classes={{ root: classes.tabs }}
          style={{ overflow: "initial", minHeight: "initial" }}
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          variant="fullWidth"
          onChange={this.handleTabChange}
        >
          {tabs.map((tab) => {
            return <Tab key={tab.key} icon={tab.icon} label={tab.label} />;
          })}
        </Tabs>

        <SwipeableViews
          index={selectedTab}
        >
          <AccountTab
            user={user}
            userData={userData}
            openSnackbar={this.openSnackbar}
            closeSnackbar={this.closeSnackbar}
          />

          <CandidateProfileTab
            user={user}
            userData={userData}
            openSnackbar={this.openSnackbar}
            closeSnackbar={this.closeSnackbar}
          />
          
        </SwipeableViews>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
        >
          <Alert serverity={snackbarSeverity}>
            { snackbarMessage }
          </Alert>
        </Snackbar>
      </Dialog>
    );
  }
}

ProfileDialog.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  // Dialog Properties
  dialogProps: PropTypes.object.isRequired,

};

export default withStyles(styles)(ProfileDialog);
