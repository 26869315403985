import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Grid,
  GridList,
  Paper,
  Typography,
  Avatar,
  CircularProgress,
  Collapse,
  IconButton,
  Button,
  Badge,
  Container,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import {
  UserList,
  YoubrioCalendar,
  AlertDialog,
} from '../common';
import {
  FiCard,
  FiCardActionArea,
  FiCardActions,
  FiCardContent,
  FiCardMedia
} from "../common/FullImageCard";
import NotificationList from './NotificationList';
import {
  getNotificationList,
  getUserList,
  onSlotClick,
} from '../../actions';
import {
  getBanners as fetchGetBanners,
} from '../../api';

import './DashScreen.css';

import AppHelper from '../../helpers/AppHelper';
import GLOBAL from '../../helpers/GLOBAL';

// const UPLOAD_CERT_ALERT = 'upload_cert_alert';
const COMPLETE_PROFILE_ALERT = 'complete_profile_alert';
// const SETUP_PASSWORD_ALERT = 'setup_password_alert';
// const UPDATE_MOBILE_ALERT = 'update_mobile_alert';

const useStyles = makeStyles(() => ({
  childContainer: {
    marginTop: 40,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  announcementTitle: {
    color: 'orange',
    fontSize: 15,
    alignItems: 'center',
    justifyContent: 'center'
  },
  announcementContainer: {
    whiteSpace: 'pre-line',
    borderWidth: 1,
    border: '1px solid lightgrey',
    borderRadius: '5px',
    marginLeft: 10,
    marginRight: 10,
    cursor: 'pointer',
    backgroundColor: 'white'
  },
  card: {
    width: 1000,
    maxWidth: 1000,
    height: 250,
  },
  media: {
    height: 300
  },
  fiCardContent: {
    color: "rgba(0,0,0,.75)",
    marginLeft: 20
  },
  fiCardContentTextSecondary: {
    color: "rgba(255,255,255,0.78)"
  }
}));

const FullBox = (props) => {
  return (
    <Grid style={{ width: '100%', alignItems: 'flex-start', justifyContent: 'center', display: 'flex', ...props.style }}>
      {props.children}
    </Grid>
  );
};

const DashScreen = (props) => { 
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    upcomingLoading,
    upcomingLessonBookings,
    // actions
    onSlotClick,
    getNotificationList,
    getUserList,
  } = props;

  useEffect(() => {
    document.title = t('youbrio');
    getNotificationList();
    getUserList();
    checkAndQueueNecessaryAlerts();
    requestGetBanners();
  }, []);

  const onViewMore = (slot) => {
    onSlotClick({ meetId: slot.meet_id, bookingSlotId: slot.booking_slot_id });
  };
  
  // const user = GLOBAL.get('user');
  const isNewLaunch = GLOBAL.get('isNewLaunch');
  const [displayNotificationList, setDisplayNotificationList] = React.useState(false);

  const [isNoticeExpanded, setIsNoticeExpanded] = React.useState(false);
  const [queuedAlerts, setQueuedAlerts] = React.useState([]);
  const [banners, setBanners] = React.useState(null);
  const refQueuedAlerts = React.useRef(queuedAlerts);
  const refIsNewLaunch = React.useRef(isNewLaunch);
  const refUpgradeAccountAlertDialog = React.useRef();

  const showQueuedAlerts = React.useCallback(() => {
    const queuedAlerts = refQueuedAlerts.current;
    if (queuedAlerts.length > 0) {
      switch (queuedAlerts[0]) {
      // case UPLOAD_CERT_ALERT:
      //   Alert.alert(labels.hint, messages.uploadCertToTeach, [
      //     { text: labels.cancel, onPress: showQueuedAlerts, style: 'cancel' },
      //     { text: labels.ok, onPress: onUploadCertPress }
      //   ]);
      //   break;
      case COMPLETE_PROFILE_ALERT:
        //  Alert.alert(labels.upgradeAccount, messages.profileIsIncompleteCompleteNow, [
        //    { text: labels.later, onPress: showQueuedAlerts, style: 'cancel' },
        //    { text: labels.ok, onPress: onCompleteProfilePress }
        //  ]);
        refUpgradeAccountAlertDialog.current.setOpen(true);
        break;
        // case SETUP_PASSWORD_ALERT:
        //   Alert.alert('', messages.passwordSetup, [
        //     { text: labels.later, onPress: this.showQueuedAlerts, style: 'cancel' },
        //     { text: labels.ok, onPress: Actions.changePasswordScreen }
        //   ]);
        //   break;
        // case UPDATE_MOBILE_ALERT:
        //   this.setState({ isCompleteMobileModalVisible: true });
        //   break;
      default:
        break;
      }
      setQueuedAlerts(q => q.slice(1)); // queuedAlerts.shift();
    } else {
      return;
    }
  }, []);

  const checkAndQueueNecessaryAlerts = React.useCallback(() => {
    // const { profileStatusId } = user;

    // if (!user.mobile && user.isForceMobileSetup) {
    //   this.queuedAlerts.push(UPDATE_MOBILE_ALERT);
    // }

    // if (false) { // profileStatusId !== 1 && AppHelper.isCBETeacher()) {
    //   // console.log('user is not approved yet as a teacher');
    //   setQueuedAlerts(q => [...q, UPLOAD_CERT_ALERT]);
    // } else
    if (!AppHelper.isIncompleteProfileAccount() && refIsNewLaunch.current) {
      // console.log('user\'s profile is not complete');
      setQueuedAlerts(q => [...q, COMPLETE_PROFILE_ALERT]);
    }
    // else if (false) { // !AppHelper.isIncompleteProfileAccount() && user.noPassword) {
    //   // console.log('user does not have a password yet');
    //   setQueuedAlerts(q => [...q, SETUP_PASSWORD_ALERT]);
    // }

    // GLOBAL.set('isNewLaunch', false);
    showQueuedAlerts();
  }, [showQueuedAlerts]);

  const handleOpenUpgradeAccountDialog = React.useCallback(() => {
    return (
      <div>
      </div>
    );
  }, []);

  const requestGetBanners = async () => {
    try {
      const res = await fetchGetBanners({ deviceType: 'web' });
      if (res && res.data && res.data.banners) {
        setBanners(res.data.banners);
      } else {
        setBanners(null);
        console.log('failed to get banners, because ', res);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const renderQueuedAlertDialog = (props, ref) => {
    return (
      <AlertDialog
        title={props.title}
        desc={props.desc}
        firstButtonText={props.firstButtonText}
        secondButtonText={props.secondButtonText}
        clickFirstButton={props.clickFirstButton}
        clickSecondButton={props.clickSecondButton}
        ref={ref}
      />
    );
  };

  const renderUpgradeAccountAlertDialog = () => {
    // console.log('---renderUpgradeAccountAlertDialog-');
    return renderQueuedAlertDialog(
      {
        title: t('alert.upgradeAccount'),
        desc: t('alert.upgradeAccount'),
        firstButtonText: t('alert.later'),
        secondButtonText: t('alert.confirm'),
        clickFirstButton: showQueuedAlerts,
        clickSecondButton: handleOpenUpgradeAccountDialog,
      },
      refUpgradeAccountAlertDialog
    );
  };

  const renderUpcomingLessonsContent = () => {
    if (upcomingLoading) {
      return (
        <FullBox>
          <CircularProgress />
        </FullBox>

      );
    }

    if (upcomingLessonBookings.length < 1) {
      return (
        <FullBox>
          <Typography color="textSecondary">{t('dash.noUpcomingLesson')}</Typography>
        </FullBox>
      );
    }

    return (
      <GridList spacing={10} cols={0} style={{ flexWrap: 'nowrap', height: '100%', width: '100%' }}>
        {
          upcomingLessonBookings.map((item, index) => {
            let firstName, lastName, startDateTime, endDateTime;

            const participant = item.meet_id ? item.participants[0] : AppHelper.createParticipant(item);

            if (item && item.start_date_time && item.end_date_time) {
              startDateTime = moment(new Date(item.start_date_time)).fromNow();
            }

            if (participant) {
              firstName = participant.first_name;
              lastName = participant.last_name;

              return (
                <Grid item key={index}>
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    onClick={() => onViewMore(item)}
                    className='button'
                  >
                    <Avatar alt={lastName} className="lg-avatar" src={participant.avatar_url} />
                    <Typography className="text-center">{AppHelper.getFullname(firstName, lastName)}</Typography>
                    {
                      AppHelper.minutesToNow(item.start_date_time) <= 0 ?
                        <Typography color="textSecondary" className="text-center">{t('now').toUpperCase()}</Typography>
                        :
                        <Typography color="textSecondary" className="text-center">{startDateTime}</Typography>
                    }
                  </Grid>
                </Grid>
              );
            }
            
            return (
              <Grid item key={index}>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  onClick={() => onViewMore(item)}
                  className='button'
                >
                  <Avatar alt={lastName} className="lg-avatar" />
                  <Typography className="text-center">{t('lesson.notBooked')}</Typography>
                  {
                    AppHelper.minutesToNow(item.start_date_time) <= 0 ?
                      <Typography color="textSecondary" className="text-center">{t('now').toUpperCase()}</Typography>
                      :
                      <Typography color="textSecondary" className="text-center">{startDateTime} - {endDateTime}</Typography>
                  }
                </Grid>
              </Grid>
            );
          })
        }
      </GridList>
    );
  };

  const renderUpcomingLessons = () => {
    const showTutorRoom = GLOBAL.get('appSettings')?.canAccessPracticeRoom;
    return (
      <Grid item container>
        {
          showTutorRoom &&
          <Paper elevation={0} className="px-3 py-3 br-3">
            <Typography className={classes.title}>
              { t('tutorRoom.tutorRooms') }
            </Typography>

            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              onClick={() => props.history.push('/tutor-room')}
              className='button'
            >
              <Avatar
                alt={'tutor-room'}
                className="lg-avatar"
                src={require('../../img/goldenYoubrio.png')}
              />
              <Button
                className="w-100"
                variant="outlined"
                color="primary"
                style={{ marginTop: 12 }}
              >
                {t('viewMore')}
              </Button>
            </Grid>
          </Paper>
        }

        <Paper elevation={0} className="px-3 py-3 br-3" style={{ flex: 1, marginLeft: showTutorRoom ? 20 : 0, flexWrap: 'wrap', overflow: 'hidden', display: 'flex', width: '100%' }}>
          <Typography className={classes.title}>
            { t('dash.upcomingLessons') }
          </Typography>

          {/* content - notifications */}
          { renderUpcomingLessonsContent() }
        </Paper>
      </Grid>
    );
  };

  const renderMiddleSection = () => {
    const { notificationList} = props;
    const unreadNotificationList = notificationList.filter(item => item.read_status === 0);
    return (
      <Grid item className="w-100 middle-container">
        <Grid container direction="row" justifyContent="space-between" spacing={3}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Paper elevation={0} className="py-3 br-3 white-smoke-bg">
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Typography variant="h6" className="mb-3 px-3 title">
                    { t('dash.notifications') }
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton aria-label="collapseNotificationList" onClick={(event) => { setDisplayNotificationList(!displayNotificationList); }}>
                    { unreadNotificationList && unreadNotificationList.length ?
                      <>
                        <Badge badgeContent={unreadNotificationList && unreadNotificationList.length} color="primary">
                          <NotificationsIcon />
                        </Badge>
                      </>
                      :
                      <>
                        <Badge badgeContent={unreadNotificationList && unreadNotificationList.length} color="primary">
                          <NotificationsNoneIcon />
                        </Badge>
                      </>
                    }
                  </IconButton>
                </Grid>
              </Grid>
              <Collapse in={displayNotificationList} timeout="auto" unmountOnExit>
                <NotificationList />
              </Collapse>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Paper elevation={0} className="py-3 br-3 white-smoke-bg h-100">
              <UserList displayList={displayNotificationList} setDisplayList={setDisplayNotificationList} />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderCalendar = () => {
    return (
      <Grid item className="w-100">
        <Paper elevation={0} className="br-3">
          <YoubrioCalendar />
        </Paper>
      </Grid>
    );
  };

  const renderAnnouncement = () => {
    return (
      <div
        className={`px-2 py-2 ${classes.announcementContainer}`}
        onClick={() => setIsNoticeExpanded(!isNoticeExpanded)}
      >
        <Typography component={'div'} className={isNoticeExpanded ? 'expanded-note' : 'collapsed-note'} style={{ color: 'grey', position: 'relative' }}>
          <Typography component={'div'} className={classes.announcementTitle}>
            <NotificationsActiveIcon  style={{ fill: 'orange', fontSize: 14, marginRight: '5px' }} />  
            {t('tutorRoom.announcement')}
          </Typography>
          {t('dash.announcement')}
          <div style={{ position: 'absolute', bottom: -5, right: 0 }} >
            {
              !isNoticeExpanded
                ? <KeyboardArrowDownIcon style={{ fill: '#000', fontSize: 18 }} />
                : <KeyboardArrowUpIcon style={{ fill: '#000', fontSize: 18 }} />
            }
          </div>
        </Typography>
      </div>
    );
  };

  const renderBanners = () => {
    if (!banners || !banners.tutorRoomBanner || !banners.tutorRoomBanner.url) {
      return null;
    }

    /*
      <Box mx={2} mt={2} component={Link} to='/tutor-room'>
        <img src={banners.tutorRoomBanner.url} width="100%" alt={'banner-image: ' + banners.tutorRoomBanner.url} />
      </Box>
            <img src={banners.tutorRoomBanner.url} width="100%" alt={'banner-image: ' + banners.tutorRoomBanner.url} />



              <Card className={classes.card}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              alt={'banner-image: ' + banners.tutorRoomBanner.url}
              image={banners.tutorRoomBanner.url}
              title={'banner-image: ' + banners.tutorRoomBanner.url}
              />
            <CardContent>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary">
              Enter Now
            </Button>
          </CardActions>
        </Card>
    */
    
    return (
      <Grid item container>
      <FullBox>
        <FiCard className={classes.card}>
          <FiCardMedia
              media="img"
              alt={'banner-image: ' + banners.tutorRoomBanner.url}
              image={banners.tutorRoomBanner.url}
              title={'banner-image: ' + banners.tutorRoomBanner.url}
          />
          <FiCardContent className={classes.fiCardContent}>
            <Typography>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
            </Typography>
          </FiCardContent>
          <FiCardActions className={classes.fiCardContent}>
            <Button size="large" color="white" style={{color:'white', backgroundColor:'darkslategrey', fontWeight:900}} variant="outlined" href="/tutor-room">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('tutorRoom.enterTutorRoom')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Button>
          </FiCardActions>
        </FiCard>



      </FullBox>
      </Grid>
    );
  };

  return (
    <Container>
      <Grid container className={classes.childContainer} direction="column" alignItems="center" spacing={3}>
{/*        { renderAnnouncement() } */}
        { renderBanners() }

        { renderUpcomingLessons() }

        { renderMiddleSection() }

        { renderCalendar() }

        { renderUpgradeAccountAlertDialog() }
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const {
    upcomingLessonBookings,
    upcomingLoading,
  } = state.lesson;

  const {
    userList,
    userLoading,
  } = state.youbrioProfile;

  const {
    notificationList,
    notificationListLoading,
  } = state.notification;

  const {
    practiceRooms
  } = state.practiceRoom;

  return {
    upcomingLessonBookings,
    upcomingLoading,
    userList: userList,
    userLoading,
    notificationList,
    notificationListLoading,
    practiceRooms
  };
};

export default connect(mapStateToProps, {
  getNotificationList,
  getUserList,
  onSlotClick,
})(DashScreen);
