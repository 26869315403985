import React from 'react';
import { Redirect } from 'react-router-dom';

import AuthHelper from '../../helpers/AuthHelper';

const HomeScreen = () => {
  // similar logic to Private Route assume we don't have a home page at this stage
  const loggedIn = AuthHelper.isLoggedIn();

  return (
    loggedIn ? (
      <Redirect to={'/dash'} />
    ) : (
      <Redirect to={'/sign-in'} />
    )
  );
};

export default HomeScreen;