import React from 'react';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Grid from '@material-ui/core/Grid';
import { loadStripe } from '@stripe/stripe-js';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import GLOBAL from '../../helpers/GLOBAL';

const ChildScreen = (props) => {
  const {
    returnUrl,
    payButtonLabel,
    onLoadingStart,
    onLoadingEnd,
    onError
  } = props;

  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    onLoadingStart();

    const result = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    onLoadingEnd();

    if (result.error) {
      if (onError) {
        onError({ error: result.error });
      }
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // console.log('SUCCESS', result);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const render = () => {
    return (
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        {
          stripe && elements
            ? <Grid className='mt-2' item container justifyContent='flex-end'>
              <Button
                type={'submit'}
                variant='contained'
                color='primary'
              >
                {payButtonLabel || t('wallet.pay')}
              </Button>
            </Grid>
            : null
        }
      </form>
    );
  };

  return render();
};

const StripeElement = (props) => {
  const {
    publishableKey,
    elementOptions
  } = props;

  const render = () => {
    if (elementOptions && publishableKey) {
      return (
        <Elements stripe={loadStripe(publishableKey, { locale: GLOBAL.get('locale') })} options={elementOptions}>
          <ChildScreen {...props} />
        </Elements>
      );
    }

    return null;
  };

  return render();
};

export { StripeElement };