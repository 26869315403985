import {
  LOGOUT_USER_SUCCESS,
  UPDATE_YOUBRIO_PROFILE_STATE,
} from '../actions/types';

const INITIAL_STATE = {
  // users whos related to logged in user.
  page: 1,
  lastPage: 1,
  userList: [],
  userListLoading: false,
  userListLoadingMore: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UPDATE_YOUBRIO_PROFILE_STATE:
    return { ...state, [action.payload.prop]: action.payload.value };
  case LOGOUT_USER_SUCCESS:
    return INITIAL_STATE;
  default:
    return state;
  }
};
