import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Typography, Avatar } from '@material-ui/core';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';

import { makeStyles } from '@material-ui/core/styles';

import {
  blue,
  green,
  orange,
  red,
  grey
} from '@material-ui/core/colors';

import {
  onSlotClick
} from '../../../actions';
import AppHelper from '../../../helpers/AppHelper';

const status = {
  EXPIRED:      { backgroundColor: grey[50], borderColor: grey[200], icon: null },
  CONFIRMED:    { backgroundColor: blue[50], borderColor: blue[200], icon: <EventOutlinedIcon style={{ color: blue[800], height: '18px' }} /> },
  COMPLETED:    { backgroundColor: green[50], borderColor: green[200], icon: <CheckCircleOutlinedIcon style={{ color: green[800], height: '18px' }} /> },
  CANCELLED:    { backgroundColor: red[50], borderColor: red[200], icon: <CancelOutlinedIcon style={{ color: red[800], height: '18px' }} /> },
  IN_PROGRESS:  { backgroundColor: orange[50], borderColor: orange[200], icon: <ScheduleOutlinedIcon style={{ color: orange[800], height: '18px' }} /> },
  REJECTED:     { backgroundColor: red[50], borderColor: red[200], icon: <CancelOutlinedIcon style={{ color: red[800], height: '18px' }} /> },
};


const useStyles = makeStyles({
  event: {
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingLeft: '10px',
    
    width: '100%',
    boxSizing: 'border-box',
    justifyContent: 'flex-start',
    borderRadius: '8px',
    borderWidth: '1px',
    borderStyle: 'solid',
    position: 'relative',
  },
  avatar: {
    marginLeft: '2px',
    height: '12px',
    width: '12px'
  },
  badge: {
    top: '4px', 
    bottom: '4px',
    left: '6px',
    position: 'absolute',
    width: '4px',
    borderRadius: '2px',
    zIndex: 99
  },
});


const YoubrioCalendarSlot = (props) => {
  let {
    id,
    start_date_time,
    teacherAvatarUrl: avatarUrl,
    type,
  } = props.slot;
  if (AppHelper.isCBETeacher()) {
    avatarUrl = props.slot.studentAvatarUrl;
  }

  let statusId = props.slot.booking_slot_status_id;
  if (type === 'm') {
    statusId = props.slot.meet_status_id;
  }

  const classes = useStyles();

  const startTime = moment(new Date(start_date_time)).format('hh:mmA');

  let key = 'EXPIRED';
  if (statusId === 'CF' || statusId === 'RQ') {
    key = 'CONFIRMED';
  } else if (statusId === 'CP') {
    key = 'COMPLETED';
  } else if (statusId === 'CSL' || statusId === 'CSM' || statusId === 'CT' || statusId === 'CTT' || statusId === 'CL') {
    key = 'CANCELLED';
  } else if (statusId === 'IP') {
    key = 'IN_PROGRESS';
  } else if (statusId === 'RJ') {
    key = 'REJECTED';
  }

  const eventStatus = {
    backgroundColor: status[key].backgroundColor,
    borderColor: status[key].borderColor,
  };

  const eventStatusBar = {
    backgroundColor: status[key].borderColor,
  };

  const { slot } = props;

  return (
    <Button key={id} style={eventStatus} className={`${classes.event} mt-1`} onClick={() => props.onSlotClick({ meetId: slot.meet_id, bookingSlotId: slot.booking_slot_id })}>
      {status[key].icon}
      <Typography variant="body2">{startTime}</Typography>
      {
        type === 'bs' ?
          <Avatar src={avatarUrl} className={classes.avatar} />
          :
          null
      }
      
      <div className={classes.badge} style={eventStatusBar}></div>
    </Button>
  );
};

export default connect(null, { onSlotClick })(YoubrioCalendarSlot);
