import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import AppHelper from '../../helpers/AppHelper';
import { connect } from 'react-redux';
import { getJitsiPreloadServerUrl } from '../../actions';
import { usePrevious } from '../../hooks';
import AuthHelper from '../../helpers/AuthHelper';

const _InitComponent = (props) => {
  const location = useLocation();
  const history = useHistory();
  const isLoggedIn = AuthHelper.isLoggedIn();
  const prevIsLoggedIn = usePrevious(isLoggedIn);

  useEffect(() => {
    const searchParams = queryString.parse(location.search);

    // if lang search param exists in the url, change language if possible, and remove from url
    if (searchParams.lang) {
      const didLangChange = AppHelper.switchPortalLanguage(searchParams.lang);

      delete searchParams.lang;
      const purgedParamString = queryString.stringify(searchParams);

      history.replace({
        to: location.pathname,
        search: purgedParamString
      });

      if (didLangChange) {
        /**
         * Reload on language change because api will need to refetch to match current language.
         * 1 second delay to give adequate time for any redux actions to complete.
         */
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  }, [location]);

  useEffect(() => {
    if (isLoggedIn && !prevIsLoggedIn) {
      // Jitsi external_api.js preload is moved here
      props.getJitsiPreloadServerUrl({
        callback: ({ response, error }) => {
          if (response) {
            const script = document.createElement('script');
            script.setAttribute('type', 'text/javascript');
            script.src = `${response.jitsiUrl}external_api.js`;

            document.body.appendChild(script);
          } else if (error) {
            console.log(error);
          }
        }
      });
    }
  }, [isLoggedIn]);

  const render = () => {
    return null;
  };
  
  return render();
};

const InitComponent = connect(null, {
  getJitsiPreloadServerUrl
})(_InitComponent);

export { InitComponent };