export const zhHant = {
  // START ---- actions::AuthActions
  fieldCannotEmpty: '此輸入框不能留空。',
  enterValidEmail: '請輸入有效郵箱地址。',
  unknownLoginError: '未知登錄錯誤。請聯繫系統管理員。',
  onlyForCandidateAndTeacher: '此手機APP目前只開放給學生和老師使用。',
  incorrectLoginCredentials: '您的郵箱地址或密碼不正確。',
  accountIsNotEmailVerified: '您的帳號未經驗證。請登錄您的郵箱以索取帳號驗證郵件。',
  teacherAccountIsNotAdminVerified: '您的帳號未經系統管理員批准。請耐心等候，帳號經批准後您將會收到郵件通知。',
  sendPasswordResetEmailSuccess: '密碼重設鏈接郵件已發送至 {0}。',
  sendPasswordResetEmailFail: '無法發送密碼重設鏈接郵件至 {0}。請您稍後再試或聯繫系統管理員。',
  unableToLogIn: '暫時無法登錄帳號。請稍後再試或聯繫系統管理員。',
  weChatNotInstalled: {
    title: '未安裝微信',
    body: '沒有安裝微信軟件，\n請您安裝微信之後在試',
    buttonsText: '確認'
  },
  resetPasswordSuccess: '您已成功重置密碼。',
  resetPasswordFail: '無法重置密碼。請您稍後再試或聯繫系統管理員。',
  siwaOnlySupportIosThirteenAbove: 'Apple ID 登錄僅限於 iOS 13 或以上的設備，請選擇其他登錄選項。',
  //   END ---- actions::AuthActions

  // START ---- components::CandidateRegisterScreen
  agreeForSubscription: '我同意接收優伯的電子郵件通訊及宣傳資訊。',
  areYouWillingToProvideFreeTrial: '您是否願意爲優伯教學介紹的學生提供一堂免費體驗課程？',
  registeredForGST: '我已經註冊了GST',
  atLeastThreeYearsOld: '至少3歲才可以開始註冊上課',
  atLeastThirteenYearsOld: '我已獲得父母或監護人的同意，可以註冊一個帳戶以訪問優伯教學平台和相關服務。',
  underThirteenYearsOld: '在您完成學生資料並訪問 YOUBRIO 和相關服務之前，請確保您已獲得父母或監護人的同意。',
  pleaseCheckPermission: '請確認你是否有父母或者監護人的同意',
  abnMustBeFilledForGST: '已註冊GST的用戶必須填寫ABN。',
  //   END ---- components::CandidateRegisterScreen

  // START ---- components::TeacherRegisterScreen
  atLeastSixteenYearsOldToBeTeacher: '您必須是十五歲以上才能註冊爲老師。',
  //   END ---- components::TeacherRegisterScreen

  // START ---- actions::RegisterActions
  passwordMustBeAtLeast8Chars: '密碼長度至少8位。',
  passwordMustConsistOfBothAlphabeticAndNumericChar: '密碼必須由字母與數字符號構成。',
  passwordDontMatch: '密碼與確認密碼不匹配。',
  pleaseSelectACountry: '請選擇國家。',
  pleaseSelectAnInstitutionType: '請選擇學院類型。',
  emailHasExisted: '此郵箱地址已被註冊。',
  checkEmailFail: '目前無法檢驗此郵箱地址是否已被註冊。請您稍後再試。',
  teacherRegistrationSuccess: '帳號驗證郵件已發送至 {0}. 請依照郵件內的指示驗證您的帳號。',
  candidateRegistrationSuccess: '創建新帳號成功。請登錄。',
  accountRegistrationFail: '無法註冊新帳號。請稍後再試或聯繫系統管理員。',
  //   END ---- actions::RegisterActions

  // START ---- components::CandidateHomeScreen
  uploadPhotoBeforeEnrol: '請在報考考試或申請級數評估前上傳個人頭像。',
  homeScreenGreeting: '今天過得好嗎？',
  logoutConfirmation: '請問您確認退出嗎？',
  needHelpWithTraining: '需要培訓方面的輔助？\n在優伯教學上預訂您的課程吧！',
  //   END ---- components::CandidateHomeScreen

  // START ---- actions::UserActions
  teacherIdDoesNotExist: '老師證編碼不存在。',
  abnWrongFormat: 'ABN必須為11位數字',
  unableToCheckTeacherId: '無法驗證老師證編碼。請您稍後再試。',
  updateProfileSuccess: '您已成功更新個人資料。',
  updateProfileFail: '更新個人資料失敗。請您稍後再試。',
  studentUpdateProfileSuccess: '您已成功更新此學生的個人資料。',
  studentUpdateProfileFail: '無法更新此學生的個人資料。請您稍後再試。',
  pleaseSelectDateOfBirth: '請選擇您的出生日期。',
  pleaseSelectYourGender: '請選擇您的性別。',
  emailsDoNotMatch: '新郵箱地址與確認郵箱地址不匹配。',
  identicalToTheCurrentEmail: '與目前的郵箱地址相同。',
  emailChangeSuccess: '您已成功更換您的郵箱地址。請您在下一次登錄時使用{0}。',
  emailChangeFail: '無法更換郵箱地址。請您稍後再試或聯繫系統管理員。',
  exitEdit: '如果您現在退出，您的選項並不會被保留',
  exitWizard: '如果您現在退出，您的選項並不會被保留',
  //   END ---- actions::UserActions

  // START ---- components::TnCScreen
  agreeToTermsAndCondition: '我同意並接受所有的條款與條件。',
  //   END ---- components::TnCScreen

  // START ---- components::VideoGalleryScreen
  confirmDeletevideo: '您確定刪除此視頻？',
  youCanChooseExamVideoRes: '您可以選擇480p或720p作爲視頻分辨率。然而，720p的視頻將需要更長的上傳時間。',
  //   END ---- components::VideoGalleryScreen

  // START ---- actions::ExamActions
  enterAValidVideoLink: '請輸入有效的視頻鏈接。（來自受支持的視頻寄存服務網站）',
  examDetailsSaveSuccess: '考試詳情儲存成功。',
  examDetailsSaveFail: '考試詳情儲存失敗。請稍後再試或聯繫系統管理員。',
  pleaseSelectAMusicType: '請選擇曲目類型。',
  pleaseSelectAMusicTitle: '請選擇曲目名字。',
  photoIdIsNeededForDiploma: '文憑級別的考試需要證件照。',
  examSubmitSuccess: '提交考試成功。',
  examSubmitFail: '提交考試失敗。請您稍後再試或聯繫系統管理員。',
  initiatePaymentGatewayFail: '無法啓動支付網關。請您稍後再試。',
  getLatestPaymentInfoFail: '無法索取最新的付款資料。請您稍後再試。',
  getUpdatedExaminationPriceFail: '無法索取最新的考試報名費。請您稍後再試。',
  affiliationCodeNotValid: '附屬代碼無效。',
  checkAffiliationCodeFail: '無法驗證附屬代碼。請您稍後再試。',
  pleaseSelectAnInstrument: '請選擇樂器。',
  pleaseSelectAGrade: '請選擇級別。',
  paymentAmountNotUpToDate: '無法更新付款銀額。請重新報考考試。',
  enrolExamSuccess: '您已成功付款並報考考試。',
  enrolExamFail: '報考考試失敗。請您稍後再試或聯繫系統管理員。',
  enrolExamNotYetProcessed: '您已成功付款，但是系統還在覈實此交易中。請稍後再查看您的付款歷史記錄以及考試列表。若您的付款歷史記錄在一個小時後還未更新，請聯繫系統管理員。',
  enrolmentPaymentGatewayError: '支付網關出現錯誤。請重新再試或聯繫系統管理員。',
  examReportSentEmailSuccess: '您的考試報告已發送至{0}。',
  examReportSentEmailFail: '無法發送您的考試報告至{0}。請您稍後再試或聯繫系統管理員。',
  examCertSentEmailSuccess: '您的考試證書已發送至{0}。',
  examCertSentEmailFail: '無法發送您的考試證書至{0}。請您稍後再試或聯繫系統管理員。',
  appealSubmitSuccess: '您已成功提交上訴請求。',
  appealSubmitFail: '無法提交上訴請求。請您稍後再試或聯繫系統管理員。',
  appealPaymentGatewayError: '支付網關出現錯誤。請重新申請上訴請求或聯繫系統管理員。',
  purchaseCertSuccess: '您已成功購買證書。',
  purchaseCertFail: '無法購買證書。請您稍後再試或聯繫系統管理員。',
  pleaseSelectACandidate: '請選擇學生。',
  videoUploadSuccess: '您已成功上傳您的視頻。視頻鏈接是 {0}',
  vidUploaded: '您已成功上傳您的視頻',
  videoUploadFail: '無法上傳您的視頻。請稍後再試或聯繫系統管理員。',
  pleaseSelectVideo: '請選擇您要上傳的視頻。',
  youHaveNotUploadedAnyVideo: '您未為此曲目上傳任何視頻。',
  youHaveNotUploadedThisVideo: '您未上傳此視頻。',
  uploadVideoBeforeSaving: '請您在儲存考試之前上傳此視頻。若您只想單單儲存其他考試詳情，請删除此視頻。',
  //   END ---- actions::ExamActions

  //  START ---- actions::SyllabusActions
  musicPieceExistInSystem: '這曲目已收錄在此係統中。',
  checkMusicPieceFail: '現在無法驗證曲目。請您稍後再試。',
  pleaseIncludeMusicSheet: '請附上曲目樂譜的照片。',
  musicPieceSubmitSuccess: '您的新曲目申請已提交成功。',
  musicPieceSubmitFail: '新曲目申請失敗。請您稍後再試或聯繫系統管理員。',
  //   END ---- actions::SyllabusActions

  //  START ---- components::StudentProfileEditScreen
  confirmRemoveStudentFromList: '您確定將此學生從您的學生列表上删除嗎？',
  removeStudentSuccess: '您已成功删除此學生。',
  removeStudentFail: '無法删除此學生。請您稍後再試或聯繫系統管理員。',
  unsavedChangesWillBeLost: '此屏幕擁有未儲存的更改，退出此屏幕後所有更改將消失。請問您確認退出此屏幕嗎？',
  //    END ---- components::StudentProfileEditScreen

  //  START ---- components::EditExamScreen/SummaryTab
  photoIdRequirement: '報考文憑級別的學生們必須上傳一份證件照（所接受的證件包括身份證、學生證或駕駛執照）。',
  additionalRequirement1: '請確保您三首曲目演奏的時間是總共至少{0}分鐘或以上。',
  additionalRequirement2: '我們將接受以兩首曲目合併爲一個視頻的方式來達到最低演奏時間的條件。在這種情況下，一共有四首曲目將會被評估。然而，其中兩首較短曲目的權重將被視爲一首。',
  additionalRequirement3: '您若提交超過三首曲目，請在下方的備註另行註明。',
  //    END ---- components::EditExamScreen/SummaryTab

  //  START ---- actions::CommonActions
  pleaseUpdateApp: '您的APP版本太舊了，請更新您的APP以繼續使用。謝謝您。',
  versionCheckFail: '暫時無法驗證您的APP版本。請您重新啟動APP或聯繫系統管理員。',
  somethingWrongPleaseRestartAppOrLoginAgain: '不好意思，系統出了狀況。請重啓APP或重新登錄。',
  sessionHasExpiredPleaseRelogin: '您的有效使用時段過期了，請重新登錄。',
  //   END ---- actions::CommonActions

  // START ---- components::BrowseMusicLibraryScreen
  enterAtLeastThreeChar: '請輸入至少三個字符',
  enterAtLeastTwoChar: '請輸入至少兩個字符',
  //   END ---- components::BrowseMusicLibraryScreen

  //  START ---- components::MusicLibraryDetailsScreen
  videoForThisPieceIsUnavailable: '此曲目無視頻可播。',
  updateEmailBeforePurchase: '請在購買曲譜之前添加有效的電子郵件地址。我們將把曲譜發至您的郵箱。',
  thisIsASoftcopySheetMusic: '這是電子曲譜。處理好您的訂單後，我們將把曲譜發至您的郵箱。',
  //    END ---- components::MusicLibraryDetailsScreen

  //  START ----- components::AccountUpgradeChooseRoleScreen
  upgradeAccountMessage: '爲了報考考試以及享用更多的功能，您必須完成您的個人資料。',
  //    END ----- components::AccountUpgradeChooseRoleScreen

  //  START ----- components::AccountUpgradeChooseRoleScreen
  pleaseProvideEmailQuickLogin: '看來您未允許讓我們獲取您Facebook帳號的郵箱地址。但是爲了創建帳號，我們仍需要您的郵箱地址。',
  pleaseProvideEmailQuickLoginWX: '如果您用微信帳號登錄，為了創建帳號，我們需要您的郵箱地址。',
  //    END ----- components::AccountUpgradeChooseRoleScreen

  //  START ----- components::SocialMediaPasswordScreen
  linkFbAccountMessage: '您剛輸入的郵箱地址已經存在於系統中。若您欲連接此郵箱地址的帳號與您的Facebook帳號，我們需要確認此帳號是否是您的。爲了安全起見，請輸入密碼並登錄。',
  //  END ----- components::SocialMediaPasswordScreen

  // START ---- actions::PieceEvaluationActions
  pieceEvaluationReportSentEmailSuccess: '您的級數評估報告已發送至{0}。',
  pieceEvaluationReportSentEmailFail: '無法發送您的級數評估報告至{0}。請您稍後再試或聯繫系統管理員。',
  requestPerformanceEvaluationSuccess: '您已成功付款並申請級數評估。',
  requestPerformanceEvaluationFail: '申請級數評估失敗。請您稍後再試或聯繫系統管理員。',
  requestPerformanceEvaluationNotYetProcessed: '您已成功付款，但是系統還在覈實此交易中。請稍後再查看您的付款歷史記錄以及級數評估列表。若您的付款歷史記錄在一個小時後還未更新，請聯繫系統管理員。',
  performanceEvaluationDetailsSaveSuccess: '級數評估詳情儲存成功。',
  performanceEvaluationDetailsSaveFail: '級數評估詳情儲存失敗。請稍後再試或聯繫系統管理員。',
  performanceEvaluationDetailsSubmitSuccess: '提交級數評估成功。',
  performanceEvaluationDetailsSubmitFail: '提交級數評估失敗。請您稍後再試或聯繫系統管理員。',
  youHaveNotSelectedAnyVideo: '您未選擇任何視頻。',
  //   END ---- actions::PieceEvaluationActions

  // START ---- component::LandingScreen
  confirmLoginAsAdmin: '是否確認登錄爲系統管理員？',
  loginAsAdminFail: '無法登錄爲系統管理員。請您稍後再試或聯繫系統管理員。',
  confirmLoginAsSupport: '是否確認登錄爲客服？',
  loginAsSupportFail: '無法登錄爲客服。請您稍後再試或聯繫系統管理員。',
  backToTeacherFail: '無法返回帳號界面，請您稍後再試',
  confirmBackToTeacher: '確認要回到您的帳號嗎?',
  pleaseEnterValidMobileNumber: '請輸入您的手機號碼以完成帳號註冊。',
  updateMobileNumSuccess: '您已成功更新您的手機號碼。',
  updateMobileNumFail: '無法更新您的手機號碼。請稍後再試。',
  approvedTeachers: '所有經由優伯平台所推薦的老師都是透過層層篩選及在線師資培訓過的優質老師。許許多多學員的傲人成績就是我們最好的口碑！ ',
  customerService: '優伯提供24小時專屬教務客服，為家長和老師們解讀各種學習上和技術上的問題。 ',
  safePayment: '安全快捷的在線支付，領先的高科技反欺詐技術和用戶保障服務，為每一筆優伯學費提供風險保障',
  withdrawalGuarentee: '學員及老師可以隨時提出餘額取款要求。平均在6個小時內即可打款到用戶微信/支付寶帳號上',
  noMatchFound: '抱歉, 未找到相關結果',
  shareYourPerformance: '與你的朋友和家人分享',
  //   END ---- component::LandingScreen

  // START ---- component::LandingScreen/AssessTab
  tapToAccessMyExams: '請點擊此處報考或查看您的考試詳情！',
  //   END ---- component::LandingScreen/AssessTab

  // START ---- actions::EventActions
  eventEnrolmentSuccess: '您已成功報名此項目，請按繼續以上傳您的演奏視頻。',
  eventEnrolmentFail: '報名項目失敗。請您稍後再試或聯繫系統管理員。',
  saveEventEnrolmentDetailSuccess: '項目報名詳情儲存成功。',
  saveEventEnrolmentDetailFail: '項目報名詳情儲存失敗。請稍後再試或聯繫系統管理員。',
  submitEventEnrolmentDetailSuccess: '提交項目報名成功。',
  submitEventEnrolmentDetailFail: '提交項目報名失敗。請您稍後再試或聯繫系統管理員。',
  pleaseAttachYourPhotoId: '請附上您的證件照。',
  pleaseSelectAGenre: '請選擇曲風。',
  pleaseAttachYourPerformanceVideo: '請附上您的演奏視頻。',
  //   END ---- actions::EventActions

  // START ---- component::CandidateNewEventEnrolmentScreen
  eventAffiliateCodeInfo: '爲了能夠參加此項目獨家的造勢活動以及有關的演奏會，請掃一掃二維碼或輸入我們合作伙伴的附屬代碼。',
  //   END ---- component::CandidateNewEventEnrolmentScreen

  // START ---- component::AddAffiliateCodeScreen
  checkAffiliateCodeFail: '無法驗證附屬代碼。請您稍後再試或聯繫系統管理員。',
  affiliateCodeIsSuccessfullyApplied: '成功添加附屬代碼“{0}”！',
  affiliateCodeIsInvalid: '附屬代碼無效。',
  //   END ---- component::AddAffiliateCodeScreen

  // START ---- actions::CertificateActions
  certUploadSuccess: '證書上傳成功。管理員審查證書後將提升您的用戶等級。',
  photoUploadSuccess: '您已成功上傳成就照片。',
  certUploadFail: '證書上傳失敗。請您稍後再試或聯繫系統管理員。',
  photoUploadFail: '成就照片上傳失敗。請您稍後再試或聯繫系統管理員。',
  //   END ---- actions::CertificateActions

  // START ---- components::UserLevelInfoListScreen/UserLevelInfoListScreen
  userLevelInfoMessage: '您可通過以下方式升級用戶等級：\n  - 上傳各類相關評定等級證書。\n  - 通過此APP報考並完成CBE音樂考級。\n\n在通過管理員審覈確認後，將跟據以下圖示升級用戶等級。',
  //   END ---- components::UserLevelInfoListScreen/UserLevelInfoListScreen

  // START ---- components::EventDetailSwiperScreen/EventDetailSwiperScreen
  thereIsNoEventsCurrently: '目前還未有項目進行中。',
  //   END ---- components::EventDetailSwiperScreen/EventDetailSwiperScreen

  // START ---- actions::PaymentActions
  paymentReceiptSentEmailSuccess: '您的收據已發送至{0}。',
  paymentReceiptSentEmailFail: '無法發送您的收據至{0}。請您稍後再試或聯繫系統管理員。',
  //   END ---- actions::PaymentActions

  // START ---- components::LandingScreen/LandingTab/ProfileDetailVideoTab/MyVideoTab
  youHaveNotUploadedAnyVideoEnrolExamOrEvent: '您未上傳任何視頻。今天就報名參加項目或報考考試並上傳您的演奏視頻吧！',
  //   END ---- components::LandingScreen/LandingTab/ProfileDetailVideoTab/MyVideoTab

  // START ---- components::EventEnrolmentListScreen
  youHaveNotEnrolIntoAnyEvents: '您未報名參加任何項目。今天就報名吧。別錯過哦！',
  //   END ---- components::EventEnrolmentListScreen

  // START ---- components::EventDetailScreen
  uploadPhotoBeforeEnrolEvent: '請在報名參加項目前上傳個人頭像。',
  //   END ---- components::EventDetailScreen

  // START ---- actions:VideoActions
  contentNotFound: '內容不存在。',
  unableToLoadContent: '無法載入內容。請您稍後再試或聯繫系統管理員。',
  //   END ---- actions:VideoActions

  // START ---- components::WantToTeachScreen
  noSelection: '請至少選擇一個選項',
  noABN: '情確認您的ANB和GST',
  noCert: '請上傳至少一張證書',
  createdTeachProfile: '恭喜！您的老師主頁已創建成功。',
  createdProfileFailed: '創建您的老師主頁失敗，請稍候再試。',
  deleteCertificate: '您確定要刪除此證書嗎',
  //   END ---- components::WantToTeachScreen

  // START ---- components::TeachProfileScreen
  noProfile: '未完成',
  verified: '已通過審核',
  awaiting: '正在審核中',
  rejected: '審核失敗',
  deactivated: '審核無效',
  deleteAchievement: '您確定要刪除此成就嗎？',
  somethingWrong: '發生未知錯誤，請稍候再嘗試。',
  deletePrivateStudent: '您確定要刪除此學生嗎?',
  //   END ---- components::TeachProfileScreen

  // START ---- components::LearnProfileScreen
  failedToGetStudentQuestion: '獲取問題失敗',
  uploadProfilePhoto: '請上傳投降照片',
  failedToCreateLearnProfile: '創建您的學生主頁失敗，請稍候嘗試。',
  createdLearnProfile: '恭喜！您的學生主頁已創建成功。',
  //   END ---- components::LearnProfileScreen

  // START ---- components::TopUpFundScreen
  topUpInfo: '充值金額必須介於{0}{1}至{0}{2}。',
  //   END ---- components::TopUpFundScreen

  // START ---- actions::FundActions
  topUpFundSuccess: '您已成功充值{0}{1}{2}。',
  topUpFundFail: '充值學習比失敗。請您稍後嘗試或聯繫管理員。',
  unableToTopUpFund: '暫時無法充值',
  topUpPaymentProcessing: '您已成功付款，我們會為您盡快處理此交易。請稍後再次查看您的交易記錄。如果1小時後付款仍未記錄，請與系統管理員聯繫。',
  addPaymentMethodSuccess: '創建提款方式成功。',
  addPaymentMethodFail: '暫時無法創建提款方式。請您稍後再試或聯繫系統管理員。',
  //   END ---- actions::FundActions

  // START ---- components::CreateLessonScreen
  thisWillDetermineYourLessonDuration: '請您仔細確認每節課的時間。',
  thisWillDetermineYourMinimumLessonDuration: '請設定您每一節課的最短時段。',
  tellUsMoreAboutYourLesson: '告訴我們更多關於您將要設置的課程。課程名稱和介紹將幫助學生們了解您在課程中提供的內容。',
  lessonFeeForEachSession: '課程結束後，該金額將記入您的帳戶。',
  lessonRateInfo: '請根據您當地稅收法律的指定加上GST或VAT等來設定您的課程學費。YOUBRIO將一律不負責老師的納稅申報。收費模式將會以計時收費。課程結束後，該金額將記入您的帳戶。',
  reviewStudentInfo: '當學生預約您的課程時，您可以選擇接受或拒絕此課程。',
  youCanSetMaximumSlot: '您可以為本課程設置最多{0}個時段。',
  byProceedYouAgreeToTnC: '* 如果繼續操作，即表示您同意YOUBRIO的條款和條件。',
  youAreUnableToAlterLessonSettingDueToOrgPolicy: '由於 {0} 的學校政策，您無法更改一些課程設置。',
  lessonFeeDpositedWhenLessonComplete: '*課程結束後課時費會及時到您的優伯錢包裏！',
  noStudentAdded: '你還未添加任何學生',
  theBookingWillBeMadeOnBehalfOfTheStudentAutomatically: '這是一堂免費的課程，系統將自動爲請求課程的學生預約這堂課。是否繼續？',
  thisStudentIsUnableToBookFreeLessonFromYou: '這位學生無法跟您預約免費課程。',
  someOfYourStudentsAreUnableToBookFreeLessonFromYou: '部分已選的學生無法跟您預約免費課程，您的通知列表已重置。',
  //   END ---- components::CreateLessonScreen

  // START ---- actions:LessonActions
  createNewLessonSuccess: '您已成功創建一堂新課程。',
  createNewLessonFail: '無法保存新課程。請稍後再試或聯繫系統管理員。',
  bookLessonSuccess: '您已成功預訂課程。',
  bookLessonFail: '無法預訂課程。請稍後再試或聯繫系統管理員。',
  createNewChangeRequestSuccess: '您已成功發送更改課程請求。',
  createNewChangeRequestFail: '無法發送您的請求。請稍後再試或聯繫系統管理員。',
  deleteChangeRequestSuccess: '您已成功撤銷了您的課程更改請求。',
  deleteChangeRequestFail: '無法撤銷您的請求。請稍後再試或聯繫系統管理員。',
  approveChangeRequestSuccess: '您已批准了此課程更改請求。請留意已更新的上課時間。',
  approveChangeRequestFail: '無法批准此請求。請稍後再試或聯繫系統管理員。',
  rejectChangeRequestSuccess: '您已拒絕了此課程更改請求。',
  rejectChangeRequestFail: '無法拒絕此請求。請稍後再試或聯繫系統管理員。',
  pleaseDeleteChangeRequestForNew: '您目前有一個待定的課程更改請求。若您想發送新的請求，請先撤銷目前的請求。',
  pleaseRespondChangeRequestForNew: '您目前有一個待定的課程更改請求。請先回應目前待定的請求。',
  //   END ---- actions:LessonActions

  // START ---- actions::YoubrioProfileActions
  somethingWentWrongPlsTryLtr: '抱歉！發生錯誤。請稍後再試。',
  //   END ---- actions::YoubrioProfileActions

  // START ---- components::BookLessonScreen
  requestRequiredLesson: '老師將會查看到您的個人主頁。',
  requestRequiredLessonDetail: '預約課程操作完成，系統將進行預授權扣款。老師確認並接受該預約課程時，預授權自動轉成實際付款。如遇老師拒絕，款項自動返回至您的錢包。',
  youWillPayWithYourStudyWallet: '在預訂課程成功後，將從您的學習錢包中扣除總費用。',
  youHaveInsufficientWalletBalance: '您的學習錢包餘額不足，請充值再繼續。',
  letYourTeacherKnowYourLessonGoals: '請讓您的老師知道您的學習目標以及您想學的內容。老師將會以您的要求備課並進行教學。',
  //   END ---- components::BookLessonScreen

  // START ---- components::TeacherLessonBookingDetailScreen
  lessonComplete: '請問您想做什麼?',
  giftReceived: '收到禮物',
  noGift: '這節課您尚未收到任何礼物',
  areYouSureRejectLesson: '您確定要拒絕此預訂嗎？此操作無法撤消。',
  areYouSureCancelLesson: '您確定要拒絕此預訂嗎？此操作無法撤消。',
  areYouSureLateCancelLesson: '您確定要取消此預訂嗎？您將無法索取退款因爲這是臨時取消。',
  cancelLessonInfo: '您可以在課程開始前的{0}個小時取消並索取退款。若超越時間後臨時取消， 您將無法索取退款。',
  pleaseGrantPermissionsForLesson: '請授權YOUBRIO所需要的權限以確保您的課程能夠正常的進行。請到APP設定開通權限。',
  pleaseGrantPrerequiredPermissionsForLessonAndroid: '請授權YOUBRIO使用相機、麥克風及儲存空間以確保您的課程能夠正常的進行。請到APP設定開通權限。',
  pleaseGrantPrerequiredPermissionsForLessonIos: '請授權YOUBRIO使用相機、麥克風及相冊以確保您的課程能夠正常的進行。請到APP設定開通權限。',
  dualDeviceInfo: '雙設備模式只在您的第二臺設備開啓智能板，從而提高視頻質量並改善您的上課體驗！',
  //   END ---- components::TeacherLessonBookingDetailScreen

  // START ---- components::LandingScreen/CompleteProfileBlockerModal
  completeProfile: '若要使用此功能，您需要完成個人資料',
  completeProfileStartRemoteLesson: '完成您的個人資料\n開始在線教學和考級',
  //   END ---- components::LandingScreen/CompleteProfileBlockerModal

  // START ---- components::TeacherEditLessonScreen
  updateLessonSuccess: '您的課程已成功更新。',
  updateLessonFail: '無法更新您的課程。請稍後再試或聯繫系統管理員。',
  thisLessonIsUnlisted: '本課不公開。它對公眾不可見，您將不會收到本課程的任何預訂。',
  thisLessonIsListed: '此課程已列出，公眾可以預訂。',
  areYouSureUnlistLesson: '您確定要取消此課程嗎？您將無法獲得本課程的任何新預訂。您可以稍後再重新使用它。 （這不會影響本課程的所有現有預訂。）',
  areYouSureDeleteLesson: '您確定要刪除此課程時段嗎？（這不會影響此時段的所有現有預訂。）',
  deleteLessonSuccess: '課程已成功刪除。',
  deleteLessonFail: '無法刪除課程。請稍後再試或聯繫系統管理員。',
  youHaveNotCreatedAnyLesson: '您還未創建任何課程。',
  //   EventDetailSwiperScreen ---- components::TeacherEditLessonScreen

  // START ---- components::MyLessonTeacherListScreen
  youHaveBookedAnyLessonWithAnyTeacher: '您沒有與任何老師預約任何課程。',
  noTeacherFound: '您沒有老師，是否前往搜索老師？',
  //   END ---- components::MyLessonTeacherListScreen

  // START ---- components::MyLessonStudentListScreen
  youDontHaveAnyStudentYet: '您還沒有學生。\n點擊並添加學生。',
  youHaveNoFavouriteUserYet: '您未收藏任何用戶。',
  //   END ---- components::MyLessonStudentListScreen

  // START ---- components::ProfilePreviewScreen
  bookStudentIsOnlyForStudent: '請另外申請一個學生帳號以預訂課程。',
  notOfferingAnyLesson: '這個老師目前還未提供任何課程。你是否要发送一个课程请求？',
  wouldYouWantToAddSomeoneAsYourRole: '請問您是否要添加{0}爲您的{1}？',
  someoneIsYourRole: '{0}是您的{1}。',
  youAreUnableToAddThisUser: '您無法添加此用戶。',
  youHaveAlreadyAddedThisUser: '您已添加此用戶。',
  wouldYouLikeToAddThisTeacherAsFavourite: '請問您是否要加這位老師入收藏名單？',
  //   END ---- components::ProfilePreviewScreen

  // START ---- components::ManageMyLessonScreen
  youHaveNoUpcomingLessons: '您暫時沒有即將開始的課程。',
  //   END ---- components::ManageMyLessonScreen

  // START ---- components::TeacherLessonBookingDetailScreen
  lessonRoomAllowedTimeInfo: '您可以在此課程開始前的{0}分鐘進入教室。',
  completeLessonAllowedTimeInfo: '您可以在此課程開始時間後{0}分鐘完成課程。',
  completeLessonTimeExactInfo: '您只能在此課程結束後才能完成課程。',
  areYouSureCompleteLesson: '完成此課程後，課程費用將轉入您的錢包。完成課程之後，您將無法進入教室。',
  completeLessonNow: '你想要完成這個課程嗎?完成之後，您將無法進入教室。',
  //   END ---- components::TeacherLessonBookingDetailScreen

  // START ---- components::LessonSessionActions
  itsTooEarlyToEnterLessonRoom: '現在進入教室還為時過早。',
  lessonTimeEndedUnableToEnterRoom: '由於課程時間已結束，因此您無法進入教室。',
  unableToEnterLessonRoom: '無法進入教室。請稍後再試或聯繫系統管理員。',
  pleaseCompleteLearnProfile: '請把您的個人資料填寫完整並註冊為學生後再預定課程。',
  //   END ---- components::LessonSessionActions

  // START ---- components::ProfileDetailTab
  uploadCertToTeach: '上傳你的證書並完整你的個人主頁可獲得更多曝光率',
  noReview: '嗯。看來你暫時還沒有任何評論',
  tapToRefresh: '點擊屏幕刷新',
  tapHereToUploadCert: '點擊此處以上傳證書',
  //   END ---- components::ProfileDetailTab

  // START ---- components::TopUpPaymentMethodScreen
  serviceOnlyAvailableForChinaVerifiedUser: '此服務只限於中國身份證實名認證過的支付寶帳號使用。',
  unableToGetExchangeRate: '無法索取匯率。',
  //   END ---- components::TopUpPaymentMethodScreen

  // START ---- components::FundWithdrawScreen
  withdrawInfo: '每一次提現必須介於 {0}{1} - {0}{2} 之間，錢包也必須要有足夠的餘額。',
  serviceFee: '請注意每筆取款將會收取 {0}% 的服務費',
  //   END ---- components::FundWithdrawScreen

  // START ---- component::FundWithdrawAddPaymentMethodScreen
  confirmAllInfoAreCorrect: '我在此確認以上所填寫的資料都是正確的。',
  addPaymentMethodInfo: '請確保所填寫的資料都是正確的。',
  fullNameMustBeCorrect: '所輸入的全名必須與登記在此付款平臺帳號的全名匹配。爲了保護我們的用戶，我們將拒絕任何有錯誤資料的提款請求。',
  invalidPhoneOrEmail: '此手機號碼/電子郵件地址無效。',
  invalidNumber: '輸入此數字無效',
  enterValidBankAccNo: '銀行戶口號碼無效。',
  pleaseEnterValidBSB: 'BSB必須要有6個字符。',
  pleaseEnterValidIBAN: '請輸入有效的國際銀行帳戶號碼（IBAN）。',
  pleaseEnterValidSWIFT: '請輸入有效的SWIFT/BIC代碼。',
  //   END ---- component::FundWithdrawAddPaymentMethodScreen

  // START ---- component::FundWithdrawPaymentMethodListScreen
  noPaymentMethodFound: '無提款方式。',
  allowedUpToNPaymentMethod: '您只能夠儲存{0}個提款方式。欲添加新的提款方式，請刪除舊的提款方式。',
  //   END ---- component::FundWithdrawPaymentMethodListScreen

  // START ---- component::WithdrawalSummaryScreen
  pleaseVerifyYourself: '此交易需要覈實身份，請輸入本帳號的密碼。',
  makeWithdrawalSuccess: '創建提款請求成功。提款手續處理完畢後我們會通知您。',
  makeWithdrawalFailed: '創建提款請求失敗。請您稍後再試或聯繫系統管理員。',
  enteredIncorrectPassword: '您輸入了錯誤的密碼。',
  thisOnlyAppliesForAusUser: '以上只供澳洲用戶填寫。',
  //   END ---- component::WithdrawalSummaryScreen

  // START ---- component::FundWithdrawalListScreen
  balanceInsufficient: '錢包餘額不足，提款最低數額爲 {0}。',
  youHaveNotMadeWithdrawal: '您未提交任何提款請求。',
  //   END ---- component::FundWithdrawalListScreen

  // START ---- component::WizardResultScreen
  couldNotFindMatchingResult: '無匹配的結果。',
  //   END ---- component::WizardResultScreen

  //  START ---- components::BrowseLessonListScreen
  teacherNoOfferLesson: '這位老師目前沒有教任何的課程。',
  //    END ---- components::BrowseLessonListScreen

  //  START ---- components::WriteReviewScreen
  exitReview: '你確定不想提交評價嗎？將會丟失修改內容。',
  reviewCommentPH: '告訴我們多一些你的評價...',
  noRating: '你還沒有給任何評分',
  reviewSuccess: '評分提交成功',
  reviewFail: '評分提交失敗，請稍後再試',
  //    END ---- components::WriteReviewScreen

  //  START ---- components::ViewReviewScreen
  getReviewFailed: '獲取評論失敗，請稍後再嘗試',
  //    END ---- components::ViewReviewScreen

  // START ---- component::BrioWalletSummaryScreen
  pleaseCompleteProfileBeforeTopUp: '請把您的個人資料填寫完整並註冊為學生後再充值錢包。',
  //   END ---- component::BrioWalletSummaryScreen

  // START ---- component::SchoolIntroScreen
  doYouOwnASchool: '您是否擁有學校或教育性的機構？把它登記在YOUBRIO並讓您的學生及老師都加入！',
  benefitsOfSchool: '當您的學生向您的老師預定課程時，學生們可享有羣組折扣。',
  searchForSchool: '您也可以尋找並加入學校。羣組的力量是很大的，一起與其他會員攜手達到更高的成就！',
  studentJoinSchoolInfo: '現在就加入學校以發掘更多老師。當您預定同學校的老師的課程時，您可享有學校所提供的折扣！',
  //   END ---- component::SchoolIntroScreen

  // START ---- component::SchoolProfileScreen
  getSchoolProfileFailed: '無法獲取學校簡介。請您稍後再試或聯繫系統管理員。',
  saveSchoolProfileFailed: '無法儲存學校簡介。請您稍後再試或聯繫系統管理員。',
  saveSchoolProfileSuccessful: '您已成功更新學校簡介。',
  yearFormatError: '請輸入正確的年份格式。',
  yearInvalid: '請輸入有效年份。',
  //   END ---- component::SchoolProfileScreen

  // START ---- component::CreateSchoolScreen
  applySchoolSuccess: '您已成功提交您的學校申請。當系統管理員批准您的申請時，公衆可參閱您學校的簡介。',
  applySchoolFail: '無法提交您的申請。請您稍後再試或聯繫系統管理員。',
  pleaseEnterValidYear: '請輸入有效年份。',
  commissionInfo: '當您的學生向您的老師預定課程時（雙方都是您學校的成員），此百分比的學費將作爲學校推薦的佣金。\n\n例子，如果佣金百分比是15%以及學費是¥100，¥15將會作爲學校推薦的佣金。',
  newOrgCommissionInfo: '當您學校的老師完成課程時，這是學校將會收到的默認提成。若想調節提成率，您可以在學校設置界面內委任老師至不同的學費分類。',
  discountInfo: '當您的學生向您的老師預定課程時（雙方都是您學校的成員），學生獲得此百分比的折扣。\n\n例子，如果折扣百分比是5%以及學費是¥100，學生只需付¥95的學費。',
  profitInfo: '佣金百分比是包括學生的折扣百分比，所以最終的盈利百分比是（佣金百分比）-（折扣百分比）。\n\n例子，如果佣金百分比是15%，折扣百分比是5%以及學費是¥100，您的學校將獲得¥10，因爲學生獲¥5的折扣。',
  makeSureAllSchoolInfoCorrect: '請確保所填寫的資料都是正確的。系統管理員有權利拒絕任何含有不完整或不正確資料的請求。',
  //   END ---- component::CreateSchoolScreen

  // START ---- component::ManagingSchoolListScreen
  youAreNotManagingAnySchoolORg: '您未管理任何學校。',
  //   END ---- component::ManagingSchoolListScreen

  // START ---- component::SchoolTeacherListScreen
  noTeachersFound: '未找到任何老師。',
  noTeachersNewRequest: '未找到任何相關老師，請點擊“老師請求” 優伯教學就會幫您安排最合適的老師',
  areYouSureYouWantToRemoveThisMember: '您是否確認要把此會員從學校删除？',
  //   END ---- component::SchoolTeacherListScreen

  // START ---- component::SchoolStudentListScreen
  noStudentsFound: '未找到任何學生。',
  //   END ---- component::SchoolStudentListScreen

  // START ---- component::SchoolPendingMembershipListScreen
  noPendingJoinRequestFound: '未找到任何待批准的加入請求。',
  areYouSureYouWantToDeclineRequest: '您是否確認要拒絕此加入請求？',
  areYouSureYouWantToBlacklistRequest: '您是否確認要列入黑名單？被列入黑名單的老師以及學生都無法搜索與加入此學校。',
  //   END ---- component::SchoolPendingMembershipListScreen

  // START ---- component::SchoolBlacklistedMembershipListScreen
  blacklistInfo: '被列入黑名單的老師以及學生都無法搜索與加入此學校。',
  youHaveNotBlacklistedAnyone: '您未列任何人入黑名單。',
  //   END ---- component::SchoolBlacklistedMembershipListScreen

  // START ---- component::SchoolProfilePreviewScreen
  areYouSureWantToLeave: '您是否確認要退出此學校嗎？',
  joinOneSchoolAtATime: '老師們一次只許加入一間學校。請在加入新的學校前退出\'{0}\'。',
  completeProfileBeforeJoiningOrg: '請在加入任何學校之前完成您的個人簡介。',
  comNotice: '當你教此學校的學生時，學校會收取 {0}% 作為手續費。',
  //   END ---- component::SchoolProfilePreviewScreen

  // START ---- component::SchoolAdminProfileScreen
  wouldYouWantCreateFeeCategory: '您尚未创建任何学费类别。您可以委任老师至不同的学费类别以更有效地管理老师的课程收费以及个性化学费提成率。是否现在前往创建学费类别？',
  //   END ---- component::SchoolAdminProfileScreen

  // START ---- component::SchoolAdminProfileScreen/SchoolProfileBlockerModal
  schoolIsDeactivated: '您的學校簡介因觸犯了YOUBRIO的用戶條規已被註銷了。請您聯繫系統管理員以採取下一步行動。',
  schoolPendingApproval: '您的學校簡介還未經系統管理員審覈及批准。',
  schoolIsRejected: '您的學校簡介已被系統管理員拒絕。請您聯繫系統管理員以採取下一步行動。',
  youMayDeleteYourOrgAndReapply: '您可以刪除此學校申請並再重新提交新的申請。',
  //   END ---- component::SchoolAdminProfileScreen/SchoolProfileBlockerModal

  // START ---- actions::OrganisationActions
  unableToDeleteOrganisation: '無法刪除申請。請您稍後再試或聯繫系統管理員。',
  //   END ---- actions::OrganisationActions

  // START ---- component::AnnotationDocumentScreen
  changesWillBeGoneWithoutSaving: '請確認您已儲存所需要的智能板笔记。您確認要退出？（若您只是想關閉此標籤，請點擊並按住下方的標籤。）',
  confirmCloseTab: '您確定要關閉此頁？您將無法重新加載此頁。',
  iosAnnotationSaveSuccessfully: '成功儲存此智能板笔记至您的相冊。',
  androidAnnotationSaveSuccessfully: '成功儲存此智能板笔记至 {0}。',
  annotationSaveFailed: '無法儲存此智能板笔记。 請您稍後再試或聯繫系統管理員。',
  tapToAddSheetMusicForAnnotation: '點擊此處以上傳圖片並添加新頁面。',
  tapToAddBlankPage: '點擊此處以開啓一個新的空白頁。',
  writeExternalPermission: '請授權YOUBRIO使用您的儲存空間，否則您將無法儲存智能板的筆記。',
  confirmClearSmartboard: '您確定要刪除所有筆畫？您將無法再加載所刪除的筆畫。',
  tabRemovedDoYouWantToSave: '此頁將永久被移除。請問您是否要保存？',
  //   END ---- component::AnnotationDocumentScreen

  // START --- component::CalendarSlotPickerScreenV2
  pleaseRearrangeYourAdjacentTimeSlot: '老師設定的最短時段是{0}分鐘。 請移除相鄰的時段或選擇其他時段。',
  editLessonEventCalendar: '您的日曆中已經有一個相關課程，你是否要修改?',
  lessonEventLost: '無法從日曆中找到您的課程，您有可能把它刪除了，請重新添加',
  //   END --- component::CalendarSlotPickerScreenV2

  // START --- component::VideoCallScreen
  annotationSessionStarted: '對方正在使用智能板。點擊此處並開始使用智能板。',
  youCanJoinTheAnnotationByBottomLeft: '點擊右下方的按鈕，您便可以隨時使用智能板。',
  //   END --- component::VideoCallScreen

  // START --- component::QRCodeScannerScreen
  cameraQRPermission: '請授權YOUBRIO使用您的相機，否則掃碼無法正常操作。',
  thisIsNotARoleProfile: '此用戶不是一名{0}。',
  //   END --- component::QRCodeScannerScreen

  // START --- component::VideoRecorderScreen
  cameraRecorderPermission: '請授權YOUBRIO使用您的相機，否則APP無法正常錄製視頻。',
  //   END --- component::VideoRecorderScreen

  // START ---- components::UploadVideoScreen/UploadVideoScreen
  failedToGetVideoTypes: '獲取視頻類型失敗，請稍後再嘗試',
  //   END ---- components::UploadVideoScreen/UploadVideoScreen

  // START --- component::QRCodeScreen
  shareReferralProgramInfo: '您現在可以通過我們的推薦活動介紹老師給您的學生！若該老師允許，每當您所推薦的學生與該老師完成一堂課時，您將可獲取學費的一部分作爲推薦賞金。若您選擇不要接受任何的推薦賞金，您可以在分享老師簡介的鏈接或二維碼時關閉獲取賞金的選項。',
  //   END --- component::QRCodeScreen

  // START ---- component::ReferralQRCodeScreen
  referralCommissionInfo: '在開通此選項時，每當您所推薦的學生與此老師完成一堂課時，您將可獲取學費的{0}%作爲推薦賞金。',
  shareReferralInvitationLinkText: '我想邀請您使用YOUBRIO APP與這位非常棒的老師上一堂課。點此並使用YOUBRIO APP以開啓此鏈接來查看這位老師的簡介。 {0}',
  //   END ---- component::ReferralQRCodeScreen

  // START ---- components::LessonPaymentScreen
  topUpMoreNotice: '爲了方便您下一次再預定課程，請問您是否要預先充值10堂課的學費呢？',
  topUpSuggestionNotice: '請問您要充值{0}來預定{1}的課程嗎？提前預定課程可以確保您預先鎖定上課時間。',
  //   END ---- components::LessonPaymentScreen

  // START ---- components.LiveLessonRoomScreen/VideoCallTabScreen
  weNeedCameraMicrophoneForVideoCall: '請授權YOUBRIO使用您的相機和麥克風，否則將無法正常進行課程。',
  oneOrMorePermissionIsRestricted: 'YOUBRIO無法使用您的相機和麥克風。原因可能是受到父母監控限制或本手機不支持。',
  weNeedCameraForVideoCall: '請授權YOUBRIO使用您的相機，否則將無法正常進行課程。',
  weNeedMicrophoneForVideoCall: '請授權YOUBRIO使用您的麥克風，否則將無法正常進行課程。',
  weNeedAccessStorageToStoreAppData: '請授權YOUBRIO使用您的設備儲存以儲存APP的數據。',
  weNeedAccessStorageToReadAppData: '請授權YOUBRIO使用您的設備儲存以讀取APP的數據。',
  areYouSureExit: '請問您確認要退出嗎？',
  oopsDisconnectedFromCall: '啊，與服務器的連接斷開了！是否重新連接？（错误：{0}）',
  oopsYouAreDisconnectedPleaseCheckInternetAndReconnect: '啊，與服務器的連接斷開了！請確保您的網絡穩定再重連。（错误：{0}）',
  //   END ---- components.LiveLessonRoomScreen/VideoCallTabScreen

  // START ---- components::LiveLessonRoomScreen/SmartboardTabScreen
  youNeedAtLeastTwoTabs: '您的智能板只有少過兩頁。',
  confirmToRefreshTab: '請問您是否確定要刷新此頁？',
  //   END ---- components::LiveLessonRoomScreen/SmartboardTabScreen

  // START ---- helpers::ApiHelper
  networkErrorMakeSureGoodConnection: '您的網絡出現了問題。',
  //   END ---- helperps::ApiHelper

  // START ---- components::ChangePasswordScreen
  passwordSetup: '您目前還未設置任何密碼. 如果需要使用錢包你需要現在設置密碼，是否現在設置？',
  passwordChanged: '密碼已更改',
  passwordChangeFailed: '更改密碼失敗，請稍後嘗試',
  oldPasswordWrong: '舊密碼輸入錯誤',
  //   END ---- components::ChangePasswordScreen

  // START ---- components::ProfilePreviewScreen
  noEndorsement: '此用戶還未有任何評價，你可以現在為他寫一個',
  // END ---- components::ProfilePreviewScreen

  // START ---- components::SubjectRequestScreen
  learnSubRequestDesc: '比如: \n\n我要找一位數學老師，我家孩子10歲...\n我要找一位小提琴老師，孩子學了五年...\n我要找一位英文老師，教雅思託福...',
  teachSubRequestDesc: '比如: 我是一個豎琴老師, 教初級至進階到學生等...',
  findTeacherForU: '沒有找到合適的老師?\n\n點擊這裡把您的需求告訴我們,\n我們將幫您預約您理想的老師',
  becomeTeacher: '我想要成為一名老師! 點擊此處開始與世界各地的學生上課',
  requestSuccess: '您的要求已提交成功，謝謝你的反饋',
  requestFail: '提交失敗，請稍後再嘗試',
  //   END ---- components::SubjectRequestScreen

  // START ---- helpers::AlipayHelper
  alipaySandboxNotAvailableForiOS: 'iOS無法使用支付寶沙箱。請更改環境變數。',
  //   END ---- helpers::AlipayHelper

  // START ---- components::UserNameIcon
  noIntroVideo: '添加介紹視頻',
  noOneMinVideo: '您還沒有任何視頻',
  failedToSaveIntro: '保存自我介紹視頻失敗，請稍後嘗試',
  //   END ---- components::UserNameIcon

  // START ---- components::ChatScreen
  adminChatFirst: '優伯教學會幫助你開始與 {0} 的第一節課',
  wantALesson: '您好優伯教學, 我想與 {0} 上課!',
  requestedLesson: '您好優伯教學, 我向 {0} 請求了一個課程 \n\n開始: {1} \n\n時常: {2} 分鐘 \n\n詳情: {3}',
  requestedLessonDirect: '您好 {0}, 我向您請求了一個課程 \n\n開始: {1} \n\n時常: {2} 分鐘 \n\n詳情: {3}',
  overlapBookingExistCreateLessonConfirmation: '已經有一個課程的時間與你選擇的十分接近或者衝突。請問您是否確認繼續？',
  createPrivateLessonFail: '無法創建課程。請您稍後再試或聯繫系統管理員。',
  areYouSureDeletePrivateLesson: '您確定要刪除此課程時段嗎？您的學生將無法預定此時段。',
  confirmStartVideoCall: '是否確認開始視頻通話？',
  nStartedAVideoCall: '{0} 開始了視頻通話。',
  thisLessonHasExpired: '已超出開課時間。',
  pleaseGrantPrerequiredPermissionsForVideoCallAndroid: '請授權YOUBRIO使用相機、麥克風及儲存空間以確保視頻通話能夠正常的進行。請到APP設定開通權限。',
  pleaseGrantPrerequiredPermissionsForVideoCallIos: '請授權YOUBRIO使用相機、麥克風及相冊以確保視頻通話能夠正常的進行。請到APP設定開通權限。',
  //   END ---- components::ChatScreen

  // START ---- actions::ChatActions
  unableToStartVideoCall: '無法連上視頻通話。請您稍後再試或聯繫系統管理員。',
  //   END ---- actions::ChatActions

  // START ---- components::ViewAchievementScreen
  savePermission: '請確保您的設置裡允許存儲到相冊',
  savedToAlbums: '已成功保存至相冊',
  failedToDownload: '下載失敗, 請稍後嘗試',
  comeWatchVideo: '優伯教學邀請您觀看一個來自Youbrio.com的視頻',
  comeWatchPhoto: '優伯教學邀請您觀看一個來自Youbrio.com的圖片',
  //   END ---- components::ViewAchievementScreen

  // START ---- component::BookingSlotChangeRequestListScreen
  approveChangeRequestConfirmation: '批准此請求將更改上課的時間。是否確認批准？',
  overlapBookingExistApproveChangeRequestConfirmation: '在這時段有課程衝突，批准此請求將更改此課程上課的時間。是否確認批准？',
  rejectChangeRequestConfirmation: '請問您是否確認拒絕此請求？此課程上課時間將保持不變。',
  deleteChangeRequestConfirmation: '請問您是否確認撤銷此請求？',
  overlapBookingExistCreateRequestConfirmation: '在這時段有課程衝突。請問您是否繼續發送此請求？',
  //   END ---- component::BookingSlotChangeRequestListScreen

  // START ---- component::common/LessonRequestModal
  requestLessonSuccess: '您已成功發送課程請求。一旦老師對您的請求作出反應，您將第一時間收到通知。',
  requestLessonFail: '無法發送課程請求。請您稍後再試或聯繫系統管理員。',
  addedCalendar: '已成功添課程到您的日曆',
  enableCalendar: '您需要允許優伯教學使用日曆來添加課程',
  //   END ---- component::common/LessonRequestModal

  // START ---- component::CandidateProfileScreen
  teacherRelationshipInfo: '您的帳號已經與這位老師連接。若有任何錯誤，請聯絡YOUBRIO客服以進行更正。',
  //   END ---- component::CandidateProfileScreen

  // START ---- component::LessonBookingDetailScreen
  bookAnother: '請問您要不要再預約一堂課?',
  sameLesson: '是否為{0}創建相同時間課程?',
  bookSameLessonSuccess: '您已經預約成功了',
  unableToGetBookingDetails: '是否重新載入/刷新課程詳情？',
  //   END ---- component::LessonBookingDetailScreen

  // START ---- component::common/TeacherIcon
  needHelpFindingTeacher: '找老師？\n點擊這裏。',
  needAssistance: '需要幫忙嗎？\n點擊這裏。',
  //   END ---- component::common/TeacherIcon

  // START ---- actions::MusicLibraryActions
  getSheetMusicPriceDetailFail: '無法載入曲譜詳情。請您稍後再試或聯繫系統管理員。',
  paymentGatewayError: '支付網關出現錯誤。請重新再試或聯繫系統管理員。',
  purchaseSheetMusicSuccess: '您已成功付款併購買曲譜。處理好您的訂單後，我們將把曲譜發至您的郵箱。',
  purchaseSheetMusicFail: '無法購買曲譜。請您稍後再試或聯繫系統管理員。',
  purchaseSheetMusicNotYetProcessed: '您已成功付款，但是系統還在覈實此交易中。請稍後再查看您的付款歷史記錄。若您的付款歷史記錄在一個小時後還未更新，請聯繫系統管理員。',
  //   END ---- actions::MusicLibraryActions

  // START ---- components::HomeTabV2
  profileIsIncompleteCompleteNow: '您的個人資料尚未完整。請問您是否想現在填寫完整？',
  //   END ---- components::HomeTabV2

  // START ---- components::SchoolFeeCategoryListScreen
  noFeeCategoryFoundCreateNew: '學費類別不存在。\n點擊並創建新學費類別。',
  //   END ---- components::SchoolFeeCategoryListScreen

  // START ---- component/SchoolFeeCategoryCreateScreen
  defaultOrgFeeCategoryHelpInfo: '若設爲默認，所有未委任至任何學費類別的老師將會屬於此學費類別。',
  emptySelectedTeacherOrgFeeCategoryInfo: '未委任老師。\n點擊並添加老師。',
  isDefaultFeeCategorySelectedTeacherInfo: '所有未委任至任何學費類別的老師將會屬於此默認學費類別。',
  createFeeCategorySuccess: '您已成功創建學費類別。',
  createFeeCategoryFail: '無法創建學費類別。請您稍後再試或聯繫系統管理員。',
  noTeacherAddedFeeCategoryWarning: '未委任老師至此非默認學費類別。請問是否現在委任？',
  //   END ---- component/SchoolFeeCategoryCreateScreen

  // START ---- component/SchoolFeeCategoryEditScreen
  unableToGetFeeCategoryDetail: '無法載入學費類別詳情。請您稍後再試或聯繫系統管理員。',
  feeCategorySaveSuccess: '您已成功儲存學費類別設置。',
  feeCategorySaveFail: '無法儲存學費類別設置。請您稍後再試或聯繫系統管理員。',
  feeCategoryDeleteSuccess: '您已成功刪除學費類別。',
  feeCategoryDeleteFail: '無法刪除學費類別。請您稍後再試或聯繫系統管理員。',
  areYouSureYouWantToDeleteThis: '您確定要刪除嗎？',
  setThisCategoryAsDefault: '設爲默認學費類別？',
  setCategoryDefaultSuccess: '成功設爲默認學費類別。',
  setCategoryDefaultFail: '無法設爲默認學費類別。請您稍後再試或聯繫系統管理員。',
  youHaveSuccessfullyAddedSomeoneAsYourRoleList: '您已成功添加{0}至您的{1}列表。',
  unableToAddSomeoneToYourRoleList: '無法添加{0}至您的{1}列表。',
  youHaveRemovedSomeoneAsYourRole: '您已把{0}從您的{1}列表中刪除。',
  unableToRemoveSomeoneFromYourRoleList: '無法把{0}從您的{1}列表中刪除。',
  //   END ---- component/SchoolFeeCategoryEditScreen

  // START ---- component/ReferralIntroScreen
  referralIntroText: '推薦老師給您身邊好友（或轉發朋友圈），\n您好友所有完成課程的課時費的百分之十，\n將自動進入您的優伯錢包作爲獎勵。',
  selectTeacherYouWishToReferFromYourTeachers: '從您的老師收藏名單裏選擇您想介紹的老師',
  //   END ---- component/ReferralIntroScreen

  // START ---- component/ChatListScreen
  unableToViewThisProfile: '無法查看此簡介。',
  deleteChatPermanently: '你確定你要永久刪除你發給{0}的聊天記錄嗎 注意! 刪除後將無法恢復!',
  chatDeleteSuccess: '聊天記錄刪除成功',
  failedToDelete: '聊天記錄刪除失敗',
  //   END ---- component/ChatListScreen

  // START ---- modal/AgreementModal
  agree: '同意',
  agreeDescription1: '請您務必審慎閱讀、充分理解',
  agreeDescription2: '《使用條款》',
  and: '和',
  agreeDescription3: '《個人隱私條款》',
  agreeDescription4: '。如果您同意，請點擊“同意”開始接受我們的服務。',
  //   END ---- modal/AgreementModal

  // START ---- component::common/AnnotationTabBar
  youHaveReachedMaxTab: '爲了確保您擁有最佳的上課體驗，您可以在每一堂課中上傳不超過 {0} 頁的筆記。若要上傳新的一頁的筆記，請關閉任何一頁。',
  //   END ---- component::common/AnnotationTabBar

  // START ---- component::NotificationListScreen/NotificationListItem
  areYouSureToDeleteNotification: '請問您確定刪除此通知嗎？',
  //   END ---- component::NotificationListScreen/NotificationListItem

  // START ---- component::NetworkTestingModal
  networkLowSpeedComment: '您可能會體驗:\n\n1. 視頻 + 聲音效果不佳\n2. 連線不穩定\n\n您可以試著:\n 1. 切換 4G 網絡重試\n2. 靠近路由器重試\n3. 重啟路由器重試\n4. 重啟設備\n5. 在課堂上關閉視頻功能',
  networkLowSpeedNote: '*註意事項\n若有其他用戶在同個網絡上使用或下載，連線效果將會受影響。',
  networkModerateSpeedComment: '若您體驗:\n1. 視頻 + 聲音效果不佳\n2. 連線不穩定\n\n您可以試著 : \n1. 切換 4G 網絡重試\n2. 靠近路由器重試\n3. 重啟路由器重試\n4. 重啟設備',
  networkFastSpeedComment: '您的網絡檢測非常好，優伯上課的效果應該沒問題。\n\n若您有任何連線上課的問題，請與我們優伯客服聯系。',
  //   END ---- component::NetworkTestingModal

  // START ---- component::components/modals/NetworkErrorModal
  oopsYourInternetHasGoneToOuterSpace: '網絡飛到外太空去啦！\n請檢查您的網絡連線再試。',
  //   END ---- component::components/modals/NetworkErrorModal

  // START ---- component::EditReferenceRateScreen/EditReferenceRateScreen
  setUpReferenceRate: '您還未設置您的課程收費標準每小時，是否現在設置?',
  priceRange: '您輸入的價錢應該在 {0} 到 {1} 之間',
  moreHelp: '您需要更多的幫助嗎?',
  //   END ---- component::EditReferenceRateScreen/EditReferenceRateScreen

  // START ---- component::DatingScreen/DatingScreen
  endOfSearch: '這個已經是最後一個了. 你是否需要要重新搜索?',
  couldNotFindASuitableTeacher: '您剛刷完最後一個搜尋結果。\n找不到適合的老師嗎？',
  //   END ---- component::DatingScreen/DatingScreen

  // START ---- component::WebAccessScreen
  webAccess: '電腦端',
  parentAccess: '家長端',
  stepByStep: '使用步驟',
  acOne: '1. 請確保您已在優伯教學APP的手機或平板端與您的學生或老師預訂了課程。 ',
  acTwo: '2. 前往',
  acTwoRest: ' 並在上課時間通過登錄頁麵點擊“進入教室”。 ',
  acThree: '3. 若要使用智能白板，請使用您的手機或平板設備，在課程預訂頁中點擊“ 智能白板”啟用。',
  giftDescription: '您可以在视频課程中收到其他用戶的禮物',
  //   END ---- component::WebAccessScreen

  // START ---- component::JitsiAudioSettingScreen
  settingsSavedSuccessfully: '設置儲存成功。',
  //   END ---- component::JitsiAudioSettingScreen

  // START ---- component::common/LessonRequestModal
  pleasePickTimeBetweenAAndBOnly: '請您選擇 {0} 至 {1} 的時段。',
  wantToLearnFrom: '我想和 {0} 學習',
  //   END ---- component::common/LessonRequestModal

  // START ---- component::modals/ShareLessonModal
  shareLesson: '您好！點擊以下鏈接以加入我的課程：',
  lessonExpiredTitle: '抱歉, 此課程已過期',
  lessonExpiredContent: '無法進入此課程, 如果你有任何問題請聯繫YOUBRIO管理員',
  lessonShareGreetingTitle: '您好, ',
  lessonAwaitingApprovalC: '感謝您預定我的課程, 我會盡快確定',
  lessonAwaitingApprovalN: '請確保您在設置中開啟YOUBRIO通知',
  lessonAcceptedC: '感謝您預定我的課程, 期待與你一起上課',
  lessonCompletedC: '课程已经结束了, 您表现的很好!',
  lessonNeedBookC: '我為您創建了一個課程, 課程如下',
  lessonUrlHint: '請複制粘貼你的課程鏈接在此處',
  invalidLessonCode: '輸入錯誤，請檢查你的鏈接和房間號碼是否正確',
  lessonShareUrlEx: '例如: https://www.youbrio.com/invite/06a3YNpm',
  meetShareUrlPlaceholder: '例如: {0}invite/meet?u=xxxxxxxx',
  lessonOnlyAvailableToStudentAccount: '只有完善的學生帳號可以預定此課程。請透過下方按鈕完成您的個人資料並設置您的賬號爲學生帳號。完成個人資料後再點擊鏈接即可預定此課程。',
  // END ---- component::modals/ShareLessonModal

  // START ---- components::MyReferralCodeScreen
  referralCodeIsCopied: '您的代碼已被複制到剪貼板上。',
  flyerTitleFrom: '現在輸入',
  flyerTitleTo: '就能獲取價值 ¥288 的在線一對一精品課！',
  shareReferralCodeRewardText: '當您的朋友使用您的推薦碼註冊賬號並上課時，雙方將獲得獎勵！',
  noOneUsedCode: '抱歉,還未有人使用你的推薦碼',
  shareReferralText: '小聲點🤫\n\n讓我告訴你一個祕密，我在用着優伯教學APP來學習以及開課。若你現在用我的推薦碼註冊帳號，我們倆都會有獎勵哦！\n\n好了不多說了，這是我的推薦碼：\n{0}\n\n現在就下載優伯教學！\n{1}',
  //   END ---- components::MyReferralCodeScreen

  // START ---- components::CreateMeetScheduleScreen
  getMeetSettingsFailed: '無法索取課程設置。請您稍後再試或聯繫系統管理員。',
  //   END ---- components::CreateMeetScheduleScreen

  // START ---- components::EditMeetScheduleScreen
  confirmToCancelInvitation: '是否確認取消對 {0} 的邀請?',
  youCannotInviteMoreThan: '您無法邀請超過 {0} 參與者。',
  //   END ---- components::EditMeetScheduleScreen

  // START ---- components::ScheduledMeetDetailScreen
  areYouSureRejectMeetBooking: '請問您是否確認拒絕此預約？ 此操作無法撤消。',
  areYouSureHostCancelMeetBooking: '請問您是否確認取消此預約？ 此操作無法撤消。',
  areYouSureHostCancelMeet: '請問您是否確認取消這堂課？系統將把預約費退回給所有參與者，此操作無法撤消。',
  areYouSureCompleteMeet: '完成此課程後，所有有效參與者的預約費將轉入您的錢包。完成課程之後，您將無法進入教室。',
  plsActionTheRequestedBookingsBeforeCompletingMeet: '在完成課程之前，請批准或拒絕參與者們的預約。',
  plsActionTheRequestedBookingsBeforeEnteringClassroom: '在進入教室之前，請批准或拒絕參與者們的預約。',
  areYouSureCompleteMeetNoActiveBookings: '此課程沒有參與者預約，完成課程後您將不會收到任何學費。請問您是否確認完成此課程？',
  recreateLesson: '是否爲所有參與者創建相同時間的課程呢？',
  wantedToBookTheSameLessonAgainNextWeek: '我想再下週預約同樣時間的課程。',
  //   END ---- components::ScheduledMeetDetailScreen

  // START ---- components::HallOfFameScreen
  examDetails: '在康柏爾國際音樂測評系統中取得最佳成績的學者們會上展示在優伯明日之星榜。測評中可以演奏任何您喜愛的曲目，給國際專業考官評論+推薦信，趕緊報名哦！',
  //   END ---- components::HallOfFameScreen

  // START ---- common/NetworkStatusBar
  youAreConnectedToTheInternet: '您已連接至互聯網。',
  plsCheckYourNetworkConnection: '請檢查您的網絡連接。',
  //   END ---- common/NetworkStatusBar

  // START ---- common/SubscriptionXScreen
  areYouKids: '孩子練琴學習沒有動力？在家效率不高？ ',
  hardToFind: '陪練家教老師不好預約？質量參差不齊？ ',
  lessonAreAlready: '正課老師的課程價格怎麼請的起天天學？ ',
  tutorRoomToHelp: '優伯隨心學為您解憂！ ',
  tutorRoomDesc: '優伯隨心學是根據美國茱莉亞音樂學院助教陪練模式所研發的在線輔導培訓系統，讓學員們可以隨時隨地、無限量地在專業老師的輔導下練琴、學習。優伯隨心學利用互聯網平台的優勢，採取名師團體輔導模式，由優伯專業老師團隊進行精準輔導、及時糾錯，引導孩子們高效率地學習。 ',
  practiceHours: '優伯隨心學的老師在線時間為：',
  theUsualRate: '每個月老師在線時間共計100個小時，平時該級別老師課時費都是$100/小時以上，透過優伯隨心學的模式我們提供給學員物超所值的指導！學員們可以根據自身需求，彈性地安排練琴，學習時間，讓每一次學習都發揮最好的效果。學在課上，功在課下，提高自學效率，減少不必要的錯誤，讓學員們少走彎路，才能事半功倍！ ',
  k12: '優伯隨心學現在運營的科目為：鋼琴房，小提琴房和學習房，專注在輔導K-12學生們的英文，數學和基本作業。 ',
  iHaveToppedUpAndWantToSubscribe: '我剛剛充值了 {0}。我想訂閱優伯隨心學 {1}，{2} {3}。',
  // END ---- common/SubscriptionXScreen

  // START ---- actions::PracticeRoomActions
  youAreNotTeacherOfPracticeRoom: '您不是這間課室的老師。',
  youAreNotSubscribedToThisRoom: '您未訂閱這間課室。',
  theRoomIsNotOpenedYet: '這間課室還未開課。',
  //   END ---- actions::PracticeRoomActions

  // START ---- components/PracticeModeScreen/modals/LearnModeInfoModal
  learnModeInfoHeader: '歡迎來到優伯隨心學\n請仔細聽從導師的指示。',
  learnModeInfo1: '1. 學習模式: 優伯導師將爲您服務，\n幫助您高效率的練習。',
  learnModeInfo2: '2. 練琴模式: 按照導師的指示認真練習，\n練好了老師將過來給您指導。',
  learnModeInfo3: '3. 舉手: 有任何不確定的問題，\n可以使用舉手按鈕通知老師。\n\n練琴愉快，下個朗朗就是你！',
  //   END ---- components/PracticeModeScreen/modals/LearnModeInfoModal

  // START ---- components/PracticeModeScreen/modals/LearnModeNotificationModal
  lessonModeActivatedInfo: '優伯導師將爲您服務，\n幫助您高效率的練習。',
  practiceModeActivatedInfo: '按照導師的指示認真練習，\n練好了老師將過來給您指導。',
  //   END ---- components/PracticeModeScreen/modals/LearnModeNotificationModal

  // START ---- components/PracticeModeScreen/modals/MeetParticipantListModal
  confirmMarkUserExitPracticeRoom: '是否確認這位學員已退出教室？',
  participantIsMissingFromPracticeRoom: '這位學員已不在教室裏。若學員還在，請重新打開此彈窗以刷新列表。',
  someoneHasEditedTheNote: '其他老師剛剛更新了這位學生的筆記。此列表已經刷新，但您所更改的筆記已安全地複製到粘貼板上。',
  someoneHasChangedTheLockStatus: '其他老師剛剛更新了這位學生的封鎖狀態。此列表正在刷新中...',
  alertedStudentNoAudio: '我們已向學生髮出無聲音警示。',
  //   END ---- components/PracticeModeScreen/modals/MeetParticipantListModal

  // START ---- common/AcknowledgementScreen
  usedTheFollowingAnimation: '此應用軟件使用了以下動畫:',
  //   END ---- common/AcknowledgementScreen

  // START ---- components/PracticeModeScreen/modals/CoffeeBreakModal
  coffeeBreakDeactivatedInfo: '謝謝您一直都這麼努力！\n若需要休息片刻，您可以點擊下方按鈕，我們將爲您關閉攝像頭與麥克風。\n休息模式啓動時，其他老師還是可以與您語音連接。',
  coffeeBreakActivatedInfo: '休息是爲了走更長遠的路！\n若您準備好退出休息模式，請點擊下方按鈕以繼續教導。',
  finishTeachingThenOnlyGoBreak: '不好意思，看來您還在教着一位學生。麻煩您在進入休息模式之前把該學生設爲“練習模式”。',
  pleaseTurnOffCoffeeBreak: '請您退出休息模式再繼續。',
  //   END ---- components/PracticeModeScreen/modals/CoffeeBreakModal

  // START ---- component::SettingScreenV2
  confirmDeleteAccount: '我們對您的離開感到遺憾。刪除賬號是永久性的，並無法恢復賬號資料。請問您是否確定刪除賬號？',
  weNeedToVerifyYourIdentity: '我們需要覈實您的身份，請輸入您的賬號密碼。',
  accountDeleted: '您的賬號已被刪除。',
  //   END ---- component::SettingScreenV2

  // START ---- helpers::AnnotationSocketHelper
  teacherCantHearYou: '老師無法聽見您的聲音。麻煩您重新進入教室或重啓APP。謝謝！'
  //   END ---- helpers::AnnotationSocketHelper
};
