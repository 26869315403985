import SocketIOClient from 'socket.io-client';

class ControlRoomSocketHelper {
  static oneOffEmit({
    serverUrl,
    roomId,
    event,
    eventData
  }) {
    return new Promise((resolve, reject) => {
      const socket = SocketIOClient(serverUrl, {
        transports: ['websocket'],
        secure: true,
        reconnection: false
      });
  
      socket.on('connect', () => {
        socket.emit('room', roomId);

        // just to make sure that 'room' event goes through first
        setTimeout(() => {
          socket.emit(event, eventData, (res) => {
            socket.disconnect();
            socket.removeAllListeners();
            resolve(res);
          });
        }, 500);
      });

      socket.on('connect_error', (error) => {
        reject(error);
      });
    });
  }
}

export default ControlRoomSocketHelper;
