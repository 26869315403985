import ApiHelper from '../helpers/ApiHelper';
import YoubrioApiHelper from '../helpers/YoubrioApiHelper';
import AppHelper from '../helpers/AppHelper';


export const getCountries = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`countries?lang=${lang}`, true);
};

export const validateAffiliationCodeV2 = ({ affiliationCode }) => {
  return ApiHelper.get(`v2/affiliate/code/${affiliationCode}`, true);
};

export const getPhysicalLocation = () => {
  return ApiHelper.get('location', false);
};

export const getFaq = () => {
  return YoubrioApiHelper.get('faqs', true);
};

export const getBanners = ({ deviceType }) => {
  return YoubrioApiHelper.get(`banners?deviceType=${deviceType}`, true);
};

export const getJitsiPreloadUrl = () => {
  return YoubrioApiHelper.get('jitsi/url', true);
};
