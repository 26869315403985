import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  button: {
    float: 'right',
    marginBottom: 12,
  },
  marginBtm: {
    marginBottom: 12,
  }
}));

export const YesNoPopover = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? ('simple-popover' + Date.now().toString()) : undefined;

  return (
    <div>
      <Button aria-describedby={id} color="secondary" onClick={handleClick}>
        {props.buttonLabel}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={classes.container}>
          <Typography variant="h6" className={classes.marginBtm}>{t('popover.areYouSure')}</Typography>

          <Typography variant="body2" className={classes.marginBtm}>{t('popover.cannotUndone')}</Typography>

          <Button className={classes.button} color="default" disableElevation onClick={handleClose}>
            <strong>{t('alert.no')}</strong>
          </Button>
          <Button className={classes.button} color="default" onClick={props.onConfirm || console.log('No-op')}>
            {t('alert.yes')}
          </Button>
        </div>
      </Popover>
    </div>
  );
}