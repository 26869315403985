import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTranslation } from 'react-i18next';

import AppHelper from '../../helpers/AppHelper';
import {
  BraintreeDropIn,
  PaymentMethodPicker,
  LogoAppBar
} from '../common';
import { AlertDialog } from '../dialogs';
import {
  payPendingOrderViaBraintree,
  getOrder,
  openDialog
} from '../../actions';

const PayOrderViaBraintreeScreen = (props) => {
  const {
    location,
    history,
    orderDetails
  } = props;

  const {
    to_currency_code,
    to_amount
  } = orderDetails;

  const orderRefId = location?.state?.orderRefId;
  const selectedPaymentMethod = location?.state?.selectedPaymentMethod;

  const { t } = useTranslation();
  const btDiv = useRef(null);
  
  const [isBtDropInVisible, setIsBtDropInVisible] = useState(false);

  useEffect(() => {
    props.getOrder({
      orderRefId,
      callback: ({ response, error }) => {
        if (response) {
          setIsBtDropInVisible(true);
        } else if (error) {
          props.openDialog({
            dialog: AlertDialog,
            props: {
              title: t('order.oops'),
              contentText: t('alert.somethingWentWrong'),
              disableEscapeKeyDown: true,
              disableBackdropClick: true,
              buttons: [
                {
                  text: t('back'),
                  primary: true,
                  onClick: () => {
                    history.goBack();
                  }
                }
              ]
            }
          });
        }
      }
    });
  }, []);

  const onOrderPaymentDoneCallback = ({ response, error }) => {
    // TODO: give different feedback after top up
    if (response) {
      history.replace(`/order/${orderRefId}/thank-you`);
    } else if (error) {
      props.openDialog({
        dialog: AlertDialog,
        props: {
          title: t('error'),
          contentText: t('order.processOrderPaymentFail'),
          disableEscapeKeyDown: true,
          disableBackdropClick: true,
          buttons: [
            {
              text: t('alert.ok'),
              onClick: () => { history.goBack(); },
              primary: true
            }
          ]
        }
      });
    }
  };

  const onPaymentMethodNonceReceived = ({ nonce }) => {
    props.payPendingOrderViaBraintree({
      orderRefId,
      currencyCode: to_currency_code,
      amount: to_amount,
      nonce,
      callback: onOrderPaymentDoneCallback
    });
  };

  const onBTError = (error) => {
    console.log(error);
  };

  const onBTInit = ({ instance }) => {
    // setBtInstance(instance);
    if (btDiv) {
      btDiv.current.scrollIntoView({ behavior: 'smooth' });
    }
    
  };

  const renderBtDropIn = () => {
    if (isBtDropInVisible) {
      return (
        <Grid item ref={btDiv}>
          <Paper square className='px-3 py-3'>
            <BraintreeDropIn
              onError={onBTError}
              onInitialized={onBTInit}
              onPaymentMethodNonceReceived={onPaymentMethodNonceReceived}
            />
          </Paper>
        </Grid>
      );
    }

    return null;
  };

  const render = () => {
    return (
      <Grid
        container
        alignItems='center'
        direction='column'
        style={{ backgroundColor: '#fafafa', minHeight: '100vh', paddingTop: 60 }}
      >
        <LogoAppBar />  
        <Grid
          container
          style={{ maxWidth: '90%' }}
          className='py-3 px-3'
          direction='column'
          alignItems='center'
        >
          <Grid xs={12} md={6} item container direction='column' spacing={2}>
            <Grid item>
              <Paper square className='px-3 py-3'>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="total-payable-amount"
                    label={t('order.totalPayable')}
                    name="total-payable-amount"
                    value={to_amount}
                    error={false}
                    helperText={''}
                    className='hide-spinner'
                    type='number'
                    InputProps={{
                      startAdornment: (<InputAdornment position="start">{`${to_currency_code} ${AppHelper.getCurrencySignByCurrencyCode(to_currency_code)}`}</InputAdornment>)
                    }}
                    disabled
                  />
                </FormControl>
                <FormControl fullWidth className={'mt-3'}>
                  <PaymentMethodPicker
                    selectedPaymentMethod={selectedPaymentMethod}
                    currencyCode={to_currency_code}
                    textFieldProps={{ variant: 'outlined', disabled: true, helperText: '', label: t('order.paymentMethod') }}
                  />
                </FormControl>
              </Paper>
            </Grid>
            {renderBtDropIn()}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return render();
};

const mapStateToProps = (state) => {
  const {
    orderDetails
  } = state.order;

  return {
    orderDetails
  };
};

export default connect(mapStateToProps, {
  payPendingOrderViaBraintree,
  getOrder,
  openDialog
})(PayOrderViaBraintreeScreen);