import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { closeDialog } from '../../actions';

const _MainDialogContainer = (_props) => {
  const location = useLocation();
  const currentPath = location?.pathname;

  const {
    props: dialogProps,
    component,
    open: _open
  } = _props.dialog;

  /**
   * This is make sure dialog is dismissed every time route is changed.
   * Otherwise some dialog might not make sense, for e.g. a dialog that shows
   * "top up successful", then it still persists after user pressed back or forward
   * button on the browser to go back to /dash.
   * However, the dialog will be persisted if isPersist is set to true.
   */
  useEffect(() => {
    if (!dialogProps.isPersist && currentPath && _open) {
      _closeDialog();
    }
  }, [currentPath]);

  const _closeDialog = () => {
    _props.closeDialog();
  };

  const _onClose = (event, reason) => {
    const {
      disableEscapeKeyDown = false,
      disableBackdropClick = false
    } = dialogProps;

    if (
      (reason === 'escapeKeyDown' && disableEscapeKeyDown)
      || (reason === 'backdropClick' && disableBackdropClick)
    ) {
      return;
    }
    _closeDialog();
  };

  const render = () => {
    const {
      /**
       * disableEscapeKeyDown, disableBackdropClick are necessary here
       * to exclude them from being passed down to the actual dialog component
       */
      // eslint-disable-next-line no-unused-vars
      disableEscapeKeyDown,
      // eslint-disable-next-line no-unused-vars
      disableBackdropClick,
      ...otherDialogProps
    } = dialogProps;

    return (
      component
        ? React.createElement(component, {
          closeDialog: _closeDialog,
          _open,
          _onClose,
          maxWidth: 'sm',
          fullWidth: true,
          ...otherDialogProps
        })
        : null);
  };

  return render();
};

const mapStateToProps = (state) => {
  const {
    dialog
  } = state.dialog;

  return {
    dialog
  };
};

export const MainDialogContainer = connect(mapStateToProps, {
  closeDialog
})(_MainDialogContainer);