import { snackbar } from '../actions';
import {
  UPDATE_COMMON_STATES,
  UPDATE_SNACKBAR_STATES,
  LOGOUT_USER_SUCCESS
} from '../actions/types';

const INITIAL_UNEXECUTED_NOTIFICATION_LAUNCH_STATE = {
  notification_type_id: null,
  video_id: null,
  booking_id: null,
  booking_slot_id: null,
  fund_txn_id: null,
  id: null
};

const INITIAL_STATE = {
  countries: [],
  countryStates: [
    { key: 'New South Wales', label: 'New South Wales' },
    { key: 'Queensland', label: 'Queensland' },
    { key: 'Victoria', label: 'Victoria' },
    { key: 'Tasmania', label: 'Tasmania' },
    { key: 'Western Australia', label: 'Western Australia' },
    { key: 'South Australia', label: 'South Australia' },
    { key: 'Northern Territories', label: 'Northern Territories' },
    { key: 'Australian Capital Territory', label: 'Australian Capital Territory' }
  ],
  institutionTypes: [],
  drawerOpened: false,
  instruments: [],
  gradesAbove: [],
  grades: [],
  uploadVideoUrl: '',
  homeMessage: '',
  loadingText: '',
  cbeVideoRegex: '',
  shareAppLink: 'https://www.youbrio.com/start',
  validVideoRegex: '',
  youbrioUrl: '',
  chatServerUrl: '',
  physicalCountryCode: '',
  isRefreshCountryFail: false,
  unExecutedDeepLink: '',
  isLoggedInScreen: false,
  oneSignalPlayerId: '',
  getuiId: '',
  switchedToSchool: null,
  unexecutedNotificationLaunch: INITIAL_UNEXECUTED_NOTIFICATION_LAUNCH_STATE,
  faqs: [],
  snackbar: {},
  redirectTo: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UPDATE_COMMON_STATES:
    return { ...state, [action.payload.prop]: action.payload.value };
  case UPDATE_SNACKBAR_STATES: 
    return { ...state, snackbar: { ...snackbar, [action.payload.prop]: action.payload.value } };
  case LOGOUT_USER_SUCCESS: 
    return INITIAL_STATE;
  default:
    return state;
  }
};
