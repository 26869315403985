import moment from 'moment';
import _ from 'lodash';
import history from '../history';
import i18n from '../i18n';
import {
  UPDATE_LESSON_STATE,
} from '../actions/types';
import {
  enterMeet,
  exitMeet,
  getSlotsForCalendar,
  getSlotDetails,
  getLessonSessionDetails,
  createMeetSchedule,
  getImmediateMeet,
  cancelMeet,
  getMeetRoomConfigurations,
  getMeetRoomConfigurationsBuild6726,
  approveMeetBookings,
  rejectMeetBookings,
  cancelMeetBookingsAsHost,
  earlyCancelMeetBookingsAsParticipant,
  lateCancelMeetBookingsAsParticipant,
  getHostUpcomingMeet,
  getParticipantUpcomingMeet,
} from '../api';
import {
  updateCommonStates
} from './CommonActions';
import { appStartsLoading, appDoneLoading } from './';
import AppHelper from '../helpers/AppHelper';
import GLOBAL from '../helpers/GLOBAL';

export const updateLessonState = ({ prop, value }) => {
  return {
    type: UPDATE_LESSON_STATE,
    payload: { prop, value }
  };
};

export const updateLessonStateAction= ({ prop, value }) => {
  return (dispatch) => {
    dispatch(updateLessonState({ prop, value }));
  };
};

/**
 * Populate students calendar with right data format.
 * @param {Object} data 
 */
const populateStudentCalendar = (data) => {
  return (dispatch) => {
    const { bookingSlots } = data;
    bookingSlots.forEach(slot => {
      slot.group = moment(new Date(slot.start_date_time)).format('DD_MM_YYYY');
    });

    // create hashmap for easy access
    const grouped = _.groupBy(bookingSlots, 'group');
    dispatch(updateLessonState({ prop: 'studentLessons', value: grouped }));
    dispatch(updateLessonState({ prop: 'calendarLoading', value: false }));
  };
};

/**
 * Populate teachers calendar with right data format.
 * @param {Object} data 
 */
const populateTeacherCalendar = (data) => {
  return (dispatch) => {
    const { bookedSlots } = data;
    bookedSlots.forEach(slot => {
      slot.group = moment(new Date(slot.start_date_time)).format('DD_MM_YYYY');
    });

    // create hashmap for easy access
    const grouped = _.groupBy(bookedSlots, 'group');
    dispatch(updateLessonState({ prop: 'teacherLessons', value: grouped }));
    dispatch(updateLessonState({ prop: 'calendarLoading', value: false }));
  };
};


/**
 * Fetch lessons for the user
 * Should work for both students and teachers.
 */
export const getLessonsForCalendar = () => {
  return (dispatch) => {
    dispatch(updateLessonState({ prop: 'calendarLoading', value: true }));
    getSlotsForCalendar()
      .then(res => {
        const { data } = res;
        const isTeacher = AppHelper.isCBETeacher();
        if (isTeacher) {
          dispatch(populateTeacherCalendar(data));
        } else {
          dispatch(populateStudentCalendar(data));
        }
      })
      .catch(err => {
        dispatch(updateLessonState({ prop: 'calendarLoading', value: false }));
        console.warn(err);
      });
  };
};


/**
 * Return action that need to dispatch when the user click on a lesson slot.
 * @param {Object} slot - Lesson Slot Details.
 */
export const onSlotClick = ({ meetId, bookingSlotId }) => {
  return async(dispatch) => {
    GLOBAL.logger.info({ meetId, bookingSlotId });
    dispatch(updateLessonState({ prop: 'opened', value: true }));
    dispatch(updateLessonState({ prop: 'lastOpenedSlot', value: { meetId, bookingSlotId } }));

    try {
      const res = await getSlotDetails({ meetId, bookingSlotId });

      // check for response
      if (res && res.status === 200) {
        GLOBAL.logger.info(res.data);

        let slotDetails = null;
        if (bookingSlotId) {
          slotDetails = res.data.bookingSlot;
        } else {
          slotDetails = res.data.meet;
        }
        
        dispatch(updateLessonState({ prop: 'slotDetails', value: slotDetails })); 
        dispatch(updateLessonState({ prop: 'lessonLoading', value: false }));
        dispatch(updateLessonState({ prop: 'error', value: false }));
      } else {
        throw new Error('Failed to fetch lesson details');
      }
    } catch (err) {
      console.error(err);
      dispatch(updateLessonState({ prop: 'lessonLoading', value: false }));
      dispatch(updateLessonState({ prop: 'error', value: true }));
    }
  };
};

/**
 * Refresh last opened slot.
 * @param {Object} slot - Lesson Slot Details.
 */
export const refreshLastOpenedSlot = () => {
  return (dispatch, getState) => {
    const slot = getState().lesson.lastOpenedSlot;
    
    if (slot) {
      GLOBAL.logger.info('Refresh slot', slot);
      dispatch(onSlotClick(slot));
    } else {
      GLOBAL.logger.info('Slot Not Found');
    }
  };
};

/**
 * Load existing and ongoing immediate meet if have any
 */
export const loadImmediateMeet = () => {
  return (dispatch) => {
    getImmediateMeet()
      .then(res => {
        if (_.get(res, 'data.meet', false)) {
          dispatch(updateLessonState({ prop: 'meet', value: res.data.meet })); 
        }
      })
      .catch(err => {
        console.warn(err.message);
        console.error(err);
      });
  };
};

export const cancelImmediateMeet = (meetId) => {
  return (dispatch) => {
    cancelMeet({ meetId })
      .then(() => {
        dispatch(updateLessonState({ prop: 'meet', value: null })); 
      })
      .catch(err => {
        console.warn(err);
      });
  };
};

/**
 * Create new Immediate Meet
 * @param {Object} params - Lesson Slot Details.
 */
export const createImmediateMeet = (params) => {
  return (dispatch) => {
    dispatch(appStartsLoading());
    const {
      currencyCode,
      pricePerDuration,
      perMinutes,
      startTime,
      endTime,
      minuteDuration,
      startDateTime,
      endDateTime,
      requestRequired = false,
      maxParticipants,
      invitees
    } = params;

    let _endDate = endDateTime || moment(startDateTime)
      .hours(23)
      .minutes(59)
      .seconds(59)
      .milliseconds(999)
      .toDate();

    if (moment(new Date(_endDate)).isBefore(moment(new Date(endTime)))) {
      // the + here is to change the date to Number() to copy the date,
      // otherwise we'll just get pointer if we do direct assignment
      _endDate = new Date(+endTime);
    }

    createMeetSchedule({
      currencyCode,
      pricePerDuration,
      perMinutes,
      desc: null, // immediate only donesn't have desc
      startTime: new Date(startTime),
      endTime: new Date(endTime),
      minuteDuration,
      isRecurring: 0, // immediate only appear once
      recurringDay: null, // immediate only appear once
      startDateTime: new Date(startDateTime),
      endDateTime: new Date(_endDate),
      requestRequired,
      maxParticipants,
      invitees,
      meetScheduleSourceId: 2
    })
      .then((response) => {
        dispatch(appDoneLoading());
        GLOBAL.logger.info(response);
        // join the lesson immediately after created
        dispatch(loadImmediateMeet());
      })
      .catch((error) => {
        dispatch(appDoneLoading());
        console.error('Failed to create lesson', error);
      });
  };
};

/**
 * Actions to get configuration before entering the lesson video call.
 * @param {Object} param - Lesson Details, can be either a meet or bookingSlot. Also need to pass the history object for redirecting.
 */
export const enterLesson = ({ meetId, bookingSlotId }) => {
  return async () => {
    if (meetId) {
      // Get configuration
      try {
        const configRes = await getMeetRoomConfigurations({ meetId: meetId, mode : 1 });
        if (configRes && configRes.data) {
          const { meetConfig } = configRes.data;
          GLOBAL.logger.info(meetConfig);
          const jitsiConfig = {
            ...meetConfig,
            meetId: meetId,
            roomName: meetConfig.room_id,
            jitoken: meetConfig.jitoken,
            serverUrl: meetConfig.jitsi_server_url,
            annotationSocket: meetConfig.annot_server_url,
            isRecordingEnabled: meetConfig.isRecordingEnabled
          };

          history.push({
            pathname: '/lesson',
            state: { jitsiConfig }
          });
        }
      } catch (err) {
        console.error(err);
      }
    } else if (bookingSlotId) {
      // Get configuration from lesson session
      try {
        const lessonSessionRes = await getLessonSessionDetails({ bookingSlotId });
        if (lessonSessionRes && lessonSessionRes.data) {
          const { lessonSession } = lessonSessionRes.data;
          GLOBAL.logger.info(lessonSession);
          const jitsiConfig = {
            ...lessonSession,
            lessonSessionId: lessonSession.id,
            bookingSlotId: bookingSlotId,
            roomName: lessonSession.room_id,
            jitoken: lessonSession.jitoken,
            serverUrl: lessonSession.server_url,
            annotationSocket: lessonSession.annot_server_url
          };
          history.push({
            pathname: '/lesson',
            state: { jitsiConfig }
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
  };
};

/**
 * Update Meet Booking Status for participants
 * @param {Object} param - contains callback functions and status details
 */
export const updateMeetBookingsStatus = ({ meetBookingIds, isCreator, status, callback }) => {
  return (dispatch) => {
    dispatch(appStartsLoading());

    let apiMethod = null;
    switch (status) {
    case 'CF':
      apiMethod = approveMeetBookings;
      break;
    case 'RJ':
      apiMethod = rejectMeetBookings;
      break;
    case 'CT':
      apiMethod = cancelMeetBookingsAsHost;
      break;
    case 'CSE':
      apiMethod = earlyCancelMeetBookingsAsParticipant;
      break;
    case 'CSL':
      apiMethod = lateCancelMeetBookingsAsParticipant;
      break;
    default:
      break;
    }

    apiMethod({ meetBookingIds, isCreator })
      .then((response) => {
        console.log(response);
        dispatch(appDoneLoading());
        // dispatch(refreshMeetLists());
        dispatch(refreshLastOpenedSlot());
        if (callback) {
          callback({ response });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(appDoneLoading());
        // dispatch(refreshMeetLists());
        dispatch(refreshLastOpenedSlot());
        if (callback) {
          callback({ error });
        }
      });
  };
};


export const getUpcomingTeacherLessonBookingList = () => {
  return (dispatch) => {
    dispatch(updateLessonState({ prop: 'upcomingLoading', value: true }));
    getHostUpcomingMeet()
      .then((response) => {
        const { data } = response;
        dispatch(updateLessonState({ prop: 'upcomingLoading', value: false }));
        const meetsAndBookings = [...data.meets];
        if (data.immediateLesson) {
          meetsAndBookings.unshift(data.immediateLesson);
        }
        dispatch(updateLessonState({ prop: 'upcomingLessonBookings', value: meetsAndBookings }));
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateLessonState({ prop: 'upcomingLoading', value: false }));
      });
  };
};

export const getUpcomingStudentLessonBookingList = () => {
  return (dispatch) => {
    dispatch(updateLessonState({ prop: 'upcomingLoading', value: true }));
    getParticipantUpcomingMeet()
      .then((response) => {
        const { data } = response;
        dispatch(updateLessonState({ prop: 'upcomingLoading', value: false }));
        let meetsAndBookings = [...data.meets];
        if (data.immediateLessons && data.immediateLessons.length > 0) {
          meetsAndBookings = [...data.immediateLessons, ...meetsAndBookings];
        }
        dispatch(updateLessonState({ prop: 'upcomingLessonBookings', value: meetsAndBookings }));
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateLessonState({ prop: 'upcomingLoading', value: false }));
      });
  };
};

export const getMeetConfigBuild6726 = ({
  meetId,
  bookingSlotId
}) => {
  return (dispatch) => {
    // dispatch(appStartsLoading());
    getMeetRoomConfigurationsBuild6726({ meetId, mode: 1 })
      .then(async (response) => {
        // console.log(response.data);
        const { meetConfig } = response.data;
        const jitsiConfig = {
          ...meetConfig,
          annotationSocket: meetConfig.annot_server_url,
          practiceRoomId: meetConfig.practice_room_id,
          meetId: meetConfig.meet_id,
          serverUrl: meetConfig.jitsi_server_url,
          roomName: meetConfig.room_id,
          subject: meetConfig.title,
        };

        dispatch(updateLessonState({ prop: 'meetConfig', value: jitsiConfig }));
      })
      .catch((/* error */) => {
        // console.log(error);
        let errorMsg = i18n.t('tutorRoom.unableToEnterLessonRoom');
  
        dispatch(updateCommonStates({ prop: 'snackbar',
          value: {
            open: true,
            severity: 'warning',
            autoHideDuration: 5000,
            message: errorMsg
          }
        }));
      });
  };
};

export const createEnterMeetLog = ({ meetConfig }) => {
  return () => {
    const {
      meet_id,
      jitsi_server_url,
      annot_server_url,
      jitsi_config_param_string,
      mode
    } = meetConfig;
    const deviceInfo = 'web';

    enterMeet({
      meetId: meet_id,
      jitsiServerUrl: jitsi_server_url,
      annotServerUrl: annot_server_url,
      configParamString: jitsi_config_param_string,
      mode,
      deviceInfo
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const createExitMeetLog = ({ meetConfig }) => {
  return () => {
    const {
      meet_id,
      jitsi_server_url,
      annot_server_url,
      jitsi_config_param_string,
      mode
    } = meetConfig;
    const deviceInfo = 'web';

    exitMeet({
      meetId: meet_id,
      jitsiServerUrl: jitsi_server_url,
      annotServerUrl: annot_server_url,
      configParamString: jitsi_config_param_string,
      mode,
      deviceInfo
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
