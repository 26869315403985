/* @flow */

import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import IconClose from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { CircularProgressWithCenterLabel } from '../../common/CircularProgressWithCenterLabel';
import { BaseDialog } from './BaseDialog';
import { Box } from '@material-ui/core';

/**
 * Implements a component for the dialog that displays the exit tutor room countdown.
 */
const KickCountdownDialog = (props) => {
  const {
    exitAt,
    countdownStartSeconds = 100,
    _open,
    _onClose,
    closeDialog: _closeDialog,
    ...otherProps
  } = props;

  const { t } = useTranslation();
  const [now, setNow] = useState(new Date());
  const timer = useRef(null);

  useEffect(() => {
    clearInterval(timer.current);
    timer.current = setInterval(() => {
      setNow(new Date());
    }, 1000);

    return () => {
      clearInterval(timer.current);
    };
  }, []);

  const getPercentage = () => {
    let percentage = (getSecondsLeftToKick() / countdownStartSeconds) * 100;

    if (percentage > 100) {
      percentage = 100;
    }

    return percentage;
  };

  const getSecondsLeftToKick = () => {
    if (exitAt) {
      let secondsLeft = moment(exitAt).diff(moment(now), 'seconds');

      if (secondsLeft < 0) {
        secondsLeft = 0;
      }

      return secondsLeft;
    }
    
    return 0;
  };

  /**
   * Callback to be invoked for cancel action (user wants to send a group message).
   *
   * @returns {boolean}
   */
  const _onDialogCancel = () => {
    _closeDialog();
  };

  /**
   * Renders the header of the dialog.
   *
   * @returns {ReactElement}
   */
  const renderHeader = () => {
    return (
      <div className = { 'header-btn dialog-close' }>
        <IconButton
          onClick = { _onDialogCancel }>
          <IconClose
            style={{ fill: '#ffffff', fontSize: 18 }} />
        </IconButton>
      </div>
    );
  };


  /**
   * Renders the body of the dialog.
   *
   * @returns {ReactElement}
   */
  const renderBody = () => {
    const secondsLeft = getSecondsLeftToKick();

    return (
      <div className = { 'dialog-container coffee-break' }>
        <div className = { 'dialog-tutor-overtime dialog-info-text' }>
          <div style = {{ width: 90, height: 90 }} >
            <CircularProgressWithCenterLabel
              circularProgressProps={{
                size: 90,
                value: getPercentage()
              }}
              labelProps={{
                variant: 'h5'
              }}
              label={secondsLeft}
            />
          </div>
        </div>
        <Typography className='dialog-info-header'>{t('tutorRoom.kickTimer.secondsLeft')}</Typography>

        <Box mt={2}>
          <div className = 'dialog-info-text'>
            {t('tutorRoom.kickTimer.trSessionEndingSoon')}
          </div>
        </Box>
      </div>
    );
  };

  /**
   * Implements React's {@link Component#render()}.
   *
   * @inheritdoc
   * @returns {ReactElement}
   */
  const render = () => {
    return (
      <BaseDialog
        open={_open}
        onClose={_onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...otherProps}
        maxWidth={'xs'}
      >
        <DialogTitle id="alert-dialog-title">
          {renderHeader()}
        </DialogTitle>
        <DialogContent>
          {renderBody()}
        </DialogContent>
      </BaseDialog>
    );
  };

  return render();
};

/**
 * Maps part of the Redux store to the props of this component.
 *
 * @param {Object} state - The Redux state.
 * @param {Props} ownProps - The own props of the component.
 * @returns {Props}
 */
const mapStateToProps = (state) => {
  const {
    exitAt
  } = state.practiceRoom.practiceRoomConfig;

  return {
    exitAt
  };
};

export default connect(mapStateToProps)(KickCountdownDialog);
