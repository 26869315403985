import YoubrioApiHelper from '../helpers/YoubrioApiHelper';
// import AppHelper from '../helpers/AppHelper';

export const getNotificationListByPage = (page = 1) => {
  return YoubrioApiHelper.get(`notifications?page=${page}`, true);
};

export const updateNotificationReadStatus = ({ notificationId }) => {
  return YoubrioApiHelper.post(`notification/${notificationId}/read`, null, true);
};

export const updateUserLastSeenNotificationTimestamp = () => {
  return YoubrioApiHelper.post('user/notification/seen', null, true);
};

export const getUserLastSeenNotificationTimestamp = () => {
  return YoubrioApiHelper.get('user/notification/seen', true);
};

export const markAllNotificationsAsRead = () => {
  return YoubrioApiHelper.post('notifications/read', null, true);
};

export const deleteNotificationById = ({ notificationId }) => {
  return YoubrioApiHelper.post(`notification/${notificationId}/delete`, null, true);
};
