import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/default.json';
import cn from './locales/default.cn.json';
import cnTW from './locales/default.cn-TW.json';

// import GLOBAL from '../helpers/GLOBAL';

// const lng = GLOBAL.get('lang') || 'en';

const resources = {
  en: { translation: en },
  'zh-CN': { translation: cn },
  'zh-TW' : { translation: cnTW }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    // lng, // will overwrite detected language
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
