import axios from 'axios';

import GLOBAL from './GLOBAL';
import config from '../config';

const env = process.env.REACT_APP_ENV || 'dev';
const region = process.env.REACT_APP_REGION || 'global';

export const host = config.cbeUrl[env][region];

class ApiHelper {
  static getConfig(needToken) {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'dataType': 'json'
    };

    if (needToken) {
      headers['Authorization'] = `Bearer ${GLOBAL.get('token')}`;
    }

    return { headers };
  }

  static appendLangField(url) {
    let lang = 'en';
    const globalLang = GLOBAL.get('lang');
    if (globalLang === 'cn') {
      lang = 'zh_CN';
    } else if (globalLang === 'cnTW') {
      lang = 'zh_TW';
    }

    if (url.includes('lang=')) {
      return url;
    } else if (url.includes('?')) {
      return `${url}&lang=${lang}`;
    }
    return `${url}?lang=${lang}`;
  }

  static appendApiPlatformField(url) {
    if (url.includes('api_plat=')) {
      return url;
    } else if (url.includes('?')) {
      return `${url}&api_plat=web`;
    }
    return `${url}?api_plat=web`;
  }

  static get(path, needToken) {
    let url = host + path;
    url = ApiHelper.appendApiPlatformField(ApiHelper.appendLangField(url));

    return axios.get(url, ApiHelper.getConfig(needToken));
  }

  static post(path, data, needToken) {
    let url = host + path;
    url = ApiHelper.appendApiPlatformField(ApiHelper.appendLangField(url));

    return axios.post(url, data, ApiHelper.getConfig(needToken));
  }

  static getCbeCdnUrl(route) {
    const cdnHost = config.cbeCdnUrl[env][region];

    return `${cdnHost}${route}`;
  }

  static getCdnUrl(route) {
    const cdnHost = config.youbrioCdnUrl[env][region];

    return `${cdnHost}${route}`;
  }
}

export default ApiHelper;
