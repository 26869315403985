
import moment from 'moment';
// import { AccessToken } from 'react-native-fbsdk';
// import { Actions } from 'react-native-router-flux';
import {
  UPDATE_FIELD,
  UPDATE_FIELD_ERROR,
  UPDATE_MULTIPLE_FIELD_ERROR,
  RESET_REGISTRATION_FIELD,
  UPDATE_REGISTRATION_MODAL_STATE
} from './types';
import AppHelper from '../helpers/AppHelper';
import { getStore } from '../Store';
import { labels } from '../lang/labels';
import { messages } from '../lang/messages';
import i18n from '../i18n';
import {
  checkEmailExistence,
  checkPrivateStudioId,
  registerAccountWithWXAndEmail,
  registerBasicAccount,
  verifyReferralCode,
  registerAsCompleteStudentProfile
} from '../api';
import {
  appStartsLoading,
  appDoneLoading
} from './';
import {
  loginUserAndSaveToken
} from './AuthActions';

const CONSTANT = require('../helpers/ConstantHelper');
// const labels = {};
// const messages = {};

export const fieldUpdate = ({ prop, value }) => {
  return {
    type: UPDATE_FIELD,
    payload: { prop, value }
  };
};

export const resetField = () => {
  return {
    type: RESET_REGISTRATION_FIELD
  };
};

export const validateField = ({ prop, value, role }) => {
  return (dispatch) => {
    let errorString = '';
    let age = 0;

    if (prop !== 'teacherId' && AppHelper.isEmptyOrWhiteSpace(value)) {
      errorString = i18n.t('form.error.fieldCannotEmpty');
    } else {
      errorString = '';

      switch (prop) {
      case 'email':
        if (!AppHelper.isEmail(value)) {
          errorString = i18n.t('form.error.enterValidEmail');
        } else {
          checkEmailExistence({ email: value })
            .then((response) => {
              if (response && response.data && response.data.checkResult && response.data.checkResult.isExist) {
                errorString = i18n.t('form.error.emailHasExisted');
              } else {
                errorString = '';
              }
              dispatch(fieldErrorUpdate({ prop, error: errorString }));
            })
            .catch((error) => {
              errorString = i18n.t('form.error.checkEmailFail');
              console.error(error);
              dispatch(fieldErrorUpdate({ prop, error: errorString }));
            });
        }
        break;
      case 'password':
        if (value.length < 7) {
          errorString = i18n.t('form.error.passwordMustBeAtLeast8Chars');
        }
        break;
      case 'confirmPassword':
        if (value !== getStore().getState().registration.password) {
          errorString = i18n.t('form.error.passwordDontMatch');
        }
        break;
      case 'teacherId':
        if (!AppHelper.isEmptyOrWhiteSpace(value)) {
          checkPrivateStudioId(value)
            .then((response) => {
              if (response.isExist) {
                errorString = '';
              } else {
                errorString = i18n.t('form.error.teacherIdDoesNotExist');
              }
              dispatch(fieldErrorUpdate({ prop: 'teacherId', error: errorString }));
            })
            .catch((error) => {
              console.error(error);
              errorString = i18n.t('form.error.unableToCheckTeacherId');
              dispatch(fieldErrorUpdate({ prop: 'teacherId', error: errorString }));
            });
        } else {
          errorString = '';
          dispatch(fieldErrorUpdate({ prop: 'teacherId', error: errorString }));
        }
        break;
      case 'dob':
        age = moment().diff(value, 'years');
        // console.log(age);
        if (role === CONSTANT.STRING.ROLE.TEACHER && age < 15) {
          errorString = i18n.t('form.error.atLeastSixteenYearsOldToBeTeacher');
//        } else if (role === CONSTANT.STRING.ROLE.STUDENT && age < 13) {
//          errorString = i18n.t('form.error.atLeastThirteenYearsOld');
        }
        break;
      default:
        break;
      }
    }
    dispatch(fieldErrorUpdate({ prop, error: errorString }));
  };
};

export const multipleFieldErrorUpdate = (dispatch, errors) => {
  dispatch({
    type: UPDATE_MULTIPLE_FIELD_ERROR,
    payload: errors
  });
};

export const fieldErrorUpdate = ({ prop, error }) => {
  return {
    type: UPDATE_FIELD_ERROR,
    payload: { prop, error }
  };
};

export const updateRegistrationModalState = ({ prop, value }) => {
  return {
    type: UPDATE_REGISTRATION_MODAL_STATE,
    payload: { prop, value }
  };
};

export const tryRegisterAccountWithWXAndEmail = ({ wxToken, defaultEmail, email }) => {
  return (dispatch) => {
    // Actions.pop();
    dispatch(appStartsLoading());
    registerAccountWithWXAndEmail({ wxToken, defaultEmail, email })
      .then(response => {
        dispatch(appDoneLoading());
        // console.log(`success response for registerAccountWithWXAndEmail:
        //   defaultEmail: ${response.email},
        //   email: ${response.email}
        //   token: ${response.token}`);
        dispatch(loginUserAndSaveToken(response.token));
      })
      .catch(error => {
        dispatch(appDoneLoading());
        console.log(`error response for registerAccountWithWXAndEmail ${error}`);
      });
  };
};

export const validateFieldsAndRegisterBasicAccount = (fields, sucCbk) => {
  // console.log(fields);
  return async (dispatch) => {
    let hasError = false;
    let errors = {};

    if (AppHelper.isEmptyOrWhiteSpace(fields.password)) {
      errors = { ...errors, password: messages.fieldCannotEmpty };
      hasError = true;
    } else if (fields.password < 7) {
      errors = { ...errors, password: messages.passwordMustBeAtLeast8Chars };
      hasError = true;
    } else {
      errors = { ...errors, password: '' };
    }

    if (AppHelper.isEmptyOrWhiteSpace(fields.confirmPassword)) {
      errors = { ...errors, confirmPassword: messages.fieldCannotEmpty };
      hasError = true;
    } else if (fields.password !== fields.confirmPassword) {
      errors = { ...errors, confirmPassword: messages.passwordDontMatch };
      hasError = true;
    } else {
      errors = { ...errors, confirmPassword: '' };
    }

    if (AppHelper.isEmptyOrWhiteSpace(fields.firstName)) {
      errors = { ...errors, firstName: messages.fieldCannotEmpty };
      hasError = true;
    } else {
      errors = { ...errors, firstName: '' };
    }

    if (AppHelper.isEmptyOrWhiteSpace(fields.lastName)) {
      errors = { ...errors, lastName: messages.fieldCannotEmpty };
      hasError = true;
    } else {
      errors = { ...errors, lastName: '' };
    }

    if (AppHelper.isEmptyOrWhiteSpace(fields.mobile)) {
      errors = { ...errors, mobile: messages.fieldCannotEmpty };
      hasError = true;
    } else {
      errors = { ...errors, mobile: '' };
    }

    if (AppHelper.isEmptyOrWhiteSpace(fields.email)) {
      errors = { ...errors, email: messages.fieldCannotEmpty };
      hasError = true;
    } else if (!AppHelper.isEmail(fields.email)) {
      errors = { ...errors, email: messages.enterValidEmail };
      hasError = true;
    } else {
      try {
        const checkEmailResponse = await checkEmailExistence({ email: fields.email });
        if (checkEmailExistence && checkEmailResponse.data && checkEmailResponse.data.checkResult && checkEmailResponse.data.checkResult.isExist) {
          errors = { ...errors, email: messages.emailHasExisted };
          hasError = true;
        } else {
          errors = { ...errors, email: '' };
          console.error('errors=', errors);
        }
      } catch (error) {
        errors = { ...errors, email: messages.checkEmailFail };
        hasError = true;
        console.error(error);
      }
    }

    multipleFieldErrorUpdate(dispatch, errors);
    if (!hasError) {
      // clear errors and register
      dispatch(appStartsLoading());
      // console.log('no error');
      const {
        email,
        password,
        firstName,
        lastName,
        mobile,
        referralCode
      } = getStore().getState().registration;

      const registrationFields = {
        email,
        password,
        firstName,
        lastName,
        mobile,
        referralCode
      };
      registerBasicAccount(registrationFields)
        .then((response) => {
          dispatch(appDoneLoading());
          // console.log(response);
          dispatch(updateRegistrationModalState({ prop: 'message', value: messages.formatString(messages.candidateRegistrationSuccess, email) }));
          dispatch(updateRegistrationModalState({ prop: 'title', value: labels.accountRegistration }));
          dispatch(updateRegistrationModalState({ prop: 'mode', value: 'success' }));
          dispatch(updateRegistrationModalState({ prop: 'visible', value: true }));
          if (sucCbk) { sucCbk(); }
        })
        .catch((error) => {
          console.log(error);
          dispatch(appDoneLoading());
          dispatch(updateRegistrationModalState({ prop: 'message', value: messages.accountRegistrationFail }));
          dispatch(updateRegistrationModalState({ prop: 'title', value: labels.accountRegistration }));
          dispatch(updateRegistrationModalState({ prop: 'mode', value: 'error' }));
          dispatch(updateRegistrationModalState({ prop: 'visible', value: true }));
        });
    }
  };
};

export const validateFieldsAndRegisterAsCompleteStudentProfile = ({ fields, callback }) => {
  // console.log(fields);
  return async (dispatch) => {
    let hasError = false;
    let errors = {};

    if (AppHelper.isEmptyOrWhiteSpace(fields.password)) {
      errors = { ...errors, password: i18n.t('form.error.fieldCannotEmpty') };
      hasError = true;
    } else if (fields.password < 7) {
      errors = { ...errors, password: i18n.t('form.error.passwordMustBeAtLeast8Chars') };
      hasError = true;
    } else {
      errors = { ...errors, password: '' };
    }

    if (AppHelper.isEmptyOrWhiteSpace(fields.confirmPassword)) {
      errors = { ...errors, confirmPassword: i18n.t('form.error.fieldCannotEmpty') };
      hasError = true;
    } else if (fields.password !== fields.confirmPassword) {
      errors = { ...errors, confirmPassword: i18n.t('form.error.passwordDontMatch') };
      hasError = true;
    } else {
      errors = { ...errors, confirmPassword: '' };
    }

    if (AppHelper.isEmptyOrWhiteSpace(fields.firstName)) {
      errors = { ...errors, firstName: i18n.t('form.error.fieldCannotEmpty') };
      hasError = true;
    } else {
      errors = { ...errors, firstName: '' };
    }

    if (AppHelper.isEmptyOrWhiteSpace(fields.lastName)) {
      errors = { ...errors, lastName: i18n.t('form.error.fieldCannotEmpty') };
      hasError = true;
    } else {
      errors = { ...errors, lastName: '' };
    }

    if (AppHelper.isEmptyOrWhiteSpace(fields.mobile)) {
      errors = { ...errors, mobile: i18n.t('form.error.fieldCannotEmpty') };
      hasError = true;
    } else {
      errors = { ...errors, mobile: '' };
    }

    if (AppHelper.isEmptyOrWhiteSpace(fields.countryId)) {
      errors = { ...errors, countryId: i18n.t('form.error.fieldCannotEmpty') };
      hasError = true;
    } else {
      errors = { ...errors, countryId: '' };
    }

    if (AppHelper.isEmptyOrWhiteSpace(fields.dob)) {
      errors = { ...errors, dob: i18n.t('form.error.fieldCannotEmpty') };
      hasError = true;
    } else {
      const age = moment().diff(fields.dob, 'years');
//      if (age < 13) {
//        errors = { ...errors, dob: i18n.t('form.error.atLeastThirteenYearsOld') };
//      } else {
        errors = { ...errors, dob: '' };
//      }
    }

    if (AppHelper.isEmptyOrWhiteSpace(fields.email)) {
      errors = { ...errors, email: i18n.t('form.error.fieldCannotEmpty') };
      hasError = true;
    } else if (!AppHelper.isEmail(fields.email)) {
      errors = { ...errors, email: i18n.t('form.error.enterValidEmail') };
      hasError = true;
    } else {
      try {
        dispatch(appStartsLoading());
        const checkEmailResponse = await checkEmailExistence({ email: fields.email });
        if (checkEmailExistence && checkEmailResponse.data && checkEmailResponse.data.checkResult && checkEmailResponse.data.checkResult.isExist) {
          errors = { ...errors, email: i18n.t('form.error.emailHasExisted') };
          hasError = true;
        } else {
          errors = { ...errors, email: '' };
          console.error('errors=', errors);
        }
      } catch (error) {
        errors = { ...errors, email: i18n.t('form.error.checkEmailFail') };
        hasError = true;
        console.error(error);
        dispatch(appDoneLoading());
      }
    }

    multipleFieldErrorUpdate(dispatch, errors);
    if (!hasError) {
      // clear errors and register
      dispatch(appStartsLoading());
      // console.log('no error');
      const {
        email,
        password,
        firstName,
        lastName,
        mobile,
        countryId,
        dob
      } = fields;

      const registrationFields = {
        email,
        password,
        firstName,
        lastName,
        mobile,
        countryId,
        dob
      };
      registerAsCompleteStudentProfile(registrationFields)
        .then((response) => {
          dispatch(appDoneLoading());
          // console.log(response);
          if (callback) { callback({ response: response.data }); }
        })
        .catch((error) => {
          console.log(error);
          dispatch(appDoneLoading());
          if (callback) { callback({ error }); }
        });
    } else {
      dispatch(appDoneLoading());
    }
  };
};

export const checkReferralCode = ({ code, callback }) => {
  return () => {
    verifyReferralCode({ code })
      .then((res) => {
        // console.log(res);
        if (callback) {
          if (res.referralCode) {
            callback({ res });
          } else if (res.error) {
            callback({ error: res.error });
          }
        }
      })
      .catch((error) => {
        // console.log(error);
        if (callback) {
          callback({ error });
        }
      });
  };
};
