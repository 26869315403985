class JitsiExternalApiHelper {
  static getJitsiParticipantsByUserId({ jitsiParticipants, userId }) {
    return jitsiParticipants[`u_${userId}`];
  }

  static disableAllUsersAudio({
    apiInstance,
    jitsiParticipants
  }) {
    if (apiInstance) {
      const uidKeys = Object.keys(jitsiParticipants);

      uidKeys.forEach(key => {
        const tracks = jitsiParticipants[key];

        tracks.forEach(t => {
          if (!t.local) {
            apiInstance.executeCommand('setParticipantVolume',
              t.jitsiParticipantId,
              0
            );
          }
        });
      });
    }
  }

  static setUserAudioEnabled({
    apiInstance,
    jitsiParticipants,
    userId,
    enabled
  }) {
    if (apiInstance) {
      let volume = 1;

      if (!enabled) {
        volume = 0;
      }

      const jitsiParts = this.getJitsiParticipantsByUserId({ jitsiParticipants, userId });

      if (jitsiParts) {
        jitsiParts.forEach(p => {
          apiInstance.executeCommand('setParticipantVolume',
            p.jitsiParticipantId,
            volume
          );
        });
      }

      
    }
  }

  static muteMyVideo({ apiInstance }) {
    if (apiInstance) {
      // there's no direct setVideoMuted api, hence this
      apiInstance.isVideoMuted()
        .then((muted) => {
          if (!muted) {
            apiInstance.executeCommand('toggleVideo');
          }
        });
    }
  }

  static unmuteMyVideo({ apiInstance }) {
    if (apiInstance) {
      // there's no direct setVideooMuted api, hence this
      apiInstance.isVideoMuted()
        .then((muted) => {
          if (muted) {
            apiInstance.executeCommand('toggleVideo');
          }
        });
    }
  }

  static muteMyAudio({ apiInstance }) {
    if (apiInstance) {
      // there's no direct setAudioMuted api, hence this
      apiInstance.isAudioMuted()
        .then((muted) => {
          if (!muted) {
            apiInstance.executeCommand('toggleAudio');
          }
        });
    }
  }

  static unmuteMyAudio({ apiInstance }) {
    if (apiInstance) {
      // there's no direct setAudioMuted api, hence this
      apiInstance.isAudioMuted()
        .then((muted) => {
          if (muted) {
            apiInstance.executeCommand('toggleAudio');
          }
        });
    }
  }

  static pinUser({
    apiInstance,
    userId,
    participantId,
    jitsiParticipants
  }) {
    if (apiInstance) {
      let _participantId;

      if (participantId) {
        _participantId = participantId;
      } else if (userId && jitsiParticipants) {
        const jitsiParts = this.getJitsiParticipantsByUserId({ jitsiParticipants, userId });

        if (jitsiParts && jitsiParts.length > 0) {
          _participantId = jitsiParts[0].jitsiParticipantId;
        }
      }

      if (_participantId) {
        // make it full screen view for student
        apiInstance.executeCommand('setTileView', false);
        apiInstance.pinParticipant(participantId);
        apiInstance.setLargeVideoParticipant(participantId);
      }
    }
  }

  static endCall({ apiInstance }) {
    if (apiInstance) {
      apiInstance.executeCommand('hangup');
    }
  }
}

export default JitsiExternalApiHelper;
