import {
  UPDATE_LESSON_SESSION_STATE,
  RESET_LESSON_ROOM_INFO_STATE,
  UPDATE_LIVE_ANNOTATION_STATE,
  CREATE_NEW_ANNOTATION_SESSION,
  UPDATE_ANNOTATION_SESSION_STATE,
  RESET_ANNOTATION_SESSIONS,
  UPDATE_VIDEO_CALL_STREAM_STATE,
  RESET_VIDEO_CALL_STREAM_STATE,
  UPDATE_SMARTBOARD_TOOL_STATE,
  UPDATE_JITSI_CONFIG_STATE,
  RESET_JITSI_CONFIG_STATE,
  UPDATE_JITSI_CONFIG_INDIVIDUAL_SETTING,
  LOGOUT_USER_SUCCESS
} from '../actions/types';

const INITIAL_LESSON_ROOM_INFO = {
  id: '',
  booking_slot_id: '',
  initiator_user_id: '',
  room_id: '',
  jitsi_config_param_string: '',
  teacher_entered: false,
  student_entered: false,
  start_date_time: '',
  end_date_time: '',
  webrtc_config: '',
  webrtc_server_url: '',
  annot_server_url: '',
  color_palette: '',
  maxSmartboardTab: 20,
  jitsi_server_url: '',
  otherUser: {
    id: '',
    firstName: '',
    lastName: '',
    avatarUrl: ''
  }
};

const INITIAL_LIVE_ANNOTATION_STATE = {
  pendingActions: [],
  photoPath: '',
  annotationSession: {
    id: '',
    session_id: '',
    lesson_session_id: '',
    initiated_by_user_id: '',
    user: '',
    new: true
  }
};

const INITIAL_ANNOTATION_SESSION_STATE = {
  pendingActions: [
    // { action: 'client1', data: { ... } }
  ],
  doneActions: [
    // { action: 'client1', data: { ... } }
  ],
  user: '',
  subSessionId: '',
  photoUrl: '',
  remoteUrl: '',
  noPhoto: false,
  order: null
};

const INITIAL_VIDEO_CALL_STREAM_STATE = {
  peerConnected: false,
  remoteStreamUrl: '',
  localStreamUrl: '',
  screenSwitched: false,
  oneJoined: false,
  isFront: true
};

const INITIAL_SMARTBOARD_TOOL_STATE = {
  penStrokeWidth: null,
  eraserStrokeWidth: null,
  selectedPenColor: null,
  lastPenColor: null,
  isScalable: null,
  smartboardPanelCollapsed: null
};

const NETWORK_TEST_ENDPOINTS_STATE = {
  ping_host: '',
  file_download_endpoint: '',
  file_upload_endpoint: ''
};

const INITIAL_JITSI_CONFIG = {
  settingDetails: [],
  settings: {},
  presets: [],
  isChanged: false
};

const INITIAL_STATE = {
  lessonRoomInfo: INITIAL_LESSON_ROOM_INFO,
  videoCallStream: INITIAL_VIDEO_CALL_STREAM_STATE,
  annotationFileList: [],
  liveAnnotation: INITIAL_LIVE_ANNOTATION_STATE,
  annotationSessions: {},
  isAnnotationFileDownloading: false,
  lessonSessionLoading: false,
  lessonSessionLoadingText: '',
  smartboardTool: INITIAL_SMARTBOARD_TOOL_STATE,
  networkTestEndpoints: NETWORK_TEST_ENDPOINTS_STATE,
  jitsiConfig: INITIAL_JITSI_CONFIG
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_LESSON_SESSION_STATE:
      return { ...state, [action.payload.prop]: action.payload.value };
    case UPDATE_LIVE_ANNOTATION_STATE:
      return { ...state, liveAnnotation: { ...state.liveAnnotation, [action.payload.prop]: action.payload.value } };
    case RESET_LESSON_ROOM_INFO_STATE:
      return { ...state, lessonRoomInfo: INITIAL_LESSON_ROOM_INFO };
    case CREATE_NEW_ANNOTATION_SESSION:
      return { ...state, annotationSessions: { ...state.annotationSessions, [action.payload.subSessionId]: INITIAL_ANNOTATION_SESSION_STATE } };
    case UPDATE_ANNOTATION_SESSION_STATE:
      return { ...state, annotationSessions: { ...state.annotationSessions, [action.payload.subSessionId]: { ...state.annotationSessions[action.payload.subSessionId], [action.payload.prop]: action.payload.value } } };
    case RESET_ANNOTATION_SESSIONS:
      return { ...state, annotationSessions: {} };
    case UPDATE_VIDEO_CALL_STREAM_STATE:
      return { ...state, videoCallStream: { ...state.videoCallStream, [action.payload.prop]: action.payload.value } };
    case RESET_VIDEO_CALL_STREAM_STATE:
      return { ...state, videoCallStream: INITIAL_VIDEO_CALL_STREAM_STATE };
    case UPDATE_SMARTBOARD_TOOL_STATE:
      return { ...state, smartboardTool: { ...state.smartboardTool, [action.payload.prop]: action.payload.value } };
    case UPDATE_JITSI_CONFIG_STATE:
      return { ...state, jitsiConfig: { ...state.jitsiConfig, [action.payload.prop]: action.payload.value } };
    case RESET_JITSI_CONFIG_STATE:
      return { ...state, jitsiConfig: INITIAL_JITSI_CONFIG };
    case UPDATE_JITSI_CONFIG_INDIVIDUAL_SETTING:
      return {
        ...state,
        jitsiConfig: {
          ...state.jitsiConfig,
          isChanged: true,
          settings: {
            ...state.jitsiConfig.settings,
            [action.payload.prop]: action.payload.value
          }
        }
      };
    case LOGOUT_USER_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
