import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
  updateLessonState
} from '../../actions';
import GLOBAL from '../../helpers/GLOBAL';

const LessonScreen = (props) => {
  const { updateLessonState } = props;
  const { jitsiConfig } = _.get(props, 'location.state', {});
  const { t } = useTranslation();

  useEffect(() => {
    // Make sure the new jitsi config is not empty and different to current one
    if (!_.isEmpty(jitsiConfig) && jitsiConfig.roomName !== props.jitsiConfig.roomName) {
      // console.log('updating config', jitsiConfig.roomName, props.jitsiConfig.roomName);
      // const { serverUrl } = jitsiConfig;
      // const script = document.createElement('script');
    
      // if (serverUrl) {
      //   script.src = serverUrl + 'external_api.js';
      //   script.async = true;
      // }
      // document.body.appendChild(script);
      
      updateLessonState({ prop: 'jitsiConfig', value: jitsiConfig });

      if (jitsiConfig.title !==  ''){
        document.title = t('youbrio') + ' | ' + jitsiConfig.title;
      }
      // return () => {
      //   document.body.removeChild(script);
      // };
    }
  }, [jitsiConfig]);

  // TO DO --- currently not redirecting to /dash even it's empty
  if (_.isEmpty(jitsiConfig)) {
    GLOBAL.logger.info('jitsiConfig not define');
    return <Redirect to={'/dash'} />;
  }

  return <div />;
};

const mapStateToProps = (state) => {
  const {
    jitsiConfig
  } = state.lesson;

  return {
    jitsiConfig
  };
};

export default connect(mapStateToProps, {
  updateLessonState
})(withRouter(LessonScreen));
