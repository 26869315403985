import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import {
  getRecurringBillDraft,
  getGatewayPaymentMethodList,
  createRecurringBill,
  updateRecurringBillDraftOrderState,
  resetRecurringBillDraftOrderState,
  openDialog
} from '../../actions';
import {
  GatewayPaymentMethodPicker,
  LogoAppBar
} from '../common';
import AppHelper from '../../helpers/AppHelper';
import { LinkIt } from 'react-linkify-it';
import { AlertDialog } from '../dialogs';

const RecurringBillPaymentScreen = (props) => {
  const {
    history,
    draftOrder,
    classes
  } = props;

  const {
    order,
    selectedGatewayPaymentMethod
  } = draftOrder;

  const { t }  = useTranslation();
  const { referenceId } = useParams();
  const draftParamRef = useRef(null);

  useEffect(() => {
    props.getRecurringBillDraft({
      referenceId,
      callback: ({ response, error }) => {
        if (response) {
          draftParamRef.current = {
            params: response.recurringBillDraftOrder.params,
            recurringBillTypeId: response.recurringBillDraftOrder.recurring_bill_type_id
          };
        } else if (error) {
          props.openDialog({
            dialog: AlertDialog,
            props: {
              title: t('error'),
              contentText: t('alert.somethingWentWrong'),
              disableEscapeKeyDown: true,
              disableBackdropClick: true,
              buttons: [
                {
                  text: t('alert.ok'),
                  primary: true,
                  onClick: () => {
                    history.goBack();
                  }
                }
              ]
            }
          });
        }
      }
    });

    props.getGatewayPaymentMethodList({
      callback: ({ response }) => {
        setTimeout(() => {
          if (response && response.data.paymentMethods && response.data.paymentMethods.length > 0) {
            // props.updateRecurringBillDraftOrderState({
            //   prop: 'selectedGatewayPaymentMethod',
            //   value: response.data.paymentMethods[0].id
            // });
          }
        }, 1000);
      }
    });

    return () => {
      props.resetRecurringBillDraftOrderState();
    };
  }, []);

  const onAddNewGatewayPaymentMethodClick = () => {
    history.push({
      pathname: '/gateway-payment-method/new',
      state: {
        currencyCode: order.currency_code,
        returnUrl: window.location.href
      }
    });
  };

  const onSubscribeClick = () => {
    props.createRecurringBill({
      recurringBillTypeId: draftParamRef.current.recurringBillTypeId,
      gatewayPaymentMethodId: selectedGatewayPaymentMethod,
      params: draftParamRef.current.params,
      referenceId
    });
  };

  const onPaymentMethodSelect = (value) => {
    props.updateRecurringBillDraftOrderState({
      prop: 'selectedGatewayPaymentMethod',
      value
    });
  };

  const renderRecurringBillContent = () => {
    if (!order) {
      return null;
    }

    const {
      title,
      description,
      currency_code,
      recurring_amount,
      cycle_duration_label,
      billing_starting_from_label
    } = order;

    const renderPlanSection = () => {
      return (
        <Box mb={2}>
          <Paper square className='py-3 px-3'>
            <Typography variant='h6'>{t('recurringBill.plan')}</Typography>
            <Divider className='my-3' />
            <Grid container direction='column'>
              <Grid item xs={8}>
                <Typography variant='body1'>{title}</Typography>
                <Typography variant='body2' className={classes.orderItemDescription}>{description}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      );
    };

    const renderBillingSection = () => {
      return (
        <Box mb={2}>
          <Paper square className='py-3 px-3'>
            <Typography variant='h6'>{t('recurringBill.billing')}</Typography>
            <Divider className='my-3' />
            <Grid container direction='column'>
              <Grid container item>
                <Grid item xs={8}>
                  <Typography variant='body1'>{cycle_duration_label}</Typography>
                  {
                    billing_starting_from_label
                      ? <Typography variant='body2' className={classes.orderItemDescription}>{billing_starting_from_label}</Typography>
                      : null
                  }
                </Grid>
                <Grid item container xs={4} alignItems='center' justifyContent='flex-end'>
                  <Typography variant='body1'>{`${currency_code} ${AppHelper.getCurrencySignByCurrencyCode(currency_code)}${parseFloat(recurring_amount).toFixed(2)}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      );
    };

    const renderTnCAndSubscribeButton = () => {
      return (
        <Box mb={2}>
          <Paper square className='py-3 px-3'>
            <Grid container direction='column'>
              <Grid item className='py-2 px-2'>
                <Typography style={{ marginRight: 6 }}>
                  <LinkIt
                    component={(match, key) => {
                      const text = match.split(/\[(.*?)\]/)[1];
                      const link = match.split(/[()]/)[1];

                      return (<a href={link} target="_blank" rel="noopener noreferrer" key={key}>{text}</a>);
                    }}
                    regex={/\[(.*?)\]\((.*?)\)/}
                  >
                    {order.tnc_disclaimer}
                  </LinkIt>
                </Typography>
              </Grid>
              <Grid item container className='px-2' justifyContent='flex-end'>
                <Button
                  disabled={!selectedGatewayPaymentMethod || !draftParamRef.current}
                  variant='contained'
                  color='primary'
                  onClick={onSubscribeClick}
                >
                  {t('recurringBill.subscribe')}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      );
    };
    
    return (
      <Grid item>
        {renderPlanSection()}
        {renderBillingSection()}
        {renderTnCAndSubscribeButton()}
      </Grid>
    );
  };

  const renderPaymentMethodColumn = () => {
    const renderPaymentMethodSelector = () => {
      return (
        <FormControl fullWidth className='mb-3'>
          <GatewayPaymentMethodPicker
            selectedPaymentMethod={selectedGatewayPaymentMethod}
            onSelect={onPaymentMethodSelect}
            currencyCode={null}
            textFieldProps={{
              variant: 'outlined',
              // label: t('order.paymentMethod'),
              helperText: ''
            }}
          />
        </FormControl>
      );
    };

    const renderAddPaymentMethodButton = () => {
      return (
        <Grid container style={{ position: 'relative' }}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            startIcon={<AddIcon color='primary' />}
            style={{ textTransform: 'none' }}
            onClick={onAddNewGatewayPaymentMethodClick}
          >
            <Typography>{t('gatewayPaymentMethod.addNewPaymentMethod')}</Typography>
          </Button>
        </Grid>
      );
    };

    return (
      <Paper square className='py-3 px-3'>
        <Typography variant='h6'>{t('order.paymentMethod')}</Typography>
        <Divider className='my-3' />
        {renderPaymentMethodSelector()}
        {renderAddPaymentMethodButton()}
      </Paper>
    );
  };

  const render = () => {
    return (
      <Grid container justifyContent='center' style={{ backgroundColor: '#fafafa', minHeight: '100vh', paddingTop: 60, paddingBottom: 60 }}>
        <CssBaseline />
        <LogoAppBar />
        <Grid
          container
          spacing={2}
          style={{ maxWidth: '80%' }}
          className='py-3 px-3'
        >
          <Grid xs={12} md={8} item container direction='column'>
            {renderRecurringBillContent()}
          </Grid>
          <Grid xs={12} md={4} item>
            {renderPaymentMethodColumn()}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return render();
};

const styles = (/* theme */) => ({
  orderItemDescription: {
    color: '#888'
  }
});

const mapStateToProps = (state) => {
  const {
    draftOrder
  } = state.recurringBill;
  return { 
    draftOrder
  };
};

export default connect(mapStateToProps, {
  getRecurringBillDraft,
  getGatewayPaymentMethodList,
  createRecurringBill,
  updateRecurringBillDraftOrderState,
  resetRecurringBillDraftOrderState,
  openDialog
})(withStyles(styles)(RecurringBillPaymentScreen));