import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Grid, Paper, Typography, colors } from '@material-ui/core';
import moment from 'moment';

import GLOBAL from '../../helpers/GLOBAL';

const self = GLOBAL.get('user');

const useStyles = makeStyles({
  root: {
    paddingTop: 20,
  },
  paper: {
    marginBottom: 4,
    padding: 10,
    maxWidth: 'calc(100vw - 800px)',
    backgroundColor: colors.blue[500],
    color: '#fff',
    borderRadius: 8,
  },
  selfBG: {
    backgroundColor: '#fff',
    color: '#000'
  },
  leftAvatar: {
    marginRight: 14
  },
  rightAvatar: {
    marginLeft: 14,
  }
});

const Message = (props) => {
  const classes = useStyles();

  const {
    user_id,
    message,
    time_stamp
  } = props.item;

  const {
    otherUserAvatar
  } = props;

  const datetime = moment(new Date(parseInt(time_stamp, 10)));
  const timestamp = datetime.format('hh:mm a');
  

  if (self.id === user_id) {
    return (
      <Grid container direction="row" justify="flex-end" className={classes.root}>
        <div>
          <Paper elevation={1} className={`${classes.paper} ${classes.selfBG}`}>
            <Typography>{message}</Typography>
          </Paper>
          <Typography variant="body2" color="textSecondary" align="right">{timestamp}</Typography>
        </div>
        

        <Avatar src={self.avatarUrl} className={classes.rightAvatar} />
      </Grid>
    );
  }

  return (
    <Grid container direction="row" className={classes.root}>
      <Avatar src={otherUserAvatar} className={classes.leftAvatar} />
      
      <div>
        <Paper elevation={1} className={classes.paper}>
          <Typography>{message}</Typography>
        </Paper>
        <Typography variant="body2" color="textSecondary">{timestamp}</Typography>
      </div>
    </Grid>
  );
};

export default Message;
