import React, { useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { BraintreeDropIn, StripeElement, LogoAppBar } from '../common';
import GatewayPaymentMethodTypePicker from './GatewayPaymentMethodTypePicker';
import { useTranslation } from 'react-i18next';
import {
  appStartsLoading,
  appDoneLoading,
  openDialog,
  getCreatePaymentMethodCredential,
  createBraintreePaymentMethod
} from '../../actions';
import { connect } from 'react-redux';
import { AlertDialog } from '../dialogs';

const AddNewGatewayPaymentMethodScreen = (props) => {
  const {
    location,
    history
  } = props;

  const [selectedGatewayPaymentMethodType, setSelectedGatewayPaymentMethodType] = useState('');
  const [isPaymentMethodTypeFixed, setIsPaymentMethodTypeFixed] = useState(false);
  const [paymentGatewayParams, setPaymentGatewayParams] = useState(null);
  const currencyCode = location?.state?.currencyCode;
  const returnUrl = location?.state?.returnUrl;

  const { t } = useTranslation();
  const btDiv = useRef(null);

  const onGatewayPaymentMethodTypeSelect = (value) => {
    setSelectedGatewayPaymentMethodType(value);
  };

  const onAddPaymentMethodClick = () => {
    if (selectedGatewayPaymentMethodType) {
      setIsPaymentMethodTypeFixed(true);

      props.getCreatePaymentMethodCredential({
        type: selectedGatewayPaymentMethodType,
        currencyCode: null,
        callback: ({ response, error }) => {
          if (response) {
            const { gateway, credentials } = response;

            setPaymentGatewayParams({ gateway, credentials });
          } else if (error) {
            props.openDialog({
              dialog: AlertDialog,
              props: {
                title: t('error'),
                contentText: t('alert.somethingWentWrong'),
                disableEscapeKeyDown: true,
                disableBackdropClick: true,
                buttons: [
                  {
                    text: t('alert.ok'),
                    primary: true,
                    onClick: () => {
                      history.goBack();
                    }
                  }
                ]
              }
            });
          }
        }
      });
    }
  };

  const onCancelAddClick = () => {
    setIsPaymentMethodTypeFixed(false);
    setPaymentGatewayParams(null);
  };

  const onStripeError = ({ error }) => {
    console.log('STRIPE_ERROR', error);

    props.openDialog({
      dialog: AlertDialog,
      props: {
        title: t('error'),
        contentText: t('alert.somethingWentWrong'),
        disableEscapeKeyDown: true,
        disableBackdropClick: true,
        buttons: [
          {
            text: t('alert.ok'),
            primary: true,
            onClick: () => {
              history.goBack();
            }
          }
        ]
      }
    });
  };

  const onBTError = (/* error */) => {
    // console.log(error);
  };

  const onBTInit = (/* { instance } */) => {
    // setBtInstance(instance);
    if (btDiv) {
      btDiv.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onPaymentMethodNonceReceived = ({ nonce }) => {
    // console.log('bt nonce', nonce);

    props.appStartsLoading();
    props.createBraintreePaymentMethod({
      nonce,
      callback: ({ response, error }) => {
        props.appDoneLoading();
        if (response) {
          window.location.href = returnUrl;
        } else if (error) {
          let alertTitle = t('error');
          let alertText = t('alert.somethingWentWrong');

          if (error === 303) {
            alertTitle = t('gatewayPaymentMethod.duplicatePaymentMethod');
            alertText = t('gatewayPaymentMethod.youHaveEnteredADuplicatePaymentMethod');
          }

          props.openDialog({
            dialog: AlertDialog,
            props: {
              title: alertTitle,
              contentText: alertText,
              disableEscapeKeyDown: true,
              disableBackdropClick: true,
              buttons: [
                {
                  text: t('alert.ok'),
                  primary: true,
                  onClick: () => {
                    window.location.href = returnUrl;
                  }
                }
              ]
            }
          });
        }
      }
    });
  };

  const parseBraintreeOptions = ({ btConfig }) => {
    const {
      clientToken,
      cardholderName,
      disablePaypal,
      disableCard
    } = btConfig;

    let cardOptions = undefined;
    let paypalOptions = undefined;

    if (disableCard) {
      cardOptions = false;
    } else {
      if (cardholderName === 'required') {
        cardOptions = {
          cardholderName: { required: true }
        };
      }
    }

    if (disablePaypal) {
      paypalOptions = false;
    } else {
      paypalOptions = {
        flow: 'vault'
      };
    }

    return {
      card: cardOptions,
      paypal: paypalOptions,
      clientToken
    };
  };

  const renderPaymentGatewayComponent = () => {
    if (!isPaymentMethodTypeFixed || !paymentGatewayParams) {
      return null;
    }

    const {
      gateway,
      credentials
    } = paymentGatewayParams;

    if (gateway === 'stripe') {
      return (
        <Grid item>
          <Paper square className='px-3 py-3'>
            <StripeElement
              onLoadingStart={props.appStartsLoading}
              onLoadingEnd={props.appDoneLoading}
              publishableKey={credentials.publishableKey}
              elementOptions={{
                clientSecret: credentials.setupIntent
              }}
              payButtonLabel={t('gatewayPaymentMethod.addNewPaymentMethod')}
              onError={onStripeError}
              returnUrl={returnUrl}
            />
          </Paper>
        </Grid>
      );
    } else if (gateway === 'braintree') {
      const btOptions = parseBraintreeOptions(credentials);
      return (
        <Grid item ref={btDiv}>
          <Paper square className='px-3 py-3'>
            <BraintreeDropIn
              onError={onBTError}
              onInitialized={onBTInit}
              onPaymentMethodNonceReceived={onPaymentMethodNonceReceived}
              payButtonLabel={t('gatewayPaymentMethod.addNewPaymentMethod')}
              clientToken={btOptions.clientToken}
              cardOptions={btOptions.card}
              paypalOptions={btOptions.paypal}
            />
          </Paper>
        </Grid>
      );
    }

    return null;
  };

  const renderOperationButton = () => {
    if (isPaymentMethodTypeFixed) {
      return (
        <Grid className='mt-2' item container justifyContent='flex-end'>
          <Button
            variant='outlined'
            style={{ borderColor: 'red', color: 'red' }}
            onClick={onCancelAddClick}
          >
            {t('alert.cancel')}
          </Button>
        </Grid>
      );
    }

    return (
      <Grid className='mt-2' item container justifyContent='flex-end'>
        <Button
          variant='contained'
          color='primary'
          onClick={onAddPaymentMethodClick}
        >
          {t('gatewayPaymentMethod.add')}
        </Button>
      </Grid>
    );
  };

  const render = () => {
    return (
      <Grid
        container
        alignItems='center'
        direction='column'
        style={{ backgroundColor: '#fafafa', minHeight: '100vh', paddingTop: 60 }}
      >
        <LogoAppBar
          showBackButton
        />  
        <Grid
          container
          style={{ maxWidth: '90%' }}
          className='py-3 px-3'
          direction='column'
          alignItems='center'
        >
          <Grid xs={12} md={6} item container direction='column' spacing={2}>
            <Grid item>
              <Paper square className='px-3 py-3'>
                <Typography variant='h6'>{t('gatewayPaymentMethod.addNewPaymentMethod')}</Typography>
                {/* <Divider className='mt-2' /> */}
                <FormControl fullWidth className={'mt-3'}>
                  <GatewayPaymentMethodTypePicker
                    selectedGatewayPaymentMethodType={selectedGatewayPaymentMethodType}
                    currencyCode={currencyCode}
                    textFieldProps={{ variant: 'outlined', helperText: '', label: t('gatewayPaymentMethod.paymentMethodType'), disabled: isPaymentMethodTypeFixed }}
                    onSelect={onGatewayPaymentMethodTypeSelect}
                  />
                </FormControl>
                {
                  selectedGatewayPaymentMethodType
                    ? renderOperationButton()
                    : null
                }
              </Paper>
            </Grid>
            {renderPaymentGatewayComponent()}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return render();
};

export default connect(null, {
  getCreatePaymentMethodCredential,
  appStartsLoading,
  appDoneLoading,
  openDialog,
  createBraintreePaymentMethod
})(AddNewGatewayPaymentMethodScreen);