import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getStore } from '../Store';

import { LessonModal } from '../components/modals';
import JitsiModal from '../components/LessonScreen/JitsiModal';

import AuthHelper from '../helpers/AuthHelper';
import { NavigationBarWrapper } from '../components/common';
import { updateCommonStates } from '../actions';

export const PrivateRoute = ({ component: Component, isStandalone, compProps, ...props }) => {
  useEffect(() => {
    if (!AuthHelper.isLoggedIn()) {
      getStore().dispatch(updateCommonStates({ prop: 'redirectTo', value: props.location }));
    }
  }, []);

  const render = () => {
    const loggedIn = AuthHelper.isLoggedIn();

    return (
      <Route
        {...props}
        render={routeProps => 
          loggedIn ? (
            isStandalone ? (
              <Component {...routeProps} {...compProps} />
            ) : (
              <NavigationBarWrapper>
                <Component {...routeProps} {...compProps} />

                {/* GLOBAL MODALS after logged in */}
                <LessonModal />
                <JitsiModal />
              </NavigationBarWrapper>
            )
          ) : (
            <Redirect to={'/sign-in'} />
          )
        }
      />
    );
  };
  
  return render();
};
