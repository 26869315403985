import { UPDATE_ORDER_STATE, RESET_ORDER_DETAIL_STATE } from './types';
import {
  createNewSubscriptionOrder,
  getOrderByRefId,
  makePaymentForOrderViaWallet,
  makePaymentForOrderViaBraintree
} from '../api';
import {
  appStartsLoading,
  appDoneLoading
} from './';

export const resetOrderDetailState = () => {
  return {
    type: RESET_ORDER_DETAIL_STATE
  };
};

export const updateOrderState = ({ prop, value }) => {
  return {
    type: UPDATE_ORDER_STATE,
    payload: { prop, value }
  };
};

export const createOrder = ({
  orderCurrencyCode,
  orderAmount,
  planId,
  callback
}) => {
  return (dispatch) => {
    dispatch(appStartsLoading());
    createNewSubscriptionOrder({
      orderCurrencyCode,
      orderAmount,
      planId
    })
      .then((response) => {
        dispatch(appDoneLoading());
        // const { orderId } = response.data;

        if (callback) {
          callback({ response: response.data });
        }
      })
      .catch((error) => {
        dispatch(appDoneLoading());
        console.error(error);
        callback({ error });
      });
  };
};

export const getOrder = ({ orderRefId, status, callback }) => {
  return (dispatch) => {
    dispatch(appStartsLoading());
    getOrderByRefId({ orderRefId, status })
      .then((response) => {
        dispatch(appDoneLoading());
        // console.log(response.data);
        dispatch(updateOrderState({ prop: 'orderDetails', value: response.data.order }));
        if (callback) { callback({ response: response.data }); }
      })
      .catch((error) => {
        console.error(error);
        dispatch(appDoneLoading());
        if (callback) { callback({ error }); }
      });
  };
};

export const payPendingOrderViaWallet = ({
  orderRefId,
  currencyCode,
  amount,
  callback
}) => {
  return (dispatch) => {
    dispatch(appStartsLoading());
    makePaymentForOrderViaWallet({ orderRefId, currencyCode, amount })
      .then((response) => {
        // console.log(response.data);
        dispatch(appDoneLoading());

        if (callback) {
          callback({ response: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(appDoneLoading());

        if (callback) {
          callback({ error });
        }
      });
  };
};

export const payPendingOrderViaBraintree = ({
  orderRefId,
  currencyCode,
  amount,
  nonce,
  callback
}) => {
  return (dispatch) => {
    dispatch(appStartsLoading());
    makePaymentForOrderViaBraintree({
      orderRefId,
      currencyCode,
      amount,
      nonce
    })
      .then((response) => {
        // console.log(response.data);
        dispatch(appDoneLoading());

        if (callback) {
          callback({ response: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(appDoneLoading());

        if (callback) {
          callback({ error });
        }
      });
  };
};