import update from 'immutability-helper';
import {
  UPDATE_ORG_STATE,
  UPDATE_NEW_ORG_STATE,
  RESET_NEW_ORG_STATE,
  UPDATE_SCHOOL_PROFILE_STATE,
  RESET_SCHOOL_PROFILE_STATE,
  UPDATE_SCHOOL_ACHIEVEMENTS_ITEM_STATE,
  UPDATE_SCHOOL_ACHIEVEMENTS_STATE,
  RESET_SCHOOL_ACHIEVEMENTS_STATE,
  UPDATE_SCHOOL_PROFILE_TEACHERS_STATE,
  UPDATE_SCHOOL_PROFILE_STUDENTS_STATE,
  RESET_SCHOOL_PROFILE_TEACHERS_STATE,
  RESET_SCHOOL_PROFILE_STUDENTS_STATE,
  UPDATE_NEW_ORG_FEE_CATEGORY_STATE,
  RESET_NEW_ORG_FEE_CATEGORY_STATE,
  SHOW_ORG_MODAL,
  HIDE_ORG_MODAL,
  LOGOUT_USER_SUCCESS,
  UPDATE_ORG_FEE_TEACHER_LIST_ADMIN_STATE,
  RESET_ORG_FEE_TEACHER_LIST_ADMIN,
  UPDATE_ORG_FEE_CATEGORY_LIST_STATE,
  UPDATE_ORG_FEE_CATEGORY_EDIT_STATE,
  RESET_ORG_FEE_CATEGORY_EDIT_STATE
} from '../actions/types';

const INITIAL_ORG_MODAL_STATE = {
  mode: 'success',
  title: '',
  content: '',
  visible: false
};

const INITIAL_NEW_ORG_STATE = {
  schoolName: '',
  description: '',
  yearFounded: '',
  profitPercentage: 10,
  avatarPath: '',
  address: '',
  discountPercentage: 5
};

const INITIAL_SCHOOL_ACHIEVEMENTS_STATE = {
  achievementList: [],
  maxPage: 0,
  isLoading: false,
  isNewPageLoading: false
};

const INITIAL_SCHOOL_PROFILE_STATE = {
  profileAnswers: []
};

const INITIAL_SCHOOL_PROFILE_TEACHERS_STATE = {
  teacherList: [],
  maxPage: 0,
  isLoading: false,
  isNewPageLoading: false
};

const INITIAL_SCHOOL_PROFILE_STUDENTS_STATE = {
  studentList: [],
  maxPage: 0,
  isLoading: false,
  isNewPageLoading: false
};

const INITIAL_ORG_FEE_CATEGORY_LIST_STATE = {
  isLoading: false,
  list: []
};

const INITIAL_ORG_FEE_CATEGORY_EDIT_STATE = {
  organisationId: null,
  label: '',
  price: 100,
  teacherPrice: 80,
  currencyCode: '',
  profitPercentage: 20,
  isDefault: false,
  duration: 60,
  canTeacherChangePrice: false,
  teach_profiles: [],
  teachProfileIds: [],
  params: {
    min_fee: 100,
    max_fee: 500,
    min_profit_percentage: 5,
    max_profit_percentage: 50,
    canCustomizeProfitPercentage: false,
    defaultProfitPercentage: 20
  },
  hasUnsavedChanges: false
};

const INITIAL_NEW_ORG_FEE_CATEGORY_STATE = {
  organisationId: null,
  label: '',
  price: 100,
  teacherPrice: 80,
  profitPercentage: 20,
  isDefault: false,
  canTeacherChangePrice: false,
  teachProfiles: [],
  teachProfileIds: []
};

const INITIAL_ORG_FEE_CATEGORY_SETTINGS_STATE = {
  min_fee: 100,
  max_fee: 500,
  min_profit_percentage: 5,
  max_profit_percentage: 50,
  duration: 60,
  canCustomizeProfitPercentage: false,
  currency_code: '',
  defaultProfitPercentage: 20,
  refreshed: false
};

const INITIAL_ORG_TEACHER_FOR_ADMIN_LIST_STATE = {
  isLoading: false,
  isNewPageLoading: false,
  maxPage: 0,
  teacherList: [],
};

const INITIAL_STATE = {
  newOrganisation: INITIAL_NEW_ORG_STATE,
  orgModal: INITIAL_ORG_MODAL_STATE,
  schoolProfile: INITIAL_SCHOOL_PROFILE_STATE,
  schoolAchievements: INITIAL_SCHOOL_ACHIEVEMENTS_STATE,
  managingSchools: [],
  schoolProfileTeachers: INITIAL_SCHOOL_PROFILE_TEACHERS_STATE,
  schoolProfileStudents: INITIAL_SCHOOL_PROFILE_STUDENTS_STATE,
  pendingMembershipList: [],
  blacklistedMembershipList: [],
  isOrganisationListLoading: false,
  joinedOrgMemberships: [],
  orgFeeCategoryList: INITIAL_ORG_FEE_CATEGORY_LIST_STATE,
  orgFeeCategoryEdit: INITIAL_ORG_FEE_CATEGORY_EDIT_STATE,
  newOrgFeeCategory: INITIAL_NEW_ORG_FEE_CATEGORY_STATE,
  orgFeeCategorySettings: INITIAL_ORG_FEE_CATEGORY_SETTINGS_STATE,
  orgTeacherForAdminList: INITIAL_ORG_TEACHER_FOR_ADMIN_LIST_STATE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_ORG_STATE:
      return { ...state, [action.payload.prop]: action.payload.value };
    case UPDATE_NEW_ORG_STATE:
      return { ...state, newOrganisation: { ...state.newOrganisation, [action.payload.prop]: action.payload.value } };
    case RESET_NEW_ORG_STATE:
      return { ...state, newOrganisation: INITIAL_NEW_ORG_STATE };
    case UPDATE_SCHOOL_PROFILE_STATE:
      return { ...state, schoolProfile: { ...state.schoolProfile, [action.payload.prop]: action.payload.value } };
    case RESET_SCHOOL_PROFILE_STATE:
      return { ...state, schoolProfile: INITIAL_SCHOOL_PROFILE_STATE };
    case SHOW_ORG_MODAL:
      return { ...state, orgModal: { title: action.payload.title, content: action.payload.content, mode: action.payload.mode, visible: true } };
    case HIDE_ORG_MODAL:
      return { ...state, orgModal: { ...state.orgModal, visible: false } };
    case UPDATE_SCHOOL_ACHIEVEMENTS_ITEM_STATE:
      if (state.schoolAchievements.achievementList[action.payload.index]) {
        return update(state, {
          schoolAchievements: {
            achievementList: {
              [action.payload.index]: {
                [action.payload.prop]: { $set: action.payload.value }
              }
            }
          }
        });
      }
      return state;
    case UPDATE_SCHOOL_ACHIEVEMENTS_STATE:
      return { ...state, schoolAchievements: { ...state.schoolAchievements, [action.payload.prop]: action.payload.value } };
    case RESET_SCHOOL_ACHIEVEMENTS_STATE:
      return { ...state, schoolAchievements: INITIAL_SCHOOL_ACHIEVEMENTS_STATE };
    case UPDATE_SCHOOL_PROFILE_TEACHERS_STATE:
      return { ...state, schoolProfileTeachers: { ...state.schoolProfileTeachers, [action.payload.prop]: action.payload.value } };
    case UPDATE_SCHOOL_PROFILE_STUDENTS_STATE:
      return { ...state, schoolProfileStudents: { ...state.schoolProfileStudents, [action.payload.prop]: action.payload.value } };
    case RESET_SCHOOL_PROFILE_TEACHERS_STATE:
      return { ...state, schoolProfileTeachers: INITIAL_SCHOOL_PROFILE_TEACHERS_STATE };
    case RESET_SCHOOL_PROFILE_STUDENTS_STATE:
      return { ...state, schoolProfileStudents: INITIAL_SCHOOL_PROFILE_STUDENTS_STATE };
    case UPDATE_NEW_ORG_FEE_CATEGORY_STATE:
      return { ...state, newOrgFeeCategory: { ...state.newOrgFeeCategory, [action.payload.prop]: action.payload.value } };
    case RESET_NEW_ORG_FEE_CATEGORY_STATE:
      return { ...state, newOrgFeeCategory: INITIAL_NEW_ORG_FEE_CATEGORY_STATE };
    case UPDATE_ORG_FEE_TEACHER_LIST_ADMIN_STATE:
      return { ...state, orgTeacherForAdminList: { ...state.orgTeacherForAdminList, [action.payload.prop]: action.payload.value } };
    case RESET_ORG_FEE_TEACHER_LIST_ADMIN:
      return { ...state, orgTeacherForAdminList: INITIAL_ORG_TEACHER_FOR_ADMIN_LIST_STATE };
    case UPDATE_ORG_FEE_CATEGORY_LIST_STATE:
      return { ...state, orgFeeCategoryList: { ...state.orgFeeCategoryList, [action.payload.prop]: action.payload.value } };
    case UPDATE_ORG_FEE_CATEGORY_EDIT_STATE:
      if (action.doNotTouch) {
        return { ...state, orgFeeCategoryEdit: { ...state.orgFeeCategoryEdit, [action.payload.prop]: action.payload.value } };
      }
      return { ...state, orgFeeCategoryEdit: { ...state.orgFeeCategoryEdit, [action.payload.prop]: action.payload.value, hasUnsavedChanges: true } };
    case RESET_ORG_FEE_CATEGORY_EDIT_STATE:
      return { ...state, orgFeeCategoryEdit: INITIAL_ORG_FEE_CATEGORY_EDIT_STATE };
    case LOGOUT_USER_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
