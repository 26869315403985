import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Autorenew';
import { makeStyles, styled } from '@material-ui/styles';
import {
  getPracticeRoomParticipantList,
  setParticipantAudioConnectedState,
  sendPracticeModeSocketEvent,
  createEnterPracticeModeMeetLog,
  sendLessonModeSocketEvent,
  createEnterLessonModeMeetLog,
  updatePracticeRoomConfigState,
  openDialog,
  saveTRUserPracticeNote,
  addParticipantFromTutorRoom,
  kickParticipantFromTutorRoom,
  createEnterPracticeRoomMeetLog
} from '../../../actions';
import ParticipantListItem from './ParticipantListItem';
import AppHelper from '../../../helpers/AppHelper';
import JitsiExternalApiHelper from '../../../helpers/JitsiExternalApiHelper';
import { CoffeeBreakDialog } from '../../dialogs/tutorRoom/CoffeeBreakDialog';
import { useTranslation } from 'react-i18next';
import { AlertDialog } from '../../dialogs';

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    backgroundColor: '#ffffff'
  },
  selfMissingButton: {
    color: '#fff',
    borderRadius: 5,
    padding: 10,
    backgroundColor: '#ff9c00'
  },
  otherUserMissingButton: {
    color: '#fff',
    borderRadius: 5,
    padding: 10,
    backgroundColor: 'rgb(0, 122, 255)'
  }
}));

// .MuiDrawer-paper overwrite can't be applied as style prop, so has to use this styled() function
const Drawer = styled(_Drawer, { shouldForwardProp: (prop) => prop !== 'width' })(
  ({ width }) => ({
    width,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width,
      boxSizing: 'border-box',
      backgroundColor: '#fafafa'
    }
  }),
);

const ParticipantListDrawer = (props) => {
  const {
    open,
    onClose,
    width,
    practiceRoomConfig,
    isConferenceJoined,
    students,
    studentToRescue,
    isOnCoffeeBreak,
    now
  } = props;

  const {
    isLoadingParticipant: isLoading,
    audioConnectedParticipants,
    jitsiParticipants,
    participants,
    JitsiMeetAPI
  } = practiceRoomConfig;

  const { t } = useTranslation();
  const classes = useStyles();
  const [skippedStudents, setSkippedStudents] = useState(0);
  const [participantMenuAnchorEl, setParticipantMenuAnchorEl] = useState(null);
  const [menuParticipant, setMenuParticipant] = useState(null);

  useEffect(() => {
    getParticipantList();
  }, [practiceRoomConfig.practice_room_id]);

  useEffect(() => {
    if (open) {
      getParticipantList();
    }
  }, [open]);

  const onRefreshClick = () => {
    getParticipantList();
  };

  const onSkipStudentPress = () => {
    setSkippedStudents(skippedStudents + 1);
  };

  const onMoreClick = ({ anchorEl, participant }) => {
    setParticipantMenuAnchorEl(anchorEl);
    setMenuParticipant(participant);
  };

  const resetMenuStates = () => {
    setParticipantMenuAnchorEl(null);
    setMenuParticipant(null);
  };

  const getParticipantList = () => {
    props.getPracticeRoomParticipantList({ practiceRoomId: practiceRoomConfig.practice_room_id });
    setSkippedStudents(0);
  };

  const onPracticeModeStartPress = ({ practiceRoomParticipantId, userId }) => {
    setSkippedStudents(0);
    props.sendPracticeModeSocketEvent({ practiceRoomParticipantId });
    props.createEnterPracticeModeMeetLog({ logUserId: userId });
  };

  const onPracticeModeStopPress = ({ practiceRoomParticipantId, userId }) => {
    if (isOnCoffeeBreak) {
      AppHelper.showSnackbarNotification({
        message: t('tutorRoom.coffeeBreak.pleaseTurnOffCoffeeBreak')
      });

      props.openDialog({
        dialog: CoffeeBreakDialog,
        props: {
          disableEscapeKeyDown: true,
          disableBackdropClick: true
        }
      });

      return;
    }

    setSkippedStudents(0);
    props.sendLessonModeSocketEvent({ practiceRoomParticipantId });
    props.createEnterLessonModeMeetLog({ logUserId: userId });

    // once taught, remove the rescue flag
    props.updatePracticeRoomConfigState({
      prop: 'rescueSignal',
      value: {
        student: null,
        fromUser: null
      }
    });
  };

  const onSaveParticipantNotePress = ({
    practiceParticipantDetailId,
    userId,
    remarks,
    practiceSubjectId,
    remarksEditedAt,
    callback
  }) => {
    props.saveTRUserPracticeNote({
      practiceParticipantDetailId,
      userId,
      remarks,
      practiceSubjectId,
      remarksEditedAt,
      practiceRoomId: practiceRoomConfig.practice_room_id,
      callback
    });
  };

  const onSetAudioTrackEnabled = ({ enabled, identityId }) => {
    JitsiExternalApiHelper.setUserAudioEnabled({
      apiInstance: JitsiMeetAPI,
      jitsiParticipants,
      userId: identityId,
      enabled
    });
  };

  const onSetAudioConnectedPress = ({
    practiceRoomParticipantId,
    shouldConnect,
    isSilently = false
  }) => {
    props.setParticipantAudioConnectedState({
      practiceRoomParticipantId,
      shouldConnect,
      isSilently
    });
  };

  const onMenuKickParticipantPress = () => {
    resetMenuStates();

    if (menuParticipant) {
      props.openDialog({
        dialog: AlertDialog,
        props: {
          title: t('controlRoom.kickStudentQuestion'),
          contentText: t('tutorRoom.confirmKickStudent', {
            participant: AppHelper.formatName(menuParticipant.first_name, menuParticipant.last_name?.charAt(0))?.split('[')?.[0]
          }),
          buttons: [
            {
              text: t('alert.no'),
              primary: true
            },
            {
              text: t('alert.yes'),
              onClick: () => {
                props.kickParticipantFromTutorRoom({ logUserId: menuParticipant.user_id });
              }
            }
          ]
        }
      });
    }
  };

  const onMissingSelfPress = () => {
    props.createEnterPracticeRoomMeetLog(practiceRoomConfig);
  };

  const onOtherMissingUserPress = () => {
    const missingOtherUserIdsInList = getOtherUserIdsMissingFromParticipantList();

    if (missingOtherUserIdsInList && missingOtherUserIdsInList.length > 0) {
      props.addParticipantFromTutorRoom({ logUserId: missingOtherUserIdsInList[0] });
    }
  };

  const getJitsiParticipant = ({ userId }) => {
    if (jitsiParticipants[`u_${userId}`]) {
      return jitsiParticipants[`u_${userId}`][0];
    }

    return undefined;
  };

  const isUserTeachingToAnyone = () => {
    let isTeaching = false;

    for (let i = 0; i < participants.length; i++) {
      if (participants[i].lesson_mode_tutor_user_id === AppHelper.getUserId()) {
        isTeaching = true;
        break;
      }
    }

    return isTeaching;
  };

  const shouldEnableTutorModeButton = ({ item }) => {
    if (isUserTeachingToAnyone()) {
      return false;
    }

    if (students && students.length > 0) {
      /*
        * If skipped student is 0, then only the first student can be taught.
        * If skipped student is 1, then first 2 students can be taught.
        * If skipped student is 5, then first 6 students can be taught.
        */
      const studentsCanBeTaught = students.slice(0, skippedStudents + 1);
      const isMatched = studentsCanBeTaught.find(
        s => s.practice_room_participant_id === item.practice_room_participant_id);

      if (isMatched !== undefined && isMatched !== null) {
        return true;
      }
    }

    return false;
  };

  const shouldShowRescueIndicator = (_participant) => {
    if (
      (
        (
          studentToRescue
          && studentToRescue.user_id === _participant.user_id
          && !_participant.lesson_mode_at
        )

        /* this flag is here just in case we need to move the flag to the server/db */
        || _participant.should_rescue
      )
      // && getJitsiParticipant({ userId: _participant.user_id })
    ) {
      return true;
    }

    return false;
  };

  const shouldShowSkipStudentButton = ({ item }) => {
    if (isUserTeachingToAnyone()) {
      return false;
    }

    if (students && students.length > 0) {
      /*
      * If skipped student is 0, then only show skip button for first student.
      * If skipped student is 2, then only show skip button for third student.
      * Don't show skip button for LAST student.
      */
      const lastNotSkippedStudent = students[skippedStudents];

      if (lastNotSkippedStudent) {
        if (
          lastNotSkippedStudent.practice_room_participant_id === item.practice_room_participant_id
          && skippedStudents !== (students.length - 1)
        ) {
          return true;
        }
      }
    }

    return false;
  };

  const getOtherUserIdsMissingFromParticipantList = () => {
    const missingUserIds = [];

    Object.keys(jitsiParticipants).forEach(_jitsiP => {
      const parsedUserId = _jitsiP.split('_');

      if (parsedUserId.length > 1) {
        const userId = parseInt(parsedUserId[1], 10);

        let isInList = false;

        for (const _p of participants) {
          if (parseInt(_p.user_id, 10) === userId) {
            isInList = true;
            break;
          }
        }

        if (!isInList && userId !== AppHelper.getUserId()) {
          missingUserIds.push(userId);
        }
      }
    });

    return missingUserIds;
  };

  const renderItem = ({ item }) => {
    return (
      <ListItem key={item.user_id}>
        <ParticipantListItem
          audioConnectedParticipants={audioConnectedParticipants}
          isLoadingParticipant={isLoading || !isConferenceJoined}
          jitsiParticipant={getJitsiParticipant({ userId: item.user_id })}
          now={now}
          onMorePress={onMoreClick}
          onPracticeModeStartPress={onPracticeModeStartPress}
          onPracticeModeStopPress={onPracticeModeStopPress}
          onSaveParticipantNotePress={onSaveParticipantNotePress}
          onSetAudioConnectedPress={onSetAudioConnectedPress}
          onSetAudioTrackEnabled={onSetAudioTrackEnabled}
          onSkipPress={onSkipStudentPress}
          onUpdatePracticeParticipantLock={() => {}}
          participant={item}
          shouldEnableTutorModeButton={shouldEnableTutorModeButton({ item })}
          shouldRescue={shouldShowRescueIndicator(item)}
          shouldShowSkipStudentButton={shouldShowSkipStudentButton({ item })}
        />
      </ListItem>
    );
  };

  /**
   * We place the menu component here, so that we don't have render multiple ones
   * in ParticipantListItem.
   * @returns {ReactElement}
   */
  const renderParticipantMenu = () => {
    return (
      <Menu
        id="participant-menu"
        anchorEl={participantMenuAnchorEl}
        keepMounted
        open={Boolean(participantMenuAnchorEl)}
        onClose={resetMenuStates}
      >
        <MenuItem onClick={onMenuKickParticipantPress}>{t('controlRoom.kick')}</MenuItem>
      </Menu>
    );
  };

  const renderListHeader = () => {
    if (!isLoading) {
      const user = participants.find(p => p.user_id === AppHelper.getUserId());
      const missingOtherUserIdsInList = getOtherUserIdsMissingFromParticipantList();

      return (
        <div>
          {
            !user
              ? <ListItem>
                <div className={`button ${classes.selfMissingButton}`} onClick={onMissingSelfPress}>
                  {t('tutorRoom.youAreMissingFromTheList')}
                </div>
              </ListItem>
              : null
          }
          {
            missingOtherUserIdsInList.length > 0
              ? <ListItem>
                <div className={`button ${classes.otherUserMissingButton}`} onClick={onOtherMissingUserPress}>
                  {t('tutorRoom.otherUsersAreMissingFromTheList')}
                </div>
              </ListItem>
              : null
          }
        </div>
      );
    }
    
    return null;
  };

  const render = () => {
    return (
      <Drawer
        width={width}
        anchor={'left'}
        variant={'persistent'}
        open={open}
        onClose={onClose}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={onRefreshClick}>
            <RefreshIcon className={isLoading ? 'spinning' : ''} />
          </IconButton>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {renderListHeader()}
          {participants.map((item, index) =>
            renderItem({ item, index }))}
        </List>
        {renderParticipantMenu()}
        <Divider />
      </Drawer>
    );
  };

  return render();
};

const mapStateToProps = (state) => {
  const {
    practiceRoomConfig
  } = state.practiceRoom;

  const {
    participants,
    rescueSignal
  } = practiceRoomConfig;

  const students = participants.filter(p => p.practice_room_role_id === 1 && !p.lesson_mode_at);
  const userParticipant = participants.find(p => p.user_id === AppHelper.getUserId());
  const isOnCoffeeBreak = (userParticipant && userParticipant?.coffee_break_at !== null) ?? false;

  return {
    practiceRoomConfig,
    studentToRescue: rescueSignal?.student,
    students,
    isOnCoffeeBreak
  };
};

export default connect(mapStateToProps, {
  getPracticeRoomParticipantList,
  setParticipantAudioConnectedState,
  sendPracticeModeSocketEvent,
  createEnterPracticeModeMeetLog,
  sendLessonModeSocketEvent,
  createEnterLessonModeMeetLog,
  updatePracticeRoomConfigState,
  openDialog,
  saveTRUserPracticeNote,
  addParticipantFromTutorRoom,
  kickParticipantFromTutorRoom,
  createEnterPracticeRoomMeetLog
})(ParticipantListDrawer);