import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import AppHelper from '../../helpers/AppHelper';

const ParticipantItem = (props) => {
  const {
    participant
  } = props;

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar alt={participant.first_name} src={participant.avatar_url} />
      </ListItemAvatar>
      <ListItemText
        primary={AppHelper.getFullname(participant.first_name, participant.last_name)}
        secondary="Student"
      />
    </ListItem>
  );
};

export { ParticipantItem };
