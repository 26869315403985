import React, { Component } from 'react';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
// import ThirdLogin from '../SigninScreen/ThirdLogin';
import {
  updateResetPasswordStates,
  sendResetPasswordLinkEmail,
  resetAuthModalState,
  validateResetFields
} from '../../actions';
import ApiHelper from '../../helpers/ApiHelper';
// import { labels } from '../../lang/labels';
// import AppHelper from '../../helpers/AppHelper';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        Youbrio
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = (theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${ApiHelper.getCdnUrl('/public/images/login-main.jpg')})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'white',
  },
  avatar: {
    padding: '20px 0 0 20px',
    width: '80px',
    height: '80px',
    // margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
  },
  welcomeSlogan: {
    padding: '10px 0 0 0',
    'text-align': 'left',
    width: '80%',
    height: '100%',
  },
  welcomeSloganLine1: {
    'font-weight': 'bold',
  },
  welcomeSloganLine2: {
    'font-weight': 'bold',
    'color': '#abababcc',
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '80%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    "background-color": "#4cbb2a8c",
  },
  rightSide: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  rightSlogan: {
    padding: '20px 40px 20px 40px',
  },
  rightDesc: {
    padding: '100px 40px 40px 40px',
  },
  rightDescLine: {
    padding: '40px 60px 20px 40px',
  }
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class ResetPasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillMount() {
  }

  componentDidMount() {
  }

  onEmailChange(e) {
    let text = e.target.value;
    this.props.updateResetPasswordStates({ prop: 'email', value: text });
    this.props.validateResetFields({ prop: 'email', value: text });
  }

  onClickResetPasswordButton() {
    const {
      passwordReset
    } = this.props;
    this.props.sendResetPasswordLinkEmail(passwordReset.email);
  }

  render() {
    return this.ResetPasswordSide(this.props.classes);
  }

  ResetPasswordSide(classes) {
    const {
      authModal,
      passwordReset,
    //   loading
    } = this.props;

    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Avatar className={classes.avatar} variant="square" src="https://files-cdn.youbrio.cn/public/images/youbrio-gold.png" />
          <div className={classes.paper}>
            <div className={classes.welcomeSlogan}>
              <Typography component="h1" variant="h3" align="left" className={classes.welcomeSloganLine1}>
                    Hi there,
              </Typography>
              <Typography component="h1" variant="h3" align="left" className={classes.welcomeSloganLine2}>
                    Reset Your Password!
              </Typography>
            </div>
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    value={passwordReset.email}
                    onChange={this.onEmailChange.bind(this)}
                    autoComplete="email"
                    helperText={passwordReset.error || 'your email address'}
                  />
                </Grid>
              </Grid>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.onClickResetPasswordButton.bind(this)}
              >
                    RESET PASSWORD
              </Button>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link href="/sign-in" variant="body2">
                      Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
        <Grid item xs={false} sm={4} md={7} className={classes.image}>
          <div className={classes.rightSide}>
            <div className={classes.rightSlogan}>
              <Typography component="h5" variant="h3" align="left">
                    Online Lessons,
              </Typography>
              <Typography component="h5" variant="h3" align="left" gutterBottom>
                    Anywhere.
              </Typography>
            </div>
            <div className={classes.rightDesc}>
              <ul>
                <li className={classes.rightDescLine}>
                  <Typography component="h5" variant="h6" align="left">
                        Please make sure you have booked the lesson with your student/teacher in the Youbrio APP on your phone or tablet.
                  </Typography>
                </li>
                <li className={classes.rightDescLine}>
                  <Typography variant="h6" align="left">
                        Access the video call through this page when its time for your lesson.
                  </Typography>
                </li>
                <li className={classes.rightDescLine}>
                  <Typography variant="h6" align="left">
                        To access the whiteboard please use your phne or tablet device and press, <Link href="#" color="#1031e4">Dual Device Mode</Link> in your lesson booking.
                  </Typography>
                </li>
                <li className={classes.rightDescLine}>
                  <Typography variant="h6" align="left">
                        For registrations, please download Youbrio on your phone/tablet device to proceed.
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
        </Grid>
        <Snackbar open={authModal.visible} autoHideDuration={6000} onClose={this.handleCloseSnackbar.bind(this)}>
          <Alert onClose={this.handleCloseSnackbar.bind(this)} severity={authModal.mode}>
            { authModal.content }
          </Alert>
        </Snackbar>
      </Grid>
    );
  }

  handleCloseSnackbar(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    // this.props.updateAuthModalState({ prop: 'visible', value: false });
    this.props.history.push('/sign-in');
  }
}

const mapStateToProps = state => {
  const {
    authModal,
    passwordReset
  } = state.auth;

  const {
    loading
  } = state.dialog;

  return {
    authModal,
    passwordReset,
    loading
  };
};

export default withStyles(styles)(withRouter(connect(mapStateToProps, {
  updateResetPasswordStates,
  sendResetPasswordLinkEmail,
  resetAuthModalState,
  validateResetFields
})(ResetPasswordScreen)));


