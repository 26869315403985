import {
  UPDATE_PRACTICE_ROOM_PASS_STATE,
  RESET_PRACTICE_ROOM_PASS_STATE,
} from '../actions/types';

const INITIAL_STATE = {
  isLoading: false,
  availablePracticeRoomPassList: [],
  unavailablePracticeRoomPassList: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UPDATE_PRACTICE_ROOM_PASS_STATE:
    return { ...state, [action.payload.prop]: action.payload.value };
  case RESET_PRACTICE_ROOM_PASS_STATE: {
    return INITIAL_STATE;
  }
  default:
    return state;
  }
};
  
