import {
  UPDATE_YOUBRIO_PROFILE_STATE,
} from '../actions/types';
import {
  getRelatedTeacherList,
  getRelatedStudentList
} from '../api';
import AppHelper from '../helpers/AppHelper';
import { getStore } from '../Store';

export const updateProfileState = ({ prop, value }) => {
  return {
    type: UPDATE_YOUBRIO_PROFILE_STATE,
    payload: { prop, value }
  };
};

/**
 * Get all the users that had lesson with logged in user.
 */
export const getUserList = () => {
  return (dispatch) => {
    const { youbrioProfile } = getStore().getState();
    const { page, lastPage, userList } = youbrioProfile;

    dispatch(updateProfileState({ prop: 'userListLoading', value: true }));

    const isTeacher = AppHelper.isCBETeacher();
    const getRelatedUsers = isTeacher ? getRelatedStudentList : getRelatedTeacherList;

    if (page === 1) {
      dispatch(updateProfileState({ prop: 'userListLoading', value: true }));

      getRelatedUsers(page)
        .then((res) => {
          const { data } = res;
          const users = isTeacher ? data.students : data.teachers;

          dispatch(updateProfileState({ prop: 'userListLoading', value: false }));

          // only update current page if has more
          if (page < users.last_page) {
            dispatch(updateProfileState({ prop: 'page', value: page + 1 }));
          }
          dispatch(updateProfileState({ prop: 'userList', value: users.data }));
          dispatch(updateProfileState({ prop: 'lastPage', value: users.last_page }));
        })
        .catch(err => {
          console.error(err);
          dispatch(updateProfileState({ prop: 'userListLoading', value: false }));
        });
    } else if (page < lastPage) {
      // infinite loading, loading more
      dispatch(updateProfileState({ prop: 'userListLoadingMore', value: true }));
      
      getRelatedUsers(page)
        .then((res) => {
          const { data } = res;
          const users = isTeacher ? data.students : data.teachers;

          dispatch(updateProfileState({ prop: 'userListLoadingMore', value: false }));

          // update current page if has more 
          dispatch(updateProfileState({ prop: 'page', value: page + 1 }));
          dispatch(updateProfileState({ prop: 'userList', value: [...userList, ...users.data] }));
          dispatch(updateProfileState({ prop: 'lastPage', value: users.last_page }));
        })
        .catch(err => {
          console.error(err);
          dispatch(updateProfileState({ prop: 'userListLoadingMore', value: false }));
        });
    }
  };
};
