import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Button, Avatar, Tooltip } from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import _ from 'lodash';
import {
  onSlotClick
} from '../../../actions';
import AppHelper from '../../../helpers/AppHelper';
import { useTranslation } from 'react-i18next';
import {
  blue,
  green,
  orange,
  red
} from '@material-ui/core/colors';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';

const status = {
  EXPIRED:      { icon: null },
  CONFIRMED:    { icon: <EventOutlinedIcon style={{ color: blue[800], height: '18px' }} /> },
  COMPLETED:    { icon: <CheckCircleOutlinedIcon style={{ color: green[800], height: '18px' }} /> },
  CANCELLED:    { icon: <CancelOutlinedIcon style={{ color: red[800], height: '18px' }} /> },
  IN_PROGRESS:  { icon: <ScheduleOutlinedIcon style={{ color: orange[800], height: '18px' }} /> },
  REJECTED:     { icon: <CancelOutlinedIcon style={{ color: red[800], height: '18px' }} /> },
};

const useStyles = makeStyles(() => ({
  contentContainer: { maxWidth: '150px' },
}));

const LessonItem =(props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { slot, onSlotClick } = props;

  const onViewMore = () => {
    onSlotClick({ meetId: slot.meet_id, bookingSlotId: slot.booking_slot_id });
  };

  let {
    teacherAvatarUrl: avatarUrl,
    teacherFirstName: firstName,
    teacherLastName: lastName,
    start_date_time,
    end_date_time,
    participants = []
  } = slot;
  if (AppHelper.isCBETeacher()) {
    avatarUrl = slot.studentAvatarUrl;
    firstName = slot.studentFirstName;
    lastName = slot.studentLastName;
  }
  const fullName = AppHelper.getFullname(firstName, lastName);

  const { type } = slot;

  let statusId = slot.booking_slot_status_id;
  if (type === 'm') {
    statusId = slot.meet_status_id;
  }

  let key = 'EXPIRED';
  if (statusId === 'CF' || statusId === 'RQ') {
    key = 'CONFIRMED';
  } else if (statusId === 'CP') {
    key = 'COMPLETED';
  } else if (statusId === 'CSL' || statusId === 'CSM' || statusId === 'CT' || statusId === 'CTT' || statusId === 'CL') {
    key = 'CANCELLED';
  } else if (statusId === 'IP') {
    key = 'IN_PROGRESS';
  } else if (statusId === 'RJ') {
    key = 'REJECTED';
  }

  const renderContent = () => {
    if (type === 'm' && participants.length > 1) {
      return (
        <Grid container direction="column">
          <AvatarGroup max={3}>
            {
              participants.map((item, index) => (
                <Tooltip key={index.toString()} title={AppHelper.getFullname(item.participantFirstName, item.participantLastName)}>
                  <Avatar alt={AppHelper.getFullname(item.participantFirstName, item.participantLastName)} src={item.participantAvatarUrl} />
                </Tooltip>
              ))
            }
          </AvatarGroup>
        </Grid>
      );
    } else if (type === 'm' && participants.length === 1) {
      return (
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            <Avatar src={participants[0].participantAvatarUrl} />
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Typography noWrap variant="body1" className={classes.contentContainer}>{AppHelper.getFullname(participants[0].participantFirstName, participants[0].participantLastName)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item>
          <Avatar src={avatarUrl} />
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Typography noWrap variant="body1" className={classes.contentContainer}>{_.isEmpty(fullName) ? t('lesson.notBooked') : fullName}</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Paper
      elevation={0}
      className="br-2 mb-2 px-2 py-2 button"
      onClick={onViewMore}
    >
      { renderContent() }
      <Typography noWrap variant="body2" className="my-1"><strong>{t('lesson.time')}: </strong>{moment(new Date(start_date_time)).format('hh:mm A')} - {moment(new Date(end_date_time)).format('hh:mm A')}</Typography>
      <Grid container direction="row" alignItems="center" justify="flex-end" className="mt-2">
        {status[key].icon} 
        <Button size="small" color="primary">{t('viewMore')}</Button>
      </Grid>
    </Paper>
  );
};

export default connect(null, { onSlotClick })(LessonItem);
