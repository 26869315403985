import {
  UPDATE_MUSIC_PIECE_REQUEST_LIST,
  UPDATE_MUSIC_PIECE_REQUEST_LOADING,
  UPDATE_MUSIC_TYPE_LIST,
  LOGOUT_USER_SUCCESS,
  UPDATE_NEW_MUSIC_PIECE_FIELD,
  UPDATE_NEW_MUSIC_PIECE_FIELD_ERROR,
  RESET_NEW_MUSIC_PIECE_STATE
} from '../actions/types';

const INITIAL_NEW_MUSIC_PIECE_REQUEST_STATE = {
  gradeId: null,
  composerName: '',
  pieceTitle: '',
  videoLink: '',
  musicSheetUri: '',
  modalTitle: '',
  modalVisible: false,
  modalContent: '',
  modalMode: 'success'
};

const INITIAL_STATE = {
  musicPieceRequestList: [],
  isMusicPieceRequestLoading: false,
  musicTypes: [],
  newPieceRequest: INITIAL_NEW_MUSIC_PIECE_REQUEST_STATE,
  newPieceRequestError: {},
  hasSyllabusChanged: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_MUSIC_PIECE_REQUEST_LIST:
      return { ...state, musicPieceRequestList: action.payload };
    case UPDATE_MUSIC_PIECE_REQUEST_LOADING:
      return { ...state, isMusicPieceRequestLoading: action.payload };
    case UPDATE_MUSIC_TYPE_LIST:
      return { ...state, musicTypes: action.payload };
    case UPDATE_NEW_MUSIC_PIECE_FIELD:
      return { ...state, newPieceRequest: { ...state.newPieceRequest, [action.payload.prop]: action.payload.value }, hasSyllabusChanged: true };
    case UPDATE_NEW_MUSIC_PIECE_FIELD_ERROR:
      return { ...state, newPieceRequestError: { ...state.newPieceRequestError, [action.payload.prop]: action.payload.error } };
    case RESET_NEW_MUSIC_PIECE_STATE:
      return { ...state, newPieceRequest: INITIAL_NEW_MUSIC_PIECE_REQUEST_STATE, newPieceRequestError: {}, hasSyllabusChanged: false };
    case LOGOUT_USER_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
