import React, { useEffect } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
// import _ from 'lodash';
import {
  Grid,
  Paper,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Switch,
  Button,
  Backdrop,
  CircularProgress,
  Fab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  // ListSubheader,
  Container,
  Divider,
  Typography,
} from '@material-ui/core';
import {
  Check as CheckIcon,
  // Save as SaveIcon,
  Error as ErrorIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Audiotrack as AudiotrackIcon,
  // ClassRounded,
} from '@material-ui/icons';
import {
  green,
} from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Tag,
  Layout,
} from 'element-react';

import {
  LanguageSelector
} from '../common';

import {
} from '../../actions';
import {
  saveAudioSettingsForStudent,
  saveAudioSettingsForTeacher,
} from '../../api/AudioSettingApi';

// import './SettingScreen.css';
import AppHelper from '../../helpers/AppHelper';
import packageJson from '../../../package.json';

const useStyles = makeStyles((theme) => ({
  Backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: theme.zIndex.drawer + 1, // 1,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  paper: { width: '100%', marginTop: 40, padding: 40 },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  },
}));

const SettingScreen = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  const isTeacher = AppHelper.isCBETeacher();

  useEffect(() => {
    document.title = t('youbrio') + ' | ' + t('settings.settings');
  }, [t]);

  const [state, setState] = React.useState({
    enableAP: true,
    enableAEC: true,
    enableAGC: true,
    enableNS: true,
    enableHPF: true,
    enableAL: true,
    enableStereo: true,
  });

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [displayAudioSettings, setDisplayAudioSettings] = React.useState(false);

  const buttonClassName = clsx({
    [classes.buttonSuccess]: success,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked});
  };

  const setMicSetting = async() => {
    setOpenBackdrop(true);
    setSuccess(false);
    setLoading(true);
    const params = {
      disableAP: !state.enableAP ? 1 : 0,
      disableAEC: !state.enableAEC ? 1 : 0,
      disableAGC: !state.enableAGC ? 1 : 0,
      disableNS: !state.enableNS ? 1 : 0,
      disableHPF: !state.enableHPF ? 1 : 0,
      disableAL: !state.enableAL ? 1 : 0,
      enableStereo: state.enableStereo ? 1 : 0,
    };
    var paramsStr = [
      `disableAP=${encodeURIComponent(params.disableAP)}`,
      `disableAEC=${encodeURIComponent(params.disableAEC)}`,
      `disableAGC=${encodeURIComponent(params.disableAGC)}`,
      `disableNS=${encodeURIComponent(params.disableNS)}`,
      `disableHPF=${encodeURIComponent(params.disableHPF)}`,
      `disableAL=${encodeURIComponent(params.disableAL)}`,
      `enableStereo=${encodeURIComponent(params.enableStereo)}`,
    ].join('&');
    try {
      if (isTeacher) {
        const response = await saveAudioSettingsForTeacher(paramsStr);
        console.info('---saveAudioSettingsForTeacher: success, response:', response);
      } else {
        const response = await saveAudioSettingsForStudent(paramsStr);
        console.info('---saveAudioSettingsForStudent: success, response:', response);
      }
      setSuccess(true);
      setLoading(false);
    } catch (err) {
      console.error('---saveAudioSettings: fail, error:', err);
      setSuccess(false);
      setLoading(false);
    }
  };

  const handleClickButtonInternalMicSetting = () => {
    setMicSetting();
  };

  const handleClickButtonExternalMicSetting = () => {
    setState({
      ...state,
      enableAP: true,
      enableAEC: true,
      enableAGC: false,
      enableHPF: true,
      enableNS: true,
      enableAL: false,
      enableStereo: true,
    });
    setMicSetting();
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleClickButtonSaveMicSetting = () => {
    setState({
      ...state,
      enableAP: false,
      enableAEC: false,
      enableAGC: false,
      enableHPF: false,
      enableNS: false,
      enableAL: false,
      enableStereo: true,
    });
    setMicSetting();
  };

  useEffect(() => {
  }, []);

  const renderSettingSection = () => {
    return (
      <Grid item className="w-100 middle-container">
        <Grid container direction="row" justify="space-evenly" spacing={3}>
          <Grid item xs={12} sm={11} md={11} lg={11} xl={11}>
            <Paper elevation={0} className="py-3 br-3 h-100">
              <FormControl component="fieldset" className="px-3">
                <FormLabel component="legend">
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch size="small" color="primary" checked={state.enableAP} onChange={handleChange} name="enableAP" />}
                    label={t('settings.enableAP')}
                  />
                  <FormControlLabel
                    control={<Switch size="small" color="primary" checked={state.enableAEC} onChange={handleChange} name="enableAEC" />}
                    label={t('settings.enableAEC')}
                  />
                  <FormControlLabel
                    control={<Switch size="small" color="primary" checked={state.enableAGC} onChange={handleChange} name="enableAGC" />}
                    label={t('settings.enableAGC')}
                  />
                  <FormControlLabel
                    control={<Switch size="small" color="primary" checked={state.enableNS} onChange={handleChange} name="enableNS" />}
                    label={t('settings.enableNS')}
                  />
                  <FormControlLabel
                    control={<Switch size="small" color="primary" checked={state.enableHPF} onChange={handleChange} name="enableHPF" />}
                    label={t('settings.enableHPF')}
                  />
                  <FormControlLabel
                    control={<Switch size="small" color="primary" checked={state.enableAL} onChange={handleChange} name="enableAL" />}
                    label={t('settings.enableAL')}
                  />
                  <FormControlLabel
                    control={<Switch size="small" color="primary" checked={state.enableStereo} onChange={handleChange} name="enableStereo" />}
                    label={t('settings.enableStereo')}
                  />
                </FormGroup>
                <FormHelperText></FormHelperText>
              </FormControl>
              <Grid container direction="row" justify="space-evenly" alignItems="center">
                <Grid item xs>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickButtonInternalMicSetting}>
                    {t('settings.internalMicSetting')}
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickButtonExternalMicSetting}>
                    {t('settings.externalMicSetting')}
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClickButtonSaveMicSetting}>
                    {t('settings.saveMicSetting')}
                  </Button>
                </Grid>
              </Grid>
              <Backdrop className={classes.Backdrop} open={openBackdrop} onClick={handleCloseBackdrop}>
                { !loading && <Fab
                  aria-label="save"
                  color="primary"
                  className={buttonClassName}
                >
                  {success ? <CheckIcon /> : <ErrorIcon />}
                </Fab>}
                {!loading && (success ? t('success') : t('error'))}
                {loading && <CircularProgress size={68} color="inherit" />}
              </Backdrop>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container maxWidth="md">
      <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
        <Paper className={classes.paper}>
          <Layout.Row type="flex" className="row-bg" justify="end">
            <Tag type="danger">
              { 'v' + packageJson.version }
            </Tag>
          </Layout.Row>
          <Typography className={classes.title}>{t('settings.browser')}</Typography>
          <ul>
            <li>{t('settings.chrome')}</li>
            <li>{t('settings.edge')}</li>
            <li>{t('settings.safari')}</li>
            <li>{t('settings.firefox')}</li>
          </ul>
          <Divider className={classes.divider} />

          <Typography className={classes.title}>{t('settings.selectYourLanguage')}</Typography>
          <LanguageSelector />

          {/* <Divider className={classes.divider} />

          <Typography className={classes.title}>{t('settings.audioSetup')}</Typography>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItem button onClick={() => { setDisplayAudioSettings(!displayAudioSettings); }}>
              <ListItemIcon>
                <AudiotrackIcon />
              </ListItemIcon>
              <ListItemText primary={t('settings.audioSettings')} />
              { displayAudioSettings ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
            </ListItem>
            <Collapse in={displayAudioSettings} timeout="auto" unmountOnExit>
              { renderSettingSection() }
            </Collapse>
          </List> */}
        </Paper>
      </Grid>
    </Container>
  );
};

const mapStateToProps = () => {
  return {
  };
};

export default connect(mapStateToProps, {
})(SettingScreen);
