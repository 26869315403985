import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Button,
  TextField,
  Checkbox,
  Select,
  InputLabel,
  FormControlLabel,
  FormControl,
  Input,
  MenuItem,
  ListItemText,
  FormHelperText,
  Grid,
  ListSubheader,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
} from '@material-ui/core';
import {
  green
} from '@material-ui/core/colors';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import TodayIcon from '@material-ui/icons/Today';
// import AvTimerIcon from '@material-ui/icons/AvTimer';
// import LockIcon from '@material-ui/icons/Lock';
// import PersonIcon from '@material-ui/icons/Person';
import {
  createImmediateMeet,
  loadImmediateMeet,
  cancelImmediateMeet,
  enterLesson,
} from '../../actions';
import {
  getImmediateStudent
} from '../../api';
import { NumberFormatCustom } from '../common';

import AppHelper from '../../helpers/AppHelper';
import { Redirect } from 'react-router-dom';

const getStyles = (name, personName, theme) => {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getContentAnchorEl: null
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginRight: '15px',
    marginTop: '25px',
  },
  toLabel: {
    marginRight: '15px',
    marginTop: '25px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '25px',
    height: '48px',
  },
  slider: {
    marginTop: '25px',
  },
  nowContainer: {
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  columnSection: {
    marginTop: '15px',
  },
  avatarSmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  optionAvatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: '10px',
  },
  anyoneCanJoin: {
    color: green[500],
  },
  actionContainer: {
    marginTop: '25px',
    textAlign: 'left',
  },
  paper: {
    marginTop: 40,
    padding: 40,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  }
}));

const StartLessonScreen = (props) => {
  const [now, setNow] = useState(moment());
  const [fee, setFee] = useState(60);
  const [free, setFree] = useState(false);
  const [max, setMax] = useState(1);
  const [invitees, setInvitees] = useState([]);
  const [duration, setDuration] = useState(30);
  const [users, setUsers] = useState([]); // a list of students availabe to be select as invitees
  const [inviteesOptionsOpen, setInviteesOptionsOpen] = useState(false);

  const { t }  = useTranslation();
  const classes = useStyles();
  const { loadImmediateMeet } = props;
  const theme = useTheme();

  useEffect(() => {
    document.title = t('youbrio') + ' | ' + t('lesson.startLessonNow');
  }, []);

  useEffect(() => {
    setInterval(() => setNow(moment()), 60000);

    const init = async () => {
      const studentRes = await getImmediateStudent();
      const students = _.get(studentRes, 'data.studentList', []);
  
      const sorted = _.sortBy(students, (o) => o.lastName);
      // const firstChar = sorted[0].lastName.charAt(0);
      // const sectioned = [{ listHeader: firstChar }];
  
      // let curr = firstChar;
      // sorted.forEach((o) => {
      //   if (o.lastName.charAt(0) !== curr) {
      //     sectioned.push({
      //       listHeader: o.lastName.charAt(0)
      //     });
  
      //     curr = o.lastName.charAt(0);
      //   }
      //   sectioned.push(o);
      // });
      
      // if (students.length > 0) {
      //   setUsers(sectioned);
      // }
  
      if (students.length > 0) {
        setUsers(sorted);
      }
  
      loadImmediateMeet();
    };

    init();
  }, [loadImmediateMeet]);
  
  useEffect(() => {
    
  }, [duration]);

  useEffect(() => {
    if (invitees.length > 0) {
      setMax(invitees.length);
    }
  }, [invitees]);

  useEffect(() => {
    if (free) {
      setFee(0);
    } else {
      setFee(1);
    }
  }, [free]);

  const enterLessonRoom = () => {
    if (props.meet.jitsiBuild === '6726') {
      props.history.push({
        pathname: '/meet/lesson',
        state: {
          meetId: props.meet.meet_id
        }
      });
    } else {
      props.enterLesson({ meetId: props.meet.meet_id });
    }
  };

  const handleFreeChange = (event) => {
    setFree(event.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    props.createImmediateMeet({
      currencyCode: 'AUD',
      pricePerDuration: fee,
      perMinutes: duration,
      startTime: now.toDate(),
      endTime: now.clone().add(duration, 'minutes').toDate(),
      minuteDuration: duration,
      startDateTime: now.toDate(),
      endDateTime: now.clone().add(duration, 'minutes').toDate(),
      maxParticipants: max,
      invitees: invitees.map(invitee => ({ user_id: invitee.userId }))
    });
  };

  const endDatetime = now.clone().add(duration, 'minutes');

  const renderDialog = () => {
    const startTime = _.get(props.meet, 'start_date_time', undefined) ? moment(new Date(props.meet.start_date_time)) : moment();
    const endTime = _.get(props.meet, 'end_date_time', undefined) ? moment(new Date(props.meet.end_date_time)) : moment();
    
    const duration = endTime.diff(startTime, 'minutes');

    return (
      <Dialog
        open={!_.isEmpty(props.meet)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('lesson.wantToReturn')}</DialogTitle>
        <DialogContent>
          {/* <Typography variant="body1">{t('students')}</Typography> */}
          <AvatarGroup max={10}>
            {
              _.get(props.meet, 'participants', []).map(item => <Avatar key={item.user_id} src={item.avatar_url} />)
            }
          </AvatarGroup>
          <Typography className={classes.formControl}>{t('lesson.date')}: <strong>{startTime.format('ddd DD/MM/YYYY')}</strong></Typography>
          <Typography>{t('lesson.time')}: <strong>{startTime.format('hh:mm A')} - {endTime.format('hh:mm A')}</strong></Typography>

          <Typography>{t('lesson.duration')}: <strong>{duration}</strong> {t('minutes')}</Typography>
          <DialogContentText id="alert-dialog-description">
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button fullWidth disableElevation onClick={enterLessonRoom} color="primary" variant="contained">
            {t('lesson.enterLesson')}
          </Button>
        </DialogActions>
        <DialogActions>
          <Button fullWidth disableElevation onClick={() => props.cancelImmediateMeet(props.meet.meet_id)} color="secondary" variant="contained">
            {t('lesson.cancelLesson')}
          </Button>
        </DialogActions>
        <DialogActions>
          <Button fullWidth disableElevation onClick={() => props.history.push('/dash')} color="default" variant="contained">
            {t('lesson.goBackToDash')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  if (!AppHelper.isCBETeacher()) {
    return <Redirect path="/dash" />;
  }

  return (
    <Container maxWidth="md">
      <Paper className={classes.paper}>
        <Typography className={classes.title}>{t('lesson.startLessonNow')}</Typography>
        <Typography variant="h6">{t('lesson.checkLessonDetails')}</Typography>
        <div className={classes.nowContainer}>
          <TodayIcon />
          <Typography variant="caption">{t('now').toUpperCase()} - {now.format('LLLL')}</Typography>
        </div>
        <Grid container direction="row">
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <form onSubmit={handleSubmit}>
              <FormControl className={classes.formControl}>
                <TextField
                  disabled
                  size="small"
                  id="start-date"
                  variant="filled"
                  label={t('calendar.today')}
                  type="date"
                  value={now.format('yyyy-MM-DD')}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <FormHelperText>{t('lesson.lessonOnlyToday')}</FormHelperText>
              </FormControl>

              <FormControl className={classes.formControl}>
                <TextField
                  disabled
                  size="small"
                  id="start-time"
                  variant="filled"
                  label={t('lesson.startTime')}
                  type="time"
                  value={now.format('HH:mm')}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </FormControl>

              <FormControl className={classes.toLabel}>
                <Typography variant="body1">{t('to')}</Typography>
              </FormControl>

              <FormControl className={classes.formControl}>
                <TextField
                  disabled
                  size="small"
                  id="end-time"
                  variant="filled"
                  label={t('lesson.endTime')}
                  type="time"
                  value={endDatetime.format('HH:mm')}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </FormControl>

              {/* <FormControl className={classes.formControl}>
              <TextField
                disabled
                size="small"
                id="end-date"
                variant="filled"
                label={t('lesson.endDate')}
                type="date"
                defaultValue={endDatetime.format('yyyy-MM-DD')}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </FormControl> */}

              <FormControl className={classes.formControl}>
                <InputLabel id="duration">{t('lesson.duration')}</InputLabel>
                <Select
                  labelId="duration"
                  id="duration"
                  value={duration}
                  onChange={(event) => setDuration(event.target.value)}
                  MenuProps={MenuProps}
                >
                  {
                    [30, 60, 90, 120].map((value) => {
                      return <MenuItem key={value.toString()} value={value}>{value}</MenuItem>;
                    })
                  }
                </Select>
                <FormHelperText>{t('minutes')}</FormHelperText>
              </FormControl>

              <div className={classes.formControl}>
                <FormControl style={{ width: '40%', marginRight: '15px' }}>
                  <InputLabel shrink id="demo-mutiple-name-label">{t('lesson.selectInvitees')}</InputLabel>
                  <Select
                    open={inviteesOptionsOpen}
                    onOpen={() => setInviteesOptionsOpen(true)}
                    onClose={() => setInviteesOptionsOpen(false)}
                    labelId="demo-mutiple-name-label"
                    id="demo-mutiple-name"
                    multiple
                    value={invitees}
                    onChange={(event) => {
                      if (event.target.value.indexOf('') !== -1) {
                        setInvitees([]);
                        setInviteesOptionsOpen(false);
                      } else {
                        setInvitees(event.target.value);
                      }
                    }}
                    renderValue={(selected) => {
                      if (selected.length > 0) {
                        return selected.map(item => AppHelper.getFullname(item.firstName, item.lastName) + ', ');
                      } 
                      return <em className={classes.anyoneCanJoin}>{t('lesson.anyoneCanJoin')}</em>;
                    }}
                    input={<Input />}
                    displayEmpty
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="">
                      <em className={classes.anyoneCanJoin}>{t('lesson.anyoneCanJoin')}</em>
                    </MenuItem>
                    {users.map((item) => (
                      item.listHeader ?
                        <ListSubheader key={item.listHeader}>{item.listHeader}</ListSubheader>
                        :
                        <MenuItem key={item.userId} value={item} style={getStyles(item.userId, invitees, theme)}>
                          <Checkbox color="primary" checked={invitees.indexOf(item) > -1} />
                          <Avatar className={classes.optionAvatar} alt={AppHelper.getFullname(item.firstName, item.lastName)} src={item.studentAvatarUrl} />
                          <ListItemText primary={AppHelper.getFullname(item.firstName, item.lastName)} />
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl style={{ width: '25%' }}>
                  <InputLabel id="demo-simple-select-filled-label">{t('lesson.maxParticipants')}</InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={max}
                    onChange={(event) => setMax(event.target.value)}
                    MenuProps={MenuProps}
                  >
                    {
                      [...Array(20).keys()].map((key) => {
                        const value = key + 1;
                        const disabled = value < invitees.length;
                        return <MenuItem disabled={disabled} key={value.toString()} value={value}>{value}</MenuItem>;
                      })
                    }
                  </Select>
                </FormControl>
              </div>

              <FormControl className={classes.formControl}>
                <TextField
                  disabled={free}
                  size="small"
                  variant="filled"
                  label={t('lesson.lessonFee')}
                  placeholder="$0"
                  value={fee}
                  onChange={(event) => setFee(event.target.value)}
                  name="fee"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </FormControl>
              <FormHelperText>{free && t('lesson.autoBooked')}</FormHelperText>

              <div>
                <FormControlLabel
                  control={<Checkbox checked={free} onChange={handleFreeChange} color="primary" name="checkFree" />}
                  label={t('lesson.makeItFree')}
                />
              </div>
          
              <div className={classes.actionContainer}>
                <Button disableElevation={true} variant="contained" color="primary" type="submit">{t('lesson.create')}</Button>
              </div>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} className={classes.columnSection}>
            {/* <div style={{ backgroundColor: 'yellow' }}>Overview Panel</div> */}
          </Grid>
        </Grid>

        { renderDialog() }
      </Paper>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    meet: state.lesson.meet
  };
};

export default connect(mapStateToProps, {
  createImmediateMeet,
  loadImmediateMeet,
  cancelImmediateMeet,
  enterLesson,
})(StartLessonScreen);