import {
  getPracticeRoomTeacherList,
} from '../api';
import history from '../history';
import {
  // appStartsLoading,
  // appDoneLoading,
  // updateCommonStates
} from './CommonActions';
import {
  UPDATE_PRACTICE_ROOM_TEACHER_LIST_STATE,
  RESET_PRACTICE_ROOM_TEACHER_LIST_STATE
} from '../actions/types';
// import i18n from '../i18n';
// import GLOBAL from '../helpers/GLOBAL';

export const updatePracticeRoomTeacherListState = ({ prop, value }) => {
  return {
    type: UPDATE_PRACTICE_ROOM_TEACHER_LIST_STATE,
    payload: { prop, value }
  };
};

export const resetPracticeRoomTeacherListState = () => {
  return {
    type: RESET_PRACTICE_ROOM_TEACHER_LIST_STATE
  };
};

export const fetchPracticeRoomTeacherList = ({ practiceRoomId, userId, meetId, day, jitsiConfig }) => {
  return (dispatch) => {
    dispatch(updatePracticeRoomTeacherListState({ prop: 'teacherListLoading', value: true }));
    getPracticeRoomTeacherList({
      practiceRoomId,
      userId,
      meetId,
      day
    })
      .then((res) => {
        const { teachers } = res.data;
        if (teachers) {
          dispatch(updatePracticeRoomTeacherListState({ prop: 'teacherList', value: teachers }));
          dispatch(updatePracticeRoomTeacherListState({ prop: 'teacherListLoading', value: false }));
	  if (teachers.length > 0) {
            console.error('fetchPracticeRoomTeacherList--action');
	    history.replace({
	      pathname: '/practice-tutor-reviews',
	      state: {
		practiceRoomId,
		userId,
		day,
		// meetId,
		// jitsiConfig,
		// teachers,
	      }
	    });
	  }
        }
      })
      .catch(err => {
        console.error('Failed to get the tutor room teacher list', err);
        dispatch(updatePracticeRoomTeacherListState({ prop: 'teacherListLoading', value: false }));
      });
  };
};
