import YoubrioApiHelper from '../helpers/YoubrioApiHelper';

export const getGatewayPaymentMethods = ({ currencyCode }) => {
  const route = currencyCode
    ? `gateway-payment-methods?currency=${currencyCode}`
    : 'gateway-payment-methods';

  return YoubrioApiHelper.get(route, true);
};

export const getAvailableGatewayPaymentMethodTypes = ({ currencyCode }) => {
  const params = {
    currencyCode
  };

  return YoubrioApiHelper.post('gateway-payment-method-types', params, true);
};

export const getCreateGatewayPaymentMethodCredential = ({
  currencyCode,
  type
}) => {
  const params = {
    currencyCode,
    type
  };

  return YoubrioApiHelper.post('gateway-payment-method/credentials', params, true);
};

export const createBraintreeGatewayPaymentMethod = ({
  nonce
}) => {
  const params = {
    nonce
  };

  return YoubrioApiHelper.post('braintree/gateway-payment-method', params, true);
};