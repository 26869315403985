import YoubrioApiHelper from '../helpers/YoubrioApiHelper';
import AppHelper from '../helpers/AppHelper';

export const getChatRoomList = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return YoubrioApiHelper.get(`chat-room?lang=${lang}`, true);
};

export const getAdminChat = ({ uid }) => {
  // get or create a chat room with admin=
  const lang = AppHelper.getAppLocaleCodeForApi();
  return YoubrioApiHelper.get(`admin-chat-room?uid=${uid}&lang=${lang}`, true);
};

export const getVideoCallChatRoomId = ({ chatRoomRoomId }) => {
  return YoubrioApiHelper.get(`video-chat/room-id?crrid=${chatRoomRoomId}`, true);
};

export const deleteOwnChat = ({ roomId }) => {
  const params = {
    roomId
  };
  return YoubrioApiHelper.post('chat/delete', params, true);
};

// export const updateReadStatus = ({ msg, callFrom }) => {
//   const params = {
//       msg,
//       callFrom
//   };
//   // console.warn(params);
//   return YoubrioApiHelper.post('chat-read-status', params, true);
// };
