/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import TelegramIcon from '@material-ui/icons/Telegram';
import MicIcon from '@material-ui/icons/Mic';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PaymentIcon from '@material-ui/icons/Payment';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  ThemeProvider,
  withStyles,
  createMuiTheme,
} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import BigCalendar from 'react-big-calendar-like-google';
import 'react-big-calendar-like-google/lib/css/react-big-calendar.css';
import moment from 'moment';
import { lighten, fade, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import VideocamIcon from '@material-ui/icons/Videocam';
import AddIcon from '@material-ui/icons/Add';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen';
import Grid from '@material-ui/core/Grid';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import NavBar from '../../components/NavBar/NavBar';
// import SideBar from '../../components/SideBar/SideBar';
import profile from './profile.png'; 
import student from './student.png'; 
import more from './more.png'; 
import noti from './noti.png'; 


BigCalendar.setLocalizer(
  BigCalendar.momentLocalizer(moment)
);

function createDataNoti(name, message, noti) {
  return { name, message, noti };
}

var myEventsList = [
  {
    'title': 'All Day Event very long title',
    'bgColor': '#ff7f50',
    'allDay': true,
    'start': new Date(2020, 3, 0),
    'end': new Date(2020, 3, 1)
  },
  {
    'title': 'Long Event',
    'start': new Date(2020, 3, 7),
    'end': new Date(2020, 3, 10)
  },

  {
    'title': 'DTS STARTS',
    'bgColor': '#dc143c',
    'start': new Date(2016, 2, 13, 0, 0, 0),
    'end': new Date(2016, 2, 20, 0, 0, 0)
  },

  {
    'title': 'DTS ENDS',
    'bgColor': '#ff8c00',
    'start': new Date(2016, 10, 6, 0, 0, 0),
    'end': new Date(2016, 10, 13, 0, 0, 0)
  },

  {
    'title': 'Some Event',
    'bgColor': '#9932cc',
    'start': new Date(2020, 3, 9, 0, 0, 0),
    'end': new Date(2020, 3, 9, 0, 0, 0)
  },
  {
    'title': 'Conference',
    'bgColor': '#e9967a',
    'start': new Date(2020, 3, 11),
    'end': new Date(2020, 3, 13),
    desc: 'Big conference for important people'
  },
  {
    'title': 'Meeting',
    'bgColor': '#8fbc8f',
    'start': new Date(2020, 3, 12, 10, 30, 0, 0),
    'end': new Date(2020, 3, 12, 12, 30, 0, 0),
    desc: 'Pre-meeting meeting, to prepare for the meeting'
  },
  {
    'title': 'Lunch',
    'bgColor': '#cd5c5c',
    'start':new Date(2020, 3, 12, 12, 0, 0, 0),
    'end': new Date(2020, 3, 12, 13, 0, 0, 0),
    desc: 'Power lunch'
  },
  {
    'title': 'Happy Hour',
    'start':new Date(2020, 3, 12, 12, 0, 0, 0),
    'end': new Date(2020, 3, 12, 13, 0, 0, 0),
    desc: 'Power lunch happy hour'
  },
  {
    'title': 'Meeting',
    'bgColor': '#da70d6',
    'start':new Date(2020, 3, 12,14, 0, 0, 0),
    'end': new Date(2020, 3, 12,15, 0, 0, 0)
  },
  {
    'title': 'Happy Hour',
    'bgColor': '#eee8aa',
    'start':new Date(2020, 3, 17, 17, 0, 0, 0),
    'end': new Date(2020, 3, 17, 17, 30, 0, 0),
    desc: 'Most important meal of the day'
  },
  {
    'title': 'Dinner',
    'bgColor': '#98fb98',
    'start':new Date(2020, 3, 15, 20, 0, 0, 0),
    'end': new Date(2020, 3, 15, 21, 0, 0, 0)
  },
  {
    'title': 'Birthday Party',
    'bgColor': '#afeeee',
    'start':new Date(2020, 3, 13, 7, 0, 0),
    'end': new Date(2020, 3, 13, 10, 30, 0)
  },
  {
    'title': 'Birthday Party 2',
    'bgColor': '#db7093',
    'start':new Date(2020, 3, 13, 7, 0, 0),
    'end': new Date(2020, 3, 13, 10, 30, 0)
  },
  {
    'title': 'Birthday Party 3',
    'bgColor': '#cd853f',
    'start':new Date(2020, 3, 13, 7, 0, 0),
    'end': new Date(2020, 3, 13, 10, 30, 0)
  },
  {
    'title': 'Late Night Event',
    'bgColor': '#b0e0e6',
    'start':new Date(2020, 3, 17, 19, 30, 0),
    'end': new Date(2020, 3, 18, 2, 0, 0)
  },
  {
    'title': 'Multi-day Event',
    'start':new Date(2020, 3, 20, 19, 30, 0),
    'end': new Date(2020, 3, 22, 2, 0, 0)
  }
];

const rowsNoti = [
  createDataNoti('Allison Summers', "What time is our lesson this week?","12"),
  createDataNoti('Allison Summers', "What time is our lesson this week?","2"),
  createDataNoti('Allison Summers', "What time is our lesson this week?","32"),
  createDataNoti('Allison Summers', "What time is our lesson this week?","152"),
];

function createDataSearch(name, subject) {
  return { name, subject};
}

const rows = [
  createDataSearch('Allison Summers', "Piano"),
  createDataSearch('Allison Summers', "Violin"),
  createDataSearch('Allison Summers', "Violin"),
  createDataSearch('Allison Summers', "Guitar"),
];


let allViews = Object.keys(BigCalendar.Views).map(k => BigCalendar.Views[k]);

const MyCalendar = props => (
	<div style={{width: '200%', objectFit: true}}>
		<BigCalendar
      culture='en-GB'
      events={myEventsList}
      views={allViews}
			startAccessor="startDate"
			endAccessor="endDate"
		/>
	</div>
);

const styles = theme => ({
  disabledB: {
    backgroundColor: theme.palette.primary || 'red'
  }
});

const useStyles = makeStyles((theme) => ({    
  tr: {
    background: "white",
    '&:hover': {
      background: "#FFD1D1",
    }
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      color:"#AAAAAA"
    },
  },
  salute: {
    display: 'none',
    fontSize: '24px',
    marginTop: '-20px',
    marginLeft: '50px',
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      color:"#AAAAAA",
    },
  },
  menu_item: {
    display: 'none',
    fontSize: '20px',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      color:"#AAAAAA",
    },
  },
  class_count: {
    display: 'none',
    fontSize: '24px',
    marginTop: '40px',
    marginLeft: '-300px',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      color:"black"
    },
  },
  circle: {
    bgcolor: 'blue',
    borderColor: 'text.primary',
    m: 1,
    border: 1,
    style: { width: '5rem', height: '5rem' },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appbar: {
    backgroundColor: 'white',
    height: '110px',
    paddingRight:'60px',
    paddingLeft:'60px',

  },
  root: {
    display: 'block',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    //flexGrow: 1,
    //backgroundColor: theme.palette.background.default,
    //padding: theme.spacing(3),
  },
}));


function myFunction() {
  var text = " ";
  var pusher = 12;
  var i;
  for (i = 1; i < 6; i++) {

    text ="<Box p={1} marginLeft='"+pusher+"px' marginTop='10px'><img src={student} className='App-Student' alt='student'/></Box>";
    pusher = 100 + (i * 12);
  }
  return text;
}

export default function PrimarySearchAppBar() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClickTable = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  
  const handleClose = () => {
    setAnchorEl(null);
  };  
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    { id: 'name', numeric: true, disablePadding: true, label: 'Name' },
    { id: 'subject', numeric: false, disablePadding: false, label: 'Subject' },
  ];
  
  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }));

  const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(1),
      },
    },
    input: {
      borderRadius: 20,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 16,
      width: '140px',
      padding: '4px 6px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }))(InputBase);

  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;
  
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          </Typography>
        )}
  
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  };
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  var text = " ";
  var pusher = 13;
  var i;
  //var Students;
  const Students = [];
  for (i = 1; i < 6; i++) {
    Students.push(<Box p={1} marginLeft='"+pusher+"px' marginTop='10px'><img src={student} className='App-Student' alt='student'/><Typography align='left' style={{ marginLeft:10, marginTop:-45, color: 'white' }}>Allison</Typography><Typography align='left' style={{ marginLeft:10, marginTop:-10, color: 'white' }}>in 5 hours</Typography></Box>);
    pusher = 100 + (i * 14);
  }
  Students.push(<Box p={1} marginLeft='"+pusher+"px' marginTop='10px'><img src={more} className='App-Student' alt='student'/><Typography align='center' style={{ marginTop:-70, color: 'white' }}>more</Typography></Box>);
  
  return (
    
      <div style={{  width:"1280px", margin: "0 auto" }}>
        <Grid container sm={12} >
          {/* <NavBar /> */}
        <Grid container sm={12}>
            <Box display="flex" flexDirection="row" p={0} m={0} >
              <Box p={1} marginLeft='163px' marginTop='10px'>
              <img src={profile} className="App-Profile" alt="profile"/>
              <Typography style={{ marginTop:10 }}>John Citizen</Typography> 
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" p={1} ml={4} >
              <Box p={1} marginLeft='30px' marginTop='10px'>
              <Typography align="left" display="inline" fontWeight="fontWeightBold"><Box fontWeight="fontWeightBold" style={{ color:"#6346FF", fontSize:"54px", fontWeight:"fontWeightBold"}}>Join Call!</Box></Typography>
              </Box>
            </Box>   
        </Grid>
        <Grid item sm={11}>
          <Box display="flex" flexDirection="row" p={1} ml={4} bgcolor="background.paper">
            {/* <SideBar /> */}
            <Grid item align="center" sm={6}>
            <Box display="flex" flexDirection="row" p={1} mt={12} ml={4} width="900px">
                <TextField
              error
              id="outlined-error"
              inputProps={{min: 0, classes: {
                notchedOutline: classes.notchedOutline
              }, style: { textAlign: 'center'}}}
              label="enter your call i.d. below "
              defaultValue="enter call I.D."
              variant="outlined"
              fullWidth="true"
            />            
              </Box> 
              <Box align="center" flexDirection="row" p={1} ml={4} width="900px"> 
                <Button variant="contained" style={{ backgroundColor:"#6346FF", color:"#FFFFFF", borderRadius:"30", width:"200px" }}>
                  Join Call
                </Button>             
              </Box> 
              <Box align="center" flexDirection="row" p={1} ml={4} width="900px"> 
                <Button variant="contained" style={{ backgroundColor:"#FFA3A3", color:"#FFFFFF", borderRadius:"30", width:"200px" }}>
                  Audio Setting
                </Button>             
              </Box> 

        </Grid>             
          </Box>
        </Grid>
        {renderMobileMenu}
        {renderMenu}
        </Grid>
       </div>
  );
}