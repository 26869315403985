import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Grid,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  // Chip,
  CircularProgress,
  Badge,
} from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
import {
  getNotificationList,
  changeNotificationReadStatus,
} from '../../actions';
import logoImg from '../../img/logo.png';
import AppHelper from '../../helpers/AppHelper';

import './DashScreen.css';

const _NotificationList = (props) => {
  const { t } = useTranslation();

  const path = 'notificationList';

  function handleNotificationItemClick(item) {
    if (item.read_status === 0) {
      props.changeNotificationReadStatus(item.id);
    }
 };
  
  if (_.get(props, path, true) === true || props.notificationListLoading) {
    return <CircularProgress />;
  }

  if (_.get(props, path).length < 1) {
    return <Typography color="textSecondary">{t('dash.noNotification')}</Typography>;
  }

  return (
    <List className="scroll-container px-3">
      <InfiniteScroll
        pageStart={props.page}
        loadMore={props.getNotificationList}
        hasMore={props.page < props.lastPage && !props.notificationListLoading && !props.notificationListLoadingMore}
        loader={<span key={0} />}
        useWindow={false}
      >
        {
          _.get(props, path)
            ?
            _.get(props, path).map((item, index) => {
              const avatar = _.get(item, 'from_user.avatarUrl', logoImg);
              const firstName = _.get(item, 'from_user.FirstName', '');
              const lastName =  _.get(item, 'from_user.LastName', t('youbrio'));

              return (
                <ListItem key={index.toString()} button className="white-bg br-3 mb-2" onClick={() => handleNotificationItemClick(item)}>
                  <ListItemIcon>
                    {item.read_status?
                      <Avatar alt={lastName} src={avatar} />
                      :
                      <Badge color="secondary" variant="dot">
                        <Avatar alt={lastName} src={avatar} />
                      </Badge>
                    }
                  </ListItemIcon>
                  <Grid container direction="column">
                    <ListItemText primary={AppHelper.getFullname(firstName || '', lastName)} />
                    <Typography style={{ fontSize: '12px' }}>{AppHelper.getDateTimeFromISODate(item.created_at)}</Typography>

                    <Typography style={{ fontSize: '12px' }} color="textSecondary">{item.parsed_message}</Typography>
                  </Grid>
                  {/* <ListItemIcon>
                    { item.read_status === 0 ? <Chip size="small" color="primary" label={`1 ${t('unread')}`} /> : null }
                  </ListItemIcon> */}
                </ListItem>
              );
            })
            : null
        }
      </InfiniteScroll>
    </List>
  );
};

const mapStateToProps = (state) => {
  const {
    page,
    lastPage,
    notificationList,
    notificationListLoading,
    notificationListLoadingMore,
  } = state.notification;

  return {
    page,
    lastPage,
    notificationList,
    notificationListLoading,
    notificationListLoadingMore,
  };
};

const NotificationList = connect(mapStateToProps, {
  getNotificationList,
  changeNotificationReadStatus,
})(_NotificationList);
export default NotificationList;
