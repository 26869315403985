import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Lottie from 'lottie-react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { LogoAppBar } from '../common';
import { Box } from '@material-ui/core';
import AuthHelper from '../../helpers/AuthHelper';

const error404Animation =  require('../../img/lottie/error404.json');

const useStyles = makeStyles(() => ({
  lottieContainer: {
    height: '30%',
    width: '30%',
    minWidth: 200,
    minHeight: 200
  },
  text: {
    textAlign: 'center',
    whiteSpace: 'pre-line'
  }
}));

const PageNotFoundScreen = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const render = () => {
    return (
      <Grid
        container
        component="main"
        style={{ minHeight: '100vh' }}
        alignItems='center'
        justifyContent='center'
      >
        <CssBaseline />
        <LogoAppBar />
        <Grid
          item
          container
          alignItems='center'
          justifyContent='center'
          direction='column'
          style={{ width: '80%' }}
        >
          <Lottie
            animationData={error404Animation}
            loop
            className={classes.lottieContainer}
          />
          <Box mb={1}>
            <Typography
              className={classes.text}
              variant='h4'
            >
              {t('error404.pageNotFound')}
            </Typography>
          </Box>
          <Typography
            className={classes.text}
            variant='body1'
            color='textSecondary'
          >{t('error404.youAreLostGoHome')}</Typography>
          <Box mt={2}>
            <Button
              color='primary'
              component={Link}
              variant='contained'
              to={
                AuthHelper.isLoggedIn()
                  ? '/dash'
                  : '/sign-in'
              }
            >
              {
                AuthHelper.isLoggedIn()
                  ? t('error404.backToDashboard')
                  : t('error404.backToSignIn')
              }
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return render();
};

export default PageNotFoundScreen;