import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { IconButton, Avatar, Typography, Grid, ListItem, List, Badge, ListItemAvatar, ListItemText, ListSubheader } from '@material-ui/core';
import ClassIcon from '@material-ui/icons/Class';
import VideocamIcon from '@material-ui/icons/Videocam';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Popover from '@material-ui/core/Popover';

import {
  getUpcomingTeacherLessonBookingList,
  getUpcomingStudentLessonBookingList,
  onSlotClick,
} from '../../actions';
import AppHelper from '../../helpers/AppHelper';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(3),
  },
}));

const UpcomingCall = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const {
    // upcomingLoading,
    upcomingLessonBookings,
    // actions below
    onSlotClick,
    getUpcomingTeacherLessonBookingList,
    getUpcomingStudentLessonBookingList
  } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getUpcomingLessons = useCallback(() => {
    if (AppHelper.isCBETeacher()) {
      getUpcomingTeacherLessonBookingList();
    } else {
      getUpcomingStudentLessonBookingList();
    }
  }, [getUpcomingTeacherLessonBookingList, getUpcomingStudentLessonBookingList]);

  useEffect(() => {
    getUpcomingLessons();
  }, [getUpcomingLessons]);

  const renderItem = (value, index) => {
    const participant = value.meet_id ? value.participants[0] : AppHelper.createParticipant(value);

    const startDateTime = moment(new Date(value.start_date_time)).fromNow();

    return (
      <ListItem key={index} button onClick={() => onSlotClick({ meetId: value.meet_id, bookingSlotId: value.booking_slot_id })}>
        <ListItemAvatar>
          {
            participant ?
              <Avatar alt={participant.first_name+participant.last_name} src={participant.avatar_url} />
              :
              <Avatar>
                <ClassIcon />
              </Avatar>
          }
          
        </ListItemAvatar>
        <ListItemText primary={participant ? participant.first_name+participant.last_name : t('lesson.notBooked')} secondary={`${startDateTime}`} />
      </ListItem>
    );
  };

  return (
    <div>
      <IconButton
        aria-label="upcomings"
        className={classes.button}
        color="primary"
        onClick={handleClick}
      >
        <Badge color="secondary" badgeContent={upcomingLessonBookings.length}>
          <VideocamIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        
        {
          upcomingLessonBookings.length < 1 ?
            <Grid container alignItems="center" style={{ height: 80, width: 400 }}>
              <Grid item>
                <IconButton size="medium" style={{ marginLeft: 6, marginRight: 6 }}>
                  <AutorenewIcon onClick={getUpcomingLessons} />
                </IconButton>
              </Grid>

              <Grid item xs={12} sm container direction="column">
                <Typography><strong>{t('lesson.upcomingLesson')}</strong></Typography>
                <Typography>{t('lesson.noUpcomingLesson')}</Typography>
              </Grid>
            </Grid>
            :
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  {t('lesson.upcomingLesson')}
                </ListSubheader>
              }
              style={{ width: 400 }}
            >
              {
                upcomingLessonBookings.map(renderItem)
              }
            </List>
        }
      </Popover>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    upcomingLessonBookings,
    upcomingLoading
  } = state.lesson;

  return {
    upcomingLessonBookings,
    upcomingLoading
  };
};

export default connect(mapStateToProps, {
  getUpcomingTeacherLessonBookingList,
  getUpcomingStudentLessonBookingList,
  onSlotClick,
})(UpcomingCall);