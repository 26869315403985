import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

const PaymentMethodPicker = (props) => {
  const {
    includesYoubrioWallet = false,
    selectedPaymentMethod,
    onSelect = () => {},
    currencyCode,
    textFieldProps
  } = props;

  const { t } = useTranslation();

  const [paymentMethodChoices, setPaymentMethodChoices] = useState(currencyCode === 'CNY' ? ['BTCN'] : ['BT']);

  // let paymentMethodChoices = ['BT', 'AP'];

  // if (currencyCode === 'CNY') {
  //   paymentMethodChoices = ['AP', 'BTCN'];
  // }

  useEffect(() => {
    let _paymentMethodChoices = [...paymentMethodChoices];
    if (includesYoubrioWallet) {
      _paymentMethodChoices.unshift('YWAL');
    } else {
      _paymentMethodChoices = _paymentMethodChoices.filter((i) => i !== 'YWAL');
    }

    setPaymentMethodChoices(_paymentMethodChoices);
  }, [includesYoubrioWallet]);

  const paymentMethods = {
    BT: {
      id: 'BT',
      label: t('wallet.braintreeOption'),
      src: require('../../img/paypalAndCardLogo.png'),
      dimensions: {
        width: 0,
        height: 0
      }
    },
    BTCN: {
      id: 'BTCN',
      label: t('wallet.braintreeCnOption'),
      src: require('../../img/paypalAndCardCnLogo.png'),
      dimensions: {
        width: 0,
        height: 0
      }
    },
    AP: {
      id: 'AP',
      label: t('wallet.alipayOption'),
      src: require('../../img/alipayLogo.png'),
      dimensions: {
        width: 0,
        height: 0
      }
    },
    YWAL: {
      id: 'YWAL',
      label: t('wallet.youbrioWallet'),
      icon: <AccountBalanceWalletIcon color='primary' />
    }
  };

  useEffect(() => {
    const isSelectedFoundInTheList = paymentMethodChoices.find(i => i === selectedPaymentMethod);

    if (!isSelectedFoundInTheList) {
      onSelect(paymentMethodChoices[0]);
    }
  }, [currencyCode]);

  const handleChange = (event) => {
    onSelect(event.target.value);
  };

  const render = () => {
    return (
      <TextField
        id="outlined-select-currency"
        select
        label={t('wallet.topUpMethod')}
        value={selectedPaymentMethod}
        onChange={handleChange}
        helperText={t('wallet.pleaseSelectYourPreferredTopUpMethod')}
        {...textFieldProps}
      >
        {paymentMethodChoices.map((option) => {
          const paymentMethod = paymentMethods[option];
          return (
            <MenuItem key={paymentMethod.id} value={paymentMethod.id}>
              <Grid container alignItems='center'>
                <Grid item xs={8}>
                  <Typography style={{ whiteSpace: 'normal' }}>{paymentMethod.label}</Typography>
                </Grid>
                <Grid item container xs={4} justifyContent='flex-end'>
                  {
                    paymentMethod.icon
                      || <img alt="logo" style={{ width: '100%', height: '100%', maxWidth: '100px', maxHeight: '100px' }} src={paymentMethod.src} />
                  }
                </Grid>
              </Grid>
              
            </MenuItem>
          );
        })}
      </TextField>
    );
  };

  return render();
};

export { PaymentMethodPicker };