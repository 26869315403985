import React from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import _ from 'lodash';

import {
  updateSnackbarStates
} from '../../actions';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const _CustomizedSnackbar = (props) => {
  // const classes = useStyles();
  const {
    open,
    severity,
    autoHideDuration,
    message,
  } = props.snackbar;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    props.updateSnackbarStates({ prop: 'open', value: false });
  };

  const vertical = 'top';
  const horizontal = 'center';

  if (_.isEmpty(props.snackbar)) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      key={vertical + horizontal}
      autoHideDuration={autoHideDuration}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = (state) => {
  const {
    snackbar
  } = state.common;

  return {
    snackbar
  };
};

const CustomizedSnackbar = connect(mapStateToProps, {
  updateSnackbarStates
})(_CustomizedSnackbar);

export { CustomizedSnackbar };
