import {
  UPDATE_FUND_SUMMARY_STATE
} from './types';
import {
  appStartsLoading,
  appDoneLoading
} from './';
import {
  getFundBalanceDetails,
  getBraintreeToken,
  topUpFundByBraintreeV2,
  getYoubrioExchangeRate
} from '../api';

export const updateFundSummaryState = ({ prop, value }) => {
  return {
    type: UPDATE_FUND_SUMMARY_STATE,
    payload: { prop, value }
  };
};

export const getFundBalance = () => {
  return (dispatch) => {
    dispatch(updateFundSummaryState({ prop: 'isBalanceLoading', value: true }));
    getFundBalanceDetails()
      .then((response) => {
        // console.log(response);
        const {
          fund,
          topUpParams
        } = response.data;

        dispatch(updateFundSummaryState({ prop: 'id', value: fund.id }));
        dispatch(updateFundSummaryState({ prop: 'balance', value: fund.balance }));
        dispatch(updateFundSummaryState({ prop: 'currencyCode', value: fund.currency_code }));
        dispatch(updateFundSummaryState({ prop: 'topUpParams', value: topUpParams }));
        dispatch(updateFundSummaryState({ prop: 'isBalanceLoading', value: false }));
      })
      .catch((error) => {
        console.log(error);
        dispatch(updateFundSummaryState({ prop: 'isBalanceLoading', value: false }));
      });
  };
};

export const fetchBraintreeClientToken = ({ callback }) => {
  return (dispatch) => {
    dispatch(appStartsLoading());
    getBraintreeToken()
      .then((response) => {
        dispatch(appDoneLoading());
        callback({ token: response.data.token });
      })
      .catch((error) => {
        dispatch(appDoneLoading());
        callback({ error });
      });
  };
};

export const payFundDepositByBraintree = ({
  currencyCode,
  amount,
  surchargeAmount,
  totalAmount,
  nonce,
  callback
}) => {
  return (dispatch) => {
    dispatch(appStartsLoading());
    topUpFundByBraintreeV2({ currencyCode, amount, surchargeAmount, totalAmount, paymentMethodNonce: nonce })
      .then((response) => {
        // console.log(response);
        dispatch(appDoneLoading());
        
        if (callback) {
          callback({ response: response.data });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(appDoneLoading());
        if (callback) {
          callback({ error });
        }
      });
  };
};

export const getExchangeRate = ({ fromCurrency, toCurrency, successCallback, failCallback }) => {
  return (/*dispatch*/) => {
    getYoubrioExchangeRate({ fromCurrency, toCurrency })
      .then((response) => {
        successCallback(response.data);
        // console.log(response);
      })
      .catch((error) => {
        failCallback(error);
        console.log(error);
      });
  };
};
