import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import AppHelper from '../../helpers/AppHelper';
import {
  BraintreeDropIn,
  PaymentMethodPicker,
  LogoAppBar
} from '../common';
import { AlertDialog } from '../dialogs';
import {
  payFundDepositByBraintree,
  getFundBalance,
  appStartsLoading,
  appDoneLoading,
  getExchangeRate,
  openDialog
} from '../../actions';

const WalletTopUpScreen = (props) => {
  const {
    location,
    history,
    fundSummary
  } = props;

  const {
    id,
    currencyCode,
    topUpParams
  } = fundSummary;

  const {
    minTopUpAmt,
    maxTopUpAmt
  } = topUpParams;

  const { t } = useTranslation();
  const btDiv = useRef(null);

  const [amount, setAmount] = useState(location?.state?.amount || 100);
  // const [btInstance, setBtInstance] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('BT');
  const [exchangeRate, setExchangeRate] = useState(null);
  const [isLoadingForexRate, setIsLoadingForexRate] = useState(false);
  const [paymentCurrencyAmount, setPaymentCurrencyAmount] = useState(100);
  const paymentCurrency = (selectedPaymentMethod === 'AP') ? 'CNY' : currencyCode;
  const [isBtDropInVisible, setIsBtDropInVisible] = useState(false);
  const [isAmtConfirmed, setIsAmtConfirmed] = useState(false);

  useEffect(() => {
    props.getFundBalance();
  }, []);

  useEffect(() => {
    if (paymentCurrency !== currencyCode) {
      getForexRate();
    }
  }, [paymentCurrency]);

  const onDialogOkayPress = () => {
    const redirectPath = location?.state?.redirect?.pathname;
    if (redirectPath) {
      history.replace(redirectPath);
    } else {
      history.replace('/dash');
    }
  };

  const onTopUpDoneCallback = ({ response, error }) => {
    // TODO: give different feedback after top up
    if (response) {
      props.openDialog({
        dialog: AlertDialog,
        props: {
          title: t('wallet.topUp'),
          contentText: t('wallet.topUpSuccessful', {
            amount: `${currencyCode} ${AppHelper.getCurrencySignByCurrencyCode(currencyCode)}${amount}`
          }),
          disableEscapeKeyDown: true,
          disableBackdropClick: true,
          buttons: [
            {
              text: t('alert.ok'),
              onClick: onDialogOkayPress,
              primary: true
            }
          ]
        }
      });
    } else if (error) {
      props.openDialog({
        dialog: AlertDialog,
        props: {
          title: t('error'),
          contentText: t('wallet.topUpFailed'),
          disableEscapeKeyDown: true,
          disableBackdropClick: true,
          buttons: [
            {
              text: t('alert.ok'),
              onClick: onDialogOkayPress,
              primary: true
            }
          ]
        }
      });
    }
  };

  const onTopUpClick = () => {
    if (selectedPaymentMethod === 'BT' || selectedPaymentMethod === 'BTCN') {
      setIsBtDropInVisible(true);
      setIsAmtConfirmed(true);
    } else {
      // alipay operations
    }
  };

  const onCancelClick = () => {
    setIsAmtConfirmed(false);
    setIsBtDropInVisible(false);
  };

  const onPaymentMethodNonceReceived = ({ nonce }) => {
    props.payFundDepositByBraintree({
      currencyCode,
      amount,
      surchargeAmount: getSurchargeAmount(),
      totalAmount: getTopUpTotalPaymentAmount(),
      nonce,
      callback: onTopUpDoneCallback
    });
  };

  const onAmountChange = (e) => {
    const text = e.target.value;

    if (text) {
      const value = parseInt(text, 10);
      if (value > maxTopUpAmt) {
        setAmount(maxTopUpAmt);
      } else {
        setAmount(value);
      }
      return;
    }

    setAmount('');
  };

  const onAmountBlur = (e) => {
    const text = e.target.value;

    if (text) {
      const value = parseInt(text, 10);
      if (value > maxTopUpAmt) {
        setAmount(maxTopUpAmt);
      } else if (value < minTopUpAmt) {
        setAmount(minTopUpAmt);
      } else {
        setAmount(value);
      }
      return;
    }

    setAmount(minTopUpAmt);
  };

  const onBTError = (error) => {
    console.log(error);
  };

  const onBTInit = ({ instance }) => {
    // setBtInstance(instance);
    if (btDiv) {
      btDiv.current.scrollIntoView({ behavior: 'smooth' });
    }
    
  };

  const onPaymentMethodSelect = (value) => {
    setSelectedPaymentMethod(value);
  };

  const getForexRate = () => {
    setIsLoadingForexRate(true);
    props.appStartsLoading();
    props.getExchangeRate({
      fromCurrency: currencyCode,
      toCurrency: paymentCurrency,
      successCallback: (response) => {
        setExchangeRate(response.rate);
        setPaymentCurrencyAmount(parseFloat(response.rate * amount).toFixed(2));
        setIsLoadingForexRate(false);
        props.appDoneLoading();
      },
      failCallback: () => {
        props.appDoneLoading();
        setIsLoadingForexRate(false);

        props.openDialog({
          dialog: AlertDialog,
          props: {
            title: t('order.oops'),
            contentText: t('alert.somethingWentWrong'),
            disableEscapeKeyDown: true,
            disableBackdropClick: true,
            buttons: [
              {
                text: t('alert.cancel'),
                onClick: onDialogOkayPress
              },
              {
                text: t('alert.retry'),
                onClick: getForexRate,
                primary: true
              }
            ]
          }
        });
      }
    });
  };

  const getSurchargeAmount = () => {
    const {
      fundSummary
    } = props;

    const {
      paypalSurchargeRate,
      defaultSurchargeRate
    } = fundSummary.topUpParams;

    let surchargeRate = defaultSurchargeRate;
    let _amount;

    if (selectedPaymentMethod === 'BT' || selectedPaymentMethod === 'BTCN') {
      surchargeRate = paypalSurchargeRate;
      _amount = amount;
    } else {
      _amount = paymentCurrencyAmount;
    }

    // has to parse it to 2 decimal point to make sure the float value doesn't go haywire
    const surchargeAmount = parseFloat(_amount * surchargeRate).toFixed(2);
    // had to parse back to float otherwise calculating totap payment will concatenate it as string
    return parseFloat(surchargeAmount);
  };

  const getTopUpTotalPaymentAmount = () => {
    const surchargeAmount = getSurchargeAmount();
    const _amount = (selectedPaymentMethod === 'BT' || selectedPaymentMethod === 'BTCN') ? amount : paymentCurrencyAmount;
    const totalAmount = parseFloat(parseFloat(_amount) + surchargeAmount).toFixed(2);

    return totalAmount;
  };

  const getProcessingFeeLabel = () => {
    let label = t('wallet.processingFee');

    switch (selectedPaymentMethod) {
    case 'BT':
    case 'BTCN':
      label = t('wallet.thirdPartyProcessingFee');
      break;
    case 'AP':
      label = t('wallet.alipayProcessingFee');
      break;
    case 'WX':
      label = t('wallet.wechatProcessingFee');
      break;
    default:
      break;
    }

    return label;
  };

  const renderPriceDetails = () => {
    const currencySign = AppHelper.getCurrencySignByCurrencyCode(currencyCode);
    const paymentCurrencySign = AppHelper.getCurrencySignByCurrencyCode(paymentCurrency);
    const surchargeAmount = getSurchargeAmount();

    const renderPriceSubItemRow = ({ label, value }) => {
      return (
        <Grid item container>
          <Grid item xs={8}>
            <Typography>{label}</Typography>
          </Grid>
          <Grid item container xs={4} alignItems='center' justifyContent='flex-end'>
            <Typography variant='body1'>{value}</Typography>
          </Grid>
        </Grid>
      );
    };

    const renderTotalPriceRow = ({ label, value }) => {
      return (
        <Grid item container>
          <Grid item xs={8}>
            <Typography>{label}</Typography>
          </Grid>
          <Grid item container xs={4} alignItems='center' justifyContent='flex-end'>
            <Typography variant='h6' color='primary'>{value}</Typography>
          </Grid>
        </Grid>
      );
    };

    const renderExchangeRate = () => {
      const paymentCurrencySign = AppHelper.getCurrencySignByCurrencyCode(paymentCurrency);

      if (
        (selectedPaymentMethod === 'AP')
        && (currencyCode !== paymentCurrency)
        && exchangeRate
        && !isLoadingForexRate
      ) {
        return (
          <Grid item container direction='column'>
            <Divider className='my-2' />
            {/*renderPriceSubItemRow({ label: `(${currencyCode} ${currencySign}1 = CNY ¥${toCNYRate})`, value: '' })*/}
            {renderPriceSubItemRow({ label: t('wallet.convertedAmount'), value: `${paymentCurrency} ${paymentCurrencySign}${paymentCurrencyAmount}` })}
          </Grid>
        );
      }
    };

    return (
      <Grid item>
        <Paper square className='px-3 py-3'>
          {renderPriceSubItemRow({ label: t('wallet.topUpAmt'), value: `${currencyCode} ${currencySign}${amount}` })}
          {renderExchangeRate()}
          {
            selectedPaymentMethod && !isLoadingForexRate
              ? <Grid>
                {
                  surchargeAmount > 0
                  &&
                  renderPriceSubItemRow({ label: getProcessingFeeLabel(), value: `${paymentCurrency} ${paymentCurrencySign}${surchargeAmount.toFixed(2)}` })
                }
                {renderTotalPriceRow({ label: t('wallet.paymentAmount'), value: `${paymentCurrency} ${paymentCurrencySign}${getTopUpTotalPaymentAmount()}` })}
              </Grid>
              :
              null
          }
          <Grid container justifyContent='flex-end' className='mt-2'>
            {
              isAmtConfirmed
                ? <Button
                  variant='contained'
                  color='secondary'
                  onClick={onCancelClick}
                  disabled={isLoadingForexRate || !id} // id missing means it is still loading
                >
                  {t('alert.cancel')}
                </Button>
                : <Button
                  variant='contained'
                  color='primary'
                  onClick={onTopUpClick}
                  disabled={isLoadingForexRate || !id} // id missing means it is still loading
                >
                  {t('wallet.topUp')}
                </Button>
            }
          </Grid>
        </Paper>
      </Grid>
    );
  };

  const renderBtDropIn = () => {
    if (isBtDropInVisible) {
      return (
        <Grid item ref={btDiv}>
          <Paper square className='px-3 py-3'>
            <BraintreeDropIn
              onError={onBTError}
              onInitialized={onBTInit}
              onPaymentMethodNonceReceived={onPaymentMethodNonceReceived}
            />
          </Paper>
        </Grid>
      );
    }

    return null;
  };

  const render = () => {
    return (
      <Grid
        container
        alignItems='center'
        direction='column'
        style={{ backgroundColor: '#fafafa', minHeight: '100vh', paddingTop: 60 }}
      >
        <LogoAppBar showBackButton />  
        <Grid
          container
          style={{ maxWidth: '90%' }}
          className='py-3 px-3'
          direction='column'
          alignItems='center'
        >
          <Grid xs={12} md={6} item container direction='column' spacing={2}>
            <Grid item>
              <Paper square className='px-3 py-3'>
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="top-up-amt"
                    label={t('wallet.topUpAmt')}
                    name="top-up-amt"
                    value={amount}
                    onChange={onAmountChange}
                    error={amount < minTopUpAmt || amount > maxTopUpAmt}
                    helperText={t('wallet.minAmtMaxAmtTopUp', {
                      min: `${AppHelper.getCurrencySignByCurrencyCode(currencyCode)}${minTopUpAmt}`,
                      max: `${AppHelper.getCurrencySignByCurrencyCode(currencyCode)}${maxTopUpAmt}`
                    })}
                    className='hide-spinner'
                    type='number'
                    InputProps={{
                      startAdornment: (<InputAdornment position="start">{`${currencyCode} ${AppHelper.getCurrencySignByCurrencyCode(currencyCode)}`}</InputAdornment>)
                    }}
                    disabled={isAmtConfirmed}
                    onBlur={onAmountBlur}
                  />
                </FormControl>
                <FormControl fullWidth className={'mt-3'}>
                  <PaymentMethodPicker
                    selectedPaymentMethod={selectedPaymentMethod}
                    onSelect={onPaymentMethodSelect}
                    currencyCode={currencyCode}
                    textFieldProps={{ variant: 'outlined', disabled: isAmtConfirmed }}
                  />
                </FormControl>
              </Paper>
            </Grid>
            
            {
              selectedPaymentMethod
                ? renderPriceDetails()
                : null
            }

            {renderBtDropIn()}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return render();
};

const mapStateToProps = (state) => {
  const {
    fundSummary
  } = state.fund;

  return {
    fundSummary
  };
};

export default connect(mapStateToProps, {
  payFundDepositByBraintree,
  getFundBalance,
  appStartsLoading,
  appDoneLoading,
  getExchangeRate,
  openDialog
})(WalletTopUpScreen);