import moment from 'moment';
import _ from 'lodash';
import queryString from 'query-string';
import { getStore } from '../Store';
import GLOBAL from '../helpers/GLOBAL';
import config from '../config.json';
import i18n from '../i18n';
import { updateCommonStates } from '../actions';

const labels = {};
const messages = {};

const CONSTANT = require('./ConstantHelper');

class AppHelper {

  static isEmail(email) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }

  static isAlphabeticAndNumeric(string) {
    const regex = /^([a-zA-Z+]+[0-9+]+)|([0-9+]+[a-zA-Z+]+)$/;
    return regex.test(string);
  }

  static isAlipayPhoneNumber(phoneNum) {
    return (!isNaN(phoneNum) && phoneNum.length <= 15 && phoneNum.length >= 6);
  }

  static checkEmail(email) {
    const regex = /^(To@Update@Email@)/;
    if (regex.test(email)) {
      return '';
    }
    return email;
  }

  static isStudent(cbeRole) {
    return cbeRole.includes(CONSTANT.STRING.ROLE.STUDENT);
  }

  static isTeacher(cbeRole) {
    return cbeRole.includes(CONSTANT.STRING.ROLE.TEACHER);
  }

  static isABN(abn) {
    const regex = /^\d{11}/;
    return regex.test(abn);
  }

  static trimStr(str, length) {
    if (!str || str.length < 0) return '';

    return (
      (str.length > length) ?
        `${str.slice(0, length)}...`
        :
        str
    );
  }

  static isCBETeacher() {
    const { cbeRole } = GLOBAL.get('user');
    if (cbeRole) {
      return cbeRole.includes(CONSTANT.STRING.ROLE.TEACHER);
    }
    return false;
  }

  static isCBEStudent() {
    const { cbeRole } = GLOBAL.get('user');
    if (cbeRole) {
      return cbeRole.includes(CONSTANT.STRING.ROLE.STUDENT);
    }
    return false;
  }

  static isCBEAdmin() {
    const { cbeRole } = GLOBAL.get('user');
    if (cbeRole) {
      return cbeRole.includes(CONSTANT.STRING.ROLE.ADMIN);
    }
    return false;
  }

  static getUser() {
    return GLOBAL.get('user');
  }

  static getUserId() {
    const { id } = GLOBAL.get('user');
    return id;
  }

  static getYoubrioProfileId() {
    const { youbrioProfileId } = GLOBAL.get('user');
    return youbrioProfileId;
  }

  static isEmptyOrWhiteSpace(string) {
    let convertedString = string;
    if ((string === 0 || string) && typeof string !== 'string') {
      convertedString = String(string);
    }

    return (convertedString === undefined || convertedString === null || convertedString.length === 0 || convertedString.trim().length === 0);
  }

  static isDefaultPhoto(fileName) {
    const regex = /default\.jpg/;
    return regex.test(fileName);
  }

  static calculateAge(birthday) { // birthday is a date
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  static formatShortDate(date) {
    if (date) {
      return moment(date).format('DD/MM/YYYY');
    }
    return '';
  }

  static formatShortDateV2(date) {
    if (date) {
      return moment(date).format('YYYY-MM-DD');
    }
    return '';
  }

  static formateShortTime(date) {
    if (date) {
      if (AppHelper.getAppLocaleCodeForApi() === 'zh_CN' || AppHelper.getAppLocaleCodeForApi() === 'zh_TW') {
        return moment(date).format('ahh:mm');
      }
      return moment(date).format('hh:mma');
    }
    return '';
  }

  static formateShortTimeWithTime(date) {
    if (date) {
      if (AppHelper.getAppLocaleCodeForApi() === 'zh_CN' || AppHelper.getAppLocaleCodeForApi() === 'zh_TW') {
        return moment(date).format('MMMDD日  ahh:mm');
      }
      return moment(date).format('DD MMM  hh:mma');
    }
    return '';
  }

  static formatShortDateNaturalSpace(date) {
    if (date) {
      if (AppHelper.getAppLocaleCodeForApi() === 'zh_CN' || AppHelper.getAppLocaleCodeForApi() === 'zh_TW') {
        return moment(date).format('YYYY年MMMDD日');
      }
      return moment(date).format('DD MMM YYYY');
    }
    return date;
  }

  static formatShortDateSlash(date) {
    if (date) {
      if (AppHelper.getAppLocaleCodeForApi() === 'zh_CN' || AppHelper.getAppLocaleCodeForApi() === 'zh_TW') {
        return moment(date).format('YYYY/MM/DD');
      }
      return moment(date).format('DD/MM/YY');
    }
    return date;
  }

  static formatMonthYear(date) {
    if (moment(date).format('MMMM YYYY') === moment().format('MMMM YYYY')) {
      return labels.thisMonth;
    } else if (moment(date).format('MMMM YYYY') === moment().subtract(1, 'months').format('MMMM YYYY')) {
      return labels.lastMonth;
    }
    return moment(date).format('MMMM YYYY');
  }

  static formatUTCToLocalMonthYear(date) {
    if (moment.utc(date).local().format('MMMM YYYY') === moment().format('MMMM YYYY')) {
      return labels.thisMonth;
    } else if (moment.utc(date).local().format('MMMM YYYY') === moment().subtract(1, 'months').format('MMMM YYYY')) {
      return labels.lastMonth;
    }
    return moment.utc(date).local().format('MMMM YYYY');
  }

  static formatSectionListDataByDate(array, date) {
    const groupedArray = _.groupBy(array, item => this.formatMonthYear(item[date]));
    const reducedArray = _.reduce(groupedArray, (acc, next, index) => {
      acc.push({
        key: index,
        data: next
      });
      return acc;
    }, []);
    return reducedArray;
  }

  static formatSectionListDataBySectionHeader(array, sectionHeader) {
    const groupedArray = _.groupBy(array, item => item[sectionHeader]);
    const reducedArray = _.reduce(groupedArray, (acc, next, index) => {
      acc.push({
        key: index,
        data: next
      });
      return acc;
    }, []);
    return reducedArray;
  }

  static formatSectionListDataByUTCDate(array, date) {
    const groupedArray = _.groupBy(array, item => this.formatUTCToLocalMonthYear(item[date]));
    const reducedArray = _.reduce(groupedArray, (acc, next, index) => {
      acc.push({
        key: index,
        data: next
      });
      return acc;
    }, []);
    return reducedArray;
  }

  static getResultGrade(gradeId, mark) {
    let grade = '';

    if (gradeId < CONSTANT.INTEGER.GRADE_ID.PCBE) {
      if (mark >= 90) {
        grade = labels.passWithHighDistinction;
      } else if (mark >= 80) {
        grade = labels.passWithDistinction;
      } else if (mark >= 65) {
        grade = labels.passWithCredit;
      } else if (mark >= 50) {
        grade = labels.pass;
      } else {
        grade = labels.unsuccessful;
      }
    } else if (gradeId >= CONSTANT.INTEGER.GRADE_ID.PCBE) {
      if (mark >= 90) {
        grade = labels.passWithHighDistinction;
      } else if (mark >= 80) {
        grade = labels.passWithDistinction;
      } else if (mark >= 65) {
        grade = labels.pass;
      } else {
        grade = labels.unsuccessful;
      }
    }

    return grade;
  }

  static getPEResultGrade(mark) {
    let grade = '';

    if (mark >= 90) {
      grade = labels.highDistinction;
    } else if (mark >= 80) {
      grade = labels.distinction;
    } else if (mark >= 65) {
      grade = labels.credit;
    } else if (mark >= 50) {
      grade = labels.pass;
    } else {
      grade = labels.unsuccessful;
    }

    return grade;
  }

  static formatDuration(elapsedTimeInSec) {
    const elapsedTimeInMSec = elapsedTimeInSec * 1000;

    let seconds = Math.floor(elapsedTimeInMSec / 1000);
    let minutes = Math.floor(elapsedTimeInMSec / 60000);
    const hours = Math.floor(elapsedTimeInMSec / 3600000);
    seconds -= (minutes * 60);
    minutes -= (hours * 60);
    let formatted = `${minutes < 10 ?
      0 : ''}${minutes}:${seconds < 10 ?
      0 : ''}${seconds}`;

    if (!elapsedTimeInSec) {
      formatted = '00:00';
    }

    return formatted;
  }

  static isValidVideoUrl(url) {
    const regex = new RegExp(getStore().getState().common.validVideoRegex);
    return regex.test(url);
  }

  static isUploadedVideoLink(url) {
    const regex = new RegExp(getStore().getState().common.cbeVideoRegex);
    return regex.test(url);
  }

  static getSelectedOption(key, options) {
    if (options) {
      return options.filter((obj) => { return obj.key === key; })[0];
    }
    return null;
  }

  static getSelectedRadioOption(key, options) {
    if (options) {
      return options.filter((obj) => { return obj.value === key; })[0];
    }
    return null;
  }

  static getFileNameWithExtension(uri) {
    const lastSlashIndex = uri.lastIndexOf('/');
    const fileName = uri.substring(lastSlashIndex + 1);

    return fileName;
  }

  static getFileNameWithoutExtension(uri) {
    const lastSlashIndex = uri.lastIndexOf('/');
    const fileNameWithExt = uri.substring(lastSlashIndex + 1);
    const fileName = fileNameWithExt.replace(`.${this.getFileTypeExtension(fileNameWithExt)}`, '');

    return fileName;
  }

  static getFileTypeExtension(uri) {
    const lastDotIndex = uri.lastIndexOf('.');
    const fileExtension = uri.substring(lastDotIndex + 1);

    return fileExtension;
  }

  static padLeft(n, width, z) {
    const paddingChar = z || '0';
    const originalValue = String(n);
    if (n) {
      return originalValue.length >= width ? n : new Array(width - originalValue.length + 1).join(paddingChar) + originalValue;
    }
    return '';
  }

  static byteToMegaByte(byteValue, decimalPlaces = 2) {
    let megaByteValue = byteValue / 1048576.0;
    megaByteValue = megaByteValue.toFixed(decimalPlaces);
    return `${megaByteValue}MB`;
  }

  static formatName(firstName, lastName) {
    if (firstName && lastName) {
      if (firstName.match(/[\u3400-\u9FBF]/) && lastName.match(/[\u3400-\u9FBF]/)) {
        return `${lastName}${firstName}`;
      }
      return `${firstName} ${lastName}`;
    } else if (firstName || lastName) {
      return firstName || lastName;
    }
    return '';
  }

  static formatShortName(firstName, lastName) {
    if (firstName && lastName) {
      if (firstName.match(/[\u3400-\u9FBF]/) && lastName.match(/[\u3400-\u9FBF]/)) {
        return `${lastName}${firstName}`;
      }
      return `${firstName} ${lastName.charAt(0).toUpperCase()}.`;
    } else if (firstName || lastName) {
      return firstName || lastName;
    }
    return '';
  }

  static isChineseChar(string) {
    if (string.match(/[\u3400-\u9FBF]/) && string.match(/[\u3400-\u9FBF]/)) {
      return true;
    }
    return false;
  }

  static getAppLocaleCodeForApi() {
    if (GLOBAL.get('lang') === 'cnTW') { // if (labels.getInterfaceLanguage().includes('zh-TW') || labels.getInterfaceLanguage().includes('zh-Hant')) {
      return 'zh_TW';
    } else if (GLOBAL.get('lang') === 'cn') { // (labels.getInterfaceLanguage().includes('zh-CN') || labels.getInterfaceLanguage().includes('zh-Hans') || labels.getInterfaceLanguage().includes('zh')) {
      return 'zh_CN';
    }
    return 'en';
  }

  static formatSectionListDataByInitialLetter(array, stringField) {
    const groupedArray = _.groupBy(array, item => item[stringField].charAt(0));
    const reducedArray = _.reduce(groupedArray, (acc, next, index) => {
      acc.push({
        key: index,
        data: next
      });
      return acc;
    }, []);
    return reducedArray;
  }

  static getUploadPercentage(uploaded, total, decimalPlaces = 2) {
    let percentage = uploaded / total * 100;
    console.log('upload, total', uploaded, total);

    if (percentage < 0) {
      percentage = 0;
    }
    percentage = percentage.toFixed(decimalPlaces);
    return percentage;
  }

  static isValidURl(url) {
    const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
    return urlregex.test(url);
  }

  static isYoutubeUrl(url) {
    const regex = new RegExp('^(?:https?:\\/\\/)?(?:www\\.)?(?:youtu\\.be\\/|youtube\\.com\\/(?:embed\\/|v\\/|watch\\?v=|watch\\?.+&v=))((\\w|-){11})(?:\\S+)?$');
    return regex.test(url);
  }

  static getYoutubeVideoId(url) {
    let matches = null;
    if (this.isYoutubeUrl(url)) {
      matches = url.match(/youtube\.com\/watch\?v=([^&?/]+)/);
      if (matches) {
        return matches[1];
      }

      matches = url.match(/youtube\.com\/embed\/([^&?/]+)/);
      if (matches) {
        return matches[1];
      }

      matches = url.match(/youtube\.com\/v\/([^&?/]+)/);
      if (matches) {
        return matches[1];
      }

      matches = url.match(/youtu\.be\/([^&?/]+)/);
      if (matches) {
        return matches[1];
      }

      matches = url.match(/youtube\.com\/verify_age\?next_url=\/watch%3Fv%3D([^&?/]+)/);
      if (matches) {
        return matches[1];
      }

      return null;
    }
    return null;
  }

  static capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return string;
  }

  static getOutTradeNo(orderString) {
    const orderStringArray = orderString.split('&');
    let outTradeNoString = '';

    for (let i = 0; i < orderStringArray.length; i++) {
      if (orderStringArray[i].includes('out_trade_no')) {
        outTradeNoString = orderStringArray[i];
        break;
      }
    }
    outTradeNoString = outTradeNoString.split('"')[1];

    return outTradeNoString;
  }

  static getOutTradeNoV2(resultString) {
    const alipayResult = JSON.parse(resultString);
    console.log(alipayResult);
    const outTradeNo = alipayResult.alipay_trade_app_pay_response ? alipayResult.alipay_trade_app_pay_response.out_trade_no : '';

    return outTradeNo;
  }

  static getAgeGroupString(lowerAgeLimit, upperAgeLimit, ageGroupName) {
    if (lowerAgeLimit && upperAgeLimit) {
      return `${ageGroupName} (${lowerAgeLimit}-${upperAgeLimit} ${labels.yearsOld})`;
    } else if (lowerAgeLimit) {
      return `${ageGroupName} (${lowerAgeLimit} ${labels.yearsOldAndAbove})`;
    } else if (upperAgeLimit) {
      return `${ageGroupName} (${upperAgeLimit} ${labels.yearsOldAndBelow})`;
    } else if (parseInt(lowerAgeLimit, 10) === 0 && parseInt(upperAgeLimit, 10) === 0) {
      return ageGroupName;
    }
    return null;
  }

  static getDurationString(duration) {
    return labels.formatString(labels.upToMinutes, duration);
  }

  static formatShortNumberCount(num) {
    const parsedNum = parseInt(num, 10);
    if (parsedNum < 1000) {
      return num;
    } else if (parsedNum < 1000000) {
      return labels.formatString(labels.countThousand, Math.round(num / 100.0) / 10);
    } else if (parsedNum < 1000000000) {
      return labels.formatString(labels.countMillion, Math.round(num / 100000.0) / 10);
    } else if (parsedNum < 1000000000000) {
      return labels.formatString(labels.countBillion, Math.round(num / 100000000.0) / 10);
    }
    return num;
  }

  static getPhysicalCountry() {
    return getStore().getState().common.physicalCountryCode;
  }

  static isUserInChina() {
    return this.getPhysicalCountry() === 'CN';
  }

  static isOwnUser({ userId }) {
    return GLOBAL.get('user').id === userId;
  }

  static getDeepLinkComponents({ url }) {
    const deepLinkComponents = url.replace('https://', '').split('/');
    return deepLinkComponents;
  }

  static makeid() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  static arraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = arr1.length; i--;) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }

  //push item if it is not exist in the array, else pop the item
  static pushOrPop(arr, item) {
    const index = arr.indexOf(item);
    if (index !== -1) {
      arr.splice(index, 1);
    } else {
      arr.push(item);
    }

    return arr.sort((a, b) => a - b);
  }

  static formatUTCToLocalNaturalDateTime({ date }) {
    if (date) {
      if (AppHelper.getAppLocaleCodeForApi() === 'zh_CN' || AppHelper.getAppLocaleCodeForApi() === 'zh_TW') {
        return moment.utc(date).local().format('YYYY年MMMDD日 ahh:mm');
      }
      return moment.utc(date).local().format('DD MMM YYYY hh:mma');
    }
    return date;
  }

  static hasNewNotification({ lastSeenDateTime, latestNotification }) {
    if (lastSeenDateTime && latestNotification) {
      const parsedLastSeenDateTime = new Date(lastSeenDateTime);
      const parsedLatestNotificationTime = new Date(latestNotification.created_at);
      console.log('latestNotif Time', parsedLatestNotificationTime);
      console.log('lastSeen Time', parsedLastSeenDateTime);
      if (parsedLatestNotificationTime > parsedLastSeenDateTime) {
        return true;
      } else if (parsedLatestNotificationTime === parsedLastSeenDateTime) {
        console.log('two dates are identical');
      }
    }
    return false;
  }

  static formatNotificationDateTime({ date }) {
    const parsedDate = moment(new Date(date));
    const now = moment();

    // console.log('parsedDate', parsedDate);
    // console.log('now', now);

    if (now.diff(parsedDate, 'hours') <= 21) {
      return parsedDate.fromNow();
    }
    return this.formatUTCToLocalNaturalDateTime({ date });
  }

  static getRecurringDaysInWeek() {
    return getStore().getState().common.recurringDaysInWeek;
  }

  static formatLocal24HourMinute({ date }) {
    if (date) {
      if (date instanceof Date) {
        return moment(date).format('HH:mm');
      }
      return moment(new Date(date)).format('HH:mm');
    }
    return date;
  }

  static formatLocalNaturalDateWithDay({ date }) {
    if (date) {
      if (date instanceof Date) {
        if (AppHelper.getAppLocaleCodeForApi() === 'zh_CN' || AppHelper.getAppLocaleCodeForApi() === 'zh_TW') {
          return moment(date).format('YYYY年MMMDD日 dddd');
        }
        return moment(date).format('ddd DD MMM YYYY');
      }
      if (AppHelper.getAppLocaleCodeForApi() === 'zh_CN' || AppHelper.getAppLocaleCodeForApi() === 'zh_TW') {
        return moment(new Date(date)).format('YYYY年MMMDD日 dddd');
      }
      return moment(new Date(date)).format('ddd DD MMM YYYY');
    }
    return date;
  }

  static formatLocalNaturalDate({ date }) {
    if (date) {
      if (date instanceof Date) {
        if (AppHelper.getAppLocaleCodeForApi() === 'zh_CN' || AppHelper.getAppLocaleCodeForApi() === 'zh_TW') {
          return moment(date).format('YYYY年MMMDD日');
        }
        return moment(date).format('DD MMM YYYY');
      }
      if (AppHelper.getAppLocaleCodeForApi() === 'zh_CN' || AppHelper.getAppLocaleCodeForApi() === 'zh_TW') {
        return moment(new Date(date)).format('YYYY年MMMDD日');
      }
      return moment(new Date(date)).format('DD MMM YYYY');
    }
    return date;
  }

  static getLocalDateOnlyForCalendar(date) {
    if (date) {
      const parsedDate = moment(new Date(date));
      return parsedDate.format('YYYY-MM-DD');
    }
    return date;
  }

  static getTimeOnlyFromISODate(date) {
    if (date) {
      const parsedDate = moment(new Date(date));
      const lang = GLOBAL.get('lang');
      if (lang === 'cn' || lang === 'cnTW') {
        return parsedDate.format('HH:mm');
      }
      return parsedDate.format('hh:mm a');
    }
    return date;
  }

  static getDateTimeFromISODate(date) {
    if (date) {
      const parsedDate = moment(new Date(date));
      const lang = GLOBAL.get('lang');
      if (lang === 'cn' || lang === 'cnTW') {
        return parsedDate.format('DD/MM/YYYY HH:mm');
      }
      return parsedDate.format('DD/MM/YYYY hh:mm a');
    }
    return date;
  }

  static getSecondsToDate({ date, now }) {
    if (date) {
      if (now) {
        return moment(new Date(date)).diff(moment(now), 'seconds');
      }
      return moment(new Date(date)).diff(moment(), 'seconds');
    }
  }

  static formatSecondsToNow(second) {
    if (second) {
      const parsedDay = Math.floor(second / 60 / 60 / 24);
      const parsedHour = Math.floor((second - (parsedDay * 24 * 60 * 60)) / 60 / 60);
      const parsedMinute = Math.floor((second - (parsedDay * 24 * 60 * 60) - (parsedHour * 60 * 60)) / 60);
      const parsedSecond = Math.floor((second - (parsedDay * 24 * 60 * 60) - (parsedHour * 60 * 60) - (parsedMinute * 60)));

      return {
        days: parsedDay,
        hours: parsedHour,
        minutes: parsedMinute,
        seconds: parsedSecond
      };
    }
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };
  }

  static dateHasPassed({ date, now }) {
    const parsedDate = new Date(date);

    if (parsedDate < now) {
      return true;
    }
    return false;
  }

  static isSlotOverlap(slots, bookingSlots, minimumDuration) {
    const slotStart = new Date(slots.start);
    const slotEnd = new Date(slots.end);

    // console.log('slot time', slotStart, slotEnd);

    let bookingSlotStart = new Date(bookingSlots.start);
    let bookingSlotEnd = new Date(bookingSlots.end);

    // console.log('booking slot time', bookingSlotStart, bookingSlotEnd);

    const bookingDurationMinute = moment(bookingSlotEnd).diff(moment(bookingSlotStart), 'minutes');
    // console.log('bookingDuration', bookingDurationMinute);

    bookingSlotStart = moment(bookingSlotStart)
      .year(moment(slotStart).year())
      .month(moment(slotStart).month())
      .date(moment(slotStart).date())
      .toDate();

    bookingSlotEnd = moment(bookingSlotStart).add(bookingDurationMinute, 'minutes').toDate();

    // console.log('new booking slot time', bookingSlotStart, bookingSlotEnd);

    // (StartA <= EndB) and (EndA >= StartB) 1-2pm overlaps with 2-3pm, 1-2pm does not overlap with 2.01pm-3pm
    // (StartA < EndB) and (EndA > StartB) 1-2pm does not overlap with 2-3pm, 1-2pm overlaps with 1.59pm-3pm
    if ((slotStart < bookingSlotEnd) && (slotEnd > bookingSlotStart)) {
      const leftoverTimeSlots = [];
      /*
      ** ss=slotStart, se=slotEnd, bs=bookingSlotStart, be=bookingSlotEnd

      ss|_________________________|se
            bs|__________|be
      bs > ss && be < se then return [ss-bs, be-se]

      ss|______|se
         bs|________________|be
      bs > ss && be >= se then return [ss-bs]

                    ss|______|se
      bs|________________|be
      bs <= ss && be < se then return [be-se]
      */
      if (minimumDuration) {
        if (bookingSlotStart > slotStart && bookingSlotEnd < slotEnd) {
          // we only include leftoverTimeSlots that has greater duration than min_duration
          // for eg, leftoverTimeSlots that has only 5 minutes is not needed
          if (moment(bookingSlotStart).diff(moment(slotStart), 'minutes') >= minimumDuration) {
            leftoverTimeSlots.push({
              newStartTime: slotStart,
              newEndTime: bookingSlotStart
            });
          }
          if (moment(slotEnd).diff(moment(bookingSlotEnd), 'minutes') >= minimumDuration) {
            leftoverTimeSlots.push({
              newStartTime: bookingSlotEnd,
              newEndTime: slotEnd
            });
          }
        } else if (bookingSlotStart > slotStart && bookingSlotEnd >= slotEnd) {
          if (moment(bookingSlotStart).diff(moment(slotStart), 'minutes') >= minimumDuration) {
            leftoverTimeSlots.push({
              newStartTime: slotStart,
              newEndTime: bookingSlotStart
            });
          }
        } else if (bookingSlotStart <= slotStart && bookingSlotEnd < slotEnd) {
          if (moment(slotEnd).diff(moment(bookingSlotEnd), 'minutes') >= minimumDuration) {
            leftoverTimeSlots.push({
              newStartTime: bookingSlotEnd,
              newEndTime: slotEnd
            });
          }
        }
      }
      return { isOverlap: true, leftoverTimeSlots };
    }
    return { isOverlap: false, leftoverTimeSlots: [] };
  }

  static isSlotOverlapForBooking(slots, bookingSlots) {
    const slotStart = new Date(slots.start);
    const slotEnd = new Date(slots.end);


    const bookingSlotStart = new Date(bookingSlots.start);
    const bookingSlotEnd = new Date(bookingSlots.end);

    // console.log('booking slot time', bookingSlotStart, bookingSlotEnd);

    // const bookingDurationMinute = moment(bookingSlotEnd).diff(moment(bookingSlotStart), 'minutes');
    // console.log('bookingDuration', bookingDurationMinute);

    // bookingSlotStart = moment(bookingSlotStart)
    //   .year(moment(slotStart).year())
    //   .month(moment(slotStart).month())
    //   .date(moment(slotStart).date())
    //   .toDate();
    //
    // bookingSlotEnd = moment(bookingSlotStart).add(bookingDurationMinute, 'minutes').toDate();

    // console.log('new booking slot time', bookingSlotStart, bookingSlotEnd);

    // (StartA <= EndB) and (EndA >= StartB) 1-2pm overlaps with 2-3pm, 1-2pm does not overlap with 2.01pm-3pm
    // (StartA < EndB) and (EndA > StartB) 1-2pm does not overlap with 2-3pm, 1-2pm overlaps with 1.59pm-3pm
    if ((slotStart < bookingSlotEnd) && (slotEnd > bookingSlotStart)) {
      const leftoverTimeSlots = [];
      const bStart = bookingSlotStart.getTime();
      const bEnd = bookingSlotEnd.getTime();
      const sStart = slotStart.getTime();
      const sEnd = slotEnd.getTime();
      /*
      ** ss=slotStart, se=slotEnd, bs=bookingSlotStart, be=bookingSlotEnd

      ss|_________________________|se
            bs|__________|be
      bs > ss && be < se then return [ss-bs, be-se]

      ss|______|se
        bs|____|be
      bs > ss && be === se then return [ss-bs]

       ss|________|se
       bs|____|be
      bs === ss && be < se then return [be-se]
      */
      if (bStart > sStart && bEnd < sEnd) {
        leftoverTimeSlots.push({
          newStartTime: slotStart,
          newEndTime: bookingSlotStart
        });
        leftoverTimeSlots.push({
          newStartTime: bookingSlotEnd,
          newEndTime: slotEnd
        });
      } else if (bStart > sStart && bEnd === sEnd) {
        leftoverTimeSlots.push({
          newStartTime: slotStart,
          newEndTime: bookingSlotStart
        });
      } else if (bStart === sStart && bEnd < sEnd) {
        leftoverTimeSlots.push({
          newStartTime: bookingSlotEnd,
          newEndTime: slotEnd
        });
      }
      return { isOverlap: true, leftoverTimeSlots };
    }
    return { isOverlap: false, leftoverTimeSlots: [] };
  }

  static formatLessonFromNowTime({ date }) {
    if (date) {
      const parsedDate = moment(new Date(date));
      return parsedDate.fromNow();
    }
    return date;
  }

  static minutesToNow(date) {
    if (date) {
      const minutes = moment(date).diff(moment(), 'minutes');
      return minutes;
    }
    return 0;
  }

  static isAllowToStartLesson({ startDateTime, endDateTime, startMinuteAllowance, endMinuteAllowance, now }) {
    if (startDateTime && endDateTime) {
      if (now) {
        const allowedStartTime = moment(now).add(startMinuteAllowance, 'minutes').toDate();
        const allowedEndTime = moment(now).subtract(endMinuteAllowance, 'minutes').toDate();

        const startTime = moment(new Date(startDateTime)).toDate();
        const endTime = moment(new Date(endDateTime)).toDate();

        if (startTime <= allowedStartTime && endTime >= allowedEndTime) {
          return true;
        }
      } else {
        const allowedStartTime = moment().add(startMinuteAllowance, 'minutes').toDate();
        const allowedEndTime = moment().subtract(endMinuteAllowance, 'minutes').toDate();

        const startTime = moment(new Date(startDateTime)).toDate();
        const endTime = moment(new Date(endDateTime)).toDate();

        if (startTime <= allowedStartTime && endTime >= allowedEndTime) {
          return true;
        }
      }
    }
    return false;
  }

  static canCompleteLesson({ startDateTime, endDateTime, now, completeMinuteAllowance }) {
    if (completeMinuteAllowance === null) {
      return false;
    }

    if (startDateTime && endDateTime) {
      const end = moment(endDateTime);
      const start = moment(startDateTime);
      const duration = end.diff(start, 'minutes');
      if (duration < completeMinuteAllowance) {
        return true;
      }
    }

    const nowDateTime = now || new Date();

    const allowedCompleteTime = moment(nowDateTime).subtract(completeMinuteAllowance, 'minutes').toDate();
    return (moment(new Date(startDateTime)).toDate() <= allowedCompleteTime);
  }

  static insertArrayAscending({ item, array, keyForCompare }) {
    array.splice(this.locationOf(item, array, this.arrayComparerForSort, keyForCompare) + 1, 0, item);
    return array;
  }

  static locationOf(element, array, comparer, key, start, end) {
    if (array.length === 0) {
      return -1;
    }

    const startB = start || 0;
    const endB = end || array.length;
    const pivot = (startB + endB) >> 1;  // should be faster than dividing by 2

    const c = comparer(element, array[pivot], key);
    if (endB - startB <= 1) return c === -1 ? pivot - 1 : pivot;

    switch (c) {
    case -1: return this.locationOf(element, array, comparer, key, startB, pivot);
    case 0: return pivot;
    case 1: return this.locationOf(element, array, comparer, key, pivot, endB);
    default: return;
    }
  }

  // comparer for above sort insert function
  static arrayComparerForSort = function (a, b, key) {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  };

  static getLessonTimerString({ startTime, now }) {
    if (startTime) {
      let prefix = '';

      let seconds = moment(now).diff(moment(startTime), 'seconds');
      if (seconds < 0) {
        prefix = '-';
        seconds = -(seconds);
      }

      const parsedHour = Math.floor(seconds / 60 / 60);
      const parsedMinute = Math.floor((seconds - (parsedHour * 60 * 60)) / 60);
      const parsedSecond = Math.floor((seconds - (parsedHour * 60 * 60) - (parsedMinute * 60)));

      const hour = (`0${parsedHour}`).slice(-2);
      const minute = (`0${parsedMinute}`).slice(-2);
      const second = (`0${parsedSecond}`).slice(-2);

      return `${prefix} ${hour}:${minute}:${second}`;
    }
    return null;
  }

  static isStudentLateCancelLesson({ startTime, minHour }) {
    const allowedEarlyCancelTime = moment().add(minHour, 'hours').toDate();
    return allowedEarlyCancelTime > moment(new Date(startTime)).toDate();
  }

  static getLessonStatuses() {
    // this is not in ConstantHelper as it relies on the labels folder and not totally constant
    return { // teacher
      CF: labels.confirmed,
      CP: labels.bookingCompleted,
      CSL: labels.cancelledByStudent,
      CSM: labels.cancelledByStudent,
      CT: labels.cancelledByTeacher,
      CTT: labels.cancelledByTeacher,
      IP: labels.inProgress,
      RJ: labels.bookingRejected,
      RQ: labels.awaitingApproval,
      EX: this.isCBETeacher() ? labels.expiredTeacher : labels.expiredStudent
    };
  }

  static isIncompleteProfileAccount() {
    const isIncomplete = parseInt(GLOBAL.get('user').basic, 10) === 1;
    return isIncomplete;
  }

  static getRandomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  static numberToAlphabet(num) {
    let number = num;
    const baseChar = ('a').charCodeAt(0);
    let letters = '';

    do {
      number -= 1;
      letters = String.fromCharCode(baseChar + (number % 26)) + letters;
      number = (number / 26) >> 0; // quick `floor`
    } while (number > 0);

    return letters;
  }

  static getYoubrioWebUrl() {
    const host = getStore().getState().common.youbrioUrl;
    return host.replace(/api\//gi, '');
  }

  static isRecurringNextDay({ startDateTime, endDateTime, slotStart }) {
    // increment one day to the startDateTime to get the next day date
    const nextDay = new Date(startDateTime);
    console.log('before nextday', nextDay);
    nextDay.setDate(nextDay.getDate() + 1);
    console.log('after nextday', nextDay);

    const slotStartMoment = moment(new Date(slotStart));
    console.log('slotStartMoment', slotStartMoment);

    // now parse the date to get the actual slotStart time for the next day
    const nextDaySlotStart = moment(nextDay);
    nextDaySlotStart.hours(slotStartMoment.hours())
      .minutes(slotStartMoment.minutes())
      .seconds(slotStartMoment.seconds())
      .milliseconds(0)
      .toDate();

    console.log('nextDaySlotStart', nextDaySlotStart);
    console.log('endDateTime', endDateTime);

    // if the next day slot start time is greater than the endDateTime of the slot validity
    // it means that it's not recurring
    if (nextDaySlotStart > new Date(endDateTime)) {
      const newEndDateTime = moment(new Date(startDateTime)).hours(23)
        .minutes(59)
        .seconds(59)
        .milliseconds(999)
        .toDate();

      return { recurring: false, newEndDateTime };
    }

    return { recurring: true, newEndDateTime: null };
  }

  static roundUpTime(time, minutesToRound) {
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    // Convert hours and minutes to time in minutes
    const parsedTime = (hours * 60) + minutes;

    const rounded = Math.ceil(parsedTime / minutesToRound) * minutesToRound;
    const rHr = String(Math.floor(rounded / 60));
    const rMin = String(rounded % 60);

    return `${_.padStart(rHr, 2, '0')}:${_.padStart(rMin, 2, '0')}`;
  }

  static roundUpDateTime(date, minutesToRound) {
    const dateMoment = moment(new Date(date));
    const hours = dateMoment.hours();
    const minutes = dateMoment.minutes();

    // Convert hours and minutes to time in minutes
    const parsedTime = (hours * 60) + minutes;

    const rounded = Math.ceil(parsedTime / minutesToRound) * minutesToRound;
    const rHr = String(Math.floor(rounded / 60));
    const rMin = String(rounded % 60);

    dateMoment.hours(rHr)
      .minutes(rMin)
      .seconds(0)
      .milliseconds(0);

    return dateMoment.toDate();
  }

  static getNextQuarterHour(dateTime) {
    if (dateTime) {
      const date = moment(new Date(dateTime));
      const minuteOfDate = date.minutes();
      let roundUpToMinute = 15;

      if (minuteOfDate < 15) {
        roundUpToMinute = 15;
      } else if (minuteOfDate < 30) {
        roundUpToMinute = 30;
      } else if (minuteOfDate < 45) {
        roundUpToMinute = 45;
      } else if (minuteOfDate <= 59) {
        roundUpToMinute = 60;
      }

      const minutesToAdd = roundUpToMinute - minuteOfDate;
      date.add(minutesToAdd, 'minutes');

      return date.seconds(0).milliseconds(0).toDate();
    }
    return null;
  }

  static formatStringWithISODateSubstring(string) {
    if (string) {
      const found = []; // an array to collect the strings that are found
      const rxp = /{([^}]+)}/g;
      let str = string;
      let curMatch = [];

      // it is intended as an assignment, ignore the no-cond-assign warning for eslint
      // eslint-disable-next-line no-cond-assign
      while (curMatch = rxp.exec(str)) {
        found.push(curMatch[1]);
      }

      found.forEach((foundStr) => {
        str = str.replace(`{${foundStr}}`, this.formatUTCToLocalNaturalDateTime({ date: foundStr }));
      });

      return str;
    }
    return '';
  }

  static getYoutubeEmbedUrl(youtubeId, embedUrlTemplate) {
    if (youtubeId && embedUrlTemplate) {
      const embedUrl = embedUrlTemplate.replace('{YOUTUBE_ID}', youtubeId);
      return embedUrl;
    }
    return null;
  }

  static getCurrencySignByCurrencyCode(currencyCode) {
    let currencySign;

    switch (currencyCode) {
    case 'GBP':
      currencySign = '£';
      break;
    case 'CNY':
    case 'JPY':
      currencySign = '¥';
      break;
    case 'EUR':
      currencySign = '€';
      break;
    case 'MYR':
      currencySign = 'RM';
      break;
    case 'VND':
      currencySign = '₫';
      break;
    case 'KRW':
      currencySign = '₩';
      break;
    default:
      currencySign = '$';
      break;
    }

    return currencySign;
  }

  static roundUpMinutes(date) {
    date.setHours(date.getHours() + 1);
    date.setMinutes(0);
    date.setSeconds(0);

    return date;
  }

  static fillArray(from, to, interval = 1) {
    const arr = [];
    for (let i = from; i <= to;) {
      arr.push(i);
      i += interval;
    }

    return arr;
  }

  static getSlotDateTime(startDateTime, endDateTime) {
    //add 23:59:59.999 for endDate for its exacly like lesson slot
    const startTime = moment(startDateTime).add(({ ms: 0 }));
    const endTime = moment(endDateTime).add(({ ms: 0 }));
    const startDate = moment(startDateTime).hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0);
    const endDate = moment(startDateTime).add(({ h: 23, m: 59, s: 59, ms: 999 }));
    return { startTime, endTime, startDate, endDate, recurringDay: null, isRecurring: false };
  }

  static findSlot(dateSlotObj, lessonRequest) {
    //create a selected lesson slot according to lesson request
    const dateArr = dateSlotObj[moment(lessonRequest.start_date_time).format('YYYY-MM-DD')];
    const index = _.findIndex(dateArr, (d) => {
      const requestedStart = new Date(lessonRequest.start_date_time);
      return d.startTime.getTime() === requestedStart.getTime();
    }
    );
    const slot = dateArr[index];
    if (slot) {
      slot.startDateTime = slot.startTime;
      slot.endDateTime = slot.endTime;
      delete slot.startTime;
      delete slot.endTime;
      slot.duration = (slot.endDateTime.getTime() - slot.startDateTime.getTime()) / (1000 * 60);
    }
    return slot;
  }

  static millisToMinutesAndSeconds(millis) {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  static paginate(array, pageSize, pageNum) {
    --pageNum; // because pages logically start with 1, but technically with 0
    return array.slice(pageNum * pageSize, (pageNum + 1) * pageSize);
  }

  static getChatServerUrl() {
    return config.chatServerUrl[process.env.REACT_APP_ENV || 'dev'][process.env.REACT_APP_REGION || 'global'];
  }

  static simpleFormatDatetimeWithLocalTimezone(date) {
    if (date) {
      const deviceTimezone = AppHelper.getDeviceTimezone();
      return `${moment(date).format('ddd, MMMM Do YYYY, h:mma')} (${deviceTimezone.city} ${labels.localTime})`;
    }
    return '';
  }

  static msgDateTime({ date }) {
    if (date) {
      const parsedDate = moment(new Date(date));
      const now = moment(Date.now());
      const diff = now.diff(parsedDate, 'days');
      if (diff < 1) {
        return parsedDate.format('hh:mm a');
      } else if (diff < 2) {
        return labels.yesterday;
      } else if (diff < 7) {
        return parsedDate.format('dddd');
      }

      return parsedDate.format('DD/MM/YYYY');
    }

    return date;
  }

  static formatLastOnlineTime({ date }) {
    if (date && moment(date)) {
      const parsedDate = moment(date);
      const now = moment(Date.now());
      const diff = now.diff(parsedDate);

      const years = Math.trunc(diff / 1000 / 60 / 60 / 24 / 365);
      const months = Math.trunc(diff / 1000 / 60 / 60 / 24 % 365 / 30);
      const weeks = Math.trunc(diff / 1000 / 60 / 60 / 24 % 365 % 30 / 7);
      const days = Math.trunc(diff / 1000 / 60 / 60 / 24 % 365 % 30 % 7);
      const hrs = Math.trunc(diff / 1000 / 60 / 60 % 24);
      const mins = Math.trunc(diff / 1000 / 60 % 60);

      // console.log('y__m__w__d__h__m', years, months, weeks, days, hrs, mins);

      if (years > 0) {
        return `7+ ${labels.daysDuration.toLowerCase()}`;
        // return `${years.toString()} ${labels.yearsDuration.toLowerCase()}`;
      } else if (months > 0) {
        return `7+ ${labels.daysDuration.toLowerCase()}`;
        // return `${months.toString()} ${labels.monthsDuration.toLowerCase()}`;
      } else if (weeks > 0) {
        if (weeks === 1 && days === 0) {
          return `7 ${labels.daysDuration.toLowerCase()}`;
        }
        return `7+ ${labels.daysDuration.toLowerCase()}`;
        // return `${weeks.toString()} ${labels.weeksDuration.toLowerCase()}`;
      } else if (days > 0) {
        return `${days.toString()} ${labels.daysDuration.toLowerCase()}`;
      } else if (hrs > 0) {
        return `${hrs.toString()} ${labels.hoursDuration.toLowerCase()}`;
      }

      return `${mins.toString()} ${labels.minsDuration.toLowerCase()}`;
    }

    return '';
  }

  static isActualAdmin() {
    const { cbeRole } = GLOBAL.get('user');
    if (cbeRole) {
      return (cbeRole.includes(CONSTANT.STRING.ROLE.ADMIN) || cbeRole.includes(CONSTANT.STRING.ROLE.YOUBRIO_ADMIN));
    }
    return false;
  }

  static shouldShowChangeRequestAlertTooltip(changeRequest, bookingSlotStatusId) {
    if (bookingSlotStatusId !== 'CF' && bookingSlotStatusId !== 'RQ') {
      return false;
    }

    if (changeRequest) {
      const {
        change_request_status_id: changeRequestStatusId,
        requested_by_user_id: requestByUserId
      } = changeRequest;

      const { id } = GLOBAL.get('user');

      if (
        (changeRequestStatusId === 1 && requestByUserId !== id) // pending and requested by other user
        || (changeRequestStatusId !== 1 && requestByUserId === id) // approved or rejeced but requested by this user
      ) {
        return true;
      }
    }
    return false;
  }

  static isLessonRelatedNotificationType(notifTypeId) {
    if (
      notifTypeId === 27
      || notifTypeId === 28
      || notifTypeId === 29
      || notifTypeId === 34
      || notifTypeId === 35
      || notifTypeId === 36
    ) {
      return true;
    }
    return false;
  }

  static isChinaOrNearbyTimezone(timezoneName) {
    console.log('timezoneName', timezoneName);

    if (!timezoneName) {
      return false;
    }
    // info from: http://www.world-timedate.com/timezone/timezone_info_by_country.php?country_id=70
    // https://www.php.net/manual/en/timezones.asia.php
    // https://www.php.net/manual/en/timezones.others.php
    const chinaTimezoneCities = ['Shanghai', 'Chongqing', 'Harbin', 'Kashgar', 'Urumqi'];
    const chinaNearbyCities = ['Hong_Kong', 'Macau', 'Macao'];
    try {
      const timezoneArray = timezoneName.split('/');
      if (timezoneArray[0] !== 'Asia') {
        return false;
      }
      const isChinaTimezone = chinaTimezoneCities.find((city) => city === timezoneArray[1]);
      const isChinaNearbyTimezone = chinaNearbyCities.find((city) => city === timezoneArray[1]);

      if (isChinaTimezone || isChinaNearbyTimezone) {
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  static utcDateToString = (momentInUTC) => moment.utc(momentInUTC).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

  static delay(milliseconds) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, milliseconds);
    });
  }

  static getInitial({ firstName, lastName, charLimit = 2 }) {
    if (firstName && lastName) {
      if (lastName && lastName.match(/[\u3400-\u9FBF]/)) {
        return lastName[0];
      }
      const name = `${firstName} ${lastName}`;
      const initial = name.split(' ').map((n) => n[0]).join('');
      return initial.substring(0, charLimit);
    } else if (firstName) {
      return firstName[0];
    } else if (lastName) {
      return lastName[0];
    }
    return '';
  }

  static generateProfileDeepLinking({ tidHashed, lidHashed, oidHashed }) {
    const youbrioWebUrl = AppHelper.getYoubrioWebUrl();
    // return different DeepLinkingUrl for different role
    if (tidHashed) {
      return (`${youbrioWebUrl}t/${tidHashed}`);
    } else if (lidHashed) {
      return (`${youbrioWebUrl}s/${lidHashed}`);
    } else if (oidHashed) {
      return (`${youbrioWebUrl}school/${oidHashed}`);
    }
    console.warn('Invalid hashed id passed in');
    return null;
  }

  static getFullname(firstName, lastName) {
    if (!firstName) {
      return lastName;
    }
    if (!lastName) {
      return firstName;
    }
    const REGEX_CHINESE = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/;
    
    const hasChinese = firstName.match(REGEX_CHINESE) || lastName.match(REGEX_CHINESE);

    if (hasChinese) {
      return `${lastName}${firstName}`;
    }

    return `${firstName} ${lastName}`;
  }

  /**
   * Get current user's fullname.
   */
  static getMyFullname() {
    const { firstName, lastName } = GLOBAL.get('user');
    if (!firstName) {
      return lastName;
    }
    if (!lastName) {
      return firstName;
    }
    const REGEX_CHINESE = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/;
    
    const hasChinese = firstName.match(REGEX_CHINESE) || lastName.match(REGEX_CHINESE);

    if (hasChinese) {
      return `${lastName}${firstName}`;
    }

    return `${firstName} ${lastName}`;
  }

  static isPastNHourFromNow({ date, hour }) {
    const parsedDate = moment(new Date(date));
    const now = moment();

    if (now.diff(parsedDate, 'hours') <= hour) {
      return true;
    }

    return false;
  }

  static isPrice(price) {
    // only works with string
    const regex = /^\d+(,\d{3})*(\.\d{1,2})?$/;
    return regex.test(price);
  }

  /**
   * Use totalFee to calculate which fee indication category it is belong
   * @param {float} totalFee
   */
  static getFeeIndication(totalFee) {
    const state = getStore().getState();
    const { currencyCode } = state.fund.fundSummary;
    const { searchCriteria } = state.findTeacher;
    if (!currencyCode || !searchCriteria) return '';

    const currencySign = AppHelper.getCurrencySignByCurrencyCode(currencyCode);
    let fee = null;
    let feeIndication = '';
    if (totalFee === 0) {
      feeIndication = labels.na;
    } else if (searchCriteria.hourlyRate && searchCriteria.hourlyRate.length > 0) {
      for (let i = 0; i < searchCriteria.hourlyRate.length; i++) {
        if (totalFee < searchCriteria.hourlyRate[i]) {
          fee = searchCriteria.hourlyRate[i];
          break;
        }
      }
      if (fee) {
        feeIndication = messages.formatString(labels.upTo, currencySign, fee.toString());
      } else {
        feeIndication = `${currencySign}${searchCriteria.hourlyRate[searchCriteria.hourlyRate.length - 1].toString().replace('+', '')} ${labels.andAbove}`;
      }
    }
    return feeIndication;
  }

  static getLocaleForMoment() {
    if (labels.getInterfaceLanguage().includes('zh-TW') || labels.getInterfaceLanguage().includes('zh-Hant')) {
      return 'zh-tw';
    } else if (labels.getInterfaceLanguage().includes('zh-CN') || labels.getInterfaceLanguage().includes('zh-Hans') || labels.getInterfaceLanguage().includes('zh')) {
      return 'zh-cn';
    }
    return 'en';
  }

  static isWechatDeepLink(url) {
    return url.includes(`${CONSTANT.STRING.WECHAT_APPID}://`);
  }

  static isFacebookDeepLink(url) {
    return url.includes(`${CONSTANT.STRING.FACEBOOK_URL_SCHEME}://`);
  }

  static isGoogleDeepLink(url) {
    return url.includes(`${CONSTANT.STRING.GOOGLE_URL_SCHEME}://`);
  }
  
  static get24hourArray() {
    const items = [];
    new Array(24).fill().forEach((acc, index) => {
      items.push(moment({ hour: index }));
      // items.push(moment({ hour: index, minute: 30 }).format('h:mm A'));
    });
    return items;
  }

  /**
   * Get calendar date in format of 6 x 7 (2D Array)
   * @param {moment Date} param 
   */
  static getDaysArrayByMonth(param) {
    var daysInMonth = param.daysInMonth();
    var arrDays = [];
  
    while(daysInMonth) {
      var current = param.clone().date(daysInMonth);
      arrDays.unshift(current);
      daysInMonth--;
    }

    while (arrDays[0].day() !== 1) {
      arrDays.unshift(arrDays[0].clone().subtract(1, 'day'));
    }

    while (arrDays.length < 42) {
      arrDays.push(arrDays[arrDays.length -1].clone().add(1, 'day'));
    }

    const _42Days = [];
    while(arrDays.length) _42Days.push(arrDays.splice(0,7));

    return _42Days;
  }

  /**
   * Convert a normal list to section list.
   */
  static sectionReducer = (acc, cur) => {
    const item = acc.find((x) => x.title === cur.title);
    if (item) {
      item.data.push(cur);
    } else {
      acc.push({
        title: cur.title,
        data: [cur]
      });
    }
    return acc;
  };

  static convertLangToLocale(lang) {
    if (lang === 'cn') {
      return 'zh-CN';
    } else if (lang === 'cnTW') {
      return 'zh-TW';
    }

    return lang;
  }

  static convertLocaleToLang(locale) {
    if (locale === 'zh-CN') {
      return 'cn';
    } else if (locale === 'zh-TW') {
      return 'cnTW';
    }

    return locale;
  }

  // Create participant from old booking lesson slot item.
  static createParticipant(item) {
    if (AppHelper.isCBETeacher()) {
      return {
        first_name: item.studentFirstName,
        last_name: item.studentLastName,
        avatar_url: item.studentAvatarUrl
      };
    } else {
      return {
        first_name: item.teacherFirstName,
        last_name: item.teacherLastName,
        avatar_url: item.teacherAvatarUrl
      };
    }
  }

  static openInNewTab(url) {
    /**
     * Using '_blank' only has vulnerability
     * source: https://stackoverflow.com/questions/45046030/maintaining-href-open-in-new-tab-with-an-onclick-handler-in-react
     */
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }

  static switchPortalLanguage(newLang) {
    const _newLang = newLang?.toLowerCase();
    // Update moment locale
    let locale = '';
    let lang = '';

    if (_newLang === 'en') {
      lang = 'en';
      locale = 'en';
    } else if (
      _newLang === 'zh_cn'
      || _newLang === 'cn'
    ) {
      lang = 'cn';
      locale = 'zh-CN';
    } else if (
      _newLang === 'zh_tw'
      || _newLang === 'tw'
    ) {
      lang = 'cnTW';
      locale = 'zh-TW';
    }

    const currentLocale = GLOBAL.get('i18nextLng', false);
    if (
      locale
      && currentLocale !== locale
    ) {
      GLOBAL.set('locale', locale);
      i18n.changeLanguage(locale);
      GLOBAL.set('lang', lang);
      return true;
    }

    return false;
  }

  static purgeSearchParamString(searchParamString) {
    const searchParams = queryString.parse(searchParamString);
    delete searchParams.lang;
    const purgedString = queryString.stringify(searchParams);

    return purgedString;
  }

  static showSnackbarNotification({
    severity = 'warning',
    autoHideDuration = 5000,
    message
  }) {
    getStore().dispatch(updateCommonStates({
      prop: 'snackbar',
      value: {
        open: true,
        severity,
        autoHideDuration,
        message
      }
    }));
  }

  static getHostNameFromUrl({ url }) {
    if (url) {
      const parsedUrl = new URL(url);
      return parsedUrl.hostname;
    }
    
    return '';
  }
}

export default AppHelper;
