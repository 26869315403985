import {
  getAvailablePracticeRoomPassList,
  getUnavailablePracticeRoomPassList,
} from '../api';
import {
  UPDATE_PRACTICE_ROOM_PASS_STATE,
  RESET_PRACTICE_ROOM_PASS_STATE,
} from '../actions/types';
// import GLOBAL from '../helpers/GLOBAL';

export const updatePracticeRoomPassState = ({ prop, value }) => {
  return {
    type: UPDATE_PRACTICE_ROOM_PASS_STATE,
    payload: { prop, value }
  };
};

export const resetPracticeRoomPassState = () => {
  return {
    type: RESET_PRACTICE_ROOM_PASS_STATE
  };
};

export const getAvailablePracticeRoomPassListAction = () => {
  return async (dispatch) => {
    dispatch(updatePracticeRoomPassState({ prop: 'isLoading', value: true }));
    try {
      const res = await getAvailablePracticeRoomPassList();
      const { passes } = res.data;
      if (passes) {
        dispatch(updatePracticeRoomPassState({ prop: 'availablePracticeRoomPassList', value: passes }));
      }
    } catch (err) {
      console.error('Failed to get the practice room pass list', err);
    } finally {
      dispatch(updatePracticeRoomPassState({ prop: 'isLoading', value: false }));
    }
  };
};

export const getUnavailablePracticeRoomPassListAction = () => {
  return async (dispatch) => {
    dispatch(updatePracticeRoomPassState({ prop: 'isLoading', value: true }));
    try {
      const res = await getUnavailablePracticeRoomPassList();
      const { passes } = res.data;
      if (passes) {
        dispatch(updatePracticeRoomPassState({ prop: 'unavailablePracticeRoomPassList', value: passes }));
      }
    } catch (err) {
      console.error('Failed to get the pass list', err);
    } finally {
      dispatch(updatePracticeRoomPassState({ prop: 'isLoading', value: false }));
    }
  };
};
