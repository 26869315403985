/* @flow */

import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
// import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';
import Lottie from 'lottie-react';
import Grid from '@material-ui/core/Grid';

import teachModeAnimation from '../../../img/lottie/teaching.json';
import practiceModeAnimation from '../../../img/lottie/weightlifting.json';

// import { KickCountdownDialog } from '../../dialogs/tutorRoom/KickCountdownDialog';
import { LearnModeInfoDialog } from '../../dialogs/tutorRoom/LearnModeInfoDialog';
import { openDialog } from '../../../actions';
import AppHelper from '../../../helpers/AppHelper';

const styles = {
  floatingBtnContainer: {
    // position: 'absolute',
    // top: 100,
    // marginLeft: -15,
    width: 'auto'
  },
  todayTotalTimerContainer: {
    height: 34,
    width: 100,
    borderRadius: 17,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    marginTop: 6,
    display: 'flex'
  },
  todayTotalTimerText: {
    color: '#fff',
    fontSize: 14
  }
};

/**
 * A Web Component which renders LearnModeIndicator.
 */
const LearnModeIndicator = (props) => {
  const {
    isVisible,
    _learnMode,
    _todayTotalLesson,
    openDialog: _openDialog,
    now
  } = props;

  /**
   * Click event handler when learn mode indicator is being pressed.
   *
   * @returns {void}
   */
  const _onLearnModeIndicatorPress = () => {
    _openDialog({
      dialog: LearnModeInfoDialog,
      props: {
        disableEscapeKeyDown: true,
        disableBackdropClick: true
      }
    });
  };

  /**
   * Renders learn mode indicator.
   *
   * @private
   * @returns {ReactElement|null}
   */
  const _renderLearnModeAnimation = () => {
    let animationData = null;
    let className = '';

    if (_learnMode === 1) {
      animationData = teachModeAnimation;
      className = 'button tutor-mode';
    } else if (_learnMode === 2) {
      animationData = practiceModeAnimation;
      className = 'button practice-mode';
    }

    if (animationData) {
      return (
        <div
          className = { className }
          onClick = { _onLearnModeIndicatorPress } >
          <Lottie
            animationData={animationData}
            loop
            autoplay
            style={{
              // width: 150,
              // height: 150,
              // transform: 'scale(1.5)'
            }} />
        </div>
      );
    }

    return null;
  };

  /**
   * Renders total in-lesson time for the day.
   *
   * @private
   * @returns {ReactElement|null}
   */
  const _renderTodayLessonTime = () => {
    if (!_todayTotalLesson) {
      return null;
    }

    const _now = moment(now);
    const lastEnterTime = _todayTotalLesson.currentTimestamp || Date.now();
    let totalSecondsElapsed = _now.diff(moment(lastEnterTime), 'seconds');

    totalSecondsElapsed += _todayTotalLesson.totalTime;

    const {
      hours,
      minutes,
      seconds
    } = AppHelper.formatSecondsToNow(totalSecondsElapsed);

    return (
      <div style = { styles.todayTotalTimerContainer }>
        <div style = { styles.todayTotalTimerText }>
          {hours.toString().padStart(2, 0)}
            :{minutes.toString().padStart(2, 0)}:{seconds.toString().padStart(2, 0)}
        </div>
      </div>
    );
  };

  /**
   * Implements React's {@link Component#render()}.
   *
   * @inheritdoc
   * @returns {ReactElement}
   */
  const render = () => {
    if (isVisible) {
      return (
        <Grid
          style={styles.floatingBtnContainer}
          container
          item
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          { _renderLearnModeAnimation() }
          { _renderTodayLessonTime() }
        </Grid>
      );
    }

    return null;
  };

  return render();
};

/**
 * Maps parts of Redux store to component prop types.
 *
 * @param {Object} state - Snapshot of Redux store.
 * @returns {Props}
 */
const mapStateToProps = (state) => {
  const { practiceRoomConfig } = state.practiceRoom;

  return {
    _todayTotalLesson: practiceRoomConfig.in_room_total_time,
    _learnMode: practiceRoomConfig.learnMode,
    practiceRoomConfig
  };
};

export default connect(mapStateToProps, {
  openDialog
})(LearnModeIndicator);
