// import AppHelper from '../helpers/AppHelper';
import YoubrioApiHelper from '../helpers/YoubrioApiHelper';

export const getAvailablePracticeRoomPassList = () => {
  return YoubrioApiHelper.get(`practice-room-passes/available`, true);
};

export const getUnavailablePracticeRoomPassList = () => {
  return YoubrioApiHelper.get(`practice-room-passes/unavailable`, true);
};

export const redeemPracticeRoomPass = (param) => {
  return YoubrioApiHelper.post('practice-room-passes/redeem', param, true);
};
