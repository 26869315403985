import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'; 
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Badge from '@material-ui/core/Badge';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import ToggleButton from '@material-ui/lab/ToggleButton';
import _ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { withStyles, styled } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles';
import {
  getTRSubscriptionPlans,
  createNewRecurringBillDraft,
  createOrder,
  openDialog
} from '../../actions';
import YoubrioApiHelper from '../../helpers/YoubrioApiHelper';
import AppHelper from '../../helpers/AppHelper';
import { LogoAppBar } from '../common';
import { Trans, useTranslation } from 'react-i18next';
import { AlertDialog } from '../dialogs';
import queryString from 'query-string';
import ApiHelper from '../../helpers/ApiHelper';
// import { reactAlipayTest } from '../../api';

/**
 * Overwrites the default style of ToggleButtonGroup
 */
const ToggleButtonGroup = styled(_ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButton-root': {
    border: '1px solid '.concat((0, fade)(theme.palette.primary.main, 0.2)),
    color: (0, fade)(theme.palette.primary.main, 0.9),
    '&:hover': {
      backgroundColor: (0, fade)(theme.palette.primary.main, 0.1)
    }
  },
  '& .MuiToggleButton-root.Mui-selected': {
    color: theme.palette.primary.main,
    backgroundColor: (0, fade)(theme.palette.primary.main, 0.2),
    '&:hover': {
      backgroundColor: (0, fade)(theme.palette.primary.main, 0.3)
    }
  }
}));

const TutorRoomSubscribeScreen = (props) => {
  const {
    classes,
    subscriptionPlans,
    history
  } = props;

  const { t } = useTranslation();

  const {
    plans
  } = subscriptionPlans;

  const mainPanelResponsiveProp = {
    xs: 12,
    sm: 9,
    md: 6
  };

  const imgPanelResponsiveProp = {
    xs: false,
    sm: 4,
    md: 6
  };

  const [expandedAccordionIndex, setExpandedAccordionIndex] = useState(0);

  const [choices, setChoices] = useState([]);
  const [selectedChoices, setSelectedChoices] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);

  // const handleButtonPress = () => {
  //   reactAlipayTest()
  //     .then((response) => {
  //       console.log(response);

  //       const newWindow = window.open('', '_self');
  //       newWindow.document.write(response.data);
  //       newWindow.focus();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const onSubscribeClick = () => {
    if (selectedPlan) {
      props.openDialog({
        dialog: AlertDialog,
        props: {
          title: t('signin.tncApply'),
          contentText: <Trans
            i18nKey="tutorRoom.byProceedingTRSubscriptionYouAgreeToTerms"
            values={{
              trTermLink: YoubrioApiHelper.getLegacyPortalPath(`tr_terms?lang=${AppHelper.getAppLocaleCodeForApi()}`),
              policyLink: YoubrioApiHelper.getLegacyPortalPath(`policies?lang=${AppHelper.getAppLocaleCodeForApi()}`)
            }}
            components={{ linkcom: <Link /> }}
          />,
          disableEscapeKeyDown: true,
          disableBackdropClick: true,
          buttons: [
            {
              text: t('alert.iDisagree')
            },
            {
              text: t('alert.iAgree'),
              onClick: () => {
                if (selectedPlan.can_recur) {
                  props.createNewRecurringBillDraft({
                    recurringBillTypeId: 1,
                    params: {
                      planId: selectedPlan.plan_id,
                      currencyCode: selectedPlan.currency_code,
                      amount: selectedPlan.amount
                    },
                    callback: ({ response, error }) => {
                      if (response) {
                        history.push(`/recurring-bill/${response.referenceId}/pay`);
                      } else if (error) {
                        console.log(error);
                      }
                    }
                  });
                  return;
                } else if (selectedPlan.is_passes) {
                  if (selectedPlan.can_pass_repurchase_recur) {
                    props.createNewRecurringBillDraft({
                      recurringBillTypeId: 2,
                      params: {
                        planId: selectedPlan.plan_id,
                        currencyCode: selectedPlan.currency_code,
                        amount: selectedPlan.amount
                      },
                      callback: ({ response, error }) => {
                        if (response) {
                          history.push(`/recurring-bill/${response.referenceId}/pay`);
                        } else if (error) {
                          console.log(error);
                        }
                      }
                    });
                    return;
                  }
                }

                props.createOrder({
                  orderCurrencyCode: selectedPlan.currency_code,
                  orderAmount: selectedPlan.amount,
                  planId: selectedPlan.plan_id,
                  callback: ({ response, error }) => {
                    if (response) {
                      history.push(`/order/${response.orderId}`);
                    } else if (error) {
                      console.log(error);
                    }
                  }
                });
                return;
              },
              primary: true
            }
          ]
        }
      });
    }
  };

  const onOptionSelected = (index) => (event, newValue) => {
    // console.log('onOptionSelected', index, newValue);
    let _selectedChoices = [ ...selectedChoices ];

    if (selectedChoices?.[index] !== undefined) {
      _selectedChoices[index] = newValue;
      _selectedChoices = _selectedChoices.splice(0, index + 1);
    } else {
      _selectedChoices.push(newValue);
    }
    setSelectedChoices(_selectedChoices);

    let _choices = [...choices];
    _choices = _choices.splice(0, index + 1);
    const newOptions = getMappedChoice({ index: index + 1, selected: _selectedChoices });

    if (newOptions) {
      _choices.push(newOptions);
      setExpandedAccordionIndex(index + 1);
      setSelectedPlan(null);
    } else { // if there are no new options, which means it has reached the end
      const planId = newValue;
      // console.log(planId, _choices?.[index]?.options);
      const _selectedPlan = _choices?.[index]?.options?.find((item) => item.plan_id === planId);
      setSelectedPlan(_selectedPlan);
    }

    // console.log('_selectedChoices', _selectedChoices);
    // console.log('_choices', _choices);

    setChoices(_choices);
  };

  const onAccordionExpandedChange = (index) => (event, isExpanded)=> {
    setExpandedAccordionIndex(isExpanded ? index : false);
  };

  useEffect(() => {
    const searchParams = queryString.parse(props.location.search);
    props.getTRSubscriptionPlans({ isPromo: searchParams.promo === 'true' });
  }, []);

  useEffect(() => {
    if (plans && Object.keys(plans)?.length > 0) {
      const firstChoice = getMappedChoice({ index: 0 });

      // console.log([firstChoice]);

      if (firstChoice) {
        setChoices([firstChoice]);
      }
    }
  }, [plans && Object.keys(plans)?.length]);

  const getMappedChoice = ({ index, selected }) => {
    let path = '';
    // plans.choices.piano.choices.piano_1.choices.1_m.choices

    const _selected = selected || selectedChoices;
    for (let i = 1; i <= index; i++) {
      if (path) {
        path = `${path}.choices.${_selected[i - 1]}`;
      } else {
        path = `choices.${_selected[i - 1]}`;
      }
    }

    // console.log('path', path);

    let trimmedObj = plans;
    if (path) {
      trimmedObj = _.get(plans, path);
    }

    let choice = null;

    if (trimmedObj && trimmedObj.choices) {
      choice = {
        id: trimmedObj.id,
        choiceLabel: trimmedObj.choiceLabel
      };
  
      const choiceOptions = [];
  
      const choiceKeys = Object.keys(trimmedObj.choices);
      const options = trimmedObj.choices;
  
      choiceKeys.forEach((objKey) => {
        if (options[objKey]?.plan_id) {
          choice.last = true;
          options[objKey].id = options[objKey].plan_id;
          const price = parseFloat(options[objKey].amount);
          const oriPrice = parseFloat(options[objKey].original_amount);
          options[objKey].hasPromo = price < oriPrice;
          if (options[objKey].hasPromo) {
            options[objKey].pctLess = Math.floor(100 * (oriPrice - price) / oriPrice);
          }
          choiceOptions.push(options[objKey]);
        } else {
          let hasPromo = false;
          let pctLess = 0;
          const nextChoices = trimmedObj?.choices?.[objKey]?.choices;

          if (nextChoices) {
            const nextChoiceIds = Object.keys(nextChoices);
            nextChoiceIds.forEach((_planId) => {
              const _plan = nextChoices[_planId];
              const price = parseFloat(_plan.amount);
              const oriPrice = parseFloat(_plan.original_amount);
              if (
                _plan.plan_id
                && price < oriPrice
              ) {
                hasPromo = true;
                pctLess = Math.floor(100 * (oriPrice - price) / oriPrice);
              }
            });
          }

          const _obj = {
            id: objKey,
            label: options[objKey]?.label,
            hasPromo
          };

          if (hasPromo) {
            _obj.pctLess = pctLess;
          }

          if (options[objKey]?.tierId) {
            _obj.tierId = options[objKey]?.tierId;
          }

          if (options[objKey]?.subjectGroupId) {
            _obj.subjectGroupId = options[objKey]?.subjectGroupId;
          }

          choiceOptions.push(_obj);
        }
        
      });
  
      choice.options = choiceOptions;
    }

    return choice;
  };

  const getSelectedChoiceLabel = ({ index }) => {
    const selectedChoiceId = selectedChoices?.[index];
    const choiceOptions = choices?.[index]?.options;

    const selected = choiceOptions.find(_item => _item.id === selectedChoiceId);
    return selected?.label || '';
  };

  const getSelectedTierId = () => {
    let tierId = null;
    selectedChoices.forEach((item, index) => {
      const choice = choices?.[index];

      // const _a is here to prevent no-unused-expressions error
      /* eslint-disable-next-line no-unused-vars */
      const _a = choice?.options?.forEach(_optItem => {
        if (String(_optItem.id) === item && _optItem.tierId) {
          tierId = _optItem.tierId;
        }
      });
    });

    return tierId != null ? String(tierId) : null;
  };

  const renderPlan = (plan) => {
    const {
      title,
      desc,
      amount,
      original_amount,
      currency_code
    } = plan;

    return (
      <Grid
        item
        container
        direction='column'
        style={{ whiteSpace: 'pre-wrap', textTransform: 'none' }}
      >
        <Typography variant='body1'>{title}</Typography>
        <Box my={2}>
          {
            original_amount
              ? <Typography variant='body2' style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>{`${currency_code} ${AppHelper.getCurrencySignByCurrencyCode(currency_code)}${original_amount}`}</Typography>
              : null
          }
          <Typography style={{ fontWeight: 'bold' }} variant='body1'>{`${currency_code} ${AppHelper.getCurrencySignByCurrencyCode(currency_code)}${amount}`}</Typography>
        </Box>
        <Typography style={{ opacity: 0.8 }} variant='caption'>{desc}</Typography>
      </Grid>
    );
  };

  const renderSubjectGroupCard = ({ subjectGroupId, title }) => {
    const subjectGroups = [
      {
        id: 1,
        src: ApiHelper.getCbeCdnUrl('/www/public/img/pianothumbnail.jpg')
      },
      {
        id: 2,
        src: ApiHelper.getCbeCdnUrl('/www/public/img/violinthumbnail.jpg')
      },
      {
        id: 3,
        src: ApiHelper.getCbeCdnUrl('/www/public/img/cellothumbnail.jpg')
      }
    ];

    const renderItem = ({ subjectGroup }) => {
      return (
        <Box m={-1} style={{ whiteSpace: 'pre-line' }}>
          {/* set minHeight so that when there's extra line for promo pack
          hour description, other cards will have the same height */}
          <Card>
            <CardMedia
              component="img"
              height="130"
              image={subjectGroup.src}
              alt={title}
            />
            <CardContent>
              <Typography>{title}</Typography>
            </CardContent>
          </Card>
        </Box>
      );
    };

    const _subjectGroup = subjectGroups.find(t => t.id === subjectGroupId);

    return renderItem({ subjectGroup: _subjectGroup });
  };

  const renderTierCard = ({ tierId }) => {
    const searchParams = queryString.parse(props.location.search);

    const tiers = [
      {
        id: 1,
        title: t('tutorRoom.tier.basic'),
        desc: t('tutorRoom.upToGradeFive'),
        hourDesc: (searchParams.promo === 'true')
          ? `${t('tutorRoom.threeHrsPerDay')}\n${t('signin.or')}\n${t('tutorRoom.oneHourPerDayLite')}`
          : t('tutorRoom.threeHrsPerDay'),
        src: ApiHelper.getCdnUrl('/public/www5/img/fee0.jpg')
      },
      {
        id: 2,
        title: t('tutorRoom.tier.pro'),
        desc: t('tutorRoom.gradeSixAndAbove'),
        hourDesc: t('tutorRoom.threeHrsPerDay'),
        src: ApiHelper.getCdnUrl('/public/www5/img/fee1.jpg')
      },
      {
        id: 4,
        title: t('tutorRoom.tier.master'),
        desc: t('tutorRoom.masterClass'),
        hourDesc: t('tutorRoom.twoHrsPerDay'),
        src: ApiHelper.getCdnUrl('/public/www5/img/fee3.jpg')
      }
    ];

    const renderItem = ({ tier }) => {
      return (
        <Box m={-1} style={{ whiteSpace: 'pre-line' }}>
          {/* set minHeight so that when there's extra line for promo pack
          hour description, other cards will have the same height */}
          <Card style={{ minHeight: searchParams.promo === 'true' ? 285 : null }}>
            <CardMedia
              component="img"
              height="130"
              image={tier.src}
              alt={tier.title}
            />
            <CardContent>
              <Typography>{tier.title}</Typography>
              {/* <Typography variant='body2' color='textSecondary'>{tier.desc}</Typography> */}
              <Divider
                // style={{ marginRight: 60 }}
                className='my-2'
              />
              <Typography variant='body2' color='textSecondary'>{tier.hourDesc}</Typography>
            </CardContent>
          </Card>
        </Box>
      );
    };

    const _tier = tiers.find(t => t.id === tierId);

    return renderItem({ tier: _tier });
  };

  const renderOptionComponent = ({ option }) => {
    if (option.plan_id) {
      return renderPlan(option);
    } else if (option.tierId) {
      return renderTierCard({ tierId: option.tierId });
    } else if (option.subjectGroupId) {
      return renderSubjectGroupCard({ subjectGroupId: option.subjectGroupId, title: option.label });
    }

    return (
      <Grid>
        <Typography>{option.label}</Typography>
      </Grid>
    );
  };

  const renderSelectionAccordions = () => {
    const components = choices.map((_choice, index) => {
      return (
        <Accordion
          square
          key={index} expanded={expandedAccordionIndex === index} onChange={onAccordionExpandedChange(index)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            // aria-controls="panel1d-content"
            // id="panel1d-header"
          >
            <Typography>{_choice.choiceLabel}</Typography>
            {
              selectedChoices?.[index] && !_choice.last && <Box ml={2}>
                <Typography style={{ color: '#777777' }}>{`${getSelectedChoiceLabel({ index: index })}`}</Typography>
              </Box>
            }
          </AccordionSummary>
          <AccordionDetails style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ToggleButtonGroup
              value={selectedChoices?.[index]}
              exclusive
              onChange={onOptionSelected(index)}
              aria-label={_choice.choiceLabel}
            >
              {
                _choice.options.map((opt) => <ToggleButton
                  key={opt.id}
                  size='large'
                  value={opt.id}
                  aria-label={opt.label}
                >
                  <Badge
                    // badgeContent={'⇩%'}
                    badgeContent={`-${opt.pctLess}%`}
                    invisible={!opt.hasPromo}
                    color='secondary'
                  >
                    {renderOptionComponent({ option: opt })}
                  </Badge>
                </ToggleButton>)
              }
            </ToggleButtonGroup>
          </AccordionDetails>
        </Accordion>
      );
    });

    return components;
  };

  const renderBottomBar = () => {
    const {
      amount,
      currency_code
    } = selectedPlan;

    return (
      <Grid
        item
        container
        justifyContent='flex-end'
        alignItems='center'
        {...mainPanelResponsiveProp}
        style={{ position: 'fixed', bottom: 0, background: '#fff' }}
      >
        <Paper
          square
          elevation={2}
          style={{ width: '100%' }}
        >
          <Grid container justifyContent='flex-end' alignItems='center'>
            <Grid item className='py-2 px-2'>
              <Typography style={{ marginRight: 6 }}>{`${t('order.total').toUpperCase()}: `}</Typography>
            </Grid>
            <Grid item className='py-2 px-2'>
              <Typography variant='h5'>{`${currency_code} ${AppHelper.getCurrencySignByCurrencyCode(currency_code)}${amount}`}</Typography>
            </Grid>
            <Grid item className='px-2'>
              <Button variant='contained' color='primary' onClick={onSubscribeClick}>
                {t('tutorRoom.subscribeOnly')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  };

  const renderAppBarRightComponent = () => {
    return (
      <Button
        color='primary'
        onClick={() => AppHelper.openInNewTab(YoubrioApiHelper.getLegacyPortalPath(`tr_terms?lang=${AppHelper.getAppLocaleCodeForApi()}`))}
      >
        {t('signin.termsTR')}
      </Button>
    );
  };

  const renderImagePanel = () => {
    const searchParams = queryString.parse(props.location.search);

    const tiers = [
      {
        id: 1,
        title: t('tutorRoom.tier.basic'),
        desc: t('tutorRoom.upToGradeFive'),
        hourDesc: (searchParams.promo === 'true')
          ? `${t('tutorRoom.threeHrsPerDay')}\n${t('signin.or')}\n${t('tutorRoom.oneHourPerDayLite')}`
          : t('tutorRoom.threeHrsPerDay'),
        src: ApiHelper.getCdnUrl('/public/www5/img/fee0.jpg')
      },
      {
        id: 2,
        title: t('tutorRoom.tier.pro'),
        desc: t('tutorRoom.gradeSixAndAbove'),
        hourDesc: t('tutorRoom.threeHrsPerDay'),
        src: ApiHelper.getCdnUrl('/public/www5/img/fee1.jpg')
      },
      {
        id: 4,
        title: t('tutorRoom.tier.master'),
        desc: t('tutorRoom.masterClass'),
        hourDesc: t('tutorRoom.twoHrsPerDay'),
        src: ApiHelper.getCdnUrl('/public/www5/img/fee3.jpg')
      }
    ];

    const renderItem = ({ tier }) => {
      return (
        <Grid item md={6} lg={4} key={tier.id} style={{ whiteSpace: 'pre-line' }}>
          <Box p={1}>
            {/* set minHeight so that when there's extra line for promo pack
            hour description, other cards will have the same height */}
            <Card style={{ minHeight: searchParams.promo === 'true' ? 360 : null }}>
              <CardMedia
                component="img"
                height="200"
                image={tier.src}
                alt={tier.title}
              />
              <CardContent>
                <Typography variant='h6'>{tier.title}</Typography>
                {/* <Typography variant='body2' color='textSecondary'>{tier.desc}</Typography> */}
                <Divider
                  // style={{ marginRight: 60 }}
                  className='my-2'
                />
                <Typography variant='body2' color='textSecondary'>{tier.hourDesc}</Typography>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      );
    };

    let displayedTiers = tiers.filter((tier) => String(tier.id) === getSelectedTierId());
    if (displayedTiers.length < 1) { displayedTiers = tiers; }

    return (
      <Grid
        item
        container
        alignItems='center'
        justifyContent={'center'}
        {...imgPanelResponsiveProp}
        style={{ backgroundColor: '#fafafa', paddingTop: 75, paddingBottom: 15, maxHeight: '100vh', overflow: 'auto' }}
        className={classes.imagePanel}
      >
        {
          displayedTiers.map(item => renderItem({ tier: item }))
        }
      </Grid>
    );
  };

  const renderMainPanel = () => {
    return (
      <Grid
        item container
        direction='column'
        style={{ maxHeight: '100vh', overflow: 'auto' }}
        {...mainPanelResponsiveProp}
      >
        <Paper
          elevation={2}
          style={{ flexGrow: 1, backgroundColor: '#fff', marginTop: 60, paddingBottom: 60 }}
          square
        >
          <Paper elevation={0} className='px-3 py-3 mb-2'>
            <Typography variant='h6'>{t('tutorRoom.subscribeTutorRoom')}</Typography>
          </Paper>
          {renderSelectionAccordions()}
        </Paper>
        {
          selectedPlan
            ? renderBottomBar()
            : null
        }
      </Grid>
    );
  };

  const _render = () => {
    return (
      <Grid
        container
        component="main"
        style={{ minHeight: '100vh', backgroundColor: '#fafafa' }}
        // direction="column"
        justifyContent="center"
      >
        <CssBaseline />
        <LogoAppBar
          rightComponent={renderAppBarRightComponent()}
          showBackButton
        />
        <Grid container>
          {renderImagePanel()}
          {renderMainPanel()}
        </Grid>
      </Grid>
    );
  };

  const renderEmptyPlan = () => {
    return (
      <Grid
        item
        container
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '100vh' }}
      >
        <Typography color='textSecondary' style={{ textAlign: 'center' }}>{t('tutorRoom.noPlansAvailableForYouAtm')}</Typography>
      </Grid>
    );
  };

  const render = () => {
    return (
      <Grid
        container
        component="main"
        style={{ minHeight: '100vh', backgroundColor: '#fff' }}
        justifyContent="center"
      >
        <CssBaseline />
        <LogoAppBar
          rightComponent={renderAppBarRightComponent()}
          showBackButton
        />
        <Grid
          container
          justifyContent="center"
        >
          {
            plans
              ? renderMainPanel()
              : renderEmptyPlan()
          }
        </Grid>
      </Grid>
    );
  };

  return render();
};

const styles = (theme) => ({
  // image: {
  //   backgroundRepeat: 'no-repeat',
  //   backgroundColor:
  //     theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center',
  //   color: 'white',
  // },
  imagePanel: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    height: '100vh',
    flexShrink: 0,
    minWidth: '100%',
    minHeight: '100%',
    // position: 'relative',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    }
  },
  leftSide: {
    flexShrink: 0,
    margin: '10% 0 10% 0',
  },
  leftSideImage: {
    width: 'auto',
    height: 'auto',
    maxHeight: '100vh',
    maxWidth: '100%',
  },

});

const mapStateToProps = (state) => {
  const {
    subscriptionPlans
  } = state.practiceRoom;

  return {
    subscriptionPlans
  };
};

export default connect(mapStateToProps, {
  getTRSubscriptionPlans,
  createNewRecurringBillDraft,
  createOrder,
  openDialog
})(
  withStyles(styles)(TutorRoomSubscribeScreen)
);
