import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {
  DialogContent,
  Grid,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Hidden,
  TextField,
  Tooltip,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Input,
  Radio,
  CircularProgress,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import {
  // Close as CloseIcon,
  // Photo as PhotoIcon,
  // CloudUpload as CloudUploadIcon,
  Person as PersonIcon,
  Edit as EditIcon,
  PersonOutline as PersonOutlineIcon,
  Email as EmailIcon,
  Warning as WarningIcon,
  Check as CheckIcon,
  // AccessTime as AccessTimeIcon,
  // DeleteForever as DeleteForeverIcon,
  // Done as DoneIcon,
  Send as SendIcon,
} from '@material-ui/icons';

import {
  // getUserProfile,
  saveUserProfile,
  // saveStudentProfile,
  // getYoubrioProfile,
  // getUserYoubrioProfile,
  // checkEmailExistence,
  // changeUserEmail,
  // updateUserProfileItem,
  // updateProfileDescription,
} from '../../api';
import GLOBAL from '../../helpers/GLOBAL';
import ChatHelper from '../../helpers/ChatHelper';
const CONSTANT = require('../../helpers/ConstantHelper');

const GenderSelects = [
  'male',
  'female',
  'others'
];

const styles = (theme) => ({
  dialogContent: {
    paddingTop: theme.spacing(2),
  },

  badge: {
    top: theme.spacing(2),
    right: -theme.spacing(2),
  },

  loadingBadge: {
    top: '50%',
    right: '50%',
  },

  avatar: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: theme.spacing(14),
    height: theme.spacing(14),
  },

  nameInitials: {
    cursor: 'default',
  },

  personIcon: {
    fontSize: theme.spacing(7),
  },

  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    minHeight: 'initial',
  },

  FormControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



const initialState = {
  profileCompletion: 0,
  showingField: '',
  avatar: null,
  avatarUrl: '',
  firstName: '',
  lastName: '',
  username: '',
  emailAddress: '',
  performingAction: false,
  loadingAvatar: false,
  sentVerificationEmail: false,
  errors: null,
  languages: [],
  subjects: [],
  mobile: '',
  dob: new Date(),
  gender: '',
  // not used
  subscribed: true,
  country: '',
  city: '',
};

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = '' + d.getFullYear();
  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }
  return [year, month, day].join('-');
}

class AccountTab extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  requestUpdateUserProfile = async(updates, upgradeAccountRole) => {
    const { openSnackbar, closeSnackbar} = this.props;
    const user = GLOBAL.get('user'); //const { user } = this.props;
    const newUser = {...user, ...updates};
    closeSnackbar();
    const response = await saveUserProfile(newUser, upgradeAccountRole);
    var responseData = response.data;
    if (typeof(responseData) === 'string') {
      responseData = eval('(' + responseData + ')'); // fail but jsonparse failed too
    }
    if (responseData.data) {
      GLOBAL.set('user', response.data.data);
      openSnackbar(
        'success',
        'Success'
      );
    } else {
      console.log('---updateUserProfile fail:', typeof(response.data), response.data);
      openSnackbar(
        'error',
        'Fail'
      );
    }
    if (upgradeAccountRole === CONSTANT.STRING.ROLE.TEACHER) {
      ChatHelper.sendNewTeacherAutoMessage();
    } else if (upgradeAccountRole === CONSTANT.STRING.ROLE.STUDENT) {
      ChatHelper.sendNewStudentAutoMessage();
    }
    return responseData;
  }

  getNameInitialsOrIcon = () => {
    const user = GLOBAL.get('user'); //const { user } = this.props;

    if (!user) {
      return null;
    }

    const { classes, userData } = this.props;

    if (!userData) {
      return <PersonIcon className={classes.personIcon} />;
    }

    // const nameInitials = authentication.getNameInitials({
    //   ...user,
    //   ...userData,
    // });
    const nameInitials = 'nameInitials';

    if (nameInitials) {
      return (
        <Typography className={classes.nameInitials} variant="h2">
          {nameInitials}
        </Typography>
      );
    }

    return <PersonIcon className={classes.personIcon} />;
  };

  showField = (fieldId) => {
    if (!fieldId) {
      return;
    }

    this.setState({
      showingField: fieldId,
    });
  };

  hideFields = (callback) => {
    this.setState(
      {
        showingField: '',
        // firstName: "",
        // lastName: "",
        username: '',
        emailAddress: '',
        errors: null,
      },
      () => {
        if (callback && typeof callback === 'function') {
          callback();
        }
      }
    );
  };

  changeFirstName = () => {
    const { firstName } = this.state;

    // const errors = validate(
    //   {
    //     firstName: firstName,
    //   },
    //   {
    //     firstName: constraints.firstName,
    //   }
    // );
    const errors = null;

    if (errors) {
      this.setState({
        errors: errors,
      });
      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const user = GLOBAL.get('user');
        const role = GLOBAL.get('role');

        if (firstName === user.firstName) {
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            this.requestUpdateUserProfile({firstName}, role)
              // eslint-disable-next-line no-unused-vars
              .then((user) => {
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;
                switch (code) {
                default:
                  this.props.openSnackbar(message);
                  return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeLastName = () => {
    const { lastName } = this.state;

    // const errors = validate(
    //   {
    //     lastName: lastName,
    //   },
    //   {
    //     lastName: constraints.lastName,
    //   }
    // );
    const errors = null;

    if (errors) {
      this.setState({
        errors: errors,
      });
      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const user = GLOBAL.get('user');
        const role = GLOBAL.get('role');
 
        if (lastName === user.lastName) {
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            this.requestUpdateUserProfile({lastName}, role)
              // eslint-disable-next-line no-unused-vars
              .then((user) => {
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;
                switch (code) {
                default:
                  this.props.openSnackbar(message);
                  return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeUsername = () => {
    const { username } = this.state;

    // const errors = validate(
    //  {
    //    username: username,
    //  },
    //  {
    //    username: 'constraints.username',
    //  }
    //);
    const errors = null;

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const user = GLOBAL.get('user');
        const role = GLOBAL.get('role');
 
        if (username === user.username) {
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            this.requestUpdateUserProfile({username}, role)
              // eslint-disable-next-line no-unused-vars
              .then((user) => {
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;
                switch (code) {
                default:
                  this.props.openSnackbar(message);
                  return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeEmailAddress = () => {
    const { emailAddress } = this.state;
    const errors = null;

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const user = GLOBAL.get('user');
        const role = GLOBAL.get('role');
 
        if (emailAddress === user.email) {
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            this.requestUpdateUserProfile({email: emailAddress, basic: 1}, role)
              // eslint-disable-next-line no-unused-vars
              .then((user) => {
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;
                switch (code) {
                default:
                  this.props.openSnackbar(message);
                  return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeMobile = () => {
    const { mobile } = this.state;

    // const errors = validate(
    //   {
    //     firstName: firstName,
    //   },
    //   {
    //     firstName: constraints.firstName,
    //   }
    // );
    const errors = null;

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const user = GLOBAL.get('user');
        const role = GLOBAL.get('role');

        if (mobile === user.mobile) {
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            this.requestUpdateUserProfile({mobile}, role)
              // eslint-disable-next-line no-unused-vars
              .then((user) => {
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;
                switch (code) {
                default:
                  this.props.openSnackbar(message);
                  return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };
  
  changeGender = () => {
    const { gender } = this.state;

    // const errors = validate(
    //   {
    //     firstName: firstName,
    //   },
    //   {
    //     firstName: constraints.firstName,
    //   }
    // );
    const errors = null;

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const user = GLOBAL.get('user');
        const role = GLOBAL.get('role');
 
        if (gender === user.gender) {
          return;
        }

        this.setState(
          {
            performingAction: true,
          },
          () => {
            this.requestUpdateUserProfile({gender}, role)
              // eslint-disable-next-line no-unused-vars
              .then((user) => {
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;
                switch (code) {
                default:
                  this.props.openSnackbar(message);
                  return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  changeDob = () => {
    const { dob } = this.state;

    // const errors = validate(
    //   {
    //     firstName: firstName,
    //   },
    //   {
    //     firstName: constraints.firstName,
    //   }
    // );
    const errors = null;

    if (errors) {
      this.setState({
        errors: errors,
      });

      return;
    }

    this.setState(
      {
        errors: null,
      },
      () => {
        const role = GLOBAL.get('role');
        const dobStr = formatDate(dob);
        //if (dob === user.dob) {
        //  return;
        //}

        this.setState(
          {
            performingAction: true,
          },
          () => {
            this.requestUpdateUserProfile({dob: dobStr}, role)
              // eslint-disable-next-line no-unused-vars
              .then((user) => {
              })
              .catch((reason) => {
                const code = reason.code;
                const message = reason.message;
                switch (code) {
                default:
                  this.props.openSnackbar(message);
                  return;
                }
              })
              .finally(() => {
                this.setState({
                  performingAction: false,
                });
              });
          }
        );
      }
    );
  };

  verifyEmailAddress = () => {
    this.setState(
      {
        performingAction: true,
      },
      () => {
        // authentication
        //   .verifyEmailAddress()
        //   .then(() => {
        //     this.setState(
        //       {
        //         sentVerificationEmail: true,
        //       },
        //       () => {
        //         this.props.openSnackbar("Sent verification e-mail");
        //       }
        //     );
        //   })
        //   .catch((reason) => {
        //     const code = reason.code;
        //     const message = reason.message;

        //     switch (code) {
        //       default:
        //         this.props.openSnackbar(message);
        //         return;
        //     }
        //   })
        //   .finally(() => {
        //     this.setState({
        //       performingAction: false,
        //     });
        //   });
      }
    );
  };

  changeField = (fieldId) => {
    switch (fieldId) {
    case 'first-name':
      this.changeFirstName();
      return;

    case 'last-name':
      this.changeLastName();
      return;

    case 'username':
      this.changeUsername();
      return;

    case 'email-address':
      this.changeEmailAddress();
      return;

    case 'mobile':
      this.changeMobile();
      return;
   
    case 'gender':
      this.changeGender();
      return;

    case 'dob':
      this.changeDob();
      return;

    default:
      return;
    }
  };

  handleKeyDown = (event, fieldId) => {
    if (!event || !fieldId) {
      return;
    }

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    const key = event.key;

    if (!key) {
      return;
    }

    if (key === 'Escape') {
      this.hideFields();
    } else if (key === 'Enter') {
      this.changeField(fieldId);
    }
  };

  handleAvatarChange = (event) => {
    if (!event) {
      return;
    }

    const files = event.target.files;

    if (!files) {
      return;
    }

    const avatar = files[0];

    if (!avatar) {
      return;
    }

    const fileTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/webp',
      'image/svg+xml',
    ];

    if (!fileTypes.includes(avatar.type)) {
      return;
    }

    if (avatar.size > 20 * 1024 * 1024) {
      return;
    }

    this.setState({
      avatar: avatar,
      avatarUrl: URL.createObjectURL(avatar),
    });
  };

  handleFirstNameChange = (event) => {
    if (!event) {
      return;
    }

    const firstName = event.target.value;

    this.setState({
      firstName: firstName,
    });
  };

  handleLastNameChange = (event) => {
    if (!event) {
      return;
    }

    const lastName = event.target.value;

    this.setState({
      lastName: lastName,
    });
  };

  handleUsernameChange = (event) => {
    if (!event) {
      return;
    }

    const username = event.target.value;

    this.setState({
      username: username,
    });
  };

  handleEmailAddressChange = (event) => {
    if (!event) {
      return;
    }

    const emailAddress = event.target.value;

    this.setState({
      emailAddress: emailAddress,
    });
  };

  handleMobileChange = (event) => {
    if (!event) {
      return;
    }

    const mobile = event.target.value;

    this.setState({
      mobile,
    });
  };

  handleGenderChange = (event) => {
    if (!event) {
      return;
    }

    const gender = event.target.value;

    this.setState({
      gender: gender,
    });
  };
  
  handleDobChange = (date) => {
    this.setState({
      dob: date,
    });
  };

  // eslint-disable-next-line no-unused-vars
  onClickSubmit = (event) => {
    this.changeFirstName();
    this.changeLastName();
    this.changeGender();
    this.changeDob();
    this.changeMobile();
  }

  renderFirstName() {
    // Styling
    // const { classes } = this.props;

    // Properties
    const user = GLOBAL.get('user'); // const { user } = this.props;

    // Events

    const {
      firstName,
      performingAction,
      errors,
    } = this.state;
    const hasFirstName = user && user.firstName;
    return (
      <ListItem>
        {!hasFirstName && (
          <ListItemIcon>
            <Tooltip title="No first name">
              <WarningIcon color="error" />
            </Tooltip>
          </ListItemIcon>
        )}

        <TextField
          autoComplete="given-name"
          autoFocus
          disabled={performingAction}
          error={!!(errors && errors.firstName)}
          fullWidth
          helperText={
            errors && errors.firstName
              ? errors.firstName[0]
              : ''
          }
          label="First name"
          placeholder={hasFirstName && firstName}
          required
          type="text"
          value={firstName}
          variant="filled"
          InputLabelProps={{ required: false }}
          onBlur={this.hideFields}
          onKeyDown={(event) => this.handleKeyDown(event, 'first-name')}
          onChange={this.handleFirstNameChange}
        />
      </ListItem>
    );
  }

  renderLastName() {
    // Properties
    const user = GLOBAL.get('user'); // const { user } = this.props;

    // Events
    const {
      lastName,
      performingAction,
      errors,
    } = this.state;

    const hasLastName = user && user.lastName;
    return (
      <ListItem>
        {!hasLastName && (
          <ListItemIcon>
            <Tooltip title="No last name">
              <WarningIcon color="error" />
            </Tooltip>
          </ListItemIcon>
        )}

        <TextField
          autoComplete="family-name"
          autoFocus
          disabled={performingAction}
          error={!!(errors && errors.lastName)}
          fullWidth
          helperText={
            errors && errors.lastName
              ? errors.lastName[0]
              : ''
          }
          label="Last name"
          placeholder={hasLastName && user.lastName}
          required
          type="text"
          value={lastName}
          variant="filled"
          InputLabelProps={{ required: false }}
          onBlur={this.hideFields}
          onKeyDown={(event) => this.handleKeyDown(event, 'last-name')}
          onChange={this.handleLastNameChange}
        />
      </ListItem>
    );
  }

  renderUsername() {
    // Properties
    const user = GLOBAL.get('user'); // const { user } = this.props;
    const { userData } = this.props;

    // Events
    const {
      showingField,
      performingAction,
      username,
      errors,
    } = this.state;

    const hasUsername = userData && userData.username;
    return (
      <ListItem>
        <Hidden xsDown>
          <ListItemIcon>
            <PersonOutlineIcon />
          </ListItemIcon>
        </Hidden>

        {!hasUsername && (
          <ListItemIcon>
            <Tooltip title="No username">
              <WarningIcon color="error" />
            </Tooltip>
          </ListItemIcon>
        )}

        {showingField === 'username' && (
          <TextField
            autoComplete="username"
            autoFocus
            disabled={performingAction}
            error={!!(errors && errors.username)}
            fullWidth
            helperText={
              errors && errors.username
                ? errors.username[0]
                : ''
            }
            label="Username"
            placeholder={hasUsername && user.username}
            required
            type="text"
            value={username}
            variant="filled"
            InputLabelProps={{ required: false }}
            onBlur={this.hideFields}
            onKeyDown={(event) => this.handleKeyDown(event, 'username')}
            onChange={this.handleUsernameChange}
          />
        )}

        {showingField !== 'username' && (
          <>
            <ListItemText
              primary="Username"
              secondary={
                hasUsername
                  ? user.username
                  : "You don’t have a username"
              }
            />

            <ListItemSecondaryAction>
              {hasUsername && (
                <Tooltip title="Change">
                  <div>
                    <IconButton
                      disabled={performingAction}
                      onClick={() => this.showField('username')}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              )}

              {!hasUsername && (
                <Button
                  color="primary"
                  disabled={performingAction}
                  variant="contained"
                  onClick={() => this.showField('username')}
                >
                  Add
                </Button>
              )}
            </ListItemSecondaryAction>
          </>
        )}
      </ListItem>
    );
  }

  renderEmail() {
    // Properties
    const user = GLOBAL.get('user'); // const { user } = this.props;

    // Events
    const {
      emailAddress,
      showingField,
      performingAction,
      sentVerificationEmail,
      errors,
    } = this.state;

    // const hasEmail = user && user.email;

    return (
      <ListItem>
        <Hidden xsDown>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
        </Hidden>

        {user.email && (
          <ListItemIcon>
            <>
              {user.emailVerified && (
                <Tooltip title="Verified">
                  <CheckIcon color="primary" />
                </Tooltip>
              )}

              {false && !user.emailVerified && (
                <Tooltip title="Not verified">
                  <WarningIcon color="error" />
                </Tooltip>
              )}
            </>
          </ListItemIcon>
        )}

        {!user.email && (
          <ListItemIcon>
            <Tooltip title="No e-mail address">
              <WarningIcon color="error" />
            </Tooltip>
          </ListItemIcon>
        )}

        {showingField === 'email-address' && (
          <TextField
            autoComplete="email"
            autoFocus
            disabled={performingAction}
            error={!!(errors && errors.emailAddress)}
            fullWidth
            helperText={
              errors && errors.emailAddress
                ? errors.emailAddress[0]
                : ''
            }
            label="E-mail address"
            placeholder={user.email}
            required
            type="email"
            value={emailAddress}
            variant="filled"
            InputLabelProps={{ required: false }}
            onBlur={this.hideFields}
            onKeyDown={(event) =>
              this.handleKeyDown(event, 'email-address')
            }
            onChange={this.handleEmailAddressChange}
          />
        )}

        {showingField !== 'email-address' && (
          <>
            <ListItemText
              primary="E-mail address"
              secondary={
                user.email ? user.email : "You don’t have an e-mail address"
              }
            />

            {false && user.email && !user.emailVerified && (
              <Box clone mr={7}>
                <ListItemSecondaryAction>
                  <Tooltip title="Verify">
                    <div>
                      <IconButton
                        color="secondary"
                        disabled={performingAction || sentVerificationEmail}
                        onClick={this.verifyEmailAddress}
                      >
                        <CheckIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </ListItemSecondaryAction>
              </Box>
            )}

            <ListItemSecondaryAction>
              {false && user.email && (
                <Tooltip title="Change">
                  <div>
                    <IconButton
                      disabled={performingAction}
                      onClick={() => this.showField('email-address')}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              )}

              {!user.email && (
                <Button
                  color="primary"
                  disabled={performingAction}
                  variant="contained"
                  onClick={() => this.showField('email-address')}
                >
                  Add
                </Button>
              )}
            </ListItemSecondaryAction>
          </>
        )}
      </ListItem>
    );
  }

  renderMobile() {
    // Properties
    const { user } = this.props;

    // Events
    const {
      // showingField,
      performingAction,
      mobile,
      errors,
    } = this.state;
    
    const hasMobile = user && user.mobile;
    return (
      <ListItem>
        {!hasMobile && (
          <ListItemIcon>
            <Tooltip title="No mobile">
              <WarningIcon color="error" />
            </Tooltip>
          </ListItemIcon>
        )}

        <TextField
          autoComplete="mobile"
          autoFocus
          disabled={performingAction}
          error={!!(errors && errors.mobile)}
          fullWidth
          helperText={
            errors && errors.mobile
              ? errors.mobile[0]
              : ''
          }
          label="Mobile"
          placeholder={hasMobile && user.mobile}
          required
          type="text"
          value={mobile}
          variant="filled"
          InputLabelProps={{ required: false }}
          onBlur={this.hideFields}
          onKeyDown={(event) => this.handleKeyDown(event, 'mobile')}
          onChange={this.handleMobileChange}
        />
      </ListItem>
    );
  }

  renderDob() {
    // Properties
    const { classes } = this.props;
    const user = GLOBAL.get('user');

    // Events
    const {
      // showingField,
      // performingAction,
      dob,
      // errors,
    } = this.state;
    
    const hasDob = user && user.dob;
    return (
      <ListItem>
        {!hasDob && (
          <ListItemIcon>
            <Tooltip title="No dob">
              <WarningIcon color="error" />
            </Tooltip>
          </ListItemIcon>
        )}

        <>
          <FormControl className={classes.formControl}>
            <InputLabel id="dob-label"></InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  margin="normal"
                  format="yyyy-MM-dd"
                  id="date-picker-id"
                  label="Date of Birth"
                  disableFuture={true}
                  openTo="year"
                  value={dob}
                  onChange={this.handleDobChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change dob',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </FormControl>
        </>
      </ListItem>
    );
  }
  
  renderRegion() {
    // Properties
    const { user, classes } = this.props;

    // Events
    const {
      showingField,
      performingAction,
      dob,
      // errors,
    } = this.state;
    
    const hasDob = user && user.dob;
    return (
      <ListItem>
        {!hasDob && (
          <ListItemIcon>
            <Tooltip title="No dob">
              <WarningIcon color="error" />
            </Tooltip>
          </ListItemIcon>
        )}

        {showingField === 'dob' && (
          <>
            <FormControl className={classes.formControl}>
              <InputLabel id="dob-label">Region</InputLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-id"
                    label="date-picker-label"
                    value={dob}
                    onChange={this.handleDobChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change region',
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </FormControl>
            <ListItemSecondaryAction>
              <Button
                color="primary"
                disabled={performingAction}
                variant="contained"
                onClick={() => this.changeField('dob')}
              >
                Save
              </Button>
            </ListItemSecondaryAction>
          </>
        )}

        {showingField !== 'dob' && (
          <>
            <ListItemText
              primary="Country/Region"
              secondary={
                hasDob
                  ? user.dob
                  : "You don’t have a dob"
              }
            />

            <ListItemSecondaryAction>
              {hasDob && (
                <Tooltip title="Change">
                  <div>
                    <IconButton
                      disabled={performingAction}
                      onClick={() => this.showField('dob')}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              )}

              {!hasDob && (
                <Button
                  color="primary"
                  disabled={performingAction}
                  variant="contained"
                  onClick={() => this.showField('dob')}
                >
                  Add
                </Button>
              )}
            </ListItemSecondaryAction>
          </>
        )}
      </ListItem>
    );
  };

  renderGender() {
    // Properties
    const { user, classes } = this.props;

    // Events
    const {
      // showingField,
      // performingAction,
      gender,
      // errors,
    } = this.state;
    
    const hasGender = user && user.gender;
    return (
      <ListItem>
        {!hasGender && (
          <ListItemIcon>
            <Tooltip title="No gender">
              <WarningIcon color="error" />
            </Tooltip>
          </ListItemIcon>
        )}

        <>
          <FormControl className={classes.formControl}>
            <InputLabel id="gender-label">gender</InputLabel>
            <Select
              labelId="gender-select-label"
              id="gender-select-checkbox"
              value={gender}
              onChange={this.handleGenderChange}
              input={<Input />}
              fullWidth
              renderValue={(selected) => (
                <div className={classes.chips}>
                  <Chip
                    key={selected}
                    label={selected}
                    className={classes.chip}
                    color="primary"
                    variant="outlined"
                    size="small"
                  />
                </div>
              )}
              MenuProps={MenuProps}
            >
              {GenderSelects.map((name)=> (
                <MenuItem key={name} value={name}>
                  <Radio checked={gender === name} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>

      </ListItem>
    );
  };

  renderSubmit() {
    const { performingAction} = this.state;
    return (
      <Grid container justify="center">
        <Grid item>
          {
            performingAction &&
            <CircularProgress variant="indeterminate" disableShrink size={40} thickness={4} />
          }
          {
            !performingAction &&
            <Button variant="contained" color="primary" onClick={this.onClickSubmit} endIcon={<SendIcon />}>
              Submit
            </Button>
          }
        </Grid>
      </Grid>
    );
  }

  render() {
    // Styling
    const { classes } = this.props;

    // Properties
    // const user = GLOBAL.get('user'); //const { user } = this.props;

    // Events
    // const {
    //   // avatarUrl,
    //   // profileCompletion,
    //   // showingField,
    //   // performingAction,
    //   // loadingAvatar,
    //   // avatar,
    //   // errors,
    // } = this.state;

    return (
      <DialogContent classes={{ root: classes.dialogContent }}>
        <List disablePadding>
          { this.renderEmail() }
          { this.renderFirstName() }
          { this.renderLastName() }
          { this.renderGender() }
          { this.renderDob() }
          { this.renderMobile() }
          { this.renderSubmit()}
          <Box mt={1} mb={1}>
            <Divider light />
          </Box>

        </List>
      </DialogContent>
    );
  }

  async componentWillMount() {
    // const response = await getUserProfile();
    // console.log('---------getUserProfile-response=', response.data);
  }

  componentDidMount() {
    const user = GLOBAL.get('user');
    // const filteredProfileAnswers = user && user.profileAnswers && user.profileAnswers.filter(x => x.teach_question === 'Languages');
    this.setState({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      mobile: user && user.mobile,
      dob: user && user.dob ? new Date(user.dob + 'T00:00:00') : new Date(),
      gender: user && user.gender,
    });
  }

  componentWillUnmount() {
    const { avatarUrl } = this.state;

    if (avatarUrl) {
      URL.revokeObjectURL(avatarUrl);

      this.setState({
        avatarUrl: '',
      });
    }
  }
}

AccountTab.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  //// Properties
  // user: PropTypes.object.isRequired,
  // userData: PropTypes.object,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(AccountTab);
