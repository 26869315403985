import {
  fetchRecurringBillDraftOrder,
  fetchRecurringBillDraft,
  createRecurringBillDraft,
  createNewRecurringBill
} from '../api';
import { AlertDialog } from '../components/dialogs';
import history from '../history';
import i18n from '../i18n';
import { appDoneLoading, appStartsLoading, openDialog } from './DialogActions';
import {
  RESET_RECURRING_BILL_DRAFT_ORDER_STATE,
  UPDATE_RECURRING_BILL_DRAFT_ORDER_STATE
} from './types';

export const updateRecurringBillDraftOrderState = ({ prop, value }) => {
  return {
    type: UPDATE_RECURRING_BILL_DRAFT_ORDER_STATE,
    payload: { prop, value }
  };
};

export const resetRecurringBillDraftOrderState = () => {
  return {
    type: RESET_RECURRING_BILL_DRAFT_ORDER_STATE
  };
};

export const getRecurringBillDraftOrder = ({
  recurringBillTypeId,
  params
}) => {
  return (dispatch) => {
    dispatch(appStartsLoading());
    fetchRecurringBillDraftOrder({
      recurringBillTypeId,
      params
    })
      .then((response) => {
        dispatch(appDoneLoading());
        dispatch(updateRecurringBillDraftOrderState({ prop: 'order', value: response.data.draftOrder }));
        // console.log(response);
      })
      .catch((error) => {
        dispatch(appDoneLoading());
        // console.log(error);
      });
  };
};

export const getRecurringBillDraft = ({ referenceId, callback }) => {
  return (dispatch) => {
    dispatch(appStartsLoading());
    fetchRecurringBillDraft({ referenceId })
      .then(({ data: response }) => {
        // console.log(response);
        dispatch(appDoneLoading());

        if (callback) {
          callback({ response });
        }
        dispatch(getRecurringBillDraftOrder({
          recurringBillTypeId: response.recurringBillDraftOrder.recurring_bill_type_id,
          params: response.recurringBillDraftOrder.params
        }));
      })
      .catch((error) => {
        // console.log(error);
        dispatch(appDoneLoading());

        if (callback) {
          callback({ error });
        }
      });
  };
};

export const createNewRecurringBillDraft = ({
  recurringBillTypeId,
  params,
  callback
}) => {
  return () => {
    createRecurringBillDraft({
      recurringBillTypeId,
      params
    })
      .then(({ data: response }) => {
        if (callback) {
          callback({ response });
        }
      })
      .catch((error) => {
        if (callback) {
          callback({ error });
        }
      });
  };
};

export const createRecurringBill = ({
  recurringBillTypeId,
  gatewayPaymentMethodId,
  params,
  referenceId
}) => {
  return (dispatch) => {
    dispatch(appStartsLoading());
    createNewRecurringBill({
      recurringBillTypeId,
      gatewayPaymentMethodId,
      params,
      referenceId
    })
      .then((response) => {
        dispatch(appDoneLoading());
        let alertTitle = '';
        let alertText = '';
        let redirectRoute = '/tutor-room';

        if (recurringBillTypeId === 1) {
          alertTitle = i18n.t('recurringBill.subsTutorRoom');
          alertText = i18n.t('recurringBill.createSubSuccess');
        } else if (recurringBillTypeId === 2) {
          alertTitle = i18n.t('recurringBill.purchaseTRPass');
          alertText = i18n.t('recurringBill.purchaseTRPassRecurringSuccess');
        }

        dispatch(openDialog({
          dialog: AlertDialog,
          props: {
            title: alertTitle,
            contentText: alertText,
            disableEscapeKeyDown: true,
            disableBackdropClick: true,
            buttons: [
              {
                text: i18n.t('alert.ok'),
                primary: true,
                onClick: () => {
                  history.replace(redirectRoute);
                }
              }
            ]
          }
        }));

        // console.log(response);
      })
      .catch((error) => {
        dispatch(appDoneLoading());
        let alertTitle = '';
        let alertText = '';

        if (recurringBillTypeId === 1) {
          alertTitle = i18n.t('recurringBill.subsTutorRoom');
          alertText = i18n.t('recurringBill.createSubFail');
        } else if (recurringBillTypeId === 2) {
          alertTitle = i18n.t('recurringBill.purchaseTRPass');
          alertText = i18n.t('recurringBill.purchaseTRPassRecurringFail');
        }

        dispatch(openDialog({
          dialog: AlertDialog,
          props: {
            title: alertTitle,
            contentText: alertText,
            disableEscapeKeyDown: true,
            disableBackdropClick: true,
            buttons: [
              {
                text: i18n.t('alert.ok'),
                primary: true,
                onClick: () => {
                  history.goBack();
                }
              }
            ]
          }
        }));
        // console.log(error);
      });
  };
};