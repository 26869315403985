import React from 'react';
import _ from 'lodash';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { blue, grey, red } from '@material-ui/core/colors';

import moment from 'moment';
import 'moment/locale/en-au';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-tw';

import history from './history';
import './App.css';
import './i18n';
import { CustomRouter } from './routing';

import GLOBAL from './helpers/GLOBAL';
import AppHelper from './helpers/AppHelper';

// material-ui global theme
const globalTheme = createMuiTheme({
  palette: {
    primary: {
      main: blue[500]
    },
    secondary: {
      main: red[500],
    },
    text: {
      primary: '#000',
      secondary: grey[500]
    }
  },
  typography: {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 13,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500
  }
});

// Global variable - DO NOT REMOVE
window.APP = {};

const App = () => {
  // DO NOT REMOVE - set the global locale for moment
  let locale = GLOBAL.get('locale');
  const i18nextLng = GLOBAL.get('i18nextLng', false);
 
  // if local is not determined
  if (_.isEmpty(locale)) {
    if (!_.isEmpty(i18nextLng)) {
      // make default moment format to 'en'
      locale = i18nextLng;
      let lang = AppHelper.convertLocaleToLang(locale);
      GLOBAL.set('locale', locale);
      GLOBAL.set('lang', lang);

    }
  }
  if (locale) {
    moment.locale(locale);
  }

  return (
    <ThemeProvider theme={globalTheme}>
      <CustomRouter
        history={history}
      />
    </ThemeProvider>
  );
};

export default App;
