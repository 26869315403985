import {
  createBraintreeGatewayPaymentMethod,
  getAvailableGatewayPaymentMethodTypes,
  getCreateGatewayPaymentMethodCredential,
  getGatewayPaymentMethods
} from '../api';
import { appDoneLoading, appStartsLoading } from './DialogActions';
import {
  UPDATE_GATEWAY_PAYMENT_METHODS_STATE,
  UPDATE_GATEWAY_PAYMENT_METHOD_TYPES_STATE
} from './types';

export const updateGatewayPaymentMethodsState = ({ prop, value }) => {
  return {
    type: UPDATE_GATEWAY_PAYMENT_METHODS_STATE,
    payload: { prop, value }
  };
};

export const updateGatewayPaymentMethodTypesState = ({ prop, value }) => {
  return {
    type: UPDATE_GATEWAY_PAYMENT_METHOD_TYPES_STATE,
    payload: { prop, value }
  };
};

export const getGatewayPaymentMethodList = ({ callback, currencyCode } = {}) => {
  return (dispatch) => {
    dispatch(updateGatewayPaymentMethodsState({ prop: 'isLoading', value: true }));
    getGatewayPaymentMethods({ currencyCode })
      .then((response) => {
        // console.log(response);
        dispatch(updateGatewayPaymentMethodsState({ prop: 'isLoading', value: false }));
        dispatch(updateGatewayPaymentMethodsState({ prop: 'list', value: response.data.paymentMethods }));
        if (callback) {
          callback({ response });
        }
      })
      .catch((error) => {
        // console.log(error);
        dispatch(updateGatewayPaymentMethodsState({ prop: 'isLoading', value: false }));
        if (callback) {
          callback({ error });
        }
      });
  };
};

export const getGatewayPaymentMethodTypes = ({ currencyCode }) => {
  return (dispatch) => {
    dispatch(appStartsLoading());
    getAvailableGatewayPaymentMethodTypes({ currencyCode })
      .then(({ data: response }) => {
        // console.log(response);
        dispatch(appDoneLoading());
        dispatch(updateGatewayPaymentMethodTypesState({ prop: 'list', value: response.paymentMethodTypes }));
      })
      .catch((error) => {
        dispatch(appDoneLoading());
        // console.log(error);
      });
  };
};

export const getCreatePaymentMethodCredential = ({
  currencyCode,
  type,
  callback
}) => {
  return (dispatch) => {
    dispatch(appStartsLoading());

    getCreateGatewayPaymentMethodCredential({
      currencyCode,
      type
    })
      .then(({ data: response }) => {
        dispatch(appDoneLoading());
        // console.log(response);
        if (callback) {
          callback({ response });
        }
      })
      .catch((error) => {
        dispatch(appDoneLoading());
        // console.log(error);
        if (callback) {
          callback({ error });
        }
      });
  };
};

export const createBraintreePaymentMethod = ({ nonce, callback }) => {
  return () => {
    createBraintreeGatewayPaymentMethod({ nonce })
      .then(({ data: response }) => {
        // console.log(response);
        if (callback) {
          callback({ response });
        }
      })
      .catch((error) => {
        // console.log(error);
        if (callback) {
          callback({ error });
        }
      });
  };
};