import AppHelper from '../helpers/AppHelper';
import YoubrioApiHelper from '../helpers/YoubrioApiHelper';

export const getTutorRoomStudentsTodayList = ({daysOffset, subjectId}) => {
  var queryParams = '?daysOffset=' + daysOffset;
  if (subjectId) { queryParams += '&subjectId=' + subjectId };
  if (AppHelper.isCBEAdmin()) {
    return YoubrioApiHelper.get('admin_tutor_room_students_today_list' + queryParams, true);
  }
  return YoubrioApiHelper.get('teacher-tutor-room-students-today-list' + queryParams, true);
};

export const getPracticeRoomSomedayStats = ({userId, today}) => {
  return YoubrioApiHelper.post(
    'practice-room-record-someday',
    {
      userId,
      today
    },
    true,
  );
};

export const getPracticeRoomSomedayStatsList = ({userIds, today}) => {
  return YoubrioApiHelper.post(
    'practice-room-record-someday-list',
    {
      userIds,
      today
    },
    true,
  );
};

export const updatePracticeRoomStudentProgressNote = (progress_note_id, notes) => {
  return YoubrioApiHelper.post(
    'teacher-tutor-room-students-edit-progress-notes',
    {
      progress_note_id,
      notes
    },
    true,
  );
};

export const updatePracticeRoomStudentHandoverNote = (participant_detail_id, student_user_id, subject_id, remarks) => {
  return YoubrioApiHelper.post(
    'teacher-tutor-room-students-edit-handover-notes',
    {
      participant_detail_id,
      student_user_id,
      subject_id,
      remarks
    },
    true,
  );
};

export const newPracticeRoomStudentProgressNote = (profile_id, student_user_id, notes) => {
  return YoubrioApiHelper.post(
    'teacher-tutor-room-students-new-progress-notes',
    {
      profile_id,
      student_user_id,
      notes
    },
    true,
  );
};

export const newPracticeRoomStudentHandoverNote = (participant_detail_id, userId, remarks, lock, lastLockedBy, practiceSubjectId, practiceRoomId, remarksEditedAt) => {
  return YoubrioApiHelper.post(
    'practice-participant-detail/'+participant_detail_id,
//    'teacher-tutor-room-students-participant-detail/'+participant_detail_id,
    {
      userId, 
      remarks, 
      lock, 
      lastLockedBy, 
      practiceSubjectId, 
      practiceRoomId, 
      remarksEditedAt
    },
    true,
  );
};


