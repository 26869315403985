import {
  getPracticeTutorReviews,
  // createPracticeTutorReview,
  // updatePracticeTutorReview,
  // deletePracticeTutorReview,
} from '../api';
// import history from '../history';
import {
  // appStartsLoading,
  // appDoneLoading,
  // updateCommonStates
} from './CommonActions';
import {
  UPDATE_PRACTICE_TUTOR_REVIEW_LIST_STATE,
  RESET_PRACTICE_TUTOR_REVIEW_LIST_STATE
} from '../actions/types';
// import i18n from '../i18n';
// import GLOBAL from '../helpers/GLOBAL';

export const updatePracticeTutorReviewListState = ({ prop, value }) => {
  return {
    type: UPDATE_PRACTICE_TUTOR_REVIEW_LIST_STATE,
    payload: { prop, value }
  };
};

export const resetPracticeTutorReviewListState = () => {
  return {
    type: RESET_PRACTICE_TUTOR_REVIEW_LIST_STATE
  };
};

export const requestGetPracticeTutorReviewList = (params) => {
  return (dispatch) => {
    dispatch(updatePracticeTutorReviewListState({ prop: 'practiceTutorReviewListLoading', value: true }));
    getPracticeTutorReviews(params)
      .then((res) => {
        // var { reviews, postLessonReviews, flags } = res.data;
        if (res && res.data) {
          dispatch(updatePracticeTutorReviewListState({ prop: 'reviewList', value: res.data }));
          dispatch(updatePracticeTutorReviewListState({ prop: 'practiceTutorReviewListLoading', value: false }));
        }
      })
      .catch(err => {
        console.error('Failed to get the practice tutor review list', err);
        dispatch(updatePracticeTutorReviewListState({ prop: 'practiceTutorReviewListLoading', value: false }));
      });
  };
};

export const updatePracticeTutorReviewList = (teachers, reviewList) => {
  if (!teachers) { return reviewList; }
  const teachersMap = new Map(teachers.map(x => [x.id, x]));
  reviewList = reviewList.map(x => { x.tutor_user = teachersMap.get(x.tutor_user_id); return x; });
  return reviewList;
};
