export const en = {
  // START ---- components::common/LoadingScreen
  saving: 'Saving...',
  submitting: 'Submitting...',
  uploading: 'Uploading...',
  downloading: 'Downloading...',
  //   END ---- components::common/LoadingScreen

  // START ---- components::common/PhotoPickerWithLabel
  camera: 'Camera',
  album: 'Album',
  //   END ---- components::common/PhotoPickerWithLabel

  // START ---- components::common/FilePicker
  pdf: 'PDF File',
  //   END ---- components::common/FilePicker

  // START ---- components::common/FilterModalPickerWithLabel
  noMatches: 'No matches...',
  searchHere: 'Search here...',
  //   END ---- components::common/FilterModalPickerWithLabel

  // START ---- components::LoginScreen
  email: 'E-mail',
  password: 'Password',
  login: 'Login',
  forgotYourPassword: 'Forgot your password?',
  dontHaveAnyAccount: "Don't have an account?",
  register: 'Register',
  ok: 'OK',
  error: 'Error',
  connectWithFb: 'Connect with Facebook',
  connectWithWX: 'Connect with WeChat',
  signInWithGoogle: 'Sign in with Google',
  copyToClipBoard: 'Copied to Clip Board',
  //   END ---- components::LoginScreen

  // START ---- components::RegisterRoleChoiceScreen
  registerAccount: 'Register Account',
  asTeacher: 'As Teacher',
  asStudent: 'As Student',
  //   END ---- components::RegisterRoleChoiceScreen

  // START ---- components::CandidateRegisterScreen
  state: 'State',
  selectstate: 'Select State',
  registerAsCandidate: 'Register As Candidate',
  uploadProfilePhoto: 'Upload Profile Photo',
  confirmPassword: 'Confirm Password',
  firstName: 'First Name',
  lastName: 'Last Name',
  gender: 'Select Gender',
  male: 'Male',
  female: 'Female',
  other: 'Other',
  dateOfBirth: 'Date of Birth',
  addressLine1: 'Address Line 1',
  addressLine2: 'Address Line 2',
  suburb: 'Suburb',
  postcode: 'Postcode',
  country: 'Country',
  region: 'Region',
  location: 'Location',
  allRegion: 'All Regions',
  selectCountry: 'Select Country',
  landLine: 'Landline',
  mobile: 'Mobile',
  teacherIdOptional: 'Teacher ID (Optional)',
  guardianName: 'Parent/Guardian Name',
  candidateRegistration: 'Candidate Registration',
  //   END ---- components::CandidateRegisterScreen

  // START ---- components::TeacherRegisterScreen
  registerAsTeacher: 'Register As Teacher',
  institutionType: 'Institution Type',
  selectInstitutionType: 'Select Institution Type',
  qualification: 'Qualification',
  teacherRegistration: 'Teacher Registration',
  //   END ---- components::TeacherRegisterScreen

  // START ---- components::QuickRegisterScreen
  accountRegistration: 'Account Registration',
  referralCode: 'Referral Code',
  referralCodeOptional: 'Referral Code (Optional)',
  //   END ---- components::QuickRegisterScreen

  // START ---- components::LoadingModal
  loading: 'Loading...',
  //   END ---- components::LoadingModal

  // START ---- components::HomeScreen/SideBar/CandidateSideBar
  exams: 'Exams',
  paymentHistory: 'Payment History',
  musicPieceRequests: 'Music Piece Requests',
  musicLibrary: 'Syllabus',
  requestHelp: 'Request Help',
  logout: 'Logout',
  emailSubjectMyExamsCandidate: 'MyExams-Candidate App',
  emailSubjectIssueReport: 'Issue Report',
  feeStructure: 'Examinations Fee',
  examGuidelines: 'Examinations Guidelines',
  newPerformanceEvaluation: 'New Performance Evaluation',
  performanceEvaluations: 'Performance Evaluations',
  diagnostics: 'Diagnostics',
  //   END ---- components::HomeScreen/SideBar/CandidateSideBar

  // START ---- components::HomeScreen/SideBar/TeacherSideBar
  studentList: 'Student List',
  emailSubjectMyExamsTeacher: 'MyExams-Teacher App',
  //   END ---- components::HomeScreen/SideBar/TeacherSideBar

  // START ---- components::HomeScreen/ExamPanel
  openExams: 'OPEN EXAM(S)',
  submittedExams: 'SUBMITTED EXAM',
  closedExams: 'COMPLETED EXAM',
  reworkExams: 'REWORK EXAM',
  appealedExams: 'APPEALED EXAM',
  openPE: 'OPEN PERF. EVAL.',
  closedPE: 'COMPLETED PERF. EVAL.',
  //   END ---- components::HomeScreen/ExamPanel

  // START ---- components::CandidateHomeScreen
  myExamsForCandidate: 'Con Brio Exams for Candidate',
  hello: 'Hello',
  newExam: 'New Exam',
  myProfile: 'My Profile',
  newMusicPiece: 'New Music Piece',
  confirm: 'Confirm',
  cancel: 'Cancel',
  exit: 'Exit',
  //   END ---- components::CandidateHomeScreen

  // START ---- components::CandidateProfileScreen
  candidateId: 'Candidate ID',
  teacherId: 'Teacher ID',
  updateProfile: 'Update Profile',
  confirmEmail: 'Confirm E-mail',
  unsavedChanges: 'Unsaved Changes',
  selectDateOfBirth: 'Select Date of Birth',
  //   END ---- components::CandidateProfileScreen

  // START ---- actions::UserActions
  profileUpdate: 'Profile Update',
  studentProfileUpdate: 'Student Profile Update',
  //   END ---- actions::UserActions

  // START ---- components::CandidateNewExamScreen
  instrument: 'Instrument',
  piano: 'Piano',
  violin: 'Violin',
  altoSaxophone: 'Alto Saxophone',
  flute: 'Flute',
  cello: 'Cello',
  viola: 'Viola',
  doubleBass: 'Double Bass',
  vocal: 'Vocal',
  clarinet: 'Clarinet',
  oboe: 'Oboe',
  selectInstrument: 'Select Instrument',
  grade: 'Grade',
  selectGrade: 'Select Grade',
  affiliationCode: 'Affiliation Code (if applicable)',
  paymentAmount: 'Payment Amount',
  proceedToPayment: 'Proceed to Payment',
  examReportChineseTranslationService: 'Exam Report Chinese Translation Service',
  //   END ---- components::CandidateNewExamScreen

  // START ---- components::TnCScreen
  termsAndConditions: 'Terms and Conditions',
  submitExam: 'Submit Exam',
  //   END ---- components::TnCScreen

  // START ---- components::CandidateAppealExamDetailsScreen
  remarks: 'Remarks',
  submitAppeal: 'Submit Appeal',
  cost: 'Cost',
  appealExam: 'Appeal Exam',
  appealOption: 'Appeal Option',
  selectAppealOption: 'Select Appeal Option',
  //   END ---- components::CandidateAppealExamDetailsScreen

  // START ---- components::CompletedExamDetailScreen
  appeal: 'Appeal',
  sendCertToMyEmail: 'Send Certificate to My Email',
  buyCert: 'Buy Certificate',
  completedExamDetails: 'Completed Exam Details',
  candidateName: 'Candidate Name',
  markedDate: 'Marked Date',
  sendReportToMyEmail: 'Send Report to My Email',
  //   END ---- components::CompletedExamDetailScreen

  // START ---- helpers::AppHelper
  thisMonth: 'This Month',
  lastMonth: 'Last Month',
  passWithHighDistinction: 'Pass with High Distinction',
  passWithDistinction: 'Pass with Distinction',
  passWithCredit: 'Pass With Credit',
  pass: 'Pass',
  unsuccessful: 'Unsuccessful',
  highDistinction: 'High Distinction',
  distinction: 'Distinction',
  credit: 'Credit',
  yearsOld: 'years old',
  yearsOldAndAbove: 'years old and above',
  yearsOldAndBelow: 'years old and below',
  upToMinutes: 'Up to {0} minutes',
  noAgeLimit: 'No age limit',
  countThousand: '{0}k',
  countMillion: '{0}m',
  countBillion: '{0}b',
  localTime: 'Local Time',
  //   END ---- helpers::AppHelper

  // START ---- components::EditExamScreen/EditExamTab
  pasteVideoLink: 'Paste video link',
  recordAndUploadVideoNow: 'Record and upload video now',
  tapToSelectVideo: 'Tap to select video',
  remove: 'Remove',
  piece: 'PIECE ',
  details: 'Details',
  musicType: 'Music Type',
  selectMusicType: 'Select Music Type',
  musicTitle: 'Music Title',
  selectMusicTitle: 'Select Music Title',
  searchHereOrSelectMusicTitle: 'Search here or select music title...',
  video: 'Video',
  next: 'Next',
  uploadVideo: 'Upload Video',
  uploadPhoto: 'Upload Photo',
  uploadedLink: 'Uploaded Link',
  reuploadVideo: 'Reupload Video',
  //   END ---- components::EditExamScreen/EditExamTab

  // START ---- components::EditExamScreen/CandidateEditExamScreen
  piece1: 'Piece 1',
  piece2: 'Piece 2',
  piece3: 'Piece 3',
  piece4: 'Piece 4',
  piece5: 'Piece 5',
  summary: 'Summary',
  //   END ---- components::EditExamScreen/CandidateEditExamScreen

  // START ---- components::EditExamScreen/SummaryTab
  photoIdRequirement: 'Photo ID Requirement',
  playingTimeRequirement: 'Playing Time Requirement',
  photoId: 'Photo ID',
  save: 'Save',
  proceedToSubmitExam: 'Proceed to Submit Exam',
  //   END ---- components::EditExamScreen/SummaryTab

  // START ---- components::MusicPieceListScreen/MusicPieceListItem
  by: 'by',
  gradeRequested: 'Grade Requested',
  dateSubmitted: 'Date Submitted',
  submitted: 'Submitted',
  accepted: 'Accepted',
  rejected: 'Rejected',
  noRecordFound: 'No record found',
  //   END ---- components::MusicPieceListScreen/MusicPieceListItem

  // START ---- components::MusicPieceListScreen
  reasonofRejection: 'Reason of Rejection',
  //   END ---- components::MusicPieceListScreen

  // START ---- components::NewMusicPieceScreen
  composer: 'Composer',
  nameOfThePiece: 'Name of the Piece',
  sampleVideoLink: 'Sample Video Link',
  uploadSheetMusic: 'Upload Sheet Music',
  request: 'Request',
  //   END ---- components::NewMusicPieceScreen

  // START ---- components::PaymentHistoryListScreen/PaymentHistoryListItem
  paymentDate: 'Payment Date',
  paymentType: 'Payment Type',
  //   END ---- components::PaymentHistoryListScreen/PaymentHistoryListItem

  // START ---- components::PurchaseCertificateScreen
  purchaseCertificate: 'Purchase Certificate',
  address: 'Address',
  certificate: 'Certificate',
  //   END ---- components::PurchaseCertificateScreen

  // START ---- components::ResetPasswordScreen
  resetPassword: 'Reset Password',
  sendPasswordResetLink: 'Send Password Reset Link',
  //   END ---- components::ResetPasswordScreen

  // START ---- components::VideoGalleryScreen/VideoGalleryHeader
  selectPieceVideo: 'Select Piece {0} Video',
  //   END ---- components::VideoGalleryScreen/VideoGalleryHeader

  // START ---- components::VideoGalleryScreen
  deleteVideo: 'Delete Video',
  yes: 'Yes',
  no: 'No',
  videoResolution: 'Video Resolution',
  //   END ---- components::VideoGalleryScreen

  // START ---- components::VideoGalleryScreen/VideoOptionModal
  selectVideo: 'Select Video',
  playVideo: 'Play Video',
  //   END ---- components::VideoGalleryScreen/VideoOptionModal

  // START ---- actions::ExamActions
  success: 'Success',
  examEnrolment: 'Exam Enrollment',
  paymentGatewayError: 'Payment Gateway Error',
  examReport: 'Examination Report',
  examCertificate: 'Examination Certificate',
  examAppeal: 'Examination Appeal',
  certPurchase: 'Certificate Purchase',
  videoUpload: 'Video Upload',
  //   END ---- actions::ExamActions

  // START ---- components::UploadVideoScreen/UploadVideoScreen
  videoTitle: 'Title',
  description: 'Description',
  upload: 'Upload',
  goUpload: 'Upload a New Video',
  privacyPolicy: 'Privacy Policy',
  privacyAndTerms: 'Terms and Privacy Policy',
  //   END ---- components::UploadVideoScreen/UploadVideoScreen

  // START ---- actions::SyllabusActions
  newMusicPieceRequest: 'New Music Piece Request',
  //   END ---- actions::SyllabusActions

  // START ---- components::ExamListScreen/CandidateExamListScreen
  current: 'Current',
  completed: 'Completed',
  rework: 'Rework',
  //   END ---- components::ExamListScreen/CandidateExamListScreen

  // START ---- components::ExamListScreen/CandidateAppealExamListItem
  appealDate: 'Appeal Date',
  //   END ---- components::ExamListScreen/CandidateAppealExamListItem

  // START ---- components::ExamListScreen/CandidateCurrentExamListItem
  expiryDate: 'Expiry Date',
  //   END ---- components::ExamListScreen/CandidateCurrentExamListItem

  // START ---- components::CandidateHomeScreen
  myExamsForTeacher: 'Con Brio Exams for Teacher',
  //   END ---- components::CandidateHomeScreen

  // START ---- components::CandidateProfileScreen
  pleaseStateYourInstitutionType: 'Please state your institution type',
  //   END ---- components::CandidateProfileScreen

  // START ---- components::CandidateProfileScreen
  studentProfile: 'Student Profile',
  //   END ---- components::CandidateProfileScreen

  // START ---- components::TeacherNewExamScreen
  selectCandidate: 'Select Candidate',
  //   END ---- components::TeacherNewExamScreen

  // START ---- components::StudentProfileEditScreen
  removeThisStudent: 'Remove This Student',
  removeStudent: 'Remove Student',
  //   END ---- components::StudentProfileEditScreen

  // START ---- actions::AuthActions
  passwordReset: 'Password Reset',
  //   END ---- actions::AuthActions

  // START ---- actions::CommonActions
  updateApp: 'Update Your App',
  versionCheckError: 'Version Check Error',
  //   END ---- actions::CommonActions

  // START ---- components::BrowseMusicLibraryScreen
  searchBy: 'Search By',
  throughInstrumentAndGrade: 'Instrument & Grade',
  throughMusicName: 'Music Name',
  throughComposer: 'Composer',
  musicName: 'Music Name',
  searchMusicPiece: 'Search Music Piece',
  //   END ---- components::BrowseMusicLibraryScreen

  //  START ---- components::BrowseMusicLibraryScreen
  cbeRecommendedPieces: 'Con Brio Recommended Pieces',
  searchResult: 'Search Result',
  noResult: 'No result',
  //    END ---- components::BrowseMusicLibraryScreen

  //  START ---- components::MusicLibraryDetailsScreen
  recommendedByCBE: 'Recommended by CBE',
  viewSheetMusic: 'Buy Sheet Music',
  reportBrokenVideoLink: 'Report Broken Video Link',
  item: 'Item',
  sheetMusicOf: 'Sheet Music [{0}]',
  //    END ---- components::MusicLibraryDetailsScreen

  //  START ---- components::SocialMediaEmailScreen
  submit: 'Submit',
  //    END ---- components::SocialMediaEmailScreen

  // START ---- components::AccountUpgradeChooseRoleScreen
  upgradeAccount: 'Complete Profile',
  //   END ---- components::AccountUpgradeChooseRoleScreen

  // START ---- components::SocialMediaPasswordScreen
  linkYourFbAccount: 'Link Your Facebook Account',
  //   END ---- components::SocialMediaPasswordScreen

  // START ---- components::CandidateCompletedPieceEvaluationDetailScreen
  completedPieceEvaluation: 'Completed Performance Evaluation',
  recommendedGrade: 'Recommended Grade',
  marks: 'Marks',
  sendPerformanceEvaluationReportToMyEmail: 'Send Report to My Email',
  //   END ---- components::CandidateCompletedPieceEvaluationDetailScreen

  // START ---- actions::PieceEvaluationActions
  performanceEvaluationReport: 'Performance Evaluation Report',
  requestPieceEvaluation: 'Performance Evaluation Request',
  //   END ---- actions::PieceEvaluationActions

  // START ---- components::CandidateNewPieceEvaluationScreen
  pieceLength: 'Piece Length',
  //   END ---- components::CandidateNewPieceEvaluationScreen

  // START ---- components::TeacherEditPieceEvaluationScreen
  pieceSource: 'Piece Source',
  proceedToSubmit: 'Proceed to Submit',
  syllabusFromCbe: 'Syllabus from Con Brio Examinations',
  ownDetails: 'Own Details',
  videoLink: 'Video Link',
  //   END ---- components::TeacherEditPieceEvaluationScreen

  // START ---- components::LandingScreen
  home: 'Home',
  wallet: 'Wallet',
  events: 'Events',
  trending: 'Trending',
  library: 'Library',
  assess: 'Assess',
  manage: 'Manage',
  youbrioApp: 'YOUBRIO APP',
  loginAsAdmin: 'Login as Admin',
  loginAsSupport: 'Login as Support',
  backToTeacher: 'Back to Account',
  featured: 'Featured',
  tapToEnter: 'Tap to Enter',
  enterMobileNo: 'Update Mobile Number',
  recentNews: 'Recent News',
  moreInfo: 'More Info',
  hallOfFame: 'Hall of Fame',
  beginner: 'Beginner',
  intermediate: 'Intermediate',
  advanced: 'Advanced',
  qualityControl: 'Quality Control',
  howToStart: 'How to Start',
  aboutYoubrio: 'About Youbrio',
  testimonials: 'Testimonials',
  approvedTeachers: 'Approved Teachers',
  customerService: 'Customer Service',
  safePayment: 'Safe Payment',
  withdrawalGuarentee: 'Withdrawal Guarentee',
  //   END ---- components::LandingScreen

  // START ---- actions::EventActions
  eventEnrolment: 'Event Enrollment',
  //   END ---- actions::EventActions

  // START ---- components::LandingScreen/SideBar
  eventEnrolments: 'Event Enrollments',
  youbrioUserLevelInfo: 'YOUBRIO Level Info',
  myWallet: 'My Wallet',
  scan: 'Scan',
  //   END ---- components::LandingScreen/SideBar

  // START ---- components/CandidateEventEnrolmentListScreen
  eventEnrolmentDetails: 'Event Enrollment Details',
  //   END ---- components/CandidateEventEnrolmentListScreen

  // START ---- components/CandidateEventEnrolmentListScreen/CandidateEventEnrolmentListItem
  enrolmentDate: 'Enrollment Date',
  ageGroup: 'Age Group',
  //   END ---- components/CandidateEventEnrolmentListScreen/CandidateEventEnrolmentListItem

  // START ---- components::PaymentDetailScreen
  paymentDetail: 'Payment Details',
  sendReceiptToMyEmail: 'Send Receipt to My Email',
  paymentGateway: 'Payment Gateway',
  directDeposit: 'Direct Deposit',
  //   END ---- components::PaymentDetailScreen

  // START ---- components::LandingScreen/LandingTab/EventsTab
  currentEvents: 'Current Events',
  collections: 'Collections',
  upcomingEvents: 'Upcoming Events',
  //   END ---- components::LandingScreen/LandingTab/EventsTab

  // START ---- components::NewEventEnrolmentScreen/CandidateNewEventEnrolmentScreen
  musicPeriod: 'Music Period',
  duration: 'Duration',
  fee: 'Fee per Lesson',
  newEventEnrolment: 'New Event Enrolment',
  event: 'Event',
  eventDate: 'Event Date',
  eventLocation: 'Event Location',
  category: 'Category',
  selectCategory: 'Select Category',
  addAffiliateCode: 'Add Affiliation Code',
  appliedAffiliateCode: 'Applied Affiliation Code',
  free: 'Free',
  proceed: 'Proceed',
  //   END ---- components::NewEventEnrolmentScreen/CandidateNewEventEnrolmentScreen

  // START ---- components::AddAffiliateCodeScreen/AddAffiliateCodeScreen
  tapToScanQRCode: 'Tap to Scan QR Code',
  enterAffiliateCode: 'Enter Affiliation Code',
  applyAffiliateCode: 'Apply Affiliation Code',
  or: 'or',
  //   END ---- components::AddAffiliateCodeScreen/AddAffiliateCodeScreen

  // START ---- components::EditEventEnrolmentScreen/CandidateEditEventEnrolmentScreen
  genre: 'Performing Group',
  selectGenre: 'Select Performing Group',
  performanceVideo: 'Performance Video',
  selectPerformanceVideo: 'Select Performance Video',
  //   END ---- components::EditEventEnrolmentScreen/CandidateEditEventEnrolmentScreen

  // START ---- components::SearchVideoScreen/SearchVideoScreen
  showingSearchResultFor: 'Showing search result for "{0}".',
  enterNameOrTitle: 'Enter search keywords here...',
  //   END ---- components::SearchVideoScreen/SearchVideoScreen

  // START ---- components::LandingScreen/LandingTab/TrendingListItem
  shareYoubrioVideo: 'Share Youbrio Video',
  more: 'More',
  levelN: 'LV {0}',
  share: 'Share',
  //   END ---- components::LandingScreen/LandingTab/TrendingListItem

  // START ---- components::LandingScreen/LandingTab/TrendingListItem
  shareYoubrioPhoto: 'Share Youbrio Photo',
  //   END ---- components::LandingScreen/LandingTab/TrendingListItem

  // START ---- components/LandingScreen/ProfileDetailTab
  me: 'Me',
  yourLevelIsNow: 'Your Level is Now',
  toTeach: 'I Want To Teach',
  teachProfile: 'Teach Profile',
  makeMePrivate: 'Make my account private',
  referenceRate: 'My Hourly Rate',
  askAdmin: 'Ask Admin',
  teacherRequest: 'Teacher Request',
  //   END ---- components/LandingScreen/ProfileDetailTab

  // START ---- components/WantToTeachScreen/WantToTeachScreen
  //   END ---- components/WantToTeachScreen/WantToTeachScreen

  // START ---- components/WatchVideoScreen/WatchVideoScreen
  comeWatchPerform: 'YOUBRIO invites you to watch {0} performing {1} on Youbrio.com',
  //   END ---- components/WatchVideoScreen/WatchVideoScreen

  // START ---- components/LandingScreen/LandingTab/ProfileDetailVideoTab/CertificateTab/CertificateTab
  uploadCertificate: 'Upload Certificate',
  certDescAndOrigin: 'Certificate description, origin and etc.',
  photoDescAndOrigin: 'Photo description, origin and etc.',
  enrolExam: 'Enrol Exam',
  myCerts: 'My Certificates',
  photo: 'Photo',
  //   END ---- components/LandingScreen/LandingTab/ProfileDetailVideoTab/CertificateTab/CertificateTab

  // START ---- components/AuditionListScreen/AuditionListScreen
  auditions: 'Auditions',
  //   END ---- components/AuditionListScreen/AuditionListScreen

  // START ---- components/AuditionListScreen/AuditionListScreen
  competitions: 'Competitions',
  //   END ---- components/AuditionListScreen/AuditionListScreen

  // START ---- components/AuditionListScreen/AuditionListScreen
  examinations: 'Examinations',
  //   END ---- components/AuditionListScreen/AuditionListScreen

  // START ---- components::EventDetailScreen
  eventLinks: 'Event Links',
  comingSoonEllipsized: 'Coming Soon...',
  enroll: 'Enroll',
  audition: 'Audition',
  currentEvent: 'Current Event',
  pastEvent: 'Past Event',
  upcomingEvent: 'Upcoming Event',
  //   END ---- components::EventDetailScreen

  // START ---- components::UserYoubrioProfileScreen
  userProfile: 'User Profile',
  videos: 'videos',
  likes: 'likes',
  levelFullN: 'Level {0}',
  uploadedVideos: 'Uploaded Videos',
  //   END ---- components::UserYoubrioProfileScreen

  // START ---- components::MyCertificatesListScreen/MyCertificatesListItem
  uploadDate: 'Upload Date',
  reviewed: 'Reviewed',
  //   END ---- components::MyCertificatesListScreen/MyCertificatesListItem

  // START ---- components::EditEmailScreen
  changeEmail: 'Change Email',
  currentEmail: 'Current Email',
  newEmail: 'New Email',
  //   END ---- components::EditEmailScreen

  // START ---- components::UserLevelInfoListScreen/UserLevelInfoListScreen
  userLevelInfo: 'User Level Info',
  voteCount: 'Vote Count',
  //   END ---- components::UserLevelInfoListScreen/UserLevelInfoListScreen

  // START ---- actions::PaymentActions
  requestReceipt: 'Request Receipt',
  //   END ---- actions::PaymentActions

  // START ---- components::common/VideoPickerWithLabel
  appVideo: 'App Video',
  //   END ---- components::common/VideoPickerWithLabel

  // START ---- components::TeachProfileScreen/TeachProfileScreen
  na: 'N/A',
  organization: 'Organisation',
  level: 'Level',
  experience: 'Exp',
  yr: 'yr',
  age: 'Age',
  rating: 'Rating',
  language: 'Language',
  spokenLanguage: 'Spoken Language',
  math: 'Math',
  subjects: 'Subjects',
  purpose: 'Purpose',
  recommendation: 'Reconmmended by',
  about: 'About',
  abn: 'ABN',
  achievement: 'Collections',
  collection: 'My Collections',
  othercollection: 'Collections',
  delete: 'Delete',
  manageMyLessons: 'Manage My Lesson',
  manageMyClasses: 'Manage My Classes',
  registerForGST: 'Register For GST',
  edit: 'Edit',
  add: 'Add',
  play: 'Play',
  intro: 'Intro',
  chooseFromLibrary: 'Choose from Library',
  chooseFromAchievements: 'Choose from Achievements',
  changeVideo: 'Change Video',
  //   END ---- components::TeachProfileScreen/TeachProfileScreen

  // START ---- components::EditReferenceRateScreen
  privateInSearch: 'By making the account private, you will not be visible in the teacher’s search under “Explore”, your student can still find you by searching for your name.',
  referenceRateDesc: 'To ensure that we are connecting with you the suitable student, please let us know your approximate hourly rate. This will be displayed on your profile as a fee indication.',
  //   END ---- components::EditReferenceRateScreen

  // START ---- components::EditDescriptionScreen
  aboutMe: 'About Me',
  aboutMeLong: 'Tell us a little bit about yourself, so your future students or teachers can get to know you better.',
  //   END ---- components::EditDescriptionScreen

  // START ---- components::EditDescriptionScreen
  experienceTitle: 'Experience',
  experienceDesc: 'How many years have you been teaching?',
  //   END ---- components::EditDescriptionScreen

  // START ---- components::ManageMyLessonScreen
  myStudents: 'My Students',
  myLessons: 'My Lesson Slots',
  cLesson: 'Create Lesson',
  new: 'New',
  lessonSettings: 'Lesson Settings',
  myFees: 'My Fees',
  myTimetable: 'My Timetable',
  resources: 'Resources',
  shop: 'Shop',
  help: 'Help',
  helpCenter: 'Help Center',
  categories: 'Categories',
  myPayments: 'My Payments',
  upcomingLessons: 'Upcoming Lessons',
  favourite: 'Favourite',
  unfavourite: 'Unfavourite',
  addToCalendar: 'Add to Calendar',
  editCalendar: 'Edit Calendar',
  cannotBeFound: 'Cannot be found',
  //   END ---- components::ManageMyLessonScreen

  // START ---- components::AdminManageScreen
  newTeachers: 'New Teachers',
  //   END ---- components::AdminManageScreen

  // START ---- components::ManageMyClassScreen
  myTeachers: 'My Teachers',
  myClasses: 'My Lessons',
  //   END ---- components::ManageMyClassScreen

  // START ---- components::FindTab
  connect: 'Connect',
  disconnect: 'Disconnect',
  find: 'find',
  explore: 'Explore',
  findMyTeacher: 'Find My Teacher',
  connectMyTeacher: 'Connect With My Teacher via QR',
  connectMyStudent: 'Connect With My Student via QR',
  findTeacherSchool: 'Find New Teacher via Wizard',
  any: 'Any',
  genderPreference: 'gender prefered',
  search: 'Search',
  keyword: 'Keyword',
  hourlyRate: 'Hourly Rate',
  searchCriteria: 'Search Criteria',
  noSubject: 'No Subject available to display',
  feeIndication: 'Fee Indication',
  upTo: 'Up to {0}{1}',
  andAbove: 'and above',
  lastOnlineTime: 'Last online {0} ago',
  months: 'months',
  year: 'year',
  searchForTeacher: 'Search for teachers',
  searchForTeacherAndSchool: 'Teachers/Schools',
  //   END ---- components::FindTab

  // START ---- components::BasicProfileTab
  wantToLearn: 'Want To Learn',
  wantToTeach: 'Want To Teach',
  //   END ---- components::BasicProfileTab

  // START ---- components::VideoCallScreen
  waiting: 'Waiting for',
  waitingToReconnect: 'Waiting to reconnect...',
  //   END ---- components::VideoCallScreen

  // START ---- components::TopUpFundScreen
  deposit: 'Deposit',
  studyCreditDeposit: 'Wallet Deposit',
  //   END ---- components::TopUpFundScreen

  // START ---- components::FundTransactionListScreen
  txnHistory: 'Transaction History',
  //   END ---- components::FundTransactionListScreen

  // START ---- components::FundTransactionListScreen/FundPriceListItem
  studyCredit: 'Wallet Balance',
  //   END ---- components::FundTransactionListScreen/FundPriceListItem

  // START ---- components::TopUpPaymentMethodScreen
  paymentMethod: 'Payment Method',
  topUpAmt: 'Top Up Amount',
  retry: 'Retry',
  convertedAmount: 'Converted Amount',
  processingFee: 'Processing Fee',
  alipayProcessingFee: 'Alipay Processing Fee',
  wechatProcessingFee: 'Wechat Processing Fee',
  thirdPartyProcessingFee: 'Third Party Processing Fee',
  pay: 'Pay',
  paymentWillBeMadeToAccount: 'Payment will be made to {0}',
  //   END ---- components::TopUpPaymentMethodScreen

  // START ---- components::BrioWalletSummaryScreen
  moreTxnHistory: 'More Transaction History...',
  youHaveNotMadeAnyTransactions: 'You have not made any transactions.',
  mostRecentTransactions: 'Most recent transactions',
  withdraw: 'Withdraw',
  serviceFee: 'Service Fee',
  receivableAmount: 'Receivable Amount',
  //   END ---- components::BrioWalletSummaryScreen

  // START ---- components::NotificationListScreen
  notifications: 'Notifications',
  youDoNotHaveAnyNotificationsYet: 'You do not have any notifications yet.',
  markAllAsRead: 'Mark all as read',
  deleteNotification: 'Delete Notification',
  //   END ---- components::NotificationListScreen

  // START ---- components::LessonNotificationListScreen
  lessonRequestNotifications: 'Lesson Request Notifications',
  //   END ---- components::LessonNotificationListScreen

  // START ---- components::CreateLessonScreen
  createLesson: 'Create Lesson Slot',
  startLesson: 'Start Lesson',
  scheduleLesson: 'Schedule Lesson',
  createAndInvite: 'Create and Invite',
  shareLesson: 'Share a Lesson',
  close: 'Close',
  invite: 'Invite',
  anyone: 'Anyone',
  selectStudent: 'Select a student',
  timeNow: 'Time Now',
  lessonDashboard: 'Lesson Dashboard',
  lessonType: 'Lesson Type',
  lessonDuration: 'Lesson Duration',
  nMinutes: '{0} minutes',
  lessonSlots: 'Lesson Slot',
  lessonName: 'Lesson Name',
  lessonDescription: 'Lesson Description',
  lessonFee: 'Lesson Fee',
  iWantToReviewMyStudents: 'I want to review my students.',
  tDuration: 'Duration',
  sunday: 'Sunday',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  daily: 'Daily',
  once: 'Once',
  setLessonSlot: 'Set Lesson Slot',
  lesson: 'Lesson',
  set: 'Set',
  reminder: 'Reminder',
  minimumLessonDuration: 'Minimum Lesson Duration',
  minimumDuration: 'Minimum Duration',
  repeat: 'Repeat',
  time: 'Time',
  onceOnly: 'Once only',
  notify: 'Notify',
  notifyStudents: 'Notify Students',
  noNotify: 'I don\'t want to notify anyone',
  availableForEveryone: 'Available for everyone',
  lessonFeeForTeacher: 'Lesson Fee for Teacher',
  makeThisLessonFree: 'Make this lesson free',
  //   END ---- components::CreateLessonScreen

  // START ---- components::MyCalendarScreen
  myTimeTable: 'My Timetable',
  noLesson: 'No Lesson',
  practiceLesson: 'Practice Lesson',
  pleaseTapOnADate: 'Please tap on a date.',
  groupLesson: 'Group Lesson',
  //   END ---- components::MyCalendarScreen

  // START ---- components::BrowseLessonListScreen
  someonesLessons: '{0}\'s Lessons',
  //   END ---- components::BrowseLessonListScreen

  // START ---- components::BookLessonScreen
  bookLesson: 'Book Lesson',
  selectLessonSlots: 'Select Lesson Slots',
  feeSummary: 'Fee Summary',
  platformCharge: 'Platform Charge',
  gstCharged: 'GST',
  currency: 'Currency',
  total: 'Total',
  discountedTotal: 'Discounted Total',
  lessonGoals: 'Lesson Goals',
  //   END ---- components::BookLessonScreen

  // START ---- components::LessonPaymentScreen
  studyWalletBalance: 'My Wallet Balance',
  toBePaid: 'To be paid',
  payAndBookLesson: 'Pay & Book Lesson',
  topUp: 'Top Up',
  yesSure: 'Yes, sure.',
  noThanks: 'No, thanks.',
  //   END ---- components::LessonPaymentScreen

  // START ---- components::LessonPaymentScreen
  noLessonBookingFound: 'No lesson booking found.',
  awaitingApproval: 'Awaiting Approval',
  confirmed: 'Confirmed',
  bookingRejected: 'Rejected',
  cancelledByTeacher: 'Cancelled by Teacher',
  cancelledByStudent: 'Cancelled by Student',
  cancelledByStudentNoRefund: 'Cancelled by Student (No Refund)',
  expiredStudent: 'Expired (Student No-Show)',
  expiredTeacher: 'Expired (Teacher No-Show)',
  bookingCompleted: 'Completed',
  inProgress: 'Lesson In Progress',
  bookedAt: 'Booked at',
  //   END ---- components::LessonPaymentScreen

  // START ---- component::TeacherLessonBookingDetailScreen
  bookingId: 'Booking ID',
  status: 'Status',
  bookedBy: 'Booked by',
  referralDetails: 'Referral Details',
  bookedThrough: 'Booked Through',
  commission: 'Commission',
  timeToClass: 'Time to Class',
  timeToLesson: 'Time till Lesson',
  lessonStarted: 'Lesson started already!',
  lessonIsCanceled: 'Lesson is cancelled',
  bookingDetails: 'Booking Details',
  yearsDuration: 'Year(s)',
  monthsDuration: 'Month(s)',
  weeksDuration: 'Week(s)',
  daysDuration: 'Day(s)',
  hoursDuration: 'Hour(s)',
  minsDuration: 'Min(s)',
  days: 'Days',
  hours: 'Hours',
  hour: 'Hour',
  minutes: 'Minutes',
  mins: 'Minutes',
  minShort: 'Mins',
  seconds: 'Seconds',
  approve: 'Approve',
  reject: 'Reject',
  rejectLessonBooking: 'Reject Lesson Booking',
  cancelLessonBooking: 'Cancel Lesson Booking',
  completeLessonBooking: 'Mark Lesson Completed',
  referredBy: 'Referred By',
  dualCamera: 'Dual Camera',
  //   END ---- component::TeacherLessonBookingDetailScreen

  // START ---- component::StudentLessonBookingDetailScreen
  taughtBy: 'Taught by',
  web: 'Web',
  spectatorMode: 'Spectator Mode',
  viewLesson: 'View Lesson',
  enter: 'Enter',
  enterLesson: 'Enter Lesson',
  joinLesson: 'Join Lesson',
  enterLessonRoom: 'Enter Lesson',
  launchSmartboardOnly: 'Launch Smartboard Only',
  dualDeviceMode: 'Dual Device Mode',
  whatIsDualDeviceMode: 'What is Dual Device Mode?',
  lateCancellation: 'Late Cancellation',
  prepareSmartboard: 'Prepare Smartboard',
  viewSmartboardNotes: 'View Smartboard Notes',
  youHaveNoSmartboardNotes: 'You have no smartboard notes',
  //   END ---- component::StudentLessonBookingDetailScreen

  // START ---- component::BookingSlotChangeRequestListScreen
  requestTimeChange: 'Request Time Change',
  changeRequests: 'Change Requests',
  deleteChangeRequest: 'Delete Change Request',
  approveChangeRequest: 'Approve Change Request',
  rejectChangeRequest: 'Reject Change Request',
  noChangeRequestFoundForThisBooking: 'No change requests found for this lesson.',
  sendRequest: 'Send Request',
  notes: 'Notes',
  pleaseStateYourChangeRequestReason: 'Time change reason, other available time period, etc...',
  you: 'You',
  yourTeacher: 'Your teacher',
  yourStudent: 'Your student',
  requestApproved: 'Request Approved',
  requestDeclined: 'Request Declined',
  pendingRequest: 'Pending Request',
  deleteRequest: 'Delete Request',
  requestToChangeLessonTime: '{0} made a request to change the lesson time.',
  youHaveALessonAtThisTime: 'You have another lesson at this time',
  youHaveNoLessonAtThisTime: 'You have no lesson clash at this time.',
  checkingForOverlappingLesson: 'Checking for lesson clash...',
  unableToCheckForOverlappingLesson: 'Unable to check for lesson clash.\nTap to retry.',
  requestedByAt: 'Requested by {0} at {1}.',
  yourTimeChangeRequestApproved: 'Your time change request has been approved.',
  yourTimeChangeRequestRejected: 'Your time change request has been rejected.',
  //   END ---- component::BookingSlotChangeRequestListScreen

  // START ---- component::TeacherEditLessonScreen
  settings: 'Settings',
  editLesson: 'Edit Lesson Slots',
  updateLesson: 'Update Lesson Slot',
  deleteLesson: 'Delete Lesson Slot',
  unlistLesson: 'Unlist Lesson',
  relistLesson: 'Relist Lesson',
  lessonCreatedFor: 'Lesson Created For',
  //   END ---- component::TeacherEditLessonScreen

  // START ---- component::MyLessonTeacherListScreen/MyLessonTeacherListItem
  nLessonsBooked: '{0} Lessons Booked',
  selected: 'Selected',
  selectAll: 'Select All',
  unselectAll: 'Unselect All',
  selectedAll: '(Selected All)',
  //   END ---- component::MyLessonTeacherListScreen/MyLessonTeacherListItem

  // START ---- component::ProfilePreviewScreen
  onlyForStudents: 'Please Note',
  //   END ---- component::ProfilePreviewScreen

  // START ---- component::ExaminationListScreen/ExaminationListItem
  onlineExamination: 'Online Examination',
  offlineExamination: 'Offline Examination',
  //   END ---- component::ExaminationListScreen/ExaminationListItem

  // START ---- component::ManageMyClassScreen/UpcomingLessonBookingListItem
  now: 'Now',
  tapToBook: 'Book',
  group: 'Group',
  pendingBooking: 'Pending...',
  //   END ---- component::ManageMyClassScreen/UpcomingLessonBookingListItem

  // START ---- component::TimeSlotModalPicker
  slotStart: 'Start',
  slotEnd: 'End',
  date: 'Date',
  slotRecurringDay: 'Repeat',
  hint: 'Hint',
  completeNow: 'Complete Now',
  selectTimeSlot: 'Select Time Slot',
  select: 'Select',
  thisIsRecurring: 'This is a repeating time slot.',
  addTimeSlot: 'Add Time Slot',
  startDate: 'Start Date',
  endDate: 'End Date',
  //   END ---- component::TimeSlotModalPicker

  // START ---- component::BookingTimeSlotModalPicker
  iWantToBook: 'Book',
  fee2: 'Fee',
  // START ---- component::BookingTimeSlotModalPicker

  // START ---- component::FundWithdrawScreen
  myPaymentMethods: 'Update My Payment Methods',
  choosePaymentMethod: 'Choose Payment Method',
  //   END ---- component::FundWithdrawScreen

  // START ---- component::FundWithdrawPaymentMethodScreenList
  addPaymentMethod: 'Add Payment Method',
  //   END ---- component::FundWithdrawPaymentMethodScreenList

  // START ---- component::FundWithdrawAddPaymentMethodScreen
  bank: 'Bank',
  bsb: 'BSB',
  accNo: 'Account Number',
  paypalEmail: 'Paypal Email',
  alipayEmailOrMobile: 'Alipay Email/Mobile',
  fullNameRegistered: 'Full Name',
  alipay: 'Alipay',
  wechatpay: 'Wechat Pay',
  paypal: 'Paypal',
  bankTransfer: 'Bank Transfer',
  paymentChannel: 'Payment Channel',
  selectBank: 'Select Bank',
  bankCountry: 'Bank Country',
  bankSwiftCode: 'SWIFT/BIC',
  iban: 'IBAN',
  bankName: 'Bank Name',
  bankAddress: 'Bank Address',
  //   END ---- component::FundWithdrawAddPaymentMethodScreen

  // START ---- component::WithdrawalSummaryScreen
  withdrawalSummary: 'Withdrawal Summary',
  amount: 'Amount',
  enterPassword: 'Enter Password',
  enterLoginPassword: 'Enter Login Password',
  //   END ---- component::WithdrawalSummaryScreen

  // START ---- component::FundWithdrawalListScreen
  myWithdrawals: 'Withdrawals',
  makeWithdrawal: 'Make Withdrawal',
  //   END ---- component::FundWithdrawalListScreen

  // START ---- component::WriteReviewScreen
  writeAReview: 'Write A Review',
  writeAFeedback: 'Leave a Feedback',
  editAReview: 'Edit My Review',
  rateTeacher: 'Rate the teacher',
  rateStudent: 'Rate the Student\'s Work',
  rateCallQuality: 'Rate the Quality of Call',
  leaveComment: 'Leave a comment',
  leaveCommentQuestion: 'Would you like to leave a comment Now? Or You can always come back to this lesson to write a review',
  leaveAsAnonymous: 'Leave as anonymous',
  anonymous: 'anonymous',
  //   END ---- component::WriteReviewScreen

  // START ---- component::ViewReviewScreen
  allReviews: 'All Reviews',
  viewMore: 'View More',
  viewLess: 'View Less',
  //   END ---- component::ViewReviewScreen

  // START ---- component::SimpleIntroSlider
  sTitleZero: 'If you are a student/parent',
  tTitleZero: 'If you are a teacher',
  tutorial: 'Tutorial',
  thisTutorial: 'This Tutorial',
  skip: 'Skip',
  done: 'Done',
  inLearning: 'Interested in Learning',
  inTeaching: 'Interested in Teaching',
  titleZero: '1. Setup Profile',
  sDescZero: 'Choose to setup your YOUBRIO account as a Student',
  tDescZero: 'Choose to setup your YOUBRIO account as a Teacher',
  sTitleOne: '2. Fill in your profile',
  sDescOne: 'Fill in your profile details',
  sTitleTwo: '3. Teacher Selection',
  sDescTwo: 'Use the “Find my Teacher” function to search and select your teacher suited to your needs and wants.',
  sTitleThree: '4. Lesson Selection',
  sDescThree: 'When you have found your teacher, select the lesson and available times.',
  sTitleFour: '5. Join Lesson',
  sDescFour: 'Join your classroom and call your teacher!',
  tTitleOne: '2. Fill in your profile',
  tDescOne: 'Fill in your profile details',
  tTitleTwo: '3. Create Lessons',
  tDescTwo: 'Create your lessons to display on your profile for students to choose from. Tell people about what you will teach and what’s included in your lesson.',
  tTitleThree: '4. Confirm Lessons',
  tDescThree: 'When a student has booked a lesson with you, you can decide whether to confirm the lesson booking or automatically book it into your schedule.',
  tTitleFour: '5. Join Lesson',
  tDescFour: 'When it is time, join the classroom with your student!',
  //   END ---- component::SimpleIntroSlider

  // START ---- component::SchoolIntroScreen
  schoolOrg: 'School',
  registerMySchoolOrg: 'Register My School',
  manageMySchoolOrg: 'Manage School',
  searchForSchool: 'Search For School',
  //   END ---- component::SchoolIntroScreen

  // START ---- component::CreateSchoolScreen
  uploadSchoolProfilePhoto: 'Upload School Profile Photo',
  schoolOrgName: 'School Name',
  schoolOrgIntrouction: 'School Introduction',
  yearFounded: 'Year Founded',
  commissionPercentage: 'Commission Rate',
  defaultSchoolCommissionRate: 'Default School Commission',
  organisationApplication: 'School Application',
  organisation: 'organisation',
  //   END ---- component::CreateSchoolScreen

  // START ---- component::SchoolProfileScreen
  editSchool: 'Edit School Profile',
  schoolName: 'School Name',
  profitPercentage: 'Profit Rate',
  numOfTeacher: 'Teachers',
  schoolOrgIntroduction: 'School Introduction',
  schoolOrgAddress: 'School Address',
  discountPercentage: 'Student Discount Rate',
  //   END ---- component::SchoolProfileScreen

  // START ---- component::ManagingSchoolListScreen/ManagingSchoolListItem
  teachers: 'Teachers',
  students: 'Students',
  requests: 'Requests',
  //   END ---- component::ManagingSchoolListScreen/ManagingSchoolListItem

  // START ---- component::SchoolTeacherListScreen/SchoolTeacherListItem
  removeFromSchool: 'Remove From School',
  //   END ---- component::SchoolTeacherListScreen/SchoolTeacherListItem

  // START ---- component::SchoolPendingMembershipListScreen
  pendingJoinRequest: 'Pending Join Requests',
  decline: 'Decline',
  blacklistName: 'Blacklist {0}',
  viewNameProfile: 'View {0}\'s Profile',
  teacher: 'Teacher',
  student: 'Student',
  //   END ---- component::SchoolPendingMembershipListScreen

  // START ---- component::SchoolBlacklistedMembershipListScreen
  blacklistedProfiles: 'Blacklisted Profiles',
  removeNameFromBlacklist: 'Remove {0} From Blacklist',
  //   END ---- component::SchoolBlacklistedMembershipListScreen

  // START ---- component::WizardResultScreen
  school: 'School',
  //   END ---- component::WizardResultScreen

  // START ---- component::SchoolProfilePreviewScreen
  join: 'Join',
  requestSent: 'Request Sent',
  joined: 'Joined',
  leaveSchool: 'Leave School',
  selectTeacher: 'Select Teacher',
  joinSchool: 'Join School',
  viewSchool: 'View School',
  admin: 'Admin',
  //   END ---- component::SchoolProfilePreviewScreen

  // START ---- component::EditSchoolProfileScreen
  updateSchoolProfile: 'Update School Profile',
  //   END ---- component::EditSchoolProfileScreen

  // START ---- component:SchoolAdminProfileScreen
  deleteApplication: 'Delete Application',
  findStudent: 'Find Students',
  //   END ---- component:SchoolAdminProfileScreen

  // START ---- component::DocumentFolderScreen
  sheetMusicFolder: 'Sheet Music Folder',
  //   END ---- component::DocumentFolderScreen

  // START ---- component::AnnotationDocumentScreen
  waitingForSheetMusic: 'Loading Uploaded Content',
  unableToLoadSheetMusic: 'Oops, unable to load uploaded content.',
  zoom: 'Zoom',
  annotate: 'Annotate',
  mode: 'Mode',
  uploadAnImage: 'Upload Image(s)',
  blankWhiteboard: 'Add Blank Page',
  eraseAll: 'Erase All',
  closeTab: 'Close Tab',
  tabRemoved: 'Tab Removed',
  //   END ---- component::AnnotationDocumentScreen

  // START --- component::AnnotationUploadPhotoScreen
  annotateMusicSheet: 'Annotate Music Sheet',
  chooseMusicSheetImage: 'New Music Sheet Image',
  myTeacherStudentWillUploadInstead: 'Resume Last Session\n(If Available)',
  //   END --- component::AnnotationUploadPhotoScreen

  // START --- component::CalendarSlotPickerScreenV2
  doesNotFulfillMinDuration: 'Does Not Fulfill Minimum Duration',
  totalDuration: 'Total duration {0} mins = {1} lessons',
  nMinutesLesson: '{0} Minutes Lesson',
  //   END --- component::CalendarSlotPickerScreenV2

  // START --- component::QRCodeScreen
  qrCode: 'QR Code',
  myQRCode: 'My QR Code',
  ypLabel: 'Youbrio Profile - ',
  notNow: 'Not Now',
  openSettings: 'Open Settings',
  scanToSee: 'Scan QR to connect',
  //   END --- component::QRCodeScreen

  // START --- component::QRCodeScannerScreen
  cameraPermission: 'Camera Permission',
  //   END --- component::QRCodeScannerScreen

  // START --- component::SettingScreen
  package: 'Package',
  discount: 'Discount',
  lessons: 'Lessons',
  off: 'Off',
  optin: 'Opt in for Lesson Package',
  agreePackage: 'By opting in, you agree to the following lessons packages: ',
  enablePackageNote: 'By enabling lesson packages, students are encouraged to have a regular and long-term commitment to learning with you.',
  optInReferral: 'Opt in for Referral Program',
  optInReferralNote: 'By enabling referral, you agree to share a portion of your earned lesson fee with your referrer.',
  deleteAccount: 'Delete Account',
  //   END --- component::SettingScreen

  // START ---- component::ReferralQRCodeScreen
  referralProgram: 'Referral Program',
  scanQRWithYoubrioScanner: 'Scan with YOUBRIO APP QR Scanner',
  wantToReceiveCommission: 'SHARE with YOUBRIO REWARD',
  //   END ---- component::ReferralQRCodeScreen

  // START ---- component::FAQListScreen
  faq: 'FAQ',
  //   END ---- component::FAQListScreen

  // START --- component::ResourceScreen
  smartBoard: 'Smart Board',
  mLibrary: 'Music Library',
  mExam: 'Music Examination',
  areaOfFocus: 'Area Of Focus',
  //   END --- component::ResourceScreen

  // START ---- components::LiveLessonRoomScreen/VideoCallTabScreen
  permission: 'Permission',
  microphonePermission: 'Microphone Permission',
  storagePermission: 'Storage Permission',
  backToVideoCall: 'Back to Video Call',
  //   END ---- components::LiveLessonRoomScreen/VideoCallTabScreen

  // START ---- components::LiveLessonRoomScreen/SmartboardTabScreen
  noPicture: 'No Picture',
  arrangeTabs: 'Arrange Tabs',
  dragNToMovePages: 'Drag {0} to move pages',
  refresh: 'Refresh',
  //   END ---- components::LiveLessonRoomScreen/SmartboardTabScreen

  // START ---- helpers::ApiHelper
  networkError: 'Network Error',
  //   END ---- helperps::ApiHelper

  // START ---- components::common/LessonRequestModal
  lessonRequest: 'Lesson Request',
  requestLesson: 'Request Lesson',
  requestADifferentTime: 'Request A Different Time',
  send: 'Send',
  //   END ---- components::common/LessonRequestModal

  // START ---- components::common/ChangePasswordScreen
  changePassword: 'Change Password',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  confirmNewPassword: 'Confirm New Password',
  //   END ---- components::common/ChangePasswordScreen

  // START ---- components::modals/ProfilePreviewScreen
  myFocus: 'Focus Area',
  myExperience: 'Experience',
  myEducation: 'Qualification',
  myAbout: 'About Me',
  comment: 'Comment',
  review: 'Review',
  myReview: 'My Review',
  endorse: 'Review',
  peopleSay: 'What People Say',
  rateEndorse: 'Give a rating',
  endReached: 'End Reached',
  tProfile: 'Teacher Profile',
  lProfile: 'Student Profile',
  schoolProfile: 'School Profile',
  //   END ---- components::modals/ProfilePreviewScreen

  // START ---- components::component/LandingScreen/LandingTab/HomeTab
  schoolShort: 'School',
  clearAll: 'Clear All',
  filter: 'Filters',
  genderShort: 'Gender',
  sort: 'Sort',
  relevance: 'Relevance',
  lastOnline: 'Last Online',
  highestRating: 'Highest Rating',
  highestLessonFee: 'Highest Lesson Fee',
  lowestLessonFee: 'Lowest Lesson Fee',
  ratingSort: 'Rating',
  nameSortAZ: 'Name (A-Z)',
  nameSortZA: 'Name (Z-A)',
  back: 'Back',
  translate: 'Translate',
  original: 'Original',
  all: 'All',
  newRequest: 'Request',
  reset: 'Reset',
  lRequest: 'Request',
  later: 'Later',
  addTeacher: 'Add Teacher',
  addStudent: 'Add Student',
  create: 'Create',
  start: 'Start',
  ongoingImmediateLesson: 'There is an ongoing lesson',
  referral: 'Referral',
  book: 'Book',
  bookLessonAgain: 'Book the Lesson Again',
  createLessonAgain: 'Create the Lesson Again',
  iWantToTeach: 'I want to teach',
  iWantToLearn: 'I want to learn',
  contactAdmin: 'Contact Admin',
  iWantToTeachBannerTagline: 'Tell us what you can teach and we will assign you students!',
  adminBannerStudentText1: 'Book NOW',
  adminBannerStudentText2: 'Tell us what you want to learn and we will book the right teacher for you!',
  adminBannerTeacherText1: 'ASK US HOW',
  adminBannerTeacherText2: 'On YOUBRIO, you can teach, refer teachers, or even run your own school!',
  shareProfile: 'Share Profile',
  schedule: 'Schedule',
  shareMyProfile: 'Share My Profile',
  homeSearchTeacher: 'Search',
  homeDeposit: 'Deposit',
  homeJoinLesson: 'Join Lesson',
  homeManageLesson: 'Lessons',
  homeOnlineExam: 'Exams',
  homeMusicLibrary: 'Library',
  homeTeacherReferral: 'Referral',
  homeNetworkTest: 'Diagnostics',
  //   END ---- components::component/LandingScreen/LandingTab/HomeTab

  //   START ---- acknowledgements
  youbrio: 'Youbrio',
  copyright: '© 2019 - 2020 YOUBRIO. All Rights Reserved',
  licenses: 'Licenses',
  bugReport: 'Found a bug? Please help us fix it. ',
  agreeTC: 'By using this application, you agree to our ',
  libraries: 'This app uses the following open-source libraries (licensed under Apache-2.0):',
  feedback: 'Feeback',
  contactUsViaEmail: 'Contact Us via Email',
  contactUsViaChat: 'Contact Us via Chat',
  //  END ---- acknowledgements

  //  START ---- components::component/ChatListScreen
  chat: 'Chat',
  typeMsg: 'Type a message...',
  newMsg: 'New',
  requireAction: 'To Act',
  incompleteProfile: 'Incomplete Profile',
  //  END ---- components::component/ChatListScreen

  //  START ---- components::component/ChatScreen
  createPrivateLesson: 'Create Lesson',
  lessonSlotNotFound: 'Lesson not found.',
  loadingPrivateLesson: 'Loading lesson...',
  createdAPrivateLesson: '{0} created a lesson',
  unableToGetPrivateLesson: 'Unable to retrieve lesson info.\nTap to retry.',
  deleted: 'Deleted',
  expired: 'Expired',
  booked: 'Booked',
  privateLesson: 'Lesson',
  deletePrivateLesson: 'Delete Lesson',
  yesterday: 'Yesterday',
  startVideoCall: 'Start Video Call',
  nDeclinedCall: '{0} declined the call.',
  callEnded: 'Call ended.',
  //  END ---- components::component/ChatScreen

  // START ---- components::VideoCallChatScreen
  goToChat: 'Go to Chat',
  goToSmartBoard: 'Go to Smartboard',
  //   END ---- components::VideoCallChatScreen

  // START ---- component/modal/CallScreenModal
  incomingVideoCall: 'Incoming Video Call',
  youbrioLesson: 'YOUBRIO Lesson',
  //   END ---- component/modal/CallScreenModal

  // START ---- component/modal/ReferralCodeModal
  enterReferralCode: 'Enter Referral Code',
  validCode: 'Valid code.',
  invalidCode: 'Invalid code.',
  useCode: 'Use Code',
  unableToVerifyCode: 'Unable to verify code.',
  verifying: 'Verifying',
  //   END ---- component/modal/ReferralCodeModal

  // START ---- component/ArticleScreen
  joinCBENow: 'Enrol Con Brio Exams Now',
  //   END ---- component/ArticleScreen

  // START ---- component/MySalesReferralScreen
  myReferrals: 'My Referrals',
  //   END ---- component/MySalesReferralScreen

  // START ---- component/SchoolFeeCategoryListScreen
  feeCategory: 'Fee Category',
  setDefault: 'Set Default',
  default: 'Default',
  //   END ---- component/SchoolFeeCategoryListScreen

  // START ---- component/SchoolFeeCategoryEditScreen
  editFeeCategory: 'Edit Fee Category',
  //   END ---- component/SchoolFeeCategoryEditScreen

  // START ---- component/SchoolFeeCategoryCreateScreen
  newFeeCategory: 'New Fee Category',
  teachersAllowedToEditPrice: 'Teachers are allowed to edit fee',
  teachersNotAllowedToEditPrice: 'Teachers are not allowed to edit fee',
  labelOrRemarks: 'Label/Remarks',
  setThisAsDefault: 'Set this as default',
  selectTeachers: 'Select Teachers',
  addOrRemoveTeachers: 'Add/Remove Teachers',
  selectedTeachers: 'Selected Teachers',
  createFeeCategory: 'Create Fee Category',
  otherSettings: 'Other Settings',
  removeAll: 'Remove All',
  searchNameOrEmail: 'Search Name/Email',
  andNMoreTeachers: 'and {0} more teachers',
  noTeachersAssigned: 'No teachers assigned',
  allTeachersWithoutCategory: 'All teachers that are not assigned any category',
  searchTeacher: 'Search Teacher',
  searchStudent: 'Search Student',
  copy: 'Copy',
  used: 'Used',
  friend: 'Friend',
  friends: 'Friends',
  friendUsedCode: 'Friends who used your code ',
  referAndEarn: 'Refer & Earn',
  scanQrCode: 'Scan QR Code',
  shareByLink: 'Share by Link',
  shareFlyer: 'Share Flyer',
  teachersLessonFee: 'Teacher\'s Lesson Fee',
  schoolCommission: 'School Commission',
  schoolsLessonFee: 'School\'s Lesson Fee',
  //   END ---- component/SchoolFeeCategoryCreateScreen

  // START ---- components::SplashScreenV2
  theComprehensiveOnlineTeachingTool: 'The comprehensive online teaching tool',
  //   END ---- components::SplashScreenV2

  // START ---- component/ReferralIntroScreen
  searchTheTeacherYouWishToRefer: 'Search the teacher you wish to refer',
  referralBannerText1: 'SHARE NOW',
  referralBannerText2: 'Can\'t find the teacher you want to share? Bring them on YOUBRIO by clicking share now!',
  downloadYoubrioNow: 'Download YOUBRIO Now!',
  shareYoubrio: 'Share YOUBRIO',
  //   END ---- component/ReferralIntroScreen

  // START ---- component/SearchStudentScreen
  searchForStudent: 'Search for students...',
  //   END ---- component/SearchStudentScreen

  // START ---- modal/AgreementModal
  agree: 'Agree',
  //   END ---- modal/AgreementModal

  // START ---- component/SelectedPhotoPreviewScreen
  onlyNPhotoPerUpload: 'Max {0} photos per upload',
  longTapAndDragThumbnailToArrangePicture: 'Tap and hold the thumbnails to drag and arrange the photos',
  //   END ---- component/SelectedPhotoPreviewScreen

  // START ---- component/SelectedPDFPreviewScreen
  onlyNPagesPerUpload: 'Max {0} pages per upload',
  onlyNFilesPerUpload: 'Only {0} files are allowed per upload.',
  nPages: '{0} page(s)',
  //   END ---- component/SelectedPDFPreviewScreen

  // START ---- component/NetworkTestingModal
  networkDiagnostics: 'Network Diagnostics',
  networkStart: 'start',
  networkStartComment: 'Regular testing will help to provide a consistent lesson quality',
  ipRetrieval: 'IP Retrieval',
  serverResponse: 'Server Response',
  downloadSpeedtest: 'Download Speed Test',
  uploadSpeedtest: 'Upload Speed Test',
  lessonSimulation: 'Lesson Simulation',
  networkLowSpeed: 'Result: Low Speed',
  networkModerateSpeed: 'Result: Moderate Speed',
  networkFastSpeed: 'Result: High Speed',
  requestDemoCall: 'Request a Demo Call',
  congratulations: 'Congratulations!',
  notBad: 'Not Bad!',
  stopTest: 'Stop Diagnosing',
  retrievingConfig: 'Retrieving configurations...',
  unableRetrieveNetworkTestConfig: 'Unable to retrieve diagnostic configurations.',
  //   END ---- component/NetworkTestingModal

  // START ---- component/DatingScreen/SliderEntry
  searchAgain: 'Search Again',
  //   END ---- component/DatingScreen/SliderEntry

  // START ---- component/Lottie
  newFeature: 'New Feature!',
  gift: 'Gift',
  giftTotal: 'Total',
  oops: 'Oops',
  myGift: 'My Gift',
  sendGift: 'Send Gift',
  duringLesson: 'During Lesson',
  comingSoon: 'Coming soon',
  totalReceived: 'You have received total of',
  totalReceivedGifts: 'You have received a total of {0} gift(s)',
  // END ---- component/Lottie

  // START ---- component/JitsiAudioSettingScreen/JitsiAudioSettingScreen
  advancedAudioSetting: 'Advanced Audio Settings',
  audioSetting: 'Audio Setting',
  audioSettingShort: 'Setting',
  presets: 'Presets',
  //   END ---- component/JitsiAudioSettingScreen/JitsiAudioSettingScreen

  // START ---- components::common/TimePickerV2
  invalidTime: 'Invalid Time',
  //   END ---- components::common/TimePickerV2

  // START ---- component/MyReferralCodeScreen/MyReferralCodeScreen
  myReferralCode: 'My Referral Code',
  wechatShare: 'Wechat Share',
  //   END ---- component/MyReferralCodeScreen/MyReferralCodeScreen

  // START ---- component/CreateMeetScheduleScreen
  selectStudents: 'Select Students',
  maxStudent: 'Max Student',
  openForAnyoneToBook: 'This lesson is open for anyone to book.',
  onlyForTheFollowingStudents: 'This lesson is only open for the selected students.',
  //   END ---- component/CreateMeetScheduleScreen

  // START ---- component/EditMeetScheduleScreen
  invited: 'Invited',
  cancelInvitation: 'Cancel Invitation',
  //   END ---- component/EditMeetScheduleScreen

  // START ---- component/ScheduledMeetDetailScreen
  lessonDetails: 'Lesson Details',
  lessonParticipants: 'Lesson Participants',
  cancelLesson: 'Cancel Lesson',
  attention: 'Attention',
  creatorNameLesson: '{0}\'s Lesson',
  youHaveRatedAllParticipants: 'Rated all the participants.',
  youHaveRatedNOfTheParticipants: 'Rated {0} out of {1} participants.',
  rate: 'Rate',
  reviewsAbtYou: 'Reviews for you',
  //   END ---- component/ScheduledMeetDetalScreen

  // START ---- component/ImmediateMeetScreen
  startLessonNow: 'Start Lesson Now',
  selectInvitees: 'Select Invitees',
  hey: 'Hey',
  checkYour: 'Check Your',
  startAt: 'Start At',
  advanceSettings: 'Advanced Settings',
  private: 'Private',
  public: 'Public',
  maxParticipants: 'Maximum Participants',
  anyoneCanJoin: 'Anyone Can Join',
  errorMax: 'Max participants have to be at least {0}, because you have invited {1}',
  invitee: 'Invitee',
  invitees: 'Invitees',
  maybeLater: 'Maybe Later',
  wantToReturn: 'Would you like to return to your lesson?',
  overview: 'Overview',
  viewMoreDetails: 'View More Details',
  end: 'End',
  //   END ---- component/ImmediateMeetScreen

  // START ---- component/modals/GiftV2
  sendTo: 'Send To...',
  everyone: 'Everyone',
  noUserFoundRefresh: 'No user found.\nTap to refresh.',
  //   END ---- component/modals/GiftV2

  // START ---- MeetReviewListScreen
  reviewForN: 'Review for {0}',
  feedbackForN: 'Feedback for {0}',
  youHaveNotWrittenAnyReview: 'You have not written any review yet.',
  youHaveNotWrittenAnyFeedback: 'You have not written any feedback yet.',
  feedbackforTutors: 'Feedback for Tutors',
  //   END ---- MeetReviewListScreen

  // START ---- YoubrioXSubscriptionScreen
  tutorRoom: 'Tutor Rooms',
  tutorRoomSubscription: 'YOUBRIO Tutor Room Membership Subscription',
  perMonth: 'per month',
  oneMonth: '1 Month',
  threeMonth: '3 Month',
  twelveMonth: '12 Month',
  getFreeTrial: 'Click here to Book a Free Trial of 20 Minutes!',
  tutorRoomPlan: 'Youbrio Tutor Room Plans:',
  open: 'OPEN, Join now!',
  opens: 'Open',
  openUntil: 'Open Until',
  closes: 'Closes',
  closed: 'Closed',
  participants: 'participants',
  numberOfStudent: '{0} students',
  firstOneJoin: 'Be the first to join!',
  subscribed: 'Subscribed',
  subscribe: 'Subscribe Now',
  iWantToSubscribe: 'I want to subscribe a tutor room plan',
  // END ---- YoubrioXSubscriptionScreen

  // START ---- components/PracticeModeScreen/modals/LearnModeNotificationModal
  lessonMode: 'Lesson Mode',
  practiceMode: 'Practice Mode',
  activated: 'On',
  deactivated: 'Off',
  practice: 'Practice',
  teach: 'Teach',
  //   END ---- components/PracticeModeScreen/modals/LearnModeNotificationModal

  // START --- components/PracticeModeScreen/modals/StudentProfileDetailTabV2
  objective: 'Objective',
  note: 'Note',
  progressNote: 'Progress Note',
  records: 'Record',
  profile: 'Profile',
  files: 'Files',
  stillWorking: "We're still working on it",
  noData: 'No Data',
  nothingAdded: "You haven't added anything yet",
  accountSecurity: 'Account Security',
  personalDetails: 'Personal Details',
  generalInfo: 'General Info',
  name: 'Name',
  goal: 'Goal',
  device: 'Device',
  pleaseEnterHere: 'Please enter here...',
  week: 'Week',
  month: 'Month',
  lastSevenDays: 'Last 7 Days',
  lastThirtyDays: 'Last 30 Days',
  todayYouPracticed: 'Today you have practiced',
  today: 'Today',
  noParticipants: 'You haven\'t teach anyone today yet',
  stats: 'Statistics',
  weeklyReport: 'Weekly Report',
  tutorRoomWeeklyRecord: 'Tutor Room Weekly Record',
  averageMin: 'Average Mins',
  day: 'Day',
  practiced: 'Practiced',
  missionAccomplished: 'Mission Accomplished',
  noAccomplishedMission: 'You haven\'t completed any objective yet',
  compareWithOther: 'Compare with others',
  beats: 'Beats',
  totalTimeThisWeek: 'Total Time of this Week',
  startPractice: 'Start Practice',
  //   END ---- components/PracticeModeScreen/modals/StudentProfileDetailTabV2

  // START ---- components/PracticeModeScreen/modals/MeetParticipantListModal
  tutoredBy: 'Tutored by: {0}',
  noNotes: 'No notes found.',
  //   END ---- components/PracticeModeScreen/modals/MeetParticipantListModal

  // START ---- components::PracticeRoomListScreen
  practiceRoomComingSoon: 'Coming Soon',
  //   END ---- components::PracticeRoomListScreen

  // START ---- components/PracticeModeScreen/modals/CoffeeBreakModal
  coffeeBreak: 'Coffee Break',
  goOnBreak: 'I am taking a break',
  imBack: 'I am back!'
  //   END ---- components/PracticeModeScreen/modals/CoffeeBreakModal
};
