// import { NetInfo } from 'react-native';
import { getStore } from '../Store';
import AppHelper from './AppHelper';
import GLOBAL from './GLOBAL';

class YoubrioWebHelper {
  static headersWithToken() {
    const token = getStore().getState().auth.token;
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'dataType': 'json',
      'Authorization': `Bearer ${token}`
    };
  }

  static formDataHeadersWithToken() {
    const token = getStore().getState().auth.token;
    return {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${token}`,
      'dataType': 'json'
    };
  }

  static formDataHeadersWithoutToken() {
    return {
      'Content-Type': 'multipart/form-data',
      'dataType': 'json'
    };
  }

  static getEndpointUrl(route) {
    const host = AppHelper.getYoubrioWebUrl();
    const url = `${host}${route}`;
    return url;
  }

  static getEndpointUrlWithLang(route) {
    const host = AppHelper.getYoubrioWebUrl();
    const url = `${host}${route}?lang=${AppHelper.getAppLocaleCodeForApi()}`;
    return url;
  }

  static headers() {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'dataType': 'json'
    };
  }

  static Exception(code, message) {
    this.code = code;
    this.message = message;
  }

  static get(route, needToken = true) {
    return this.xhr(route, null, 'GET', needToken);
  }

  static put(route, params, needToken = true, isFormData = false) {
    return this.xhr(route, params, 'PUT', needToken, isFormData);
  }

  static post(route, params, needToken = true, isFormData = false) {
    return this.xhr(route, params, 'POST', needToken, isFormData);
  }

  static delete(route, params, needToken = true) {
    return this.xhr(route, params, 'DELETE', needToken);
  }

  static checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }

  static checkNetwork() {
    let hasConnection = true; // false;
    return hasConnection;
  }

  static handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  handleResponse(response) {
    if (response.ok) {
      return response.json();
    }
    throw new Error(response.statusText);
  }

  static appendLangField(url) {
    const lang = AppHelper.getAppLocaleCodeForApi();
    if (url.includes('lang=')) {
      return url;
    } else if (url.includes('?')) {
      return `${url}&lang=${lang}`;
    }
    return `${url}?lang=${lang}`;
  }

  static xhr(route, params, verb, requiresToken = false, isFormData = false) {
    const url = YoubrioWebHelper.appendLangField(YoubrioWebHelper.getEndpointUrl(route));
    let options = null;

    if (isFormData) {
      options = Object.assign({
        method: verb
      }, params ? {
        body: params
      } : null);
    } else {
      options = Object.assign({
        method: verb
      }, params ? {
        body: JSON.stringify(params)
      } : null);
    }

    if (requiresToken && !isFormData) {
      options.headers = YoubrioWebHelper.headersWithToken();
    } else if (requiresToken && isFormData) {
      options.headers = YoubrioWebHelper.formDataHeadersWithToken();
    } else if (isFormData && !requiresToken) {
      options.headers = YoubrioWebHelper.formDataHeadersWithoutToken();
    } else {
      options.headers = YoubrioWebHelper.headers();
    }

    console.log(options);

    return fetch(url, options)
      .then((response) => {
        // console.log('raw response:');
        // console.log(response);
        if (response && response.ok) {
	  const newToken = response.headers['authorization'];
	  if (newToken) {
	    GLOBAL.set('token', newToken.split(' ')[1]);
	  }
          return response.json();
        }
        throw response.status;
      });
  }
}

export default YoubrioWebHelper;
