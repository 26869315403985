/* eslint-disable no-invalid-this */
/* eslint-disable new-cap */
/* eslint-disable require-jsdoc */

import _ from 'lodash';
import SocketIOClient from 'socket.io-client';
import {
  closeDialog,
  getPracticeRoomParticipantList,
  openDialog,
  setParticipantAudioConnectedState,
  updatePracticeRoomConfigState
} from '../actions';
import { LearnModeNotificationDialog } from '../components/dialogs/tutorRoom/LearnModeNotificationDialog';
import { RescueStudentDialog } from '../components/dialogs/tutorRoom/RescueStudentDialog';
import { getStore } from '../Store';

// import { openGift } from '../gift/actions';

import AppHelper from './AppHelper';
import JitsiExternalApiHelper from './JitsiExternalApiHelper';

const { GIFT_EVENT, PRACTICE_ROOM_EVENT } = require('./ConstantHelper');

class AnnotationSocketHelper {
  modalDismissTimeout = null;

  static initialize(serverUrl, roomId) {
    this.disconnect();

    // // console.log('annotationSocket', config.hosts);
    const annotServerUrl = serverUrl || 'https://webrtc.youbrio.com:3001';
    const options = {
      transports: [ 'websocket' ],
      secure: true
    };

    // console.log('annot_server_url', annotServerUrl, roomId);
    this.socket = SocketIOClient(annotServerUrl, options);
    // console.log('annotation socket', this.socket);

    this.socket.on('connect', () => {
      // console.log('socket connected');
      this.socket.emit('room', roomId);
    });

    this.socket.on('clientreq', data => {
      // console.log('clientreq', data);
    });

    this.socket.on('newEvent', data => {
      const practiceRoomConfig = getStore().getState().practiceRoom?.practiceRoomConfig;
      const {
        JitsiMeetAPI,
        jitsiParticipants,
        participants,
        learnMode,
        practiceRoomRoleId,
        practice_room_id: practiceRoomId
      } = practiceRoomConfig;

      switch (data.event_type_id) {
      case GIFT_EVENT.SEND_GIFT: {
        const gift = data.data.gift;

        // getStore().dispatch(openGift(gift));
        break;
      }
      case GIFT_EVENT.SEND_MEET_GIFT: {
        const sentGifts = data.data.gifts;

        if (sentGifts && sentGifts.length > 0) {
          const giftSentToMe = sentGifts.find(
            gift => gift.recipient_user_id === AppHelper.getUserId());

          // console.log('senttome', giftSentToMe);
          if (giftSentToMe) {
            // Update id to gid to ensure compatibility of old version.
            // getStore().dispatch(openGift({ id: giftSentToMe.gid,
            //   hid: giftSentToMe.hid }));
          }
        }
        break;
      }
      case PRACTICE_ROOM_EVENT.START_PRACTICE_MODE: {
        const userIds = _.get(data, 'data.userIds');
        const isUserTargeted = userIds.find(item => item === AppHelper.getUserId());

        if (isUserTargeted) {
          if (this.modalDismissTimeout) {
            clearTimeout(this.modalDismissTimeout);
          }

          getStore().dispatch(updatePracticeRoomConfigState({ prop: 'learnMode', value: 2 }));
          getStore().dispatch(openDialog({
            dialog: LearnModeNotificationDialog,
            props: {
              disableEscapeKeyDown: true,
              disableBackdropClick: true
            }
          }));

          if (data.user_id) { // null value may crash the app
            JitsiExternalApiHelper.setUserAudioEnabled({
              apiInstance: JitsiMeetAPI,
              jitsiParticipants: jitsiParticipants,
              userId: data.user_id,
              enabled: false
            });
          }

          // getStore().dispatch(setPrivateSingleSessionParticipantId(null));
          JitsiExternalApiHelper.disableAllUsersAudio({
            apiInstance: JitsiMeetAPI,
            jitsiParticipants: jitsiParticipants
          });
          JitsiExternalApiHelper.muteMyAudio({ apiInstance: JitsiMeetAPI });
          // console.log('User is targeted, all incoming sounds are off');
        }
        break;
      }
      case PRACTICE_ROOM_EVENT.START_TUTOR_MODE: {
        const userIds = _.get(data, 'data.userIds');
        const isUserTargeted = userIds.find(item => item === AppHelper.getUserId());

        if (isUserTargeted) {
          if (this.modalDismissTimeout) {
            clearTimeout(this.modalDismissTimeout);
          }

          getStore().dispatch(updatePracticeRoomConfigState({ prop: 'learnMode', value: 1 }));
          getStore().dispatch(openDialog({
            dialog: LearnModeNotificationDialog,
            props: {
              disableEscapeKeyDown: true,
              disableBackdropClick: true
            }
          }));

          this.modalDismissTimeout = setTimeout(() => {
            getStore().dispatch(closeDialog());
          }, 3000);

          if (data.user_id) { // null value may crash the app
            JitsiExternalApiHelper.setUserAudioEnabled({
              apiInstance: JitsiMeetAPI,
              jitsiParticipants: jitsiParticipants,
              userId: data.user_id,
              enabled: true
            });
            JitsiExternalApiHelper.pinUser({
              apiInstance: JitsiMeetAPI,
              userId: data.user_id,
              participantId: data.participant_id,
              jitsiParticipants: jitsiParticipants
            });
            // getStore().dispatch(pinParticipantByIdentityId({ identityId: data.user_id }));
          }

          JitsiExternalApiHelper.unmuteMyAudio({ apiInstance: JitsiMeetAPI });
          // console.log('User is targeted, all incoming sounds are on');
        }
        break;
      }
      case PRACTICE_ROOM_EVENT.CONNECT_AUDIO: {
        const userIds = _.get(data, 'data.userIds');
        const isUserTargeted = userIds.find(item => item === AppHelper.getUserId());

        if (isUserTargeted) {
          if (data.user_id) {
            const participant = participants.find(p => p.user_id === data.user_id);

            /**
             * Without this check, if will crash when student is being connected/disconnect
             * as "participants" state is an empty array on student's side. This dis/connected
             * flag is not needed on student's side as well, so it's fine
             */
            /* eslint-disable-next-line max-depth */
            if (participant) {
              getStore().dispatch(setParticipantAudioConnectedState({
                practiceRoomParticipantId: participant.practice_room_participant_id,
                shouldConnect: true,
                isSilently: true
              }));
            }

            JitsiExternalApiHelper.setUserAudioEnabled({
              apiInstance: JitsiMeetAPI,
              jitsiParticipants: jitsiParticipants,
              userId: data.user_id,
              enabled: true
            });
            JitsiExternalApiHelper.unmuteMyAudio({ apiInstance: JitsiMeetAPI });
          }
        }
        break;
      }
      case PRACTICE_ROOM_EVENT.DISCONNECT_AUDIO: {
        const userIds = _.get(data, 'data.userIds');
        const isUserTargeted = userIds.find(item => item === AppHelper.getUserId());

        if (isUserTargeted) {
          if (data.user_id) {
            const participant = participants.find(p => p.user_id === data.user_id);

            /**
             * Without this check, if will crash when student is being connected/disconnect
             * as "participants" state is an empty array on student's side. This dis/connected
             * flag is not needed on student's side as well, so it's fine.
             */
            /* eslint-disable-next-line max-depth */
            if (participant) {
              getStore().dispatch(setParticipantAudioConnectedState({
                practiceRoomParticipantId: participant.practice_room_participant_id,
                shouldConnect: false,
                isSilently: true
              }));
            }

            JitsiExternalApiHelper.setUserAudioEnabled({
              apiInstance: JitsiMeetAPI,
              jitsiParticipants: jitsiParticipants,
              userId: data.user_id,
              enabled: false
            });

            // only mutes STUDENTS who are on practice mode
            /* eslint-disable-next-line max-depth */
            if (practiceRoomRoleId === 1 && learnMode === 2) {
              JitsiExternalApiHelper.muteMyAudio({ apiInstance: JitsiMeetAPI });
            }
          }
        }
        break;
      }
      case PRACTICE_ROOM_EVENT.REFRESH_PARTICIPANT_LIST: {
        if (practiceRoomRoleId === 2) { // only refresh participant list if user is a teacher
          getStore().dispatch(getPracticeRoomParticipantList({ practiceRoomId }));
        }
        break;
      }
      case PRACTICE_ROOM_EVENT.RESCUE_TR_STUDENT: {
        const userIds = _.get(data, 'data.userIds');
        const isUserTargeted = userIds.find(item => item === AppHelper.getUserId());

        if (isUserTargeted) {
          getStore().dispatch(updatePracticeRoomConfigState({
            prop: 'rescueSignal',
            value: {
              student: _.get(data, 'data.student'),
              fromUser: _.get(data, 'data.fromUser')
            }
          }));

          getStore().dispatch(openDialog({
            dialog: RescueStudentDialog,
            props: {
              disableEscapeKeyDown: true,
              disableBackdropClick: true
            }
          }));
        }
        break;
      }
      default:
        break;
      }
    });

    this.socket.on('error', error => {
      // console.log('socket.error', error);
    });

    this.socket.on('connecting', data => {
      // console.log('socket.connecting', data);
    });

    this.socket.on('disconnect', data => {
      // console.log('socket.disconnect', data);
    });

    // console.log('AnnotationSocketHelper initialize');

    return this.socket;
  }

  static emit(event, data, callback) {
    // console.log('AnnotationSocketHelper.emit', event, data);
    this.socket.emit(event, data, callback);
  }

  static getSocket() {
    return this.socket;
  }

  static disconnect() {
    // console.log('AnnotationSocketHelper disconnect');
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}

export default AnnotationSocketHelper;
