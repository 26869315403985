import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/styles';

import {
  getOrder,
  payPendingOrderViaWallet,
  openDialog,
  resetOrderDetailState,
  getFundBalance
} from '../../actions';
import { FundSummary, LogoAppBar, PaymentMethodPicker } from '../common';
import AppHelper from '../../helpers/AppHelper';
import { AlertDialog } from '../dialogs';
import { useTranslation } from 'react-i18next';

const OrderScreen = (props) => {
  const {
    orderDetails,
    location,
    fundCurrencyCode,
    fundBalance,
    classes,
    history
  } = props;

  const {
    items,
    from_currency_code,
    from_amount,
    to_currency_code,
    to_amount,
    exchange_rate
  } = orderDetails;

  const { t }  = useTranslation();
  const { orderRefId } = useParams();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('BTCN');

  // if balance is enough, set youbrio wallet as default payment method
  useEffect(() => {
    if (isWalletBalanceSufficient()) {
      setSelectedPaymentMethod('YWAL');
    }
  }, [fundBalance, to_amount, fundCurrencyCode, to_currency_code]);

  useEffect(() => {
    props.getFundBalance();
    props.getOrder({
      orderRefId,
      callback: ({ error }) => {
        if (error) {
          props.openDialog({
            dialog: AlertDialog,
            props: {
              title: t('order.oops'),
              contentText: t('alert.somethingWentWrong'),
              disableEscapeKeyDown: true,
              disableBackdropClick: true,
              buttons: [
                {
                  text: t('order.returnToDash'),
                  primary: true,
                  onClick: () => {
                    history.replace('/dash');
                  }
                }
              ]
            }
          });
        }
      }
    });

    return () => {
      props.resetOrderDetailState();
    };
  }, []);

  const onPayClick = () => {
    if (selectedPaymentMethod === 'YWAL') {
      props.payPendingOrderViaWallet({
        orderRefId,
        amount: to_amount,
        currencyCode: to_currency_code,
        callback: ({ response, error }) => {
          if (response) {
            history.replace(`/order/${orderRefId}/thank-you`);
          } else if (error) {
            props.openDialog({
              dialog: AlertDialog,
              props: {
                title: t('error'),
                contentText: t('order.processOrderPaymentFail'),
                disableEscapeKeyDown: true,
                disableBackdropClick: true,
                buttons: [
                  {
                    text: t('order.returnToDash'),
                    onClick: () => {
                      history.replace('/dash')
                    },
                    primary: true
                  }
                ]
              }
            });
          }
        }
      });
    } else if (selectedPaymentMethod === 'BT' || selectedPaymentMethod === 'BTCN') {
      history.replace({
        pathname: '/bt-pay/order',
        state: {
          orderRefId,
          selectedPaymentMethod
        }
      });
    }
  };

  const onPaymentMethodSelect = (value) => {
    setSelectedPaymentMethod(value);
  };

  const isWalletBalanceSufficient = () => {
    return (fundCurrencyCode === to_currency_code && parseFloat(fundBalance) >= parseFloat(to_amount));
  };

  const isPayButtonDisabled = () => {
    if (
      selectedPaymentMethod === 'YWAL'
      && !isWalletBalanceSufficient()
    ) {
      return true;
    }

    return false;
  };

  const renderBottomBar = () => {
    return (
      <Grid
        item
        container
        justifyContent='flex-end'
        alignItems='center'
        xs={12}
        style={{ position: 'fixed', bottom: 0, background: '#fff' }}
      >
        <Paper
          square
          elevation={1}
          style={{ width: '100%', justifyContent: 'center', display: 'flex' }}
        >
          <Grid
            container
            justifyContent='flex-end'
            alignItems='center'
          >
            <Grid item className='py-2 px-2'>
              <Typography style={{ marginRight: 6 }}>{`${t('order.total').toUpperCase()}: `}</Typography>
            </Grid>
            <Grid item className='py-2 px-2'>
              <Typography variant='h5' color='primary'>{`${to_currency_code} ${AppHelper.getCurrencySignByCurrencyCode(to_currency_code)}${parseFloat(to_amount).toFixed(2)}`}</Typography>
            </Grid>
            <Grid item className='px-2'>
              <Button
                disabled={isPayButtonDisabled()}
                variant='contained'
                color='primary'
                onClick={onPayClick}
              >
                {t('order.pay')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  };
  
  const renderItems = () => {
    return (
      <List>
        {
          items?.map((i, index) => {
            return (
              <ListItem key={index}>
                <Grid container key={index} direction='column'>
                  <Grid container item>
                    <Grid item xs={8}>
                      <Typography variant='body1'>{i.title}</Typography>
                      <Typography variant='body2' className={classes.orderItemDescription}>{i.description}</Typography>
                    </Grid>
                    <Grid item container xs={4} alignItems='center' justifyContent='flex-end'>
                      <Typography variant='body1'>{`${i.currency_code} ${AppHelper.getCurrencySignByCurrencyCode(i.currency_code)}${parseFloat(i.amount).toFixed(2)}`}</Typography>
                    </Grid>
                  </Grid>
                  {
                    index < (items?.length - 1)
                      ? <Divider className='my-2' />
                      : null
                  }
                </Grid>
              </ListItem>
            );
          })
        }
      </List>
    );
  };

  const renderOrderItemsPanel = () => {
    return (
      <Grid item>
        <Paper square className='py-3 px-3'>
          <Typography variant='h6'>{t('order.paymentSummary')}</Typography>
          <Divider className='my-3' />
          {renderItems()}
        </Paper>
      </Grid>
    );
  };

  const renderPriceLine = ({
    amount,
    currencyCode,
    label,
    shouldHighlightAmount = false,
    amountVariant,
    customAmount
  }) => {
    return (
      <Grid item container>
        <Grid item xs={8}>
          <Typography>{label}</Typography>
        </Grid>
        <Grid item container xs={4} alignItems='center' justifyContent='flex-end'>
          {
            customAmount
              ? <Typography color={shouldHighlightAmount ? 'primary' : 'initial'} variant={amountVariant || 'h6'}>{customAmount}</Typography>
              : <Typography color={shouldHighlightAmount ? 'primary' : 'initial'} variant={amountVariant || 'h6'}>{`${currencyCode} ${AppHelper.getCurrencySignByCurrencyCode(currencyCode)}${parseFloat(amount).toFixed(2)}`}</Typography>
          }
        </Grid>
      </Grid>
    );
  }

  const renderTotalAmountPanel = () => {
    return (
      <Grid item>
        <Paper square className='py-3 px-3'>
          <Grid container direction='column'>
            {
              from_currency_code !== to_currency_code
                ? renderPriceLine({ label: t('order.total'), currencyCode: from_currency_code, amount: from_amount, amountVariant: 'body1' })
                : null
            }
            {/* {
              from_currency_code !== to_currency_code
                ? renderPriceLine({ label: t('order.exchangeRate'), customAmount: `1 ${from_currency_code} = ${exchange_rate} ${to_currency_code}`, amountVariant: 'body1' })
                : null
            } */}
            {renderPriceLine({ label: t('order.totalPayable'), currencyCode: to_currency_code, amount: to_amount, shouldHighlightAmount: true })}
          </Grid>
        </Paper>
      </Grid>
    );
  };

  const render = () => {
    return (
      <Grid container justifyContent='center' style={{ backgroundColor: '#fafafa', minHeight: '100vh', paddingTop: 60, paddingBottom: 60 }}>
        <CssBaseline />
        <LogoAppBar />
        <Grid
          container
          spacing={2}
          style={{ maxWidth: '80%' }}
          className='py-3 px-3'
        >
          <Grid xs={12} md={8} item container direction='column'>
            {renderOrderItemsPanel()}
            {renderTotalAmountPanel()}
          </Grid>
          <Grid xs={12} md={4} item>
            <Paper square className='py-3 px-3'>
              <Typography variant='h6'>{t('order.paymentMethod')}</Typography>
              <Divider className='my-3' />
              <FormControl fullWidth className='mb-3'>
                <PaymentMethodPicker
                  selectedPaymentMethod={selectedPaymentMethod}
                  onSelect={onPaymentMethodSelect}
                  currencyCode={to_currency_code}
                  includesYoubrioWallet={isWalletBalanceSufficient()}
                  textFieldProps={{
                    variant: 'outlined',
                    label: t('order.paymentMethod'),
                    helperText: ''
                  }}
                />
              </FormControl>
              {
                selectedPaymentMethod === 'YWAL'
                  ? <FundSummary
                    amountToPay={to_amount}
                    location={location}
                  />
                  : null
              }
            </Paper>
          </Grid>
        </Grid>
        {renderBottomBar()}
      </Grid>
    );
  };

  return render();
};

const styles = (theme) => ({
  orderItemDescription: {
    color: '#888'
  }
});

const mapStateToProps = (state) => {
  const {
    orderDetails
  } = state.order;

  const {
    currencyCode: fundCurrencyCode,
    balance: fundBalance
  } = state.fund.fundSummary

  return {
    orderDetails,
    fundCurrencyCode,
    fundBalance
  };
};

export default connect(mapStateToProps, {
  getOrder,
  payPendingOrderViaWallet,
  openDialog,
  resetOrderDetailState,
  getFundBalance
})(withStyles(styles)(OrderScreen));