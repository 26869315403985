import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';

import { CustomizedSnackbar, InitComponent } from '../components/common';
import { MainDialogContainer } from '../components/dialogs';
import { LoadingModal } from '../components/modals';
import { PrivateRoute } from './PrivateRoute';
import { UnauthenticatedRoute } from './UnauthenticatedRoute';
import { StudentOnlyRoute } from './StudentOnlyRoute';

import HomeScreen from '../components/HomeScreen/HomeScreen';
import DashScreen from '../components/DashScreen/DashScreen';
import SignInScreen from '../components/SigninScreen/SigninScreen';
import SignUpScreen from '../components/SignupScreen/SignupScreen';
import SignUpOrCompleteAsStudentScreen from '../components/SignUpOrCompleteAsStudentScreen/SignUpOrCompleteAsStudentScreen';
import ResetPasswordScreen from '../components/ResetPasswordScreen/ResetPasswordScreen';
import LoginFacebookCallbackScreen from '../components/LoginFacebookCallbackScreen/LoginFacebookCallbackScreen';
import LoginGoogleCallbackScreen from '../components/LoginGoogleCallbackScreen/LoginGoogleCallbackScreen';
import StartLessonScreen from '../components/StartLessonScreen/StartLessonScreen';
import JoinCall from '../components/JoinCall/JoinCall';
import Wallet from '../components/Wallet/Wallet';
import ChatScreen from '../components/v2_ChatScreen/ChatScreen';
import QuickDeposit from '../components/QuickDeposit/QuickDeposit';
import ScheduleScreen from '../components/ScheduleScreen/ScheduleScreen';
import LessonScreen from '../components/LessonScreen/LessonScreen';
import SettingScreen from '../components/SettingScreen/SettingScreen';
import ProfileScreen from '../components/ProfileScreen/ProfileScreen';
import TutorRoomScreen from '../components/TutorRoomScreen/TutorRoomScreen';
import TutoredStudentsScreen from '../components/TutoredStudentsScreen/TutoredStudentsScreen';
import ControlRoomScreen from '../components/ControlRoomScreen/ControlRoomScreen';
import TutorRoomSubscribeScreen from '../components/TutorRoomSubscribeScreen/TutorRoomSubscribeScreen';
// import PricingScreen from '../components/PricingScreen/PricingScreen';
import PracticeTutorReviewScreen from '../components/PracticeTutorReviewScreen/PracticeTutorReviewScreen';
import OrderScreen from '../components/OrderScreen/OrderScreen';
import WalletTopUpScreen from '../components/WalletTopUpScreen/WalletTopUpScreen';
import OrderThankYouScreen from '../components/OrderThankYouScreen/OrderThankYouScreen';
import PayOrderViaBraintreeScreen from '../components/PayOrderViaBraintreeScreen/PayOrderViaBraintreeScreen';
import PageNotFoundScreen from '../components/PageNotFoundScreen/PageNotFoundScreen';
import TutorRoomLessonScreen from '../components/TutorRoomLessonScreen/TutorRoomLessonScreen';
import MeetLessonScreen from '../components/MeetLessonScreen/MeetLessonScreen';
import RecurringBillPaymentScreen from '../components/RecurringBillPaymentScreen/RecurringBillPaymentScreen';
import AddNewGatewayPaymentMethodScreen from '../components/AddNewGatewayPaymentMethodScreen/AddNewGatewayPaymentMethodScreen';

export const CustomRouter = (props) => {
  return (
    <Router {...props}>
      <Switch>
        <Route exact path="/" component={HomeScreen} />
        <UnauthenticatedRoute exact path="/sign-in" component={SignInScreen} />
        <UnauthenticatedRoute exact path="/sign-up" component={SignUpScreen} />
        <UnauthenticatedRoute exact path="/sign-up-student" compProps={{ mode: 'signUp'}} component={SignUpOrCompleteAsStudentScreen} />
        <Route exact path="/reset-password" component={ResetPasswordScreen} />
        <Route exact path="/login/facebook/callback" component={LoginFacebookCallbackScreen} />
        <Route exact path="/login/google/callback" component={LoginGoogleCallbackScreen} />
        {/* <PrivateRoute exact path="/pricing" component={PricingScreen} /> */}
        <PrivateRoute exact path="/dash" component={DashScreen} />
        <PrivateRoute exact path="/start-lesson" component={StartLessonScreen} />
        <PrivateRoute exact path="/join-call" component={JoinCall} />
        <PrivateRoute exact path="/wallet" component={Wallet} />
        <PrivateRoute exact path="/quick-deposit" component={QuickDeposit} />
        <PrivateRoute exact path="/schedule" component={ScheduleScreen} />
        <PrivateRoute exact path="/lesson" component={LessonScreen} />
        <PrivateRoute exact path="/chat" component={ChatScreen} />
        <PrivateRoute exact path="/settings" component={SettingScreen} />
        <PrivateRoute exact path="/profile" component={ProfileScreen} />
        <PrivateRoute exact path="/tutor-room" component={TutorRoomScreen} />
        <StudentOnlyRoute exact isStandalone path="/tutor-room/subscribe" component={TutorRoomSubscribeScreen} />
        <PrivateRoute exact path="/tutored-students" component={TutoredStudentsScreen} />
        <PrivateRoute exact path="/practice-tutor-reviews" component={PracticeTutorReviewScreen} />
        <PrivateRoute exact path="/control-room" component={ControlRoomScreen} />
        <PrivateRoute exact isStandalone path="/order/:orderRefId" component={OrderScreen} />
        <StudentOnlyRoute exact isStandalone path="/wallet/topup" component={WalletTopUpScreen} />
        <PrivateRoute exact isStandalone path="/complete-student-profile" compProps={{ mode: 'complete'}} component={SignUpOrCompleteAsStudentScreen} />
        <PrivateRoute exact isStandalone path="/order/:orderRefId/thank-you" component={OrderThankYouScreen} />
        <PrivateRoute exact isStandalone path="/bt-pay/order" component={PayOrderViaBraintreeScreen} />
        <PrivateRoute exact isStandalone path='/tutor-room/lesson' component={TutorRoomLessonScreen} />
        <PrivateRoute exact isStandalone path='/meet/lesson' component={MeetLessonScreen} />
        <PrivateRoute exact isStandalone path='/recurring-bill/:referenceId/pay' component={RecurringBillPaymentScreen} />
        <PrivateRoute exact isStandalone path='/gateway-payment-method/new' component={AddNewGatewayPaymentMethodScreen} />
        <Route component={PageNotFoundScreen} />
      </Switch>

      <InitComponent />

      {/* GLOBAL MODALS */}
      <LoadingModal />
      {/* Global snackbars */}
      <CustomizedSnackbar />
      <MainDialogContainer />
    </Router>
  );
};