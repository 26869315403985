import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { getGatewayPaymentMethodList } from '../../actions';
import { connect } from 'react-redux';

const _GatewayPaymentMethodPicker = (props) => {
  const {
    gatewayPaymentMethods,
    selectedPaymentMethod,
    onSelect = () => {},
    currencyCode,
    textFieldProps
  } = props;

  const {
    list: gatewayPaymentMethodList
  } = gatewayPaymentMethods;

  const { t } = useTranslation();

  // const [paymentMethodChoices, setPaymentMethodChoices] = useState(currencyCode === 'CNY' ? ['BTCN'] : ['BT']);

  useEffect(() => {
    props.getGatewayPaymentMethodList({ currencyCode });
  }, []);

  useEffect(() => {
    if (gatewayPaymentMethodList.length > 0) {
      if (selectedPaymentMethod) {
        const isSelectedFoundInTheList = gatewayPaymentMethodList.find(i => i.id === selectedPaymentMethod);
        
        if (isSelectedFoundInTheList) {
          return;
        }
      }

      // if select is null OR selected is not found in the list, then select the first item in the list
      onSelect(gatewayPaymentMethodList[0].id);
    }
  }, [gatewayPaymentMethodList, selectedPaymentMethod]);

  const handleChange = (event) => {
    onSelect(event.target.value);
  };

  const render = () => {
    return (
      <TextField
        id="outlined-select-currency"
        select
        label={t('gatewayPaymentMethod.paymentMethod')}
        value={selectedPaymentMethod || ''}
        onChange={handleChange}
        helperText={t('gatewayPaymentMethod.pleaseSelectYourPreferredPaymentMethod')}
        {...textFieldProps}
      >
        {gatewayPaymentMethodList.map((paymentMethod) => {
          return (
            <MenuItem key={paymentMethod.id} value={paymentMethod.id}>
              <Grid container alignItems='center'>
                <Grid item container xs={8} direction='column'>
                  <Typography style={{ whiteSpace: 'normal' }}>{paymentMethod.title}</Typography>
                  <Typography variant='body2' style={{ whiteSpace: 'normal', color: '#888' }}>{paymentMethod.description}</Typography>
                </Grid>
                <Grid item container xs={4} justifyContent='flex-end'>
                  {
                    paymentMethod.icon_url
                      ? <img alt="logo" style={{ width: '100%', height: '100%', maxWidth: '40px', maxHeight: '40px' }} src={paymentMethod.icon_url} />
                      : null
                  }
                </Grid>
              </Grid>
              
            </MenuItem>
          );
        })}
      </TextField>
    );
  };

  return render();
};

const mapStateToProps = (state) => {
  const {
    gatewayPaymentMethods
  } = state.gatewayPayment;

  return {
    gatewayPaymentMethods
  };
};

const GatewayPaymentMethodPicker = connect(mapStateToProps, {
  getGatewayPaymentMethodList
})(_GatewayPaymentMethodPicker);

export { GatewayPaymentMethodPicker };