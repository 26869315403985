import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';

const _LoadingModal = (props) => {
  return (
    <Dialog
      aria-labelledby="loading-dialog"
      open={props.loading}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          height: '80px',
          width: '80px',
        },
      }}
    >
      <CircularProgress />
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const {
    loading
  } = state.dialog;

  return {
    loading
  };
};

export const LoadingModal = connect(mapStateToProps)(_LoadingModal);
