// import AppHelper from '../helpers/AppHelper';
import YoubrioApiHelper from '../helpers/YoubrioApiHelper';

export const getPracticeTutorReviews = ({ practiceRoomId, practiceRoomProfileId, byUserId, day }) => {
  return YoubrioApiHelper.get(`practice-tutor-reviews?practiceRoomId=${practiceRoomId}&practiceRoomProfileId=${practiceRoomProfileId}&byUserId=${byUserId}&day=${day}`, true);
};

export const createPracticeTutorReview = ({ practiceRoomId, practiceRoomProfileId, tutorUserId, reviewText, rating }) => {
  return YoubrioApiHelper.post(
    `practice-tutor-reviews`,
    {
      practiceRoomId,
      practiceRoomProfileId,
      tutorUserId,
      reviewText,
      rating
    },
    true
  );
};

export const updatePracticeTutorReview = ({ id, practiceRoomId, practiceRoomProfileId, tutorUserId, reviewText, rating }) => {
  var params = {};
  if (practiceRoomId) { params['practiceRoomId'] = practiceRoomId; }
  if (practiceRoomProfileId) { params['practiceRoomProfileId'] = practiceRoomProfileId; }
  if (tutorUserId) { params['tutorUserId'] = tutorUserId; }
  if (reviewText) { params['reviewText'] = reviewText; }
  if (rating) { params['rating'] = rating; }
  return YoubrioApiHelper.post(
    `practice-tutor-reviews/${id}`,
    params,
    true
  );
};

export const deletePracticeTutorReview = (id) => {
  return YoubrioApiHelper.post(
    `practice-tutor-reviews/${id}/delete`,
    {},
    true
  );
};
















