import React, { useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import {
  Grid,
  Paper,
  Container,
  Typography,
  Button,
  // colors,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  IconButton,
  TextField,
} from '@material-ui/core';
import {
  Edit as EditIcon,
  Done as DoneIcon,
  AddCircle as AddCircleIcon,
} from '@material-ui/icons';
import {
  Rating,
} from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import {
  updateCommonStates,
  requestGetPracticeTutorReviewList,
  updatePracticeTutorReviewList,
} from '../../actions';
import {
  getPracticeRoomProfile,
  getPracticeTutorReviews,
  createPracticeTutorReview,
  updatePracticeTutorReview,
  deletePracticeTutorReview,
} from '../../api';
// import AppHelper from '../../helpers/AppHelper';
// import GLOBAL from '../../helpers/GLOBAL';
import _ from 'lodash';
// import { Theme } from '@fullcalendar/react';

const useStyles = makeStyles((theme) => ({
  paper: { width: '100%', marginTop: 40, marginBottom: 40, padding: 40 },
  listItem: {
    width: '100%',
    border: '1px solid lightgrey',
    borderRadius: 6,
    marginTop: 32,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 24,
    paddingBottom: 24,
  },
  roomIcon: { height: 46, width: 46, marginRight: 24 },
  enterBtn: {
    marginRight: 12,
  },
  avatarContainer: {
    display: 'flex',
    '& > *': {
      marginRight: 12,
      marginTop: 6,
      marginBottom: 6,
    },
  },
  refreshBtn: {
    marginLeft: 12
  },
  textField: {
    width: '80%'
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  teacherList: {
    width: '100%',
    maxWidth: 240,
  },
  column: {
    flexDirection: 'column',
  }
}));

const PracticeTutorReviewScreen = (props) => {
  const classes = useStyles();
  const { practiceRoomId, userId, day } = _.get(props, 'location.state', {});

  const { t } = useTranslation();
  const [reviewText, setReviewText] = React.useState('');
  const [rating, setRating] = React.useState(0);
  const [revieweeUserId, setRevieweeUserId] = React.useState(-1);
  const [practiceRoomProfileId, setPracticeRoomProfileId] = React.useState(null);

  const requestGetPracticeTutorReviewListHelper = async () => {
    var profileId = practiceRoomProfileId;
    if (!practiceRoomProfileId) {
      const response = await getPracticeRoomProfile({ userId });
      if (!response || !response.data || !response.data.practiceRoomProfile) {
        return;
      }
      profileId = response.data.practiceRoomProfile.id;
      setPracticeRoomProfileId(profileId);
    }
    const reviewListParams = {
      practiceRoomId,
      practiceRoomProfileId: profileId,
      byUserId: userId, // AppHelper.getUserId(),
      day: day // AppHelper.formatShortDateV2(new Date())
    };
    await props.requestGetPracticeTutorReviewList(reviewListParams);
  };

  const requestCreateReview = async (params) => {
    await createPracticeTutorReview(params);
  };

  const requestUpdateReview = async (params) => {
    await updatePracticeTutorReview(params);
  };

  const onChangeRating = async (newValue, review) => {
    if (!newValue) { return; }
    setRating(newValue);
  };

  const onChangeEdit = event => {
    setReviewText(event.target.value);
  };

  const onClickEditButton = async (review, index) => {
    if (revieweeUserId !== review.reviewee_user_id) {
      setReviewText(review.review_text ? review.review_text : '');
      setRating(review.rating ? review.rating : 0);
      setRevieweeUserId(review.reviewee_user_id);
    } else {
      await requestUpdateReview({
        id: review.practice_tutor_review_id,
        reviewText: reviewText,
        rating: rating
      });
      setReviewText('');
      setRating(0);
      setRevieweeUserId(-1);
      await requestGetPracticeTutorReviewListHelper();
    }
  };

  const onClickAdd = async (review, index) => {
    await requestCreateReview({
      practiceRoomProfileId: practiceRoomProfileId,
      practiceRoomId,
      tutorUserId: review.reviewee_user_id,
      reviewText: '',
      rating: 0,
    });
    await requestGetPracticeTutorReviewListHelper();
    setRevieweeUserId(review.reviewee_user_id);
  };

  useEffect(() => {
    requestGetPracticeTutorReviewListHelper();
  }, []);

  const renderSingleReview = (review, index) => {
    return (
      <>
        <ListItem alignItems="flex-start" id={review.reviewee_user_id} key={review.reviewee_user_id}>
          <ListItemAvatar>
            <Avatar
              alt={review.reviewee_first_name + ' ' + review.reviewee_last_name}
              src={review.reviewee_avatar_url}
              className={classes.purple}
            />
          </ListItemAvatar>
          <ListItemText
            primary={review.reviewee_first_name + ' ' + review.reviewee_last_name}
            secondary={
              <React.Fragment>
                {
                  revieweeUserId === review.reviewee_user_id
                    ?
                    <>
                    </>
                    :
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {review.created_at ? review.created_at : ''}
                      </Typography>
                      {review.review_text ? ' - ' + review.review_text : ''}
                    </>
                }
              </React.Fragment>
            }
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={() => onClickEditButton(review, index)}>
              {
                revieweeUserId === review.reviewee_user_id
                  ?
                  <DoneIcon fontSize="large" color="primary" />
                  :
                  <EditIcon color="primary" />
              }
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Grid container direction="column" justify="center" alignItems="center">
          {
            revieweeUserId === review.reviewee_user_id
              ?
              <TextField
                id={'text-' + review.id}
                className={classes.textField}
                label={t('practiceTutorReview.feedbacksFor') + ' ' + review.reviewee_first_name + ' ' + review.reviewee_last_name}
                variant="outlined"
                value={reviewText}
                size="small"
                onChange={(event) => onChangeEdit(event)}
                multiline={true}
                rowsMax="5"
              />
              :
              <></>
          }
          <Rating
            id={review.id}
            name="Rating"
            key={'rating-' + review.reviewee_user_id}
            value={revieweeUserId === review.reviewee_user_id ? rating : (review.rating ? review.rating : 0)}
            disabled={revieweeUserId === review.reviewee_user_id ? false : true}
            onChange={(event, newValue) => {
              onChangeRating(newValue, review);
            }}
          />
          <Divider key={'divider-' + review.id} variant="inset" component="li" />
        </Grid>
      </>
    );
  };

  const renderSingleTeacher = (review, index) => {
    return (
      <>
        <ListItem alignItems="flex-start" id={review.reviewee_user_id} key={review.reviewee_user_id}>
          <ListItemAvatar>
            <Avatar
              alt={review.reviewee_first_name + ' ' + review.reviewee_last_name}
              src={review.reviewee_avatar_url}
              className={classes.orange}
            />
          </ListItemAvatar>
          <ListItemText
            primary={review.reviewee_first_name + ' ' + review.reviewee_last_name}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="add" onClick={() => onClickAdd(review, index)}>
              {
                <AddCircleIcon fontSize="large" color="primary" />
              }
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </>
    );
  };

  const renderReviewList = () => {
    if (props.practiceTutorReviewListLoading) {
      return <CircularProgress />;
    }

    return (
      <List className={classes.root}>
        {
          props.reviewList
          ?
          props.reviewList.map((review, index) => (
            <>
              {
                review.practice_tutor_review_id
                  ?
                  renderSingleReview(review, index)
                  :
                  renderSingleTeacher(review, index)
              }
            </>
          ))
          :
          <></>
        }
      </List>
    );
  };

  return (
    <Container maxWidth="md">
      <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
        <Paper className={classes.paper}>
          <Typography variant="h6">
            {t('practiceTutorReview.practiceTutorReviews')}
            <Button
              disabled={props.practiceTutorReviewListLoading}
              className={classes.refreshBtn}
              color="primary"
              onClick={requestGetPracticeTutorReviewListHelper}
            >
              {t('refresh')}
            </Button>
          </Typography>
          {renderReviewList()}
        </Paper>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const {
    reviewList,
    practiceTutorReviewListLoading
  } = state.practiceTutorReview;

  return {
    reviewList: reviewList.postLessonReviews,
    practiceTutorReviewListLoading
  };
};

export default connect(mapStateToProps, {
  updateCommonStates,
  requestGetPracticeTutorReviewList,
  updatePracticeTutorReviewList,
  getPracticeTutorReviews,
  createPracticeTutorReview,
  updatePracticeTutorReview,
  deletePracticeTutorReview,
})(PracticeTutorReviewScreen);
