import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography, 
  Button,
  Avatar,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  YesNoPopover
} from '../common';

import _ from 'lodash';
import {
  updateLessonState,
  enterLesson,
  updateMeetBookingsStatus,
  refreshLastOpenedSlot,
} from '../../actions';
import GLOBAL from '../../helpers/GLOBAL';
import AppHelper from '../../helpers/AppHelper';
import { lightBlue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  name: { alignSelf: 'center' },
  statusChip: { marginLeft: 12 },
  avatar: { height: '120px', width: '120px', alignSelf: 'center' },
  avatarList: {
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 160,
  },
  hostItem: {
    backgroundColor: lightBlue[50],
  },
  notBooked: {
    marginTop: 12
  },
  label: {
    marginRight: 6
  }
}));

const _LessonModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = GLOBAL.get('user');
  const location = useLocation();
  const history = useHistory();
  const [now, setNow] = useState();

  const {
    opened,
    lessonLoading: loading,
    slotDetails: details,
    error,
  } = props;

  // GLOBAL.logger.info('Lesson slot details', details);
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(moment());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleClose = () => {
    props.updateLessonState({ prop: 'opened', value: false });
  };

  const onJoin = () => {
    GLOBAL.logger.info('on join');
    GLOBAL.logger.info('slotDetails', details);
    handleClose();
    
    if (details.jitsiBuild === '6726') {
      history.push({
        pathname: '/meet/lesson',
        state: {
          meetId: details.meet_id,
          bookingSlotId: details.id
        }
      });
    } else {
      // one of the props are undefined
      props.enterLesson({
        meetId: details.meet_id,
        bookingSlotId: details.id
      });
    }
  };

  const onHostConfirmParticipant = ({ meet_booking_id: meetBookingId }) => {
    props.updateMeetBookingsStatus({
      meetBookingIds: [meetBookingId],
      isCreator: details.is_creator,
      status: 'CF'
    });
  };

  const onHostRejectParticipant = ({ meet_booking_id: meetBookingId }) => {
    props.updateMeetBookingsStatus({
      meetBookingIds: [meetBookingId],
      isCreator: details.is_creator,
      status: 'RJ'
    });
  };

  const onHostCancelParticipant = ({ meet_booking_id: meetBookingId }) => {
    props.updateMeetBookingsStatus({
      meetBookingIds: [meetBookingId],
      isCreator: details.is_creator,
      status: 'CT'
    });
  };

  const renderContent = () => {
    if (_.isEmpty(details) || loading) {
      return (
        <div className="px-3 py-3">
          <Skeleton animation="wave" className="mt-2" variant="circle" height={120} width={120} />
          <Skeleton animation="wave" className="mt-2" variant="rect" height={30} />
          <Skeleton animation="wave" className="mt-2" variant="rect" height={30} />
          <Skeleton animation="wave" className="mt-2" variant="rect" height={150} />
        </div>
      );
    }
    
    let {
      teacherAvatarUrl: avatarUrl,
      teacherFirstName: firstName,
      teacherLastName: lastName,
      start_date_time,
      end_date_time,
      currencyCode,
    } = details;
  
    if (AppHelper.isCBETeacher()) {
      avatarUrl = details.studentAvatarUrl;
      firstName = details.studentFirstName;
      lastName = details.studentLastName;
    }

    // Legacy lesson session key names.
    let bookingStatus = details.booking_slot_status_id;
    let startMinuteAllowance = details.startMinuteAllowance;
    let price = details.price;
    // Meet lesson session key names.
    if (details.meet_id) {
      bookingStatus = details.meet_status_id;
      startMinuteAllowance = details.start_minute_allowance;
      price = _.get(details, 'meetSchedule.price_per_duration', 0);
      currencyCode = _.get(details, 'meetSchedule.currency_code', '');
    }

    const date = moment(new Date(start_date_time));
    const startTime = moment(new Date(start_date_time));
    const endTime = moment(new Date(end_date_time));
    const interval = endTime.diff(startTime, 'minute');
    const earliestJoinTime = startTime.clone().subtract(startMinuteAllowance, 'minutes');
    const latestJoinTime = endTime.clone().add(startMinuteAllowance, 'minutes');
    const renderAvatar = () => {
      if (details.meet_id && details.participants.length > 1) {
        const participants = [...details.participants];
        const i = participants.findIndex((item) => item.user_id === details.creator_user_id);
        participants.splice(i, 1);

        const renderListItemActions = (item) => {
          if (item.meet_booking_status_id === 'CF') {
            return (
              <YesNoPopover
                buttonLabel={t('alert.cancel')}
                onConfirm={() => onHostCancelParticipant(item)}
              />
            );
          } else if (item.meet_booking_status_id === 'RQ') {
            return (
              <>
                <Button
                  color="primary"
                  onClick={() => onHostConfirmParticipant(item)}
                >
                  {t('alert.confirm')}
                </Button>
                <YesNoPopover
                  buttonLabel={t('alert.cancel')}
                  onConfirm={() => onHostRejectParticipant(item)}
                />
              </>
            );
          } else {
            return (
              <Button
                disabled
              >
                {t('lesson.' + item.meet_booking_status_id)}
              </Button>
            );
          }
        };

        return (
          <React.Fragment>
            <ListItem className={classes.hostItem}>
              <ListItemAvatar>
                <Avatar alt={AppHelper.getFullname(details.creator_first_name, details.creator_last_name)} src={details.creator_avatar_url}></Avatar>
              </ListItemAvatar>
              <ListItemText primary={AppHelper.getFullname(details.creator_first_name, details.creator_last_name)} secondary={t('lesson.host') + (details.creator_user_id === user.id ? ` (${t('me')})` : '')} />
            </ListItem>
            <List className={classes.avatarList}>
              {
                participants.map((item, index) => (
                  <ListItem key={index.toString()}>
                    <ListItemAvatar>
                      <Avatar alt={AppHelper.getFullname(item.first_name, item.last_name)} src={item.avatar_url}></Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={AppHelper.getFullname(item.first_name, item.last_name)} secondary={t('lesson.participant') + (item.user_id === user.id ? `(${t('me')})` : '')} />
                    {
                      // Only allow actions if the user is the creator or host of the lesson.
                      details.creator_user_id === user.id ?
                        <ListItemSecondaryAction>
                          { renderListItemActions(item) }
                        </ListItemSecondaryAction>
                        : null
                    }
                    
                  </ListItem>
                ))
              }
            </List>
          </React.Fragment>
        );
      } else if (details.meet_id && details.participants.length === 1) {
        return (
          <React.Fragment>
            <ListItem className={classes.hostItem}>
              <ListItemAvatar>
                <Avatar alt={AppHelper.getFullname(details.creator_first_name, details.creator_last_name)} src={details.creator_avatar_url}></Avatar>
              </ListItemAvatar>
              <ListItemText primary={AppHelper.getFullname(details.creator_first_name, details.creator_last_name)} secondary={t('lesson.host') + (details.creator_user_id === user.id ? `(${t('me')})` : '')} />
            </ListItem>
            <Typography noWrap variant="h6" className={classes.notBooked}>{t('lesson.notBooked')}</Typography>
          </React.Fragment>
        );
      }
      
      return (
        <Grid container direction="column">
          <Avatar className={classes.avatar} src={avatarUrl}/>
          <Typography noWrap variant="h5" className={classes.name}>{AppHelper.getFullname(firstName, lastName)}</Typography>
        </Grid>
      );
    };

    return (
      <>
        <DialogTitle id="alert-dialog-title">
          {t('lesson.lessonDetails')}
          <Chip clickable onClick={props.refreshLastOpenedSlot} icon={<RefreshIcon />} label={t('lesson.' + bookingStatus)} className={classes.statusChip} color="default" variant="outlined" />
        </DialogTitle>

        <DialogContent>
          <Grid container direction="column" justify="space-between" spacing={1}>
            <Grid container direction="row">
              <Typography noWrap variant="body1" color="primary" className={classes.label}>{t('lesson.date')}:</Typography>
              <Typography noWrap variant="body1">{date.format('ddd D MMM YYYY')}</Typography>
            </Grid>
            
            <Grid container direction="row">
              <Typography noWrap variant="body1" color="primary" className={classes.label}>{t('lesson.time')}:</Typography>
              <Typography noWrap variant="body1">{startTime.format('hh:mm A')} - {endTime.format('hh:mm A')}</Typography>
            </Grid>

            <Grid container direction="row">
              <Typography noWrap variant="body1" color="primary" className={classes.label}>{t('lesson.duration')}:</Typography>
              <Typography noWrap variant="body1">{interval} {t('minutes')}</Typography>
            </Grid>

            {
              _.get(details, 'creator_user_id', -1) === user.id ?
                <Grid container direction="row">
                  <Typography noWrap variant="body1" color="primary" className={classes.label}>{t('lesson.price')}:</Typography>
                  <Typography noWrap variant="body1">{currencyCode} {price}</Typography>
                </Grid>
                : null
            }

            <Grid container direction="row" className="mb-3">
              <Typography variant="caption">{ t('lesson.lessonNote') }</Typography>
            </Grid>

            <Typography variant="subtitle1">{t('lesson.participants')}</Typography>
            { renderAvatar() }  
          </Grid>
        </DialogContent>

        <DialogActions>
          {
            // Check status and hide it when the user already in the call
            (bookingStatus === 'IP' || bookingStatus === 'CF') && location.pathname !== '/lesson' ?
              <Button
                disabled={!now?.isBetween(earliestJoinTime, latestJoinTime) || error}
                color="primary"
                variant="contained"
                disableElevation
                onClick={onJoin}
              >
                {t('dash.joinCall')}
              </Button>
              :null
          }
          <Button onClick={handleClose} color="secondary">
            { t('close') }
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <Dialog
      aria-labelledby="lesson-dialog"
      aria-describedby="lesson-dialog-description"
      open={opened}
      onClose={handleClose}
      PaperProps={{
        style: {
          height: '500px',
          width: '500px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'space-between',
          justifyContent: 'center',
        }
      }}
    >
      { renderContent() }
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const {
    opened,
    lessonLoading,
    slotDetails,
    error,
  } = state.lesson;

  return {
    opened,
    lessonLoading,
    slotDetails,
    error,
  };
};

export const LessonModal = connect(mapStateToProps, {
  updateLessonState,
  enterLesson,
  updateMeetBookingsStatus,
  refreshLastOpenedSlot,
})(_LessonModal);
