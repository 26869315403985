import ApiHelper from '../helpers/ApiHelper';
import YoubrioApiHelper from '../helpers/YoubrioApiHelper';

export const loginByEmailAndPassword = (email, password) => {
  const params = {
    email,
    password
  };
  return YoubrioApiHelper.post('web/token', params);
};

/**
 * For getting initialization data
 */
export const initData = () => {
  return ApiHelper.post('v2/init/auth', null, true);
};

export const getTokenByFB = (fbToken) => {
  const params = {
    _t: fbToken,
    ospid: null,
    getuiId: null,
    deviceInfo: null,
  };
  return YoubrioApiHelper.post('facebook/token', params, false);
};

export const getTokenByGoogle = (googleToken) => {
  const params = {
    _t: googleToken,
    ospid: null,
    getuiId: null,
    deviceInfo: null,
  };
  return YoubrioApiHelper.post('google/token', params, false);
};

export const getTokenByApple = (data) => {
  const params = {
    _d: data,
    ospid: null,
    getuiId: null,
    deviceInfo: null,
  };
  
  return YoubrioApiHelper.post('apple/token', params, false);
};