import YoubrioApiHelper from '../helpers/YoubrioApiHelper';

export const getReferralLink = ({ teachProfileId, isExploredReferral }) => {
  return YoubrioApiHelper.get(`teach-profile/${teachProfileId}/referral?exr=${isExploredReferral}`, true);
};

export const getMyReferralCode = () => {
  return YoubrioApiHelper.get('referral-code', true);
};

export const verifyReferralCode = ({ code }) => {
  const params = {
    code
  };
  return YoubrioApiHelper.post('referral-code/check', params, true);
};
