import YoubrioApiHelper from '../helpers/YoubrioApiHelper';
import AppHelper from '../helpers/AppHelper';

export const getUserList = () => {
  if (AppHelper.isCBETeacher()) {
    return YoubrioApiHelper.get('teach-profile/students', true);
  }
  return YoubrioApiHelper.get('learn-profile/teachers', true);
};

/**
 * Get Calendar data for student/teacher.
 */
export const getSlotsForCalendar = () => {
  const isTeacher = AppHelper.isCBETeacher();
  if (isTeacher) {
    return YoubrioApiHelper.get('host/bookings-meet-schedules', true);
  }
  return YoubrioApiHelper.get('participant/bookings-meets/calendar', true);
};

export const getSlotDetails = ({ meetId, bookingSlotId }) => {
  const isTeacher = AppHelper.isCBETeacher();
  if (meetId) {
    // New Meet Structure type of Lesson
    return YoubrioApiHelper.get(`scheduled/meet?mid=${meetId}`, true);
  } else if (bookingSlotId) {
    // Legacy Lesson Session type of Lesson
    if (isTeacher) {
      return YoubrioApiHelper.get(`teach-profile/booking-slot/${bookingSlotId}`, true);
    } else {
      return YoubrioApiHelper.get(`learn-profile/booking-slot/${bookingSlotId}`, true);
    }
  } else {
    return 'No-op';
  }
};

export const getLessonSessionDetails = ({
  bookingSlotId,
  lessonSessionId,
  startSmartboardOnly = false,
  isSilentMode = false,
}) => {
  let url = `v2/room-id?sb=${startSmartboardOnly}&silent=${isSilentMode}`;
  if (bookingSlotId) {
    url = `${url}&bsid=${bookingSlotId}`;
  } else if (lessonSessionId) {
    url = `${url}&lsid=${lessonSessionId}`;
  }
  return YoubrioApiHelper.get(url, true);
};

export const getImmediateStudent = () => {
  return YoubrioApiHelper.get('immediate-students', true);
};

export const createMeetSchedule = ({
  currencyCode,
  pricePerDuration,
  perMinutes,
  desc,
  startTime,
  endTime,
  minuteDuration,
  isRecurring,
  recurringDay,
  startDateTime,
  endDateTime,
  requestRequired,
  maxParticipants,
  invitees,
  meetScheduleSourceId
}) => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const params = {
    currencyCode,
    pricePerDuration,
    perMinutes,
    desc,
    startTime,
    endTime,
    minuteDuration,
    isRecurring,
    recurringDay,
    startDateTime,
    endDateTime,
    requestRequired,
    maxParticipants,
    invitees,
    tz,
    meetScheduleSourceId
  };
  return YoubrioApiHelper.post('meet-schedule', params, true);
};

export const getImmediateMeet = () => {
  return YoubrioApiHelper.get('immediate-meet', true);
};

export const cancelMeet = ({ meetId }) => {
  const params = {
    meetId,
    status: 'CL'
  };
  return YoubrioApiHelper.post('meet/status', params, true);
};

export const getMeetRoomConfigurations = ({ meetId, mode = 1 }) => {
  return YoubrioApiHelper.get(`meet/configs?mid=${meetId}&mode=${mode}`, true);
};

export const getMeetRoomConfigurationsBuild6726 = ({ meetId, mode = 1 }) => {
  return YoubrioApiHelper.get(`meet/configs-b6726?mid=${meetId}&mode=${mode}`, true);
};

export const approveMeetBookings = ({ meetBookingIds, isCreator }) => {
  const params = {
    mbids: meetBookingIds,
    status: 'CF',
    creator: isCreator
  };

  return YoubrioApiHelper.post('meet-bookings/status', params, true);
};

export const rejectMeetBookings = ({ meetBookingIds, isCreator }) => {
  const params = {
    mbids: meetBookingIds,
    status: 'RJ',
    creator: isCreator
  };

  return YoubrioApiHelper.post('meet-bookings/status', params, true);
};

export const cancelMeetBookingsAsHost = ({ meetBookingIds, isCreator }) => {
  const params = {
    mbids: meetBookingIds,
    status: 'CT',
    creator: isCreator
  };

  return YoubrioApiHelper.post('meet-bookings/status', params, true);
};

export const earlyCancelMeetBookingsAsParticipant = ({ meetBookingIds, isCreator }) => {
  const params = {
    mbids: meetBookingIds,
    status: 'CSE',
    creator: isCreator
  };

  return YoubrioApiHelper.post('meet-bookings/status', params, true);
};

export const lateCancelMeetBookingsAsParticipant = ({ meetBookingIds, isCreator }) => {
  const params = {
    mbids: meetBookingIds,
    status: 'CSL',
    creator: isCreator
  };

  return YoubrioApiHelper.post('meet-bookings/status', params, true);
};

export const getTeacherUpcomingLessonBookings = () => {
  return YoubrioApiHelper.get('teach-profile/booking-slots/upcoming', true);
};

export const getStudentUpcomingLessonBookings = () => {
  return YoubrioApiHelper.get('learn-profile/booking-slots/upcoming', true);
};

export const getHostUpcomingMeet = () => {
  return YoubrioApiHelper.get('host/meets/upcoming', true);
};

export const getParticipantUpcomingMeet = () => {
  return YoubrioApiHelper.get('participant/meets/upcoming', true);
};

export const enterMeet = ({
  meetId,
  practiceRoomId,
  jitsiServerUrl,
  annotServerUrl,
  configParamString,
  mode,
  deviceInfo,
  logUserId
}) => {
  const params = {
    meetId,
    practiceRoomId,
    meetLogTypeId: 1,
    jitsiServerUrl,
    annotServerUrl,
    configParamString,
    mode,
    deviceInfo,
    logUserId
  };
  return YoubrioApiHelper.post('meet-log', params, true);
};

export const exitMeet = ({
  meetId,
  practiceRoomId,
  jitsiServerUrl,
  annotServerUrl,
  configParamString,
  mode,
  deviceInfo,
  logUserId
}) => {
  const params = {
    meetId,
    practiceRoomId,
    meetLogTypeId: 2,
    jitsiServerUrl,
    annotServerUrl,
    configParamString,
    mode,
    deviceInfo,
    logUserId
  };
  return YoubrioApiHelper.post('meet-log', params, true);
};