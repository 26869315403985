import moment from 'moment';
import {
  LOGOUT_USER_SUCCESS,
  UPDATE_LESSON_STATE,
} from '../actions/types';

const INITIAL_STATE = {
  // Modal states
  opened: false,
  lastOpenedSlot: null, // Last opened slot, can be reused to dispatch a refresh action.
  lessonLoading: false,
  slotDetails: null,
  error: false,

  // upcoming Lessons
  upcomingLessonBookings: [],
  upcomingLoading: false,

  // users who had lesson with me
  userList: [],
  userListLoading: false,

  // Calendar or Schedule states
  calendarLoading: false,
  studentLessons: {},
  teacherLessons: {},
  selectedDay: moment(),
  jitsiConfig: {},
  meetConfig: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UPDATE_LESSON_STATE:
    return { ...state, [action.payload.prop]: action.payload.value };
  case LOGOUT_USER_SUCCESS:
    return INITIAL_STATE;
  default:
    return state;
  }
};
