import update from 'immutability-helper';
import {
  UPDATE_VIDEO_LIST,
  UPDATE_EXAM_FIELD,
  UPDATE_EXAM_VIDEO_FIELD,
  UPDATE_EXAM_LIST,
  UPDATE_EXAM_LOADING,
  LOGOUT_USER_SUCCESS,
  UPDATE_EXAM_STATES,
  RESET_EXAM_DETAILS,
  UPDATE_EXAM_DETAILS_ERROR,
  UPDATE_NEW_EXAM_STATE,
  UPDATE_NEW_EXAM_STATE_ERROR,
  RESET_NEW_EXAM_STATE,
  UPDATE_EXAM_DETAILS_FOR_APPEAL,
  RESET_EXAM_DETAILS_FOR_APPEAL,
  RESET_PURCHASE_CERT_DETAILS,
  RESET_EXAM_MODAL
} from '../actions/types';

const INITIAL_EXAM_DETAILS = [
  {
    musicTypeId: '',
    musicTitleId: '',
    musicSelectionList: [],
    video: {
      type: 'link',
      link: '',
      filePath: '',
      thumbnailPath: '',
      fileHash: '',
      uploadedLink: ''
    }
  },
  {
    musicTypeId: '',
    musicTitleId: '',
    musicSelectionList: [],
    video: {
      type: 'link',
      link: '',
      filePath: '',
      thumbnailPath: '',
      fileHash: '',
      uploadedLink: ''
    }
  },
  {
    musicTypeId: '',
    musicTitleId: '',
    musicSelectionList: [],
    video: {
      type: 'link',
      link: '',
      filePath: '',
      thumbnailPath: '',
      fileHash: '',
      uploadedLink: ''
    }
  }
];

const INITIAL_PIECE_DETAILS_ERROR = [
  {
    musicType: '',
    musicTitle: '',
    videoLink: '',
    uploadedLink: ''
  },
  {
    musicType: '',
    musicTitle: '',
    videoLink: '',
    uploadedLink: ''
  },
  {
    musicType: '',
    musicTitle: '',
    videoLink: '',
    uploadedLink: ''
  }
];

const INITIAL_EXAM_ERROR = {
  photoId: ''
};

const INITIAL_NEW_EXAM_STATE = {
  instrumentId: null,
  gradeId: null,
  paymentAmount: 0,
  currencyCode: '-',
  affiliationCode: null,
  isPriceFinalized: false,
  cnTranslationAvailable: false,
  cnTranslationFee: 0,
  isSelectCnTranslation: false,
  error: {
    instrument: '',
    grade: '',
    affiliationCode: ''
  },
  discount: 0,
  couponType: 0,
  studentList: [],
  studentId: null
};

const INITIAL_EXAM_DETAILS_FOR_APPEAL = {
  examId: null,
  originalPrice: null,
  paymentCurrency: '',
  instrumentId: null,
  gradeId: null,
  instrumentName: '',
  gradeName: '',
  studentFirstName: '',
  studentLastName: '',
  appealOptionId: 0,
  appealReason: '',
  appealReasonError: '',
  appealPrice: 0
};

const INITIAL_STATE = {
  videoList: {},
  currentExams: [],
  reworkExams: [],
  appealExams: [],
  completedExams: [],
  openExams: [],
  submittedExams: [],
  isCurrentExamsLoading: false,
  isAppealExamsLoading: false,
  isCompletedExamsLoading: false,
  isReworkExamsLoading: false,
  examId: null,
  instrumentId: null,
  instrumentName: '',
  gradeId: null,
  grade: '',
  remarks: '',
  studentFirstName: '',
  studentLastName: '',
  pieceDetails: INITIAL_EXAM_DETAILS,
  pieceDetailsError: INITIAL_PIECE_DETAILS_ERROR,
  examError: INITIAL_EXAM_ERROR,
  pieceIndexWithError: -1,
  photoIdUri: '',
  defaultPhotoIdUri: '',
  showExamNotificationModal: false,
  examNotificationModalMessage: '',
  examNotificationModalTitle: '',
  examNotificationModalMode: null,
  newExam: INITIAL_NEW_EXAM_STATE,
  appealExamDetails: INITIAL_EXAM_DETAILS_FOR_APPEAL,
  certPurchaseDetails: {},
  hasExamChanged: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_VIDEO_LIST:
      return { ...state, videoList: action.payload };
    case UPDATE_EXAM_FIELD:
      if (action.payload.prop === 'musicSelectionList') {
        return update(state, {
          pieceDetails: {
            [action.payload.index]: {
              [action.payload.prop]: { $set: action.payload.value }
            }
          }
        });
      }
      return update(state, {
        pieceDetails: {
          [action.payload.index]: {
            [action.payload.prop]: { $set: action.payload.value }
          }
        },
        hasExamChanged: { $set: true }
      });
    case UPDATE_EXAM_VIDEO_FIELD:
      return update(state, {
        pieceDetails: {
          [action.payload.index]: {
            video: {
              [action.payload.prop]: { $set: action.payload.value }
            }
          }
        },
        hasExamChanged: { $set: true }
      });
    case UPDATE_EXAM_LIST:
      return { ...state, [action.payload.examListType]: action.payload.examListValue };
    case UPDATE_EXAM_LOADING:
      return { ...state, [action.payload.loadingState]: action.payload.isLoading };
    case UPDATE_EXAM_STATES:
      return { ...state, [action.payload.prop]: action.payload.value };
    case RESET_EXAM_DETAILS:
      return {
        ...state,
        examId: null,
        instrumentId: null,
        instrumentName: '',
        gradeId: null,
        grade: '',
        remarks: '',
        studentFirstName: '',
        studentLastName: '',
        photoIdUri: '',
        defaultPhotoIdUri: '',
        showExamNotificationModal: false,
        pieceDetails: INITIAL_EXAM_DETAILS,
        examError: INITIAL_EXAM_ERROR,
        pieceDetailsError: INITIAL_PIECE_DETAILS_ERROR,
        pieceIndexWithError: -1,
        hasExamChanged: false
      };
    case UPDATE_EXAM_DETAILS_ERROR:
      if (action.payload.index !== null) {
        return update(state, {
          pieceDetailsError: {
            [action.payload.index]: {
              [action.payload.prop]: { $set: action.payload.error }
            }
          }
        });
      }
      return { ...state, examError: { ...state.examError, [action.payload.prop]: action.payload.error } };
    case LOGOUT_USER_SUCCESS:
      return INITIAL_STATE;
    case UPDATE_NEW_EXAM_STATE:
      return { ...state, newExam: { ...state.newExam, [action.payload.prop]: action.payload.value } };
    case RESET_NEW_EXAM_STATE:
      return {
        ...state,
        newExam: INITIAL_NEW_EXAM_STATE,
        showExamNotificationModal: false
      };
    case UPDATE_NEW_EXAM_STATE_ERROR:
      return update(state, {
        newExam: {
          error: {
            [action.payload.prop]: { $set: action.payload.error }
          }
        }
      });
    case UPDATE_EXAM_DETAILS_FOR_APPEAL:
      return { ...state, appealExamDetails: { ...state.appealExamDetails, [action.payload.prop]: action.payload.value } };
    case RESET_EXAM_DETAILS_FOR_APPEAL:
      return {
        ...state,
        appealExamDetails: INITIAL_EXAM_DETAILS_FOR_APPEAL,
        showExamNotificationModal: false,
        examNotificationModalMessage: '',
        examNotificationModalTitle: '',
        examNotificationModalMode: null
      };
    case RESET_PURCHASE_CERT_DETAILS:
      return {
        ...state,
        certPurchaseDetails: {},
        showExamNotificationModal: false,
        examNotificationModalMessage: '',
        examNotificationModalTitle: '',
        examNotificationModalMode: null
      };
    case RESET_EXAM_MODAL:
      return {
        ...state,
        showExamNotificationModal: false,
        examNotificationModalMessage: '',
        examNotificationModalTitle: '',
        examNotificationModalMode: null,
      };
    default:
      return state;
  }
};
