import React from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import AppHelper from '../../helpers/AppHelper';
import GLOBAL from '../../helpers/GLOBAL';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(3),
  }
}));

const ControlRoomButton = (/*props*/) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const onButtonClick = () => {
    history.push('/control-room');
  };

  const render = () => {
    const isTutor = _.get(GLOBAL.get('appSettings'), 'isTRTutor', false);

    if (isTutor || AppHelper.isActualAdmin()) {
      return (
        <Button
          variant="outlined"
          color="primary"
          onClick={onButtonClick}
          className={classes.button}
        >
          {t('tutorRoom.controlRoom')}
        </Button>
      );
    }
    
    return null;
  };

  return render();
};

export default ControlRoomButton;