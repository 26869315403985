import {
  UPDATE_FIELD,
  UPDATE_FIELD_ERROR,
  UPDATE_MULTIPLE_FIELD_ERROR,
  RESET_REGISTRATION_FIELD,
  UPDATE_REGISTRATION_MODAL_STATE
} from '../actions/types';

const INITIAL_STATE = {
  email: '',
  password: '',
  confirmPassword: '',
  firstName: '',
  lastName: '',
  guardianName: '',
  gender: '',
  dob: '',
  address1: '',
  address2: '',
  suburb: '',
  postcode: '',
  countryId: '',
  countryRegion: '',
  countryState: '',
  landline: '',
  mobile: '',
  institutionType: '',
  teachAddress: '',
  qualification: '',
  teacherId: '',
  subscribed: false,
  avatarUrl: '',
  error: {},
  socialMediaEmail: '',
  passwordOnCBEForSocialMediaLogIn: '',
  registrationModal: {
    mode: 'success',
    message: '',
    title: '',
    visible: false
  },
  hasRegistrationChanged: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UPDATE_FIELD:
    return { ...state, [action.payload.prop]: action.payload.value, hasRegistrationChanged: true };
  case UPDATE_FIELD_ERROR:
    return { ...state, error: { ...state.error, [action.payload.prop]: action.payload.error } };
  case UPDATE_MULTIPLE_FIELD_ERROR:
    return { ...state, error: action.payload };
  case UPDATE_REGISTRATION_MODAL_STATE:
    return { ...state, registrationModal: { ...state.registrationModal, [action.payload.prop]: action.payload.value } };
  case RESET_REGISTRATION_FIELD:
    return INITIAL_STATE;
  default:
    return state;
  }
};
