import LocalizedStrings from 'react-localization';
import { en } from './en';
import { zhHans } from './zhHans';
import { zhHant } from './zhHant';

export const messages = new LocalizedStrings({
  'en': en,
  'zh-CN': zhHans,
  'zh-Hans': zhHans,
  'zh-TW': zhHant,
  'zh-Hant': zhHant,
  'zh': zhHans
});
