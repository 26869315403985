/* @flow */

import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Lottie from 'lottie-react';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { BaseDialog } from './BaseDialog';
import IconClose from '@material-ui/icons/Close';
import { IconCoffee } from '../../Icons';
import coffeeAnimation from '../../../img/lottie/coffeetime.json';
import AppHelper from '../../../helpers/AppHelper';
import {
  activateCoffeeBreak,
  deactivateCoffeeBreak
} from '../../../actions';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import '../../ControlRoomScreen/ControlRoom.css';

/**
 * Implements a component for the dialog for teacher to toggle coffee break mode.
 */
const _CoffeeBreakDialog = (props) => {
  const {
    isOnCoffeeBreak,
    isUserTeaching,
    coffeeBreakCounter,
    isLoading,
    _open,
    _onClose,
    activateCoffeeBreak: _activateCoffeeBreak,
    deactivateCoffeeBreak: _deactivateCoffeeBreak,
    closeDialog: _closeDialog,
    ...otherProps
  } = props;

  const [now, setNow] = useState(new Date());
  const timer = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    _timerStart();

    return () => {
      clearInterval(timer.current);
    };
  });

  /**
   * Update the now timestamp.
   *
   * @returns {void}
   */
  const _tick = () => {
    // console.log('tick');
    setNow(new Date());
  };

  /**
   * Start the interval to update now timestamp every second.
   *
   * @returns {void}
   */
  const _timerStart = () => {
    clearInterval(timer.current);
    timer.current = setInterval(_tick, 1000);
  };

  /**
   * Callback to be invoked for cancel action (user wants to send a group message).
   *
   * @returns {boolean}
   */
  const _onDialogCancel = () => {
    _closeDialog();
  };

  /**
   * Toggles coffee break status.
   *
   * @returns {void}
   */
  const _onToggleCoffeeBreakClick = () => {
    if (isUserTeaching) {
      AppHelper.showSnackbarNotification({
        message: t('tutorRoom.coffeeBreak.finishTeachingThenOnlyGoBreak')
      });

      return;
    }

    if (isOnCoffeeBreak) {
      _deactivateCoffeeBreak();
    } else {
      _activateCoffeeBreak();
    }
  };

  /**
   * Renders the header of the dialog.
   *
   * @returns {ReactElement}
   */
  const renderHeader = () => {
    return (
      <div className = { 'header-btn dialog-close' }>
        <IconButton
          onClick = { _onDialogCancel }>
          <IconClose
            style={{ fill: '#ffffff', fontSize: 18 }} />
        </IconButton>
      </div>
    );
  };

  /**
   * Renders the total duration of user being on break for the day.
   *
   * @returns {ReactElement}
   */
  const renderCoffeeBreakTimer = () => {
    if (coffeeBreakCounter) {
      const {
        coffeeBreakAt,
        totalSeconds
      } = coffeeBreakCounter;

      const _now = moment(now);
      let totalSecondsElapsed = coffeeBreakAt ? _now.diff(moment(coffeeBreakAt), 'seconds') : 0;

      totalSecondsElapsed += totalSeconds;

      const {
        hours,
        minutes,
        seconds
      } = AppHelper.formatSecondsToNow(totalSecondsElapsed);

      return (
        <span className = { 'dialog-info-text coffee-break-timer' }>
          <span className = { 'coffee-break-icon' }>
            <IconCoffee
              viewBox='0 0 30 30'
              style = {{ fontSize: 18, fill: '#fff', overflow: 'initial' }} />
          </span>
          <span>
            {hours.toString().padStart(2, 0)}
            :{minutes.toString().padStart(2, 0)}
            :{seconds.toString().padStart(2, 0)}
          </span>
        </span>
      );
    }

    return null;
  };

  /**
   * Renders the body of the dialog.
   *
   * @returns {ReactElement}
   */
  const renderBody = () => {
    return (
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        className='coffee-break'
      >
        <Lottie
          animationData={coffeeAnimation}
          loop={isOnCoffeeBreak}
          autoplay={isOnCoffeeBreak}
          style={{
            width: 150,
            height: 150,
            transform: 'scale(1.5)'
          }} />

        <Typography className='dialog-info-header'>{t('tutorRoom.coffeeBreak.coffeeBreak').toUpperCase()}</Typography>

        {
          isOnCoffeeBreak
            ? <div className = 'activated'>{t('tutorRoom.coffeeBreak.activated').toUpperCase()}</div>
            : <div className = 'deactivated'>{t('tutorRoom.coffeeBreak.deactivated').toUpperCase()}</div>
        }

        { renderCoffeeBreakTimer() }

        <div className = 'dialog-info-text'>
          { isOnCoffeeBreak
            ? t('tutorRoom.coffeeBreak.coffeeBreakActivatedInfo')
            : t('tutorRoom.coffeeBreak.coffeeBreakDeactivatedInfo') }
        </div>
      </Grid>
    );
  };

  const renderButtons = () => {
    return (
      <Button
        fullWidth
        onClick={_onToggleCoffeeBreakClick}
        autoFocus={true}
        color={'primary'}
        variant={'contained'}
        disabled = {isLoading}
      >
        { isLoading
          ? <div>
            <div className = { 'loader' } />
          </div>
          : isOnCoffeeBreak
            ? t('tutorRoom.coffeeBreak.imBack')
            : t('tutorRoom.coffeeBreak.goOnBreak') }
      </Button>
    );
  };

  /**
   * Implements React's {@link Component#render()}.
   *
   * @inheritdoc
   * @returns {ReactElement}
   */
  const render = () => {
    return (
      <BaseDialog
        open={_open}
        onClose={_onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...otherProps}
        maxWidth={'xs'}
      >
        <DialogTitle id="alert-dialog-title">
          {renderHeader()}
        </DialogTitle>
        <DialogContent>
          {renderBody()}
        </DialogContent>
        <DialogActions>
          {renderButtons()}
        </DialogActions>
      </BaseDialog>
    );
  };

  return render();
};

/**
 * Maps part of the Redux store to the props of this component.
 *
 * @param {Object} state - The Redux state.
 * @param {Props} ownProps - The own props of the component.
 * @returns {Props}
 */
export function mapStateToProps(state) {
  const {
    participants,
    isLoadingParticipant
  } = state.practiceRoom.practiceRoomConfig;

  const userParticipant = participants.find(p => p.user_id === AppHelper.getUserId());
  const participantBeingTaught = participants.find(p => p.lesson_mode_tutor_user_id === AppHelper.getUserId());

  return {
    /* eslint-disable-next-line camelcase */
    isOnCoffeeBreak: (userParticipant && userParticipant?.coffee_break_at !== null) ?? false,
    isUserTeaching: participantBeingTaught !== undefined && participantBeingTaught !== null,
    coffeeBreakCounter: userParticipant?.coffeeBreakCounter ?? null,
    isLoading: isLoadingParticipant
  };
}

const CoffeeBreakDialog = connect(mapStateToProps, {
  activateCoffeeBreak,
  deactivateCoffeeBreak
})(_CoffeeBreakDialog);

export { CoffeeBreakDialog };
