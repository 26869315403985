import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function DumbbellIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M27.438 19.813l1.875-1.875-1.875-1.938-4.75 4.75-11.438-11.438 4.75-4.75-1.938-1.875-1.875 1.875-1.938-1.875-2.813 2.813-1.938-1.875-1.875 1.875 1.875 1.938-2.813 2.813 1.875 1.938-1.875 1.875 1.875 1.938 4.75-4.75 11.438 11.438-4.75 4.75 1.938 1.875 1.875-1.875 1.938 1.875 2.813-2.813 1.938 1.875 1.875-1.875-1.875-1.938 2.813-2.813z"></path>
    </SvgIcon>
  );
}

