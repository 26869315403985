/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RLink } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import AppHelper from '../../helpers/AppHelper';
import YoubrioApiHelper from '../../helpers/YoubrioApiHelper';
// import ThirdLogin from '../SigninScreen/ThirdLogin';
import {
  fieldUpdate,
  validateField,
  validateFieldsAndRegisterBasicAccount,
  // validateAndLoginUser,
  resetField,
  updateRegistrationModalState,
  checkForUnsavedData,
  resetCommonModalState
} from '../../actions';
import ApiHelper from '../../helpers/ApiHelper';
// import { labels } from '../../lang/labels';
// import AppHelper from '../../helpers/AppHelper';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        Youbrio
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = (theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'white',
  },
  avatar: {
    padding: '20px 0 0 20px',
    width: '60px',
    height: '60px',
    // margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
  },
  welcomeSlogan: {
    padding: '1% 0 1% 0',
    'text-align': 'left',
    width: '80%',
    height: '100%',
  },
  welcomeSloganLine1: {
    'font-weight': 'bold',
  },
  welcomeSloganLine2: {
    'font-weight': 'bold',
    'color': '#abababcc',
  },
  paper: {
    margin: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  or: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: '85%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    'background-color': '#4cbb2a8c',
    'background-image': 'linear-gradient(blur, green)',
  },
  agreeTerms: {
    'font-size': '0.8rem',
  },
  rightSide: {
    margin: '10% 0 10% 0',
  },
  rightSideImage: {
    width: 'auto',
    height: 'auto',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  rightSlogan: {
    padding: '20px 40px 20px 40px',
  },
  rightDesc: {
    padding: '10% 2% 5% 5%',
  },
  rightDescLine: {
    padding: '10px 60px 0 10px',
  }
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class SignupScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillMount() {
  }

  componentDidMount() {
  }

  onFirstNameChange(e) {
    let text = e.target.value;
    this.props.fieldUpdate({ prop: 'firstName', value: text });
    this.props.validateField({ prop: 'firstName', value: text });
  }

  onLastNameChange(e) {
    let text = e.target.value;
    this.props.fieldUpdate({ prop: 'lastName', value: text });
    this.props.validateField({ prop: 'lastName', value: text });
  }

  onEmailChange(e) {
    let text = e.target.value;
    this.props.fieldUpdate({ prop: 'email', value: text });
    this.props.validateField({ prop: 'email', value: text });
  }

  onPasswordChange(e) {
    let text = e.target.value;
    this.props.fieldUpdate({ prop: 'password', value: text });
    this.props.validateField({ prop: 'password', value: text });
    this.props.validateField({ prop: 'confirmPassword', value: this.props.confirmPassword });
  }

  onConfirmPasswordChange(e) {
    let text = e.target.value;
    this.props.fieldUpdate({ prop: 'confirmPassword', value: text });
    this.props.validateField({ prop: 'confirmPassword', value: text });
  }

  onMobileChange(e) {
    let text = e.target.value;
    this.props.fieldUpdate({ prop: 'mobile', value: text });
    this.props.validateField({ prop: 'mobile', value: text });
  }

  validateAllFields() {
    const fields = {
      email: this.props.email,
      password: this.props.password,
      confirmPassword: this.props.confirmPassword,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      mobile: this.props.mobile
    };
    this.props.validateFieldsAndRegisterBasicAccount(
      fields,
      () => {
        /// can't find validateAndLoginUser
        // this.props.validateAndLoginUser({
        //   email: this.props.email,
        //   password: this.props.password,
        //   history: this.props.history
        // });
        this.props.history.replace('/dash');
      }
    );
  }

  onClickRegisterButton() {
    this.validateAllFields();
  }

  render() {
    return this.SignUpSide(this.props.classes);
  }

  SignUpSide(classes) {
    const { t } = this.props;
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Avatar className={classes.avatar} variant="square" src={ApiHelper.getCdnUrl('/public/images/youbrio-gold.png')} />
          <div className={classes.paper}>
            <div className={classes.welcomeSlogan}>
              <Typography component="h1" variant="h4" align="left" className={classes.welcomeSloganLine1}>
                { t('signup.hi') }
              </Typography>
              <Typography component="h1" variant="h4" align="left" className={classes.welcomeSloganLine2}>
                { t('signup.createAnAccount') }
              </Typography>
            </div>
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label={t('signup.firstName')}
                    value={this.props.firstName}
                    onChange={this.onFirstNameChange.bind(this)}
                    autoFocus
                    error={this.props.error.firstName}
                    helperText={this.props.error.firstName || t('signup.firstNameDefaultHelperText')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label={t('signup.lastName')}
                    name="lastName"
                    value={this.props.lastName}
                    onChange={this.onLastNameChange.bind(this)}
                    autoComplete="lname"
                    error={this.props.error.lastName}
                    helperText={this.props.error.lastName || t('signup.lastNameDefaultHelperText')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label={t('signup.email')}
                    name="email"
                    value={this.props.email}
                    onChange={this.onEmailChange.bind(this)}
                    autoComplete="email"
                    error={this.props.error.email}
                    helperText={this.props.error.email || t('signup.emailDefaultHelperText')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label={t('signup.password')}
                    type="password"
                    id="password"
                    value={this.props.password}
                    onChange={this.onPasswordChange.bind(this)}
                    autoComplete="current-password"
                    error={this.props.error.password}
                    helperText={this.props.error.password || t('signup.passwordDefaultHelperText')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="confirmPassword"
                    label={t('signup.confirmPassword')}
                    type="password"
                    id="confirmPassword"
                    value={this.props.confirmPassword}
                    onChange={this.onConfirmPasswordChange.bind(this)}
                    autoComplete="current-password"
                    error={this.props.error.confirmPassword}
                    helperText={this.props.error.confirmPassword || t('signup.confirmPasswordDefaultHelperText')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="phone"
                    label={t('signup.phoneNumber')}
                    id="phoneNumber"
                    value={this.props.mobile}
                    onChange={this.onMobileChange.bind(this)}
                    autoComplete="phoneNumber"
                    eror={this.props.error.mobile}
                    helperText={this.props.error.mobile || t('signup.phoneNumberDefaultHelperText')}
                  />
                </Grid>
                <Box mt={2} ml={1} mb={1}>
                  <Typography variant={'body2'}>
                    <Trans
                      i18nKey="signup.byProceedingAccCreateYouAgreeToTerms"
                      values={{
                        tncLink: YoubrioApiHelper.getLegacyPortalPath(`terms?lang=${AppHelper.getAppLocaleCodeForApi()}`),
                        privacyLink: YoubrioApiHelper.getLegacyPortalPath(`privacy?lang=${AppHelper.getAppLocaleCodeForApi()}`)
                      }}
                      components={{ linkcom: <Link /> }}
                    />
                  </Typography>
                </Box>
              </Grid>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={this.onClickRegisterButton.bind(this)}
              >
                { t('signup.createAccount') }
              </Button>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link component={RLink} to="/sign-in" variant="body2">
                    {t('signup.goToSignin')}
                  </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
        <Grid item xs={false} sm={4} md={7} className={classes.image}>
          <div className={classes.rightSide}>
            <img alt="youbrio-login" src={ApiHelper.getCdnUrl('/public/images/login-main2.jpg')} className={classes.rightSideImage} />
          </div>
        </Grid>
        <Snackbar open={this.props.registrationModal.visible} autoHideDuration={6000} onClose={this.handleCloseSnackbar.bind(this)}>
          <Alert onClose={this.handleCloseSnackbar.bind(this)} severity={this.props.registrationModal.mode}>
            { this.props.registrationModal.message }
          </Alert>
        </Snackbar>
      </Grid>
    );
  }

  handleCloseSnackbar(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.props.updateRegistrationModalState({ prop: 'visible', value: false });
  }
}

const mapStateToProps = state => {
  const {
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    error,
    registrationModal,
    hasRegistrationChanged,
    mobile,
    referralCode
  } = state.registration;

  const {
    commonModal,
    loading
  } = state.dialog;

  return {
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    error,
    registrationModal,
    hasRegistrationChanged,
    mobile,
    referralCode,
    commonModal,
    loading
  };
};

export default withTranslation()(
  withStyles(styles)(
    withRouter(
      connect(mapStateToProps, {
        fieldUpdate,
        validateField,
        validateFieldsAndRegisterBasicAccount,
        // validateAndLoginUser,
        resetField,
        updateRegistrationModalState,
        checkForUnsavedData,
        resetCommonModalState
      })(SignupScreen)
    )
  )
);


