import YoubrioApiHelper from '../helpers/YoubrioApiHelper';
import AppHelper from '../helpers/AppHelper';
import ApiHelper from '../helpers/ApiHelper';

export const getQuestions = (questionId) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return YoubrioApiHelper.get(`questions?lang=${lang}&questionId=${questionId}`, true);
};

export const getTeachQuestions = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return YoubrioApiHelper.get(`teach-profile/questions?lang=${lang}`, true);
};

export const getLearnQuestions = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return YoubrioApiHelper.get(`learn-profile/questions?lang=${lang}`, true);
};

export const getYoubrioProfileAndAnswers = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return YoubrioApiHelper.get(`role-profile?lang=${lang}`, true);
};

export const getOtherYoubrioProfileAndAnswers = ({
  learnId,
  teachId,
  lidHashed,
  tidHashed,
  ytidHashed,
  ylidHashed,
  referralUuid,
  exploredReferralUuid,
  shouldAddExploreRelation
}) => {
  if (learnId) {
    const lang = AppHelper.getAppLocaleCodeForApi();
    return YoubrioApiHelper.get(`other-role-profile?lang=${lang}&lid=${learnId}`, true);
  } else if (teachId) {
    const lang = AppHelper.getAppLocaleCodeForApi();
    return YoubrioApiHelper.get(`other-role-profile?lang=${lang}&tid=${teachId}&addex=${shouldAddExploreRelation}`, true);
  } else if (tidHashed) {
    const lang = AppHelper.getAppLocaleCodeForApi();
    return YoubrioApiHelper.get(`unhashed-other-profile?lang=${lang}&tidHashed=${tidHashed}`, true);
  } else if (lidHashed) {
    const lang = AppHelper.getAppLocaleCodeForApi();
    return YoubrioApiHelper.get(`unhashed-other-profile?lang=${lang}&lidHashed=${lidHashed}`, true);
  } else if (ytidHashed) {
    const lang = AppHelper.getAppLocaleCodeForApi();
    return YoubrioApiHelper.get(`unhashed-other-profile?lang=${lang}&ytidHashed=${ytidHashed}`, true);
  } else if (ylidHashed) {
    const lang = AppHelper.getAppLocaleCodeForApi();
    return YoubrioApiHelper.get(`unhashed-other-profile?lang=${lang}&ylidHashed=${ylidHashed}`, true);
  } else if (referralUuid) {
    return YoubrioApiHelper.get(`other-role-profile?&ref=${referralUuid}`, true);
  } else if (exploredReferralUuid) {
    return YoubrioApiHelper.get(`other-role-profile?&ref=${exploredReferralUuid}&ex=true`, true);
  }
};

export const updateProfilePhoto = (image) => {
  const params = new FormData();

  console.log(JSON.stringify(image, null, 4));
  if (image.path && !image.path.includes('https://')) {
    const fileName = AppHelper.getFileNameWithExtension(image.path);
    const fileExt = AppHelper.getFileTypeExtension(image.path);

    params.append('avatar', { uri: image.path, name: fileName, type: `image/${fileExt.toLowerCase()}` });
  }

  console.log(JSON.stringify(params, null, 4));
  return ApiHelper.post('profile/photo', params, true, true);
};

export const createLearnProfileAndAnswers = (user, selectedAnswers) => {
  const params = new FormData();

  params.append('firstName', user.firstName);
  params.append('lastName', user.lastName);

  if (user.gender) {
    params.append('gender', user.gender);
  } else {
    params.append('gender', 'male');
  }

  if (user.dob) {
    params.append('dob', user.dob);
  }

  if (selectedAnswers) {
    params.append('answers', JSON.stringify(selectedAnswers));
  }

  const lang = AppHelper.getAppLocaleCodeForApi();
  console.log(JSON.stringify(params, null, 4));
  return YoubrioApiHelper.post(`new-learn-profile?lang=${lang}&selectedAnswers=${selectedAnswers}`, params, true, true);
};

export const getAchievementList = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  const physicalCountry = AppHelper.getPhysicalCountry();

  return YoubrioApiHelper.get(`achievements?lang=${lang}&country=${physicalCountry}`, true);
};

export const getAchievementListWithPage = ({ page }) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  const physicalCountry = AppHelper.getPhysicalCountry();

  return YoubrioApiHelper.get(`achievements?lang=${lang}&page=${page}&country=${physicalCountry}`, true);
};

export const getOtherUserAchievementList = (page = 1, id) => {
  const lang = AppHelper.getAppLocaleCodeForApi();

  return YoubrioApiHelper.get(`other-achievements?lang=${lang}&page=${page}&id=${id || ''}`, true);
};

export const updateProfileDescription = ({ description }) => {
  const params = {
    description
  };
  return YoubrioApiHelper.post('description/update', params, true);
};

export const updateProfileFocus = ({ focus }) => {
  const params = {
    focus
  };
  return YoubrioApiHelper.post('focus/update', params, true);
};

export const updateTeachExperience = ({ experience }) => {
  const params = {
    experience
  };
  return YoubrioApiHelper.post('teach-profile/experience', params, true);
};

export const updateTeachReferenceRate = ({ referenceRate }) => {
  const params = {
    referenceRate
  };
  return YoubrioApiHelper.post('teach-profile/reference-rate', params, true);
};

export const referenceRateRange = () => {
  return YoubrioApiHelper.get('teach-profile/r-rate-range', true);
};

export const updateAnswers = (questionId, selectedAnswers) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  const physicalCountry = AppHelper.getPhysicalCountry();

  return YoubrioApiHelper.get(`answers/update?lang=${lang}&questionId=${questionId}&selectedAnswers=${JSON.stringify(selectedAnswers)}&country=${physicalCountry}`, true);
};

export const deleteAchievement = ({ achievement }) => {
  let type;
  let id;
  if (achievement.video_id) {
    type = 'video';
    id = achievement.video_id;
  } else {
    type = 'image';
    id = achievement.id;
  }
  return YoubrioApiHelper.get(`achievements/delete?id=${id}&type=${type}`, true);
};

export const submitReviewForLesson = (params) => {
  let api = 'student-review';

  if (AppHelper.isCBETeacher()) {
    api = 'teacher-review';
  }
  return YoubrioApiHelper.post(api, params, true);
};

export const getReviewList = ({ uid }) => {
  return YoubrioApiHelper.get(`review?uid=${uid}`, true);
};

export const getReviewListWithPage = ({ uid, page }) => {
  return YoubrioApiHelper.get(`review?uid=${uid}&page=${page}`, true);
};

export const updateFavouriteTeacher = ({ tid, favourite }) => {
  if (favourite) {
    return YoubrioApiHelper.get(`favourite/delete?tid=${tid}`, true);
  }

  return YoubrioApiHelper.get(`favourite/new?tid=${tid}`, true);
};

export const getFavouriteList = () => {
  return YoubrioApiHelper.get('favourite/all', true);
};

export const updatePackageFlag = () => {
  return YoubrioApiHelper.get('lesson-package/update', true);
};

export const getSubjectRegion = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return YoubrioApiHelper.get(`subject-region?lang=${lang}`, true);
};

export const updateSubjectSpecificRegion = ({ subjectProfileId, selected }) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return YoubrioApiHelper.get(`subject-region/update?lang=${lang}&subjectProfileId=${subjectProfileId}&selected=${JSON.stringify(selected)}`, true);
};

export const getSubjectLevel = ({ subjectProfileId }) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return YoubrioApiHelper.get(`subject-level?lang=${lang}&subjectProfileId=${subjectProfileId}`, true);
};

export const updateSubjectSpecificLevel = ({ subjectProfileId, selected }) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return YoubrioApiHelper.get(`subject-level/update?lang=${lang}&subjectProfileId=${subjectProfileId}&selected=${JSON.stringify(selected)}`, true);
};

export const updateSubjectFocus = ({ subjectProfileId, focus }) => {
  return YoubrioApiHelper.get(`subject-focus/update?subjectProfileId=${subjectProfileId}&focus=${focus}`, true);
};

export const getTeachingSubject = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return YoubrioApiHelper.get(`subjects?lang=${lang}`, true);
};

export const updateMyTeachingSubject = ({ added, deleted }) => {
  return YoubrioApiHelper.get(`subject-all/update?added=${JSON.stringify(added)}&deleted=${JSON.stringify(deleted)}`, true);
};

export const changePassword = ({
  oldPassword,
  newPassword,
  newPasswordConfirm
}) => {
  const params = new FormData();
  params.append('oldPassword', oldPassword);
  params.append('newPassword', newPassword);
  params.append('newPasswordConfirm', newPasswordConfirm);
  return YoubrioApiHelper.post('password-update', params, true, true);
};

export const getUserEndorsement = ({ fromUid, toUid, eId }) => {
  if (eId) {
    return YoubrioApiHelper.get(`endorsement?id=${eId}`, true);
  } else if (fromUid) {
    return YoubrioApiHelper.get(`endorsement?fromUid=${fromUid}&toUid=${toUid}`, true);
  }
  return YoubrioApiHelper.get(`endorsement?toUid=${toUid}`, true);
};

export const getVariousUserReviewById = ({ id, type }) => {
  return YoubrioApiHelper.get(`various-review?id=${id}&t=${type}`, true);
};

export const submitUserEndorsement = ({
  toUid,
  rating,
  comment
}) => {
  const params = new FormData();
  params.append('toUid', toUid);
  params.append('rating', rating);
  params.append('comment', comment);
  return YoubrioApiHelper.post('endorsement', params, true, true);
};

export const getAllMyEndorsement = ({ page = 1 }) => {
  return YoubrioApiHelper.get(`endorsement-all?page=${page}`, true);
};

export const getOtherUserEndorsement = ({ uid, page }) => {
  return YoubrioApiHelper.get(`endorsement-other?uid=${uid}&page=${page}`, true);
};

/**
 * Get Various type reviews, can be endorsements or student/teacher reviews
 * @param {string} type of review, can be 'endorsement', 'teacher_review', 'student_review'
 * @param {int} endorsement/review id
 */
export const getVariousTypeReview = (type, id) => {
  return YoubrioApiHelper.get(`various-review?t=${type}&id=${id}`, true);
};

export const getMyNotifyOption = () => {
  return YoubrioApiHelper.get('notification-option', true);
};

//admin api
export const searchUserByName = ({ name, page }) => {
  return YoubrioApiHelper.get(`search-by-name?name=${name}&page=${page}`, true);
};

export const getRelatedTeacherList = (page = 1) => {
  return YoubrioApiHelper.get(`learn-profile/related-teachers?page=${page}`, true);
};

export const getRelatedStudentList = (page = 1) => {
  return YoubrioApiHelper.get(`teach-profile/related-students?page=${page}`, true);
};

export const addTeacherStudent = ({ teachProfileId, learnProfileId, relationshipType }) => {
  const params = {
    tpid: teachProfileId,
    lpid: learnProfileId,
    rtype: relationshipType
  };
  return YoubrioApiHelper.post('teacher-student', params, true);
};

export const removeTeacherStudent = ({ teachProfileId, learnProfileId }) => {
  const params = {
    tpid: teachProfileId,
    lpid: learnProfileId
  };
  return YoubrioApiHelper.post('teacher-student/delete', params, true);
};

export const searchStudents = ({ q, page }) => {
  return YoubrioApiHelper.get(`students?page=${page}&q=${q}`, true);
};

export const updateAccountPrivacy = () => {
  return YoubrioApiHelper.get('teach-profile/private', true);
};
