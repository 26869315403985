import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function RaisedHandIcon(props) {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.74988 2.625V3.95455V9V9.75C9.74988 10.1642 10.0857 10.5 10.4999 10.5C10.9141 10.5 11.2499 10.1642 11.2499 9.75V9V3.95455C11.2499 3.87516 11.3876 3.75 11.6249 3.75C11.8622 3.75 11.9999 3.87516 11.9999 3.95455V5.625V9.75C11.9999 10.1642 12.3357 10.5 12.7499 10.5C13.1641 10.5 13.4999 10.1642 13.4999 9.75V5.625C13.4999 5.41789 13.6678 5.25 13.8749 5.25C14.082 5.25 14.2499 5.41789 14.2499 5.625V11.2811C14.0457 13.3687 12.266 15 10.1249 15C8.71915 15 7.43958 14.2916 6.68469 13.1525L6.682 13.1532L3.82247 8.85337C3.68527 8.65681 3.7265 8.42298 3.89615 8.30418C4.06581 8.18539 4.29963 8.22662 4.41843 8.39627L5.37775 9.83045L5.37678 9.8311C5.60841 10.1745 6.07456 10.2651 6.41796 10.0335C6.62525 9.89367 6.74042 9.66839 6.74821 9.43624L6.74988 9.43673V4.125C6.74988 3.91789 6.91777 3.75 7.12488 3.75C7.33199 3.75 7.49988 3.91789 7.49988 4.125V9V9.75C7.49988 10.1642 7.83567 10.5 8.24988 10.5C8.66409 10.5 8.99988 10.1642 8.99988 9.75V9V4.125V2.625C8.99988 2.41789 9.16777 2.25 9.37488 2.25C9.58199 2.25 9.74988 2.41789 9.74988 2.625ZM15.7366 11.2652L15.7499 11.2586V10.875V5.625C15.7499 4.58947 14.9104 3.75 13.8749 3.75C13.7434 3.75 13.615 3.76354 13.4912 3.78929C13.3998 2.92544 12.5991 2.25 11.6249 2.25C11.4859 2.25 11.3504 2.26375 11.22 2.28984C11.062 1.41423 10.296 0.75 9.37488 0.75C8.4524 0.75 7.68552 1.41617 7.52906 2.29368C7.39889 2.26508 7.26364 2.25 7.12488 2.25C6.08934 2.25 5.24988 3.08947 5.24988 4.125V7.12106C4.61807 6.63808 3.72195 6.595 3.03579 7.07546C2.18753 7.66941 1.98138 8.83856 2.57533 9.68682L5.27627 13.7284C6.25454 15.3871 8.05975 16.5 10.1249 16.5C13.1003 16.5 15.5362 14.1898 15.7366 11.2652Z"/>
    </SvgIcon>
  );
}

