import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function TeachIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M26.688 22.688q1.125-0.063 1.875-0.813t0.75-1.875v-14.688q0-1.125-0.75-1.875t-1.875-0.75h-14.063q0.688 1.25 0.688 2.625h13.375v14.688h-12v2.688h12zM20 9.313v2.688h-8v17.313h-2.688v-8h-2.625v8h-2.688v-10.625h-2v-6.688q0-1.125 0.781-1.875t1.906-0.813h15.313zM10.688 5.313q-0.063 1.125-0.813 1.906t-1.875 0.781-1.875-0.781-0.75-1.906 0.75-1.875 1.875-0.75 1.875 0.75 0.813 1.875z"></path>
    </SvgIcon>
  );
}

