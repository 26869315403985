import AnnotationSocketHelper from './AnnotationSocketHelper';

const CONSTANT = require('./ConstantHelper');

class PracticeRoomHelper {
  static pushRefreshParticipantListEvent({ userId, roomId }) {
    const { PRACTICE_ROOM_EVENT } = CONSTANT;

    const data = {
      event_type_id: PRACTICE_ROOM_EVENT.REFRESH_PARTICIPANT_LIST,
      data: {},
      user_id: userId,
      session_id: roomId
    };

    AnnotationSocketHelper.emit('pushEvent', data, (/* res */) => {
      // console.log('PracticeRoomHelper.pushRefreshParticipantListEvent_REFRESH_PARTICIPANT_LIST', res);
    });
  }
}

export default PracticeRoomHelper;
