import update from 'immutability-helper';
import {
  RESET_FREE_UPLOAD_VIDEO_STATE,
  UPDATE_ONE_MIN_VIDEO_STATE,
  UPDATE_VIDEO_STATE,
  UPDATE_TRENDING_VIDEOS_STATE,
  UPDATE_SEARCH_VIDEOS_STATE,
  RESET_SEARCH_VIDEO_STATE,
  UPDATE_TRENDING_VIDEO_ITEM_STATE,
  UPDATE_HOME_VIDEO_ITEM_STATE,
  UPDATE_SEARCH_VIDEO_ITEM_STATE,
  LOGOUT_USER_SUCCESS,
  UPDATE_MY_UPLOADED_VIDEO_ITEM_STATE,
  UPDATE_MY_LIKED_VIDEO_ITEM_STATE,
  UPDATE_MY_UPLOADED_VIDEOS_STATE,
  UPDATE_MY_LIKED_VIDEOS_STATE,
  UPDATE_USER_UPLOADED_VIDEOS_STATE,
  UPDATE_USER_UPLOADED_VIDEO_ITEM_STATE,
  RESET_USER_YOUBRIO_PROFILE_STATE,
  UPDATE_DEEP_LINK_VIDEO_ITEM_STATE,
  RESET_DEEP_LINK_VIDEO_ITEM_STATE,
  SHOW_VIDEO_MODAL,
  HIDE_VIDEO_MODAL,
} from '../actions/types';

  const INITIAL_VIDEO_MODAL_STATE = {
    mode: 'success',
    title: '',
    content: '',
    visible: false
  };

  const INITIAL_TRENDING_VIDEOS_STATE = {
    trendingList: [],
    currentPage: 1,
    maxPage: 0,
    isLoading: false,
    isNewPageLoading: false
  };

  const INITIAL_SEARCH_VIDEOS_STATE = {
    searchTerm: '',
    searchedTerm: '',
    searchList: [],
    maxPage: 0,
    isLoading: false,
    isNewPageLoading: false
  };

  const MY_UPLOADED_VIDEOS_INITIAL_STATE = {
    uploadedList: [],
    maxPage: 0,
    isLoading: false,
    isNewPageLoading: false
  };

  const MY_LIKED_VIDEOS_INITIAL_STATE = {
    likedList: [],
    maxPage: 0,
    isLoading: false,
    isNewPageLoading: false
  };

  const USER_UPLOADED_VIDEOS_INITIAL_STATE = {
    userUploadedList: [],
    maxPage: 0,
    isLoading: false,
    isNewPageLoading: false
  };

  const INITIAL_DEEP_LINK_VIDEO_ITEM_STATE = {
    video_id: null,
    user_id: null,
    video_title: '',
    performer_name: '',
    synopsis: '',
    video_url: '',
    video_image: '',
    video_url_zhCN: '',
    view_count: null,
    like_count: null,
    vote_count: '',
    city: '',
    country: '',
    publish_date: '',
    event_name: '',
    event_locatio: '',
    event_date: '',
    sub_event_name: '',
    grades: '',
    musicType: '',
    thumbnail_flag: '',
    playback_url: '',
    thumbnail: '',
    liked: false,
    userAvatar: '',
    user_level: {
      id: null,
      level: null,
      levelName: ''
    }
  };

  // const FREE_UPLOAD_VIDEO_INITIAL_STATE = {
  //   title: '',
  //   synopsis: '', // video description
  //   videoFilePath: '',
  //   videoFileHash: '',
  //   videoThumbnailPath: '',
  //   videoLink: '',
  //   publicFlag: 1,
  //   error: {
  //     video: '',
  //     title: '',
  //     // selectedInstrument: '',
  //     synopsis: '',
  //   }
  // };

  const INITIAL_ONE_MIN_VIDEO_STATE = {
    oneMinVidList: [],
    currentPage: 1,
    lastPage: 1,
    isLoading: false,
    isNewPageLoading: false
  };

const INITIAL_STATE = {
  videoModal: INITIAL_VIDEO_MODAL_STATE,
  trendingVideos: INITIAL_TRENDING_VIDEOS_STATE,
  searchVideos: INITIAL_SEARCH_VIDEOS_STATE,
  homeVideoList: [],
  myUploadedVideos: MY_UPLOADED_VIDEOS_INITIAL_STATE,
  myLikedVideos: MY_LIKED_VIDEOS_INITIAL_STATE,
  userUploadedVideos: USER_UPLOADED_VIDEOS_INITIAL_STATE,
  deepLinkVideoItem: INITIAL_DEEP_LINK_VIDEO_ITEM_STATE,
  isProcessingNewVideo: false,
  // uploadVideo: FREE_UPLOAD_VIDEO_INITIAL_STATE,
  oneMinVideo: INITIAL_ONE_MIN_VIDEO_STATE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_VIDEO_STATE:
      return { ...state, [action.payload.prop]: action.payload.value };
    case SHOW_VIDEO_MODAL:
      return { ...state, videoModal: { title: action.payload.title, content: action.payload.content, mode: action.payload.mode, visible: true } };
    case HIDE_VIDEO_MODAL:
      return { ...state, videoModal: { ...state.eventModal, visible: false } };
    // case UPDATE_FREE_UPLOAD_VIDEO_STATE:
    //   return { ...state, uploadVideo: { ...state.uploadVideo, [action.payload.prop]: action.payload.value } };
    case UPDATE_ONE_MIN_VIDEO_STATE:
      return { ...state, oneMinVideo: { ...state.oneMinVideo, [action.payload.prop]: action.payload.value } };
    // case UPDATE_FIELD_ERROR:
    //   return { ...state, uploadVideo: { ...state.uploadVideo, error: { ...state.uploadVideo.error, [action.payload.prop]: action.payload.error } } };
    case UPDATE_TRENDING_VIDEOS_STATE:
      return { ...state, trendingVideos: { ...state.trendingVideos, [action.payload.prop]: action.payload.value } };
    case UPDATE_SEARCH_VIDEOS_STATE:
      return { ...state, searchVideos: { ...state.searchVideos, [action.payload.prop]: action.payload.value } };
    case RESET_SEARCH_VIDEO_STATE:
      return { ...state, searchVideos: INITIAL_SEARCH_VIDEOS_STATE };
    case RESET_FREE_UPLOAD_VIDEO_STATE:
      return { ...state }; // , uploadVideo: FREE_UPLOAD_VIDEO_INITIAL_STATE };
    case UPDATE_TRENDING_VIDEO_ITEM_STATE:
      if (state.trendingVideos.trendingList[action.payload.index]) {
        return update(state, {
          trendingVideos: {
            trendingList: {
              [action.payload.index]: {
                [action.payload.prop]: { $set: action.payload.value }
              }
            }
          }
        });
      }
      return state;
    case UPDATE_HOME_VIDEO_ITEM_STATE:
      if (state.homeVideoList[action.payload.index]) {
        return update(state, {
          homeVideoList: {
            [action.payload.index]: {
              [action.payload.prop]: { $set: action.payload.value }
            }
          }
        });
      }
      return state;
    case UPDATE_SEARCH_VIDEO_ITEM_STATE:
      if (state.searchVideos.searchList[action.payload.index]) {
        return update(state, {
          searchVideos: {
            searchList: {
              [action.payload.index]: {
                [action.payload.prop]: { $set: action.payload.value }
              }
            }
          }
        });
      }
      return state;
    case UPDATE_MY_UPLOADED_VIDEO_ITEM_STATE:
      if (state.myUploadedVideos.uploadedList[action.payload.index]) {
        return update(state, {
          myUploadedVideos: {
            uploadedList: {
              [action.payload.index]: {
                [action.payload.prop]: { $set: action.payload.value }
              }
            }
          }
        });
      }
      return state;
    case UPDATE_MY_LIKED_VIDEO_ITEM_STATE:
      if (state.myLikedVideos.likedList[action.payload.index]) {
        return update(state, {
          myLikedVideos: {
            likedList: {
              [action.payload.index]: {
                [action.payload.prop]: { $set: action.payload.value }
              }
            }
          }
        });
      }
      return state;
    case UPDATE_MY_UPLOADED_VIDEOS_STATE:
      return { ...state, myUploadedVideos: { ...state.myUploadedVideos, [action.payload.prop]: action.payload.value } };
    case UPDATE_MY_LIKED_VIDEOS_STATE:
      return { ...state, myLikedVideos: { ...state.myLikedVideos, [action.payload.prop]: action.payload.value } };
    case UPDATE_USER_UPLOADED_VIDEOS_STATE:
      return { ...state, userUploadedVideos: { ...state.userUploadedVideos, [action.payload.prop]: action.payload.value } };
    case UPDATE_USER_UPLOADED_VIDEO_ITEM_STATE:
      if (state.userUploadedVideos.userUploadedList[action.payload.index]) {
        return update(state, {
          userUploadedVideos: {
            userUploadedList: {
              [action.payload.index]: {
                [action.payload.prop]: { $set: action.payload.value }
              }
            }
          }
        });
      }
      return state;
    case RESET_USER_YOUBRIO_PROFILE_STATE:
      return { ...state, userUploadedVideos: USER_UPLOADED_VIDEOS_INITIAL_STATE };
    case RESET_DEEP_LINK_VIDEO_ITEM_STATE:
      return { ...state, deepLinkVideoItem: INITIAL_DEEP_LINK_VIDEO_ITEM_STATE };
    case UPDATE_DEEP_LINK_VIDEO_ITEM_STATE:
      return { ...state, deepLinkVideoItem: { ...state.deepLinkVideoItem, [action.payload.prop]: action.payload.value } };
    case LOGOUT_USER_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
