import {
  UPDATE_FUND_STATE,
  UPDATE_FUND_SUMMARY_STATE,
  LOGOUT_USER_SUCCESS
} from '../actions/types';

const INITIAL_FUND_SUMMARY_STATE = {
  id: '',
  balance: 0,
  currencyCode: '',
  isBalanceLoading: false,
  recentTxnHistoryList: [],
  moreTxnHistory: false,
  isTxnHistoryLoading: false,
  topUpParams: {
    minTopUpAmt: 10,
    maxTopUpAmt: 5000,
    defaultTopUpAmt: 500,
    walletBalanceCap: 10000,
    paypalSurchargeRate: 0,
    defaultSurchargeRate: 0
  }
};

const INITIAL_STATE = {
  fundSummary: INITIAL_FUND_SUMMARY_STATE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UPDATE_FUND_STATE:
    return { ...state, [action.payload.prop]: action.payload.value };
  case UPDATE_FUND_SUMMARY_STATE:
    return { ...state, fundSummary: { ...state.fundSummary, [action.payload.prop]: action.payload.value } };
  case LOGOUT_USER_SUCCESS:
    return INITIAL_STATE;
  default:
    return state;
  }
};
