import YoubrioApiHelper from '../helpers/YoubrioApiHelper';
import YoubrioWebHelper from '../helpers/YoubrioWebHelper';

export const getPracticeRoomFiles = ({ practiceRoomProfileId }) => {
  return YoubrioApiHelper.get(`practice-room-files?prpId=${practiceRoomProfileId}`, true);
};

export const uploadPracticeRoomFiles = ({ filePaths, practiceRoomProfileId, onUploadProgress }) => {
  const uploadEndpoint = YoubrioWebHelper.getEndpointUrl('practice-room-files');
  var params = new FormData();
  params.append('practiceRoomProfileId', practiceRoomProfileId);

  if (filePaths && filePaths.length > 0) {
    filePaths.forEach((filePath) => {
      params.append('files', filePath);
   });
  }

  return YoubrioApiHelper.post(
    uploadEndpoint,
    params,
    true,
    'multipart/form-data',
    onUploadProgress
  );
};

export const deletePracticeRoomFiles = ({ practiceRoomFileIds }) => {
  const params = {
    practiceRoomFileIds
  };
  return YoubrioApiHelper.post('practice-room-files/delete', params, true);
};

export const updatePracticeRoomFile = ({ practiceRoomFileId, displayName }) => {
  const params = {
    displayName
  };
  return YoubrioApiHelper.post(`practice-room-file/${practiceRoomFileId}`, params, true);
};


