import {
  UPDATE_LEGAL_STATEMENT_STATE,
  LOGOUT_USER_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
  enrolExamDisclaimerText: '',
  enrolPieceEvaluationDisclaimerText: '',
  tnCText: '',
  appealOptions: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_LEGAL_STATEMENT_STATE:
      return { ...state, [action.payload.prop]: action.payload.value };
    case LOGOUT_USER_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
