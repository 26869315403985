import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { YoubrioCalendar } from '../common';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
// import AppHelper from '../../helpers/AppHelper';

const useStyles = makeStyles(() => ({
  childContainer: {
    marginTop: 40,
  }
}));

const ScheduleScreen = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('youbrio') + " | " + t('calendar.calendar');
  }, []);

  return (
    <Container>
      <div className={classes.childContainer}>
        <YoubrioCalendar />
      </div>
    </Container>
  );
};

export default ScheduleScreen;
