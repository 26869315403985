import {
  UPDATE_ARTICLE_STATE,
  UPDATE_ARTICLE_LIST_STATE,
  UPDATE_OPENED_ARTICLE_STATE,
  RESET_OPENED_ARTICLE_STATE,
  RESET_ARTICLE_STATE
} from '../actions/types';

const INITIAL_ARTICLE_RESULT_STATE = {
  isLoading: false,
  list: [],
  filteredList: []
};

const INITIAL_OPENED_ARTICLE_STATE = {
  isLoading: false,
  article: {
    heading: '',
    subHeading: '',
    content: '',
    heading_zh_CN: '',
    subHeading_zh_CN: '',
    content_zh_CN: '',
    sources: []
  }
};

const INITIAL_STATE = {
  articleList: INITIAL_ARTICLE_RESULT_STATE,
  openedArticle: INITIAL_OPENED_ARTICLE_STATE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_ARTICLE_STATE:
      return { ...state, [action.payload.prop]: action.payload.value };
    case UPDATE_ARTICLE_LIST_STATE:
      return { ...state, articleList: { ...state.articleList, [action.payload.prop]: action.payload.value } };
    case UPDATE_OPENED_ARTICLE_STATE:
      return { ...state, openedArticle: { ...state.openedArticle, [action.payload.prop]: action.payload.value } };
    case RESET_OPENED_ARTICLE_STATE:
      return { ...state, openedArticle: INITIAL_OPENED_ARTICLE_STATE };
    case RESET_ARTICLE_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
