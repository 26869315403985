import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import RegistrationReducer from './RegistrationReducer';
import CommonReducer from './CommonReducer';
import UserReducer from './UserReducer';
import ExamReducer from './ExamReducer';
import PaymentReducer from './PaymentReducer';
import SyllabusReducer from './SyllabusReducer';
import LegalStatementReducer from './LegalStatementReducer';
import MusicLibraryReducer from './MusicLibraryReducer';
import PieceEvaluationReducer from './PieceEvaluationReducer';
import EventReducer from './EventReducer';
import VideoReducer from './VideoReducer';
import CertificateReducer from './CertificateReducer';
import YoubrioProfileReducer from './YoubrioProfileReducer';
import FindTeacherReducer from './FindTeacherReducer';
import FundReducer from './FundReducer';
import NotificationReducer from './NotificationReducer';
import LessonReducer from './LessonReducer';
import LessonSessionReducer from './LessonSessionReducer';
import OrganisationReducer from './OrganisationReducer';
import ArticleReducer from './ArticleReducer';
import ChatReducer from './ChatReducer';
import PracticeRoomReducer from './PracticeRoomReducer';
import PracticeRoomPassReducer from './PracticeRoomPassReducer';
import PracticeRoomTeacherReducer from './PracticeRoomTeacherReducer';
import PracticeTutorReviewReducer from './PracticeTutorReviewReducer';
import OrderReducer from './OrderReducer';
import DialogReducer from './DialogReducer';
import RecurringBillReducer from './RecurringBillReducer';
import GatewayPaymentReducer from './GatewayPaymentReducer';

export default combineReducers({
  auth: AuthReducer,
  registration: RegistrationReducer,
  common: CommonReducer,
  user: UserReducer,
  exam: ExamReducer,
  payment: PaymentReducer,
  syllabus: SyllabusReducer,
  legalStatement: LegalStatementReducer,
  musicLibrary: MusicLibraryReducer,
  pieceEvaluation: PieceEvaluationReducer,
  event: EventReducer,
  video: VideoReducer,
  cert: CertificateReducer,
  youbrioProfile: YoubrioProfileReducer,
  findTeacher: FindTeacherReducer,
  fund: FundReducer,
  notification: NotificationReducer,
  lesson: LessonReducer,
  lessonSession: LessonSessionReducer,
  organisation: OrganisationReducer,
  article: ArticleReducer,
  chat: ChatReducer,
  practiceRoom: PracticeRoomReducer,
  practiceRoomPass: PracticeRoomPassReducer,
  practiceRoomTeacher: PracticeRoomTeacherReducer,
  practiceTutorReview: PracticeTutorReviewReducer,
  order: OrderReducer,
  dialog: DialogReducer,
  recurringBill: RecurringBillReducer,
  gatewayPayment: GatewayPaymentReducer
});
