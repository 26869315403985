import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import {
  DialogContent,
  Grid,
  Typography,
  Box,
  Avatar,
  Fade,
  CircularProgress,
  Badge,
  Button,
  List,
  Hidden,
  Divider,
  Fab,
  // Radio,
} from '@material-ui/core';
import {
  Select,
  Form,
  // Layout,
  Input as EInput,
  Button as EButton,
  Radio as ERadio,
} from 'element-react';
import 'element-theme-default';

import {
  // Close as CloseIcon,
  Photo as PhotoIcon,
  CloudUpload as CloudUploadIcon,
  Person as PersonIcon,
  // Edit as EditIcon,
  // PersonOutline as PersonOutlineIcon,
  // Email as EmailIcon,
  // Warning as WarningIcon,
  // Check as CheckIcon,
  // AccessTime as AccessTimeIcon,
  // DeleteForever as DeleteForeverIcon,
  // Language as LanguageIcon,
  // MenuBook as MenuBookIcon,
  // Done as DoneIcon,
  // Send as SendIcon,
} from '@material-ui/icons';
import { withTranslation} from 'react-i18next';

import {
  // getUserProfile,
  saveUserProfile,
  // saveStudentProfile,
  // getYoubrioProfile,
  // getUserYoubrioProfile,
  // checkEmailExistence,
  // changeUserEmail,
  updateUserProfileItem,
  updateProfileDescription,
  getQuestions,
  updateAnswers,
} from '../../api';
import GLOBAL from '../../helpers/GLOBAL';
import ChatHelper from '../../helpers/ChatHelper';
// import AppHelper from "../../helpers/AppHelper";
const CONSTANT = require('../../helpers/ConstantHelper');

// const LanguageSelects = [
//   'en',
//   'zh',
//   'jp',
//   'fr',
//   'sp',
//   'ru',
//   'it',
//   'ge',
// ];
// 
// const SubjectSelects = [
//   'piano',
//   'violin',
//   'altoSaxophone',
//   'flute',
//   'cello',
//   'viola',
//   'doubleBass',
//   'vocal',
//   'clarinet',
//   'oboe',
// ];

// const GenderSelects = [
//   'male',
//   'female',
//   'others'
// ];


const styles = (theme) => ({
  dialogContent: {
    paddingTop: theme.spacing(2),
  },

  badge: {
    top: theme.spacing(2),
    right: -theme.spacing(2),
  },

  loadingBadge: {
    top: '50%',
    right: '50%',
  },

  avatar: {
    marginRight: 'auto',
    marginLeft: 'auto',

    width: theme.spacing(14),
    height: theme.spacing(14),
  },

  nameInitials: {
    cursor: 'default',
  },

  personIcon: {
    fontSize: theme.spacing(7),
  },

  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),

    minHeight: 'initial',
  },

  FormControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  smallIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  }
});

const initialState = {
  profileCompletion: 0,
  averageRating: 0,
  avatar: null,
  avatarUrl: '',
  firstName: '',
  lastName: '',
  username: '',
  emailAddress: '',
  about: '',
  languages: [],
  subjects: [],
  languagesAnswers: [],
  subjectsAnswers: [],
  profileRole: '',
  languagesAnswersLoading: false,
  subjectsAnswersLoading: false,
  // not used
  subscribed: true,
  country: '',
  city: '',
  showingField: '',
  uploadAvatarFile: null,
  performingAction: false,
  loadingAvatar: false,
  sentVerificationEmail: false,
  errors: null,
};

class AccountTab extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  async componentWillMount() {
    try {
      this.setState({
        languagesAnswersLoading: true,
      });
      const res = await getQuestions(1);
      if (res && res.data && res.data.questionList) {
        this.setState({
          languagesAnswers: res.data.questionList.answer,
        });
      } else {
        console.info('error=', res);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({
        languagesAnswersLoading: false,
      });
    }
    try {
      this.setState({
        subjectsAnswersLoading: true,
      });
      const res = await getQuestions(4);
      if (res && res.data && res.data.questionList) {
        this.setState({
          subjectsAnswers: res.data.questionList.answer
        });
      } else {
        console.info('error=', res);
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({
        subjectsAnswersLoading: false,
      });
    }
  }

  requestUpdateUserProfile = async(updates, upgradeAccountRole) => {
    const user = GLOBAL.get('user');
    const newUser = {...user, ...updates};
    const { openSnackbar, closeSnackbar } = this.props;
    closeSnackbar();

    const response = await saveUserProfile(newUser, upgradeAccountRole);
    if (response && response.data && response.data.data) {
      GLOBAL.set('user', response.data.data);
      console.log('--setUser=', user);
      openSnackbar(
        'success',
        'Success'
      );
    } else {
      openSnackbar(
        'error',
        'Fail'
      );
    }
    if (upgradeAccountRole === CONSTANT.STRING.ROLE.TEACHER) {
      ChatHelper.sendNewTeacherAutoMessage();
    } else if (upgradeAccountRole === CONSTANT.STRING.ROLE.STUDENT) {
      ChatHelper.sendNewStudentAutoMessage();
    }
    return response.data.data;
  }

  requestUpdateLanguageAnswer = async() => {
    const { openSnackbar, closeSnackbar } = this.props;
    const { languages, languagesAnswers } = this.state;
    var languagesAnswersMap = {};
    languagesAnswers.forEach(item => {
      languagesAnswersMap[item.answer] = item;
    });
    var selectedLanguages = [];
    languages.forEach(id => {
      selectedLanguages.push(languagesAnswersMap[id]);
    });
    closeSnackbar();
    const response = await updateAnswers(1, selectedLanguages);
    if (response && response.data && response.data.profileAnswers) {
      const user = GLOBAL.get('user');
      user.profileAnswers = response.data.profileAnswers;
      GLOBAL.set('user', user);
      console.log('--setUser=', user);
      openSnackbar(
        'success',
        'success'
      );
      return response.data;
    } else {
      openSnackbar(
        'error',
        'fail'
      );
    }
  }

  requestUpdateSubjectAnswer = async() => {
    const { openSnackbar, closeSnackbar } = this.props;
    const { subjects, subjectsAnswers } = this.state;
    var subjectsAnswersMap = {};
    subjectsAnswers.forEach(item => {
      subjectsAnswersMap[item.answer] = item;
    });
    var selectedSubjects = [];
    subjects.forEach(id => {
      selectedSubjects.push(subjectsAnswersMap[id]);
    });
    closeSnackbar();
    console.log('---selectedSubjects=', selectedSubjects);
    const response = await updateAnswers(4, selectedSubjects);
    if (response && response.data.profileAnswers) {
      const user = GLOBAL.get('user');
      user.profileAnswers = response.data.profileAnswers;
      GLOBAL.set('user', user);
      console.log('--setUser=', user);
      return response.data;
      // openSnackbar(
      //   'success',
      //   'Success'
      // );
    } else {
      openSnackbar(
        'error',
        'Fail'
      );
    }
  }

  requestUpdateAvatar = async() => {
    const { openSnackbar, closeSnackbar } = this.props;
    const { uploadAvatarFile } = this.state;
    console.log('---uploadAvatarFile=', uploadAvatarFile);
    closeSnackbar();
    const response = await updateUserProfileItem({
      avatarUrl: uploadAvatarFile
    });
    if (response && response.data.profileAnswers) {
      const user = GLOBAL.get('user');
      user.profileAnswers = response.data.profileAnswers;
      GLOBAL.set('user', user);
      console.log('--setUser=', user);
      return response.data;
      // openSnackbar(
      //   'success',
      //   'Success'
      // );
    } else {
      openSnackbar(
        'error',
        'Fail'
      );
    }
  }

  getNameInitialsOrIcon = () => {
    const user = GLOBAL.get('user'); //const { user } = this.props;

    if (!user) {
      return null;
    }

    const { classes, userData } = this.props;

    if (!userData) {
      return <PersonIcon className={classes.personIcon} />;
    }

    // const nameInitials = authentication.getNameInitials({
    //   ...user,
    //   ...userData,
    // });
    const nameInitials = 'nameInitials';

    if (nameInitials) {
      return (
        <Typography className={classes.nameInitials} variant="h2">
          {nameInitials}
        </Typography>
      );
    }

    return <PersonIcon className={classes.personIcon} />;
  };

  onChangeAvatar = (e) => {
    const file = e.target.files[0];
    // console.log('----file=', file);
    this.setState({
      uploadAvatarFile: file
    });
  }

  uploadAvatar = async (e) => {
    const file = e.target.files[0];
    // console.log('----file=', file);
    this.setState({
      uploadAvatarFile: file
    });
    // const { avatar } = this.state;

    this.setState(
      {
        performingAction: true,
        loadingAvatar: true,
      }
    );

    try {
      const role = this.state.profileRole;
      await this.requestUpdateUserProfile({avatarUrl: file}, role);
    } catch (reason) {
      const code = reason.code;
      const message = reason.message;
      switch (code) {
      default:
        this.props.openSnackbar(message);
      }
    } finally {
      this.setState({
        performingAction: false,
        loadingAvatar: false,
      });
    }
  };

  submitAbout = async () => {
    const { about } = this.state;
    let user = GLOBAL.get('user');
    if (about === user.description) {
      return;
    }

    this.setState(
      {
        errors: null,
        performingAction: true,
      }
    );

    try {
      // eslint-disable-next-line no-unused-vars
      const res = await updateProfileDescription({description: about});
      const user = GLOBAL.get('user');
      user.description = about;
      GLOBAL.set('user', user);
    } catch (reason) {
      console.error('---request-fail', reason);
      const code = reason.code;
      const message = reason.message;
      switch (code) {
      default:
        this.props.openSnackbar(message);
        return;
      }
    } finally {
      this.setState({
        performingAction: false,
      });
    }
  };
  
  submitLanguages = async () => {
    // const { languages } = this.state;
    this.setState(
      {
        errors: null,
        performingAction: true,
      }
    );
    try {
      await this.requestUpdateLanguageAnswer();
    } catch (reason) {
      const code = reason.code;
      const message = reason.message;
      switch (code) {
      default:
        this.props.openSnackbar(message);
        return;
      }
    } finally {
      this.setState({
        performingAction: false,
      });
    }
  };
  
  submitSubjects = async () => {
    const { subjects } = this.state;
    console.log('---subjects=', subjects);

    this.setState(
      {
        errors: null,
        performingAction: true,
      }
    );
    try {
      await this.requestUpdateSubjectAnswer();
    } catch (reason) {
      const code = reason.code;
      const message = reason.message;
      switch (code) {
      default:
        this.props.openSnackbar(message);
        return;
      }
    } finally {
      this.setState({
        performingAction: false,
      });
    }
  };

  handleAvatarChange = (event) => {
    if (!event) {
      return;
    }

    const files = event.target.files;

    if (!files) {
      return;
    }

    const avatar = files[0];

    if (!avatar) {
      return;
    }

    const fileTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/webp',
      'image/svg+xml',
    ];

    if (!fileTypes.includes(avatar.type)) {
      return;
    }

    if (avatar.size > 20 * 1024 * 1024) {
      return;
    }

    this.setState({
      avatar: avatar,
      avatarUrl: URL.createObjectURL(avatar),
    });
  };

  handleLanguagesChange = (languages) => {
    this.setState({
      languages: languages,
    });
  };
  
  handleSubjectsChange = (subjects) => {
    console.log('----subjects=', subjects);
    this.setState({
      subjects: subjects,
    });
  };

  handleAboutChange = (about) => {
    this.setState({
      about
    });
  }

  handleRoleChange = (role) => {
    this.setState({
      profileRole: role,
    });
  }
  
  // eslint-disable-next-line no-unused-vars
  onClickSubmit = (event) => {
    console.log('--submit-state=', this.state);
    this.submitAbout();
    this.submitLanguages();
    this.submitSubjects();
  }

  renderForm() {
    // const { t } = this.props;
    const role = GLOBAL.get('role');
    const {
      // showingField,
      // performingAction,
      // errors,
      languagesAnswers,
      subjectsAnswers,
    } = this.state;
    
    // const filteredProfileAnswers = user && user.profileAnswers && user.profileAnswers.filter(x => x.teach_question === 'Languages');
    // const userLanguages = filteredProfileAnswers && filteredProfileAnswers.length > 0 && filteredProfileAnswers[0].answer.map(x => x && x.answer);

    const renderSubjects = () => {
      return (
        <Form.Item label="Subjects">
          <Select
            multiple
            placeholder="You should choose at least one"
            onChange={this.handleSubjectsChange}
            value={this.state.subjects}
          >
            {subjectsAnswers.map((item)=> (
              <Select.Option key={item.id} value={item.answer}>
                <span style={{float: 'left'}}>{item.answer}</span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    };
    
    return (
      <>
        <Form model={this.state} labelWidth="80">
          <Form.Item label="Profile Role">
            <ERadio.Group value={this.state.profileRole} onChange={this.handleRoleChange}>
              <ERadio value="Teacher" disabled={role}>Teacher</ERadio>
              <ERadio value="Student" disabled={role}>Student</ERadio>
            </ERadio.Group>
          </Form.Item>
          <Form.Item label="About">
            <EInput value={this.state.about} type="textarea" autosize={{ minRows: 2}} onChange={this.handleAboutChange} placeholder="Please text your bio"></EInput>
          </Form.Item>
          {
            !this.state.languagesAnswersLoading
            && <Form.Item label="Languages">
              <Select
                value={this.state.languages}
                onChange={this.handleLanguagesChange}
                placeholder="You should choose at least one"
                multiple
              >
                {languagesAnswers.map((item)=> (
                  <Select.Option key={item.id} value={item.answer}>
                    <span style={{float: 'left'}}>{item.answer}</span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          }
          {
            // renderSubjects()
          }
          <Form.Item>
            <EButton type="primary" loading={this.state.performingAction} onClick={this.onClickSubmit}>Submit</EButton>
          </Form.Item>
        </Form>
      </>
    );
  }
  
  renderAvatar() {

  }

  renderRating() {
    const {
      averageRating,
    } = this.state;

    return (
      <div>
        <Typography variant="body1">Average Rating</Typography>

        {isNaN(averageRating) && (
          <Typography color="error" variant="h5">
            -
          </Typography>
        )}

        {!isNaN(averageRating) && averageRating < 3 && (
          <Typography color="error" variant="h5">
            {averageRating}
          </Typography>
        )}

        {!isNaN(averageRating) && averageRating >= 4.5 && (
          <Typography color="primary" variant="h5">
            {averageRating}
          </Typography>
        )}

        {!isNaN(averageRating) && averageRating >= 3 && averageRating < 4.5 && (
          <Typography color="secondary" variant="h5">
            {averageRating}
          </Typography>
        )}
 
      </div>
    );
  }
  
  render() {
    // Styling
    const { classes } = this.props;

    // Properties
    const user = GLOBAL.get('user'); //const { user } = this.props;
    // console.log('----render-user=', user);

    // Events
    const {
      avatarUrl,
      // profileCompletion,
      // showingField,
      performingAction,
      loadingAvatar,
      avatar,
      // errors,
    } = this.state;

    return (
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Box mb={2}>
          <Hidden xsDown>
            <Grid alignItems="center" container>
              <Grid item xs>
                <Box textAlign="center">
                  <Box mb={1.5}>
                    {avatar && avatarUrl && (
                      <Badge
                        classes={{ badge: classes.badge }}
                      >
                        {loadingAvatar && (
                          <Badge
                            classes={{ badge: classes.loadingBadge }}
                            badgeContent={
                              <Fade
                                style={{ transitionDelay: "1s" }}
                                in={loadingAvatar}
                                unmountOnExit
                              >
                                <CircularProgress size={120} thickness={1.8} />
                              </Fade>
                            }
                          >
                            <Avatar
                              className={classes.avatar}
                              alt="Avatar"
                              src={avatarUrl}
                            />
                          </Badge>
                        )}

                        {!loadingAvatar && (
                          <Avatar
                            className={classes.avatar}
                            alt="Avatar"
                            src={avatarUrl}
                          />
                        )}
                      </Badge>
                    )}

                    {!avatar && !avatarUrl && (
                      <>
                        {user.avatarUrl && (
                          <Badge
                            classes={{ badge: classes.badge }}
                          >
                            {loadingAvatar && (
                              <Badge
                                classes={{ badge: classes.loadingBadge }}
                                badgeContent={
                                  <Fade
                                    style={{ transitionDelay: "1s" }}
                                    in={loadingAvatar}
                                    unmountOnExit
                                  >
                                    <CircularProgress
                                      size={120}
                                      thickness={1.8}
                                    />
                                  </Fade>
                                }
                              >
                                <Avatar
                                  className={classes.avatar}
                                  alt="Avatar"
                                  src={user.avatarUrl}
                                />
                              </Badge>
                            )}

                            {!loadingAvatar && (
                              <Avatar
                                className={classes.avatar}
                                alt="Avatar"
                                src={user.avatarUrl}
                              />
                            )}
                          </Badge>
                        )}

                        {!user.avatarUrl && (
                          <>
                            {loadingAvatar && (
                              <Badge
                                classes={{ badge: classes.loadingBadge }}
                                badgeContent={
                                  <Fade
                                    style={{ transitionDelay: "1s" }}
                                    in={loadingAvatar}
                                    unmountOnExit
                                  >
                                    <CircularProgress
                                      size={120}
                                      thickness={1.8}
                                    />
                                  </Fade>
                                }
                              >
                                <Avatar className={classes.avatar} alt="Avatar">
                                  {this.getNameInitialsOrIcon()}
                                </Avatar>
                              </Badge>
                            )}

                            {!loadingAvatar && (
                              <Avatar className={classes.avatar} alt="Avatar">
                                {this.getNameInitialsOrIcon()}
                              </Avatar>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Box>

                  {avatar && avatarUrl && (
                    <>
                      <label>
                        <input
                          style={{ display: "none"}}
                          id="upload-photo"
                          name="upload-photo"
                          type="file"
                          onChange={this.uploadAvatar}
                        />
                        <Fab
                          color="primary"
                          size="small"
                          component="span"
                          aria-label="add"
                          variant="extended"
                        >
                          <CloudUploadIcon /> Upload
                        </Fab>
                      </label>
                    </>
                  )}

                  {!avatar && !avatarUrl && (
                    <>
                      <input
                        id="avatar-input"
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={this.handleAvatarChange}
                      />

                      <label htmlFor="avatar-input">
                        <Button
                          color="primary"
                          component="span"
                          disabled={performingAction}
                          startIcon={<PhotoIcon />}
                          variant="contained"
                        >
                          Choose...
                        </Button>
                      </label>
                    </>
                  )}
                </Box>
              </Grid>

              <Grid item xs>
                <Box textAlign="center">
                  { this.renderRating() }
                </Box>
              </Grid>
            </Grid>
          </Hidden>

          <Hidden smUp>
            <Box textAlign="center" mb={3}>
              <Box mb={1.5}>
                {avatar && avatarUrl && (
                  <Badge
                    classes={{ badge: classes.badge }}
                  >
                    {loadingAvatar && (
                      <Badge
                        classes={{ badge: classes.loadingBadge }}
                        badgeContent={
                          <Fade
                            style={{ transitionDelay: "1s" }}
                            in={loadingAvatar}
                            unmountOnExit
                          >
                            <CircularProgress size={120} thickness={1.8} />
                          </Fade>
                        }
                      >
                        <Avatar
                          className={classes.avatar}
                          alt="Avatar"
                          src={avatarUrl}
                        />
                      </Badge>
                    )}

                    {!loadingAvatar && (
                      <Avatar
                        className={classes.avatar}
                        alt="Avatar"
                        src={avatarUrl}
                      />
                    )}
                  </Badge>
                )}

                {!avatar && !avatarUrl && (
                  <>
                    {user.avatarUrl && (
                      <Badge
                        classes={{ badge: classes.badge }}
                      >
                        {loadingAvatar && (
                          <Badge
                            classes={{ badge: classes.loadingBadge }}
                            badgeContent={
                              <CircularProgress size={120} thickness={1.8} />
                            }
                          >
                            <Avatar
                              className={classes.avatar}
                              alt="Avatar"
                              src={user.avatarUrl}
                            />
                          </Badge>
                        )}

                        {!loadingAvatar && (
                          <Avatar
                            className={classes.avatar}
                            alt="Avatar"
                            src={user.avatarUrl}
                          />
                        )}
                      </Badge>
                    )}

                    {!user.avatarUrl && (
                      <>
                        {loadingAvatar && (
                          <Badge
                            classes={{ badge: classes.loadingBadge }}
                            badgeContent={
                              <Fade
                                style={{ transitionDelay: "1s" }}
                                in={loadingAvatar}
                                unmountOnExit
                              >
                                <CircularProgress size={120} thickness={1.8} />
                              </Fade>
                            }
                          >
                            <Avatar className={classes.avatar} alt="Avatar">
                              {this.getNameInitialsOrIcon()}
                            </Avatar>
                          </Badge>
                        )}

                        {!loadingAvatar && (
                          <Avatar className={classes.avatar} alt="Avatar">
                            {this.getNameInitialsOrIcon()}
                          </Avatar>
                        )}
                      </>
                    )}
                  </>
                )}
              </Box>

              {avatar && avatarUrl && (
                <Button
                  color="primary"
                  disabled={performingAction}
                  startIcon={<CloudUploadIcon />}
                  variant="contained"
                  onClick={this.uploadAvatar}
                >
                  Upload
                </Button>
              )}

              {!avatar && !avatarUrl && (
                <>
                  <input
                    id="avatar-input"
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={this.handleAvatarChange}
                  />

                  <label htmlFor="avatar-input">
                    <Button
                      color="primary"
                      component="span"
                      disabled={performingAction}
                      startIcon={<PhotoIcon />}
                      variant="contained"
                    >
                      Choose...
                    </Button>
                  </label>
                </>
              )}
            </Box>

            <Grid container>
              <Grid item xs>
                <Box textAlign="center">
                  { this.renderRating() }
                </Box>
              </Grid>
            </Grid>
          </Hidden>
        </Box>

        <List disablePadding>
          { this.renderForm() }
          <Box mt={1} mb={1}>
            <Divider light />
          </Box>

        </List>
      </DialogContent>
    );
  }

  componentDidMount() {
    const user = GLOBAL.get('user'); // const { user, userData } = this.props;
    const role = GLOBAL.get('role');

    const filteredProfileAnswers = user && user.profileAnswers && user.profileAnswers.filter(x => x.id === 1);
    const userLanguages = filteredProfileAnswers && filteredProfileAnswers.length > 0 && filteredProfileAnswers[0].answer.map(x => x && x.answer);
    const filteredProfileAnswersSubjects = user && user.profileAnswers && user.profileAnswers.filter(x => x.id === 4);
    const userSubjects = filteredProfileAnswersSubjects && filteredProfileAnswersSubjects.length > 0 && filteredProfileAnswersSubjects[0].answer.map(x => x && x.answer);
    this.setState({
      about: user.description,
      avatar: user.avatarName,
      avatarUrl: user.avatarUrl,
      email: user.email,
      averageRating: parseFloat(user.averageRating),
      languages: userLanguages,
      subjects: userSubjects,
      profileRole: role,
    });
  }

  componentWillUnmount() {
    const { avatarUrl } = this.state;

    if (avatarUrl) {
      URL.revokeObjectURL(avatarUrl);

      this.setState({
        avatarUrl: '',
      });
    }
  }
}

AccountTab.propTypes = {
  // Styling
  classes: PropTypes.object.isRequired,

  //// Properties
  // user: PropTypes.object.isRequired,
  // userData: PropTypes.object,

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(styles)(withTranslation()(AccountTab));
