import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function TimerIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M16 26.688q-4-0.125-6.625-2.75t-2.688-6.625q0.063-3.938 2.688-6.563t6.625-2.75q4 0.125 6.625 2.75t2.688 6.563q-0.063 4-2.688 6.625t-6.625 2.75zM25.375 9.875l1.875-1.938q-0.875-1-1.875-1.875l-1.875 1.938q-1.563-1.25-3.469-1.969t-4.031-0.719q-5.125 0.125-8.5 3.531t-3.5 8.469q0.125 5.125 3.5 8.5t8.5 3.5q5.125-0.125 8.5-3.5t3.5-8.5q0-2.125-0.688-4.031t-1.938-3.406zM14.688 18.688h2.625v-8h-2.625v8zM20 1.313h-8v2.688h8v-2.688z"></path>
    </SvgIcon>
  );
}

