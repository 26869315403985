import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CoffeeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M2.688 28h24v-2.688h-24v2.688zM26.688 10.688h-2.688v-4h2.688v4zM26.688 4h-21.375v13.313q0.063 2.313 1.563 3.813t3.813 1.563h8q2.25-0.063 3.75-1.563t1.563-3.813v-4h2.688q1.125 0 1.875-0.75t0.75-1.875v-4q0-1.188-0.75-1.938t-1.875-0.75z"></path>
    </SvgIcon>
  );
}

