import axios from 'axios';
import i18n from '../i18n';

import GLOBAL from './GLOBAL';
import config from '../config';
import { getStore } from '../Store';
import { updateCommonStates, logout } from '../actions';

const env = process.env.REACT_APP_ENV || 'dev';
const region = process.env.REACT_APP_REGION || 'global';

export const host = config.youbrioUrl[env][region];
export const hostRoot = config.youbrioUrl[env][region];

const UNAUTHORIZED = 401;
axios.interceptors.response.use(
  response => response,
  error => {
    console.log('---error.response=', error.response);
    if (!error.response) {
      Promise.reject(error);
    }
    const {status} = error.response;
    if (status === UNAUTHORIZED) {

      const whitelistedRoutes = config.youbrio_unauthorized_whitelist_routes;

      if (whitelistedRoutes.includes(YoubrioApiHelper.getApiRoute(error?.response?.config?.url))) {
        /* don't log out whitelisted routes */
        return;
      }

      // Once we intercept an 401 unauthorized token, we direct user to login again
      GLOBAL.logger.info('interceptor 401, user token expired');
      
      // Show a snackbar to user to show that his session has expired
      getStore().dispatch(updateCommonStates({
        prop: 'snackbar',
        value: {
          open: true,
          severity: 'warning',
          autoHideDuration: 5000,
          message: i18n.t('sessionExpired'),
        }
      }));
      setTimeout(() => {
        getStore().dispatch(logout());
      }, 3000);
    }
    return Promise.reject(error);
  }
);

class YoubrioApiHelper {
  static getLegacyPortalPath(route) {
    const url = `${config.youbrioUrl[env][`${region}Root`]}${route}`;

    return url;
  }

  static getApiRoute(route) {
    const url = route?.split('?')?.[0];
    const _route = url?.replace(host, '');

    return _route;
  }

  static getConfig(needToken) {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'dataType': 'json'
    };

    if (needToken) {
      headers['Authorization'] = `Bearer ${GLOBAL.get('token')}`;
    }

    return { headers };
  }

  static getFormDataConfig(needToken, onUploadProgress=null) {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',
      // 'dataType': 'json'
    };

    if (needToken) {
      headers['Authorization'] = `Bearer ${GLOBAL.get('token')}`;
    }

    var config = { headers };
    if (onUploadProgress) {
      config.onUploadProgress = onUploadProgress;
    }

    return config;
  }


  static getRegion() {
    return region;
  }

  static appendLangField(url) {
    let lang = 'en';
    const globalLang = GLOBAL.get('lang');
    if (globalLang === 'cn') {
      lang = 'zh_CN';
    } else if (globalLang === 'cnTW') {
      lang = 'zh_TW';
    }

    if (url.includes('lang=')) {
      return url;
    } else if (url.includes('?')) {
      return `${url}&lang=${lang}`;
    }
    return `${url}?lang=${lang}`;
  }

  static appendApiPlatformField(url) {
    if (url.includes('api_plat=')) {
      return url;
    } else if (url.includes('?')) {
      return `${url}&api_plat=web`;
    }
    return `${url}?api_plat=web`;
  }

  static get(path, needToken) {
    let url = host + path;
    url = YoubrioApiHelper.appendApiPlatformField(YoubrioApiHelper.appendLangField(url));

    // return axios.get(url, YoubrioApiHelper.getConfig(needToken));
    return this.request('get', url, needToken);
  }

  static post(path, data, needToken, contentType='application/json', onUploadProgress=null) {
    let url = host + path;
    url = YoubrioApiHelper.appendApiPlatformField(YoubrioApiHelper.appendLangField(url));

    // return axios.post(url, data, YoubrioApiHelper.getConfig(needToken));
    return this.request('post', url, needToken, data, contentType, onUploadProgress);
  }

  static postRoot(path, data, needToken) {
    let url = hostRoot + path;
    url = YoubrioApiHelper.appendApiPlatformField(YoubrioApiHelper.appendLangField(url));

    // return axios.post(url, data, YoubrioApiHelper.getConfig(needToken));
    return this.request('post', url, needToken, data);
  }

  static request(type, url, needToken, data=null, contentType='application/json', onUploadProgress=null) {
    let result;
    let config;
    // console.log('--request-url=', url, ',data=', data);
    if (contentType === 'application/json') {
      config = YoubrioApiHelper.getConfig(needToken);
    } else if (contentType === 'multipart/form-data') {
      config = YoubrioApiHelper.getFormDataConfig(needToken, onUploadProgress);
    } else {
      console.error('--wrong contentType=', contentType);
      config = YoubrioApiHelper.getConfig(needToken);
    }

    if (type === 'post') {
      result = axios.post(url, data, config);
    } else if(type === 'get') {
      result = axios.get(url, config);
    }
    result = result.then((response) => {
      if (response && response.status === 200) {
        const newToken = response.headers['authorization'];
        if (newToken) {
          GLOBAL.set('token', newToken.split(' ')[1]);
        }
      }
      return response;
    });
    return result;
  }
}

export default YoubrioApiHelper;
