import {
  UPDATE_MUSIC_LIBRARY_STATE,
  LOGOUT_USER_SUCCESS,
  UPDATE_MUSIC_LIBRARY_SEARCH_INPUT,
  RESET_MUSIC_LIBRARY_STATE,
  UPDATE_MUSIC_LIBRARY_SEARCH_FIELD_ERROR
} from '../actions/types';

const CONSTANT = require('../helpers/ConstantHelper');

const INITIAL_SEARCH_INPUT = {
  selectedSearchOption: CONSTANT.STRING.MUSIC_LIBRARY_SEARCH_OPTIONS.INSTRUMENT_GRADE,
  instrumentId: null,
  musicTypeId: null,
  gradeId: null,
  musicName: null,
  composer: null,
  musicTypes: [],
  instruments: [],
  grades: [],
  isRecommendedChecked: false,
  hasRecommended: false,
  error: {}
};

const INITIAL_PURCHASE_SHEET_MUSIC_STATE = {
  sheetMusicId: '',
  currencyCode: '',
  price: ''
};

const INITIAL_STATE = {
  searchField: INITIAL_SEARCH_INPUT,
  searchResult: [],
  youtubeEmbedUrlTemplate: '',
  purchaseSheetMusic: INITIAL_PURCHASE_SHEET_MUSIC_STATE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_MUSIC_LIBRARY_STATE:
      return { ...state, [action.payload.prop]: action.payload.value };
    case UPDATE_MUSIC_LIBRARY_SEARCH_INPUT:
      return { ...state, searchField: { ...state.searchField, [action.payload.prop]: action.payload.value } };
    case UPDATE_MUSIC_LIBRARY_SEARCH_FIELD_ERROR:
      return { ...state, searchField: { ...state.searchField, error: { ...state.searchField.error, [action.payload.prop]: action.payload.error } } };
    case RESET_MUSIC_LIBRARY_STATE:
      return INITIAL_STATE;
    case LOGOUT_USER_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
