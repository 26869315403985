import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Avatar, colors, ListItem } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  listItem: { marginTop: 8, borderRadius: 6, },
  root: {
    width: '100%',
    height: 64,
  },
  large: {
    width: 40,
    height: 40,
  },
  content: {
    flexGrow: 1,
    paddingLeft: 20,
    paddingRight: 20,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '1.2rem',
    maxHeight: '2.5rem',
    overflow: 'hidden',
    width: 140,
  },
  subtitle: {
    fontSize: 12,
  },
  unreadChip: {
    height: 24,
    width: 24,
    fontSize: 10,
    color: '#fff',
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.blue[500],
  },
}));

const RoomListItem = (props) => {
  const classes = useStyles();

  const {
    selected = false,
    title = '',
    subtitle = '',
    unread = 0,
    avatar = '',
  } = props;

  let unreadMsg = unread.toString();
  if (unread > 99) {
    unreadMsg = '99+';
  }

  return (
    <ListItem button className={`${classes.listItem}`} selected={selected} onClick={props.onClick || console.log('No-ops')}>
      <Grid container className={classes.root} alignItems="center">
        <Grid item>
          <Avatar
            alt={title}
            src={avatar}
            className={classes.large} 
          />
        </Grid>
        <Grid item className={classes.content}>
          <Grid container direction="column">
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.subtitle} color="textSecondary">{subtitle}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            { unread > 0 ? <div size="small" color="primary" className={classes.unreadChip}>{unreadMsg}</div> : null }
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default RoomListItem;