import ApiHelper from '../helpers/ApiHelper';
import AppHelper from '../helpers/AppHelper';
import YoubrioApiHelper from '../helpers/YoubrioApiHelper';

export const getPaymentHistories = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`paymenthistories?lang=${lang}`, true);
};

export const getExamPrice = ({ gradeId }) => {
  const physicalCountry = AppHelper.getPhysicalCountry();
  return ApiHelper.get(`exam/price?gradeid=${gradeId}&country=${physicalCountry}`, true);
};

// as usual, to interact with the server's rest api, create a new apiwrapper function
export const getBraintreeToken = () => {
  return ApiHelper.get('payment/token/braintree', true);
};

export const getExamOrPEIdByOutTradeNo = ({ outTradeNo, payMethod }) => {
  if (payMethod === 'WXCN') {
    return ApiHelper.get(`payment/wechatpay-cn/paid/id?outTradeNo=${outTradeNo}`, true);
  }
  return ApiHelper.get(`payment/alipay-cn/paid/id?outTradeNo=${outTradeNo}`, true);
};

export const getStudentEnrolExamAlipayOrderString = ({
  instrumentId,
  gradeId,
  affiliateCode,
  isSelectCnTranslation,
  cnTranslationFee,
  selectedExtraOptions
}) => {
  const params = {
      instrumentId,
      gradeId,
      affiliateCode: affiliateCode || '',
      txnType: 'EXEN',
      isSelectCnTranslation,
      cnTranslationFee: isSelectCnTranslation ? cnTranslationFee : null,
      selectedExtraOptions
  };
  return ApiHelper.post('payment/alipay-cn/orderinfo', params, true);
};

export const getTeacherEnrolExamAlipayOrderString = ({
  instrumentId,
  gradeId,
  affiliateCode,
  studentId,
  isSelectCnTranslation,
  cnTranslationFee,
  selectedExtraOptions
}) => {
  const params = {
      instrumentId,
      gradeId,
      affiliateCode: affiliateCode || '',
      studentId,
      txnType: 'EXEN',
      isSelectCnTranslation,
      cnTranslationFee: isSelectCnTranslation ? cnTranslationFee : null,
      selectedExtraOptions
  };
  return ApiHelper.post('payment/alipay-cn/orderinfo', params, true);
};

export const getEnrolExamWechatPrepay = ({
  instrumentId,
  gradeId,
  affiliateCode,
  studentId,
  isSelectCnTranslation,
  cnTranslationFee,
  selectedExtraOptions
}) => {
  const params = {
      instrumentId,
      gradeId,
      affiliateCode: affiliateCode || '',
      txnType: 'EXEN',
      isSelectCnTranslation,
      cnTranslationFee: isSelectCnTranslation ? cnTranslationFee : null,
      selectedExtraOptions
  };
  if (studentId) {
    params.studentId = studentId;
  }
  return ApiHelper.post('payment/wechatpay-cn/prepayinfo', params, true);
};

export const getEnrolPieceEvaluationWechatpay = ({ instrumentId, studentId, optionId }) => {
  const params = {
      instrumentId,
      optionId,
      txnType: 'PEEN'
  };
  if (studentId) {
    params.studentId = studentId;
  }
  return ApiHelper.post('payment/wechatpay-cn/prepayinfo', params, true);
};

export const getSheetMusicPurchaseWechatPrepay = ({ sheetMusicId, currencyCode, price }) => {
  const params = {
      sheetMusicId,
      currencyCode,
      price,
      txnType: 'SMPU'
  };
  return ApiHelper.post('payment/wechatpay-cn/prepayinfo', params, true);
};

export const getStudentEnrolPieceEvaluationAlipayOrderString = ({ instrumentId, optionId }) => {
  const params = {
      instrumentId,
      optionId,
      txnType: 'PEEN'
  };
  return ApiHelper.post('payment/alipay-cn/orderinfo', params, true);
};

export const getTeacherEnrolPieceEvaluationAlipayOrderString = ({ instrumentId, studentId, optionId }) => {
  const params = {
      instrumentId,
      studentId,
      optionId,
      txnType: 'PEEN'
  };
  return ApiHelper.post('payment/alipay-cn/orderinfo', params, true);
};

export const getSheetMusicPurchaseAlipayOrderString = ({ sheetMusicId, currencyCode, price }) => {
  const params = {
      sheetMusicId,
      currencyCode,
      price,
      txnType: 'SMPU'
  };
  return ApiHelper.post('payment/alipay-cn/orderinfo', params, true);
};

export const getAppealExamAlipayOrderString = ({ examId, instrumentId, gradeId, amount }) => {
  const params = {
      examId,
      instrumentId,
      gradeId,
      amount,
      txnType: 'EXAP'
  };
  return ApiHelper.post('payment/alipay-cn/orderinfo', params, true);
};

export const sendPaymentReceiptToEmail = ({ paymentId }) => {
  const params = {
      paymentId
  };
  return ApiHelper.post('email/payment/receipt', params, true);
};

// START --- Youbrio API endpoints
export const sendYoubrioPaymentReceiptToEmail = ({ paymentId }) => {
  const params = {
      paymentId
  };
  return YoubrioApiHelper.post('email/payment/receipt', params, true);
};
//   END --- Youbrio API endpoints

export const getPaymentStatusForAsyncPaymentPlatform = ({ outTradeNo, platform }) => {
  return ApiHelper.get(`payment/paid?otn=${outTradeNo}&plt=${platform}`, true);
};
