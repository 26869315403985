import {
  getPracticeRoomConfigurations,
  getPracticeRoomConfigurationsBuild6726,
  enterMeet,
  exitMeet,
  getTutorRoomList,
  getControlRoomPracticeParticipantList,
  getPracticeRoomParticipants,
  savePracticeParticipantNote,
  savePracticeParticipantLockStatus,
  getPracticeRoomSubscriptionPlans,
  enterCoffeeBreak,
  exitCoffeeBreak,
  enterLessonModeMeetLog,
  enterPracticeModeMeetLog
} from '../api';
import history from '../history';
import {
  appStartsLoading,
  appDoneLoading
} from './DialogActions';
import {
  updateCommonStates
} from './CommonActions';
import {
  UPDATE_PRACTICE_ROOM_STATE,
  RESET_PRACTICE_ROOM_STATE,
  UPDATE_CONTROL_ROOM_STATE,
  UPDATE_SUBSCRIPTION_PLANS_STATE,
  UPDATE_PRACTICE_ROOM_CONFIG_STATE,
  RESET_PRACTICE_ROOM_CONFIG_STATE
} from '../actions/types';
import i18n from '../i18n';
import { closeDialog } from './DialogActions';
import { PRACTICE_ROOM_EVENT } from '../helpers/ConstantHelper';
import AppHelper from '../helpers/AppHelper';
import AnnotationSocketHelper from '../helpers/AnnotationSocketHelper';
import JitsiExternalApiHelper from '../helpers/JitsiExternalApiHelper';
import PracticeRoomHelper from '../helpers/PracticeRoomHelper';
// import GLOBAL from '../helpers/GLOBAL';

export const updatePracticeRoomState = ({ prop, value }) => {
  return {
    type: UPDATE_PRACTICE_ROOM_STATE,
    payload: { prop, value }
  };
};

export const resetPracticeRoomState = () => {
  return {
    type: RESET_PRACTICE_ROOM_STATE
  };
};

export const getPracticeRoomList = () => {
  return (dispatch) => {
    dispatch(updatePracticeRoomState({ prop: 'practiceRoomsLoading', value: true }));
    getTutorRoomList()
      .then((res) => {
        const { practiceRooms, practiceRoomAnnouncement, jitsiBuild } = res.data;
        if (practiceRooms) {
          dispatch(updatePracticeRoomState({ prop: 'practiceRooms', value: practiceRooms }));
          dispatch(updatePracticeRoomState({ prop: 'practiceRoomAnnouncement', value: practiceRoomAnnouncement }));
          dispatch(updatePracticeRoomState({ prop: 'jitsiBuild', value: jitsiBuild }));
          dispatch(updatePracticeRoomState({ prop: 'practiceRoomsLoading', value: false }));
        }
      })
      .catch(err => {
        console.error('Failed to get the tutor room list', err);
        dispatch(updatePracticeRoomState({ prop: 'practiceRoomsLoading', value: false }));
      });
  };
};
  
export const getPracticeRoomConfigAndEnter = ({ practiceRoomId, enterAsRole, isTeacher }) => {
  return (dispatch) => {
    dispatch(appStartsLoading());
    getPracticeRoomConfigurations({ practiceRoomId, enterAsRole, mode: 1 })
      .then(async (response) => {
        // console.log(response.data);
        const { practiceRoomConfig } = response.data;
        const jitsiConfig = {
          ...practiceRoomConfig,
          annotationSocket: practiceRoomConfig.annot_server_url,
          practiceRoomId: practiceRoomConfig.practice_room_id,
          meetId: practiceRoomConfig.meet_id,
          serverUrl: practiceRoomConfig.jitsi_server_url,
          roomName: practiceRoomConfig.room_id,
          subject: practiceRoomConfig.title,
        };
        
        // If the user is the teacher of the tutor room.
        // We add toolbar button of tutor room.
        if (isTeacher) {
          jitsiConfig.TOOLBAR_BUTTONS = ['raisehand', 'microphone', 'hangup', 'camera', 'tileview', 'fullscreen', 'desktop', 'chat', 'gift', 'videobackgroundblur', 'virtualbackgroundpick', 'localrecording', 'tutorroom'];
        } else {
          jitsiConfig.TOOLBAR_BUTTONS = ['raisehand', 'microphone', 'hangup', 'camera', 'tileview', 'fullscreen', 'desktop', 'chat', 'gift', 'videobackgroundblur', 'virtualbackgroundpick', 'localrecording'];
        }

        dispatch(updatePracticeRoomState({ prop: 'practiceRoomConfig', value: practiceRoomConfig }));
  
        dispatch(appDoneLoading());

        history.push({
          pathname: '/lesson',
          state: { jitsiConfig }
        });
      })
      .catch((error) => {
        // console.log(error);
        dispatch(appDoneLoading());
        let errorMsg = i18n.t('tutorRoom.unableToEnterLessonRoom');
  
        if (error === 499) {
          errorMsg = i18n.t('tutorRoom.youAreNotTeacherOfPracticeRoom');
        } else if (error === 498) {
          errorMsg = i18n.t('tutorRoom.youAreNotSubscribedToThisRoom');
        } else if (error === 497) {
          errorMsg = i18n.t('tutorRoom.theRoomIsNotOpenedYet');
        }
  
        dispatch(updateCommonStates({ prop: 'snackbar',
          value: {
            open: true,
            severity: 'warning',
            autoHideDuration: 5000,
            message: errorMsg
          }
        }));
      });
  };
};


export const createEnterPracticeRoomMeetLog = (practiceRoomConfig) => {
  return (dispatch) => {
    const {
      meet_id,
      practice_room_id,
      jitsi_server_url,
      annot_server_url,
      jitsi_config_param_string,
      mode,
      room_id: roomId
    } = practiceRoomConfig;
    const deviceInfo = 'web';

    dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: true }));
    enterMeet({
      meetId: meet_id,
      practiceRoomId: practice_room_id,
      jitsiServerUrl: jitsi_server_url,
      annotServerUrl: annot_server_url,
      configParamString: jitsi_config_param_string,
      mode,
      deviceInfo
    })
      .then((response) => {
        dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: false }));
        // console.log(response);
        if (response?.data?.practiceRoomParticipantList) {
          dispatch(updatePracticeRoomConfigState({ prop: 'participants', value: response?.data?.practiceRoomParticipantList }));
        }

        PracticeRoomHelper.pushRefreshParticipantListEvent({
          userId: AppHelper.getUserId(),
          roomId
        });
      })
      .catch((/* error */) => {
        dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: false }));
        // console.log(error);
      });
  };
};
  
export const createExitPracticeRoomMeetLog = (practiceRoomConfig) => {
  return (dispatch) => {
    const {
      meet_id,
      practice_room_id,
      jitsi_server_url,
      annot_server_url,
      jitsi_config_param_string,
      mode,
      room_id: roomId
    } = practiceRoomConfig;
    const deviceInfo = 'web';
  
    exitMeet({
      meetId: meet_id,
      practiceRoomId: practice_room_id,
      jitsiServerUrl: jitsi_server_url,
      annotServerUrl: annot_server_url,
      configParamString: jitsi_config_param_string,
      mode,
      deviceInfo
    })
      .then((response) => {
        // console.log(response);
        if (response?.data?.practiceRoomParticipantList) {
          dispatch(updatePracticeRoomConfigState({ prop: 'participants', value: response?.data?.practiceRoomParticipantList }));
        }

        PracticeRoomHelper.pushRefreshParticipantListEvent({
          userId: AppHelper.getUserId(),
          roomId
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
};

export const updateControlRoomState = ({ prop, value }) => {
  return {
    type: UPDATE_CONTROL_ROOM_STATE,
    payload: { prop, value }
  };
};

export const getControlRoomAllRoomParticipants = () => {
  return (dispatch) => {
    dispatch(updateControlRoomState({ prop: 'isAllRoomLoading', value: true }));
    getControlRoomPracticeParticipantList()
      .then((response) => {
        const { practiceRooms, participants, activeTeachers, activeStudents, activeTeachersList } = response.data;
        dispatch(updateControlRoomState({ prop: 'isAllRoomLoading', value: false }));
        dispatch(updateControlRoomState({ prop: 'practiceRooms', value: practiceRooms }));
        dispatch(updateControlRoomState({ prop: 'participants', value: participants }));
        dispatch(updateControlRoomState({ prop: 'activeTeachers', value: activeTeachers }));
        dispatch(updateControlRoomState({ prop: 'activeStudents', value: activeStudents }));
        dispatch(updateControlRoomState({ prop: 'activeTeachersList', value: activeTeachersList }));
      })
      .catch((error) => {
        // console.log(error);
        dispatch(updateControlRoomState({ prop: 'isAllRoomLoading', value: false }));
      });
  };
};

export const updateControlRoomParticipantList = ({
  practiceRoomId,
  participants
}) => {
  return (dispatch, getState) => {
    const { participants: _participants } = getState().practiceRoom.controlRoom;
    const updatedParticipants = { ..._participants, [`pr_${practiceRoomId}`]: participants };

    dispatch(updateControlRoomState({ prop: 'participants', value: updatedParticipants }));
  };
};

export const getControlRoomSingleRoomParticipants = ({ practiceRoomId, callback }) => {
  return (dispatch) => {
    getPracticeRoomParticipants({ practiceRoomId })
      .then((response) => {
        // console.log(response);
        const { participants } = response.data;
        dispatch(updateControlRoomParticipantList({ practiceRoomId, participants }));

        callback && callback({ response });
      })
      .catch((error) => {
        callback && callback({ error });
      });
  };
};

export const updateControlRoomParticipantNote = ({
  practiceParticipantDetailId,
  userId,
  remarks,
  practiceSubjectId,
  remarksEditedAt,
  practiceRoomId,
  callback
}) => {
  return (dispatch) => {
    savePracticeParticipantNote({
      practiceParticipantDetailId,
      userId,
      remarks,
      practiceSubjectId,
      remarksEditedAt,
      practiceRoomId
    })
      .then((response) => {
        // console.log(response);
        dispatch(updateControlRoomParticipantList({ practiceRoomId, participants: response.data.participants }));
        callback && callback({ response });
      })
      .catch((error) => {
        // console.log(error);
        callback && callback({ error });
      });
  };
};

export const updateControlRoomPracticeParticipantLock = ({
  practiceParticipantDetailId,
  practiceSubjectId,
  userId,
  lock,
  lastLockedBy,
  practiceRoomId,
  callback
}) => {
  return (dispatch) => {
    savePracticeParticipantLockStatus({
      practiceParticipantDetailId,
      practiceSubjectId,
      userId,
      lock,
      lastLockedBy,
      practiceRoomId
    })
      .then((response) => {
        // console.log(response);
        dispatch(updateControlRoomParticipantList({ practiceRoomId, participants: response.data.participants }));
        callback && callback({ response });
      })
      .catch((error) => {
        // console.log(error);
        callback && callback({ error });
      });
  };
};

export const kickParticipantFromControlRoom = ({
  meetId,
  practiceRoomId,
  logUserId,
  callback
}) => {
  return () => {
    exitMeet({
      meetId: meetId,
      practiceRoomId: practiceRoomId,
      jitsiServerUrl: null,
      annotServerUrl: null,
      configParamString: null,
      mode: null,
      deviceInfo: null,
      logUserId
    })
      .then(response => {
        if (callback) {
          callback({ response });
        }
      })
      .catch(error => {
        if (callback) {
          callback({ error });
        }
      });
  };
};

export const updateSubscriptionPlansState = ({ prop, value }) => {
  return {
    type: UPDATE_SUBSCRIPTION_PLANS_STATE,
    payload: { prop, value }
  };
};

export const getTRSubscriptionPlans = ({ isPromo } = {}) => {
  return (dispatch) => {
    dispatch(appStartsLoading());
    getPracticeRoomSubscriptionPlans({ isPromo })
      .then((response) => {
        // console.log(response);
        dispatch(updateSubscriptionPlansState({ prop: 'plans', value: response.data.plans }));
        dispatch(appDoneLoading());
      })
      .catch((error) => {
        dispatch(appDoneLoading());
        // console.log(error);
      });
  };
};

export const updatePracticeRoomConfigState = ({ prop, value }) => {
  return {
    type: UPDATE_PRACTICE_ROOM_CONFIG_STATE,
    payload: { prop, value }
  };
};

export const resetPracticeRoomConfigState = () => {
  return {
    type: RESET_PRACTICE_ROOM_CONFIG_STATE
  };
};

export const getPracticeRoomParticipantList = ({ practiceRoomId }) => {
  return (dispatch) => {
    dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: true }));
    getPracticeRoomParticipants({ practiceRoomId })
      .then((response) => {
        const { participants } = response.data;
        dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: false }));
        dispatch(updatePracticeRoomConfigState({ prop: 'participants', value: participants }));
        // console.log(response);
      })
      .catch((error) => {
        dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: false }));
        // console.log(error);
      });
  };
};

export const getPracticeRoomConfigBuild6726 = ({
  practiceRoomId,
  enterAsRole,
  // isTeacher
}) => {
  return (dispatch) => {
    // dispatch(appStartsLoading());
    getPracticeRoomConfigurationsBuild6726({ practiceRoomId, enterAsRole, mode: 1 })
      .then(async (response) => {
        // console.log(response.data);
        const { practiceRoomConfig } = response.data;
        const jitsiConfig = {
          ...practiceRoomConfig,
          annotationSocket: practiceRoomConfig.annot_server_url,
          practiceRoomId: practiceRoomConfig.practice_room_id,
          meetId: practiceRoomConfig.meet_id,
          serverUrl: practiceRoomConfig.jitsi_server_url,
          roomName: practiceRoomConfig.room_id,
          subject: practiceRoomConfig.title,
        };
        
        // If the user is the teacher of the tutor room.
        // We add toolbar button of tutor room.
        // if (isTeacher) {
        //   jitsiConfig.TOOLBAR_BUTTONS = ['raisehand', 'microphone', 'hangup', 'camera', 'tileview', 'fullscreen', 'desktop', 'chat', 'gift', 'videobackgroundblur', 'virtualbackgroundpick', 'localrecording', 'tutorroom'];
        // } else {
        //   jitsiConfig.TOOLBAR_BUTTONS = ['raisehand', 'microphone', 'hangup', 'camera', 'tileview', 'fullscreen', 'desktop', 'chat', 'gift', 'videobackgroundblur', 'virtualbackgroundpick', 'localrecording'];
        // }

        dispatch(updatePracticeRoomState({ prop: 'practiceRoomConfig', value: jitsiConfig }));
      })
      .catch((error) => {
        // console.log(error);
        let errorMsg = i18n.t('tutorRoom.unableToEnterLessonRoom');
  
        if (error === 499) {
          errorMsg = i18n.t('tutorRoom.youAreNotTeacherOfPracticeRoom');
        } else if (error === 498) {
          errorMsg = i18n.t('tutorRoom.youAreNotSubscribedToThisRoom');
        } else if (error === 497) {
          errorMsg = i18n.t('tutorRoom.theRoomIsNotOpenedYet');
        }
  
        dispatch(updateCommonStates({ prop: 'snackbar',
          value: {
            open: true,
            severity: 'warning',
            autoHideDuration: 5000,
            message: errorMsg
          }
        }));
      });
  };
};

export const activateCoffeeBreak = () => {
  return (dispatch, getState) => {
    dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: true }));
    const {
      meet_id,
      practice_room_id,
      jitsi_server_url,
      annot_server_url,
      jitsi_config_param_string,
      mode,
      room_id: roomId,
      JitsiMeetAPI
    } = getState().practiceRoom.practiceRoomConfig;

    enterCoffeeBreak({
      meetId: meet_id,
      practiceRoomId: practice_room_id,
      jitsiServerUrl: jitsi_server_url,
      annotServerUrl: annot_server_url,
      configParamString: jitsi_config_param_string,
      mode,
      deviceInfo: null
    })
      .then(response => {
        if (response?.data?.practiceRoomParticipantList) {
          dispatch(updatePracticeRoomConfigState({ prop: 'participants', value: response?.data?.practiceRoomParticipantList }));
        }

        JitsiExternalApiHelper.muteMyVideo({
          apiInstance: JitsiMeetAPI
        });
        JitsiExternalApiHelper.muteMyAudio({
          apiInstance: JitsiMeetAPI
        });
        PracticeRoomHelper.pushRefreshParticipantListEvent({
          userId: AppHelper.getUserId(),
          roomId
        });
        dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: false }));
      })
      .catch(() => {
        dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: false }));
      });
  };
};

export const deactivateCoffeeBreak = () => {
  return (dispatch, getState) => {
    dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: true }));
    const {
      meet_id,
      practice_room_id,
      jitsi_server_url,
      annot_server_url,
      jitsi_config_param_string,
      mode,
      JitsiMeetAPI,
      room_id: roomId
    } = getState().practiceRoom.practiceRoomConfig;

    exitCoffeeBreak({
      meetId: meet_id,
      practiceRoomId: practice_room_id,
      jitsiServerUrl: jitsi_server_url,
      annotServerUrl: annot_server_url,
      configParamString: jitsi_config_param_string,
      mode,
      deviceInfo: null
    })
      .then(response => {
        if (response?.data?.practiceRoomParticipantList) {
          dispatch(updatePracticeRoomConfigState({ prop: 'participants', value: response?.data?.practiceRoomParticipantList }));
        }

        JitsiExternalApiHelper.unmuteMyVideo({
          apiInstance: JitsiMeetAPI
        });

        PracticeRoomHelper.pushRefreshParticipantListEvent({
          userId: AppHelper.getUserId(),
          roomId
        });
        dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: false }));

        setTimeout(() => {
          dispatch(closeDialog());
        }, 1000);
      })
      .catch(() => {
        dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: false }));
      });
  };
};

export const addParticipantFromTutorRoom = ({
  logUserId
}) => {
  return (dispatch, getState) => {
    dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: true }));

    const {
      meet_id,
      practice_room_id,
      room_id: roomId
    } = getState().practiceRoom.practiceRoomConfig;
  
    enterMeet({
      meetId: meet_id,
      practiceRoomId: practice_room_id,
      jitsiServerUrl: null,
      annotServerUrl: null,
      configParamString: null,
      mode: null,
      deviceInfo: null,
      logUserId
    })
      .then(response => {
        if (response?.data?.practiceRoomParticipantList) {
          dispatch(updatePracticeRoomConfigState({ prop: 'participants', value: response?.data?.practiceRoomParticipantList }));
        }

        PracticeRoomHelper.pushRefreshParticipantListEvent({
          userId: AppHelper.getUserId(),
          roomId
        });

        dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: false }));
      })
      .catch(error => {
        console.log(error);
        dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: false }));
      });
  };
};

export const kickParticipantFromTutorRoom = ({
  logUserId
}) => {
  return (dispatch, getState) => {
    dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: true }));

    const {
      meet_id,
      practice_room_id,
      room_id: roomId
    } = getState().practiceRoom.practiceRoomConfig;
  
    exitMeet({
      meetId: meet_id,
      practiceRoomId: practice_room_id,
      jitsiServerUrl: null,
      annotServerUrl: null,
      configParamString: null,
      mode: null,
      deviceInfo: null,
      logUserId
    })
      .then(response => {
        if (response?.data?.practiceRoomParticipantList) {
          dispatch(updatePracticeRoomConfigState({ prop: 'participants', value: response?.data?.practiceRoomParticipantList }));
        }

        PracticeRoomHelper.pushRefreshParticipantListEvent({
          userId: AppHelper.getUserId(),
          roomId
        });

        dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: false }));
      })
      .catch(error => {
        console.log(error);
        dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: false }));
      });
  };
};

export const setParticipantAudioConnectedState = ({
  practiceRoomParticipantId,
  shouldConnect,
  isSilently = false
}) => (dispatch, getState) => {
  const {
    participants,
    audioConnectedParticipants,
    room_id,
    JitsiMeetAPI,
    jitsiParticipants
  } = getState().practiceRoom.practiceRoomConfig;

  const newAudioConnectedParticipants = { ...audioConnectedParticipants,
    [`prp_${practiceRoomParticipantId}`]: shouldConnect };

  dispatch(updatePracticeRoomConfigState({
    prop: 'audioConnectedParticipants',
    value: newAudioConnectedParticipants
  }));

  const selectedParticipant = participants
    && participants.find(item => item.practice_room_participant_id === practiceRoomParticipantId);

  if (selectedParticipant && !isSilently) {
    const eventType = shouldConnect
      ? PRACTICE_ROOM_EVENT.CONNECT_AUDIO
      : PRACTICE_ROOM_EVENT.DISCONNECT_AUDIO;

    const data = {
      event_type_id: eventType,
      data: { userIds: [ selectedParticipant.user_id ] },
      user_id: AppHelper.getUserId(),
      session_id: room_id
    };

    AnnotationSocketHelper.emit('pushEvent', data, () => {
      // enable/disable the audio of that participant
      // dispatch(setAudioTrackEnabled({
      //   enabled: shouldConnect,
      //   identityId: parseInt(selectedParticipant.user_id, 10)
      // }));
      JitsiExternalApiHelper.setUserAudioEnabled({
        apiInstance: JitsiMeetAPI,
        jitsiParticipants: jitsiParticipants,
        userId: parseInt(selectedParticipant.user_id, 10),
        enabled: shouldConnect
      });

      if (shouldConnect) {
        // dispatch(setAudioMuted(false));
        JitsiExternalApiHelper.unmuteMyAudio({ apiInstance: JitsiMeetAPI });
      }
    });
  }
};

export function sendPracticeModeSocketEvent({ practiceRoomParticipantId }) {
  return function(dispatch: (Object) => Object, getState: Function) {
    const {
      participants,
      room_id,
      jitsiParticipants,
      JitsiMeetAPI
    } = getState().practiceRoom.practiceRoomConfig;

    const selectedParticipant = participants.find(item =>
      item.practice_room_participant_id === practiceRoomParticipantId);

    if (selectedParticipant) {
      const data = {
        event_type_id: PRACTICE_ROOM_EVENT.START_PRACTICE_MODE,
        data: { userIds: [ selectedParticipant.user_id ] },
        user_id: AppHelper.getUserId(),
        session_id: room_id
      };

      AnnotationSocketHelper.emit('pushEvent', data, () => {
        // disable the student's audio track
        JitsiExternalApiHelper.setUserAudioEnabled({
          apiInstance: JitsiMeetAPI,
          jitsiParticipants: jitsiParticipants,
          userId: parseInt(selectedParticipant.user_id, 10),
          enabled: false
        });
      });
    }
  };
}

export function sendLessonModeSocketEvent({ practiceRoomParticipantId }) {
  return function(dispatch: (Object) => Object, getState: Function) {
    const {
      participants,
      room_id,
      jitsiParticipants,
      JitsiMeetAPI
    } = getState().practiceRoom.practiceRoomConfig;

    const selectedParticipant = participants.find(item =>
      item.practice_room_participant_id === practiceRoomParticipantId);

    if (selectedParticipant) {
      const data = {
        event_type_id: PRACTICE_ROOM_EVENT.START_TUTOR_MODE,
        data: { userIds: [ selectedParticipant.user_id ] },
        user_id: AppHelper.getUserId(),
        session_id: room_id
      };

      AnnotationSocketHelper.emit('pushEvent', data, () => {
        JitsiExternalApiHelper.unmuteMyAudio({ apiInstance: JitsiMeetAPI });
        JitsiExternalApiHelper.setUserAudioEnabled({
          apiInstance: JitsiMeetAPI,
          jitsiParticipants: jitsiParticipants,
          userId: parseInt(selectedParticipant.user_id, 10),
          enabled: true
        });
      });
    }
  };
}

export const createEnterPracticeModeMeetLog = ({
  logUserId
}) => (dispatch, getState) => {
  dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: true }));

  const {
    meet_id,
    practice_room_id,
    jitsi_server_url,
    annot_server_url,
    jitsi_config_param_string,
    mode,
    room_id: roomId
  } = getState().practiceRoom.practiceRoomConfig;
  const deviceInfo = 'web';

  enterPracticeModeMeetLog({
    meetId: meet_id,
    practiceRoomId: practice_room_id,
    jitsiServerUrl: jitsi_server_url,
    annotServerUrl: annot_server_url,
    configParamString: jitsi_config_param_string,
    mode,
    deviceInfo,
    logUserId
  })
    .then(response => {
      dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: false }));

      if (response?.data?.practiceRoomParticipantList) {
        dispatch(updatePracticeRoomConfigState({ prop: 'participants', value: response?.data?.practiceRoomParticipantList }));
      }

      PracticeRoomHelper.pushRefreshParticipantListEvent({
        userId: AppHelper.getUserId(),
        roomId
      });
    })
    .catch(() => {
      dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: false }));
    });
};

export const createEnterLessonModeMeetLog = ({
  logUserId
}) => (dispatch, getState) => {
  dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: true }));

  const {
    meet_id,
    practice_room_id,
    jitsi_server_url,
    annot_server_url,
    jitsi_config_param_string,
    mode,
    room_id: roomId
  } = getState().practiceRoom.practiceRoomConfig;
  const deviceInfo = 'web';

  enterLessonModeMeetLog({
    meetId: meet_id,
    practiceRoomId: practice_room_id,
    jitsiServerUrl: jitsi_server_url,
    annotServerUrl: annot_server_url,
    configParamString: jitsi_config_param_string,
    mode,
    deviceInfo,
    logUserId
  })
    .then(response => {
      dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: false }));

      if (response?.data?.practiceRoomParticipantList) {
        dispatch(updatePracticeRoomConfigState({ prop: 'participants', value: response?.data?.practiceRoomParticipantList }));
      }

      PracticeRoomHelper.pushRefreshParticipantListEvent({
        userId: AppHelper.getUserId(),
        roomId
      });
    })
    .catch(() => {
      dispatch(updatePracticeRoomConfigState({ prop: 'isLoadingParticipant', value: false }));
    });
};

export const saveTRUserPracticeNote = ({
  practiceParticipantDetailId,
  userId,
  remarks,
  practiceSubjectId,
  remarksEditedAt,
  practiceRoomId,
  callback
}) => {
  return (dispatch) => {
    savePracticeParticipantNote({
      practiceParticipantDetailId,
      userId,
      remarks,
      practiceSubjectId,
      remarksEditedAt,
      practiceRoomId
    })
      .then((response) => {
        // console.log(response);
        if (response?.data?.participants) {
          dispatch(updatePracticeRoomConfigState({ prop: 'participants', value: response?.data?.participants }));
        }

        callback && callback({ response });
      })
      .catch((error) => {
        // console.log(error);
        callback && callback({ error });
      });
  };
};
