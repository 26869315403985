import YoubrioApiHelper from '../helpers/YoubrioApiHelper';

export const createNewSubscriptionOrder = ({
  orderCurrencyCode,
  orderAmount,
  planId
}) => {
  const _params = {
    orderCurrencyCode,
    orderAmount,
    items: [
      {
        orderTypeId: 1,
        currency_code: orderCurrencyCode,
        amount: orderAmount,
        params: {
          planId
        }
      }
    ]
  };

  return YoubrioApiHelper.post('order', _params, true);
};

export const getOrderByRefId = ({ orderRefId, status = 'pending' }) => {
  return YoubrioApiHelper.get(`order/${orderRefId}?status=${status}`, true);
};

export const makePaymentForOrderViaWallet = ({
  orderRefId,
  currencyCode,
  amount
}) => {
  const params = {
    currencyCode,
    amount,
    paymentMethod: 'ybfund'
  };
  return YoubrioApiHelper.post(`order/${orderRefId}/pay`, params, true);
};

export const makePaymentForOrderViaBraintree = ({
  orderRefId,
  currencyCode,
  amount,
  nonce
}) => {
  const params = {
    currencyCode,
    amount,
    nonce,
    paymentMethod: 'bt'
  };
  return YoubrioApiHelper.post(`order/${orderRefId}/pay`, params, true);
};