import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import _ from 'lodash';
import {
  Grid,
  Hidden,
} from '@material-ui/core';
import {
} from '@material-ui/icons';
import {
} from '@material-ui/core/colors';
// import { useTranslation } from 'react-i18next';

import {
} from '../../actions';

import GLOBAL from '../../helpers/GLOBAL';
// import CONSTANT from '../../helpers/ConstantHelper';
import ProfileDialog from './ProfileDialog';

// eslint-disable-next-line no-unused-vars
const ProfileScreen = (props) => {
  // const classes = useStyles();
  const history = useHistory();

  // const { t } = useTranslation();
  // const isTeacher = AppHelper.isCBETeacher();
  const user = GLOBAL.get('user');
  GLOBAL.set('role', user.cbeRole);
  // const isTeacher = AppHelper.isCBETeacher();

  const [openDialog, setOpenDialog] = React.useState(true);

  useEffect(() => {
  }, []);

  const renderProfileDialog = () => {
    if (!user) {
      return (
        <></>
      );
    }

    return (
      <>
        <ProfileDialog
          dialogProps={{
            open: openDialog,
            onClose: () => {
              setOpenDialog(false);
              history.replace('/');
            }
          }}
          user={user}
        />
      </>
    );
  };

  return (
    <Grid container direction="column" justify="flex-start" spacing={3}>
      <div>
        <Hidden smDown>
          { renderProfileDialog() }
        </Hidden>

        <Hidden mdUp>
          { renderProfileDialog() }
        </Hidden>
      </div>
    </Grid>
  );
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, {
})(ProfileScreen);
