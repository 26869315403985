import {
  UPDATE_ORDER_STATE,
  RESET_ORDER_DETAIL_STATE,
  LOGOUT_USER_SUCCESS
} from '../actions/types';

const INITIAL_ORDER_DETAILS_STATE = {
  items: [],
  currencyCode: null,
  totalAmount: 0
};

const INITIAL_STATE = {
  orderDetails: INITIAL_ORDER_DETAILS_STATE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UPDATE_ORDER_STATE:
    return { ...state, [action.payload.prop]: action.payload.value };
  case RESET_ORDER_DETAIL_STATE:
    return { ...state, orderDetails: INITIAL_ORDER_DETAILS_STATE };
  case LOGOUT_USER_SUCCESS:
    return INITIAL_STATE;
  default:
    return state;
  }
};
