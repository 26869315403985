import {
  UPDATE_CHAT_STATE,
  UPDATE_CHAT_LIST_STATE,
  RESET_CHAT_STATE
} from '../actions/types';

const INITIAL_CHAT_LIST_STATE = {
  isLoading: false,
  list: [],
  showNewChatAlert: false
};

const INITIAL_STATE = {
  chatRoom: INITIAL_CHAT_LIST_STATE,
  selectedChat: '', // store the id of selected chat room
  // roomIndex: -1
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UPDATE_CHAT_STATE:
    return { ...state, [action.payload.prop]: action.payload.value };
  case UPDATE_CHAT_LIST_STATE:
    return { ...state, chatRoom: { ...state.chatRoom, [action.payload.prop]: action.payload.value } };
  case RESET_CHAT_STATE: {
    return INITIAL_STATE;
  }
  default:
    return state;
  }
};
