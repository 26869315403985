

import {
  UPDATE_GATEWAY_PAYMENT_METHODS_STATE,
  UPDATE_GATEWAY_PAYMENT_METHOD_TYPES_STATE,
  LOGOUT_USER_SUCCESS
} from '../actions/types';

const INITIAL_GATEWAY_PAYMENT_METHOD_TYPES_STATE = {
  isLoading: false,
  list: []
};

const INITIAL_GATEWAY_PAYMENT_METHODS_STATE = {
  isLoading: false,
  list: []
};

const INITIAL_STATE = {
  gatewayPaymentMethodTypes: INITIAL_GATEWAY_PAYMENT_METHOD_TYPES_STATE,
  gatewayPaymentMethods: INITIAL_GATEWAY_PAYMENT_METHODS_STATE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UPDATE_GATEWAY_PAYMENT_METHODS_STATE:
    return { ...state, gatewayPaymentMethods: { ...state.gatewayPaymentMethods, [action.payload.prop]: action.payload.value } };
  case UPDATE_GATEWAY_PAYMENT_METHOD_TYPES_STATE:
    return { ...state, gatewayPaymentMethodTypes: { ...state.gatewayPaymentMethodTypes, [action.payload.prop]: action.payload.value } };
  case LOGOUT_USER_SUCCESS:
    return INITIAL_STATE;
  default:
    return state;
  }
};
