import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { getGatewayPaymentMethodTypes } from '../../actions';

const GatewayPaymentMethodTypePicker = (props) => {
  const {
    selectedGatewayPaymentMethodType,
    onSelect = () => {},
    currencyCode,
    textFieldProps,
    gatewayPaymentMethodTypes
  } = props;

  const {
    list: paymentMethodTypeList
  } = gatewayPaymentMethodTypes;

  const { t } = useTranslation();

  useEffect(() => {
    props.getGatewayPaymentMethodTypes({ currencyCode });
  }, []);

  const handleChange = (event) => {
    onSelect(event.target.value);
  };

  const render = () => {
    return (
      <TextField
        id="outlined-select-currency"
        select
        label={t('wallet.topUpMethod')}
        value={selectedGatewayPaymentMethodType}
        onChange={handleChange}
        helperText={t('wallet.pleaseSelectYourPreferredTopUpMethod')}
        {...textFieldProps}
      >
        {paymentMethodTypeList.map((option) => {
          const paymentMethod = option;
          return (
            <MenuItem key={paymentMethod.id} value={paymentMethod.id}>
              <Grid container alignItems='center'>
                <Grid item xs={8}>
                  <Typography style={{ whiteSpace: 'normal' }}>{paymentMethod.label}</Typography>
                </Grid>
                <Grid item container xs={4} justifyContent='flex-end'>
                  {
                    paymentMethod.icon
                      || <img alt={paymentMethod.label} style={{ maxWidth: '100px', maxHeight: '25px' }} src={paymentMethod.logo_url} />
                  }
                </Grid>
              </Grid>
              
            </MenuItem>
          );
        })}
      </TextField>
    );
  };

  return render();
};

const mapStateToProps = (state) => {
  const {
    gatewayPaymentMethodTypes
  } = state.gatewayPayment;

  return {
    gatewayPaymentMethodTypes
  };
};

export default connect(mapStateToProps, {
  getGatewayPaymentMethodTypes
})(GatewayPaymentMethodTypePicker);