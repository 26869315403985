import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  Typography,
  Button,
  Grid,
  // IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  // Icon,
  Snackbar,
} from '@material-ui/core';
import {
  // ArrowBack as ArrowBackIcon,
  Comment as CommentIcon,
  // Share as ShareIcon,
  ExitToApp as ExitToAppIcon,
  // Minimize,
  // NoEncryption,
} from '@material-ui/icons';
// import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Jitsi from 'react-jitsi';
import _ from 'lodash';
import { UAParser } from 'ua-parser-js';
import GLOBAL from '../../helpers/GLOBAL';
import AppHelper from '../../helpers/AppHelper';
// import YoubrioApiHelper from '../../helpers/YoubrioApiHelper';
import { Skeleton } from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';
import {
  getPracticeRoomProfile,
  getPracticeTutorReviews,
} from '../../api';

import {
  onSlotClick,
  updateLessonStateAction,
  createEnterPracticeRoomMeetLog,
  createExitPracticeRoomMeetLog,
  fetchPracticeRoomTeacherList,
  // requestGetPracticeTutorReviewList,
} from '../../actions';
import UserFilesDialog from '../UserFilesDialog/UserFilesDialog';

const styles = {
  root: {
    paddingTop: 10,
    position: 'fixed',
    top: 64,
    left: 64 + 72,  // 72px is the left navbar and 64 is the margin
    right: 0,
    bottom: 0,
    zIndex: 9,
  },
  container: {
    overflow: 'hidden',
    width: 'calc(100vw - 200px)',
    minHeight: 'calc(100vh - 200px)',
  },
  jitsiContainer: {
    width: 'calc(100vw - 200px)',
    height: 'calc(100vh - 200px)',
  },
  button: {
    marginLeft: 12,
  },
  miniModeOverlay: {},
  infoContainer: {
    padding: 20,
  },
  iconButton: {
  },
  iconButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
};

const miniPlayerStyles = {
  root: {
    paddingTop: 10,
    // position: 'fixed',
    top: 64,
    left: 73,
    right: 0,
    bottom: 0,
    zIndex: 9999,
    pointerEvents: 'none',
  },
  container: {
    overflow: 'hidden',
    width: '28%',
    height: '40%',
    minHeight: 225,
    position: 'fixed',
    bottom: 0,
    right: 0,
    pointerEvents: 'auto',
  },
  jitsiContainer: {
    width: '100%', // 400,
    height: '85%', // 225,
  },
  button: {
  },
  miniModeOverlay: {
    // width: 400,
    // height: 225,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  infoContainer: {
    padding: 12,
    height: '10%',
  }
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const TOOLBAR_BUTTONS = ['raisehand', 'microphone', 'hangup', 'camera', 'tileview', 'fullscreen', 'desktop', 'chat', 'gift', 'videobackgroundblur', 'virtualbackgroundpick'];

const JitsiModal = (props) => {
  const [miniMode, setMiniMode] = React.useState(false);
  const { jitsiConfig, location, updateLessonStateAction } = props;
  const { t } = useTranslation();
  const apiToken = GLOBAL.get('token');
  const prevProps = usePrevious({ location });
  const userId = AppHelper.getUserId();
  const day = AppHelper.formatShortDateV2(new Date());

  const parser = new UAParser();
  const browser = parser.getBrowser();
  const localRecordingBrowserSupport = browser && (
    browser.name === 'Chrome'
    || browser.name === 'Chromium'
    || browser.name === 'Edge'
  );

  const [openExitPopup, setOpenExitPopup] = React.useState(false);
  const [practiceRoomProfileId, setPracticeRoomProfileId] = React.useState(null);
  const [showExitPopupFlags, setShowExitPopupFlags] = React.useState(null);
  const [readyToClose, setReadyToClose] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [myFilesDialogVisible, setMyFilesDialogVisible] = React.useState(false);
  const [profileId, setProfileId] = React.useState(null);

  if (jitsiConfig.isRecordingEnabled) {
    TOOLBAR_BUTTONS.push('localrecording');
  }

  if (!localRecordingBrowserSupport) {
    const pos = TOOLBAR_BUTTONS.indexOf('localrecording');
    if (pos >=0) {
      TOOLBAR_BUTTONS.splice(pos, 1);
    }
    if (jitsiConfig && jitsiConfig.TOOLBAR_BUTTONS) {
      const pos2 = jitsiConfig.TOOLBAR_BUTTONS.indexOf('localrecording');
      if (pos2 >=0) {
        jitsiConfig.TOOLBAR_BUTTONS.splice(pos2, 1);
      }
    }
  }

  const updatePracticeRoomProfileId = async() => {
    try {
      const userPracticeRoomProfile = GLOBAL.get('userPracticeRoomProfile');
      if (userPracticeRoomProfile && userPracticeRoomProfile.id) {
        setProfileId(userPracticeRoomProfile.id);
        return;
      }
      try {
        const response = await getPracticeRoomProfile({ userId }); // work only for getting my own profileId!
        if (!response || !response.data || !response.data.practiceRoomProfile) {
          return null;
        }
        setProfileId(response.data.practiceRoomProfile.id);
        GLOBAL.set('userPracticeRoomProfile', response.data.practiceRoomProfile);
 
      } catch (e) {
        console.error(e);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const requestGetShowFlagHelper = async () => {
    if (!jitsiConfig || !jitsiConfig.practice_room_id) {
      return null;
    }
    var profileId = practiceRoomProfileId;
    if (!practiceRoomProfileId) {
      const response = await getPracticeRoomProfile({ userId });
      if (!response || !response.data || !response.data.practiceRoomProfile) {
        if (response && response.error) {
          setErrorText(response.error);
          setSnackbarOpen(true);
        }
        return null;
      }
      profileId = response.data.practiceRoomProfile.id;
      setPracticeRoomProfileId(profileId);
    }
    const reviewListParams = {
      practiceRoomId: jitsiConfig.practice_room_id,
      practiceRoomProfileId: profileId,
      byUserId: userId, // AppHelper.getUserId(),
      day: day // AppHelper.formatShortDateV2(new Date())
    };
    const response2 = await getPracticeTutorReviews(reviewListParams);
    if (!response2 || !response2.data) {
      return null;
    }
    return response2.data.flags;
  };

  const decideShowExitPopup = async () => {
    try {
      const flags = await requestGetShowFlagHelper(); // this api will throw error for non-student, hence need try catch
      if (flags) { // this flag should only work on tutor room students
        setShowExitPopupFlags(flags);
        if (flags.shouldShowEnterAgainButton || flags.shouldShowReviewButton) {
          setOpenExitPopup(true);
        } else {
          onClickCloseButton();
        }
      } else {
        props.history.push('/dash');
      }
    } catch (error) { // just exit on error, rather than letting user be stuck there
      console.error(error);
      setMiniMode(false);
      // props.history.push('/dash');
      window.location.href = window.location.protocol + '//' + window.location.host + '/dash';
    }
    
  };

  // const handleOpenExitPopup = () => {
  //   setOpenExitPopup(true);
  // };

  const handleCloseExitPopup = () => {
    setOpenExitPopup(false);
  };

  const onClickReenterButton = () => {
    setOpenExitPopup(false);
    setTimeout(() => {
      props.history.go(0);
    }, 300);
  };

  const onClickReviewTutorButton = () => {
    setOpenExitPopup(false);
    setTimeout(()=> {
      props.history.push({
        pathname: '/practice-tutor-reviews',
        state: {
          practiceRoomId: jitsiConfig.practice_room_id,
          userId: AppHelper.getUserId(),
          day: AppHelper.formatShortDateV2(new Date())
        }
      });
    }, 300);
  };

  // const onClickShareButton = () => {
  //   setOpenExitPopup(false);
  // };

  const onClickCloseButton = () => {
    setOpenExitPopup(false);
    // On exit
    updateLessonStateAction({ prop: 'jitsiConfig', value:{} });
    setTimeout(() => {
      // props.history.replace('/tutor-room');
      location.href = location.protocal + location.hostname + '/tutor-room';
    }, 300);
  };

  const onClickBackButton = () => {
    if (miniMode) {
      props.history.push({
        pathname: '/lesson',
        state: { jitsiConfig }
      });
    } else {
      // props.history.length > 0
      //   ?
      //   props.history.goBack()
      //   :
      //   props.history.replace('/dash');
    }
    setMiniMode(!miniMode);
  };

  useEffect(() => {
    if (readyToClose) {
      decideShowExitPopup();
      setReadyToClose(false);
    }
  }, [readyToClose]);

  useEffect(() => {
    // Jitsi Preload code moved to InitComponent
    // const region = YoubrioApiHelper.getRegion();
    // const script = document.createElement('script');
    // script.setAttribute('type', 'text/javascript');

    // if (region === 'cn') {
    //   script.src = 'https://conf-pek.youbrio.cn/external_api.js';
    // } else {
    //   script.src = 'https://conf-syd3.youbrio.net/external_api.js';
    // }
    // document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (prevProps && prevProps.location.pathname === '/lesson' && location.pathname !== '/lesson') { // leave lesson screen
      if (!_.isEmpty(jitsiConfig)) {
        if (!miniMode) {
          setMiniMode(true);
        }
      }
    } else if (prevProps && prevProps.location.pathname !== '/lesson' && location.pathname === '/lesson') { // enter lesson screen
      if (!_.isEmpty(jitsiConfig)) {
        setMiniMode(false);
      }
    }
  }, [location, jitsiConfig, miniMode]);

  useEffect(() => {
    updatePracticeRoomProfileId();
  },
  [updatePracticeRoomProfileId]                                                                                                                 
  );

  let dynamicStyles = styles;
  if (miniMode) {
    dynamicStyles = miniPlayerStyles;
  }

  const handleAPI = (api) => {
    let toggleCount = 0;
    
    api.on('readyToClose', () => {
      api.dispose();
      decideShowExitPopup();
      // props.history.push('/dash');
    });
    
    api.on('tileViewChanged', (obj) => {
      if (obj.enabled === false && toggleCount === 0) {
        api.executeCommand('toggleTileView');
        toggleCount = 1;
      }
    });

    // eslint-disable-next-line no-unused-vars
    api.on('videoConferenceJoined', (event) => {
      // console.log('videoConferenceJoined', event);
      props.createEnterPracticeRoomMeetLog(jitsiConfig);
    });

    // eslint-disable-next-line no-unused-vars
    api.on('videoConferenceLeft', (event) => {
      // console.log('videoConferenceLeft', event);
      props.createExitPracticeRoomMeetLog(jitsiConfig);
    });
  };
    
  const onViewDetails = () => {
    GLOBAL.logger.info('On lesson details view');
    const meetId = _.get(jitsiConfig, 'meetId', undefined);
    const bookingSlotId =  _.get(jitsiConfig, 'bookingSlotId', undefined);
    
    props.onSlotClick({ meetId, bookingSlotId });
  };

  const onClickMyFilesButton = () => {
    setMyFilesDialogVisible(true);
  };

  if (_.isEmpty(jitsiConfig)) {
    return null;
  }

  // Replace https:// and backslash if have any.
  let domain = jitsiConfig.serverUrl.replace('https://', '');
  domain = domain.replace(/\/$/, '');
    
  const jwt = jitsiConfig.jitoken;
  const displayName = _.get(jitsiConfig, 'displayName', '')
    ?? `${AppHelper.getMyFullname()}_#${AppHelper.getUserId()}}`;
  const userInfo = {
    displayName
  };

  const interfaceConfigOverwrite = {
    LANG_DETECTION: true,
    TOOLBAR_BUTTONS: _.get(jitsiConfig, 'TOOLBAR_BUTTONS', TOOLBAR_BUTTONS),
    filmStripOnly: false,
    DEFAULT_BACKGROUND: 'rgb(27,38.56)',
  };

  let disableAP = false;
  let disableAEC = false;
  let disableAGC = false;
  let disableNS = false;
  let disableHPF = false;
  let disableAL = false;
  let enableStereo = false;

  const configOverwrite = {
    p2p: true,
    disableDeepLinking: true,
    enableWelcomePage: false,
    disableAP: disableAP,
    disableAEC: disableAEC,
    disableAGC: disableAGC,
    disableNS: disableNS,
    disableHPF: disableHPF,
    disableAudioLevels: disableAL,
    enableStereo: enableStereo,
    subject: jitsiConfig.subject !== null && jitsiConfig.subject !== ' ' ? jitsiConfig.subject : ' ',
    hosts: {
      practiceRoomId: _.get(jitsiConfig, 'practiceRoomId', ''),
      meetId: _.get(jitsiConfig, 'meetId', ''),
      lessonSessionId: _.get(jitsiConfig, 'lessonSessionId', ''),
      annotationSocket: jitsiConfig.annotationSocket,
      youbrioApiJwt: apiToken
    },
    tutorRoomConfig: {
      practiceRoomId: _.get(jitsiConfig, 'practiceRoomId', ''),
      practiceRoomRoleId: _.get(jitsiConfig, 'practiceRoomRoleId', ''),
      meetId: _.get(jitsiConfig, 'meetId', ''),
      todayTotalLesson: _.get(jitsiConfig, 'in_room_total_time', {})
    },
    startWithSpeakerMuted: _.get(jitsiConfig, 'startWithSpeakerMuted', ''),
    startWithMicMuted: _.get(jitsiConfig, 'startWithMicMuted', ''),
    startTileView: _.get(jitsiConfig, 'extraJitsiConfig.startTileView', true)
  };
    
  const isTutorRoom = interfaceConfigOverwrite.TOOLBAR_BUTTONS.indexOf('tutorroom') !== -1;

  const renderExitPopup = () => {
    if (!openExitPopup) {
      return (<div />);
    }

    return (
      <div>
        <Dialog open={openExitPopup} onClose={handleCloseExitPopup} fullWidth={true} maxWidth='xs' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{t('exitPopup.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
            </DialogContentText>
            <Grid container justifycontent="center" spacing={4}>
              {
                showExitPopupFlags && showExitPopupFlags.shouldShowEnterAgainButton
                  ?
                  <Grid item xs={showExitPopupFlags.shouldShowReviewButton ? 6 : 'auto'}>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<ExitToAppIcon />}
                      style={{textTransform: 'none'}}
                      onClick={onClickReenterButton}
                    >
                      {t('exitPopup.reenter') + (jitsiConfig && jitsiConfig.subject ? ' ' + jitsiConfig.subject : '')}
                    </Button>
                  </Grid>
                  :
                  null
              }
              {
                showExitPopupFlags && showExitPopupFlags.shouldShowReviewButton
                  ?
                  <Grid item xs={showExitPopupFlags.shouldShowEnterAgainButton ? 6 : 'auto'}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CommentIcon />}
                      style={{textTransform: 'none'}}
                      onClick={onClickReviewTutorButton}
                    >
                      {t('exitPopup.review')}
                    </Button>
                  </Grid>
                  :
                  null
              }
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClickCloseButton} color="primary">
              {t('exitPopup.close')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const renderMyFilesDialog = () => {
    const user = GLOBAL.get('user');

    if (!user) {
      return null;
    }
    if (!myFilesDialogVisible) {
      return null;
    }

    return (
      <div>
        <UserFilesDialog
          visible={myFilesDialogVisible}
          setVisible={setMyFilesDialogVisible}
          userId={userId}
          profileId={profileId}
          isMyFiles={true}
        />
      </div>
    );
  };

  return (
    <div style={dynamicStyles.root}>
      <Paper elevation={1} style={dynamicStyles.container}>
        <Jitsi
          containerStyle={dynamicStyles.jitsiContainer}
          loadingComponent={() => <Skeleton variant="rect" animation="wave" style={dynamicStyles.jitsiContainer} />}
          onAPILoad={handleAPI}
          domain={domain}
          roomName={jitsiConfig.roomName}
          interfaceConfig={interfaceConfigOverwrite}
          config={configOverwrite}
          noSsl={false}
          jwt={jwt}
          userInfo={userInfo}
          displayName={displayName}
        />

        <Grid container direction="row" justify="space-between" alignItems="center" style={dynamicStyles.infoContainer}>
          <Grid item>
            {
              miniMode ?
                <div style={dynamicStyles.miniModeOverlay} /> :
                <>
                  <Typography variant="h6">{t('lesson.myLesson')}</Typography>
                  <Typography variant="caption" color="textSecondary">{t('lesson.lessonNote')}</Typography>
                </>
            }
          </Grid>
          <Grid item>
            <Button variant="contained" size="small" onClick={() => onClickBackButton()} color="primary" style={dynamicStyles.button}>
              {miniMode ? t('back') : t('lesson.miniMode')}
            </Button>
    
            {
              isTutorRoom || !AppHelper.isCBETeacher() ?
                null
                :
                <Button variant="contained" size="small" onClick={onViewDetails} color="primary" style={dynamicStyles.button}>
                  {t('lesson.lessonDetails')}
                </Button>
            }
            {
              AppHelper.isCBETeacher() || miniMode ?
                null
                :
                <Button variant="contained" size="small" onClick={onClickMyFilesButton} color="primary" style={dynamicStyles.button}>
                  {t('myFiles')}
                </Button>
            }
          </Grid>
        </Grid>
      </Paper>
      { renderExitPopup() }
      { !miniMode && renderMyFilesDialog() }
      <Snackbar open={snackbarOpen} autoHideDuration={6000}>
        <MuiAlert severity="success">
          { errorText }
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    jitsiConfig
  } = state.lesson;
  const {
    reviewList
  } = state.practiceTutorReview;

  return {
    jitsiConfig,
    reviewList,
  };
};

export default connect(mapStateToProps, {
  onSlotClick,
  updateLessonStateAction,
  createEnterPracticeRoomMeetLog,
  createExitPracticeRoomMeetLog,
  fetchPracticeRoomTeacherList,
  // requestGetPracticeTutorReviewList,
})(withRouter(JitsiModal));
