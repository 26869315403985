import { Box } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { openDialog } from '../../../actions';
import JitsiExternalApiHelper from '../../../helpers/JitsiExternalApiHelper';
import { CircularProgressWithCenterLabel } from '../../common/CircularProgressWithCenterLabel';
import KickCountdownDialog from '../../dialogs/tutorRoom/KickCountdownDialog';

const KICK_TIMER_CD_SECONDS = 100;
const SECOND_REMINDER_KICK_TIMER_CD_SECONDS = 30;

const AutoKickIndicator = (props) => {
  const {
    JitsiMeetAPI,
    exitAt,
    now
  } = props;

  useEffect(() => {
    if (exitAt) {
      const secondsToExit = getSecondsLeftToKick();

      // console.log(`useEffect_Kicking in ${secondsToExit} seconds.`);

      if (
        (secondsToExit <= KICK_TIMER_CD_SECONDS
          && secondsToExit >= KICK_TIMER_CD_SECONDS - 1)
        || (secondsToExit <= SECOND_REMINDER_KICK_TIMER_CD_SECONDS
          && secondsToExit >= SECOND_REMINDER_KICK_TIMER_CD_SECONDS - 1)
      ) {
        openKickCountdownDialog();
      }

      if (
        exitAt
        && secondsToExit <= 0 // already past now
      ) {
        JitsiExternalApiHelper.endCall({
          apiInstance: JitsiMeetAPI
        });
      }
    }
  }, [now]);

  const openKickCountdownDialog = () => {
    props.dispatch(openDialog({
      dialog: KickCountdownDialog,
      props: {
        disableEscapeKeyDown: true,
        disableBackdropClick: true,
        countdownStartSeconds: KICK_TIMER_CD_SECONDS
      }
    }));
  };

  const getSecondsLeftToKick = () => {
    if (exitAt) {
      let secondsLeft = moment(exitAt).diff(moment(now), 'seconds');

      if (secondsLeft < 0) {
        secondsLeft = 0;
      }

      return secondsLeft;
    }
    
    return 0;
  };

  const getPercentage = () => {
    let percentage = (getSecondsLeftToKick() / KICK_TIMER_CD_SECONDS) * 100;

    if (percentage > 100) {
      percentage = 100;
    }

    return percentage;
  };

  const shouldDisplayMinimizedKickTimer = () => {
    return (
      exitAt
      && moment(exitAt).diff(moment(now), 'seconds') <= KICK_TIMER_CD_SECONDS
      && moment(exitAt).diff(moment(now), 'seconds') >= 0
    );
  };

  const render = () => {
    if (shouldDisplayMinimizedKickTimer()) {
      return (
        <Box mt={2} className='button' onClick={openKickCountdownDialog}>
          <CircularProgressWithCenterLabel
            circularProgressProps={{
              size: 70,
              value: getPercentage()
            }}
            labelProps={{
              variant: 'h5'
            }}
            label={getSecondsLeftToKick()}
          />
        </Box>
      );
    }
    
    return null;
  };

  return render();
};

const mapStateToProps = (state) => {
  const {
    exitAt,
    JitsiMeetAPI
  } = state.practiceRoom.practiceRoomConfig;

  return {
    exitAt,
    JitsiMeetAPI
  };
};

export default connect(mapStateToProps)(AutoKickIndicator);