import {
  UPDATE_PRACTICE_TUTOR_REVIEW_LIST_STATE,
  RESET_PRACTICE_TUTOR_REVIEW_LIST_STATE
} from '../actions/types';

const INITIAL_STATE = {
  practiceTutorReviewListLoading: false,
  reviewList: []
};
  
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UPDATE_PRACTICE_TUTOR_REVIEW_LIST_STATE:
    return { ...state, [action.payload.prop]: action.payload.value };
  case RESET_PRACTICE_TUTOR_REVIEW_LIST_STATE: {
    return INITIAL_STATE;
  }
  default:
    return state;
  }
};
  
