import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link as RLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import AppleLogin from 'react-apple-login';

import {
  youbrioLogin,
  loginByFacebookCallback,
  loginByGoogleCallback,
  loginByAppleCallback,
  updateAuthStates,
} from '../../actions';
import config from '../../config.json';
import AppHelper from '../../helpers/AppHelper';
import YoubrioApiHelper from '../../helpers/YoubrioApiHelper';
import ApiHelper from '../../helpers/ApiHelper';

import './SigninScreen.css';

import GLOBAL from '../../helpers/GLOBAL';
import facebook from '../../img/facebook.png';
import google from '../../img/google.png';
import apple from '../../img/apple.png';

const { STRING } = require('../../helpers/ConstantHelper');

const SigninScreen = (props) => {
  const {
    location
  } = props;

  const { t } = useTranslation();
  const passwordResetUrl = YoubrioApiHelper.getLegacyPortalPath('password/email');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isFbLogin, setIsFbLogin] = useState(false);

  const env = process.env.REACT_APP_ENV || 'dev';
  const region = process.env.REACT_APP_REGION || 'global';
  const regionRoot = region+'Root';
  const hostRoot = config.youbrioUrl[env][regionRoot];

  useEffect(() => {
    const globalEmail = GLOBAL.get('email');
    if (globalEmail) {
      setEmail(globalEmail);
      setRemember(true);
    }
  }, []);

  const onEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (emailError) {
      setEmailError(false);
    }
  };

  const onPasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    if (props.error) {
      props.updateAuthStates({ prop: 'error', value: false });
    }
  };

  const getSignUpLocation = () => {
    if (location?.state?.signUpAs === STRING.ROLE.STUDENT) {
      return '/sign-up-student';
    } else {
      return '/sign-up';
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!AppHelper.isEmail(email)) {
      return setEmailError(true);
    }
    
    props.youbrioLogin(email, password, remember);
  };

  const renderLeftPanel = () => {
    return (
      <Grid
        className="login-form-container"
        container
        direction="column"
        wrap='nowrap'
        style={{ maxHeight: '100vh', overflow: 'scroll', height: '100%' }}
      >
        <img alt={'logo'} src={require('../../img/betaLogo.png')} style={{ width: '50%', marginBottom: 20 }} />
        <h2 className="bold">{ t('signin.welcome') }</h2>
        <h4 className="light">{ t('signin.loginAccount') }</h4>
        <Grid className="social-btn-container" container spacing={3}>
          <Grid item xs={12} md={4}>
            <FacebookLogin
              appId={config.facebookClientId}
              autoLoad={false}
              fields="name,email,picture"
              // redirectUri={config.appleRedirectUri[process.env.REACT_APP_ENV]}
              redirectUri={`${window.location.origin}/dash`}
              callback={(fbResponse) => {
                if (isFbLogin) {
                  props.loginByFacebookCallback(fbResponse);
                }
              }}
              render={renderProps => (
                <Button
                  fullWidth
                  onClick={() => {
                    setIsFbLogin(true);
                    renderProps.onClick();
                  }}
                  variant="contained"
                  className="white-bg"
                  startIcon={<img className="icon-sm" alt="social-icon-facebook" src={facebook} />}
                >
                  { t('signin.fbLogin') }
                </Button>
              )}
            />
          </Grid>
          
          <Grid item xs={12} md={4}>
            <GoogleLogin
              clientId={config.googleClientId}
              autoLoad={false}
              onSuccess={props.loginByGoogleCallback}
              redirectUri="/dash"
              cookiePolicy={'single_host_origin'}
              render={renderProps => (
                <Button
                  fullWidth
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  variant="contained"
                  className="white-bg"
                  startIcon={<img className="icon-sm" alt="social-icon-google" src={google} />}
                >
                  { t('signin.gLogin') }
                </Button>
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <AppleLogin
              usePopup={true}
              autoLoad={false}
              clientId={config.appleClientId}
              scope="name email"
              responseType='code id_token'
              responseMode='form_post'
              callback={props.loginByAppleCallback}
              // redirectURI={config.appleRedirectUri[process.env.REACT_APP_ENV]}
              redirectURI={`${window.location.origin}/sign-in`}
              render={(renderProps) => (
                <Button
                  fullWidth
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  variant="contained"
                  className="white-bg"
                  startIcon={<img className="icon-sm" alt="social-icon-apple" src={apple} />}
                >
                  { t('signin.aLogin') }
                </Button>
              )}
            />
            
          </Grid>
        </Grid>

        <span className="text-center my-4"> { t('signin.or') } </span>

        <form onSubmit={handleLogin}>
          <Grid container direction="column">
            <TextField
              required
              inputProps={{ maxLength: 100 }}
              className="my-2"
              type="email"
              label={t('signin.email')}
              placeholder={t('signin.enterEmail')}
              value={email}
              onChange={onEmailChange}
              autoFocus
              autoComplete='email'
            />

            { emailError && <span style={{ color: 'red' }}>{t('signin.invalidEmail')}</span> }

            <TextField
              required
              inputProps={{ maxLength: 100 }}
              className="my-2"
              type="password"
              label={t('signin.password')}
              placeholder={t('signin.enterPassword')}
              value={password}
              onChange={onPasswordChange}
            />

            { props.error && <span style={{ color: 'red' }}>{t('signin.failed')}</span> }

            <Grid item className="my-2">
              <Grid container justifyContent="space-between">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={remember}
                      onChange={(event) => setRemember(event.target.checked)}
                      name={t('signin.rememberMe')}
                      color="primary"
                    />
                  }
                  label={t('signin.rememberMe')}
                />
                

                <FormControlLabel
                  control={<Link href={passwordResetUrl}>
                    { t('signin.forgotPassword') }
                  </Link>}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} className="my-3">
              <Grid item sm={12} md={6}>
                <Button
                  disabled={props.error || emailError}
                  fullWidth
                  type="submit"
                  className="my-2"
                  variant="contained"
                  color="primary"
                >
                  { t('signin.login') }
                </Button>
              </Grid>
              
              <Grid item sm={12} md={6}>
                <Button
                  fullWidth
                  component={RLink}
                  className="my-2"
                  variant="outlined"
                  color="primary"
                  to={getSignUpLocation()}
                >
                  { t('signin.register') }
                </Button>
              </Grid>
            </Grid>

            <div className="my-1 smallFont">
              <p>{t('signin.bySignup')}</p>
              <Link href={hostRoot+'tr_terms'} target="_blank">
                { t('signin.termsTR') }
              </Link> | <Link href={hostRoot+'terms'} target="_blank">
                { t('signin.termsConditions') }
              </Link> | <Link href={hostRoot+'privacy'} target="_blank">
                { t('signin.privacyPolicy') }
              </Link>
            </div>
          </Grid>
        </form>

        {
          YoubrioApiHelper.getRegion() === 'cn'/*  && GLOBAL.get('lang').includes('cn') */
            ? <Box mt={2}>
              <Grid container direction='column' alignItems='center'>
                <Typography variant='caption'>备案号：<Link href={'http://beian.miit.gov.cn/'} target="_blank">沪ICP备18010838号-2</Link></Typography>
                <Typography variant='caption'>
                  <img src="https://www.youbrio.cn/public/images/police-beian.png" style={{ float: 'left', marginRight: 5 }}/>
                  <Link href={'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010702004483'} target="_blank">沪公网安备 31010702004483号</Link>
                </Typography>
              </Grid>
            </Box>
            : null
        }
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} md={5}>
        { renderLeftPanel() }
      </Grid>
      <Grid item xs={12} md={7}>
        <div className="fill">
          <img alt={ t('images.youbrioLandingImg') } src={ApiHelper.getCdnUrl('/public/images/login-main2.jpg')} />
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const {
    error
  } = state.auth;

  return {
    error
  };
};

export default connect(mapStateToProps, {
  youbrioLogin,
  loginByFacebookCallback,
  loginByGoogleCallback,
  loginByAppleCallback,
  updateAuthStates,
})(SigninScreen);
