import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  Grid,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  CircularProgress,
  InputBase,
  IconButton,
  Collapse,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import InfiniteScroll from 'react-infinite-scroller';
import {createFilter} from 'react-search-input';
import {
  getUserList
} from '../../actions';

import AppHelper from '../../helpers/AppHelper';

import './css/UserList.css';

const KEYS_TO_FILTERS = ['firstname', 'lastname'];

const _UserList = (props) => {
  const { t } = useTranslation();

  const isTeacher = AppHelper.isCBETeacher();
  const [searchTerm, setSearchTerm] = React.useState('');
  const { displayList, setDisplayList} = props;

  const onChangeSearchTerm = (event) => {
    setSearchTerm(event.target.value);
  };

  const onClickSearchIcon = (event) => {
    setDisplayList(true);
  }


  const path = 'userList';
  const filteredUserList = props.userList.filter(createFilter(searchTerm, KEYS_TO_FILTERS));

  if (_.get(props, path, true) === true || props.userListLoading) {
    return <CircularProgress />;
  }    

  if (_.get(props, path).length < 1) {
    return <Typography color="textSecondary">{t('dash.noUsers')}</Typography>;
  }

  return (
    <React.Fragment>
      {/* <Typography variant="h6" className="mb-3 px-3">
        { isTeacher ? t('dash.myStudent') : t('dash.myTeacher') }
      </Typography> */}
      <div className="search-container mb-2 br-3">
        <InputBase
          className="search-input br-3"
          placeholder={t('search')}
          inputProps={{ 'aria-label': 'search' }}
          onChange={onChangeSearchTerm}
        />
        <div className="search-icon-container">
          <SearchIcon
            onClick={onClickSearchIcon}
          />
        </div>
        <IconButton aria-label="collapseUserList" onClick={(event) => { setDisplayList(!displayList); }}>
        { displayList ?
          <ExpandLessIcon />
          :
          <ExpandMoreIcon />
        }
        </IconButton>
      </div>
      <Collapse in={displayList} timeout="auto" unmountOnExit>
        <List className="scroll-container px-3">
          <InfiniteScroll
            pageStart={props.page}
            loadMore={props.getUserList}
            hasMore={props.page < props.lastPage && !props.userListLoading && !props.userListLoadingMore}
            loader={<span key={0} />}
            useWindow={false}
          >
            {
              filteredUserList.map((item, index) => {
                const user = {
                  firtName: item.profileFirstName,
                  lastName: item.profileLastName,
                  avatar: item.profileAvatarUrl,
                };
                if (!isTeacher) {
                  user.firtName = item.firstname;
                  user.lastName = item.lastname;
                  user.avatar = item.avatarURL;
                  user.subjects = item.subjects;
                }

                return (
                  <ListItem key={index.toString()} button className="white-bg br-3 mb-2" onClick={() => console.log(item)}>
                    <ListItemIcon>
                      <Avatar alt={user.lastName} src={user.avatar} />
                    </ListItemIcon>
                    <Grid container direction="column">
                      <ListItemText primary={AppHelper.getFullname(user.firtName, user.lastName)} />

                      <Grid container direction="row">
                        {user.subjects ? (
                          user.subjects.slice(0, 6).map((sub) => <Avatar key={sub} src={sub} className="subject-icon" /> )
                        )
                          : null}
                      </Grid>
                    </Grid>
                    <ListItemIcon>
                      <Chip size="small" label={isTeacher ? t('student') : t('teacher')} />
                    </ListItemIcon>
                  </ListItem>
                );
              })
            }
          </InfiniteScroll>
        </List>
      </Collapse>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const {
    page,
    lastPage,
    userList,
    userListLoading,
    userListLoadingMore,
  } = state.youbrioProfile;

  return {
    page,
    lastPage,
    userList,
    userListLoading,
    userListLoadingMore,
  };
};

export const UserList = connect(mapStateToProps, {
  getUserList,
})(_UserList);
