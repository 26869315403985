import {
  UPDATE_NEW_PIECE_EVALUATION_FIELD,
  UPDATE_NEW_PIECEEVALUATION_STATE_ERROR,
  UPDATE_PIECE_EVALUATION_STATE,
  UPDATE_EDIT_PIECE_EVALUATION_FIELD,
  UPDATE_PIECE_EVALUATION_MODAL_STATE,
  RESET_PIECE_EVALUATION_MODAL,
  RESET_NEW_PIECE_EVALUATION_FIELD,
  UPDATE_EDIT_PIECE_EVALUATION_FIELD_ERROR,
  RESET_EDIT_PIECE_EVALUATION_DETAILS,
  RESET_EDIT_PIECE_EVALUATION_HAS_CHANGED,
  UPDATE_EDIT_PIECE_EVALUATION_VIDEO_FIELD,
  LOGOUT_USER_SUCCESS
} from '../actions/types';

const INITIAL_PIECE_EVALUATION_FIELD = {
  pieceEvaluationOptionId: '1',
  pieceEvaluationOptions: [],
  price: 0,
  instrumentId: '',
  options: [],
  error: {
    price: ''
  },
  currencyCode: '-',
  studentId: null,
  pieceEvaluationId: '',
  studentList: []
};

const INITIAL_PIECE_EVALUATION_MODAL_STATE = {
  showModal: false,
  message: '',
  title: '',
  mode: null
};

const INITIAL_EDIT_PIECE_EVALUATION_DETAILS_FIELD = {
  instrumentId: '',
  instrumentName: '',
  pieceNameOwn: '',
  pieceNameId: '',
  pieceComposerOwn: '',
  video: {
    type: 'link',
    link: '',
    filePath: '',
    thumbnailPath: '',
    fileHash: '',
    uploadedLink: ''
  },
  cbeBased: '',
  musicTypeId: '',
  musicTypes: [],
  pieceEvaluationId: '',
  hasPieceEvaluationChanged: false,
  pieceNameList: [],
  studentFirstName: '',
  studentLastName: '',
  optionName: '',
  error: {
    instrumentId: '',
    pieceNameId: '',
    videoLink: '',
    uploadedLink: '',
    pieceNameOwn: '',
    pieceComposerOwn: ''
  }
};

const INITIAL_STATE = {
  newPieceEvaluationField: INITIAL_PIECE_EVALUATION_FIELD,
  pieceEvaluationList: [],
  openPieceEvaluationList: [],
  closedPieceEvaluationList: [],
  isPieceEvaluationListLoading: false,
  editPieceEvaluationDetailsField: INITIAL_EDIT_PIECE_EVALUATION_DETAILS_FIELD,
  pieceEvaluationModal: INITIAL_PIECE_EVALUATION_MODAL_STATE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_NEW_PIECE_EVALUATION_FIELD:
      return { ...state, newPieceEvaluationField: { ...state.newPieceEvaluationField, [action.payload.prop]: action.payload.value } };
    case UPDATE_NEW_PIECEEVALUATION_STATE_ERROR:
      return { ...state, newPieceEvaluationField: { ...state.newPieceEvaluationField, error: { [action.payload.prop]: action.payload.error } } };
    case UPDATE_PIECE_EVALUATION_STATE:
      return { ...state, [action.payload.prop]: action.payload.value };
    case UPDATE_EDIT_PIECE_EVALUATION_FIELD:
      return { ...state, editPieceEvaluationDetailsField: { ...state.editPieceEvaluationDetailsField, [action.payload.prop]: action.payload.value, hasPieceEvaluationChanged: true } };
    case RESET_EDIT_PIECE_EVALUATION_HAS_CHANGED:
      return { ...state, editPieceEvaluationDetailsField: { ...state.editPieceEvaluationDetailsField, hasPieceEvaluationChanged: false } };
    case UPDATE_PIECE_EVALUATION_MODAL_STATE:
      return { ...state, pieceEvaluationModal: { ...state.pieceEvaluationModal, [action.payload.prop]: action.payload.value } };
    case RESET_PIECE_EVALUATION_MODAL:
      return { ...state, pieceEvaluationModal: INITIAL_PIECE_EVALUATION_MODAL_STATE };
    case RESET_NEW_PIECE_EVALUATION_FIELD:
      return { ...state, newPieceEvaluationField: INITIAL_PIECE_EVALUATION_FIELD };
    case UPDATE_EDIT_PIECE_EVALUATION_FIELD_ERROR:
      return { ...state, editPieceEvaluationDetailsField: { ...state.editPieceEvaluationDetailsField, error: { ...state.editPieceEvaluationDetailsField.error, [action.payload.prop]: action.payload.error } } };
    case RESET_EDIT_PIECE_EVALUATION_DETAILS:
      return { ...state, editPieceEvaluationDetailsField: INITIAL_EDIT_PIECE_EVALUATION_DETAILS_FIELD };
    case UPDATE_EDIT_PIECE_EVALUATION_VIDEO_FIELD:
      return { ...state, editPieceEvaluationDetailsField: { ...state.editPieceEvaluationDetailsField, video: { ...state.editPieceEvaluationDetailsField.video, [action.payload.prop]: action.payload.value } } };
    case LOGOUT_USER_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
