import {
  UPDATE_RECURRING_BILL_DRAFT_ORDER_STATE,
  RESET_RECURRING_BILL_DRAFT_ORDER_STATE,
  LOGOUT_USER_SUCCESS
} from '../actions/types';

const INITIAL_DRAFT_ORDER_STATE = {
  order: null,
  selectedGatewayPaymentMethod: null
};

const INITIAL_STATE = {
  draftOrder: INITIAL_DRAFT_ORDER_STATE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UPDATE_RECURRING_BILL_DRAFT_ORDER_STATE:
    return { ...state, draftOrder: { ...state.draftOrder, [action.payload.prop]: action.payload.value } };
  case RESET_RECURRING_BILL_DRAFT_ORDER_STATE:
    return { ...state, draftOrder: INITIAL_DRAFT_ORDER_STATE };
  case LOGOUT_USER_SUCCESS:
    return INITIAL_STATE;
  default:
    return state;
  }
};
