import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/HighlightOff';
import AlertIcon from '@material-ui/icons/NotificationsNone';
import IconPerson from '@material-ui/icons/Person';
import { blue } from '@material-ui/core/colors';

import ControlRoomSocketHelper from '../../helpers/ControlRoomSocketHelper';
import { updateCommonStates } from '../../actions';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@material-ui/core';

import {
  MessageBox,
  Message
} from 'element-react';
import ParticipantListItem from './ParticipantListItem';
import {
  updatePracticeRoom as updatePracticeRoomApi,
} from '../../api';
import {
  getControlRoomAllRoomParticipants,
  getControlRoomSingleRoomParticipants,
  updateControlRoomParticipantNote,
  updateControlRoomPracticeParticipantLock,
  kickParticipantFromControlRoom
} from '../../actions';
import AppHelper from '../../helpers/AppHelper';

const { PRACTICE_ROOM_EVENT } = require('../../helpers/ConstantHelper');


let selectedParticipant = null;

const SingleRoomParticipantList = (props) => {
  const styles = {
    avatar: {
      backgroundColor: blue[100],
      color: blue[600],
    }
  };

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [kickDialogText, setKickDialogText] = useState('');
  const [isSelectTutorPopoverOpened, setIsSelectTutorPopoverOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [selectTutorAnchorEl, setSelectTutorAnchorEl] = useState(false);

  const {
    isAllRoomLoading,
    practiceRoom,
    participants,
    numberOfTeacher,
    numberOfStudent,
    now,
    activeTeachersList
  } = props;

  const isLoading = (isAllRoomLoading || loading);

  const openKickDialog = ({ practiceRoomParticipantId }) => {
    const participant = participants.find(p => p.practice_room_participant_id === practiceRoomParticipantId);

    if (participant) {
      selectedParticipant = participant;
      setKickDialogText(t('controlRoom.kickStudentFromRoomQuestion',
        {
          participant: AppHelper.formatName(participant.first_name, participant.last_name?.charAt(0))?.split('[')?.[0],
          room: practiceRoom.name
        })
      );
      setIsDialogOpen(true);
    }
  };

  const closeDialog = () => {
    selectedParticipant = null;
    setKickDialogText('');
    setIsDialogOpen(false);
  };

  const onConfirmKick = () => {
    setLoading(true);
    console.log(selectedParticipant);
    props.kickParticipantFromControlRoom({
      meetId: practiceRoom.meet_id,
      practiceRoomId: practiceRoom.id,
      logUserId: selectedParticipant.user_id,
      callback: () => {
        setLoading(false);
        refreshRoomList();
      }
    });
    closeDialog();
  };

  const refreshRoomList = () => {
    setLoading(true);
    props.getControlRoomSingleRoomParticipants({
      practiceRoomId: practiceRoom.id,
      callback: () => {
        setLoading(false);
      }
    });
  };

  const requestClosePracticeRoom = async ({practiceRoomId}) => {
    try {
      setLoading(true);
      let response = await updatePracticeRoomApi({
        practiceRoomId,
        is_available: false,
      });
      response = response.data;
      setLoading(false);
      return response;
    } catch (e) {
      console.error(e);
      setLoading(false);
      return false;
    }
  };

  const onClickCloseRoomButton = async ({ practiceRoomId }) => {
    MessageBox.msgbox({
      'message': t('controlRoom.closeRoomQuestion'),
      title: t('controlRoom.closeRoom'),
      type: 'warning',
      showCancelButton: true,
      cancelButtonText: t('controlRoom.cancel'),
      confirmButtonText: t('controlRoom.confirm'),
    }).then(async(action) => {
      if (action !== 'confirm') {
        Message({
          type: 'info',
          message: t('controlRoom.canceled')
        });
        return;
      }
      const response = await requestClosePracticeRoom({ practiceRoomId });
      if (response) {
        props.getControlRoomAllRoomParticipants(); // refreshRoomList();
        Message({
          type: 'success',
          message: t('controlRoom.success')
        });
      } else {
        Message({
          type: 'warning',
          message: t('controlRoom.fail')
        });
      }
    }).catch((err) => {
      Message({
        type: 'err',
        message: err
      });
    });
  };

  const onSaveParticipantNotePress = ({
    practiceParticipantDetailId,
    userId,
    remarks,
    practiceSubjectId,
    remarksEditedAt,
    callback
  }) => {
    setLoading(true);
    props.updateControlRoomParticipantNote({
      practiceParticipantDetailId,
      userId,
      remarks,
      practiceSubjectId,
      remarksEditedAt,
      practiceRoomId: practiceRoom.id,
      callback: ({ response, error }) => {
        setLoading(false);
        callback({ response, error });
      }
    });
  };

  const onUpdatePracticeParticipantLock = ({
    practiceParticipantDetailId,
    practiceSubjectId,
    userId,
    lock,
    lastLockedBy,
    callback
  }) => {
    setLoading(true);
    props.updateControlRoomPracticeParticipantLock({
      practiceParticipantDetailId,
      practiceSubjectId,
      userId,
      lock,
      lastLockedBy,
      practiceRoomId: practiceRoom.id,
      callback: ({ response, error }) => {
        setLoading(false);
        callback({ response, error });
      }
    });
  };

  const renderKickDialog = () => {
    return (
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('controlRoom.kickStudentQuestion')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {kickDialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            {t('alert.cancel')}
          </Button>
          <Button onClick={onConfirmKick} color="primary">
            {t('controlRoom.kick')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  /**
   * When select tutor is pressed.
   *
   * @returns {void}
   */
  const _onSelectTutorPress = () => {
    setIsSelectTutorPopoverOpened(true);
  };

  const handleClose = () => {
    setIsSelectTutorPopoverOpened(false);
  };

  const onSelectedTutorPress = (tutor) => {
    setLoading(true);

    const me = AppHelper.getUser();
    const fromUser = {
      user_id: me.id,
      name: AppHelper.formatName(me.firstName, me.lastName)
    };

    const params = {
      serverUrl: tutor.annot_server_url,
      roomId: tutor.room_id,
      event: 'pushEvent',
      eventData: {
        event_type_id: PRACTICE_ROOM_EVENT.RESCUE_TR_STUDENT,
        data: {
          userIds: [tutor.user_id],
          student: {
            user_id: 0,
            name: 'Room Opened: '+tutor.room_name
          },
          fromUser
        },
        session_id: tutor.room_id,
        user_id: me.id
      }
    };

    ControlRoomSocketHelper.oneOffEmit(params)
      .then((response) => {
        console.log('done oneOffEmit', response);
      })
      .catch((error) => {
        console.log('error oneOffEmit', error);
      });

    setIsSelectTutorPopoverOpened(false);
    setLoading(false);
  };

  const renderSelectTutorPopover = () => {
      if (activeTeachersList != null){

      return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={isSelectTutorPopoverOpened}>
          <DialogTitle id="simple-dialog-title">{t('controlRoom.selectTutor')}</DialogTitle>
          <List>

            {activeTeachersList.map((tutor) => (
              <ListItem button onClick={() => onSelectedTutorPress(tutor)} key={tutor.key}>
                <ListItemAvatar>
                  <Avatar style={styles.avatar}>
                    <IconPerson />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={ tutor.first_name + ' ' + tutor.last_name + '(' + tutor.room_name + ')'} />
              </ListItem>
            ))}
          </List>
        </Dialog>
      );
    }

  };

  const render = () => {
    return (
      <div className='single-room'>
        <div className='room-header'>
          <img src={practiceRoom.icon_url} alt={practiceRoom.practiceRoomId} height={25} width={25} />
          <div className='room-title'>{`${practiceRoom.name} (T:${numberOfTeacher} | S:${numberOfStudent})`}</div>
          <RefreshIcon
            className={isLoading ? 'spinning' : 'button'}
            onClick={isLoading ? null : refreshRoomList}
          />
          {
            practiceRoom.is_open
              ?
              <AlertIcon
                className={'button'}
                onClick={isLoading ? null : _onSelectTutorPress}
              />
              :
              null
          }
          {
            practiceRoom.is_open
              ?
              <CloseIcon
                className={'button'}
                onClick={isLoading ? null : () => onClickCloseRoomButton({ practiceRoomId: practiceRoom.id })}
              />
              :
              null
          }
        </div>
        <div>
          {
            participants.map((participant) => (
              <ParticipantListItem
                key = { participant.user_id }
                now = { now }
                isLoadingParticipant = { isLoading }
                onSaveParticipantNotePress = { onSaveParticipantNotePress }
                onUpdatePracticeParticipantLock = { onUpdatePracticeParticipantLock }
                onKickPress = { openKickDialog }
                participant = { participant }
                practiceRoom = { practiceRoom }
                participants = { participants }
              />
            ))
          }
        </div>
        { renderKickDialog() }
        { renderSelectTutorPopover() }
      </div>
    );
  };

  return render();
}

const mapStateToProps = (state, props) => {
  const {
    isAllRoomLoading,
    activeTeachersList
  } = state.practiceRoom.controlRoom;

  const {
    participants
  } = props;

  const students = participants.filter(p => p.practice_room_role_id === 1);
  // since there are only students and teachers for now, we avoid iterating the list to get number of teachers
  // const teachers = participants.filter(p => p.practice_room_role_id === 2);

  return {
    isAllRoomLoading,
    numberOfTeacher: participants.length - students.length,
    numberOfStudent: students.length,
    activeTeachersList: activeTeachersList
  };
};

export default connect(mapStateToProps, {
  getControlRoomAllRoomParticipants,
  getControlRoomSingleRoomParticipants,
  updateControlRoomParticipantNote,
  updateControlRoomPracticeParticipantLock,
  kickParticipantFromControlRoom,
  updateCommonStates
})(SingleRoomParticipantList);
