import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import BTDropIn from 'braintree-web-drop-in';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchBraintreeClientToken } from '../../actions';
import AppHelper from '../../helpers/AppHelper';
import { useTranslation } from 'react-i18next';

const BraintreeDropIn = (props) => {
  const {
    paypalOptions,
    cardOptions,
    clientToken: _clientToken,
    payButtonLabel,
    onError,
    onInitialized,
    onPaymentMethodNonceReceived
  } = props;

  const { t } = useTranslation();
  const [btInstance, setBtInstance] = useState(null);
  const [clientToken, setClientToken] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!_clientToken) {
      props.fetchBraintreeClientToken({
        callback: ({ token, error }) => {
          if (token) {
            setClientToken(token);
          } else if (error) {
            console.log(error);
          }
        }
      });
    } else {
      setClientToken(_clientToken);
    }

    return () => {
      // console.log('BT unmount');
      if (btInstance) {
        btInstance.teardown();
        setIsReady(false);
      }
    };
  }, []);

  useEffect(() => {
    if (clientToken) {
      if (btInstance) {
        setIsReady(false);
        btInstance.teardown()
          .then(() => {
            initializeBraintree(clientToken);
          });
      } else {
        initializeBraintree(clientToken);
      }
    }
  }, [clientToken]);

  const initializeBraintree = (token) => {
    const btOptions = {
      // insert your tokenization key or client token here
      authorization: token,
      container: '#braintree-drop-in-div',
      paypal: {
        singleUse: true, // Required
        button: {
          type: 'checkout'
        }
      },
      locale: AppHelper.getAppLocaleCodeForApi()
    };

    if (cardOptions !== undefined) {
      btOptions.card = cardOptions;
    }

    if (paypalOptions !== undefined) {
      btOptions.paypal = paypalOptions;
    }

    return BTDropIn.create(btOptions, function (error, instance) {
      if (error) {
        onError(error);
      } else {
        setIsReady(true);
        setBtInstance(instance);
        onInitialized({
          instance,
          getPaymentMethodNonce: authorizePaymentMethod
        });
      }
    });
  };

  const onPayClick = () => {
    if (btInstance) {
      btInstance.requestPaymentMethod(
        (error, payload) => {
          if (error) {
            console.error(error);
            onError(error);
          } else {
            const paymentMethodNonce = payload.nonce;
            // console.log('payment method nonce', payload.nonce);

            onPaymentMethodNonceReceived({ nonce: paymentMethodNonce });
          }
        });
    }
  };

  const authorizePaymentMethod = () => {
    return new Promise((resolve, reject) => {
      // console.log('get nonce invoked');
      if (btInstance) {
        btInstance.requestPaymentMethod(
          (error, payload) => {
            if (error) {
              console.error(error);
              reject(error);
            } else {
              const paymentMethodNonce = payload.nonce;
              // console.log('payment method nonce', payload.nonce);
  
              resolve({ nonce: paymentMethodNonce });
            }
          });
      }
    });
  };

  const renderLoadingSpinner = () => {
    if (!isReady) {
      return (
        <Grid
          container
          alignItems='center'
          justifyContent='center'
          style={{
            minHeight: 100
          }}
        >
          <CircularProgress />
        </Grid>
      );
    }

    return null;
  };

  const render = () => {
    return (
      <Grid container direction='column' style={{ position: 'relative' }}>
        <Grid
          id={'braintree-drop-in-div'}
        />
        {
          btInstance
            ? <Grid container justifyContent='flex-end'>
              <Box ma={2} mb={2}>
                <Button
                  variant="contained"
                  onClick={onPayClick}
                  color='primary'
                >
                  {payButtonLabel || t('wallet.pay')}
                </Button>
              </Box>
            </Grid>
            : null
        }
        {renderLoadingSpinner()}
      </Grid>
    );
  };

  return render();
};

const _DropIn = connect(null, {
  fetchBraintreeClientToken
})(BraintreeDropIn);

export { _DropIn as BraintreeDropIn };