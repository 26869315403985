import {
  APP_LOADING_STARTED,
  APP_LOADING_FINISHED,
  OPEN_DIALOG,
  CLOSE_DIALOG,
  LOGOUT_USER_SUCCESS
} from '../actions/types';

const INITIAL_DIALOG_STATE = {
  props: {},
  open: false,
  component: null
};

const INITIAL_STATE = {
  dialog: INITIAL_DIALOG_STATE,
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case APP_LOADING_STARTED:
    return { ...state, loading: true };
  case APP_LOADING_FINISHED:
    return { ...state, loading: false };
  case OPEN_DIALOG:
    return {
      ...state,
      dialog: {
        ...state.dialog,
        component: action.payload.dialog,
        props: action.payload.props,
        open: true
      }
    };
  case CLOSE_DIALOG:
    return { ...state, dialog: INITIAL_DIALOG_STATE };
  case LOGOUT_USER_SUCCESS: 
    return INITIAL_STATE;
  default:
    return state;
  }
};
