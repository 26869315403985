import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { getCountryList } from '../../actions';
import { useTranslation } from 'react-i18next';

const _CountryPicker = (props) => {
  const {
    countries,
    selectedCountry,
    onSelect,
    ...otherProps
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    props.getCountryList();
  }, []);

  const [searchText, setSearchText] = useState('');

  const containsText = (text, searchText) =>
    text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const displayedOptions = useMemo(
    () => countries.filter((option) => containsText(option.country, searchText) || containsText(option.country_en, searchText)),
    [searchText]
  );

  const render = () => {
    return (
      <FormControl fullWidth>
        <TextField
          select
          variant="outlined"
          // Disables auto focus on MenuItems and allows TextField to be in focus
          autoComplete="fname"
          MenuProps={{ autoFocus: false }}
          labelId="country-select-label"
          id="select-country"
          value={selectedCountry}
          label="Options"
          onChange={(e) => onSelect(e.target.value)}
          onClose={() => setSearchText('')}
          // This prevents rendering empty string in Select's value
          // if search text would exclude currently selected option.
          // renderValue={() => selectedCountry}
          {...otherProps}
        >
          {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
          <ListSubheader>
            <Paper square elevation={0}>
              <Box pt={1}>
                <TextField
                  // Autofocus on textfield
                  autoFocus
                  placeholder={`${t('form.typeToSearch')}...`}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== 'Escape') {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </Box>
            </Paper>
          </ListSubheader>
          {displayedOptions.map((option, i) => (
            <MenuItem key={i} value={option.id}>
              {option.country}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    );
  };

  return render();
};

const mapStateToProps = (state) => {
  const {
    countries
  } = state.common;

  return {
    countries
  };
};

const CountryPicker = connect(mapStateToProps, {
  getCountryList
})(_CountryPicker);
export { CountryPicker };