import {
  UPDATE_EVENT_STATE,
  UPDATE_EVENT_DETAILS_STATE,
  SHOW_EVENT_MODAL,
  HIDE_EVENT_MODAL,
  UPDATE_NEW_EVENT_ENROLMENT_STATE,
  UPDATE_EDIT_EVENT_ENROLMENT_STATE,
  RESET_NEW_EVENT_ENROLMENT_STATE,
  RESET_EVENT_DETAILS_STATE,
  RESET_EDIT_EVENT_ENROLMENT_STATE,
  LOGOUT_USER_SUCCESS,
  UPDATE_EDIT_EVENT_ENROLMENT_ERROR
} from '../actions/types';

const INITIAL_EVENT_MODAL_STATE = {
  mode: 'success',
  title: '',
  content: '',
  visible: false
};

const INITIAL_EVENT_DETAILS = {
  eventId: '',
  eventName: '',
  eventDescription: '',
  backgroundUri: '',
  eventStatus: '',
  eventDate: '',
  eventCategory: '',
  eventLinks: []
};

const INITIAL_EVENT_ENROLMENT_STATE = {
  eventName: '',
  ageGroupName: '',
  upperAgeLimit: '',
  lowerAgeLimit: '',
  eventLocation: '',
  qrEnabled: false,
  eventDate: '',
  studentList: [],
  studentId: '',
  eventId: '',
  musicGroupId: '',
  musicGroupName: '',
  subEvents: [],
  eventEnrolmentId: '',
  subEventId: '',
  currencyCode: '',
  fee: '',
  duration: '',
  affiliateCode: ''
};

const INITIAL_EDIT_EVENT_ENROLMENT_STATE = {
  eventEnrolmentId: '',
  eventId: '',
  enrolmentStatus: '',
  eventName: '',
  eventLocation: '',
  eventDate: '',
  subEventId: '',
  subEventName: '',
  ageGroupId: '',
  ageGroupName: '',
  ageUpper: '',
  ageLower: '',
  genres: [],
  genreId: '',
  musicGroupId: '',
  musicGroupName: '',
  duration: '',
  videoLink: '',
  musicTitle: '',
  firstName: '',
  lastName: '',
  studentId: '',
  videoFilePath: '',
  videoFileHash: '',
  videoThumbnailPath: '',
  photoIdFilePath: '',
  photoIdUrl: '',
  isVideoCompressed: true,
  error: {
    video: '',
    photoId: '',
    genre: '',
    musicTitle: ''
  }
};

const INITIAL_STATE = {
  homeEventList: [],
  currentEventList: [],
  pastEventList: [],
  auditionList: [],
  competitionList: [],
  isCurrentEventListLoading: false,
  isPastEventListLoading: false,
  isHomeEventListLoading: false,
  isEventEnrolmentListLoading: false,
  isAuditionListLoading: false,
  isCompetitionListLoading: false,
  eventEnrolmentList: [],
  eventDetails: INITIAL_EVENT_DETAILS,
  eventModal: INITIAL_EVENT_MODAL_STATE,
  newEventEnrolment: INITIAL_EVENT_ENROLMENT_STATE,
  editEventEnrolment: INITIAL_EDIT_EVENT_ENROLMENT_STATE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_EVENT_STATE:
      return { ...state, [action.payload.prop]: action.payload.value };
    case UPDATE_EVENT_DETAILS_STATE:
      return { ...state, eventDetails: { ...state.eventDetails, [action.payload.prop]: action.payload.value } };
    case SHOW_EVENT_MODAL:
      return { ...state, eventModal: { title: action.payload.title, content: action.payload.content, mode: action.payload.mode, visible: true } };
    case HIDE_EVENT_MODAL:
      return { ...state, eventModal: { ...state.eventModal, visible: false } };
    case UPDATE_NEW_EVENT_ENROLMENT_STATE:
      return { ...state, newEventEnrolment: { ...state.newEventEnrolment, [action.payload.prop]: action.payload.value } };
    case UPDATE_EDIT_EVENT_ENROLMENT_STATE:
      return { ...state, editEventEnrolment: { ...state.editEventEnrolment, [action.payload.prop]: action.payload.value } };
    case RESET_NEW_EVENT_ENROLMENT_STATE:
      return { ...state, newEventEnrolment: INITIAL_EVENT_ENROLMENT_STATE };
    case RESET_EVENT_DETAILS_STATE:
      return { ...state, eventDetails: INITIAL_EVENT_DETAILS };
    case RESET_EDIT_EVENT_ENROLMENT_STATE:
      return { ...state, editEventEnrolment: INITIAL_EDIT_EVENT_ENROLMENT_STATE };
    case UPDATE_EDIT_EVENT_ENROLMENT_ERROR:
      return { ...state, editEventEnrolment: { ...state.editEventEnrolment, error: { ...state.editEventEnrolment.error, [action.payload.prop]: action.payload.error } } };
    case LOGOUT_USER_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
