import YoubrioApiHelper from '../helpers/YoubrioApiHelper';

export const fetchRecurringBillDraftOrder = ({
  recurringBillTypeId,
  params
}) => {
  const _params = {
    recurringBillTypeId,
    params
  };
  return YoubrioApiHelper.post('recurring-draft-order', _params, true);
};

export const createRecurringBillDraft = ({
  recurringBillTypeId,
  params
}) => {
  const _params = {
    recurringBillTypeId,
    params
  };
  return YoubrioApiHelper.post('recurring-bill-draft', _params, true);
};

export const fetchRecurringBillDraft = ({ referenceId }) => {
  return YoubrioApiHelper.get(`recurring-bill-draft/${referenceId}`, true);
};

export const createNewRecurringBill = ({
  recurringBillTypeId,
  gatewayPaymentMethodId,
  params,
  referenceId
}) => {
  const _params = {
    recurringBillTypeId,
    gatewayPaymentMethodId,
    params,
    referenceId
  };
  return YoubrioApiHelper.post('recurring-bill', _params, true);
};