// custom history to allow more flexbility e.g. use history in redux
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const historyPush = (...param) => {
  history.push(...param);
  window.location.reload();
};

const historyReplace = (...param) => {
  history.replace(...param);
  window.location.reload();
};

export default history;

export {
  historyPush,
  historyReplace
};