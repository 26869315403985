/* @flow */

import React from 'react';
import Lottie from 'lottie-react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';

import teachModeAnimation from '../../../img/lottie/teaching.json';
import practiceModeAnimation from '../../../img/lottie/weightlifting.json';
import IconClose from '@material-ui/icons/Close';
import { IconRaisedHand } from '../../Icons';
import { closeDialog } from '../../../actions';
import { BaseDialog } from './BaseDialog';
import '../../ControlRoomScreen/ControlRoom.css';

/**
 * Implements a component for the dialog that displays the information of different learn modes.
 */
const _LearnModeInfoDialog = (props) => {
  const {
    _open,
    _onClose,
    closeDialog: _closeDialog,
    ...otherProps
  } = props;

  const { t } = useTranslation();

  /**
   * Callback to be invoked for cancel action (user wants to send a group message).
   *
   * @returns {boolean}
   */
  const _onDialogCancel = () => {
    _closeDialog();
  };

  /**
   * Renders the header of the dialog.
   *
   * @returns {ReactElement}
   */
  const renderHeader = () => {
    return (
      <div className = { 'header-btn dialog-close' }>
        <IconButton
          onClick = { _onDialogCancel }>
          <IconClose
            style={{ fill: '#ffffff', fontSize: 18 }} />
        </IconButton>
      </div>
    );
  };

  /**
   * Renders the body of the dialog.
   *
   * @returns {ReactElement}
   */
  const renderBody = () => {
    return (
      <div className = { 'dialog-container' }>
        <div className = { 'dialog-info-header' }>{t('tutorRoom.learnMode.learnModeInfoHeader')}</div>
        <div
          className = { 'dialog-tutor-mode dialog-info-text' }>
          <Lottie
            animationData={teachModeAnimation}
            loop
            autoplay />
        </div>
        <div className = 'dialog-info-text'>{t('tutorRoom.learnMode.learnModeInfo1')}</div>
        <div
          className = { 'dialog-practice-mode dialog-info-text' }>
          <Lottie
            animationData={practiceModeAnimation}
            loop
            autoplay />
        </div>
        <div className = 'dialog-info-text'>{t('tutorRoom.learnMode.learnModeInfo2')}</div>
        <div
          className = 'dialog-icon raisehand dialog-info-text'>
          <div className = 'toolbox-icon'>
            <IconRaisedHand />
          </div>
        </div>
        <div className = 'dialog-info-text'>{t('tutorRoom.learnMode.learnModeInfo3')}</div>
      </div>
    );
  };

  /**
   * Implements React's {@link Component#render()}.
   *
   * @inheritdoc
   * @returns {ReactElement}
   */
  const render = () => {
    // return (
    //   <div
    //     className = { 'none' } >
    //     <Dialog
    //       components = {{
    //         Footer: () => <div />,
    //         Header: this.renderHeader
    //       }}
    //       onCancel = { this._onDialogCancel }
    //       width = 'small' >
    //       { this.renderBody() }
    //     </Dialog>
    //   </div>
    // );

    return (
      <BaseDialog
        open={_open}
        onClose={_onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...otherProps}
        maxWidth={'xs'}
      >
        <DialogTitle id="alert-dialog-title">
          {renderHeader()}
        </DialogTitle>
        <DialogContent>
          {renderBody()}
        </DialogContent>
      </BaseDialog>
    );
  };

  return render();
};

/**
 * Maps part of the Redux store to the props of this component.
 *
 * @param {Object} state - The Redux state.
 * @param {Props} ownProps - The own props of the component.
 * @returns {Props}
 */
export function mapStateToProps(/* state: Object, ownProps: Props */) {
  return {};
}

const LearnModeInfoDialog = connect(mapStateToProps, {
  closeDialog
})(_LearnModeInfoDialog);

export { LearnModeInfoDialog };
