import {
  UPDATE_PAYMENT_HISTORY_LIST,
  LOGOUT_USER_SUCCESS,
  UPDATE_PAYMENT_HISTORY_LOADING,
  UPDATE_PAYMENT_STATES,
  SHOW_PAYMENT_MODAL,
  HIDE_PAYMENT_MODAL
} from '../actions/types';

const INITIAL_PAYMENT_MODAL_STATE = {
  mode: 'success',
  title: '',
  content: '',
  visible: false
};

const INITIAL_STATE = {
  paymentHistoryList: [],
  isPaymentHistoryLoading: false,
  braintreeToken: '',
  paymentModal: INITIAL_PAYMENT_MODAL_STATE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_PAYMENT_HISTORY_LIST:
      return { ...state, paymentHistoryList: action.payload };
    case UPDATE_PAYMENT_HISTORY_LOADING:
      return { ...state, isPaymentHistoryLoading: action.payload };
    case UPDATE_PAYMENT_STATES:
      return { ...state, [action.payload.prop]: action.payload.value };
    case SHOW_PAYMENT_MODAL:
      return { ...state, paymentModal: { title: action.payload.title, content: action.payload.content, mode: action.payload.mode, visible: true } };
    case HIDE_PAYMENT_MODAL:
      return { ...state, paymentModal: { ...state.paymentModal, visible: false } };
    case LOGOUT_USER_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
