import _ from 'lodash';
import { historyPush } from '../../src/history';
import {
  UPDATE_AUTH_STATES,
  LOGOUT_USER_SUCCESS,
} from './types';
import {
  loginByEmailAndPassword,
  getTokenByFB,
  getTokenByGoogle,
  getTokenByApple,
  initData,
} from '../api';
import { appStartsLoading, appDoneLoading } from './';
import GLOBAL from '../helpers/GLOBAL';
import NavigationHelper from '../helpers/NavigationHelper';

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS
  };
};

export const updateAuthStates = ({ prop, value }) => {
  return {
    type: UPDATE_AUTH_STATES,
    payload: { prop, value }
  };
};

export const loginUserAndSaveToken = (token) => {
  return async (dispatch) => {
    GLOBAL.set('token', token);

    // After authenticated user, init app with server data.
    const initRes = await initData();
    GLOBAL.logger.info(initRes);
    const { user } = initRes.data;

    dispatch(appDoneLoading());
    if (user) {
      GLOBAL.set('user', user);
      GLOBAL.set('isNewLaunch', true);
      GLOBAL.set('appSettings', _.get(initRes, 'data.appSettings'));

      NavigationHelper.redirectCachedRoute({ replace: true });
    } else {
      throw new Error('Invalid login or failed to init');
    }
  };
};

export const youbrioLogin = (email, password, remember = false) => {
  return async (dispatch) => {
    dispatch(appStartsLoading());
    try {
      const res = await loginByEmailAndPassword(email, password);
      
      const { token } = res.data;
      if (token) {
        dispatch(loginUserAndSaveToken(token));
        if (remember) {
          GLOBAL.set('email', email);
        }
      } else {
        throw new Error('Invalid login');
      }
    } catch (err) {
      dispatch(appDoneLoading());
      dispatch(updateAuthStates({ prop: 'error', value: true }));
      GLOBAL.logger.error(err);
    }
  };
};

export const loginByFacebookCallback = (fbResponse) => {
  return (dispatch) => {
    GLOBAL.logger.info(fbResponse);
    if (fbResponse.accessToken) {
      dispatch(appStartsLoading());

      getTokenByFB(fbResponse.accessToken)
        .then((response) => {
          GLOBAL.logger.info('FACEBOOK USER AUTHENTICATED', response);
          const { data } = response;
          dispatch(appDoneLoading());
          dispatch(loginUserAndSaveToken(data.token));
        })
        .catch((error) => {
          dispatch(appDoneLoading());
          console.error(error);
        });
    } else {
      console.error('Failed to login by Facebook');
    }
  };
};

export const loginByGoogleCallback = (ggResponse) => {
  return (dispatch) => {
    GLOBAL.logger.info(ggResponse);
    if (ggResponse.accessToken) {
      dispatch(appStartsLoading());

      getTokenByGoogle(ggResponse.accessToken)
        .then((response) => {
          GLOBAL.logger.info('GOOGLE USER AUTHENTICATED', response);
          const { data } = response;
          dispatch(appDoneLoading());
          dispatch(loginUserAndSaveToken(data.token));
        })
        .catch((error) => {
          dispatch(appDoneLoading());
          console.error(error);
        });
    } else {
      console.error('Failed to login by Google');
    }
  };
};

export const loginByAppleCallback = (appleResponse) => {
  return (dispatch) => {
    GLOBAL.logger.info(appleResponse);
    const { authorization, user, error } = appleResponse;
    if (authorization) {
      // user is authenticated
      GLOBAL.logger.info('APPLE USER AUTHENTICATED', appleResponse);
      dispatch(appStartsLoading());

      getTokenByApple({
        identityToken: authorization.id_token,
        authorizationCode: authorization.code,
        fullName: user
          ? { givenName: user.name.firstName, familyName: user.name.lastName }
          : { givenName: null, familyName: null }
      })
        .then((response) => {
          const { data } = response;
          dispatch(loginUserAndSaveToken(data.token));
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error('Failed to login by Appple', error);
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    historyPush('/sign-in');

    GLOBAL.logger.info('user logged out');
    GLOBAL.logoutClear();
    dispatch(logoutUserSuccess());
  };
};
