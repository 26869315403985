import ApiHelper from '../helpers/ApiHelper';
import AppHelper from '../helpers/AppHelper';
import YoubrioApiHelper from '../helpers/YoubrioApiHelper';

export const getUserProfile = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`profile?lang=${lang}`, true);
};

export const checkPrivateStudioId = (privateStudioId) => {
  return ApiHelper.get(`teacher/exist?privatestudioid=${privateStudioId}`, true);
};

export const saveUserProfile = (user, upgradeAccountRole) => {
  const params = new FormData();
  if (!AppHelper.isEmail(user.email)) {
    params.append('email', user.newEmail);
  }
  params.append('firstName', user.firstName);
  params.append('lastName', user.lastName);

  if (user.gender) {
    params.append('gender', user.gender);
  }/* else {
    params.append('gender', 'male');
  }*/

  // params.append('address1', user.address1);

  if (upgradeAccountRole) {
    params.append('cbeRole', upgradeAccountRole);

    if (
      upgradeAccountRole === 'Teacher'
      && user.isAllowFreeTrial !== undefined
      && user.isAllowFreeTrial !== null
    ) {
      params.append('isAllowFreeTrial', user.isAllowFreeTrial);
    }
  }
  // allow for all user
  params.append('dob', user.dob);

  // if (user.address2) {
  //   params.append('address2', user.address2);
  // }

  // params.append('suburb', user.suburb);
  // params.append('postCode', user.postCode);
  params.append('countryId', user.countryId);
  params.append('provinceId', user.provinceId);
  params.append('cityId', user.cityId);
  // params.append('state', user.state);

  // if (user.landLine) {
  //   params.append('landLine', user.landLine);
  // }

  params.append('mobile', user.mobile);

  if (user.privateStudioId) {
    params.append('privateStudioId', user.privateStudioId);
  }

  if (user.subscribed) {
    params.append('subscribed', user.subscribed);
  }

  if (user.qualification) {
    params.append('qualification', user.qualification);
  }

  if (user.teachLocation) {
    params.append('institutionType', user.teachLocation);
  }

  if (user.teachAddress) {
    params.append('institutionAddress', user.teachAddress);
  }

  if (user.avatarUrl && !user.avatarUrl.includes('https://')) {
    const fileName = AppHelper.getFileNameWithExtension(user.avatarUrl);
    const fileExt = AppHelper.getFileTypeExtension(user.avatarUrl);

    params.append('avatar', { uri: user.avatarUrl, name: fileName, type: `image/${fileExt.toLowerCase()}` });
  }

  if (user.cbeRole && user.cbeRole.includes('Teacher')) {
    if (user.abn) {
      params.append('abn', user.abn);
    } else {
      params.append('abn', '');
    }
    params.append('gst', user.gst);
  }

  const lang = AppHelper.getAppLocaleCodeForApi();

  return ApiHelper.post(`profile?lang=${lang}`, params, true, true);
};

export const updateUserProfileItem = (profile) => {
  const params = new FormData();

  if (profile.mobile) {
    params.append('mobile', profile.mobile);
  }

  return ApiHelper.post('profile/items', params, true, true);
};

export const getStudentList = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`teacher/students?lang=${lang}`, true);
};

export const getStudentListForExamEnrolment = () => {
  return ApiHelper.get('teacher/students?register=true', true);
};

export const getProfileForStudentList = ({ studentId }) => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return ApiHelper.get(`teacher/student/${studentId}?lang=${lang}`, true);
};

export const saveStudentProfile = (user, studentId) => {
  const params = new FormData();
  params.append('firstName', user.firstName);
  params.append('lastName', user.lastName);
  params.append('gender', user.gender);
  // params.append('address1', user.address1);

  // if (user.address2) {
  //   params.append('address2', user.address2);
  // }

  // params.append('suburb', user.suburb);
  // params.append('postCode', user.postCode);
  params.append('countryId', user.countryId);
  params.append('provinceId', user.provinceId);
  params.append('cityId', user.cityId);
  // params.append('state', user.state);

  if (user.landLine) {
    params.append('landLine', user.landLine);
  }

  params.append('mobile', user.mobile);

  if (user.subscribed) {
    params.append('subscribed', user.subscribed);
  }

  if (user.avatarUrl && !user.avatarUrl.includes('https://')) {
    const fileName = AppHelper.getFileNameWithExtension(user.avatarUrl);
    const fileExt = AppHelper.getFileTypeExtension(user.avatarUrl);

    params.append('avatar', { uri: user.avatarUrl, name: fileName, type: `image/${fileExt.toLowerCase()}` });
  }

  return ApiHelper.post(`teacher/student/${studentId}`, params, true, true);
};

export const deleteStudent = (studentId) => {
  return ApiHelper.post(`teacher/student/delete/${studentId}`, null, true);
};

export const changeUserEmail = ({ newEmail, oldEmail, password }) => {
  const params = {
    ne: newEmail,
    oe: oldEmail,
    _p: password
  };
  return ApiHelper.post('user/email', params, true);
};

export const updateUserPhysicalCountry = ({ countryCode }) => {
  const params = {
    pc: countryCode
  };
  return ApiHelper.post('user/country', params, true);
};

// START --- Youbrio API endpoints
export const getYoubrioProfile = () => {
  return YoubrioApiHelper.get('profile', true);
};

export const getUserYoubrioProfile = ({ userId }) => {
  return YoubrioApiHelper.get(`user/${userId}/profile`, true);
};
//   END --- Youbrio API endpoints
