import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { Grid, Paper, Typography, Button, Tooltip } from '@material-ui/core';
import moment from 'moment';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  getLessonsForCalendar,
  updateLessonState,
} from '../../../actions';

import './YoubrioCalendar.css';
import AppHelper from '../../../helpers/AppHelper';
import LessonItem from './LessonItem';
import YoubrioCalendarSlot from './YoubrioCalendarSlot';

const useStyles = makeStyles(() => ({
  underLineToday: {
    position: 'absolute',
    width: '50%',
    borderBottom: '2px solid ' + red[500],
    bottom: 0,
  },
  activeDay: {
    color: '#fff',
  },
  marginLeft: {
    marginLeft: 12,
  }
}));

const _YoubrioCalendar = (props) => {
  const today = moment();
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    selectedDay,
    studentLessons,
    teacherLessons,
    calendarLoading,
    getLessonsForCalendar,
    updateLessonState,
  } = props;

  const lessons = AppHelper.isCBETeacher() ? teacherLessons : studentLessons;

  const setSelectedDay = useCallback((date) => {
    updateLessonState({ prop: 'selectedDay', value: date });
  }, [updateLessonState]);

  useEffect(() => {
    getLessonsForCalendar();
    // DO NOT REMOVE - we need to reset date on component mount to ensure locale is correct
    setSelectedDay(moment());
  }, [getLessonsForCalendar, setSelectedDay]);
  
  const dayArray = AppHelper.getDaysArrayByMonth(selectedDay);

  const renderRightHeader = () => {
    return (
      <Grid container direction="row" justifyContent="space-between" alignItems="center" className="px-3">
        <Grid item>
          <Grid container direction="row" justifyContent="center" alignItems="center" >
            <Typography variant="h5" className="selected-day">{selectedDay.format('MMMM yyyy')}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Tooltip title={t('calendar.previousMonth')}>
            <Button color="primary" onClick={() => setSelectedDay(moment(selectedDay).subtract(1, 'M'))}><ChevronLeftIcon /></Button>
          </Tooltip>
          <Button
            color="primary"
            size="medium"
            onClick={() => setSelectedDay(today)}
          >
            { t('calendar.today') }
          </Button>
          <Tooltip title={t('calendar.nextMonth')}>
            <Button color="primary" onClick={() => setSelectedDay(moment(selectedDay).add(1, 'M'))}><ChevronRightIcon /></Button>
          </Tooltip>

          <ToggleButtonGroup
            exclusive
            value={'month'}
            size="small"
            color="primary"
            className={classes.marginLeft}
            onChange={() => console.log('btn group')}
            aria-label="view setting"
          >
            {/* <ToggleButton value="day" disabled aria-label="day view">
              { t('calendar.day') }
            </ToggleButton>
            <ToggleButton value="week" disabled aria-label="week view">
              { t('calendar.week') }
            </ToggleButton> */}
            <ToggleButton value="month" aria-label="month view">
              { t('calendar.month') }
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    );
  };

  /**
   * This renders the bottom part of the tiles.
   * Should be where to show the lesson slots.
   * @param {Date} day - The moment day object that it is rendering.
   */
  const renderCalendarTileContent = (day) => {
    if (calendarLoading) {
      return <Skeleton animation="wave" variant="rect" />;
    }

    // The key to access lessons that are in this day.
    // DO NOT change format as it must be 'DD_MM_YYYY'.
    const key = day.format('DD_MM_YYYY');

    return (
      <Grid container direction="column" alignItems="flex-start">
        {
          _.get(lessons, key, [])
            .slice(0, 2)
            .map(slot => <YoubrioCalendarSlot key={slot._id} slot={slot} />)
        }

        {
          _.get(lessons, key, []).length > 2
            ?
            <Typography style={{ cursor: 'pointer' }} variant="caption" color="textSecondary" onClick={() => setSelectedDay(day)}>{t('calendar.more')}</Typography>
            :null
        }
      </Grid>
    );
  };

  const renderCalendarTile = () => {
    return dayArray.map((value, index) => (
      <React.Fragment key={index}>
        {
          dayArray[index].map((day, i) => {
            const isToday = day.isSame(today, 'day');
            // same day or month as selectedDay
            const sameDay = day.isSame(selectedDay, 'day');
            const sameMonth = day.isSame(selectedDay, 'month');
            let color = !sameMonth ? 'textSecondary' : 'textPrimary';

            return (
              <div key={i} className="item px-1 py-1">
                <Grid container direction="column">
                  <Grid item>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                      <Button size="small" color="primary" disableElevation variant={sameDay ? 'contained' : 'text'} onClick={() => setSelectedDay(day)}>
                        <Typography color={color} className={clsx({ [classes.activeDay]: sameDay })}>
                          {day.format('D')}
                        </Typography>
                        { isToday ? <div className={classes.underLineToday} /> : null }
                      </Button>
                      {
                        index === 0 ?
                          <Typography color="textSecondary">{day.format('ddd')}</Typography>
                          :null
                      }
                    </Grid>
                  </Grid>
                  <Grid item>
                    { renderCalendarTileContent(day) }
                  </Grid>
                </Grid>
              </div>
            );
          })
        }
      </React.Fragment>
    ));
  };

  const renderDayView = () => {
    // const sameMonth = selectedDay.isSame(selectedDay, 'month');

    // The key to access lessons that are in this selectedDay.
    // DO NOT change format as it must be 'DD_MM_YYYY'.
    const key = selectedDay.format('DD_MM_YYYY');
    const slots = _.get(lessons, key, []);
    if (slots.length < 1) {
      return <Typography variant="body2" color="textSecondary">{t('calendar.noLessonOn')}</Typography>;
    }

    return slots.map((slot, index) => <LessonItem key={index} slot={slot} />);
  };

  const isToday = selectedDay.isSame(today, 'day');

  return (
    <Paper elevation={0} className="br-3 px-3 py-3">
      <div className="container">
        <div className="item">
          { renderRightHeader() }
        </div>
        <div className="item">
          <Grid container direction="column" className="py-3">
            <Typography className="px-3 pb-3">{t('calendar.myLessonsOn')} <strong>{isToday ? t('calendar.today') : selectedDay.format('LL')}</strong></Typography>
            <Grid item className="top-left-panel px-3">
              { renderDayView() }
            </Grid>

            {/* <Divider className="px-3" />
            
            <Grid item className="px-3 bottom-left-panel"></Grid> */}
          </Grid>
        </div>
        { renderCalendarTile() }
      </div>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  const {
    studentLessons,
    teacherLessons,
    selectedDay,
    calendarLoading,
  } = state.lesson;

  return {
    selectedDay,
    studentLessons,
    teacherLessons,
    calendarLoading,
  };
};

export const YoubrioCalendar = connect(mapStateToProps, {
  getLessonsForCalendar,
  updateLessonState,
})(_YoubrioCalendar);
