import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, InputBase, Divider, IconButton, LinearProgress, CircularProgress } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SendIcon from '@material-ui/icons/Send';

import ChatSocketHelper from '../../helpers/ChatSocketHelper';
import AppHelper from '../../helpers/AppHelper';
import GLOBAL from '../../helpers/GLOBAL';
import Message from './Message';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    position: 'relative',
    // backgroundColor: colors.green[50],
  },
  messageContainer: {
    height: '100%',
    padding: 20,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  messageInputOuter: {
    // backgroundColor: colors.lightGreen[50],
    height: 64,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
  },
  messageInputInner: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  loadingBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0
  },
  loadingMore: {
    textAlign: 'center',
    padding: 10,
  }
}));

const THRESHOLD = 100;
let currentId = null;
let previousId = null;

const ChatRightPanel = (props) => {
  const classes = useStyles();
  const {
    selectedChat,
  } = props;
  const [messages, setMessages] = useState([]);
  const [newestMsgId, setNewestMsgId] = useState(null);
  const [oldestMsgId, setOldestMsgId] = useState(null);
  const [connected, setConnected] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [endReached, setEndReached] = useState(false);
  const [typingMsg, setTypingMsg] = useState('');
  const messageContainer = React.createRef();
  // console.log('room', room);
  
  const loadEarlierMsg = () => {
    console.log('load earlier messages');
    setLoadingMore(true);
    ChatSocketHelper.emit('chat-old', {
      oldestMsgId: oldestMsgId,
      room_id: selectedChat.room_id,
      token: GLOBAL.get('token')
    });
  };

  const loadNewestMsg = () => {
    ChatSocketHelper.emit('chat-new', {
      newestMsgId: newestMsgId,
      room_id: selectedChat.room_id,
      token: GLOBAL.get('token')
    });
  };

  useEffect(() => {
    // loadEarlierMsg();
    // () => {
    //   console.log('chatscreen unmount');
    //   ChatSocketHelper.disconnect();
    // };
  }, []);

  useEffect(() => {
    console.log('selectedChat changed', selectedChat);
    if (_.isEmpty(selectedChat)) {
      return () => console.log('not loaded yet');
    }

    const connect = () => {
      console.log('connect callback');
      ChatSocketHelper.emit('join-room', selectedChat.room_id);
    };

    const disconnect = (reason) => {
      console.log('disconnect callback', reason);
      setConnected(false, () => {
        ChatSocketHelper.attemptReconnect();
      });
    };

    const reconnect = (attemptNumber) => {
      console.log('reconnect callback', attemptNumber);
      if (attemptNumber > 5) {
        console.log('cannot reconnect');
      }
    };

    const onChat = () => {
      const userId = AppHelper.getUserId();
      console.log('Start_Chat callback');
      ChatSocketHelper.emit('Register_Name', userId);  //register user id
      setConnected(true);
  
      // below hidden for web portal
      // send calling signal to callee if in video chat, since this screen
      // is in one of the tab in VideoCallChatScreen
      // if (this.props.isInVideoCall && this.props.isCaller) {
      //   this.sendVideoCallSystemMessage();
      //   this.startEmitCallingSignal();
      // }

      // load any new message when connected to the socket
      loadNewestMsg();
      loadEarlierMsg();
    };

    const onRName = (data) => {
      console.log('onRName callback', data);
    };

    const onMsg = (data) => {
      console.log('onMsg callback', data);
      if (data && data.length > 0) {
        ChatSocketHelper.emit('read', { msg: data, token: GLOBAL.get('token') });
  
        // const messages = this.convertToMsgObj(data);
        // // console.log('msg', data);
        // this.setState(previousState => ({
        //   newestMsgId: data[0].seq_id || data[0].id,
        //   messages: this.getAppendedMsg({
        //     existingMsgs: previousState.messages,
        //     newMsgs: messages
        //   })
        // }));
        setNewestMsgId(data[0].seq_id || data[0].id);
        setMessages((m) => ([...m, ...data]));
      }
    };
  
    const onMsg3 = (data) => {
      console.log('onMsg3 callback', data);
      if (data) {
        ChatSocketHelper.emit('read', { msg: data, token: GLOBAL.get('token') });
  
        currentId = data.id;
  
        if (currentId !== previousId) {
          // const messages = convertToMsgObj([data]);
          // console.log('msg', data);
          // setState(previousState => ({
          //   newestMsgId: data.seq_id || data.id,
          //   messages: getAppendedMsg({
          //     existingMsgs: previousState.messages,
          //     newMsgs: messages
          //   })
          // }));
          setNewestMsgId(data.seq_id || data.id);
          setMessages((m) => ([data, ...m]));
  
          previousId = currentId;
        }
      }
    };

    const onNewMsg = (data) => {
      console.log('onNewMsg callback', data);
      if (data && data.length > 0) {
        // const messages = this.convertToMsgObj(data);
  
        if (data && data.length > 0) {
          // this.setState(previousState => ({
          //   newestMsgId: data[0].seq_id || data[0].id,
          //   messages: this.getAppendedMsg({
          //     existingMsgs: previousState.messages,
          //     newMsgs: messages
          //   })
          // }));
          setNewestMsgId(data[0].seq_id || data[0].id);
          setMessages((m) => ([...m, ...data]));
  
          // this.saveChatToAsync(data, true, 'onNewMsg');
  
          // this.props.updateReadStatusWithRoomOrder({
          //   lastSeenMsgId: data[0].seq_id || data[0].id,
          //   roomIndex: this.props.roomIndex
          // });
        }
      }
      // auto forward/send the details of lesson request or details of the teacher
      // this.autoSendMsg();
    };
  
    const onOldMsg = (data, local = false) => {
      console.log('onOldMsg callback', data, local);
      // Used for fetching older chat history
      if (data && data.length > 0) {
        setOldestMsgId(data[data.length - 1].seq_id || data[data.length - 1].id);
        setNewestMsgId(newestMsgId || data[0].seq_id || data[0].id);
        
        setLoadingMore(false);
        setMessages((m) => [...m, ...data]);
      } else {
        setEndReached(true);
        setLoadingMore(false);
      }
    };
  
    const onAlertMsg = (data) => {
      console.log(data);
    };

    ChatSocketHelper.initialize({ serverUrl: AppHelper.getChatServerUrl() });
    ChatSocketHelper.setCallbacks({
      callbacks: {
        connect: connect,
        disconnect: disconnect,
        reconnecting: reconnect,
        Start_Chat: onChat,
        r_name: onRName,
        msg: onMsg,
        msg3: onMsg3,
        msg_new: onNewMsg,
        msg_old: onOldMsg,
        alert_msg: onAlertMsg
      }
    });
  }, [selectedChat]);

  const onSubmit = (e) => {
    e.preventDefault();
    
    if (!_.isEmpty(typingMsg)) {
      if (typingMsg.length > 0) {
        // {room_id: room_id, user_id: user_id, message: ''}
        const message = {
          room_id: selectedChat.room_id,
          user_id: AppHelper.getUserId(),
          // message: messages[0].text
          message: typingMsg.trim()
        };
        ChatSocketHelper.emit('Send_msg', { msg: message, token: GLOBAL.get('token') });
        setTypingMsg('');
        messageContainer.current.scrollTo(0, 0);
      }
    }
  };

  const onScroll = (e) => {
    const list = e.target;
    // scrollHeight is the total scrollable list height
    if ((list.clientHeight - list.scrollTop + THRESHOLD  > list.scrollHeight) && !loadingMore && !endReached) {
      console.log('load more');

      // save scroll top, add items, refresh list, restore scroll top
      var top = list.scrollTop;
      loadEarlierMsg();
      list.scrollTop = top;
    }
  };

  const renderLoadingBar = () => {
    if (!connected) {
      return (
        <div className={classes.loadingBar}>
          <LinearProgress />
        </div>
      );
    }
    return null;
  };

  return (
    <div className={classes.root}>
      <div ref={messageContainer} className={classes.messageContainer} onScroll={onScroll}>
        {
          messages.map((item, index) => <Message key={index} item={item} otherUserAvatar={selectedChat.users[0].avatarUrl} />)
        }
        {
          loadingMore && <div className={classes.loadingMore}>
            <CircularProgress />
          </div>
        }
      </div>

      <div className={classes.messageInputOuter}>
        <Paper component="form" className={classes.messageInputInner} elevation={4} onSubmit={onSubmit}>
          <InputBase
            disabled={!connected}
            className={classes.input}
            placeholder="Send a message"
            inputProps={{ 'aria-label': 'send message' }}
            value={typingMsg}
            onChange={(e) => setTypingMsg(e.target.value)}
          />
          <IconButton color="primary" type="submit" className={classes.iconButton} aria-label="send" disabled={!connected}>
            <SendIcon />
          </IconButton>
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton className={classes.iconButton} type="submit" aria-label="directions" disabled={!connected}>
            <MoreVertIcon />
          </IconButton>
        </Paper>
      </div>

      { renderLoadingBar() }
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    selectedChat,
  } = state.chat;

  return {
    selectedChat,
  };
};

export default connect(mapStateToProps)(ChatRightPanel);