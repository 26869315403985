import {
  UPDATE_CHAT_STATE,
  UPDATE_CHAT_LIST_STATE,
  RESET_CHAT_STATE,
} from '../actions/types';
import {
  getChatRoomList,
  getAdminChat,
  getVideoCallChatRoomId,
  // updateReadStatus,
  deleteOwnChat
} from '../api';
// import AppHelper from '../helpers/AppHelper';
// import {
//   updateLessonSessionState,
//   updateCommonStates
// } from '../actions';
import ChatHelper from '../helpers/ChatHelper';
import { STRING } from '../helpers/ConstantHelper';
import { getStore } from '../Store';

const Storage = window.localStorage;

export const updateChatState = ({ prop, value }) => {
  return {
    type: UPDATE_CHAT_STATE,
    payload: { prop, value }
  };
};

export const updateChatListState = ({ prop, value }) => {
  return {
    type: UPDATE_CHAT_LIST_STATE,
    payload: { prop, value }
  };
};

export const resetChatState = () => {
  return {
    type: RESET_CHAT_STATE
  };
};

export const getChatRoom = () => {
  return (dispatch) => {
    /*
     *  showNewChatAlert use to record the state of the
     *  new badge in the chat icon of dashboard
     */
    console.log('------getChatRoom---start');
    let showNewChatAlert = false;
    dispatch(updateChatListState({ prop: 'isLoading', value: true }));
    const res = Storage.getItem(STRING.AS_KEY.CHATROOM);
    getChatRoomList()
      .then((listRes) => {
        // console.log('------getChatRoom---fetch-done-listRes=', listRes);
        const { chatRooms } = listRes.data;
        if (!chatRooms || chatRooms.length < 1) {
          console.log('empty list');
          return;
        }

        Storage.setItem(STRING.AS_KEY.CHATROOM, JSON.stringify(chatRooms));
        // new method that check read read status
        // we can use the last_read_msg_id to found out what is latest message that read by the user
        for (let i = 0; i < chatRooms.length; i++) {
        // get locally last read
          if (chatRooms[i].last_read_msg_id !== undefined
          && chatRooms[i].last_msg_id
          && chatRooms[i].last_read_msg_id !== chatRooms[i].last_msg_id
          ) {
            chatRooms[i].new_msg = true;
            showNewChatAlert = true;
          } else {
            chatRooms[i].new_msg = false;
          }
        }

        // use unread_msg_count to check chatrooms read status
        for (let i = 0; i < chatRooms.length; i++) {
          chatRooms[i].new_msg = false;
          const read = chatRooms[i].unread_msg_count;
          if (typeof read !== undefined && parseInt(read, 10) > 0) {
            chatRooms[i].new_msg = true;
            showNewChatAlert = true;
          }
        }

        dispatch(updateChatListState({ prop: 'isLoading', value: false }));
        dispatch(updateChatListState({ prop: 'list', value: chatRooms }));
        const { selectedChat } = getStore().getState().chat;
        // Automatically connect to the first room
        if (!selectedChat) {
          dispatch(updateChatState({ prop: 'selectedChat', value: chatRooms[0] }));
        }

        const { isFirstLogin } = getStore().getState().common;
        if (isFirstLogin) {
          console.log('-------isFirstLogin----sendLessonRequestToChat----');
          ChatHelper.sendNewUserAutoMessage();
        // dispatch(updateCommonStates({ prop: 'isFirstLogin', value: false }));
        }
        dispatch(updateChatListState({ prop: 'showNewChatAlert', value: showNewChatAlert }));
        console.log('------getChatRoom---done');
      })
      .catch(error => {
        dispatch(updateChatListState({ prop: 'isLoading', value: false }));
        if (res) {
          const localChatRooms = JSON.parse(res);
          dispatch(updateChatListState({ prop: 'list', value: localChatRooms }));
        }
        console.log('Failed to get chat rooms from server', error);
      });
  };
};

export const selectChatRoom = (id) => {
  return (dispatch) => {
    dispatch(updateChatState({ prop: 'selectedChat', value: id }));
  };
};

// export const updateReadStatusWithRoomOrder = ({ /*lastSeenMsgId, lastSeenMsgTime,*/ roomIndex }) => {
//   return (dispatch) => {
//     const { list } = getStore().getState().chat.chatRoom;
//     // push room to the top of the list
//     // so the one with latest message is at the top
//     if (roomIndex <= 0 || roomIndex > list.length - 1) {
//       console.log('no need to update');
//     } else if (list[roomIndex]) {
//       // const adminLastIndex = _.findLastIndex(list, l => l.has_admin === true);
//       // const chatRoom = list[roomIndex];
//       // // if (lastSeenMsgTime) {
//       // //   // modify lastSeenMsgTime aswell
//       // //   chatRoom.updated_at = lastSeenMsgTime;
//       // // }
//       // list.splice(roomIndex, 1);
//       // list.splice(adminLastIndex + 1, 0, chatRoom);
//       // dispatch(updateChatListState({ prop: 'list', value: list }));
//       // AsyncStorage.setItem(STRING.AS_KEY.CHATROOM, JSON.stringify(list))
//       // .then(r => console.log(r))
//       // .catch(e => console.warn(e));
// 
//       // dispatch(updateLastSeenMessage({ lastSeenMsgId, roomId: chatRoom.room_id }));
//     }
//   };
// };

export const updateRoomReadStatus = ({ roomId }) => {
  return (dispatch) => {
    console.warn('getting called');
    const { list } = getStore().getState().chat.chatRoom;
    const index = list.findIndex((room) => room.room_id === roomId);
    let showNewChatAlert = false;
    list[index].new_msg = false;

    list.map(room => {
      if (room.new_msg === true) {
        showNewChatAlert = true;
      }
      return '';
    });

    dispatch(updateChatListState({ prop: 'list', value: list }));
    dispatch(updateChatListState({ prop: 'showNewChatAlert', value: showNewChatAlert }));
  };
};

/* when user exit chat screen, update last read msg in here aswell */
// export const updateLastSeenMessage = ({ lastSeenMsgId, roomId }) => {
//   return (dispatch) => {
//     const { list } = getStore().getState().chat.chatRoom;
//     const index = list.findIndex((room) => room.room_id === roomId);
//     if (index !== -1 && list[index] && lastSeenMsgId !== list[index].last_read_msg_id) {
//       // last read msg should be updated
//       const msg = { id: lastSeenMsgId, room_id: roomId };
//       const callFrom = 'YOUBRIOAPP - updateReadStatus';
//       dispatch(updateRoomReadStatus({ roomId }));
//       updateReadStatus({ msg, callFrom })
//       .then(res => {
//         console.log(res);
//       })
//       .catch(err => {
//         console.warn(err);
//         console.warn('something wrong updateLastSeenMessage lastSeenMsgId=', lastSeenMsgId);
//       });
//     }
//   };
// };

// /**
//  * After a lesson request created, this function is called to send message to chat
//  * It is triggered in LessonRequestModal component
//  * @param {object}
//  */
// export const sendLessonRequestToChat = ({ lessonRequest, fullProfile }) => {
//   return () => {
//     // const { user } = getStore().getState().user;
//     // const { token } = getStore().getState().auth;
//     if (fullProfile) {
//       const previewedUser = fullProfile.user;  // the user on the profile preview
//       const roomId = fullProfile.cr.room_id;
//       const guestUser = fullProfile.cr.users[0];  // the user in the chat room
//       const canChat = fullProfile.canChat;
// 
//       if (canChat) {
//         // talk directly to the user
//         // Actions.chatScreen({ roomId, guestUser, msgType: 1, lessonRequest });
//       } else {
//         // talking to admin, format message
//         // Actions.chatScreen({ roomId, guestUser, msgType: 2, lessonRequest, previewedUser });
// 
//         // // Actions.chatScreen({ roomId, guestUser, previewedUser, msgType: 1, lessonRequest });
// 
//         // // send lesson request to admin in the back without go to the chat screen
//         // const chatServerUrl = AppHelper.getChatServerUrl() || 'http://webrtc1.youbrio.com:3002';
//         // const socket = io.connect(chatServerUrl, {
//         //   'reconnection': true,
//         //   'reconnectionDelay': 1000,
//         //   'reconnectionDelayMax': 5000,
//         //   'reconnectionAttempts': 5
//         // });
//         // socket.on('connect', () => {
//         //   socket.emit('join-room', roomId);
//         // });
// 
//         // socket.on('Start_Chat', () => {
//         //   // const { startDate, duration, description } = lessonRequest;
//         //   // const { firstName, lastName } = previewedUser;
//         //   // const fullName = `${firstName} ${lastName}`;
// 
//         //   // const msg = {
//         //   //   room_id: roomId,
//         //   //   user_id: user.id,
//         //   //   message: messages.formatString(messages.requestedLesson, fullName, AppHelper.simpleFormatDatetimeWithLocalTimezone(startDate), duration, description)
//         //   // };
// 
//         //   // if (AppHelper.isEmptyOrWhiteSpace(description)) {
//         //   //   msg.message = msg.message.replace(`\n\n${labels.description}:`, '');
//         //   // }
// 
//         //   const msg = {
//         //     room_id: roomId,
//         //     user_id: user.id,
//         //     msg_type_id: MSG_TYPE_ID.LESSON_REQUEST,
//         //     message: MSG_TYPE_ID.LESSON_REQUEST,
//         //     data: { lesson_request_id: lessonRequest.id }
//         //   };
// 
//         //   socket.emit('Register_Name', user.id);
//         //   socket.emit('Send_msg', { msg, token });
//         // });
// 
//         // socket.on('msg3', (data) => {
//         //   console.log(data, ' sent, ready to disconnect');
//         //   socket.disconnect();
//         // });
//       }
//     }
//   };
// };

export const clearChatHistory = () => {
  return (dispatch) => {
    // START clear chat and lesson_event(added to calendar) data in AsyncStorage
    // AsyncStorage.getAllKeys((e, keys) => {
    //   const fKeys = keys.filter(key => key.includes(STRING.AS_KEY.MSG) || key.includes(STRING.AS_KEY.CHATROOM) || key.includes(STRING.AS_KEY.LESSON_EVENT));
    //   // console.log(results);
    //   AsyncStorage.multiRemove(fKeys, (err) => {
    //     if (err) console.warn(err);
    //   });
    // });
    dispatch(resetChatState());
    // FINISH clear chat data in AsyncStorage
  };
};

// ADMIN CHAT TO ANY USER
export const adminChatTo = ({ uid }) => {
  return (dispatch) => {
    if (uid) {
      // dispatch(appStartsLoading());
      getAdminChat({ uid })
        .then(r => {
        // dispatch(appDoneLoading());
        // const { room_id: roomId, users } = r.chatRoom;
        // Actions.chatScreen({ roomId, guestUser: users[0] });
        })
        .catch(e => {
        // dispatch(appDoneLoading());
          console.warn(e);
        });
    }
  };
};

export const getVideoChatRoomId = ({ guestUser, roomId, isCaller, successCallback, failCallback }) => {
  return (dispatch) => {
    // dispatch(appStartsLoading());
    getVideoCallChatRoomId({ chatRoomRoomId: roomId })
      .then(async (response) => {
        console.log(response);
        // dispatch(updateLessonSessionState({ prop: 'lessonRoomInfo', value: response.lessonSession }));
        if (successCallback) {
          successCallback(response);
        }

        const result = true; // await PermissionHelper.requestPrerequiredVideoChatRoomPermissions();
        console.log('permission result', result);

        // if (result) {
        //   Actions.videoChat({
        //     chatGuestUser: guestUser,
        //     chatRoomId: roomId,
        //     type: ActionConst.RESET,
        //     animation: 'fade',
        //     duration: 1000,
        //     isCaller
        //   });
        // } else if (Platform.OS === 'android') {
        //   Alert.alert(
        //     labels.permission,
        //     messages.pleaseGrantPrerequiredPermissionsForVideoCallAndroid
        //   );
        // } else if (Platform.OS === 'ios') {
        //   Alert.alert(
        //     labels.permission,
        //     messages.pleaseGrantPrerequiredPermissionsForVideoCallIos
        //   );
        // }

        // dispatch(appDoneLoading());
      })
      .catch((error) => {
        console.log(error);
        // dispatch(appDoneLoading());
        if (failCallback) {
          failCallback(error);
        }
        // Alert.alert(
        //   labels.startVideoCall,
        //   messages.unableToStartVideoCall,
        //   [
        //     { text: labels.ok },
        //   ],
        //   { cancelable: false }
        // );
      });
  };
};

// export const checkReadStatus = () => {
//   return (dispatch) => {
//     AsyncStorage.getItem(STRING.AS_KEY.CHATROOM)
//     .then(list => {
//       let showNewChatAlert = false;
//       for (let i = 0; i < list.length; i++) {
//         if (list[i].new_msg) {
//           showNewChatAlert = true;
//           break;
//         }
//       }
//
//       dispatch(updateChatListState({ prop: 'showNewChatAlert', value: showNewChatAlert }));
//     })
//     .catch(error => {
//       console.warn(error);
//     });
//   };
// };

export const deleteOwnChatHistory = ({ roomId }) => {
  return (dispatch) => {
    console.log('deleting chat with roomId:', roomId);

    // dispatch(appStartsLoading());
    deleteOwnChat({ roomId })
      .then(res => {
        console.warn('res', res);
        // dispatch(appDoneLoading());
        dispatch(getChatRoom());
      })
      .catch(err => {
      // dispatch(appDoneLoading());
        console.warn(err);
      // Alert.alert(
      //   '',
      //   messages.failedToDelete
      // );
      });
  };
};
