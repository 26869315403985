// @flow

import { FieldTextAreaStateless } from '@atlaskit/field-text-area';
import moment from 'moment';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
// import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// import Grow from '@material-ui/core/Grow';
// import Paper from '@material-ui/core/Paper';
// import Popper from '@material-ui/core/Popper';
// import MenuList from '@material-ui/core/MenuList';
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

import { getLocalizedDateFormatter } from '../../i18n/dateUtil';
import {
  IconCrown,
  IconDumbbell,
  IconTeach,
  IconTimer
} from '../Icons';
import IconLock from '@material-ui/icons/Lock';
import IconUnlock from '@material-ui/icons/LockOpen';
import IconEdit from '@material-ui/icons/Edit';
import IconClose from '@material-ui/icons/Close';
import IconSend from '@material-ui/icons/Send';
import IconMore from '@material-ui/icons/MoreVert';
import IconPerson from '@material-ui/icons/Person';
// import IconAssignmentInd from '@material-ui/icons/AssignmentInd';
// import { Message } from 'element-react';
import 'element-theme-default';
// import { OverflowMenuItem } from '../../../base/toolbox/components';
import AppHelper from '../../helpers/AppHelper';
import ControlRoomSocketHelper from '../../helpers/ControlRoomSocketHelper';
import { updateCommonStates } from '../../actions';

import './ControlRoom.css';
import { withTranslation } from 'react-i18next';
// import { mergeClasses } from '@material-ui/styles';

const WARNING_DURATION_SECOND = 10 * 60;
const OVERTIME_DURATION_SECOND = 15 * 60;
const COLOR = {
  FAILED: '#FF0000',
  ORANGE: '#FF9500',
  BLUE: '#007AFF',
  GREEN: '#33CC66',
  GREY: '#C7C7CC',
  WARNING: '#FF9C00',
  COBALT_BLUE: '#3373BD'
};

const { PRACTICE_ROOM_EVENT } = require('../../helpers/ConstantHelper');

type Props = {

  /**
   * Individual participant object from the participant list array.
   *
   */
  participant: Object,

  /**
   * All participant object from the participant list array.
   *
   */
  participants: Array<Object>,


  /**
   * Practice room data.
   *
   */
  practiceRoom: Object,


  /**
   * Present timestamp that is updated from the parent component
   * every second.
   */
  now: Date,

  /**
   * Function to be called when handover note is saved.
   */
  onSaveParticipantNotePress: Function,

  /**
   * Function to be called when participant lock status is toggled.
   */
  onUpdatePracticeParticipantLock: Function,

  /**
   * Whether the participant list is loading.
   */
  isLoadingParticipant: Boolean,

  /**
   * The map of user id where audio is connected.
   */
  audioConnectedParticipants: Object,

  /**
   * Participant object with various track states.
   */
  jitsiParticipant: Object,

  /**
   * Invoked to obtain translated strings.
   */
  t: Function
};

const styles = {
  participantItemOuterContainer: {
    flexDirection: 'column',
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 6,
    boxShadow: '2px 5px 15px 5px rgba(204,204,204,0.25)',
    display: 'flex',
    padding: 10,
    boxSizing: 'border-box',
    marginTop: 12,
    minHeight: 100,
    justifyContent: 'center'
  },
  blurredParticipantItemOuterContainer: {
    flexDirection: 'column',
    backgroundColor: 'rgba(255,255,255,0.6)',
    width: '100%',
    borderRadius: 6,
    boxShadow: '2px 5px 15px 5px rgba(204,204,204,0.25)',
    display: 'flex',
    padding: 10,
    boxSizing: 'border-box',
    marginTop: 12,
    minHeight: 100,
    justifyContent: 'center'
  },
  participantContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  timerContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minWidth: '50%'
  },
  name: {
    color: '#000',
    display: 'flex',
    flexWrap: 'wrap',
    fontSize: 16,
    marginBottom: 5,
    alignItems: 'center'
  },
  nameLineStyle: {
    overflowWrap: 'break-word',
    whiteSpace: 'initial'
  },
  timer: {
    color: '#000',
    fontSize: 14,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5
  },
  singleBadgeContainerStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    padding: 5,
    marginRight: 5,
    marginBottom: 1,
    whiteSpace: 'nowrap',
    display: 'flex'
  },
  badgeTextStyle: {
    fontSize: 12
  },
  badgesContainerStyle: {
    flexDirection: 'row',
    marginBottom: 4,
    alignItems: 'center',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
    display: 'flex'
  },
  participantItemRowContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex'
  },
  lessonModeTimerTextStyle: {
    fontSize: 14,
    minWidth: 80,
    color: '#000'
  },
  iconContainerStyle: {
    marginRight: 3
  },
  lastTutorNameTextStyle: {
    color: '#555',
    fontSize: 8,
  },
  lastEnteredTextStyle: {
    color: '#555',
    fontSize: 8,
  },
  participantPracticeModeTimerTextStyle: {
    fontSize: 14
  },
  participantPracticeModeTimerIconContainerStyle: {
    marginRight: 5
  },
  rowContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  modeTimerContainStyle: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
    justifyContent: 'center'
  },
  controlRootStyle: {
    flexGrow: 1,
  },
  controlContainerStyle: {
    alignItems: 'center'
  },
  tutorTextStyle: {
    fontSize: 14,
    color: '#000'
  },
  speakerEnabledButtonStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    width: 30,
    borderRadius: 20,
    border: '1px solid #999999',
    marginRight: 5,
    display: 'flex'
  },
  speakerFillerButtonStyle: {
    height: 30,
    width: 30,
    display: 'flex'
  },
  noteLastEditedInfoTextStyle: {
    marginTop: 5,
    marginRight: 5,
    display: 'flex',
    justifyContent: 'flex-end',
    color: '#777',
    fontSize: 13
  },
  cancelNoteEditButtonStyle: {
    alignSelf: 'flex-end',
    marginTop: 5,
    marginRight: 5,
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    width: 30,
    borderRadius: 15,
    backgroundColor: COLOR.FAILED,
    display: 'flex'
  },
  editNoteButtonStyle: {
    alignSelf: 'flex-end',
    marginTop: 5,
    marginRight: 0,
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    width: 30,
    borderRadius: 15,
    backgroundColor: COLOR.COBALT_BLUE,
    display: 'flex'
  },
  editNoteButtonContainerStyle: {
    display: 'flex',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row'
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  }
};

/**
 * Renders each participant list item in the TutorRoom component
 */
class ParticipantListItem extends Component<Props> {
  /**
   * Initializes a new {@code ParticipantListItem} instance.
   *
   * @param {*} props - The read-only properties with which the new instance
   * is to be initialized.
   */
  constructor(props: Props) {
    super(props);

    this.state = {
      isNoteLoading: false,
      isNoteChanged: false,
      isEditingNote: false,
      isExpanded: false,
      successVisible: false,
      editedNoteText: '',
      anchorEl: null,
      isSelectTutorPopoverOpened: false,
      selectTutorAnchorEl: null,
    };

    this._onChangeNoteText = this._onChangeNoteText.bind(this);
    this._onToggleExpandedHandoverNote = this._onToggleExpandedHandoverNote.bind(this);
    this._onEditHandoverNotePress = this._onEditHandoverNotePress.bind(this);
    this._onCancelEditNotePress = this._onCancelEditNotePress.bind(this);
    this._onMenuPress = this._onMenuPress.bind(this);
    this._onMenuClose = this._onMenuClose.bind(this);
    this._onSaveNotePress = this._onSaveNotePress.bind(this);
    this._onKickParticipantPress = this._onKickParticipantPress.bind(this);
    this._onSelectTutorPress = this._onSelectTutorPress.bind(this);
    this._saveNoteCallback = this._saveNoteCallback.bind(this);
    this._toggleLockCallback = this._toggleLockCallback.bind(this);
    this._onLockParticipantPress = this._onLockParticipantPress.bind(this);
  }

  /**
   * Toggles the expanded state of the handover note.
   *
   * @returns {void}
   */
  _onToggleExpandedHandoverNote() {
    const {
      isExpanded
    } = this.state;

    const {
      participant
    } = this.props;

    // if to be expanded and remarks is empty, turn to edit mode automatically
    if (!isExpanded === true && !participant?.notes?.remarks) {
      this._onEditHandoverNotePress();
    } else {
      this.setState({
        isExpanded: !isExpanded
      });
    }
  }

  /**
   * On edit hand over note press.
   *
   * @param {event} event - Click event.
   * @returns {void}
   */
  _onEditHandoverNotePress(event) {
    if (event) {
      event.stopPropagation();
    }

    const {
      participant
    } = this.props;

    this.setState({
      isEditingNote: true,
      editedNoteText: participant?.notes?.remarks ?? ''
    });
  }

  /**
   * Handle for hand over note text change.
   *
   * @param {event} event - Event object of the text change event.
   * @returns {void}
   */
  _onChangeNoteText(event) {
    this.setState({
      editedNoteText: event.target.value,
      isNoteChanged: true
    });
  }

  /**
   * Cancel handover note editing.
   *
   * @param {event} event - Click event.
   * @returns {void}
   */
  _onCancelEditNotePress(event) {
    if (event) {
      event.stopPropagation();
    }

    this.setState({
      isEditingNote: false,
      isNoteChanged: false,
      editedNoteText: ''
    });
  }

  /**
   * Saves edited handover note.
   *
   * @param {event} event - Click event.
   * @returns {void}
   */
  _onSaveNotePress(event) {
    if (event) {
      event.stopPropagation();
    }

    const {
      participant
    } = this.props;

    const {
      editedNoteText
    } = this.state;

    this.setState({
      isNoteLoading: true
    }, () => {
      this.props.onSaveParticipantNotePress({
        practiceParticipantDetailId: participant.notes.practice_participant_detail_id,
        userId: participant.user_id,
        remarks: editedNoteText,
        practiceSubjectId: participant.notes.practice_subject_id,
        remarksEditedAt: participant.notes.remarks_edited_at,
        callback: this._saveNoteCallback
      });
    });
  }

  /**
   * Toggles the lock status of the participant.
   *
   * @returns {void}
   */
  _onLockParticipantPress() {
    const { participant } = this.props;

    this.props.onUpdatePracticeParticipantLock({
      practiceParticipantDetailId: participant.notes.practice_participant_detail_id,
      practiceSubjectId: participant.notes.practice_subject_id,
      userId: participant.user_id,
      lock: !this.isParticipantLocked(participant),
      lastLockedBy: participant?.notes?.locked_by, /* eslint-disable-line camelcase */
      callback: this._toggleLockCallback
    });
  }

  /**
   * On participant menu press handler.
   *
   * @param {boolean} event - Event object of the click.
   * @returns {void}
   */
  _onMenuPress(event) {
    this.setState({
      anchorEl: event.currentTarget
    });
  }

  /**
   * On participant menu close handler.
   *
   * @returns {void}
   */
  _onMenuClose() {
    this.setState({
      anchorEl: null
    });
  }

  /**
   * When kick participant is pressed.
   *
   * @returns {void}
   */
  _onKickParticipantPress() {
    const { participant } = this.props;
    this._onMenuClose();

    this.props.onKickPress({ practiceRoomParticipantId: participant.practice_room_participant_id });
  }

  /**
   * When select tutor is pressed.
   *
   * @returns {void}
   */
  _onSelectTutorPress(event) {
    const currentTarget = event.currentTarget;
    this.setState({
      isSelectTutorPopoverOpened: true,
      selectTutorAnchorEl: currentTarget,
    });
    this._onMenuClose();
  }
  
  /**
   * When selected tutor is pressed.
   *
   * @returns {void}
   */

  /**
   * Callback after saving edited handover note.
   *
   * @param {Object} [param.response] - Response from the API.
   * @param {Object} [param.error] - API error code.
   * @returns {void}
   */
  _saveNoteCallback({ response, error }) {
    // console.log('_saveNoteCallback', response, error, error.code);
    const newState = {
      isNoteLoading: false
    };

    if (response) {
      newState.isNoteChanged = false;
      newState.isEditingNote = false;
      newState.isExpanded = true;
      newState.successVisible = true;
    } else if (error) {
      if (error === 303) {
        // Clipboard.setString(editedNoteText);
        // this.props.dispatch(showNotification({
        //   descriptionKey: 'tutorRoom.someoneHasEditedTheNote',
        //   titleKey: 'connection.ERROR'
        // },
        // NOTIFICATION_TIMEOUT));
        newState.isEditingNote = false;
      } else {
        // this.props.dispatch(showNotification({
        //   descriptionKey: 'info.genericError',
        //   titleKey: 'connection.ERROR'
        // },
        // NOTIFICATION_TIMEOUT));
      }
    }

    this.setState(newState);
  }

  /**
   * Callback after toggling participant lock status.
   *
   * @param {Object} [param.response] - Response from the API.
   * @param {Object} [param.error] - API error code.
   * @returns {void}
   */
  _toggleLockCallback({ /* response, */ error }) {
    if (error) {
      if (error === 303) {
        // this.props.dispatch(showNotification({
        //   descriptionKey: 'tutorRoom.someoneHasChangedTheLockStatus',
        //   titleKey: 'connection.ERROR'
        // },
        // NOTIFICATION_TIMEOUT));
      } else {
        // this.props.dispatch(showNotification({
        //   descriptionKey: 'info.genericError',
        //   titleKey: 'connection.ERROR'
        // },
        // NOTIFICATION_TIMEOUT));
      }
    }
  }

  /**
   * Returns whether participant is locked.
   *
   * @param {Object} participant - Participant object from the list.
   * @returns {boolean}
   */
  isParticipantLocked(participant) {
    /* eslint-disable-next-line camelcase */
    if (participant?.notes?.locked_by) {
      return true;
    }

    return false;
  }

  /**
   * Renders the toggle lock button of the participant.
   *
   * @returns {ReactElement | null}
   */
  renderToggleLockButton() {
    const {
      isLoadingParticipant,
      participant
    } = this.props;

    const {
      practice_room_role_id: practiceRoomRoleId
    } = participant;

    const isLocked = this.isParticipantLocked(participant);

    if (practiceRoomRoleId === 1) {
      return (
        <button
          className = { isLocked ? 'button lock-button' : 'button unlock-button' }
          disabled = { isLoadingParticipant }
          onClick = { this._onLockParticipantPress } >
          {
            isLocked
              ? <IconUnlock
                style={{ fill: '#fff', fontSize: 18 }} />
              : <IconLock
                style={{ fill: '#000', fontSize: 18 }} />
          }
        </button>
      );
    }

    return null;
  }

  /**
   * Renders the badges of the participants.
   *
   * @param {Object} props - Props of this component.
   * @returns {ReactElement}
   */
  renderBadges = props => {
    const {
      badges
    } = props.participant;

    const {
      singleBadgeContainerStyle,
      badgeTextStyle,
      badgesContainerStyle
    } = styles;

    const renderIndividualBadge = (badge, index) => {
      const {
        type,
        textStyle,
        containerStyle,

        // icon,
        text
      } = badge;

      if (type === 'icon') {
        return null;
      } else if (type === 'text') {
        return (
          <span
            key = { index }
            style = {{ ...singleBadgeContainerStyle,
              ...containerStyle }} >
            <span
              style = {{ badgeTextStyle,
                ...textStyle }}>{text}</span>
          </span>
        );
      }

      return null;
    };

    if (badges && badges.length > 0) {
      return (
        <div style = { badgesContainerStyle }>
          {badges.map((badge, index) => renderIndividualBadge(badge, index))}
        </div>
      );
    }

    return null;
  };

  /**
   * Renders the accumulated lesson mode timer of the participant.
   *
   * @returns {ReactElement}
   */
  renderAccumulatedLessonTimer() {
    const {
      now
    } = this.props;

    const {
      lessonModeCounter
    } = this.props.participant;

    if (lessonModeCounter) {
      const {
        totalSeconds,
        totalInstances,
        lessonModeAt
      } = lessonModeCounter;

      const {
        participantItemRowContainerStyle,
        lessonModeTimerTextStyle,
        iconContainerStyle
      } = styles;

      const currentLessonModeDuration = AppHelper.getSecondsToDate({
        date: now,
        now: lessonModeAt
      });
      const totalDuration = totalSeconds + currentLessonModeDuration;

      const parsedDuration = AppHelper.formatSecondsToNow(totalDuration);
      const {
        hours,
        minutes,
        seconds
      } = parsedDuration;

      return (
        <div style = { participantItemRowContainerStyle }>
          <span style = { iconContainerStyle }>
            <IconTeach
              viewBox='0 0 30 30'
              style={{ fill: '#000', fontSize: 18 }} />
          </span>
          <span style = { lessonModeTimerTextStyle }>
            { hours.toString().padStart(2, 0) }
            :{ minutes.toString().padStart(2, 0) }
            :{ seconds.toString().padStart(2, 0) } { `[x${totalInstances}]` }
          </span>
        </div>
      );
    }

    return null;
  }

  /**
   * Renders the total in-room timer of the participant.
   *
   * @returns {ReactElement}
   */
  renderInRoomTimer() {
    if (this.props.participant.in_room_total_time) {
      const {
        totalTime, // accumulated time previously
        lastEnterTime
      } = this.props.participant.in_room_total_time;

      const {
        participantItemRowContainerStyle,
        iconContainerStyle,
        lessonModeTimerTextStyle
      } = styles;

      if (lastEnterTime) {
        let secondsToNow = moment(moment()).diff(new Date(lastEnterTime), 'seconds');

        secondsToNow += totalTime;
        const parsedDuration = AppHelper.formatSecondsToNow(secondsToNow);
        const {
          hours,
          minutes,
          seconds
        } = parsedDuration;

        return (
          <div style = { participantItemRowContainerStyle }>
            <span style = { iconContainerStyle }>
              <IconTimer
                viewBox='0 0 30 30'
                style={{ fill: '#000', fontSize: 18 }} />
            </span>
            <span style = { lessonModeTimerTextStyle }>
              {hours.toString().padStart(2, 0)}
              :{minutes.toString().padStart(2, 0)}
              :{seconds.toString().padStart(2, 0)}
            </span>
          </div>
        );
      }
    }

    return null;
  }

  /**
   * Renders the last tutor name of the participant.
   *
   * @returns {ReactElement}
   */
  renderLastTutorName() {
    const {
      lastTutorNameTextStyle
    } = styles;

    const {
      last_tutor_display_name: lastTutorName
    } = this.props.participant;

    if (lastTutorName) {
      return (
        <div style = { lastTutorNameTextStyle }>{`Tutored by: ${lastTutorName}`}</div>
      );
    }

    return null;
  }

  /**
   * Renders the last entered date.
   *
   * @returns {ReactElement}
   */
  renderLastEnteredDate() {
    const {
      lastEnteredTextStyle
    } = styles;

    const {
      last_tutor_display_name: lastTutorName,
      last_entered: lastEnteredDate,
    } = this.props.participant;

    if (lastTutorName && lastEnteredDate) {
      return (
        <div style = { lastEnteredTextStyle }>{`Last entered on ${lastEnteredDate}`}</div>
      );
    }

    return null;
  }

  /**
   * Renders the last entered in room duration.
   *
   * @returns {ReactElement}
   */
     renderLastEnteredDuration() {
      const {
        lastEnteredTextStyle
      } = styles;
  
      const {
        last_tutor_display_name: lastTutorName,
        last_entered: lastEnteredDate,
        time_in_total: timeInTotal
      } = this.props.participant;
  
      if (lastTutorName && lastEnteredDate) {
        return (
          <div style = { lastEnteredTextStyle }>{`Time in room: ${timeInTotal}`}</div>
        );
      }
  
      return null;
    }

  /**
   * Renders current lesson/practice mode timer.
   *
   * @returns {ReactElement}
   */
  renderPracticeModeTimer() {
    const {
      participantPracticeModeTimerTextStyle,
      participantPracticeModeTimerIconContainerStyle,
      modeTimerContainStyle
    } = styles;

    const {
      // practice_room_participant_id: practiceRoomParticipantId,
      practice_room_role_id: practiceRoomRoleId,
      practice_mode_at: practiceModeAt, /* Timestamp to keep track of the mode */
      lesson_mode_at: lessonModeAt
    } = this.props.participant;

    if ((practiceModeAt || lessonModeAt) && practiceRoomRoleId !== 2) {
      const secondsToNow = AppHelper.getSecondsToDate({
        date: this.props.now,
        now: practiceModeAt || lessonModeAt
      });
      const parsedDuration = AppHelper.formatSecondsToNow(secondsToNow);

      let {
        minutes,
        seconds
      } = parsedDuration;

      let className = '';
      let fontColor = '#777';

      if (secondsToNow >= OVERTIME_DURATION_SECOND) {
        fontColor = COLOR.FAILED;
        className = 'vibrate';
      } else if (secondsToNow >= WARNING_DURATION_SECOND) {
        fontColor = COLOR.ORANGE;
        className = 'vibrate';
      }

      if (secondsToNow >= 60 * 60) { // if over an hour
        minutes = 59;
        seconds = 59;
      }

      return (
        <div
          className = { className }
          style = { modeTimerContainStyle } >
          <span style = { participantPracticeModeTimerIconContainerStyle }>
            {
              practiceModeAt
                ? <IconDumbbell
                  viewBox='0 0 30 30'
                  style = {{ fontSize: 18, fill: fontColor, overflow: 'initial' }} />
                : <IconTeach
                  viewBox='0 0 30 30'
                  style = {{ fontSize: 18, fill: fontColor, overflow: 'initial' }} />
            }
          </span>
          {
            secondsToNow >= 60 * 60 /* timer doesn't show correct time when it's over 60 minutes */
              ? <span
                style = {{ ...participantPracticeModeTimerTextStyle,
                  color: fontColor }}>
                {'60:00+'}
              </span>
              : <span
                style = {{ ...participantPracticeModeTimerTextStyle,
                  color: fontColor }}>
                {`${minutes.toString().padStart(2, 0)}:${seconds.toString().padStart(2, 0)}`}
              </span>
          }
        </div>
      );
    }

    return null;
  }

  /**
   * Renders participant controls.
   *
   * @returns {ReactElement}
   */
  renderParticipantControls() {
    const {
      participant
    } = this.props;

    const {
      user_id: userId
    } = participant;

    const {
      controlContainerStyle,
      controlRootStyle,
    } = styles;

    // only show the control if the participant is not the actual user
    if (AppHelper.getUserId() === userId) {
      return null;
    }

    return (
      <div style={ controlRootStyle }>
        <Grid container spacing={0}>
          <Grid item xs={12} style={ controlContainerStyle }>
            { this.renderPracticeModeTimer() }
          </Grid>
        </Grid>
      </div>
    );
  }

  /**
   * Render handover notes.
   *
   * @returns {ReactElement}
   */
  renderHandoverNotes() {
    const {
      notes
    } = this.props.participant;

    const {
      isEditingNote,
      isNoteChanged,
      editedNoteText,
      isExpanded
    } = this.state;

    if (notes) {
      const {
        noteLastEditedInfoTextStyle,
        cancelNoteEditButtonStyle,
        editNoteButtonStyle,
        editNoteButtonContainerStyle
      } = styles;

      const {
        remarks,
        editorDisplayName,
        remarks_edited_at: remarksEditedAt,
        edited_by_user_id: editedByUserId,
        maxLength
      } = notes;

      if (isEditingNote) {
        const shouldShowSendButton = isNoteChanged && editedNoteText;

        return (
          <div>
            <FieldTextAreaStateless
              autoFocus = { true }
              className = 'input-control'
              enableResize = { 'vertical' }
              id = 'handoverNoteTextArea'
              isLabelHidden = { true }
              maxLength = { maxLength }
              minimumRows = { 5 }
              onChange = { this._onChangeNoteText }
              shouldFitContainer = { true }
              value = { editedNoteText } />
            <div style = { editNoteButtonContainerStyle }>
              <span
                className = { 'button' }
                onClick = { this._onCancelEditNotePress }
                style = {{ ...cancelNoteEditButtonStyle,
                  marginRight: shouldShowSendButton ? 5 : 0 }} >
                <IconClose  
                  style={{ fill: '#fff', fontSize: 18 }} />
              </span>
              {
                shouldShowSendButton
                  ? <span
                    className = { 'button' }
                    onClick = { this._onSaveNotePress }
                    style = { editNoteButtonStyle } >
                    <IconSend
                      style={{ fill: '#fff', fontSize: 18 }} />
                  </span>
                  : null
              }
            </div>
          </div>
        );
      }

      return (
        <div>
          <div
            className = { isExpanded ? 'expanded-note' : 'collapsed-note' }
            style = {{
              whiteSpace: 'pre-line',
              color: isExpanded ? '#FF0000' : '#FF000099',
              fontStyle: remarks ? 'normal' : 'italic'
            }} >
            {remarks || 'No notes.'}
          </div>
          {
            isExpanded && editedByUserId && remarksEditedAt
              ? <div
                style = { noteLastEditedInfoTextStyle } >
                {`>> ${AppHelper.getUserId() === editedByUserId
                  ? 'Me' : editorDisplayName},
                  ${getLocalizedDateFormatter(moment(remarksEditedAt)).format('DD MMM  hh:mma')}`}
              </div>
              : null
          }
          {
            isExpanded
              ? <div style = { editNoteButtonContainerStyle }>
                <span
                  className = { 'button' }
                  onClick = { this._onEditHandoverNotePress }
                  style = { editNoteButtonStyle } >
                  <IconEdit
                    style={{ fill: '#fff', fontSize: 18 }} />
                </span>
              </div>
              : null
          }
        </div>
      );
    }

    return <div />;
  }

  /**
   * Render selectTutor popover.
   *
   * @returns {ReactElement}
   */
 
  renderSelectTutorPopover() {
    const {
      isSelectTutorPopoverOpened,
    } = this.state;
    const {
      participant,
      participants,
      practiceRoom,
      t,
    } = this.props;

    if (!isSelectTutorPopoverOpened) {
      return null;
    }

    let tutors = participants?.filter(x => x?.practice_room_role_id === 2);
    tutors = tutors.filter(x => x.user_id !== participant.user_id);

    const handleClose = () => {
      // if (anchorRef.current && anchorRef.current.contains(event.target)) {
      //   return;
      // }
      
      this.setState({
        isSelectTutorPopoverOpened: false
      });
    };

    const onSelectedTutorPress = (tutor, student, practiceRoom) => {
      const me = AppHelper.getUser();
      const fromUser = {
        user_id: me.id,
        name: AppHelper.formatName(me.firstName, me.lastName)
      };

      const params = {
        serverUrl: practiceRoom.annot_server_url,
        roomId: practiceRoom.meet_room_id,
        event: 'pushEvent',
        eventData: {
          event_type_id: PRACTICE_ROOM_EVENT.RESCUE_TR_STUDENT,
          data: {
            userIds: [tutor.user_id],
            student: {
              user_id: student.user_id,
              name: student.first_name?.split('[')?.[0]
            },
            fromUser
          },
          session_id: practiceRoom.meet_room_id,
          user_id: me.id
        }
      };

      ControlRoomSocketHelper.oneOffEmit(params)
        .then((response) => {
          console.log('done oneOffEmit', response);
          // Message.success({
          //   message: t('controlRoom.sendSuccessfully')
          // });
          this.props.updateCommonStates({
            prop: 'snackbar',
            value: {
              open: true,
              severity: 'success',
              autoHideDuration: 3000,
              message: t('controlRoom.trRescueSentSuccessfully')
            }
          });
        })
        .catch((error) => {
          console.log('error oneOffEmit', error);
          this.props.updateCommonStates({
            prop: 'snackbar',
            value: {
              open: true,
              severity: 'error',
              autoHideDuration: 3000,
              message: t('controlRoom.trRescueSentFailed')
            }
          });
        });
      this.setState({
        isSelectTutorPopoverOpened: false,
      });
    };


    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={isSelectTutorPopoverOpened}>
        <DialogTitle id="simple-dialog-title">{t('controlRoom.selectTutor')}</DialogTitle>
        <List>
          {tutors.map((tutor) => (
            <ListItem button onClick={() => onSelectedTutorPress(tutor, participant, practiceRoom)} key={tutor.user_id}>
              <ListItemAvatar>
                <Avatar style={styles.avatar}>
                  <IconPerson />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={ tutor.first_name + ' ' + tutor.last_name} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  }

  /**
   * Render menu button.
   *
   * @returns {ReactElement}
   */
  renderMenuButton() {
    const {
      participant,
      participants,
      t,
    } = this.props;

    let tutors = participants?.filter(x => x?.practice_room_role_id === 2);
    tutors = tutors.filter(x => x.user_id !== participant.user_id);

    if (participant.user_id !== AppHelper.getUserId()) {

      const {
        anchorEl
      } = this.state;

      return (
        <div>
          <div className={'button'} aria-controls="simple-menu" aria-haspopup="true" onClick={this._onMenuPress}>
            <IconMore
              style={{ fill: '#000', fontSize: 18 }} />
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this._onMenuClose}
          >
            <MenuItem onClick={this._onKickParticipantPress}>{t('controlRoom.kick')}</MenuItem>
            {
              participant && participant.practice_room_role_id === 1 && tutors && tutors.length > 0
                ?
                <MenuItem onClick={this._onSelectTutorPress}>{t('controlRoom.selectTutor')}</MenuItem>
                :
                null
            }
          </Menu>
          { this.renderSelectTutorPopover() }
        </div>
      );
    }

    return null;
  }

  /**
   * Implements React's {@link Component#render()}.
   *
   * @inheritdoc
   * @returns {ReactElement}
   */
  render() {
    const {
      rowContainerStyle,
      participantItemOuterContainer,
      blurredParticipantItemOuterContainer,
      participantContainer,
      speakerFillerButtonStyle,
      tutorTextStyle
    } = styles;

    const {
      participant,
      jitsiParticipant
    } = this.props;

    const {
      lesson_mode_tutor_user_id: tutorUserId,
      tutor_first_name: tutorFirstName,
      tutor_last_name: tutorLastName
    } = participant;

    const me = participant.user_id === AppHelper.getUserId();

    return (
      <div
        key = { participant.user_id }
        style = { jitsiParticipant ? participantItemOuterContainer : blurredParticipantItemOuterContainer }>
        <div
          style = { participantContainer }>
          {
            me
              ? <div style = { speakerFillerButtonStyle } />
              : <div>
                { this.renderToggleLockButton() }
              </div>
          }

          {/* <Avatar
            appearance = 'circle'
            name = 'large'
            size = 'large'
            src = { participant.avatar_url } /> */}

          <div style = { styles.timerContainer }>
            <span style = { styles.name }>
              <strong style = {styles.nameLineStyle}>
                {AppHelper.formatName(participant.first_name,
                  participant.last_name?.charAt(0))} {me ? '(Me)' : ''}
              </strong>
              {
                tutorUserId
                  ? <span
                    style = {{ ...rowContainerStyle,
                      marginLeft: 5 }}>
                    <IconCrown
                      style={{ fill: '#ffa500', fontSize: 18 }} />
                    <span style = { tutorTextStyle }>
                      {AppHelper.getInitial({
                        firstName: tutorFirstName,
                        lastName: tutorLastName,
                        charLimit: 3 })}
                    </span>
                  </span>
                  : null
              }
            </span>
            { this.renderBadges(this.props) }
            { this.renderAccumulatedLessonTimer() }
            { this.renderInRoomTimer() }
            { this.renderLastTutorName() }
            { this.renderLastEnteredDate() }
            { this.renderLastEnteredDuration() }
          </div>

          { this.renderParticipantControls() }
          { this.renderMenuButton() }
        </div>
        { participant.notes && <div className = { 'dashed' } /> }
        <div
          id = { 'handover-note' }
          onClick = { this._onToggleExpandedHandoverNote }>
          { this.renderHandoverNotes() }
        </div>
      </div>
    );
  }
}

export default connect(null, {
  updateCommonStates
})(withTranslation()(ParticipantListItem));
