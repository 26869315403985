import { getStore } from '../Store';
import { historyPush, historyReplace } from '../../src/history';
import { updateCommonStates } from '../actions';
import AppHelper from './AppHelper';

class NavigationHelper {
  static redirectCachedRoute({ history, replace = false }) {
    const { redirectTo: _redirectTo } = getStore().getState().common;
    const redirectTo = { ..._redirectTo };
    let redirectLocation = '/dash';

    if (redirectTo) {
      // purge all unneeded search params as it would have been resolved before redirect
      const purgedSearchString = AppHelper.purgeSearchParamString(redirectTo.search);
      redirectTo.search = purgedSearchString;

      redirectLocation = redirectTo;
      getStore().dispatch(updateCommonStates({ prop: 'redirectTo', value: null }));
    }

    let redirectFunc = null;

    if (history) {
      redirectFunc = replace ? history.replace : history.push;
    } else {
      redirectFunc = replace ? historyReplace : historyPush;
    }

    if (redirectFunc) {
      redirectFunc(redirectLocation);
    }
  }
}

export default NavigationHelper;
