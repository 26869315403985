import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { LogoAppBar } from '../common';
import { getOrder, resetOrderDetailState } from '../../actions';

const OrderThankYouScreen = (props) => {
  const {
    orderDetails
  } = props;

  const {
    order_type_id
  } = orderDetails;

  const { t } = useTranslation();
  const { orderRefId } = useParams();
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    props.getOrder({
      orderRefId,
      status: 'complete',
      callback: getOrderCallback
    });

    return () => {
      props.resetOrderDetailState();
    };
  }, []);

  const getOrderCallback = ({ error }) => {
    if (error) {
      setHasError(true);
    }
  };

  /**
   * Can customise description according to different order_type_id
   * @returns React.Component
   */
  const renderDescription = () => {
    if (hasError) {
      return (
        <Box mt={3}>
          <Typography style={{ textAlign: 'center' }} variant='body1'>{t('order.couldNotRetrieveOrderInfo')}</Typography>
        </Box>
      );
    }

    return (
      <Box mt={3}>
        <Typography style={{ textAlign: 'center' }} variant='body1'>{t('order.youCanAccessTRWhenItIsOpen')}</Typography>
      </Box>
    );
  };

  const renderButtons = () => {
    return (
      <Box mt={4}>
        <Grid
          item
          container
          alignItems='center'
          justifyContent='center'
          spacing={2}
        >
          <Grid item>
            <Button
              color='primary'
              variant='contained'
              component={Link}
              to='/dash'
              replace
            >
              {t('order.returnToDash')}
            </Button>
          </Grid>
          {order_type_id === 1 && <Grid item>
            <Button
              color='primary'
              variant='contained'
              component={Link}
              to='/tutor-room'
              replace
            >
              {t('order.goToTR')}
            </Button>
          </Grid>}
        </Grid>
      </Box>
    );
  };

  const render = () => {
    return (
      <Grid
        container
        component="main"
        style={{ minHeight: '100vh' }}
        alignItems='center'
        justifyContent='center'
      >
        <Grid
          item
          container
          alignItems='center'
          justifyContent='center'
          direction='column'
          style={{ width: '60%', marginTop: -20 }}
        >
          <CssBaseline />
          <LogoAppBar />
          <Typography style={{ textAlign: 'center' }} variant='h1'>{hasError ? t('order.oops') : t('order.thankYou')}</Typography>
          {renderDescription()}
          {renderButtons()}
        </Grid>
      </Grid>
    );
  };

  return render();
};

const mapStateToProps = (state) => {
  const {
    orderDetails
  } = state.order;

  return {
    orderDetails
  };
};

export default connect(mapStateToProps, {
  getOrder,
  resetOrderDetailState
})(OrderThankYouScreen);