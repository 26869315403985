import React, {
//  useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  // Layout,
  Button,
  Message,
  Upload,
} from 'element-react';
import 'element-theme-default';
import {
  // getPracticeRoomFiles,
  // deletePracticeRoomFiles,
  uploadPracticeRoomFiles,
} from '../../api';
// import EventReducer from '../../reducers/EventReducer';
// import { fieldErrorUpdate } from '../../actions';
// import { update } from 'lodash';

const FileUploader = (props) => {
  const { t } = useTranslation();

  const {
    limit,
    profileId,
    callback,
    uploadMine,
  } = props;
 
  // eslint-disable-next-line no-unused-vars
  const [fileList, setFileList] = React.useState([]);

  const fetchUploadPracticeRoomFiles = async ({ filePaths, practiceRoomProfileId, onUploadProgress }) => {
    try {
      var res;
      res = await uploadPracticeRoomFiles({ filePaths, practiceRoomProfileId, onUploadProgress });
      if (res && res.data) {
        if (res.data.success) {
          return res.data.success;
        } else {
          console.warn(res);
          return false;
        }
      } else {
        console.error(res);
        return null;
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const requestUploadPracticeRoomFiles = async ({ filePaths, profileId, onUploadProgress }) => {
    const success = await fetchUploadPracticeRoomFiles({
      practiceRoomProfileId: profileId,
      filePaths,
      onUploadProgress,
    });
    if (success) {
      return success;
    } else {
      return false;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const onPreview = (file) => {
  };

  // eslint-disable-next-line no-unused-vars
  const onRemove = (file) => {
  };

  const onSuccess = (response, file, fileList) => {
    callback(null, { response, file, fileList });
  };

  // eslint-disable-next-line no-unused-vars
  const onProgress = (event, file, fileList) => {
  };

  const onError = (err, file, fileList) => {
    console.log('--onError-err=', err, ', file=', file, ',fileList=', fileList);
    callback(err, { file, fileList });
  };

  // eslint-disable-next-line no-unused-vars
  const beforeUpload = (file) => {
  };

  const uploadFiles = async (params) => {
    const file = params.file;

    const fileType = file.type;
    const isImage = fileType.indexOf('image') !== -1;
    const isPDF = fileType.indexOf('pdf') !== -1;
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isImage && !isPDF) {
      Message.error(t('userFileDialog.fileUploader.fileTypeWarningMessage'));
      return;
    }
    if (!isLt5M) {
      Message.error(t('userFileDialog.fileUPloader.fileSizeWarningMessage'));
      return;
    }

    params.status = 'uploading';

    const onUploadProgress = (event) => {
      let percent = (event.loaded / event.total * 100) || 0;
      params.onProgress({ percent });
    };

    const result = await requestUploadPracticeRoomFiles({
      profileId,
      filePaths: [file],
      onUploadProgress,
    });

    if (result) {
      params.onSuccess({ result });
    } else {
      params.onError({ result });
    }

    callback(null , {});
  };

  return (
    <div>
      <Upload
        className="upload-demo"
        action="//fakeAPIUrl/post"
        fileList={fileList}
        listType="text"
        limit={limit ? limit : 3}
        multiple
        onPreview={file => onPreview(file)}
        onRemove={file => onRemove(file)}
        // eslint-disable-next-line no-unused-vars
        onExceed={(files, fileList) => {
          Message.warning(t('userFileDialog.fileUploader.fileCountWarningMessage'));
        }}
        onSuccess={onSuccess}
        onProgress={onProgress}
        onError={onError}
        beforeUpload={beforeUpload}
        httpRequest={uploadFiles}
        tip={<div className="el-upload__tip">{ t('userFileDialog.fileUploader.fileCommonWarningMessage')}</div>}
      >
        <Button size="small" type="primary">
          {
            uploadMine
              ?
              t('userFileDialog.fileUploader.uploadFile')
              :
              t('userFileDialog.fileUploader.helpUploadFile')
          }
        </Button>
      </Upload>
    </div>
  );
};

export default FileUploader;