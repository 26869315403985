import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Lottie from 'lottie-react';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { IconDumbbell, IconTeach } from '../../Icons';
import AppHelper from '../../../helpers/AppHelper';
import { CoffeeBreakDialog } from '../../dialogs/tutorRoom/CoffeeBreakDialog';
import { openDialog } from '../../../actions';

const { COLOR } = require('../../../helpers/ConstantHelper');
const coffeeBreakAnimation = require('../../../img/lottie/coffeetime.json');

const useStyles = makeStyles(() => ({
  floatingBtnContainer: {
    // position: 'absolute',
    // top: 100,
    // marginLeft: 15,
    width: 'auto'
  },
  coffeeBreakLottieContainer: {
    transform: 'scale(3)',
    marginBottom: -15
  },
  iconButton: {
    height: 50,
    width: 50,
    position: 'relative'
  },
  solidIcon: {
    fontSize: 21,
    fill: '#fff',
    overflow: 'initial'
  },
  translucentIcon: {
    fontSize: 21,
    fill: '#fff5',
    overflow: 'initial'
  }
}));

const TutorRoomIndicators = (props) => {
  const {
    isVisible,
    toggleParticipantList,
    isUserOnCoffeeBreak,
    earliestPracticeModeTS,
    currentTutorModeTS,
    now
  } = props;

  const classes = useStyles();

  const getTimerString = ({ timestamp }) => {
    const secondsToNow = AppHelper.getSecondsToDate({
      date: now,
      now: timestamp });

    if (secondsToNow >= 60 * 60) {
      return '60:00+';
    }

    const parsedDuration = AppHelper.formatSecondsToNow(secondsToNow);

    const {
      minutes,
      seconds
    } = parsedDuration;

    return `${minutes.toString().padStart(2, 0)}:${seconds.toString().padStart(2, 0)}`;
  };

  const onCoffeeBreakPress = () => {
    props.openDialog({
      dialog: CoffeeBreakDialog,
      props: {
        disableEscapeKeyDown: true,
        disableBackdropClick: true
      }
    });
  };

  const renderPracticeButton = () => {
    return (
      <IconButton
        size={'medium'}
        onClick={toggleParticipantList}
        className={classes.iconButton}
        style={{
          backgroundColor: COLOR.BLUE
        }}
      >
        <IconDumbbell
          viewBox='0 0 30 30'
          className={earliestPracticeModeTS ? classes.translucentIcon : classes.solidIcon}
        />
        {
          earliestPracticeModeTS && <div className='timer'>
            <Typography variant='body2' className='timer-text'>
              {getTimerString({ timestamp: earliestPracticeModeTS })}
            </Typography>
          </div>
        }
      </IconButton>
    );
  };

  const renderTeachButton = () => {
    return (
      <IconButton
        size={'medium'}
        onClick={toggleParticipantList}
        className={classes.iconButton}
        style={{
          backgroundColor: COLOR.GREEN
        }}
      >
        <IconTeach
          viewBox='0 0 30 30'
          className={currentTutorModeTS ? classes.translucentIcon : classes.solidIcon}
        />
        {
          currentTutorModeTS && <div className='timer'>
            <Typography variant='body2' className='timer-text'>
              {getTimerString({ timestamp: currentTutorModeTS })}
            </Typography>
          </div>
        }
      </IconButton>
    );
  };
  
  const renderCoffeeBreakButton = () => {
    return (
      <IconButton
        size={'medium'}
        onClick={onCoffeeBreakPress}
        className={classes.iconButton}
        style={{
          backgroundColor: isUserOnCoffeeBreak ? COLOR.GREEN : '#0000'
        }}
      >
        <Lottie
          animationData={coffeeBreakAnimation}
          className={classes.coffeeBreakLottieContainer}
          loop={isUserOnCoffeeBreak}
          autoplay={isUserOnCoffeeBreak}
        />
      </IconButton>
    );
  };

  const render = () => {
    if (isVisible) {
      return (
        <Grid
          className={classes.floatingBtnContainer}
          container
          item
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          {renderPracticeButton()}
          <Box mt={1}>
            {renderTeachButton()}
          </Box>
          <Box mt={1}>
            {renderCoffeeBreakButton()}
          </Box>
        </Grid>
      );
    }
  
    return null;
  };

  return render();
};

const mapStateToProps = (state) => {
  const {
    participants
  } = state.practiceRoom.practiceRoomConfig;

  const userParticipant = participants.find(p => p.user_id === AppHelper.getUserId());

  let earliestPracticeModeTS = null;
  let currentTutorModeTS = null;

  participants.forEach(p => {
    if (p.practice_mode_at) {
      if (!earliestPracticeModeTS) {
        earliestPracticeModeTS = p.practice_mode_at;
      } else if (moment(p.practice_mode_at).isBefore(moment(earliestPracticeModeTS))) {
        earliestPracticeModeTS = p.practice_mode_at;
      }
    }

    if (p.lesson_mode_tutor_user_id === AppHelper.getUserId()) {
      currentTutorModeTS = p.lesson_mode_at;
    }
  });

  return {
    earliestPracticeModeTS,
    currentTutorModeTS,
    isUserOnCoffeeBreak: (userParticipant && userParticipant?.coffee_break_at !== null) ?? false
  };
};

export default connect(mapStateToProps, {
  openDialog
})(TutorRoomIndicators);
