import {
  UPDATE_NOTIFICATION_STATE,
  LOGOUT_USER_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
  page: 1,
  lastPage: 1,
  notificationList: [],
  notificationListLoading: false,
  notificationListLoadingMore: false,
  lastSeenDateTime: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UPDATE_NOTIFICATION_STATE:
    return { ...state, [action.payload.prop]: action.payload.value };
  case LOGOUT_USER_SUCCESS:
    return INITIAL_STATE;
  default:
    return state;
  }
};
