import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { useTranslation } from 'react-i18next';

/**
 * Reimplements the existing common/AlertDialog, for use with openDialog action.
 * @param {*} props - Props for the AlertDialog component.
 * @returns 
 */
const AlertDialog = (props) => {
  const {
    title,
    contentText,
    closeDialog,
    buttons,
    _open,
    _onClose,
    ...otherProps
  } = props;
  
  const { t } = useTranslation();

  const buttonHandler = (btnFunction) => () => {
    // if function returns true, then do not dismiss the dialog
    if (btnFunction && btnFunction() === true) {
      return;
    }

    closeDialog();
  };
  
  const renderButtons = () => {
    if (buttons && buttons.length >= 0) {
      return buttons.map((btn, index) => {
        const {
          primary,
          onClick,
          text,
          props
        } = btn;
        return (
          <Button
            key={index}
            onClick={buttonHandler(onClick)}
            autoFocus={primary}
            color={'primary'}
            variant={primary ? 'contained' : 'outlined'}
            {...props}
          >
            {text}
          </Button>
        );
      });
    }

    return (
      <Button color='primary' autoFocus onClick={buttonHandler()}>{t('alert.ok')}</Button>
    );
  };

  const render = () => {
    return (
      <Dialog
        open={_open}
        onClose={_onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...otherProps}
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {renderButtons()}
        </DialogActions>
      </Dialog>
    );
  };
  
  return render();
};

export { AlertDialog };