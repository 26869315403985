import YoubrioApiHelper from '../helpers/YoubrioApiHelper';
import AppHelper from '../helpers/AppHelper';

export const getFundPrices = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return YoubrioApiHelper.get(`fund-prices?lang=${lang}`, true);
};

export const getFundBalanceDetails = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return YoubrioApiHelper.get(`fund?lang=${lang}`, true);
};

export const topUpFundByBraintree = ({ currencyCode, amount, paymentMethodNonce }) => {
  const params = {
    currencyCode,
    amount,
    nonce: paymentMethodNonce
  };
  return YoubrioApiHelper.post('fund/topup', params, true);
};

export const topUpFundByBraintreeV2 = ({ currencyCode, amount, surchargeAmount, totalAmount, paymentMethodNonce }) => {
  const params = {
    currencyCode,
    amount,
    surchargeAmount,
    totalAmount,
    nonce: paymentMethodNonce
  };
  return YoubrioApiHelper.post('v2/fund/topup', params, true);
};

export const getRecentFundTxnList = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return YoubrioApiHelper.get(`fund-txns/recent?lang=${lang}`, true);
};

export const getFundTxnListByPage = ({ page }) => {
  const lang = AppHelper.getAppLocaleCodeForApi();

  if (page) {
    return YoubrioApiHelper.get(`fund-txns?lang=${lang}&page=${page}`, true);
  }
  return YoubrioApiHelper.get(`fund-txns?lang=${lang}`, true);
};

export const getOmiPayOrderInfo = ({ wid, amount, currencyCode }) => {
  const txnType = 'TPUP';
  const params = {
    wid,
    amount,
    txnType,
    currency: currencyCode
  };
  return YoubrioApiHelper.post('payment/omipay/orderinfo', params, true);
};

export const getAlipayCNOrderInfo = ({ wid, amount, currencyCode, cnyAmount }) => {
  const txnType = 'TPUP';
  const params = {
    wid,
    amount,
    txnType,
    currency: currencyCode,
    cnyAmt: cnyAmount
  };
  return YoubrioApiHelper.post('payment/alipay-cn/orderinfo', params, true);
};

export const getAlipayCNOrderInfoV2 = ({
  wid,
  amount,
  currencyCode,
  cnyAmount,
  surchargeAmount,
  totalCnyAmount
}) => {
  const txnType = 'TPUP';
  const params = {
    wid,
    amount,
    txnType,
    currency: currencyCode,
    cnyAmt: cnyAmount,
    totalCnyAmt: totalCnyAmount,
    surchargeAmount
  };
  return YoubrioApiHelper.post('v2/payment/alipay-cn/orderinfo', params, true);
};

export const getWechatPayCNPrepayInfo = ({ wid, amount, currencyCode, cnyAmount }) => {
  const txnType = 'TPUP';
  const params = {
    wid,
    amount,
    txnType,
    currency: currencyCode,
    cnyAmt: cnyAmount
  };
  return YoubrioApiHelper.post('payment/wechat-cn/prepayinfo', params, true);
};

export const getWechatPayCNPrepayInfoV2 = ({
  wid,
  amount,
  currencyCode,
  cnyAmount,
  totalCnyAmount,
  surchargeAmount
}) => {
  const txnType = 'TPUP';
  const params = {
    wid,
    amount,
    txnType,
    currency: currencyCode,
    cnyAmt: cnyAmount,
    totalCnyAmt: totalCnyAmount,
    surchargeAmount
  };
  return YoubrioApiHelper.post('v2/payment/wechat-cn/prepayinfo', params, true);
};

export const checkOmiPayPayment = ({ omiPayTxnId }) => {
  return YoubrioApiHelper.get(`omipay/payment?otid=${omiPayTxnId}`, true);
};

export const checkAlipayCNPayment = ({ outTradeNo }) => {
  return YoubrioApiHelper.get(`payment/alipay-cn/paid?outTradeNo=${outTradeNo}`, true);
};

export const getWithdrawFundParams = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return YoubrioApiHelper.get(`fund-withdraw/params?lang=${lang}`, true);
};

export const getWithdrawFundParamsV2 = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return YoubrioApiHelper.get(`v2/fund-withdraw/params?lang=${lang}`, true);
};

export const getPaymentMethodDetails = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return YoubrioApiHelper.get(`payment-method-details?lang=${lang}`, true);
};

export const getWithdrawals = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return YoubrioApiHelper.get(`withdrawals?lang=${lang}`, true);
};

export const makeNewWithdrawal = ({
  pw,
  amount,
  paymentMethodDetailId,
  abn,
  gst
}) => {
  const params = {
    p: pw,
    amount,
    pmdid: paymentMethodDetailId,
    abn,
    gst
  };
  return YoubrioApiHelper.post('withdrawal', params, true);
};

export const makeNewWithdrawalV2 = ({
  pw,
  amount,
  paymentMethodDetailId,
  abn,
  gst,
  serviceFee
}) => {
  const params = {
    p: pw,
    amount,
    pmdid: paymentMethodDetailId,
    abn,
    gst,
    serviceFee
  };
  return YoubrioApiHelper.post('v2/withdrawal', params, true);
};

export const createNewPaymentMethodDetail = ({
  paymentMethodTypeId,
  name,
  alipayUsername,
  paypalEmail,
  bankId,
  bankAccNo,
  bsb
}) => {
  let alipayUsernameType = 'amobile';
  if (AppHelper.isEmail(alipayUsername)) {
    alipayUsernameType = 'aemail';
  }
  const params = {
    pmtid: paymentMethodTypeId,
    name,
    [alipayUsernameType]: alipayUsername,
    pemail: paypalEmail,
    bid: bankId,
    ban: bankAccNo,
    bsb
  };

  return YoubrioApiHelper.post('payment-method-detail', params, true);
};

export const createNewPaymentMethodDetailV2 = ({
  paymentMethodTypeId,
  name,
  alipayUsername,
  paypalEmail,
  bankId,
  bankAccNo,
  bsb,
  bankCountryId,
  bankName,
  bankIban,
  bankSwift,
  bankAddress
}) => {
  let alipayUsernameType = 'amobile';
  if (AppHelper.isEmail(alipayUsername)) {
    alipayUsernameType = 'aemail';
  }
  const params = {
    pmtid: paymentMethodTypeId,
    name,
    [alipayUsernameType]: alipayUsername,
    pemail: paypalEmail,
    bid: bankId,
    ban: bankAccNo,
    bsb,
    bankCountryId,
    bankName,
    bankIban,
    bankSwift,
    bankAddress
  };

  return YoubrioApiHelper.post('v2/payment-method-detail', params, true);
};

export const makeStudentNewWithdrawal = ({
  pw,
  amount,
  paymentMethodDetailId
}) => {
  const params = {
    p: pw,
    amount,
    pmdid: paymentMethodDetailId
  };
  return YoubrioApiHelper.post('student/withdrawal', params, true);
};

export const deletePaymentMethodDetail = ({ paymentMethodDetailId }) => {
  return YoubrioApiHelper.post(`payment-method-detail/${paymentMethodDetailId}/delete`, null, true);
};

export const getFundTxnDetail = ({ fundTxnId }) => {
  return YoubrioApiHelper.get(`fund-txn/${fundTxnId}`, true);
};

export const getYoubrioExchangeRate = ({ fromCurrency, toCurrency }) => {
  return YoubrioApiHelper.get(`forex/rate?from=${fromCurrency}&to=${toCurrency}`, true);
};

// export const reactAlipayTest = () => {
//   return YoubrioApiHelper.post('v2/react/alipay', null, true);
// };
