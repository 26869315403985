import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GLOBAL from '../../helpers/GLOBAL';
import { useHistory } from 'react-router-dom';

const LogoAppBar = (props) =>{
  const {
    rightComponent,
    showBackButton = false
  } = props;

  const history = useHistory();

  const onBackClick = () => {
    if (history) {
      history.goBack();
    }
  };

  const render = () => {
    const locale = GLOBAL.get('locale');
    const logoSrc = (locale === 'zh-CN' || locale === 'zh-TW')
      ? require('../../img/redYoubrioLogoCn.png')
      : require('../../img/redYoubrioLogoEn.png');

    return (
      <AppBar
        elevation={0}
        position="fixed"
        className={'white-bg'}
        style={{ borderBottom: '1px solid #efefef' }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <React.Fragment>
              {
                showBackButton
                  ? <Box ml={-2} mr={1}>
                    <IconButton aria-label="back" size="medium" onClick={onBackClick}>
                      <ArrowBackIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                  : null
              }
              <img alt="logo" height="50px" width="135px" src={logoSrc} />
              <Box ml={2} style={{ flexGrow: 1 }} />
              {rightComponent}
            </React.Fragment>
          </Toolbar>
        </Container>
      </AppBar>
    );
  };
  
  return render();
};

export { LogoAppBar };