import {
  UPDATE_NOTIFICATION_STATE,
} from '../actions/types';
import {
  getNotificationListByPage,
  updateNotificationReadStatus,
} from '../api';
import { getStore } from '../Store';
// import GLOBAL from '../helpers/GLOBAL';
// import AppHelper from '../helpers/AppHelper';

export const updateNotificationState = ({ prop, value }) => {
  return {
    type: UPDATE_NOTIFICATION_STATE,
    payload: { prop, value }
  };
};

export const getNotificationList = () => {
  return (dispatch) => {
    const { notification } = getStore().getState();
    const { page, lastPage, notificationList } = notification;

    if (page === 1) {
      // initial loading
      dispatch(updateNotificationState({ prop: 'notificationListLoading', value: true }));

      getNotificationListByPage()
        .then((res) => {
          const { data } = res;
          dispatch(updateNotificationState({ prop: 'notificationListLoading', value: false }));

          // only update current page if has more
          if (page < data.notificationList.last_page) {
            dispatch(updateNotificationState({ prop: 'page', value: page + 1 }));
          }
          dispatch(updateNotificationState({ prop: 'notificationList', value: data.notificationList.data }));
          dispatch(updateNotificationState({ prop: 'lastPage', value: data.notificationList.last_page }));
        })
        .catch(err => {
          console.error(err);
          dispatch(updateNotificationState({ prop: 'notificationListLoading', value: false }));
        });
    } else if (page < lastPage) {
      // GLOBAL.logger.info('loading more notifications');
      // infinite loading, loading more
      dispatch(updateNotificationState({ prop: 'notificationListLoadingMore', value: true }));

      getNotificationListByPage(page)
        .then((res) => {
          const { data } = res;
          dispatch(updateNotificationState({ prop: 'notificationListLoadingMore', value: false }));

          // update current page if has more
          dispatch(updateNotificationState({ prop: 'page', value: page + 1 }));
          dispatch(updateNotificationState({ prop: 'notificationList', value: [...notificationList, ...data.notificationList.data] }));
          dispatch(updateNotificationState({ prop: 'lastPage', value: data.notificationList.last_page }));
        })
        .catch(err => {
          console.error(err);
          dispatch(updateNotificationState({ prop: 'notificationListLoadingMore', value: false }));
        });
    }
  };
};

/**
 * Open notification by specific id and mark status to read
 * @param {Object} notification id 
 */
export const openNotification = (data) => {
  return (dispatch) => {
    dispatch(updateNotificationState({ prop: 'opened', value: data }));
  };
};

export const changeNotificationReadStatus = (notificationId) => {
  return (dispatch) => {
    const { notification } = getStore().getState();
    const { notificationList } = notification;

    updateNotificationReadStatus({notificationId})
      .then((res) => {
        const index = notificationList.map(x => x.id).indexOf(notificationId);
        const newNotificationList = notificationList;
        newNotificationList[index].read_status = 1;
        dispatch(updateNotificationState({ prop: 'notificationList', value: newNotificationList}));
      })
      .catch(err => {
        console.error(err);
      });
  };
};

