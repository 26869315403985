/* @flow */

import React, { useEffect } from 'react';
import Lottie from 'lottie-react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import sirenAnimation from '../../../img/lottie/siren.json';
import { updatePracticeRoomConfigState } from '../../../actions';
import { BaseDialog } from './BaseDialog';
import { IconClose } from '../../Icons';

// import { IconClose } from '../../../base/icons';

const rescueAlertAudio = require('../../../sounds/ding_dong.wav');

const styles = {
  activatedTextStyle: {
    fontWeight: 'bold',
    fontSize: 20,
    color: 'rgb(255, 204, 0)',
    textAlign: 'center',
    marginBottom: 15
  }
};

/**
 * Implements a functional component for the dialog that will appear when rescue signal is received.
 */
const _RescueStudentDialog = (props) => {
  const {
    rescueSignal,
    _open,
    _onClose,
    closeDialog: _closeDialog,
    dispatch,
    ...otherProps
  } = props;

  const { t } = useTranslation();

  useEffect(() => {
    const favicon = document.getElementById('favicon');
    const originalTitle = document.title;
    const originalFaviconHref = favicon.href;
    

    document.title = t('tutorRoom.rescueSignal.rescueAlertTitle');
    favicon.href = '/favicon-alert.ico';

    return () => {
      document.title = originalTitle;
      favicon.href = originalFaviconHref;
      const alarm = document.getElementById('rescueAlarm');
      if (alarm) {
        alarm.pause();
        alarm.currentTime = 0;
      }
    };
  }, []);

  const onCanPlayThrough = () => {
    // play straight away once loaded
    const alarm = document.getElementById('rescueAlarm');
    if (alarm) {
      alarm.play();
    }
  };

  /**
   * Callback to be invoked for cancel action.
   * Hides the dialog.
   *
   * @returns {void}
   */
  const _onDialogCancel = () => {
    _closeDialog();
  };

  /**
   * Callback to be invoked for submit action.
   * Opens the TR participant list.
   *
   * @returns {void}
   */
  const _onRescueClick = () => {
    // has to resort to this 'props.dispatch(action)' pattern instead to avoid circular
    // dependency because AnnotationSocketHelper is being imported in PracticeRoomActions.
    // When AnnotationSocketHelper is being imported, this (RescueStudentDialog) component
    // is being imported at the same time too. But at that time, the actions in PracticeRoomActions
    // are not being loaded yet, hence if we include them into mapDispatchToAction,
    // then it will be undefined, resulting in props.action() being not a function.
    _closeDialog();
    dispatch(updatePracticeRoomConfigState({ prop: 'isParticipantListOpen', value: true }));
  };

  /**
   * Renders the header of the dialog.
   *
   * @returns {ReactElement}
   */
  const renderHeader = () => {
    return (
      <div className = { 'header-btn dialog-close' }>
        <IconButton
          onClick = { _onDialogCancel }>
          <IconClose
            style={{ fill: '#ffffff', fontSize: 18 }} />
        </IconButton>
      </div>
    );
  };

  /**
   * Renders the body of the dialog.
   *
   * @returns {ReactElement}
   */
  const renderBody = () => {
    const {
      student,
      fromUser
    } = rescueSignal;

    return (
      <div className = 'dialog-container'>
        <div
          className = 'dialog-rescue-student dialog-info-text' >
          <Lottie
            animationData={sirenAnimation}
            loop={true}
            autoplay={true}
            style={{
              width: 150,
              height: 150,
              // transform: 'scale(1.5)'
            }} />
        </div>
        <div className = 'dialog-info-header'>{t('tutorRoom.rescueSignal.rescueSignal').toUpperCase()}</div>
        {
          student ? <div style = { styles.activatedTextStyle }>{student.name.toUpperCase()}</div>
            : null
        }
        <div className = 'dialog-info-text'>
          {t('tutorRoom.rescueSignal.needYourImmediateAttention', { fromUser: fromUser?.name })}
        </div>
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <Button
        fullWidth
        onClick={_onRescueClick}
        autoFocus={true}
        color={'primary'}
        variant={'contained'}
      >
        { t('tutorRoom.rescueSignal.openTRParticipantList') }
      </Button>
    );
  };

  const renderRescueAudio = () => {
    return (
      <audio
        loop={false}
        onCanPlayThrough={onCanPlayThrough}
        id="rescueAlarm"
        src={rescueAlertAudio} />
    );
  };

  /**
   * Implements React's {@link Component#render()}.
   *
   * @inheritdoc
   * @returns {ReactElement}
   */
  const render = () => {
    return (
      <BaseDialog
        open={_open}
        onClose={_onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...otherProps}
        maxWidth={'xs'}
      >
        <DialogTitle id="alert-dialog-title">
          {renderHeader()}
        </DialogTitle>
        <DialogContent>
          {renderBody()}
        </DialogContent>
        <DialogActions>
          {renderButtons()}
        </DialogActions>
        {renderRescueAudio()}
      </BaseDialog>
    );
  };

  return render();
};

/**
 * Maps part of the Redux store to the props of this component.
 *
 * @param {Object} state - The Redux state.
 * @param {Props} ownProps - The own props of the component.
 * @returns {Props}
 */
const mapStateToProps = (state) => {
  const {
    rescueSignal
  } = state.practiceRoom.practiceRoomConfig;

  return {
    rescueSignal
  };
};

const RescueStudentDialog = connect(mapStateToProps)(_RescueStudentDialog);

export { RescueStudentDialog };
