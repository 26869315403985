import {
  UPDATE_CERTIFICATE_STATE,
  UPDATE_UPLOAD_CERTIFICATE_STATE,
  UPDATE_UPLOADED_CERTIFICATES_STATE,
  LOGOUT_USER_SUCCESS,
  RESET_UPLOAD_CERTIFICATE_STATE,
  RESET_UPLOADED_CERTIFICATES_STATE
} from '../actions/types';

const INITIAL_UPLOAD_CERTIFICATE_STATE = {
  remarks: '',
  filePath: ''
};

const INITIAL_UPLOADED_CERTIFICATES_STATE = {
  uploadedCertList: [],
  maxPage: 0,
  isLoading: false,
  isNewPageLoading: false,
  totalItem: 0
};

const INITIAL_STATE = {
  uploadCertificate: INITIAL_UPLOAD_CERTIFICATE_STATE,
  uploadedCertificates: INITIAL_UPLOADED_CERTIFICATES_STATE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CERTIFICATE_STATE:
      return { ...state, [action.payload.prop]: action.payload.value };
    case UPDATE_UPLOAD_CERTIFICATE_STATE:
      return { ...state, uploadCertificate: { ...state.uploadCertificate, [action.payload.prop]: action.payload.value } };
    case RESET_UPLOAD_CERTIFICATE_STATE:
      return { ...state, uploadCertificate: INITIAL_UPLOAD_CERTIFICATE_STATE };
    case UPDATE_UPLOADED_CERTIFICATES_STATE:
      return { ...state, uploadedCertificates: { ...state.uploadedCertificates, [action.payload.prop]: action.payload.value } };
    case RESET_UPLOADED_CERTIFICATES_STATE:
      return { ...state, uploadedCertificates: INITIAL_UPLOADED_CERTIFICATES_STATE };
    case LOGOUT_USER_SUCCESS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
