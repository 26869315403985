import React, { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Jitsi from 'react-jitsi';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Lottie from 'lottie-react';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  getMeetConfigBuild6726,
  updateLessonState,
  createEnterMeetLog,
  createExitMeetLog
} from '../../actions';
import AppHelper from '../../helpers/AppHelper';
import AnnotationSocketHelper from '../../helpers/AnnotationSocketHelper';

const teachingAnimation = require('../../img/lottie/teaching.json');

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100vh'
  },
  floatingBtnContainer: {
    position: 'absolute',
    top: 100,
    left: 15,
    width: 'auto'
  },
  lottieContainer: {
    height: '20%',
    width: '20%',
    minWidth: 200,
    minHeight: 200
  },
  coffeeBreakLottieContainer: {
    transform: 'scale(3)',
    marginBottom: -15
  },
  iconButton: {
    height: 45,
    width: 45,
    position: 'relative'
  },
  solidIcon: {
    fontSize: 18,
    fill: '#fff',
    overflow: 'initial'
  },
  translucentIcon: {
    fontSize: 18,
    fill: '#fff',
    overflow: 'initial'
  }
}));

const MeetLessonScreen = (props) => {
  const {
    meetConfig,
    history,
    location
  } = props;

  const meetId = location?.state?.meetId;
  const bookingSlotId = location?.state?.bookingSlotId;

  const {
    displayName,
    avatarUrl,
    annot_server_url,
    jitsi_server_url,
    room_id,
    jitoken,
    startWithMicMuted,
    jitsi_config_obj
  } = meetConfig;

  const classes = useStyles();
  const timer = useRef(null);
  const { t } = useTranslation();
  const [now, setNow] = useState(moment());
  const [isConferenceJoined, setIsConferenceJoined] = useState(false);

  useEffect(() => {
    props.getMeetConfigBuild6726({
      meetId,
      bookingSlotId
    });

    clearInterval(timer.current);
    timer.current = setInterval(() => {
      setNow(new Date());
    }, 1000);

    return () => {
      clearInterval(timer.current);
      props.updateLessonState({ prop: 'meetConfig', value: {} });
    };
  }, []);

  // const toggleParticipantList = () => {
  //   props.updatePracticeRoomConfigState({ prop: 'isParticipantListOpen', value: !isParticipantListOpen });
  // };

  const onJitsiApiLoad = (api) => {
    AnnotationSocketHelper.initialize(annot_server_url, room_id);
    let toggleCount = 0;

    // had to resort to this as student token doesn't work on build 6726
    // doesn't work when passed via the userInfo prop as well
    if (!jitoken) {
      api.executeCommand('avatarUrl', avatarUrl);
    }
    
    api.on('readyToClose', () => {
      api.dispose();
      // props.history.push('/dash');
    });
    
    api.on('tileViewChanged', (obj) => {
      if (obj.enabled === false && toggleCount === 0) {
        api.executeCommand('toggleTileView');
        toggleCount = 1;
      }
    });

    api.on('videoConferenceJoined', (/* event */) => {
      setIsConferenceJoined(true);
      props.createEnterMeetLog({ meetConfig });
    });

    api.on('videoConferenceLeft', (/* event */) => {
      setIsConferenceJoined(false);
      props.createExitMeetLog({ meetConfig });
      props.updateLessonState({ prop: 'meetConfig', value: {} });
      history.replace('/dash');
    });
  };

  const renderLoadingScreen = () => {
    return (
      <Grid
        container
        style={{ minHeight: '100vh', backgroundColor: '#222' }}
        alignItems='center'
        justifyContent='center'
        direction='column'
      >
        <Lottie
          animationData={teachingAnimation}
          loop
          className={classes.lottieContainer}
        />
        <Box mt={2}>
          <Typography color='textSecondary'>{t('tutorRoom.enteringTutorRoom')}</Typography>
        </Box>
      </Grid>
    );
  };
  
  const renderIndicators = () => {
    return (
      <Grid
        className={classes.floatingBtnContainer}
        container
        item
        direction='column'
        alignItems='center'
        justifyContent='center'
      >
        {/* any indicators should go here */}
      </Grid>
    );
  };

  const render = () => {
    if (!meetConfig.room_id) {
      return renderLoadingScreen();
    }

    return (
      <Grid
        container
        component="main"
        className={classes.root}
        alignItems='center'
        justifyContent='center'
      >
        <CssBaseline />
        <Jitsi
          containerStyle={{
            width: '100%',
            minHeight: '100vh'
          }}
          loadingComponent={() => <Skeleton
            variant="rect"
            animation="wave"
            style={{
              width: '100%',
              minHeight: '100vh'
            }}
          />}
          onAPILoad={onJitsiApiLoad}
          domain={AppHelper.getHostNameFromUrl({ url: jitsi_server_url })}
          roomName={room_id}
          jwt={jitoken || undefined}
          interfaceConfig={{
            LANG_DETECTION: false
          }}
          config={{
            startWithAudioMuted: startWithMicMuted === true,
            startWithVideoMuted: false,
            prejoinConfig: {
              enabled: false
            },
            hideConferenceSubject: true,
            hideConferenceTimer: true,
            defaultLanguage: AppHelper.getAppLocaleCodeForApi()?.replace('_', ''),
            ...jitsi_config_obj
          }}
          noSsl={false}
          userInfo={{
            displayName
          }}
          displayName={displayName}
        />
        {renderIndicators()}
      </Grid>
    );
  };

  return render();
};

const mapStateToProps = (state) => {
  const {
    meetConfig
  } = state.lesson;

  return {
    meetConfig
  };
};

export default connect(mapStateToProps, {
  getMeetConfigBuild6726,
  updateLessonState,
  createEnterMeetLog,
  createExitMeetLog
})(MeetLessonScreen);