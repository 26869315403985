import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const _AlertDialog = React.forwardRef((props, ref) => {
  const {
    title,
    desc,
    firstButtonText,
    secondButtonText,
    clickFirstButton,
    clickSecondButton,
  } = props;

  const [open, setOpen] = React.useState(false);

  ref.current = {
    setOpen
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickFirstButtonWrapper = () => {
    handleClose();
    clickFirstButton();
  };

  const handleClickSecondButtonWrapper = () => {
    handleClose();
    clickSecondButton();
  };


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {desc}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickFirstButtonWrapper} color="primary">
            {firstButtonText}
          </Button>
          <Button onClick={handleClickSecondButtonWrapper} color="primary" autoFocus>
            {secondButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export const AlertDialog = _AlertDialog;
