import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import AuthHelper from '../helpers/AuthHelper';

export const UnauthenticatedRoute = ({ component: Component, compProps, ...rest }) => {
  const render = () => {
    const loggedIn = AuthHelper.isLoggedIn();

    return (
      <Route
        {...rest}
        render={routeProps => 
          !loggedIn ? (
            <Component {...routeProps} {...compProps} />
          ) : (
            <Redirect to={'/dash'} />
          )
        }
      />
    );
  };
  
  return render();
};
