import React, { Component } from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import {
    emailChanged,
    passwordChanged,
    validateAndLoginUser,
    validateLoginField,
    hideLoginErrorModal,
    signInWithFB,
    signInWithGoogle,
    signInWithWeChat,
    signInWithApple,
    updateCommonStates
} from '../../actions';
// const config = require('../../config');

const styles = theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(https://files-cdn.youbrio.com/public/images/login-main.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '80%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    ThirdLogin: {
    },
    rightSlogan: {
        padding: '20px 40px 20px 40px',
    },
    rightDesc: {
        padding: '80px 0 20px 20px',
    }
});

class SigninScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.props.history.push('/dash');
    }

    componentWillMount() {
    }

    componentDidMount() {
    }

    render() {
        return this.SignInSide(this.props.classes);
    }
    
    SignInSide(classes) {
        return (
          <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            </Grid>
            <Grid item xs={false} sm={4} md={7} className={classes.image}>
            </Grid>
          </Grid>
        );
    }
}

const mapStateToProps = state => {
  const {
    email,
    password,
    error,
    showLoginErrorModal,
    loginErrorMessage
  } = state.auth;

  const {
    unExecutedDeepLink
  } = state.common;

  return {
    loading: state.dialog.loading,
    email,
    password,
    error,
    showLoginErrorModal,
    loginErrorMessage,
    unExecutedDeepLink
  };
}

export default withStyles(styles)(connect(mapStateToProps, {
    emailChanged,
    passwordChanged,
    validateAndLoginUser,
    validateLoginField,
    hideLoginErrorModal,
    signInWithFB,
    signInWithGoogle,
    signInWithWeChat,
    signInWithApple,
    updateCommonStates
})(SigninScreen));
