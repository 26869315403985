import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import { getFundBalance } from '../../actions';
import AppHelper from '../../helpers/AppHelper';
import { useTranslation } from 'react-i18next';

const FundSummary = (props) => {
  const {
    fundSummary,
    amountToPay,
    location
  } = props;

  const {
    balance,
    isBalanceLoading,
    currencyCode
  } = fundSummary;

  const { t } = useTranslation();

  useEffect(() => {
    props.getFundBalance();
  }, []);

  const isInsufficientBalance = () => {
    return parseFloat(amountToPay) > parseFloat(balance);
  };

  const renderLoadingSpinner = () => {
    if (isBalanceLoading) {
      return (
        <Grid style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', top: 0, left: 0, bottom: 0, right: 0, backgroundColor: '#0001' }}>
          <CircularProgress size={20} />
        </Grid>
      );
    }

    return null;
  };

  const render = () => {
    return (
      <Grid container style={{ position: 'relative' }}>
        <Grid
          container
          item
          alignItems='center'
          xs={12}
        >
          <Grid item container xs={2} alignItems='center' justifyContent='center'>
            <AccountBalanceWalletIcon color='primary' />
          </Grid>
          <Grid item xs={10}>
            <Typography>{t('wallet.youbrioWallet')}</Typography>
            <Typography>
              {`${t('wallet.balance')}: `}
              {`${currencyCode} ${AppHelper.getCurrencySignByCurrencyCode(currencyCode)}${balance}`}
            </Typography>
            {
              isInsufficientBalance()
                ? <Typography variant='caption' color='secondary'>{t('wallet.insufficientBalance')}</Typography>
                : null
            }
          </Grid>
        </Grid>
        <Box mt={1} style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
          {
            isInsufficientBalance()
              ? <Button
                color='primary'
                variant='outlined'
                component={Link}
                size='small'
                to={{
                  pathname: '/wallet/topup',
                  state: {
                    redirect: location,
                    amount: Math.ceil(parseFloat(amountToPay) - parseFloat(balance))
                  }
                }}
              >
                {t('wallet.topUp')}
              </Button>
              : null
          }
        </Box>
        {renderLoadingSpinner()}
      </Grid>
    );
  };

  return render();
};

const mapStateToProps = (state) => {
  const {
    fundSummary
  } = state.fund;

  return {
    fundSummary
  };
};

const _FundSummary = connect(mapStateToProps, {
  getFundBalance
})(FundSummary);

export { _FundSummary as FundSummary };

