import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import _ from 'lodash';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import List from '@material-ui/core/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  getPracticeRoomParticipantsBySubject
} from '../../api';
import { ParticipantItem } from './ParticipantItem';

import GLOBAL from '../../helpers/GLOBAL';
import { Typography } from '@material-ui/core';
import AppHelper from '../../helpers/AppHelper';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(3),
  },
  formControl: {
    marginLeft: 24
  },
  form: {
    display: 'flex',
    alignItems: 'center',
  },
  participantList: {
    maxHeight: 240
  }
}));

const TutoredStudents = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const isTutor = _.get(GLOBAL.get('appSettings'), 'isTRTutor', false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSubject, setSelectedSubject] = useState(-1);
  const [refreshing, setRefreshing] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const handleClickOpen = () => {
    // setOpen(true);
    history.push('/tutored-students');
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSubjectChange = (e) => {
    setSelectedSubject(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (selectedSubject && selectedDate) {
      const date = moment(selectedDate).format('YYYY-MM-DD'); 
      const param = {
        subjectId: selectedSubject,
        date
      };
  
      setRefreshing(true);
      getPracticeRoomParticipantsBySubject(param)
        .then(response => {
          setRefreshing(false);
          if (!response.data || response.data.participants.length < 1) {
            setIsEmpty(true);
          } else {
            setParticipants(response.data.participants);
            setIsEmpty(false);
          }
        })
        .catch(error => {
          setRefreshing(false);
          setIsEmpty(true);
          console.warn(error);
        });
    }
  };

  const renderParticipantList = () => {
    if (refreshing) {
      return <CircularProgress />;
    }

    if (isEmpty) {
      return (
        <Typography
          variant="body2"
          color="textSecondary"
        >
          {t('noResult')}
        </Typography>
      );
    }

    return (
      <List className={classes.participantList}>
        {
          participants.map((p, idx) => (
            <ParticipantItem key={idx} participant={p} />
          ))
        }
      </List>
    );
  };

  if (isTutor || AppHelper.isActualAdmin()) {
    return (
      <div>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClickOpen}
          className={classes.button}
        >
          {t('tutorRoom.tutorRoomStudents')}
        </Button>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{t('tutorRoom.tutorRoomStudents')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('tutorRoom.tutorRoomStudentDescription')}
            </DialogContentText>
  
            <form className={classes.form} onSubmit={onSubmit}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  id="date-picker-inline"
                  label={t('lesson.date')}
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
  
              <FormControl className={classes.formControl}>
                <InputLabel id="subject-select">{t('subject')}</InputLabel>
                <Select
                  labelId="subject-select"
                  id="subject-simple-select"
                  value={selectedSubject}
                  onChange={handleSubjectChange}
                  style={{ width: 120 }}
                >
                  <MenuItem value={-1}>{t('none')}</MenuItem>
                  {
                    props.uniqSubjects.map((option, idx) => (
                      <MenuItem key={idx} value={option.practice_subject_id}>{option.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
  
              <FormControl className={classes.formControl}>
                <Button color="primary" variant="contained" type="submit">{t('find')}</Button>
              </FormControl>
            </form>
  
            { renderParticipantList() }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return null;
};

const mapStateToProps = (state) => {
  const {
    practiceRooms
  } = state.practiceRoom;

  const uniqSubjects = _.uniqBy(practiceRooms, 'practice_subject_id');

  return {
    uniqSubjects,
  };
};

export default connect(mapStateToProps)(TutoredStudents);
