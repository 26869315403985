import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Grid,
  Paper,
  Container,
  Typography,
  Button,
  IconButton,
  colors
} from '@material-ui/core';
import {
  Comment as CommentIcon,
  AddComment as AddCommentIcon,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import {
  MessageBox,
} from 'element-react';
import {
  updateCommonStates,
  getPracticeRoomConfigAndEnter,
  getPracticeRoomList,
  getAvailablePracticeRoomPassListAction
} from '../../actions';
import AppHelper from '../../helpers/AppHelper';
// import YoubrioApiHelper from '../../helpers/YoubrioApiHelper';
import GLOBAL from '../../helpers/GLOBAL';
import UserFilesDialog from '../UserFilesDialog/UserFilesDialog';
import {
  getPracticeRoomProfile,
  redeemPracticeRoomPass
} from '../../api';

const useStyles = makeStyles(() => ({
  paper: { width: '100%', marginTop: 40, marginBottom: 40, padding: 40 },
  listItem: {
    width: '100%',
    border: '1px solid lightgrey',
    borderRadius: 6,
    marginTop: 32,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 24,
    paddingBottom: 24,
  },
  roomIcon: { height: 46, width: 46, marginRight: 24 },
  enterBtn: {
    marginRight: 12,
  },
  avatarContainer: {
    display: 'flex',
    '& > *': {
      marginRight: 12,
      marginTop: 6,
      marginBottom: 6,
    },
  },
  refreshBtn: {
    marginLeft: 12
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  announcementTitle: {
    color: 'orange',
    fontSize: 15,
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const TutorRoomScreen = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const userId = AppHelper.getUserId();
  const isTeacher = AppHelper.isCBETeacher();

  const [isNoticeExpanded, setIsNoticeExpanded] = useState(false);
  const [myFilesDialogVisible, setMyFilesDialogVisible] = useState(false);
  const [profileId, setProfileId] = useState(null);

  const updatePracticeRoomProfileId = async() => {
    try {
      const userPracticeRoomProfile = GLOBAL.get('userPracticeRoomProfile');
      if (userPracticeRoomProfile && userPracticeRoomProfile.id) {
        setProfileId(userPracticeRoomProfile.id);
        return;
      }
      try {
        if (!userId) {
          return;
        }
        const response = await getPracticeRoomProfile({ userId }); // work only for getting my own profileId!
        if (!response || !response.data || !response.data.practiceRoomProfile) {
          return null;
        }
        setProfileId(response.data.practiceRoomProfile.id);
        GLOBAL.set('userPracticeRoomProfile', response.data.practiceRoomProfile);
      } catch (e) {
        console.error(e);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const requestRedeemPracticeRoomPass = async ({ availablePassList, room }) => {
    let passId;
    // let pass = availablePassList.filter(x => x.practice_subject_id === room.practice_subject_id);
    // if (pass.length === 0) {
    //   return;
    // }
    // pass = pass[0];
    // let passId = pass.id;
    const practiceSubjectId = room.practice_subject_id;

    let params = {};
    if (passId) {
      params.passId = passId;
    } else if (practiceSubjectId) {
      params.practiceSubjectId = practiceSubjectId;
    } else {
      return null;
    }

    let res;
    try {
      res = await redeemPracticeRoomPass(params);
      res = res.data;
      if (res && res.result) {
        res = res.result;
      }
    } catch (err) {
      console.error(err);
      return null;
    }

    if (res.success) {
      // onEnterLesson(room);
      props.getPracticeRoomList();
      props.getAvailablePracticeRoomPassListAction();
    } else {
      MessageBox.alert(
        res.message,
        t('tutorRoom.warning')
      );
    }
    return true;
  };

  useEffect(() => {
    props.getPracticeRoomList();
    props.getAvailablePracticeRoomPassListAction();
    document.title = t('youbrio') + ' | ' + t('tutorRoom.tutorRooms');
    updatePracticeRoomProfileId();
  }, []);

  const onNoticeBoardPress = () => {
    setIsNoticeExpanded(!isNoticeExpanded);
  };

  const onEnterLesson = (room) => {
    GLOBAL.logger.info(room);
    // if (!AppHelper.isCBETeacher() && room.active_participants.length < 1) {
    //   props.updateCommonStates({
    //     prop: 'snackbar',
    //     value: {
    //       open: true,
    //       severity: 'warning',
    //       autoHideDuration: 5000,
    //       message: t('tutorRoom.lessonNotStarted')
    //     }
    //   });
    // } else {
    //   props.getPracticeRoomConfigAndEnter({
    //     practiceRoomId: room.practice_room_id,
    //     enterAsRole: AppHelper.isCBETeacher() ? 't' : 's',
    //     isTeacher: room.is_teacher === 1
    //   });
    // }

    if (props.jitsiBuild === '6726') {
      props.history.push({
        pathname: '/tutor-room/lesson',
        state: {
          practiceRoomId: room.practice_room_id
        }
      });
    } else {
      props.getPracticeRoomConfigAndEnter({
        practiceRoomId: room.practice_room_id,
        enterAsRole: AppHelper.isCBETeacher() ? 't' : 's',
        isTeacher: room.is_teacher === 1
      });
    }
  };

  const onClickRereshButton = () => {
    props.getPracticeRoomList();
    props.getAvailablePracticeRoomPassListAction();
  };

  const onClickReviewButton = (room/*, index*/) => {
    props.history.push({
      pathname: '/practice-tutor-reviews',
      state: {
        practiceRoomId: room.practice_room_id,
        userId: AppHelper.getUserId(),
        day: AppHelper.formatShortDateV2(new Date())
      }
    });
  };

  const onClickMyFilesButton = () => {
    setMyFilesDialogVisible(true);
  };

  const onClickActivateDayPass = async ({ room, availablePassList}) => {
    MessageBox.confirm(
      t('tutorRoom.activateMessage', { subject: room.practice_subject_name }),
      t('tutorRoom.warning'),
      {
        type: 'warning',
        cancelButtonText: t('alert.cancel'),
        confirmButtonText: t('alert.confirm')
      }
    ).then(() => {
      requestRedeemPracticeRoomPass({
        room,
        availablePassList
      });
    }).catch(() => {
      console.log('canceled activating passCard');
    });
  };

  const renderParticipantAvatar = (room) => {
    if (room.active_participants.length < 1) {
      return null;
    }

    return (
      <div className={classes.avatarContainer}>
        {
          room.active_participants.map((p, idx) => (
            <Avatar key={idx} alt={AppHelper.getFullname(p.firstName, p.lastName)} src={p.avatarUrl} />
          ))
        }
      </div>
    );
  };

  const renderFeedbackTutorButton = (room, index) => {
    const canLeaveReview = room.practiceTutorReviews && room.practiceTutorReviews.length > 0;

    if (!canLeaveReview) {
      return (
        <></>
      );
    }
    const hasReview = room.practiceTutorReviews.some(x => x.practice_tutor_review_id);

    return (
      <IconButton aria-label="comment" onClick={ () => onClickReviewButton(room, index) } >
        {
          hasReview
            ?
            <CommentIcon style={{ color: colors.green[500] }} fontSize="large" />
            :
            <AddCommentIcon style={{ color: colors.green[500] }} fontSize="large" />
        }
      </IconButton>
    );
  };

  const renderRoomList = () => {
    if (props.practiceRoomsLoading || props.practiceRoomPassLoading) {
      return <CircularProgress />;
    }

    return (
      props.practiceRooms.map((room, index) => {
        const isOpen = room.is_open === 1;
        const isSub = room.is_subscribed === 1;
        const numOfParticipants = room.active_participant_count;

        return (
          <Grid key={index} container direction="row" alignItems="center" className={classes.listItem}>
            {
              room.next_open_at && !room.is_open
                &&
                <Grid item className={classes.openingHour}>
                  <Typography variant="h5">{room.next_opens_at}</Typography>
                </Grid>
            }
              
            <Grid item className={classes.roomIcon}>
              <img src={room.icon_url} alt={room.practiceRoomId} height="46" width="46" />
            </Grid>

            <Grid item container direction="column" xs={12} sm>
              <Typography variant="h5">{room.name}</Typography>
              <Typography style={{ color: isOpen ? colors.green[500] : colors.red[500] }}>{isOpen ? t('tutorRoom.open') : t('tutorRoom.closed')}</Typography>
              {
                room.next_open_at && !isOpen &&
                  <Grid item className={classes.openingHour}>
                    <Typography variant="h5">{room.next_opens_at}</Typography>
                  </Grid>
              }

              {
                numOfParticipants > 0
                  ?
                  <Typography>
                    {numOfParticipants} {t('lesson.participants')}
                  </Typography>
                  :
                  <Typography>
                    {t('tutorRoom.firstOneJoin')}
                  </Typography>
              }

              { renderParticipantAvatar(room) }
            </Grid>
            { renderFeedbackTutorButton(room, index) }
            {
              isSub ?
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={AppHelper.isCBEStudent() && !isOpen}
                  className={classes.enterBtn}
                  onClick={() => onEnterLesson(room)}>
                  {t('lesson.enterLesson')}
                </Button>
                :
                (
                  props.availablePracticeRoomPassList.filter(x => x.practice_subject_id === room.practice_subject_id).length > 0
                    ?
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      disabled={AppHelper.isCBEStudent() && !isOpen}
                      onClick={() => onClickActivateDayPass({ room, availablePassList: props.availablePracticeRoomPassList })}
                    >
                      {t('tutorRoom.activate') + t('tutorRoom.passCard')}
                    </Button>
                    :
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={'/tutor-room/subscribe'}
                      // onClick={() => props.history.push(`/pricing?tutorRoom=${room.name}`)}
                      // onClick={() => AppHelper.openInNewTab(YoubrioApiHelper.getLegacyPortalPath(`tutor-room?lang=${AppHelper.getAppLocaleCodeForApi()}`))}
                    >
                      {t('tutorRoom.subscribe')}
                    </Button>
                )
            }
          </Grid>
        );
      })
    );
  };

  const renderMyFilesDialog = () => {
    const user = GLOBAL.get('user');

    if (!user) {
      return null;
    }
    if (!myFilesDialogVisible) {
      return null;
    }

    return (
      <div>
        <UserFilesDialog
          visible={myFilesDialogVisible}
          setVisible={setMyFilesDialogVisible}
          userId={userId}
          profileId={profileId}
          isMyFiles={true}
        />
      </div>
    );
  };

  return (
    <Container maxWidth="md">
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
        <Paper className={classes.paper}>
          {/* Had to add component={'div'} as Typography prop to avoid "<div> cannot appear as a descendant of <p>" error */}
          <Typography component={'div'} className={classes.title}>
            {t('tutorRoom.tutorRooms')}
            <Button
              disabled={props.practiceRoomsLoading}
              className={classes.refreshBtn}
              color="primary"
              onClick={onClickRereshButton}
            >
              {t('refresh')}
            </Button>
            {
              isTeacher
                ?
                null
                :
                <Button
                  disabled={props.practiceRoomsLoading}
                  className={classes.refreshBtn}
                  color="primary"
                  onClick={onClickMyFilesButton}
                >
                  {t('myFiles')}
                </Button>
            }
            { props.practiceRoomAnnouncement && <div
              className={'px-2 py-2'}
              onClick={onNoticeBoardPress}
              style={{ whiteSpace: 'pre-line', borderWidth: 1, border: '1px solid lightblue', borderRadius: '5px', cursor: 'pointer' }}
            >
              <Typography component={'div'} className={isNoticeExpanded ? 'expanded-note' : 'collapsed-note'} style={{ color: 'grey', position: 'relative' }}>
                <Typography component={'div'} className={classes.announcementTitle}>
                  <NotificationsActiveIcon  style={{ fill: 'orange', fontSize: 14, marginRight: '5px' }} />  
                  {t('tutorRoom.announcement')}
                </Typography>
                {props.practiceRoomAnnouncement}
                <div style={{ position: 'absolute', bottom: -5, right: 0 }} >
                  {
                    !isNoticeExpanded
                      ? <KeyboardArrowDownIcon style={{ fill: '#000', fontSize: 18 }} />
                      : <KeyboardArrowUpIcon style={{ fill: '#000', fontSize: 18 }} />
                  }
                </div>
              </Typography>
            </div> }
          </Typography>
          { renderRoomList() }
          { renderMyFilesDialog() }
        </Paper>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const {
    practiceRooms,
    practiceRoomsLoading,
    practiceRoomAnnouncement,
    jitsiBuild
  } = state.practiceRoom;

  const {
    isLoading: practiceRoomPassLoading,
    availablePracticeRoomPassList
  } = state.practiceRoomPass;

  return {
    practiceRooms,
    practiceRoomsLoading,
    practiceRoomAnnouncement,
    practiceRoomPassLoading,
    availablePracticeRoomPassList,
    jitsiBuild
  };
};

export default connect(mapStateToProps, {
  getPracticeRoomList,
  getAvailablePracticeRoomPassListAction,
  updateCommonStates,
  getPracticeRoomConfigAndEnter,
})(TutorRoomScreen);
