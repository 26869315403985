import {
  UPDATE_COMMON_STATES,
  UPDATE_SNACKBAR_STATES
} from './types';
import { appStartsLoading, appDoneLoading } from './';
import {
  getCountries,
  getFaq,
  getJitsiPreloadUrl,
} from '../api';

export const getCountryList = () => {
  return (dispatch) => {
    getCountries()
      .then(response => {
        const countryList = response.data.countries;
        dispatch(updateCommonStates({ prop: 'countries', value: countryList }));
      })
      .catch((error) => {
        console.warn(error);
      });
  };
};

export const updateCommonStates = ({ prop, value }) => {
  return {
    type: UPDATE_COMMON_STATES,
    payload: { prop, value }
  };
};

export const updateSnackbarStates = ({ prop, value }) => {
  return {
    type: UPDATE_SNACKBAR_STATES,
    payload: { prop, value }
  };
};

export const getFrequentlyAskedQuestions = () => {
  return (dispatch) => {
    dispatch(appStartsLoading());
    getFaq()
      .then((response) => {
        dispatch(appDoneLoading());
        dispatch(updateCommonStates({ prop: 'faqs', value: response.faq }));
        // console.log(response);
      })
      .catch((error) => {
        dispatch(appDoneLoading());
        console.log(error);
      });
  };
};

export const getJitsiPreloadServerUrl = ({ callback }) => {
  return () => {
    getJitsiPreloadUrl()
      .then((response) => {
        // console.log(response);

        callback({ response: response.data });
      })
      .catch((error) => {
        // console.log(error);
        callback({ error });
      });
  };
};
