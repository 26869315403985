import ApiHelper from '../helpers/ApiHelper';
import YoubrioApiHelper from '../helpers/YoubrioApiHelper';
import AppHelper from '../helpers/AppHelper';

export const getTeachLocations = () => {
  const lang = AppHelper.getAppLocaleCodeForApi();
  return YoubrioApiHelper.get(`institutiontypes?lang=${lang}`, false);
};

export const checkEmailExistence = ({ email }) => {
  const params = {
    email
  };
  return YoubrioApiHelper.post('email/check', params, false);
};

/**
 * @deprecated Since version 1.1. Will be deleted in future version.
 * Use registerBasicAccount instead.
 */
export const registerTeacherAccount = ({
  email,
  password,
  firstName,
  lastName,
  gender,
  dob,
  address1,
  address2,
  suburb,
  postcode,
  countryRegion,
  countryState,
  landline,
  mobile,
  institutionType,
  teachAddress,
  qualification,
  subscribed,
  avatarUrl
}) => {
  const params = new FormData();
  params.append('email', email);
  params.append('password', password);
  params.append('firstName', firstName);
  params.append('lastName', lastName);

  if (avatarUrl) {
    const fileName = AppHelper.getFileNameWithExtension(avatarUrl);
    const fileExt = AppHelper.getFileTypeExtension(avatarUrl);
    params.append('avatar', { uri: avatarUrl, name: fileName, type: `image/${fileExt.toLowerCase()}` });
  }

  params.append('gender', gender);
  params.append('address1', address1);
  params.append('address2', address2);
  params.append('suburb', suburb);
  params.append('postcode', postcode);
  params.append('countryId', countryRegion);
  params.append('state', countryState);
  params.append('landline', landline);
  params.append('mobile', mobile);
  params.append('dob', dob);
  params.append('teachLocation', institutionType);

  if (teachAddress) {
    params.append('teachAddress', teachAddress);
  }

  params.append('qualification', qualification);
  params.append('subscribe', subscribed);
  return ApiHelper.post('teacher', params, false, true);
};

/**
 * @deprecated Since version 1.1. Will be deleted in future version.
 * Use registerBasicAccount instead.
 */
export const registerStudentAccount = ({
  email,
  password,
  firstName,
  lastName,
  gender,
  dob,
  address1,
  address2,
  suburb,
  postcode,
  countryRegion,
  countryState,
  landline,
  mobile,
  subscribed,
  avatarUrl,
  // teacherId,
  guardianName
}) => {
  const params = new FormData();
  params.append('email', email);
  params.append('password', password);
  params.append('firstName', firstName);
  params.append('lastName', lastName);

  if (avatarUrl) {
    const fileName = AppHelper.getFileNameWithExtension(avatarUrl);
    const fileExt = AppHelper.getFileTypeExtension(avatarUrl);
    params.append('avatar', { uri: avatarUrl, name: fileName, type: `image/${fileExt.toLowerCase()}` });
  }

  if (guardianName) {
    params.append('guardianName', guardianName);
  }

  // if (teacherId) {
  //   params.append('teacherId', teacherId);
  // }

  params.append('gender', gender);
  params.append('address1', address1);
  params.append('address2', address2);
  params.append('suburb', suburb);
  params.append('postcode', postcode);
  params.append('countryId', countryRegion);
  params.append('state', countryState);
  params.append('landline', landline);
  params.append('mobile', mobile);
  params.append('dob', dob);
  params.append('subscribe', subscribed);

  return ApiHelper.post('student', params, false, true);
};

export const registerAccountWithFBAndEmail = ({ fbToken, email, osPlayerId, getuiId }) => {
  const params = {
    _t: fbToken,
    email,
    ospid: osPlayerId,
    getuiId
  };
  return YoubrioApiHelper.post('facebook/email/submit', params, false);
};

// not in use anymore together with RegistrationActions/tryRegisterAccountWithWXAndEmail
// and components/SocialMediaEmailScreenWX
export const registerAccountWithWXAndEmail = ({ wxToken, defaultEmail, email }) => {
  const params = {
    _t: wxToken,
    defaultEmail,
    email,
  };
  return ApiHelper.post('wechat/email/submit', params, false);
};

export const linkFBAccountWithCredentials = ({ fbToken, email, password, osPlayerId, getuiId }) => {
  const params = {
    _t: fbToken,
    email,
    _p: password,
    ospid: osPlayerId,
    getuiId
  };
  return YoubrioApiHelper.post('facebook/existing/authenticate', params, false);
};

export const registerBasicAccount = ({
  email,
  password,
  firstName,
  lastName,
  mobile
}) => {
  // const params = new FormData();
  // params.append('email', email);
  // params.append('password', password);
  // params.append('firstName', firstName);
  // params.append('lastName', lastName);
  // params.append('mobile', mobile);
  const params = {
    email,
    password,
    firstName,
    lastName,
    mobile
  };

  return YoubrioApiHelper.post('user', params, false, false);
};

export const registerAsCompleteStudentProfile = ({
  email,
  password,
  firstName,
  lastName,
  mobile,
  dob,
  countryId
}) => {
  const params = {
    email,
    password,
    firstName,
    lastName,
    mobile,
    dob,
    countryId
  };

  return YoubrioApiHelper.post('user/student', params, false, false);
};
