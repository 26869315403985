import AppHelper from '../helpers/AppHelper';
import YoubrioApiHelper from '../helpers/YoubrioApiHelper';

export const getPracticeRoomConfigurations = ({ practiceRoomId, enterAsRole, mode }) => {
  return YoubrioApiHelper.get(`practice-room/configs?prid=${practiceRoomId}&ro=${enterAsRole}&mode=${mode}`, true);
};

export const getPracticeRoomConfigurationsBuild6726 = ({ practiceRoomId, enterAsRole, mode }) => {
  return YoubrioApiHelper.get(`practice-room/configs-b6726?prid=${practiceRoomId}&ro=${enterAsRole}&mode=${mode}`, true);
};

export const updatePracticeRoom = ({ practiceRoomId, is_available }) => {
  return YoubrioApiHelper.post('practice-room/update',
    {
      prid: practiceRoomId,
      is_available
    },
    true
  );
};


export const getPracticeRoomParticipants = ({ practiceRoomId }) => {
  return YoubrioApiHelper.get(`practice-room-participants?prid=${practiceRoomId}`, true);
};

export const getPracticeRoomParticipantsBySubject = (param) => {
  return YoubrioApiHelper.post('practice-room-participants/subject', param, true);
};

export const getTutorRoomList = () => {
  if (AppHelper.isCBETeacher()) {
    return YoubrioApiHelper.get('teacher/practice-rooms', true);
  }
  return YoubrioApiHelper.get('participant/practice-rooms', true);
};

export const getSubscriptionPlansList = (subjectId) => {
  return YoubrioApiHelper.get(`subscription-plans?subjectId=${subjectId}`, true);
};

export const sendSignUpRequest = (content) => {
  return YoubrioApiHelper.post(`practice-room/sign-up?content=${content}`, {}, true);
};

export const getControlRoomPracticeParticipantList = () => {
  return YoubrioApiHelper.get('control-room/practice-room-participants', true);
};

export const savePracticeParticipantNote = ({
  practiceParticipantDetailId,
  userId,
  remarks,
  practiceSubjectId,
  remarksEditedAt,
  practiceRoomId
}) => {
  const params = {
    userId,
    remarks,
    practiceSubjectId,
    practiceRoomId,
    remarksEditedAt
  };

  return YoubrioApiHelper.post(`practice-participant-detail/${practiceParticipantDetailId}`, params, true);
};

export const savePracticeParticipantLockStatus = ({
  practiceParticipantDetailId,
  practiceSubjectId,
  userId,
  lock,
  lastLockedBy,
  practiceRoomId
}) => {
  const params = {
    practiceSubjectId,
    practiceRoomId,
    userId,
    lock: lock === true ? 1 : 0,
    lastLockedBy
  };

  return YoubrioApiHelper.post(`practice-participant-detail/${practiceParticipantDetailId}`, params, true);
};

export const getPracticeRoomSubscriptionPlans = ({ isPromo }) => {
  return YoubrioApiHelper.get(`subscription-plans/structured${isPromo ? '?promo=true' : ''}`, true);
};

export const enterPracticeModeMeetLog = ({
  meetId,
  practiceRoomId,
  jitsiServerUrl,
  annotServerUrl,
  configParamString,
  mode,
  deviceInfo,
  logUserId
}) => {
  const params = {
    meetId,
    practiceRoomId,
    meetLogTypeId: 3,
    jitsiServerUrl,
    annotServerUrl,
    configParamString,
    mode,
    deviceInfo,
    logUserId
  };
  return YoubrioApiHelper.post('meet-log', params, true);
};

export const enterLessonModeMeetLog = ({
  meetId,
  practiceRoomId,
  jitsiServerUrl,
  annotServerUrl,
  configParamString,
  mode,
  deviceInfo,
  logUserId
}) => {
  const params = {
    meetId,
    practiceRoomId,
    meetLogTypeId: 4,
    jitsiServerUrl,
    annotServerUrl,
    configParamString,
    mode,
    deviceInfo,
    logUserId
  };
  return YoubrioApiHelper.post('meet-log', params, true);
};

export const enterCoffeeBreak = ({
  meetId,
  practiceRoomId,
  jitsiServerUrl,
  annotServerUrl,
  configParamString,
  mode,
  deviceInfo
}) => {
  const params = {
    meetId,
    practiceRoomId,
    meetLogTypeId: 5,
    jitsiServerUrl,
    annotServerUrl,
    configParamString,
    mode,
    deviceInfo
  };
  return YoubrioApiHelper.post('meet-log', params, true);
};

export const exitCoffeeBreak = ({
  meetId,
  practiceRoomId,
  jitsiServerUrl,
  annotServerUrl,
  configParamString,
  mode,
  deviceInfo
}) => {
  const params = {
    meetId,
    practiceRoomId,
    meetLogTypeId: 6,
    jitsiServerUrl,
    annotServerUrl,
    configParamString,
    mode,
    deviceInfo
  };
  return YoubrioApiHelper.post('meet-log', params, true);
};