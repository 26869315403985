import io from 'socket.io-client';
import AppHelper from './AppHelper';
import ApiHelper from './ApiHelper';
import { getStore } from '../Store';

const socketConnectionOption = {
  'reconnection': true,
  'reconnectionDelay': 1000,
  'reconnectionDelayMax': 5000,
  'reconnectionAttempts': 5
};

class ChatHelper {
  static getAdminChatRoom() {
    const chatRoomList = getStore().getState().chat.chatRoom.list;
    const adminRoom = chatRoomList.find((room) => room.has_admin === true);
    return adminRoom;
  }

  static sendNewUserAutoMessage() {
    const adminRoom = ChatHelper.getAdminChatRoom();

    if (adminRoom) {
      const chatServerUrl = AppHelper.getChatServerUrl() || 'http://webrtc1.youbrio.com:3002';
      const socket = io.connect(chatServerUrl, socketConnectionOption);
      const roomId = adminRoom.room_id;
      const token = ApiHelper.getAuthToken();

      socket.on('connect', () => {
        socket.emit('join-room', roomId);
      });

      socket.on('Start_Chat', () => {
        const message = {
          room_id: roomId,
          type: 'NEW_USER',
          lang: AppHelper.getAppLocaleCodeForApi()
        };

        socket.emit('Register_Name', AppHelper.getUserId());
        socket.emit('auto_msg', { data: message, token });
        socket.disconnect();
      });

      // socket.on('msg3', (data) => {
      //   console.log(data, ' sent, ready to disconnect');
      //   socket.disconnect();
      // });
    }
  }

  static sendNewTeacherAutoMessage() {
    const adminRoom = ChatHelper.getAdminChatRoom();

    if (adminRoom) {
      const chatServerUrl = AppHelper.getChatServerUrl() || 'http://webrtc1.youbrio.com:3002';
      const socket = io.connect(chatServerUrl, socketConnectionOption);
      const roomId = adminRoom.room_id;
      const token = ApiHelper.getAuthToken();

      socket.on('connect', () => {
        socket.emit('join-room', roomId);
      });

      socket.on('Start_Chat', () => {
        const message = {
          room_id: roomId,
          type: 'NEW_TEACHER',
          lang: AppHelper.getAppLocaleCodeForApi()
        };

        socket.emit('Register_Name', AppHelper.getUserId());
        socket.emit('auto_msg', { data: message, token });
        socket.disconnect();
      });

      // socket.on('msg3', (data) => {
      //   console.log(data, ' sent, ready to disconnect');
      //   socket.disconnect();
      // });
    }
  }

  static sendNewStudentAutoMessage() {
    const adminRoom = ChatHelper.getAdminChatRoom();

    if (adminRoom) {
      const chatServerUrl = AppHelper.getChatServerUrl() || 'http://webrtc1.youbrio.com:3002';
      const socket = io.connect(chatServerUrl, socketConnectionOption);
      const roomId = adminRoom.room_id;
      const token = ApiHelper.getAuthToken();

      socket.on('connect', () => {
        socket.emit('join-room', roomId);
      });

      socket.on('Start_Chat', () => {
        const message = {
          room_id: roomId,
          type: 'NEW_STUDENT',
          lang: AppHelper.getAppLocaleCodeForApi()
        };

        socket.emit('Register_Name', AppHelper.getUserId());
        socket.emit('auto_msg', { data: message, token });
        socket.disconnect();
      });

      // socket.on('msg3', (data) => {
      //   console.log(data, ' sent, ready to disconnect');
      //   socket.disconnect();
      // });
    }
  }
}

export default ChatHelper;
