// @flow

import { FieldTextAreaStateless } from '@atlaskit/field-text-area';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getLocalizedDateFormatter } from '../../../i18n/dateUtil';
import IconLock from '@material-ui/icons/Lock';
import IconUnlock from '@material-ui/icons/LockOpen';
import IconEdit from '@material-ui/icons/Edit';
import IconClose from '@material-ui/icons/Close';
import IconSend from '@material-ui/icons/Send';
import IconMore from '@material-ui/icons/MoreVert';
import IconVolumeUp from '@material-ui/icons/VolumeUp';
import IconVolumeOff from '@material-ui/icons/VolumeOff';
import IconTimer from '@material-ui/icons/Timer';
import IconWarning from '@material-ui/icons/Warning';
import {
  IconCrown,
  IconDumbbell,
  IconTeach
} from '../../Icons';
import AppHelper from '../../../helpers/AppHelper';
import '../../ControlRoomScreen/ControlRoom.css';

const { COLOR } = require('../../../helpers/ConstantHelper');

const WARNING_DURATION_SECOND = 10 * 60;
const OVERTIME_DURATION_SECOND = 15 * 60;

const styles = {
  participantItemOuterContainer: {
    flexDirection: 'column',
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 6,
    boxShadow: '2px 5px 15px 5px rgba(204,204,204,0.25)',
    display: 'flex',
    padding: 10,
    boxSizing: 'border-box',
    minHeight: 100,
    justifyContent: 'center'
  },
  blurredParticipantItemOuterContainer: {
    flexDirection: 'column',
    backgroundColor: 'rgba(255,255,255,1)',
    width: '100%',
    borderRadius: 6,
    boxShadow: '2px 5px 15px 5px rgba(204,204,204,0.25)',
    display: 'flex',
    padding: 10,
    boxSizing: 'border-box',
    minHeight: 100,
    justifyContent: 'center',
    opacity: 0.4
  },
  participantContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  timerContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minWidth: '50%',
    position: 'relative'
  },
  name: {
    color: '#000',
    fontSize: 16,
    marginBottom: 5
  },
  timer: {
    color: '#000',
    fontSize: 14,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5
  },
  singleBadgeContainerStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    padding: 5,
    marginRight: 5,
    marginBottom: 1,
    whiteSpace: 'nowrap',
    display: 'flex'
  },
  badgeTextStyle: {
    fontSize: 12
  },
  badgesContainerStyle: {
    flexDirection: 'row',
    marginBottom: 4,
    alignItems: 'center',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
    display: 'flex'
  },
  participantItemRowContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex'
  },
  lessonModeTimerTextStyle: {
    fontSize: 14,
    minWidth: 80,
    color: '#000'
  },
  iconContainerStyle: {
    marginRight: 3
  },
  lastTutorNameTextStyle: {
    color: '#555'
  },
  participantPracticeModeTimerTextStyle: {
    fontSize: 14
  },
  participantPracticeModeTimerIconContainerStyle: {
    marginRight: 5
  },
  rowContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  modeTimerContainStyle: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
    justifyContent: 'center'
  },
  controlContainerStyle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  tutorTextStyle: {
    fontSize: 14,
    color: '#000'
  },
  speakerEnabledButtonStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    width: 30,
    borderRadius: 20,
    border: '1px solid #999999',
    marginRight: 5,
    display: 'flex'
  },
  speakerFillerButtonStyle: {
    height: 30,
    width: 30,
    display: 'flex'
  },
  noteLastEditedInfoTextStyle: {
    marginTop: 5,
    marginRight: 5,
    display: 'flex',
    justifyContent: 'flex-end',
    color: '#777',
    fontSize: 13
  },
  cancelNoteEditButtonStyle: {
    alignSelf: 'flex-end',
    marginTop: 5,
    marginRight: 5,
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    width: 30,
    borderRadius: 15,
    backgroundColor: COLOR.FAILED,
    display: 'flex'
  },
  editNoteButtonStyle: {
    alignSelf: 'flex-end',
    marginTop: 5,
    marginRight: 0,
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    width: 30,
    borderRadius: 15,
    backgroundColor: COLOR.COBALT_BLUE,
    display: 'flex'
  },
  editNoteButtonContainerStyle: {
    display: 'flex',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row'
  },
  rescueContainerStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  rescueTextContainerStyle: {
    padding: 10,
    backgroundColor: '#f00',
    borderRadius: 10
  },
  rescueTextStyle: {
    color: '#fff',
    fontSize: 20,
    textAlign: 'center'
  }
};

/**
 * Renders each participant list item in the TutorRoom component
 */
const ParticipantListItem = (props) => {
  const {
    onMorePress,
    onPracticeModeStartPress,
    onPracticeModeStopPress,
    onSetAudioConnectedPress,
    onSetAudioTrackEnabled,
    onSaveParticipantNotePress,
    onSkipPress,
    onUpdatePracticeParticipantLock,
    isLoadingParticipant,
    shouldShowSkipStudentButton,
    shouldEnableTutorModeButton,
    participant,
    jitsiParticipant,
    audioConnectedParticipants,
    shouldRescue,
    now
  } = props;

  const [isNoteLoading, setIsNoteLoading] = useState(false);
  const [isNoteChanged, setIsNoteChanged] = useState(false);
  const [isEditingNote, setIsEditingNote] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);
  const [editedNoteText, setEditedNoteText] = useState('');

  const { t } = useTranslation();

  /**
   * Sets a participant to practice mode.
   *
   * @returns {void}
   */
  const startPracticeMode = () => {
    const {
      practice_room_participant_id: practiceRoomParticipantId,
      user_id: userId
    } = participant;

    onPracticeModeStartPress({
      practiceRoomParticipantId,
      userId
    });
  };

  /**
   * Sets a participant to lesson mode.
   *
   * @returns {void}
   */
  const stopPracticeMode = () => {
    const {
      practice_room_participant_id: practiceRoomParticipantId,
      user_id: userId
    } = participant;

    onPracticeModeStopPress({
      practiceRoomParticipantId,
      userId
    });
  };

  /**
   * Disconnects the audio participant with the participant.
   *
   * @returns {void}
   */
  const onDisconnectPress = () => {
    const {
      practice_room_participant_id: practiceRoomParticipantId
    } = participant;

    onSetAudioConnectedPress({
      practiceRoomParticipantId,
      shouldConnect: false
    });
  };

  /**
   * Connects to the participant via audio.
   *
   * @returns {void}
   */
  const onConnectPress = () => {
    const {
      practice_room_participant_id: practiceRoomParticipantId
    } = participant;

    onSetAudioConnectedPress({
      practiceRoomParticipantId,
      shouldConnect: true
    });
  };

  /**
   * Skip the student.
   *
   * @returns {void}
   */
  const _onSkipPress = () => {
    onSkipPress();
  };

  /**
   * Get the status of the audio track of the participant.
   *
   * @returns {boolean}
   */
  const getSpeakerEnabled = () => {
    const speakerMuted = jitsiParticipant?.volume === 0
      ?? true;

    return !speakerMuted;
  };

  /**
   * Returns whether user is connected on audio to the participant.
   *
   * @returns {boolean}
   */
  const isAudioConnected = () => {
    const {
      practice_room_participant_id: practiceRoomParticipantId
    } = participant;

    return audioConnectedParticipants[`prp_${practiceRoomParticipantId}`]
      ?? false;
  };

  /**
   * Toggles the audio track of the participant.
   *
   * @returns {void}
   */
  const _onToggleSpeakerEnabled = () => {
    const speakerEnabled = getSpeakerEnabled();

    onSetAudioTrackEnabled({
      enabled: !speakerEnabled,
      identityId: parseInt(participant.user_id, 10)
    });
  };

  /**
   * Toggles the expanded state of the handover note.
   *
   * @returns {void}
   */
  const _onToggleExpandedHandoverNote = () => {
    // if to be expanded and remarks is empty, turn to edit mode automatically
    if (!isExpanded === true && !participant?.notes?.remarks) {
      _onEditHandoverNotePress();
    } else {
      setIsExpanded(!isExpanded);
    }
  };

  /**
   * On edit hand over note press.
   *
   * @param {event} event - Click event.
   * @returns {void}
   */
  const _onEditHandoverNotePress = (event) => {
    if (event) {
      event.stopPropagation();
    }

    setIsEditingNote(true);
    setEditedNoteText(participant?.notes?.remarks ?? '');
  };

  /**
   * When the participant without jitsi track statuses is pressed.
   * Which potentially also means the participant had already left
   * the practice room.
   *
   * @returns {void}
   */
  const _onSpeakerTogglePressForEmptyTrack = () => {
    AppHelper.showSnackbarNotification({
      message: 'The participant might have left.'
    });
  };

  /**
   * Handle for hand over note text change.
   *
   * @param {event} event - Event object of the text change event.
   * @returns {void}
   */
  const _onChangeNoteText = (event) => {
    setEditedNoteText(event.target.value);
    setIsNoteChanged(true);
  };

  /**
   * Cancel handover note editing.
   *
   * @param {event} event - Click event.
   * @returns {void}
   */
  const _onCancelEditNotePress = (event) => {
    if (event) {
      event.stopPropagation();
    }

    setIsEditingNote(false);
    setIsNoteChanged(false);
    setEditedNoteText('');
  };

  /**
   * Saves edited handover note.
   *
   * @param {event} event - Click event.
   * @returns {void}
   */
  const _onSaveNotePress = (event) => {
    if (event) {
      event.stopPropagation();
    }

    setIsNoteLoading(true);
    onSaveParticipantNotePress({
      practiceParticipantDetailId: participant.notes.practice_participant_detail_id,
      userId: participant.user_id,
      remarks: editedNoteText,
      practiceSubjectId: participant.notes.practice_subject_id,
      remarksEditedAt: participant.notes.remarks_edited_at,
      callback: _saveNoteCallback
    });

    // this.setState({
    //   isNoteLoading: true
    // }, () => {
    //   onSaveParticipantNotePress({
    //     practiceParticipantDetailId: participant.notes.practice_participant_detail_id,
    //     userId: participant.user_id,
    //     remarks: editedNoteText,
    //     practiceSubjectId: participant.notes.practice_subject_id,
    //     remarksEditedAt: participant.notes.remarks_edited_at,
    //     callback: _saveNoteCallback
    //   });
    // });
  };

  /**
   * Toggles the lock status of the participant.
   *
   * @returns {void}
   */
  const _onLockParticipantPress = () => {
    onUpdatePracticeParticipantLock({
      practiceParticipantDetailId: participant.notes.practice_participant_detail_id,
      practiceSubjectId: participant.notes.practice_subject_id,
      userId: participant.user_id,
      lock: !isParticipantLocked(),
      lastLockedBy: participant?.notes?.locked_by, /* eslint-disable-line camelcase */
      callback: _toggleLockCallback
    });
  };

  /**
   * On participant menu press.
   *
   * @param {boolean} visible - Whether or not the menu should be
   * displayed.
   * @returns {void}
   */
  const _onMenuPress = (event) => {
    onMorePress({ anchorEl: event.currentTarget, participant });
  };

  /**
   * Callback after saving edited handover note.
   *
   * @param {Object} [param.response] - Response from the API.
   * @param {Object} [param.error] - API error code.
   * @returns {void}
   */
  const _saveNoteCallback = ({ response, error }) => {
    // console.log('_saveNoteCallback', response, error, error.code);
    setIsNoteLoading(false);

    if (response) {
      setIsNoteChanged(false);
      setIsEditingNote(false);
      setIsExpanded(true);
      setSuccessVisible(true);
    } else if (error) {
      if (error === 303) {
        // Clipboard.setString(editedNoteText);
        AppHelper.showSnackbarNotification({
          message: t('tutorRoom.someoneHasEditedTheNote'),
          severity: 'error'
        });
        setIsEditingNote(false);
      } else {
        AppHelper.showSnackbarNotification({
          message: t('info.genericError'),
          severity: 'error'
        });
      }
    }
  };

  /**
   * Callback after toggling participant lock status.
   *
   * @param {Object} [param.response] - Response from the API.
   * @param {Object} [param.error] - API error code.
   * @returns {void}
   */
  const _toggleLockCallback = ({ /* response, */ error }) => {
    if (error) {
      if (error === 303) {
        AppHelper.showSnackbarNotification({
          message: t('tutorRoom.someoneHasChangedTheLockStatus'),
          severity: 'error'
        });
      } else {
        AppHelper.showSnackbarNotification({
          message: t('info.genericError'),
          severity: 'error'
        });
      }
    }
  };

  /**
   * Returns whether participant is locked.
   *
   * @param {Object} participant - Participant object from the list.
   * @returns {boolean}
   */
  const isParticipantLocked = () => {
    /* eslint-disable-next-line camelcase */
    if (participant?.notes?.locked_by) {
      return true;
    }

    return false;
  };

  /**
   * Renders the speaker button of the participant.
   *
   * @returns {ReactElement | null}
   */
  const renderToggleSpeakerButton = () => {
    const { speakerEnabledButtonStyle } = styles;
    const speakerEnabled = getSpeakerEnabled();

    if (jitsiParticipant) {
      return (
        <span
          className = { 'button' }
          onClick = { _onToggleSpeakerEnabled }
          style = { speakerEnabledButtonStyle } >
          {
            speakerEnabled
              ? <IconVolumeUp
                style={{ fill: '#000', fontSize: 18 }} />
              : <IconVolumeOff
                style={{ fill: '#000', fontSize: 18 }} />
          }
        </span>
      );
    }

    return (
      <span
        className = { 'button' }
        onClick = { _onSpeakerTogglePressForEmptyTrack }
        style = { speakerEnabledButtonStyle } >
        <IconWarning
          style={{ fill: COLOR.WARNING, fontSize: 18 }} />
      </span>
    );
  };

  /**
   * Renders the toggle lock button of the participant.
   *
   * @returns {ReactElement | null}
   */
  const renderToggleLockButton = () => {
    const {
      practice_room_role_id: practiceRoomRoleId
    } = participant;

    const isLocked = isParticipantLocked();

    if (practiceRoomRoleId === 1) {
      return (
        <button
          className = { isLocked ? 'button lock-button' : 'button unlock-button' }
          disabled = { isLoadingParticipant }
          onClick = { _onLockParticipantPress } >
          {
            isLocked
              ? <IconUnlock
                style={{ fill: '#fff', fontSize: 18 }} />
              : <IconLock
                style={{ fill: '#000', fontSize: 18 }} />
          }
        </button>
      );
    }

    return null;
  };

  /**
   * Renders the badges of the participants.
   *
   * @returns {ReactElement}
   */
  const renderBadges = () => {
    const {
      badges
    } = props.participant;

    const {
      singleBadgeContainerStyle,
      badgeTextStyle,
      badgesContainerStyle
    } = styles;

    const renderIndividualBadge = (badge, index) => {
      const {
        type,
        textStyle,
        containerStyle,

        // icon,
        text
      } = badge;

      if (type === 'icon') {
        return null;
      } else if (type === 'text') {
        return (
          <span
            key = { index }
            style = {{ ...singleBadgeContainerStyle,
              ...containerStyle }} >
            <span
              style = {{ badgeTextStyle,
                ...textStyle }}>{text}</span>
          </span>
        );
      }

      return null;
    };

    if (badges && badges.length > 0) {
      return (
        <div style = { badgesContainerStyle }>
          {badges.map((badge, index) => renderIndividualBadge(badge, index))}
        </div>
      );
    }

    return null;
  };

  /**
   * Renders the accumulated lesson mode timer of the participant.
   *
   * @returns {ReactElement}
   */
  const renderAccumulatedLessonTimer = () => {
    const {
      lessonModeCounter
    } = participant;

    if (lessonModeCounter) {
      const {
        totalSeconds,
        totalInstances,
        lessonModeAt
      } = lessonModeCounter;

      const {
        participantItemRowContainerStyle,
        lessonModeTimerTextStyle,
        iconContainerStyle
      } = styles;

      const currentLessonModeDuration = AppHelper.getSecondsToDate({
        date: now,
        now: lessonModeAt
      });
      const totalDuration = totalSeconds + currentLessonModeDuration;

      const parsedDuration = AppHelper.formatSecondsToNow(totalDuration);
      const {
        hours,
        minutes,
        seconds
      } = parsedDuration;

      return (
        <div style = { participantItemRowContainerStyle }>
          <span style = { iconContainerStyle }>
            <IconTeach
              viewBox='0 0 30 30'
              style={{ fill: '#000', fontSize: 18 }} />
          </span>
          <span style = { lessonModeTimerTextStyle }>
            { hours.toString().padStart(2, 0) }
            :{ minutes.toString().padStart(2, 0) }
            :{ seconds.toString().padStart(2, 0) } { `[x${totalInstances}]` }
          </span>
        </div>
      );
    }

    return null;
  };

  /**
   * Renders the total in-room timer of the participant.
   *
   * @returns {ReactElement}
   */
  const renderInRoomTimer = () => {
    if (participant.in_room_total_time) {
      const {
        totalTime, // accumulated time previously
        lastEnterTime
      } = participant.in_room_total_time;

      const {
        participantItemRowContainerStyle,
        iconContainerStyle,
        lessonModeTimerTextStyle
      } = styles;

      if (lastEnterTime) {
        let secondsToNow = moment(moment()).diff(new Date(lastEnterTime), 'seconds');

        secondsToNow += totalTime;
        const parsedDuration = AppHelper.formatSecondsToNow(secondsToNow);
        const {
          hours,
          minutes,
          seconds
        } = parsedDuration;

        return (
          <div style = { participantItemRowContainerStyle }>
            <span style = { iconContainerStyle }>
              <IconTimer
                style={{ fill: '#000', fontSize: 18 }} />
            </span>
            <span style = { lessonModeTimerTextStyle }>
              {hours.toString().padStart(2, 0)}
              :{minutes.toString().padStart(2, 0)}
              :{seconds.toString().padStart(2, 0)}
            </span>
          </div>
        );
      }
    }

    return null;
  };

  /**
   * Renders the last tutor name of the participant.
   *
   * @returns {ReactElement}
   */
  const renderLastTutorName = () => {
    const {
      lastTutorNameTextStyle
    } = styles;

    const {
      last_tutor_display_name: lastTutorName
    } = participant;

    if (lastTutorName) {
      return (
        <div style = { lastTutorNameTextStyle }>{`Tutored by: ${lastTutorName}`}</div>
      );
    }

    return null;
  };

  /**
   * Renders current lesson/practice mode timer.
   *
   * @returns {ReactElement}
   */
  const renderPracticeModeTimer = () => {
    const {
      participantPracticeModeTimerTextStyle,
      participantPracticeModeTimerIconContainerStyle,
      modeTimerContainStyle
    } = styles;

    const {
      // practice_room_participant_id: practiceRoomParticipantId,
      practice_room_role_id: practiceRoomRoleId,
      practice_mode_at: practiceModeAt, /* Timestamp to keep track of the mode */
      lesson_mode_at: lessonModeAt
    } = participant;

    if ((practiceModeAt || lessonModeAt) && practiceRoomRoleId !== 2) {
      const secondsToNow = AppHelper.getSecondsToDate({
        date: now,
        now: practiceModeAt || lessonModeAt
      });
      const parsedDuration = AppHelper.formatSecondsToNow(secondsToNow);

      let {
        minutes,
        seconds
      } = parsedDuration;

      let className = '';
      let fontColor = '#777';

      if (secondsToNow >= OVERTIME_DURATION_SECOND) {
        fontColor = COLOR.FAILED;
        className = 'vibrate';
      } else if (secondsToNow >= WARNING_DURATION_SECOND) {
        fontColor = COLOR.ORANGE;
        className = 'vibrate';
      }

      if (secondsToNow >= 60 * 60) { // if over an hour
        minutes = 59;
        seconds = 59;
      }

      return (
        <div
          className = { className }
          style = { modeTimerContainStyle } >
          <span style = { participantPracticeModeTimerIconContainerStyle }>
            {
              practiceModeAt
                ? <IconDumbbell
                  viewBox='0 0 30 30'
                  style = {{ fontSize: 18, fill: fontColor, overflow: 'initial' }} />
                : <IconTeach
                  viewBox='0 0 30 30'
                  style = {{ fontSize: 18, fill: fontColor, overflow: 'initial' }} />
            }
          </span>
          {
            secondsToNow >= 60 * 60 /* timer doesn't show correct time when it's over 60 minutes */
              ? <span
                style = {{ ...participantPracticeModeTimerTextStyle,
                  color: fontColor }}>
                {'60:00+'}
              </span>
              : <span
                style = {{ ...participantPracticeModeTimerTextStyle,
                  color: fontColor }}>
                {`${minutes.toString().padStart(2, 0)}:${seconds.toString().padStart(2, 0)}`}
              </span>
          }
        </div>
      );
    }

    return null;
  };

  /**
   * Renders audio connect/disconnect button.
   *
   * @returns {ReactElement}
   */
  const renderAudioConnectButton = () => {
    const {
      lesson_mode_tutor_user_id: tutorUserId,
      lesson_mode_at: lessonModeAt
    } = participant;

    const isConnected = isAudioConnected();
    const onPressAudioConnected = isConnected
      ? onDisconnectPress
      : onConnectPress;

    // disable button if someone else is teaching the participant
    const disabled = lessonModeAt && tutorUserId === AppHelper.getUserId();

    return (
      <button
        className = { isConnected ? 'button disconnect-button' : 'button connect-button' }
        disabled = { disabled }
        onClick = { onPressAudioConnected }>
        { isConnected ? 'Disconnect' : 'Connect' }
      </button>
    );
  };

  /**
   * Renders skip button.
   *
   * @returns {ReactElement}
   */
  const renderSkipButton = () => {
    const {
      lesson_mode_tutor_user_id: tutorUserId,
      lesson_mode_at: lessonModeAt
    } = participant;

    const disabled = isLoadingParticipant || (lessonModeAt && tutorUserId === AppHelper.getUserId());

    return (
      <button
        className = { 'button skip-button' }
        disabled = { disabled }
        onClick = { _onSkipPress }>
        { 'Skip' }
      </button>
    );
  };


  /**
   * Renders participant controls.
   *
   * @returns {ReactElement}
   */
  const renderParticipantControls = () => {
    const {
      user_id: userId,
      practice_room_role_id: practiceRoomRoleId,
      practice_mode_at: practiceMode,
      lesson_mode_tutor_user_id: tutorUserId
    } = participant;

    const {
      controlContainerStyle
    } = styles;

    // only show the control if the participant is not the actual user
    if (AppHelper.getUserId() !== userId) {
      if (practiceRoomRoleId === 1) {
        // check which mode user is on practice or lesson mode
        const pModeOnPress = practiceMode
          ? stopPracticeMode
          : startPracticeMode;

        // disable button if someone else is teaching the participant
        const shouldShowPracticeOrTeachButton = true;

        const disabled = isLoadingParticipant
          || (tutorUserId !== null && tutorUserId !== AppHelper.getUserId())
          || (practiceMode && !shouldEnableTutorModeButton);

        return (
          <div style = { controlContainerStyle }>
            {
              shouldShowPracticeOrTeachButton
                ? <button
                  className = { practiceMode ? 'button lesson-button' : 'button practice-button' }
                  disabled = { disabled }
                  onClick = { pModeOnPress }>
                  { practiceMode ? 'Teach' : 'Practice' }
                </button>
                : null
            }
            { shouldShowSkipStudentButton ? renderSkipButton() : null }
            { renderAudioConnectButton() }
            { renderPracticeModeTimer() }
          </div>
        );
      } else if (practiceRoomRoleId === 2) {
        return (
          <div style = { controlContainerStyle }>
            { renderAudioConnectButton() }
          </div>
        );
      }
    }

    return null;
  };

  /**
   * Render handover notes.
   *
   * @returns {ReactElement}
   */
  const renderHandoverNotes = () => {
    const {
      notes
    } = participant;

    if (notes) {
      const {
        noteLastEditedInfoTextStyle,
        cancelNoteEditButtonStyle,
        editNoteButtonStyle,
        editNoteButtonContainerStyle
      } = styles;

      const {
        remarks,
        editorDisplayName,
        remarks_edited_at: remarksEditedAt,
        edited_by_user_id: editedByUserId,
        maxLength
      } = notes;

      if (isEditingNote) {
        const shouldShowSendButton = isNoteChanged && editedNoteText;

        return (
          <div>
            <FieldTextAreaStateless
              autoFocus = { true }
              className = 'input-control'
              enableResize = { 'vertical' }
              id = 'handoverNoteTextArea'
              isLabelHidden = { true }
              maxLength = { maxLength }
              minimumRows = { 5 }
              onChange = { _onChangeNoteText }
              shouldFitContainer = { true }
              value = { editedNoteText } />
            <div style = { editNoteButtonContainerStyle }>
              <span
                className = { 'button' }
                onClick = { _onCancelEditNotePress }
                style = {{ ...cancelNoteEditButtonStyle,
                  marginRight: shouldShowSendButton ? 5 : 0 }} >
                <IconClose
                  style={{ fill: '#fff', fontSize: 18 }} />
              </span>
              {
                shouldShowSendButton
                  ? <span
                    className = { 'button' }
                    onClick = { _onSaveNotePress }
                    style = { editNoteButtonStyle } >
                    <IconSend
                      style={{ fill: '#fff', fontSize: 18 }} />
                  </span>
                  : null
              }
            </div>
          </div>
        );
      }

      return (
        <div>
          <div
            className = { isExpanded ? 'expanded-note' : 'collapsed-note' }
            style = {{
              whiteSpace: 'pre-line',
              color: isExpanded ? '#FF0000' : '#FF000099',
              fontStyle: remarks ? 'normal' : 'italic'
            }} >
            {remarks || 'No notes.'}
          </div>
          {
            isExpanded && editedByUserId && remarksEditedAt
              ? <div
                style = { noteLastEditedInfoTextStyle } >
                {`>> ${AppHelper.getUserId() === editedByUserId
                  ? 'Me' : editorDisplayName},
                  ${getLocalizedDateFormatter(moment(remarksEditedAt)).format('DD MMM  hh:mma')}`}
              </div>
              : null
          }
          {
            isExpanded
              ? <div style = { editNoteButtonContainerStyle }>
                <span
                  className = { 'button' }
                  onClick = { _onEditHandoverNotePress }
                  style = { editNoteButtonStyle } >
                  <IconEdit
                    style={{ fill: '#fff', fontSize: 18 }} />
                </span>
              </div>
              : null
          }
        </div>
      );
    }

    return <div />;
  };

  /**
   * Render menu button.
   *
   * @returns {ReactElement}
   */
  const renderMenuButton = () => {
    if (participant.user_id !== AppHelper.getUserId()) {
      return (
        <div>
          <div className={'button'} aria-controls="simple-menu" aria-haspopup="true" onClick={_onMenuPress}>
            <IconMore
              style={{ fill: '#000', fontSize: 18 }} />
          </div>
        </div>
      );
    }

    return null;
  };

  /**
   * Render rescue indicator for student that needs to be rescued.
   *
   * @returns {ReactElement}
   */
  const renderRescueIndicator = () => {
    const {
      rescueContainerStyle,
      rescueTextContainerStyle,
      rescueTextStyle
    } = styles;

    if (shouldRescue) {
      return (
        <div
          className = { 'blink' }
          style = { rescueContainerStyle }>
          <div style = { rescueTextContainerStyle }>
            <span style = { rescueTextStyle }>{ t('tutorRoom.rescueSignal.rescue').toUpperCase() }</span>
          </div>
        </div>
      );
    }

    return null;
  };

  /**
   * Implements React's {@link Component#render()}.
   *
   * @inheritdoc
   * @returns {ReactElement}
   */
  const render = () => {
    const {
      rowContainerStyle,
      participantItemOuterContainer,
      blurredParticipantItemOuterContainer,
      participantContainer,
      speakerFillerButtonStyle,
      tutorTextStyle
    } = styles;

    const {
      lesson_mode_tutor_user_id: tutorUserId,
      tutor_first_name: tutorFirstName,
      tutor_last_name: tutorLastName
    } = participant;

    const me = participant.user_id === AppHelper.getUserId();

    return (
      <div
        key = { participant.user_id }
        style = { jitsiParticipant ? participantItemOuterContainer : blurredParticipantItemOuterContainer }>
        <div
          style = { participantContainer }>
          {
            me
              ? <div style = { speakerFillerButtonStyle } />
              : <div>
                { renderToggleSpeakerButton() }
                { renderToggleLockButton() }
              </div>
          }

          {/* <Avatar
            appearance = 'circle'
            name = 'large'
            size = 'large'
            src = { participant.avatar_url } /> */}

          <div style = { styles.timerContainer }>
            <span style = { styles.name }>
              <strong>
                {AppHelper.formatName(participant.first_name,
                  participant.last_name?.charAt(0))} {me ? '(Me)' : ''}
              </strong>
              {
                tutorUserId
                  ? <span
                    style = {{ ...rowContainerStyle,
                      marginLeft: 5 }}>
                    <IconCrown
                      style={{ fill: '#ffa500', fontSize: 18 }} />
                    <span style = { tutorTextStyle }>
                      {AppHelper.getInitial({
                        firstName: tutorFirstName,
                        lastName: tutorLastName,
                        charLimit: 3 })}
                    </span>
                  </span>
                  : null
              }
            </span>
            { renderBadges() }
            { renderAccumulatedLessonTimer() }
            { renderInRoomTimer() }
            { renderLastTutorName() }
            { renderRescueIndicator() }
          </div>

          { renderParticipantControls() }
          { renderMenuButton() }
        </div>
        { participant.notes && <div className = { 'dashed' } /> }
        <div
          id = { 'handover-note' }
          onClick = { _onToggleExpandedHandoverNote }>
          { renderHandoverNotes() }
        </div>
      </div>
    );
  };

  return render();
};

export default ParticipantListItem;
