import io from 'socket.io-client';

class ChatSocketHelper {
  static serverUrl = 'http://webrtc1.youbrio.com:3002';
  static socket = null;
  static isDisconnectedByUser = false;
  static callbacks = null;
  static channels = [
    'connect',
    'disconnect',
    'reconnecting',
    'Start_Chat',
    'r_name',
    'msg',
    'msg3', // this is emitted from other chat server, similar to 'msg'
    'msg-new',
    'msg-old',
    'alert-msg',
    'read'
  ];
  static pendingEmits = [];

  static getSocket() {
    return this.socket;
  }

  static initialize({ serverUrl }) {
    console.log('initialize', serverUrl);
    this.disconnect();
    this.serverUrl = serverUrl;
    this.isDisconnectedByUser = false;
    this.socket = io.connect(this.serverUrl);
    this.firePendingEmits();
  }

  static setCallback({ channel, callback }) {
    this.socket.on(channel, callback);
  }

  static setCallbacks({ callbacks }) {
    this.callbacks = callbacks;
    // on chat socket process
    // connect -> join-room
    // status:    Start_Chat
    this.channels.forEach((channel) => {
      console.log('channel', channel);
      this.socket.on(channel, callbacks[channel.replace('-', '_')]);
    });
  }

  static removeCallback({ channel }) {
    this.socket.removeAllListeners(channel);
  }

  static removeCallbacks() {
    this.channels.forEach((channel) => {
      this.socket.removeAllListeners(channel.replace('-', '_'));
    });
  }

  static emit(channel, data) {
    console.log('helper emit');
    if (this.socket) {
      this.socket.emit(channel, data);
      console.log('successful emit', channel, data);
    } else {
      this.pendingEmits.push({ channel, data });
    }
  }

  static firePendingEmits() {
    console.log('firePendingEmits');
    if (this.socket) {
      console.log('firePendingEmits', this.pendingEmits);
      this.pendingEmits.forEach((item) => {
        this.socket.emit(item.channel, item.data);
      });
      this.pendingEmits = [];
    }
  }

  static disconnect() {
    if (this.socket) {
      this.isDisconnectedByUser = true;
      this.removeCallbacks();
      this.socket.disconnect();
      this.socket = null;
    }
  }

  static attemptReconnect() {
    if (!this.isDisconnectedByUser) {
      this.socket = io.connect(this.serverUrl, {
        'reconnection': true,
        'reconnectionDelay': 5000,
        'reconnectionDelayMax': 10000,
        'reconnectionAttempts': 12
      });
      this.firePendingEmits();
      console.log(this.callbacks);
      this.setCallbacks({ callbacks: this.callbacks });
    }
  }
}

export default ChatSocketHelper;
