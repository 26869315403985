import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ReduxThunk from 'redux-thunk';
import reducers from './reducers';

let store = null;
let persistor = null;

export const configureStore = () => {
  const enhancers = compose(
    applyMiddleware(
      ReduxThunk
    )
  );

  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['common']
  };

  const persistedReducer = persistReducer(persistConfig, reducers);

  store = createStore(
    persistedReducer,
    enhancers
  );

  persistor = persistStore(store);

  return { store, persistor };
};

export const getStore = () => {
  return store;
};

export const getPersistor = () => {
  return persistor;
};
