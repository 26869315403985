import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import { green } from '@material-ui/core/colors';
import SvgIcon from '@material-ui/core/SvgIcon';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > svg': {
//       margin: theme.spacing(2),
//     },
//   },
// }));

export default function CloseIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z"></path>
    </SvgIcon>
  );
}

// export default function SvgIconsColor() {
//   const classes = useStyles();
// 
//   return (
//     <div className={classes.root}>
//       <HomeIcon />
//       <HomeIcon color="primary" />
//       <HomeIcon color="secondary" />
//       <HomeIcon color="action" />
//       <HomeIcon color="disabled" />
//       <HomeIcon style={{ color: green[500] }} />
//     </div>
//   );
// }

