import {
  APP_LOADING_STARTED,
  APP_LOADING_FINISHED,
  OPEN_DIALOG,
  CLOSE_DIALOG
} from './types';

// moved from CommonActions to avoid being loading state persisted.
// as it is somehow stuck at loading when the state is persisted.
// dispatch this to show LoadingModal
export const appStartsLoading = () => {
  return {
    type: APP_LOADING_STARTED
  };
};

// moved from CommonActions to avoid being loading state persisted.
// as it is somehow stuck at loading when the state is persisted.
// dispatch this to hide LoadingModal
export const appDoneLoading = () => {
  return {
    type: APP_LOADING_FINISHED
  };
};

export const openDialog = ({ dialog, props }) => {
  return {
    type: OPEN_DIALOG,
    payload: { dialog, props }
  };
};

export const closeDialog = () => {
  return {
    type: CLOSE_DIALOG
  };
};