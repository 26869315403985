import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Paper,
  Container,
  Typography,
  CircularProgress,
  Backdrop
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Autorenew';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  getControlRoomAllRoomParticipants
} from '../../actions';
import GroupedRoomList from './GroupedRoomList';

const useStyles = makeStyles((theme) => ({
  paper: { width: '100%', marginTop: 40, marginBottom: 40, padding: 20 },
  table: { minWidth: 650 },
  subjectSelect: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  listItem: {
    width: '100%',
    border: '1px solid lightgrey',
    borderRadius: 6,
    marginTop: 32,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 24,
    paddingBottom: 24,
  },
  refreshBtn: {
    marginLeft: 12,
  },
  gridListRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  }
}));

const ControlRoomScreen = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    isAllRoomLoading,
    activeTeachers,
    activeStudents
  } = props;

  useEffect(() => {
    document.title = t('youbrio') + ' | Control Room';
    // fetchAllRoomParticipantList();
    props.getControlRoomAllRoomParticipants();
    const interval = setInterval(() => { props.getControlRoomAllRoomParticipants(); }, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container maxWidth={false}>
      <Grid container direction='row' justify='center' alignItems='center' spacing={3}>
        <Paper className={classes.paper}>
          <Typography variant='h6'>
            {t('tutorRoom.controlRoom')}
            &nbsp;[T: {activeTeachers} | S: {activeStudents} | {t('tutorRoom.total')}: {activeTeachers + activeStudents}]
            <RefreshIcon
              style={{ marginLeft: '10px' }}
              className={isAllRoomLoading ? 'button spinning' : 'button'}
              onClick={props.getControlRoomAllRoomParticipants}
            />
            
            {/* <Button
              disabled={props.practiceRoomsLoading}
              className={classes.refreshBtn}
              color='primary'
              onClick={props.getControlRoomAllRoomParticipants}
            >
              {t('refresh')}
            </Button> */}
          </Typography>
          <>
            <GroupedRoomList />
          </>
        </Paper>
      </Grid>
      <Backdrop className={classes.backdrop} open={isAllRoomLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </Container>
  );
};

const mapStateToProps = (state) => {
  const {
    isAllRoomLoading,
    activeTeachers,
    activeStudents
  } = state.practiceRoom.controlRoom;

  return {
    isAllRoomLoading,
    activeTeachers,
    activeStudents
  };
};

export default connect(mapStateToProps, {
  getControlRoomAllRoomParticipants
})(ControlRoomScreen);
