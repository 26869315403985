import {
  UPDATE_PRACTICE_ROOM_STATE,
  RESET_PRACTICE_ROOM_STATE,
  UPDATE_CONTROL_ROOM_STATE,
  UPDATE_PRACTICE_ROOM_CONFIG_STATE,
  RESET_PRACTICE_ROOM_CONFIG_STATE,
  UPDATE_SUBSCRIPTION_PLANS_STATE
} from '../actions/types';

const INITIAL_CONTROL_ROOM_STATE = {
  isAllRoomLoading: false,
  practiceRooms: [],
  participants: {}
};

const INITIAL_SUBSCRIPTION_PLANS_STATE = {
  plans: {}
};

const INITIAL_PRACTICE_ROOM_CONFIG_STATE = {
  room_id: '',
  meet_id: '',
  practice_room_id: '',
  jitsi_server_url: '',
  jitsi_config_param_string: '',
  jitoken: '',
  start_date_time: null,
  end_date_time: null,
  title: '',
  annot_server_url: '',
  color_palette: [],
  maxSmartboardTab: 9,
  redirectScene: null,
  participants: [],
  isLoadingParticipant: false,
  isModerator: false,
  practiceModeTimestamps: {},
  audioConnectedParticipants: {},
  jitsiParticipants: {},
  learnMode: 2, // 1 = Tutor Mode, 2 = Practice Mode
  practiceRoomRoleId: null, // 1 = Student, 2 = Teacher
  JitsiMeetAPI: undefined,
  rescueSignal: {
    student: null,
    fromUser: null
  }
};

const INITIAL_STATE = {
  practiceRoomsLoading: false,
  practiceRooms: [],
  practiceRoomConfig: INITIAL_PRACTICE_ROOM_CONFIG_STATE,
  controlRoom: INITIAL_CONTROL_ROOM_STATE,
  practiceRoomAnnouncement: '',
  subscriptionPlans: INITIAL_SUBSCRIPTION_PLANS_STATE,
  jitsiBuild: null
};
  
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UPDATE_PRACTICE_ROOM_STATE:
    return { ...state, [action.payload.prop]: action.payload.value };
  case UPDATE_CONTROL_ROOM_STATE:
    return { ...state, controlRoom: { ...state.controlRoom, [action.payload.prop]: action.payload.value } };
  case UPDATE_SUBSCRIPTION_PLANS_STATE:
    return { ...state, subscriptionPlans: { ...state.subscriptionPlans, [action.payload.prop]: action.payload.value } };
  case UPDATE_PRACTICE_ROOM_CONFIG_STATE:
    return { ...state, practiceRoomConfig: { ...state.practiceRoomConfig, [action.payload.prop]: action.payload.value } };
  case RESET_PRACTICE_ROOM_CONFIG_STATE:
    return { ...state, practiceRoomConfig: INITIAL_PRACTICE_ROOM_CONFIG_STATE };
  case RESET_PRACTICE_ROOM_STATE: {
    return INITIAL_STATE;
  }
  default:
    return state;
  }
};
  