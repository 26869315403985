import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, InputBase, colors, IconButton, InputAdornment, CircularProgress, Paper } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { createFilter } from 'react-search-input';

import RoomListItem from './RoomListItem';
import AppHelper from '../../helpers/AppHelper';
import {
  getChatRoom as getChatRoomList,
  selectChatRoom,
} from '../../actions';
import ChatRightPanel from './ChatRightPanel';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
  // left panel styles
  chatLeftPanel: {
    width: 320,
    backgroundColor: '#fff',
    margin: 12,
  },
  chatLeftHeader: {
    height: 120,
    paddingLeft: 20,
    paddingRight: 20,
  },
  chatRoomList: {
    // 64 is appBar and 120 is chatLeftHeader
    height: 'calc(100vh - 64px - 170px)',
    overflow: 'auto',
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  leftTitle: {
    marginTop: 20,
    fontSize: 24,
    fontWeight: 'bold'
  },
  searchInput: {
    borderRadius: 6,
    padding: '10px 20px',
    backgroundColor: colors.grey[200],
    width: '100%',
    color: colors.grey[900],
    marginTop: 20,
  },
  roomSectionTitle: {
    marginTop: 10,
    fontSize: 14,
    fontWeight: 'bold',
  },
  // right panel styles
  chatRightPanel: {
    minWidth: 500,
    height: 'calc(100vh - 64px - 24px)',
    marginTop: 12,
    marginRight: 12,
    flex: 1,
    background: '#fff',
    paddingBottom: 64,
  },
}));

const KEYS_TO_FILTERS = ['room_name'];

const ChatScreen = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    selectedChat,
    // functions
    chatRoomList,
    // chatRoomListLoading,
    getChatRoomList,
    selectChatRoom,
  } = props;

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    document.title = t('youbrio') + " | " + t('chat.chat');
    getChatRoomList();
  }, [getChatRoomList]);

  const renderChatRoomList = () => {
    if (!chatRoomList) { // || chatRoomListLoading
      return <CircularProgress />;
    }    
    const filteredChatRoomList = chatRoomList.filter(createFilter(searchTerm, KEYS_TO_FILTERS));
    if (filteredChatRoomList.length < 1) {
      return <Typography color="textSecondary">No matched results</Typography>;
    }

    return filteredChatRoomList.map((item, index) => {
      return (<RoomListItem
        key={index}
        onClick={() => {
          selectChatRoom(item);
          getChatRoomList();
        }}
        selected={item.id === selectedChat.id}
        title={item.room_name}
        subtitle={AppHelper.msgDateTime({ date: item.updated_at })}
        avatar={item.users[0].avatarUrl}
        unread={item.unread_msg_count}
      />);
    });
  };

  return (
    <Grid container className={classes.root}>
      <Paper className={classes.chatLeftPanel}>
        <div className={classes.chatLeftHeader}>
          <Typography className={classes.leftTitle}>{t('chat.chat')}</Typography>

          <InputBase
            className={classes.searchInput}
            value={searchTerm}
            placeholder={t('search')}
            onChange={(e) => setSearchTerm(e.target.value)}
            inputProps={{ 'aria-label': 'naked' }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  disabled
                  aria-label="toggle search visibility"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        
        <div className={classes.chatRoomList}>
          {/* <Typography className={classes.roomSectionTitle} color="textSecondary">ADMIN</Typography> */}
          {/* <RoomListItem selected title='Youbrio Admin' subtitle='Admin' unread={1} /> */}

          <Typography className={classes.roomSectionTitle} color="textSecondary">{t('chat.lastChats')}</Typography>
          { renderChatRoomList() }
        </div>
      </Paper>

      <Paper className={classes.chatRightPanel}>
        <ChatRightPanel key={selectedChat.id} />
      </Paper>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  const {
    chatRoom,
    selectedChat,
  } = state.chat;

  return {
    chatRoomList: chatRoom.list,
    chatRoomListLoading: chatRoom.isLoading,
    // showNewChatAlert: chatRoom.showNewChatAlert,
    selectedChat,
  };
};

export default connect(mapStateToProps, {
  getChatRoomList,
  selectChatRoom,
})(ChatScreen);