import React, { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Jitsi from 'react-jitsi';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles, styled } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Lottie from 'lottie-react';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  getPracticeRoomConfigBuild6726,
  updatePracticeRoomConfigState,
  createEnterPracticeRoomMeetLog,
  createExitPracticeRoomMeetLog,
  resetPracticeRoomConfigState,
  openDialog
} from '../../actions';
import { usePrevious } from '../../hooks';
import ParticipantListDrawer from './ParticipantListDrawer/ParticipantListDrawer';
import AppHelper from '../../helpers/AppHelper';
import TutorRoomIndicators from './indicators/TutorRoomIndicators';
import LearnModeIndicator from './indicators/LearnModeIndicator';
import AutoKickIndicator from './indicators/AutoKickIndicator';
import { LearnModeInfoDialog } from '../dialogs/tutorRoom/LearnModeInfoDialog';
import AnnotationSocketHelper from '../../helpers/AnnotationSocketHelper';

const DRAWER_WIDTH = 350;
const OVERTIME_ALERT_SNOOZE = 2 * 60 * 1000; // 2 minutes snooze for overtime tutor alert
const UNATTENDED_STUDENT_ALERT_SNOOZE = 1 * 60 * 1000; // 1 minute snooze for unattended student alert
const teachingAnimation = require('../../img/lottie/teaching.json');

const dingDongSound = require('../../sounds/ding_dong.wav');

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100vh'
  },
  floatingBtnContainer: {
    position: 'absolute',
    top: 100,
    left: 15,
    width: 'auto'
  },
  lottieContainer: {
    height: '20%',
    width: '20%',
    minWidth: 200,
    minHeight: 200
  },
  coffeeBreakLottieContainer: {
    transform: 'scale(3)',
    marginBottom: -15
  },
  iconButton: {
    height: 45,
    width: 45,
    position: 'relative'
  },
  solidIcon: {
    fontSize: 18,
    fill: '#fff',
    overflow: 'initial'
  },
  translucentIcon: {
    fontSize: 18,
    fill: '#fff',
    overflow: 'initial'
  }
}));

const JitsiContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    position: 'relative',
    backgroundColor: '#000',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${DRAWER_WIDTH}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const TutorRoomLessonScreen = (props) => {
  const {
    practiceRoomConfig,
    students,
    history,
    location
  } = props;

  const prevStudents = usePrevious(students);

  const practiceRoomId = location?.state?.practiceRoomId;

  const {
    practiceRoomRoleId,
    displayName,
    avatarUrl,
    annot_server_url,
    jitsi_server_url,
    room_id,
    jitoken,
    startWithSpeakerMuted,
    startWithMicMuted,
    jitsi_config_obj,
    isParticipantListOpen = false
  } = practiceRoomConfig;

  const classes = useStyles();
  const timer = useRef(null);
  const overtimeAlertSnoozeTimeout = useRef(null);
  const unattendedStudentAlertSnoozeTimeout = useRef(null);
  const { t } = useTranslation();
  const [now, setNow] = useState(moment());
  const [isConferenceJoined, setIsConferenceJoined] = useState(false);
  const [isOvertimeAlertBarDismissed, setIsOvertimeAlertBarDismissed] = useState(false);
  const [isUnattendedStudentAlertDismissed, setIsUnattendedStudentAlertDismissed] = useState(false);

  useEffect(() => {
    if (isConferenceJoined && hasNewStudent(prevStudents, students) && AppHelper.isCBETeacher()) {
      playDingDong();
    }
  }, [students]);

  useEffect(() => {
    props.getPracticeRoomConfigBuild6726({
      practiceRoomId,
      enterAsRole: AppHelper.isCBETeacher() ? 't' : 's'
    });

    clearInterval(timer.current);
    timer.current = setInterval(() => {
      setNow(new Date());
    }, 1000);

    return () => {
      clearInterval(timer.current);
      props.resetPracticeRoomConfigState();
    };
  }, []);

  const toggleParticipantList = () => {
    props.updatePracticeRoomConfigState({ prop: 'isParticipantListOpen', value: !isParticipantListOpen });
  };

  const shouldShowTutorOvertimeAlert = () => {
    const {
      participants
    } = practiceRoomConfig;

    if (!isOvertimeAlertBarDismissed && participants && participants.length > 0) {
      const students = participants.filter(p => p.practice_room_role_id === 1);

      if (students.length > 0) {
        const studentBeingTaughtByUser = students.find(p =>
          (p.lesson_mode_tutor_user_id === AppHelper.getUserId())
          && p.lesson_mode_at
        );

        if (!studentBeingTaughtByUser) {
          return false;
        }

        const _now = moment(now);
        const currentTutorModeDuration = _now.diff(moment(studentBeingTaughtByUser.lesson_mode_at), 'seconds');

        if (students.length > 3) { // 3 students and above, and tutor mode over 3 minutes
          if (currentTutorModeDuration >= (3 * 60)) {
            return true;
          }
        } else if (students.length >= 2) { // 2 students, and tutor mode over 5 minutes
          if (currentTutorModeDuration >= (5 * 60)) {
            return true;
          }
        } else if (students.length === 1) { // 1 student, no need to alert
          return false;
        }
      }
    }

    return false;
  };

  const getUnattendedStudentStates = () => {
    const {
      warningDurationMinute,
      participants
    } = practiceRoomConfig;

    let shouldShowAlert = false;
    let studentToBeAttended = null;

    if (
      practiceRoomConfig.practiceRoomRoleId === 2
      && !isUnattendedStudentAlertDismissed
      && participants
      && participants.length > 0
    ) {
      const burningStudents = participants.filter(p => {
        if (!p.practice_mode_at) {
          return false;
        }

        const secondsToNow = AppHelper.getSecondsToDate({ date: now, now: p.practice_mode_at });
        p.practiceSeconds = secondsToNow;

        return (
          p.practice_room_role_id === 1
          && secondsToNow >= warningDurationMinute * 60
        );
      });

      if (burningStudents.length > 0) {
        shouldShowAlert = true;

        burningStudents.forEach(p => {
          if (!studentToBeAttended) {
            studentToBeAttended = p;
          } else if (studentToBeAttended.practiceSeconds < p.practiceSeconds) {
            studentToBeAttended = p;
          }
        });
      }
    }

    return {
      shouldShowAlert,
      studentToBeAttended
    };
  };

  const onJitsiApiLoad = (api) => {
    props.updatePracticeRoomConfigState({ prop: 'JitsiMeetAPI', value: api });
    AnnotationSocketHelper.initialize(annot_server_url, room_id);
    let toggleCount = 0;

    // had to resort to this as student token doesn't work on build 6726
    // doesn't work when passed via the userInfo prop as well
    if (AppHelper.isCBEStudent()) {
      // api.executeCommand('avatarUrl', avatarUrl);
    }
    
    api.on('readyToClose', () => {
      api.dispose();
      // props.history.push('/dash');
    });
    
    api.on('tileViewChanged', (obj) => {
      if (obj.enabled === false && toggleCount === 0) {
        api.executeCommand('toggleTileView');
        toggleCount = 1;
      }
    });

    // eslint-disable-next-line no-unused-vars
    api.on('videoConferenceJoined', (event) => {
      // only show if user is student
      if (practiceRoomRoleId === 1) {
        props.openDialog({
          dialog: LearnModeInfoDialog,
          props: {
            disableEscapeKeyDown: true,
            disableBackdropClick: true
          }
        });
      }

      setIsConferenceJoined(true);
      props.createEnterPracticeRoomMeetLog(practiceRoomConfig);
    });

    // eslint-disable-next-line no-unused-vars
    api.on('videoConferenceLeft', (event) => {
      // console.log('videoConferenceLeft', event);
      setIsConferenceJoined(false);
      props.createExitPracticeRoomMeetLog(practiceRoomConfig);
      props.resetPracticeRoomConfigState();
      history.replace('/tutor-room');
    });
    
    api.on('participantJoined', (p) => {
      if (startWithSpeakerMuted) {
        api.executeCommand('setParticipantVolume', p.id, 0);
      }
    });

    api.on('participantListUpdated', (participantsObject) => {
      props.updatePracticeRoomConfigState({ prop: 'jitsiParticipants', value: participantsObject?.participants ?? {} });
    });
  };

  const onTutorOvertimeAlertClose = (event, reason) => {
    // ignore if it's just clickaway
    if (reason === 'clickaway' || reason === 'escapeKeyDown') {
      return;
    }

    setIsOvertimeAlertBarDismissed(true);

    clearTimeout(overtimeAlertSnoozeTimeout.current);
    overtimeAlertSnoozeTimeout.current = setTimeout(() => {
      setIsOvertimeAlertBarDismissed(false);
    }, OVERTIME_ALERT_SNOOZE);
  };

  const onUnattendedStudentAlertClose = (event, reason) => {
    // ignore if it's just clickaway
    if (reason === 'clickaway' || reason === 'escapeKeyDown') {
      return;
    }

    setIsUnattendedStudentAlertDismissed(true);
    clearTimeout(unattendedStudentAlertSnoozeTimeout.current);

    unattendedStudentAlertSnoozeTimeout.current = setTimeout(() => {
      setIsUnattendedStudentAlertDismissed(false);
    }, UNATTENDED_STUDENT_ALERT_SNOOZE);
  };

  const hasNewStudent = (prevStudentList, currentStudentList) => {
    let hasNewStudent = false;

    if (currentStudentList?.length > 0 && !_.isEqual(prevStudentList, currentStudentList)) {
      for (const stud of currentStudentList) {
        let isCurrentStudentInPrevList = false;

        if (prevStudentList) {
          prevStudentList.forEach(prevStud => {
            if (prevStud.user_id === stud.user_id) {
              isCurrentStudentInPrevList = true;
            }
          });
        }

        if (!isCurrentStudentInPrevList) {
          hasNewStudent = true;
          break;
        }
      }
    }

    return hasNewStudent;
  };

  const playDingDong = () => {
    // play straight away once loaded
    const dingDong = document.getElementById('dingDongAudio');
    if (dingDong) {
      dingDong.play();
    }
  };

  const renderStudentUnattendedAlert = () => {
    const {
      warningDurationMinute,
      overtimeDurationMinute
    } = practiceRoomConfig;

    const unattendedStudentStates = getUnattendedStudentStates();
    const studentToBeAttended = unattendedStudentStates.studentToBeAttended;

    let severity = 'warning';
    let displayMinute = warningDurationMinute;

    if (
      studentToBeAttended
      && studentToBeAttended.practiceSeconds >= overtimeDurationMinute * 60
    ) {
      severity = 'error';
      displayMinute = overtimeDurationMinute;
    }

    if (isConferenceJoined) {
      return (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={unattendedStudentStates.shouldShowAlert}
          onClose={onUnattendedStudentAlertClose}
          key={'unattendedstudentsnackbar'}
          autoHideDuration={null}
        >
          <Alert
            className='vibrate'
            elevation={6}
            variant="filled"
            onClose={onUnattendedStudentAlertClose}
            severity={severity}
          >
            {t('tutorRoom.studentHasBeenWaitingForMoreThanNMinutes', {
              name: studentToBeAttended ? AppHelper.formatName(studentToBeAttended.first_name, studentToBeAttended.last_name.charAt(0)) : '',
              // name: AppHelper.formatName(studentToBeAttended.first_name, studentToBeAttended.last_name.charAt(0)),
              minute: displayMinute
            })}
          </Alert>
        </Snackbar>
      );
    }

    return null;
  };

  const renderTutorOvertimeAlert = () => {
    if (isConferenceJoined) {
      return (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={shouldShowTutorOvertimeAlert()}
          onClose={onTutorOvertimeAlertClose}
          key={'overtimesnackbar'}
          autoHideDuration={null}
        >
          <Alert
            className='vibrate'
            elevation={6}
            variant="filled"
            onClose={onTutorOvertimeAlertClose}
            severity={'error'}
          >
            {t('tutorRoom.youShouldMoveOnToOtherStudent')}
          </Alert>
        </Snackbar>
      );
    }
    
    return null;
  };

  const renderLoadingScreen = () => {
    return (
      <Grid
        container
        style={{ minHeight: '100vh', backgroundColor: '#222' }}
        alignItems='center'
        justifyContent='center'
        direction='column'
      >
        <Lottie
          animationData={teachingAnimation}
          loop
          className={classes.lottieContainer}
        />
        <Box mt={2}>
          <Typography color='textSecondary'>{t('tutorRoom.enteringTutorRoom')}</Typography>
        </Box>
      </Grid>
    );
  };
  
  const renderIndicators = () => {
    return (
      <Grid
        className={classes.floatingBtnContainer}
        container
        item
        direction='column'
        alignItems='center'
        justifyContent='center'
      >
        {
          practiceRoomRoleId === 1 && <LearnModeIndicator
            now={now}
            isVisible={isConferenceJoined}
          />
        }
        {
          practiceRoomRoleId === 2 && <TutorRoomIndicators
            now={now}
            isVisible={isConferenceJoined}
            toggleParticipantList={toggleParticipantList}
          />
        }
        <AutoKickIndicator
          now={now}
        />
      </Grid>
    );
  };

  const renderDingDongAudio = () => {
    return (
      <audio
        loop={false}
        id="dingDongAudio"
        src={dingDongSound} />
    );
  };

  const render = () => {
    if (!practiceRoomConfig.practice_room_id) {
      return renderLoadingScreen();
    }

    return (
      <Grid
        container
        component="main"
        className={classes.root}
        alignItems='center'
        justifyContent='center'
      >
        <CssBaseline />
        <ParticipantListDrawer
          now={now}
          open={isParticipantListOpen}
          onClose={() => props.updatePracticeRoomConfigState({ prop: 'isParticipantListOpen', value: false })}
          width={DRAWER_WIDTH}
          isConferenceJoined={isConferenceJoined}
        />
        <JitsiContainer
          open={isParticipantListOpen}
        >
          <Jitsi
            containerStyle={{
              width: '100%',
              minHeight: '100vh'
            }}
            loadingComponent={() => <Skeleton
              variant="rect"
              animation="wave"
              style={{
                width: '100%',
                minHeight: '100vh'
              }}
            />}
            onAPILoad={onJitsiApiLoad}
            domain={AppHelper.getHostNameFromUrl({ url: jitsi_server_url })}
            roomName={room_id}
            jwt={jitoken || undefined}
            interfaceConfig={{
              LANG_DETECTION: false
            }}
            config={{
              startWithAudioMuted: startWithMicMuted === true,
              startWithVideoMuted: false,
              prejoinConfig: {
                enabled: false
              },
              hideConferenceSubject: true,
              hideConferenceTimer: true,
              defaultLanguage: AppHelper.getAppLocaleCodeForApi()?.replace('_', ''),
              ...jitsi_config_obj
            }}
            noSsl={false}
            userInfo={{
              displayName
            }}
            displayName={displayName}
          />
          {renderIndicators()}
        </JitsiContainer>
        {renderTutorOvertimeAlert()}
        {renderStudentUnattendedAlert()}
        {renderDingDongAudio()}
      </Grid>
    );
  };

  return render();
};

const mapStateToProps = (state) => {
  const {
    practiceRoomConfig
  } = state.practiceRoom;

  const students = practiceRoomConfig.participants.filter(p => p.practice_room_role_id === 1);

  return {
    practiceRoomConfig,
    students
  };
};

export default connect(mapStateToProps, {
  getPracticeRoomConfigBuild6726,
  updatePracticeRoomConfigState,
  createEnterPracticeRoomMeetLog,
  createExitPracticeRoomMeetLog,
  resetPracticeRoomConfigState,
  openDialog
})(TutorRoomLessonScreen);